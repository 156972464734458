import {TextField} from 'formik-material-ui';
import React from 'react';

const SmartTextField = ({inputClass, startAdornment, endAdornment, ...rest}) => (
  <TextField
    InputProps={{
      className: inputClass,
      startAdornment: startAdornment,
      endAdornment: endAdornment
    }}
    {...rest}
  />
);
export default SmartTextField;
