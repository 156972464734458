import { useContext } from 'react';

import emspChargerServicev4 from '../../services/emspChargerServicev4';

import { EventType } from '../../types/SharedStates';

import { failedState as loadingContainerFailedState, succeededState as loadingContainerSucceededState } from '../../hooks/useLoadingContainerWithErrorPanel';
import { ChargersFlowState, UseChargersType } from '../../types/charger/ChargerState';
import { ChargerStateContext } from './ChargerContextProvider';

export const ChargerEvent = {
  LOAD_CHARGERS: 'LOAD_CHARGERS',
  CREATE_CHARGER: 'CREATE_CHARGER',
  LOAD_CHARGERS_FOR_LOCATION: 'LOAD_CHARGERS_FOR_LOCATION'
};

const useChargers = (): UseChargersType => {
  const { state, dispatch } = useContext(ChargerStateContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case ChargerEvent.LOAD_CHARGERS:
        emspChargerServicev4.getChargers().subscribe(
          (result: any) => {
            return dispatch({
              loadingState: loadingContainerSucceededState,
              chargers: result,
              flowState: ChargersFlowState.CHARGERS_LOADED
            });
          },
          (error: any) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargersFlowState.FAILED_TO_LOAD_CHARGERS
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useChargers;
