import { FC } from 'react';
import { AdminPermission, useAdminPermissions } from '../../hooks/useAdminPermissions';
import { FeatureFlag, useFeatureFlag } from '../../hooks/useFeatureFlags';
import Dashboard from '../Dashboard/Dashboard';
import NotFoundPage from '../GenericNotFound/GenericNotFound';
import ChargePointInsightsChart from './ChargePointInsightsChart/ChargePointInsightsChart';
import ChargePointStatusChart from './ChargePointStatusChart/ChargerStatusChart';
import SimpleMaintenanceOverlay from './MaintenanceOverlay';

const Insights: FC = () => {
  const dashboardUnderMaintenance = useFeatureFlag(FeatureFlag.DASHBOARD_UNDER_MAINTENANCE);
  const { hasAdminPermission } = useAdminPermissions();
  const canViewDashboard = hasAdminPermission(AdminPermission.ORG_ADMIN) || hasAdminPermission(AdminPermission.LOCATION_ADMIN);

  return (
    <>
      {dashboardUnderMaintenance && <SimpleMaintenanceOverlay />}
      <Dashboard pageHeading="Dashboard">
        <>
          {canViewDashboard ? (
            <>
              <ChargePointStatusChart />
              <ChargePointInsightsChart />
            </>
          ) : (
            <NotFoundPage errorMessage="You do not have permission to view this page" />
          )}
        </>
      </Dashboard>
    </>
  );
};

export default Insights;
