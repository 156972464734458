import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import {NewChargePointGroupUserStatus} from '../hook/useChargePointGroupUserList';
import mainTheme from '../../../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  linearProgress: {
    height: theme.spacing(1.5),
    borderColor: theme.palette.box.border,
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.box.border,
    borderBottomWidth: 0,
  },
  linearProgressBar: {
    backgroundColor: theme.palette.primary.dark,
  },
  statusError: {
    color: theme.palette.notification.error,
  },
}));

const AddingChargePointGroupUsersProgressBar = ({newChargePointGroupUsers}) => {
  const classes = useStyles(mainTheme);

  const totalByStatus = (newChargePointGroupUsers, newChargePointGroupUserStatus) => {
    let total = 0;
    newChargePointGroupUsers.forEach((newChargePointGroupUser) => {
      if (newChargePointGroupUserStatus === newChargePointGroupUser.status) {
        total++;
      }
    });
    return total;
  };

  const totalAdded = totalByStatus(newChargePointGroupUsers, NewChargePointGroupUserStatus.ADDED);
  const totalError = totalByStatus(newChargePointGroupUsers, NewChargePointGroupUserStatus.ERROR);
  const totalWarning = totalByStatus(newChargePointGroupUsers, NewChargePointGroupUserStatus.WARNING);

  const totalProcessed = totalAdded + totalError + totalWarning;
  const totalFailed = totalError + totalWarning;

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography>
          Processed {totalProcessed} of {newChargePointGroupUsers.length} user{newChargePointGroupUsers.length > 1 ? 's' : ''}
        </Typography>
        {totalFailed > 0 && (
          <Typography className={classes.statusError}>
            Failed to add {totalFailed} user{totalFailed > 1 ? 's' : ''}
          </Typography>
        )}
      </Box>
      <LinearProgress
        classes={{
          root: classes.linearProgress,
          bar: classes.linearProgressBar,
        }}
        variant="determinate"
        value={(totalProcessed / newChargePointGroupUsers.length) * 100}
      />
    </>
  );
};

AddingChargePointGroupUsersProgressBar.propTypes = {
  newChargePointGroupUsers: PropTypes.array.isRequired,
};

export default AddingChargePointGroupUsersProgressBar;
