import React, {createContext, useContext, useReducer} from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState,
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import _ from 'lodash';
import userGroupService from '../../../../services/userGroupService';

// events
export const UserGroupUserListEvent = {
  LOAD_USER_GROUP_USER_LIST: 'LOAD_USER_GROUP_USER_LIST',
};

// flow states
export const UserGroupUserListFlowState = {
  INIT: 'INIT',
  USER_GROUP_USER_LIST_OBTAINED: 'USER_GROUP_USER_LIST_OBTAINED',
  FAILED_TO_LOAD_USER_GROUP_USER_LIST: 'FAILED_TO_LOAD_USER_GROUP_USER_LIST',
};

// initial state
const initialState = {
  flowState: UserGroupUserListFlowState.INIT,
  loadingState: loadingContainerInitialState,
  userGroup: null,
  userGroupUserList: null,
};

// reducer
const reducer = (state, newState) => {
  return {...state, ...newState};
};

// context
const userGroupUserListContext = createContext();

// provider
export const UserGroupUserListProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <userGroupUserListContext.Provider value={{state, dispatch}}>{children}</userGroupUserListContext.Provider>
  );
};

// hook
const useUserGroupUserList = () => {
  const {state, dispatch} = useContext(userGroupUserListContext);

  const addEvent = (event) => {
    switch (event.type) {
      case UserGroupUserListEvent.LOAD_USER_GROUP_USER_LIST:
        userGroupService.getUserGroupUsers(event.payload.userGroup.id).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              userGroup: event.payload.userGroup,
              userGroupUserList: result,
              flowState: UserGroupUserListFlowState.USER_GROUP_USER_LIST_OBTAINED,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              userGroup: event.payload.userGroup,
              flowState: UserGroupUserListFlowState.FAILED_TO_LOAD_USER_GROUP_USER_LIST,
            })
        );
        break;
      default:
        console.log(event.type);
        throw new Error(`Unhandled event: ${event}`);
    }
  };
  return {
    state,
    addEvent,
  };
};
export default useUserGroupUserList;
