import React, {useEffect} from 'react';
import Dashboard from '../Dashboard/Dashboard';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import ChargePointGroupsTable from './ChargePointGroupsTable';
import {withChargePointGroups} from './hook/withChargePointGroups';
import useChargePointGroups, {ChargePointGroupEvent, ChargePointGroupFlowState} from './hook/useChargePointGroups';
import {SuccessBar} from '../../commons/snackbars/snackbars';
import useApplication, {ApplicationEvent} from '../../hooks/useApplication';
import useRouteListener from '../../hooks/useRouteListener';
import { FeatureFlag, requiresFeatureFlag } from '../../hooks/useFeatureFlags';
import paths from '../../routes/paths';

const ChargePointGroups = () => {
  const {state, addEvent} = useChargePointGroups();
  const {applicationState, addApplicationEvent} = useApplication();

  useEffect(() => {
    if (state.flowState === ChargePointGroupFlowState.INIT || state.flowState === ChargePointGroupFlowState.NEW_CHARGE_POINT_GROUP_CREATED) {
      addEvent({type: ChargePointGroupEvent.LOAD_CHARGE_POINT_GROUP_TABLE});
    }
  }, [state.flowState, addEvent]);

  useRouteListener(() => {
    if (applicationState.removedChargePointGroupName) {
      addApplicationEvent({type: ApplicationEvent.CLEAR_REMOVED_CHARGE_POINT_GROUP});
    }
  });

  return (
    <Dashboard pageHeading="Charge Point Fleets">
      <>
        <LoadingContainerWithErrorPanel {...state.loadingState}>
          <ChargePointGroupsTable />
        </LoadingContainerWithErrorPanel>
        <SuccessBar open={state.newChargePointGroup}>A new charge point group "{state.newChargePointGroup?.name}" has been created!</SuccessBar>
        <SuccessBar open={applicationState.removedChargePointGroupName}>{applicationState.removedChargePointGroupName} has been removed!</SuccessBar>
      </>
    </Dashboard>
  );
};

export default requiresFeatureFlag(FeatureFlag.LEGACY_CHARGE_POINT_MANAGEMENT, { redirectTo: paths.LOCATIONS })(
  withChargePointGroups(ChargePointGroups)
);
