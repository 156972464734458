import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { FC } from 'react';
import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';
import { EmspOrganisationAdmin } from '../../../types/organisation/Organisation';

interface OrganisationAdminListTableProps {
  adminUsers: EmspOrganisationAdmin[] | null;
  organisationName: string;
  handleAddAdminButtonClick: () => void;
  handleRemoveAdminButtonClick: (admin: EmspOrganisationAdmin) => void;
}

const OrganisationAdminListTable: FC<OrganisationAdminListTableProps> = ({
  adminUsers,
  organisationName,
  handleAddAdminButtonClick,
  handleRemoveAdminButtonClick
}) => {
  return (
    <MaterialTableWrapper
      columns={[
        { title: 'Id', field: 'id', width: 'auto', hidden: true, hiddenByColumnsButton: true, export: true },
        {
          title: 'Name',
          field: 'name',
          width: 'auto',
          defaultSort: 'asc'
        },
        { title: 'Email', field: 'email', width: 'auto' }
      ]}
      data={adminUsers?.map((emspOrganisationAdmin) => ({
        id: emspOrganisationAdmin.id,
        name: emspOrganisationAdmin.name,
        email: emspOrganisationAdmin.email
      }))}
      globalActionButton={{
        name: 'Add Admin',
        tooltip: 'Add a new admin to the organisation',
        onClick: handleAddAdminButtonClick
      }}
      singleActionButton={{
        icon: RemoveCircleIcon,
        tooltip: 'Remove admin from the organisation',
        onClick: (rowData: EmspOrganisationAdmin) => handleRemoveAdminButtonClick(rowData)
      }}
      exportFileName={`organisation_admins_${organisationName}`}
    />
  );
};

export default OrganisationAdminListTable;
