import React, {createContext, useContext, useReducer} from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import _ from 'lodash';
import chargePointGroupService from '../../../../services/chargePointGroupService';
import ConflictException from '../../../../utils/auth/exceptions/ConflictException';

// events
export const ChargePointGroupAdminListEvent = {
  LOAD_CHARGE_POINT_GROUP_ADMIN_LIST: 'LOAD_CHARGE_POINT_GROUP_ADMIN_LIST',
  NEW_CHARGE_POINT_GROUP_ADMIN_FORM_SUBMITTED: 'NEW_CHARGE_POINT_GROUP_ADMIN_FORM_SUBMITTED',
  NEW_CHARGE_POINT_GROUP_ADMIN_FORM_REQUESTED: 'NEW_CHARGE_POINT_GROUP_ADMIN_FORM_REQUESTED',
  NEW_CHARGE_POINT_GROUP_ADMIN_FORM_CLOSED: 'NEW_CHARGE_POINT_GROUP_ADMIN_FORM_CLOSED',
  REMOVE_CHARGE_POINT_GROUP_ADMIN_DIALOG_REQUESTED: 'REMOVE_CHARGE_POINT_GROUP_ADMIN_DIALOG_REQUESTED',
  REMOVE_CHARGE_POINT_GROUP_ADMIN_DIALOG_SUBMITTED: 'REMOVE_CHARGE_POINT_GROUP_ADMIN_DIALOG_SUBMITTED',
  REMOVE_CHARGE_POINT_GROUP_ADMIN_DIALOG_CLOSED: 'REMOVE_CHARGE_POINT_GROUP_ADMIN_DIALOG_CLOSED',
};

// flow states
export const ChargePointGroupAdminListFlowState = {
  INIT: 'INIT',
  CHARGE_POINT_GROUP_ADMIN_LIST_OBTAINED: 'CHARGE_POINT_GROUP_ADMIN_LIST_OBTAINED',
  FAILED_TO_LOAD_CHARGE_POINT_GROUP_ADMIN_LIST: 'FAILED_TO_LOAD_CHARGE_POINT_GROUP_ADMIN_LIST',
  FAILED_TO_ADD_NEW_CHARGE_POINT_GROUP_ADMIN: 'FAILED_TO_ADD_NEW_CHARGE_POINT_GROUP_ADMIN',
  ADDING_CHARGE_POINT_GROUP_ADMIN: 'ADDING_CHARGE_POINT_GROUP_ADMIN',
  SHOWING_NEW_CHARGE_POINT_GROUP_ADMIN_FORM: 'SHOWING_NEW_CHARGE_POINT_GROUP_ADMIN_FORM',
  NEW_CHARGE_POINT_GROUP_ADMIN_ADDED: 'NEW_CHARGE_POINT_GROUP_ADMIN_ADDED',
  SHOWING_REMOVE_CHARGE_POINT_GROUP_ADMIN_FORM: 'SHOWING_REMOVE_CHARGE_POINT_GROUP_ADMIN_FORM',
  REMOVING_CHARGE_POINT_GROUP_ADMIN: 'REMOVING_CHARGE_POINT_GROUP_ADMIN',
  CHARGE_POINT_GROUP_ADMIN_REMOVED: 'CHARGE_POINT_GROUP_ADMIN_REMOVED',
  FAILED_TO_REMOVE_CHARGE_POINT_GROUP_ADMIN: 'FAILED_TO_REMOVE_CHARGE_POINT_GROUP_ADMIN',
};

// initial state
const initialState = {
  chargePointGroupAdminList: null,
  loadingState: loadingContainerInitialState,
  flowState: ChargePointGroupAdminListFlowState.INIT,
  chargePointGroupAdminErrorMessage: null,
  chargePointGroupAdminWarningMessage: null,
  chargePointGroup: null,
  newChargePointGroupAdmin: null,
  removedChargePointGroupAdmin: null,
  chargePointGroupAdmin: null,
};

// reducer
const reducer = (state, newState) => {
  return {...state, ...newState};
};

// context
const chargePointGroupAdminListContext = createContext();

// provider
export const ChargePointGroupAdminListProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <chargePointGroupAdminListContext.Provider value={{state, dispatch}}>{children}</chargePointGroupAdminListContext.Provider>
  );
};

// hook
const useChargePointGroupAdminList = () => {
  const {state, dispatch} = useContext(chargePointGroupAdminListContext);

  const addEvent = (event) => {
    switch (event.type) {
      case ChargePointGroupAdminListEvent.LOAD_CHARGE_POINT_GROUP_ADMIN_LIST:
        chargePointGroupService.getAdmins(event.payload.chargePointGroup.id).subscribe(
          (result) =>
            dispatch({
              chargePointGroup: event.payload.chargePointGroup,
              loadingState: loadingContainerSucceededState,
              chargePointGroupAdminList: result,
              flowState: ChargePointGroupAdminListFlowState.CHARGE_POINT_GROUP_ADMIN_LIST_OBTAINED,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              chargePointGroup: event.payload.chargePointGroup,
              flowState: ChargePointGroupAdminListFlowState.FAILED_TO_LOAD_CHARGE_POINT_GROUP_ADMIN_LIST,
            })
        );
        break;
      case ChargePointGroupAdminListEvent.NEW_CHARGE_POINT_GROUP_ADMIN_FORM_REQUESTED:
        dispatch({
          chargePointGroupAdminErrorMessage: null,
          chargePointGroupAdminWarningMessage: null,
          newChargePointGroupAdmin: null,
          flowState: ChargePointGroupAdminListFlowState.SHOWING_NEW_CHARGE_POINT_GROUP_ADMIN_FORM,
        });
        break;
      case ChargePointGroupAdminListEvent.NEW_CHARGE_POINT_GROUP_ADMIN_FORM_CLOSED:
        dispatch({
          flowState: ChargePointGroupAdminListFlowState.CHARGE_POINT_GROUP_ADMIN_LIST_OBTAINED,
        });
        break;
      case ChargePointGroupAdminListEvent.NEW_CHARGE_POINT_GROUP_ADMIN_FORM_SUBMITTED:
        dispatch({
          flowState: ChargePointGroupAdminListFlowState.ADDING_CHARGE_POINT_GROUP_ADMIN,
        });
        chargePointGroupService.addAdmin(event.payload.chargePointGroupId, event.payload.email).subscribe(
          (result) =>
            dispatch({
              newChargePointGroupAdmin: result,
              flowState: ChargePointGroupAdminListFlowState.NEW_CHARGE_POINT_GROUP_ADMIN_ADDED,
            }),
          (error) => {
            dispatch(
              error instanceof ConflictException
                ? {
                    flowState: ChargePointGroupAdminListFlowState.FAILED_TO_ADD_NEW_CHARGE_POINT_GROUP_ADMIN,
                    chargePointGroupAdminWarningMessage: error.message,
                  }
                : {
                    flowState: ChargePointGroupAdminListFlowState.FAILED_TO_ADD_NEW_CHARGE_POINT_GROUP_ADMIN,
                    chargePointGroupAdminErrorMessage: error.message,
                  }
            );
          }
        );
        break;
      case ChargePointGroupAdminListEvent.REMOVE_CHARGE_POINT_GROUP_ADMIN_DIALOG_REQUESTED:
        dispatch({
          removedChargePointGroupAdmin: null,
          chargePointGroupAdminErrorMessage: null,
          chargePointGroupAdminWarningMessage: null,
          chargePointGroupAdmin: event.payload.chargePointGroupAdmin,
          flowState: ChargePointGroupAdminListFlowState.SHOWING_REMOVE_CHARGE_POINT_GROUP_ADMIN_FORM,
        });
        break;
      case ChargePointGroupAdminListEvent.REMOVE_CHARGE_POINT_GROUP_ADMIN_DIALOG_SUBMITTED:
        dispatch({
          flowState: ChargePointGroupAdminListFlowState.REMOVING_CHARGE_POINT_GROUP_ADMIN,
        });
        
        chargePointGroupService.removeAdmin(event.payload.chargePointGroupId, event.payload.email).subscribe(
          (result) =>
            dispatch({
              removedChargePointGroupAdmin: result,
              flowState: ChargePointGroupAdminListFlowState.CHARGE_POINT_GROUP_ADMIN_REMOVED,
            }),
          (error) =>
            dispatch({
              flowState: ChargePointGroupAdminListFlowState.FAILED_TO_REMOVE_CHARGE_POINT_GROUP_ADMIN,
              chargePointGroupAdminErrorMessage: error.message,
            })
        );
        break;
      case ChargePointGroupAdminListEvent.REMOVE_CHARGE_POINT_GROUP_ADMIN_DIALOG_CLOSED:
        dispatch({
          flowState: ChargePointGroupAdminListFlowState.CHARGE_POINT_GROUP_ADMIN_LIST_OBTAINED,
        });
        break;
      default:
        console.log(event.type);
        throw new Error(`Unhandled event: ${event}`);
    }
  };
  return {
    state,
    addEvent,
  };
};
export default useChargePointGroupAdminList;
