import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Field, FieldArray, FormikProps } from 'formik';
import React, { RefObject } from 'react';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import SmartTextField from '../../../commons/form/SmartTextField';
import { ConnectorActionButtonsContainer } from '../ChargerConnectors/ConnectorActionButtonsContainer';
import { CpoEvses } from '../ChargerDetails/ChargerInfo/ChargerInfo';
import { ReactElementLike } from 'prop-types';
import { CpoEvse, NewCpoChargePoint } from '../../../types/charger/Charger';
import NewConnectors from './NewConnectors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface EvsesFormProps {
  formikRef: RefObject<FormikProps<NewCpoChargePoint>> | RefObject<FormikProps<CpoEvses>>;
  bottomActionButtons?: ReactElementLike | null | undefined;
  isNewEvse: boolean;
}

const useStyles = makeStyles((theme) => ({
  connectorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2)
  },
  connectorTitleHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  connectorsBox: {
    flexBasis: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  connectorTitle: {
    flexBasis: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const NewEvses: React.FC<EvsesFormProps> = ({ formikRef, bottomActionButtons, isNewEvse }) => {
  const classes = useStyles();
  const formEvses = formikRef.current?.values.evses;
  const shouldDisplayRemoveIcon = formEvses !== undefined ? formEvses.length > 1 : false;
  const countFormConnectors = formikRef.current?.values.evses.flatMap((evse) => evse.connectors).length;

  const newConnectorValues = {
    format: '',
    maxAmperage: 0,
    maxVoltage: 0,
    maxElectricPower: 0,
    powerType: 'AC_1_PHASE',
    standard: '',
    ocppConnectorId: countFormConnectors ? countFormConnectors + 1 : 1
  };
  const newEvseValues = {
    evseReference: formEvses ? formEvses.length + 1 : 1,
    connectors: [{ ...newConnectorValues }]
  };

  const defaultExpanded = isNewEvse ? [`panel1`] : [];
  const [expanded, setExpanded] = React.useState<string[]>(defaultExpanded);

  const handleChange = (panel: string) => (event: React.ChangeEvent<Record<string, unknown>>, newExpanded: boolean) => {
    setExpanded((prevExpanded) => (newExpanded ? [...prevExpanded, panel] : prevExpanded.filter((p) => p !== panel)));
  };

  return (
    <FieldArray name="evses">
      {({ push, remove, form }) => {
        const evses = form.values.evses;

        const handleAddEvses = () => {
          push(newEvseValues);

          setExpanded([`panel${evses.length + 1}`]);
        };

        const handleRemoveEvses = (evseIndex: number) => {
          if (evses.length > 1) {
            remove(evseIndex);
          }
        };

        return (
          <PaperWithTitle
            title="Evses"
            topRightComponent={<ConnectorActionButtonsContainer handleAddConnector={handleAddEvses} isEvse={true} />}
            bottomRightComponent={bottomActionButtons}
          >
            <Box>
              {evses?.map((evse: CpoEvse, evseIndex: number) => {
                const evsePath = `evses.${evseIndex}`;

                const evseReference = evse.evseReference;
                const connectorCount = evse.connectors.length;
                const accordionName = `panel${evseIndex + 1}`;

                return (
                  <Accordion key={evsePath} expanded={expanded.includes(accordionName)} onChange={handleChange(accordionName)}>
                    <AccordionSummary
                      expandIcon={
                        <Tooltip title="Expand" placement="bottom">
                          <ArrowDropDownIcon />
                        </Tooltip>
                      }
                    >
                      <Box className={classes.connectorTitle}>
                        <Typography className={classes.connectorTitleHeading} variant="h4">
                          {`Evse ${evseReference !== null ? evseReference : ''}`}
                        </Typography>
                        <Typography variant="caption">{`${connectorCount} Connector${connectorCount > 1 ? 's' : ''}`}</Typography>
                        {shouldDisplayRemoveIcon ? (
                          <Tooltip title="Remove Evse" placement="bottom">
                            <IconButton onClick={() => handleRemoveEvses(evseIndex)}>
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Box></Box>
                        )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box key={evseIndex} className={classes.connectorsBox}>
                        <Field label="Evse Reference" name={`${evsePath}.evseReference`} component={SmartTextField} type="nmber" />
                        <NewConnectors formikRef={formikRef} evseReference={evsePath} />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </PaperWithTitle>
        );
      }}
    </FieldArray>
  );
};

export default NewEvses;
