import * as React from 'react';
import { ChargerLogsProvider } from './ChargerLogsProvider';

interface WithChargerLogsProps {
  (Component: React.FC<any>): (props: any) => JSX.Element;
}

export const withChargerLogs: WithChargerLogsProps = (Component) => (props) =>
  (
    <ChargerLogsProvider>
      <Component {...props} />
    </ChargerLogsProvider>
  );
