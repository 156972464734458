import { Paper, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { FC } from 'react';
import { ErrorBar, ProcessingBar, SuccessBar } from '../../../commons/snackbars/snackbars';
import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';
import useChargerConfigurations from './hook/useChargerConfigurations';
import { ChargerConfigurationsFlowState } from '../../../types/charger/ChargerConfiguration';

const useStyles = makeStyles((theme) => ({
  warning: {
    marginBottom: theme.spacing(2)
  }
}));
interface ChargePointConfigurationsPanelProps {
  chargePointId: string;
  configurations: any[];
  rebootRequired: boolean;
  refreshRequested: boolean;
  updateRequested: boolean;
}
const ChargePointConfigurationsPanel: FC<ChargePointConfigurationsPanelProps> = ({
  chargePointId,
  configurations,
  rebootRequired,
  refreshRequested,
  updateRequested
}) => {
  const { state, addEvent, ChargerConfigurationsEvent } = useChargerConfigurations();
  const classes = useStyles();
  const handleRowUpdate = (newData: any) => {
    return new Promise<void>((resolve, reject) => {
      addEvent({
        type: ChargerConfigurationsEvent.UPDATE_CHARGE_POINT_CONFIG_REQUESTED,
        payload: { id: chargePointId, updatedConfigItem: newData, onConfigItemUpdate: () => resolve() }
      });
    });
  };
  return (
    <>
      <Paper className={classes.warning}>
        {rebootRequired && (
          <Alert severity="warning">Charge point requires a reset! Please reset the charge point for the configurations to take effect!</Alert>
        )}
      </Paper>

      <MaterialTableWrapper
        columns={[
          { title: 'Key', field: 'key', editable: 'never', cellStyle: { width: '25%' } },
          { title: 'Value', field: 'value', editable: 'onUpdate', cellStyle: { width: '72%' } }
        ]}
        data={configurations}
        title="Charge Point Configurations"
        exportFileName="all_configurations"
        editable={{
          isEditable: (rowData: any) => rowData.readonly === false && !refreshRequested,
          onRowUpdate: (newData: any, oldData: any) => handleRowUpdate(newData)
        }}
        emptyDataMessage={state.configurationsErrorMessage || 'No configurations found for this charger'}
      />

      <ErrorBar open={state.configurationsErrorMessage} fullPage={undefined} onClose={undefined}>
        {state.configurationsErrorMessage}
      </ErrorBar>

      <SuccessBar open={state.flowState === ChargerConfigurationsFlowState.CHARGE_POINT_CONFIG_UPDATED} fullPage={undefined} onClose={undefined}>
        Charge Point configuration item updated!
      </SuccessBar>
      {state.updateRequested && (
        <ProcessingBar
          open={state.updateRequested && !state.configurationsErrorMessage}
          text={'Updating charge point configuration...Please wait...'}
          fullPage={undefined}
        />
      )}
      {state.refreshRequested && (
        <ProcessingBar
          open={state.refreshRequested && !state.configurationsErrorMessage}
          text={'Refreshing charge point configurations...Please wait...'}
          fullPage={undefined}
        />
      )}
    </>
  );
};

export default ChargePointConfigurationsPanel;
