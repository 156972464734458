import React, {useEffect} from 'react';
import {useRouteMatch} from 'react-router';
import paths from '../../routes/paths';
import Dashboard from '../Dashboard/Dashboard';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import ChargePointGroupDetailsTabs from './ChargePointGroupDetailsTabs';
import useChargePointGroupDetail, { ChargePointGroupDetailEvent, ChargePointGroupDetailFlowState } from './hook/useChargePointGroupDetail';
import { withChargePointGroupDetail } from './hook/withChargePointGroupDetail';
import { FeatureFlag, requiresFeatureFlag } from '../../hooks/useFeatureFlags';

const ChargePointGroupDetails = () => {
  const match = useRouteMatch(paths.CHARGE_POINT_FLEET_DETAILS);
  const chargePointGroupId = match.params.chargePointFleetId;

  const {state, addEvent} = useChargePointGroupDetail();
  useEffect(() => {
    if (state.flowState === ChargePointGroupDetailFlowState.INIT) {
      addEvent({type: ChargePointGroupDetailEvent.LOAD_CHARGE_POINT_GROUP, payload: {id: chargePointGroupId}});
    }
  }, [state.flowState, addEvent, chargePointGroupId]);

  return (
    <Dashboard pageHeading={state.chargePointGroup?.name}>
      <LoadingContainerWithErrorPanel {...state.loadingState}>{state.chargePointGroup && <ChargePointGroupDetailsTabs chargePointGroup={state.chargePointGroup} />}</LoadingContainerWithErrorPanel>
    </Dashboard>
  );
};

export default requiresFeatureFlag(FeatureFlag.LEGACY_CHARGE_POINT_MANAGEMENT, { redirectTo: paths.LOCATIONS })(
  withChargePointGroupDetail(ChargePointGroupDetails)
);
