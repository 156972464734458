import * as React from 'react';
import InsightsContextProvider from './ChargePointInsightsContextProvider';

export type WithChargePointInsightsChartProps = <T extends Record<string, any>>(Component: React.ComponentType<T>) => React.FC<T>;

export const withChargePointInsightsChart: WithChargePointInsightsChartProps = (Component) => (props) =>
  (
    <InsightsContextProvider>
      <Component {...props} />
    </InsightsContextProvider>
  );
