import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { createRef, FC, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import Button from '@material-ui/core/Button';
import SmartTextField from '../../../commons/form/SmartTextField';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

interface AddAccountAdminFormProps {
  handleAddAdminByEmail: (email: string) => void;
  hasFailed: boolean;
}

const AddAccountAdminForm: FC<AddAccountAdminFormProps> = ({ handleAddAdminByEmail, hasFailed }) => {
  const classes = useStyles(mainTheme);
  const formikRef = createRef<FormikProps<FormikValues>>();

  useEffect(() => {
    if (hasFailed && formikRef.current) {
      formikRef.current.setSubmitting(false);
    }
  }, [hasFailed, formikRef]);

  const onSubmit = (values: FormikValues) => {
    const { email } = values;
    handleAddAdminByEmail(email);
  };
  return (
    <>
      <Formik
        innerRef={formikRef}
        onSubmit={onSubmit}
        validationSchema={Yup.object({
          email: Yup.string().required('Required').email('Invalid email address')
        })}
        initialValues={{
          email: ''
        }}
      >
        {(formikProps) => (
          <Form>
            <Field
              label="Email"
              name="email"
              fullWidth
              autoFocus
              component={SmartTextField}
              helperText="Please enter the email address of the user you would like to add to this account"
            />
            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}
            >
              Add
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddAccountAdminForm;
