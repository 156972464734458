import { useContext } from 'react';
import {
  failedState as loadingContainerFailedState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import emspLocationServicev4 from '../../../../services/emspLocationServicev4';
import { AddEventType, EventType } from '../../../../types/SharedStates';

import { LocationAdminsEvent, LocationAdminsFlowState, LocationAdminsState } from '../../../../types/location/admin/LocationAdminState';
import ConflictException from '../../../../utils/auth/exceptions/ConflictException';
import { LocationAdminsContext } from './LocationAdminProvider';

interface IUseLocationAdmins {
  state: LocationAdminsState;
  addEvent: AddEventType;
}

// hook
const useLocationAdmins = (): IUseLocationAdmins => {
  const { state, dispatch } = useContext(LocationAdminsContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      // Initialisation
      case LocationAdminsEvent.LOAD_ADMINS_FOR_LOCATION:
        emspLocationServicev4.getAdminsForLocationId(event.payload?.locationId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              admins: result,
              flowState: LocationAdminsFlowState.ADMINS_LOADED_FOR_LOCATION
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: LocationAdminsFlowState.FAILED_TO_LOAD_ADMINS_FOR_LOCATION
            })
        );
        break;
      // Add admin to lcoation
      case LocationAdminsEvent.SHOW_ADD_ADMIN_DIALOG:
        dispatch({
          loadingState: loadingContainerSucceededState,
          flowState: LocationAdminsFlowState.SHOWING_ADD_ADMIN_DIALOG
        });
        break;
      case LocationAdminsEvent.CLOSE_ADD_ADMIN_DIALOG:
        dispatch({
          loadingState: loadingContainerSucceededState,
          flowState: LocationAdminsFlowState.ADD_ADMIN_DIALOG_CLOSED
        });
        break;
      case LocationAdminsEvent.SUBMIT_ADD_ADMIN_DIALOG_TO_LOCATION_REQUESTED:
        dispatch({
          flowState: LocationAdminsFlowState.ADDING_ADMIN_TO_LOCATION
        });

        emspLocationServicev4.addAdminToLocationId(event.payload?.locationId, event.payload.email).subscribe(
          (_) => {
            dispatch({
              flowState: LocationAdminsFlowState.ADMIN_ADDED_TO_LOCATION,
              loadingState: loadingContainerSucceededState,
              adminAdded: true
            });
          },
          (error) => {
            error instanceof ConflictException
              ? dispatch({
                  errorMessage: 'User is already an admin of this location',
                  flowState: LocationAdminsFlowState.FAILED_TO_ADD_ADMIN,
                  loadingState: loadingContainerFailedState('User is already an admin of this location')
                })
              : dispatch({
                  errorMessage: error.message,
                  flowState: LocationAdminsFlowState.FAILED_TO_ADD_ADMIN,
                  loadingState: loadingContainerFailedState(error.message, error.status)
                });
          }
        );
        break;

      // Remove admin from location
      case LocationAdminsEvent.SHOW_REMOVE_ADMIN_FROM_LOCATION_WARNING:
        dispatch({
          flowState: LocationAdminsFlowState.SHOWING_REMOVE_ADMIN_FROM_LOCATION_WARNING,
          adminToRemove: event.payload.adminUser
        });
        break;
      case LocationAdminsEvent.CLOSE_REMOVE_ADMIN_FROM_LOCATION_WARNING:
        dispatch({
          flowState: LocationAdminsFlowState.ADMINS_LOADED_FOR_LOCATION
        });
        break;
      case LocationAdminsEvent.REMOVE_ADMIN_FROM_LOCATION:
        dispatch({
          flowState: LocationAdminsFlowState.REMOVING_ADMIN_FROM_LOCATION
        });
        emspLocationServicev4.removeAdminFromLocationId(event.payload.locationId, event.payload.adminUserId).subscribe(
          () =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: LocationAdminsFlowState.INIT,
              adminRemoved: true
            }),
          (error) =>
            dispatch({
              errorMessage: error.message,
              flowState: LocationAdminsFlowState.FAILED_TO_REMOVE_ADMIN_FROM_LOCATION
            })
        );
        break;

      //notificationas
      case LocationAdminsEvent.CLEAR_WARNING:
        dispatch({
          warningMessage: ''
        });
        break;
      case LocationAdminsEvent.CLEAR_ERROR:
        dispatch({
          errorMessage: ''
        });
        break;
      case LocationAdminsEvent.CLEAR_SUCCESS:
        dispatch({
          adminAdded: false,
          adminRemoved: false
        });
        break;

      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useLocationAdmins;
