import useNewChargePoint, {NewChargePointEvent} from '../hook/useNewChargePoint';
import * as Yup from 'yup';
import {FieldArray, Form, Formik} from 'formik';
import {Button} from '@material-ui/core';
import React, {createRef} from 'react';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ConnectorActionButtonsContainer from './ConnectorActionButtonsContainer';
import NewChargePointFormInfoDetails from './NewChargePointFormInfoDetails';
import NewConnectorsDetails from './NewConnectorDetails';
import mainTheme from '../../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  connectorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2)
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const NewChargePointForm = () => {
  const classes = useStyles(mainTheme);
  const {state, addEvent} = useNewChargePoint();
  const formikRef = createRef();
  
  const connectorInitialValues = {
    id: '1',
    format: 'CABLE',
    maxAmperage: '',
    maxVoltage: '',
    maxPower: '',
    powerType: '',
    standard: ''
  }
  const initialValues = (state.newChargePoint) ? state.newChargePoint : {
    ocppChargePointIdentifier: '',
    name: '',
    anonymousChargingMode: false,
    latitude: '',
    longitude: '',
    address: '',
    city: '',
    state: '',
    country: 'NZL',
    postCode: '',
    connectors: [connectorInitialValues]
  }
  
  const validationSchema = Yup.object({
    ocppChargePointIdentifier: Yup.string().required('Required')
      .min(5).max(50)
      .matches(/^[A-Z0-9]+([A-Z0-9_-])*$/, 'Should be in uppercase and alphanumeric'),
    name: Yup.string().required('Required'),
    anonymousChargingMode: Yup.boolean().required('Required'),
    latitude: Yup.string().required('Required')
      .matches(/^-?([0-9]{1,2})\.([0-9]{5,7})$/, 'Should be in valid latitude format e.g. -36.848461'),
    longitude: Yup.string().required('Required')
      .matches(/^-?([0-9]{1,3})\.([0-9]{5,7})$/, 'Should be in valid longitude format e.g 174.763336'),
    address: Yup.string().required('Required').max(45),
    city: Yup.string().required('Required').max(45),
    country: Yup.string().required('Required'),
    postCode:Yup.string().max(10),
    state: Yup.string().max(20),
    connectors: Yup.array().of(
      Yup.object({
        id: Yup.number().typeError('Should be a number').required('Required'),
        format: Yup.string().required('Required'),
        maxAmperage: Yup.number().typeError('Should be a number').required('Required'),
        maxVoltage: Yup.number().typeError('Should be a number').required('Required'),
        powerType: Yup.string().required('Required'),
        standard: Yup.string().required('Required')
      })
    )
  });
  
  const handleChange = (e) => {
    // We need the current connector element's index so we can grab the maxAmperage and maxVoltage field values for this connector block.
    // We can get this index from the "name" attribute of the field
    const connectorFieldIndex = e.target.name.split('.')[1];
    const currentConnector = formikRef.current.values.connectors[connectorFieldIndex];
    const maxAmperage = currentConnector.maxAmperage;
    const maxVoltage = currentConnector.maxVoltage;
    formikRef.current.setFieldValue(`connectors.${connectorFieldIndex}.maxPower`, maxAmperage * maxVoltage);
  }
  
  const handleAddConnectorOnClick = () => {
    const values = formikRef.current.values;
    const connectors = [...values.connectors];
    const newConnector = {
      id: connectors.length+1,
      format: 'CABLE',
      maxAmperage: '',
      maxVoltage: '',
      maxPower:'',
      powerType: '',
      standard: ''
    };
    
    connectors.push(newConnector);
    formikRef.current.setValues({...values, connectors});
  }
  
  const handleRemoveConnectorOnClick = () => {
    const values = formikRef.current.values;
    const connectors = [...values.connectors];
    connectors.pop();
    formikRef.current.setValues({...values, connectors});
  }
  
  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        enableReinitialize
        validateOnMount
        onSubmit={(values) => {
          addEvent({
            type: NewChargePointEvent.REVIEW_NEW_CHARGE_POINT_FORM_REQUESTED,
            payload: values,
            createChargePointErrorMessage: null
          });
        }}
        validationSchema={validationSchema}
      >
        {( { isValid, values }) => (
          <Form>
            <PaperWithTitle title="General">
              <NewChargePointFormInfoDetails />
            </PaperWithTitle>
            <PaperWithTitle title="Connectors" topRightComponent={
              <ConnectorActionButtonsContainer
                handleAddConnector={handleAddConnectorOnClick}
                handleRemoveConnector={handleRemoveConnectorOnClick}
                connectors={values.connectors}/>}>
              <Box className={classes.connectorsContainer}>
                <FieldArray name="connectors">
                  {() => (values.connectors.map((connector, i) => {
                    return (<NewConnectorsDetails key={i} handleChange={handleChange} index={i}/>)
                  }))}
                </FieldArray>
              </Box>
            </PaperWithTitle>
            <Box className={classes.submitButtonContainer}>
              <Button type="submit" variant="contained" color="primary" disabled={!(isValid)}>
                Next
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default NewChargePointForm