import { Button } from "@material-ui/core";
const UpdateButton = ({ formikProps }) => {
    return (
        <Button type='submit' variant='contained' color='primary' disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>
            Update
        </Button>

    );
}

export default UpdateButton;