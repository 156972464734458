import { AddEventType, InitialStateType, ReducerContext } from '../../../../types/SharedStates';
import { CpoTariff } from '../../../../types/tariff/CpoTariff';
import { EmspTariff } from '../../../../types/tariff/EmspTariff';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState, succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import { createContext, FC, useContext, useReducer } from 'react';
import _ from 'lodash';
import { TariffProfilesEvent, TariffProfilesEventTypes } from '../../../../types/tariff/TariffProfilesEvents';
import tariffService from '../../../../services/tariffService';
import { combineLatest } from 'rxjs';

export enum TariffProfilesFlowState {
  INIT = 'INIT',
  LOADING_TARIFF_PROFILES = 'LOADING_TARIFF_PROFILES',
  LOADED_TARIFF_PROFILES = 'LOADED_TARIFF_PROFILES',
  FAILED_LOADING_TARIFF_PROFILES = 'FAILED_LOADING_TARIFF_PROFILES'
}

export interface TariffProfilesState {
  flowState: TariffProfilesFlowState;
  loadingState: InitialStateType;
  cpoTariffs: CpoTariff[];
  emspTariffs: EmspTariff[];
}

const initialState: TariffProfilesState = {
  flowState: TariffProfilesFlowState.INIT,
  loadingState: loadingContainerInitialState,
  cpoTariffs: [],
  emspTariffs: []
};

const TariffProfilesContext = createContext<ReducerContext<TariffProfilesState>>({
  state: initialState,
  dispatch: () => initialState
});

const reducer = (state: TariffProfilesState, newState: Partial<TariffProfilesState>) => ({ ...state, ...newState });

export const TariffProfilesProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));

  return <TariffProfilesContext.Provider value={{ state, dispatch }}>{children}</TariffProfilesContext.Provider>;
};

interface UseTariffProfilesType {
  state: TariffProfilesState;
  addEvent: AddEventType<TariffProfilesEventTypes>;
}

const useTariffProfiles = (): UseTariffProfilesType => {
  const { state, dispatch } = useContext(TariffProfilesContext);

  const addEvent = (event: TariffProfilesEventTypes) => {
    switch (event.type) {
      case TariffProfilesEvent.LOAD_TARIFF_PROFILES:
        dispatch({
          flowState: TariffProfilesFlowState.LOADING_TARIFF_PROFILES,
          loadingState: loadingContainerInitialState
        });
        combineLatest([
          tariffService.getEmspTariffs(),
          tariffService.getCpoTariffs(event.payload.cpoCountryCode, event.payload.cpoPartyId)
        ]).subscribe({
          next: ([emspTariffs, cpoTariffs]) => {
            dispatch({
              flowState: TariffProfilesFlowState.LOADED_TARIFF_PROFILES,
              loadingState: loadingContainerSucceededState,
              emspTariffs: emspTariffs,
              cpoTariffs: cpoTariffs
            });
          },
          error: (error) => {
            dispatch({
              flowState: TariffProfilesFlowState.FAILED_LOADING_TARIFF_PROFILES,
              loadingState: loadingContainerFailedState(error.message)
            });
          }
        });
        break;
    }
  };

  return { state, addEvent };
};

export default useTariffProfiles;
