import _ from 'lodash';
import { FC } from 'react';
import MaterialTableWrapper from '../../commons/table/MaterialTableWrapper';
import { ChargerReport } from '../../types/charger/Charger';
import dateTimeFormatter from '../../utils/date/dateTimeFormatter';
import lodashExtras from '../../utils/lodash_extras/lodashExtras';

interface ChargersReportTableProps {
  chargers: ChargerReport[];
}

const ChargersReportTable: FC<ChargersReportTableProps> = ({ chargers }) => {
  return (
    <MaterialTableWrapper
      columns={[
        {
          title: 'ID',
          field: 'id'
        },
        {
          title: 'Address',
          field: 'address',
          width: 'auto'
        },
        {
          title: 'Location Name',
          field: 'locationName'
        },
        {
          title: 'Organisation',
          field: 'owner'
        },

        {
          title: 'Commissioned',
          field: 'commissioned',
          render: (rowData: ChargerReport) => dateTimeFormatter.printReadableDateTime(rowData.commissioned),
          customFilterAndSearch: (filter: string, rowData: ChargerReport) =>
            lodashExtras.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.commissioned), filter),
          export: false
        },
        {
          title: 'Commissioned',
          field: 'readableCommissioned',
          hidden: true,
          export: true
        },
        {
          title: 'Decommissioned',
          field: 'decommissioned',
          render: (rowData: ChargerReport) =>
            _.isNil(rowData.decommissioned) ? '' : dateTimeFormatter.printReadableDateTime(rowData.decommissioned),
          customFilterAndSearch: (filter: string, rowData: ChargerReport) => {
            return _.isNil(rowData.decommissioned)
              ? false
              : lodashExtras.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.decommissioned), filter);
          },
          export: false
        },
        {
          title: 'Decommissioned',
          field: 'readableDecommissioned',
          hidden: true,
          export: true
        },
        {
          title: 'Power Type',
          field: 'powerType',
          width: 'auto',
          cellStyle: { width: 70 }
        }
      ]}
      data={chargers.map((charger) => ({
        ...charger,
        readableCommissioned: dateTimeFormatter.printReadableDateTime(charger.commissioned),
        readableDecommissioned: _.isNil(charger.decommissioned) ? '' : dateTimeFormatter.printReadableDateTime(charger.decommissioned)
      }))}
      exportFileName="Chargers"
    />
  );
};

export default ChargersReportTable;
