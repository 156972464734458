import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import useUserAccountBalance, {UserAccountBalanceEvent, UserAccountBalanceFlowState} from './hook/useUserAccountBalance';
import {withUserAccountBalance} from './hook/withUserAccountBalance';

const UserAccountBalance = ({userId}) => {
  const {state, addEvent} = useUserAccountBalance();

  useEffect(() => {
    if (state.flowState === UserAccountBalanceFlowState.INIT) {
      addEvent({
        type: UserAccountBalanceEvent.PAGE_ENTERED,
        userId: userId,
      });
    }
  }, [state.flowState, addEvent, userId]);
  
  
  const formatCurrency = (amount, currency) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    });
    return formatter.format(amount)
  }
  
  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      {state.accountBalance && (
        <PaperWithTitle title="Topup Details">
          <>
            <TextWithLabel label="Balance" text={formatCurrency(state.accountBalance.amount, state.accountBalance.currency)} />
          </>
        </PaperWithTitle>
      )}
    </LoadingContainerWithErrorPanel>
  );
};
UserAccountBalance.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default withUserAccountBalance(UserAccountBalance);
