import useChargePointGroupUserList, {ChargePointGroupUserListEvent, ChargePointGroupUserListFlowState} from './hook/useChargePointGroupUserList';
import React, {useEffect} from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import ChargePointGroupUserListTable from './ChargePointGroupUserListTable';
import PropTypes from 'prop-types';
import {ErrorBar, ProcessingBar, SuccessBar} from '../../../commons/snackbars/snackbars';
import _ from 'lodash';

const PrivateChargePointGroupUserList = ({chargePointGroup}) => {
  const {state, addEvent} = useChargePointGroupUserList();
  useEffect(() => {
    if (
      _.includes(
        [ChargePointGroupUserListFlowState.INIT, ChargePointGroupUserListFlowState.CHARGE_POINT_GROUP_USER_REMOVED, ChargePointGroupUserListFlowState.ADDED_USERS_TO_CHARGE_POINT_GROUP],
        state.flowState
      )
    ) {
      addEvent({
        type: ChargePointGroupUserListEvent.LOAD_CHARGE_POINT_GROUP_USER_LIST,
        payload: {chargePointGroup},
      });
    }
  }, [state.flowState, addEvent, chargePointGroup]);
  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        <ChargePointGroupUserListTable />
      </LoadingContainerWithErrorPanel>
      <SuccessBar open={state.newChargePointGroupUser}>
        {state.newChargePointGroupUser?.name} ({state.newChargePointGroupUser?.email}) has been added!
      </SuccessBar>
      <SuccessBar open={state.removedChargePointGroupUser}>
        {state.removedChargePointGroupUser?.name} ({state.removedChargePointGroupUser?.email}) has been removed!
      </SuccessBar>
      <ErrorBar open={!_.isBlankString(state.chargePointGroupUserErrorMessage)}>{state.chargePointGroupUserErrorMessage}</ErrorBar>
      <ProcessingBar open={state.flowState === ChargePointGroupUserListFlowState.REMOVING_CHARGE_POINT_GROUP_USER} />
    </>
  );
};
PrivateChargePointGroupUserList.propTypes = {
  chargePointGroup: PropTypes.object.isRequired,
};

export default PrivateChargePointGroupUserList;
