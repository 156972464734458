import { FC } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import ValueWithUnit from '../../../commons/texts/ValueWithUnit';
import transactionInsightService from '../../../services/transactionInsightService';
import mainTheme from '../../../themes/mainTheme';
import ChargePointInsightBarGraph from './ChargePointInsightBarGraphs';

interface ChargePointEnergyConsumptionProps {
  loadingTransactionsState: any;
  transactions: any;
  lastNumberOfDays: number;
}

const ChargePointEnergyConsumption: FC<ChargePointEnergyConsumptionProps> = ({ loadingTransactionsState, transactions, lastNumberOfDays }) => (
  <PaperWithTitle
    title="Electricity Consumption"
    topRightComponent={
      <ValueWithUnit value={transactionInsightService.calculateTotalElectricityConsumption(transactions).toFixed(2)} unit="kWh" textAlign="right" />
    }
  >
    <LoadingContainerWithErrorPanel {...loadingTransactionsState}>
      <ChargePointInsightBarGraph
        dataset={transactionInsightService.buildElectricityConsumptionData(transactions, lastNumberOfDays)}
        color={mainTheme.palette.chart.electricityConsumption}
      />
    </LoadingContainerWithErrorPanel>
  </PaperWithTitle>
);

export default ChargePointEnergyConsumption;
