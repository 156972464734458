import _ from 'lodash';
import { FC, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import FormDialog from '../../../commons/dialogs/FormDialog';
import { WarningDialog } from '../../../commons/dialogs/dialogs';
import { ErrorBar, ProcessingBar, SuccessBar, WarningBar } from '../../../commons/snackbars/snackbars';
import paths from '../../../routes/paths';
import { EmspOrganisationAdmin } from '../../../types/organisation/Organisation';
import { OrganisationDetailsMatchParams } from '../OrganisationDetails';
import useOrganisationDetails, { OrganisationDetailsEvent, OrganisationDetailsFlowState } from '../hook/useOrganisationDetails';
import AddOrganisationAdminForm from './AddOrganisationAdminForm';
import OrganisationAdminListTable from './OrganisationAdminListTable';

const OrganisationAdminListPanel: FC = () => {
  const organisationAdminListMatch = useRouteMatch<OrganisationDetailsMatchParams>(paths.ORGANISATION_ADMINS);

  const organisationId = organisationAdminListMatch?.params.organisationId;
  const { state, addEvent } = useOrganisationDetails();

  const handleAddAdminButtonClick = () => {
    addEvent({ type: OrganisationDetailsEvent.SHOW_ADD_ORG_ADMIN_FORM });
  };

  const handleRemoveAdminButtonClick = (admin: EmspOrganisationAdmin) => {
    addEvent({ type: OrganisationDetailsEvent.SHOW_REMOVE_ORG_ADMIN_WARNING, payload: { admin } });
  };

  const handleAddAdminByEmail = (email: string) => {
    addEvent({ type: OrganisationDetailsEvent.ADD_ORG_ADMIN_BY_EMAIL, payload: { email, organisationId } });
  };

  useEffect(() => {
    if (
      state.flowState === OrganisationDetailsFlowState.SHOWING_ORGANISATION ||
      state.flowState === OrganisationDetailsFlowState.LOAD_ORGANISATION_ADMIN_LIST
    ) {
      addEvent({ type: OrganisationDetailsEvent.LOAD_ORGANISATION_ADMINS, payload: { organisationId } });
    }
  }, [state.flowState, addEvent, organisationId]);

  const showAddAdminForm = _.includes(
    [
      OrganisationDetailsFlowState.SHOWING_ADD_ORG_ADMIN_FORM,
      OrganisationDetailsFlowState.FAILED_TO_ADD_ORG_ADMIN,
      OrganisationDetailsFlowState.ADDING_ORG_ADMIN
    ],
    state.flowState
  );

  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        <OrganisationAdminListTable
          adminUsers={state.adminUsers}
          organisationName={state.organisation?.name ?? ''}
          handleAddAdminButtonClick={handleAddAdminButtonClick}
          handleRemoveAdminButtonClick={handleRemoveAdminButtonClick}
        />
        <FormDialog
          fullPage={false}
          open={showAddAdminForm}
          onClose={() => addEvent({ type: OrganisationDetailsEvent.CLOSE_ADD_ORG_ADMIN_FORM })}
          title={`Add admin to ${state.organisation?.name ?? ''}`}
        >
          <AddOrganisationAdminForm
            handleAddAdminByEmail={handleAddAdminByEmail}
            hasFailed={state.flowState === OrganisationDetailsFlowState.FAILED_TO_ADD_ORG_ADMIN}
          />
        </FormDialog>
      </LoadingContainerWithErrorPanel>
      <SuccessBar
        open={state.adminAdded || state.adminRemoved}
        fullPage={false}
        onClose={() => addEvent({ type: OrganisationDetailsEvent.CLOSE_SUCCESS_DIALOG })}
      >
        Admin has been {state.adminAdded ? 'added' : 'removed'}
      </SuccessBar>
      <ProcessingBar open={state.flowState === OrganisationDetailsFlowState.ADDING_ORG_ADMIN} fullPage={false} />
      <WarningDialog
        fullPage={false}
        open={_.includes([OrganisationDetailsFlowState.SHOWING_REMOVE_ORG_ADMIN_WARNING], state.flowState)}
        buttonLabel="Remove admin"
        buttonCallback={() => {
          addEvent({
            type: OrganisationDetailsEvent.REMOVE_ORG_ADMIN_BY_ID,
            payload: { userId: state.adminToRemove?.id, organisationId }
          });
        }}
        onClose={() => addEvent({ type: OrganisationDetailsEvent.REMOVE_ORG_ADMIN_WARNING_CLOSED })}
      >
        Are you sure you want to remove {state.adminToRemove?.name} from {state.organisation?.name} admins?
      </WarningDialog>
      <WarningBar
        open={!_.isEmpty(state.warningMessage)}
        fullPage={false}
        onClose={() => addEvent({ type: OrganisationDetailsEvent.CLOSE_ERROR_DIALOG })}
      >
        {state.warningMessage}
      </WarningBar>
      <ErrorBar
        open={!_.isEmpty(state.errorMessage)}
        fullPage={false}
        onClose={() => addEvent({ type: OrganisationDetailsEvent.CLOSE_ERROR_DIALOG })}
      >
        {state.errorMessage}
      </ErrorBar>
    </>
  );
};

export default OrganisationAdminListPanel;
