import React, {createContext, useContext, useReducer} from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState,
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import _ from 'lodash';
import userService from '../../../../services/userService';

// events
export const UserAccountBalanceEvent = {
  PAGE_ENTERED: 'PAGE_ENTERED',
};

// flow states
export const UserAccountBalanceFlowState = {
  INIT: 'INIT',
  SHOWING_ACCOUNT_BALANCE: 'SHOWING_ACCOUNT_BALANCE',
  ERROR: 'ERROR',
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  flowState: UserAccountBalanceFlowState.INIT,
  accountBalance: null,
};

// reducer
const reducer = (state, newState) => ({...state, ...newState});

// context
const userAccountBalanceContext = createContext();

// provider
export const UserAccountBalanceProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <userAccountBalanceContext.Provider value={{state, dispatch}}>{children}</userAccountBalanceContext.Provider>
  );
};

// hook
const useUserAccountBalance = () => {
  const {state, dispatch} = useContext(userAccountBalanceContext);

  const addEvent = (event) => {
    switch (event.type) {
      case UserAccountBalanceEvent.PAGE_ENTERED:
        userService.getAccountByUuid(event.userId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: UserAccountBalanceFlowState.SHOWING_ACCOUNT_BALANCE,
              accountBalance: result,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: UserAccountBalanceFlowState.ERROR,
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
  };
};

export default useUserAccountBalance;
