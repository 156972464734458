import Slide from '@material-ui/core/Slide';
import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import mainTheme from '../../themes/mainTheme';
import clsx from 'clsx';
import {isMobile} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  desktopWithDrawer: {
    transform: `translateX(-50%) translateX(${theme.leftDrawer.width / 2}px)`,
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: theme.palette.backdrop,
  },
  snackBarMessage: {
    textAlign: 'left',
  },
  info: {
    backgroundColor: theme.palette.notification.info,
    '& $snackBarMessage, & $button': {
      color: theme.palette.primary.contrastText,
    },
  },
  success: {
    backgroundColor: theme.palette.notification.success,
    '& $snackBarMessage, & $button': {
      color: theme.palette.primary.contrastText,
    },
  },
  warning: {
    backgroundColor: theme.palette.notification.warning,
    '& $snackBarMessage, & $button': {
      color: theme.palette.primary.contrastText,
    },
  },
  error: {
    backgroundColor: theme.palette.notification.error,
    '& $snackBarMessage, & $button': {
      color: theme.palette.primary.contrastText,
    },
  },
  button: {},
}));

const SlideTransition = (props) => <Slide {...props} direction="up" />;

const SmartDialog = ({type, children, buttonLabel, buttonCallback, fullPage, onClose = () => {}}) => {
  const classes = useStyles(mainTheme);
  const [open, setOpen] = useState(true);
  let shouldCallOnClose = true;

  const handleClose = () => {
    setOpen(false);
    if (shouldCallOnClose) {
      onClose();
    }
  };

  const handleOnClick = () => {
    shouldCallOnClose = false;
    buttonCallback();
  };

  const action = (
    <Button onClick={handleOnClick} className={classes.button}>
      {buttonLabel}
    </Button>
  );

  return (
    <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
      <Snackbar className={clsx({[classes.desktopWithDrawer]: !isMobile && !fullPage})} open={open} onClose={handleClose} TransitionComponent={SlideTransition}>
        <SnackbarContent
          classes={{
            message: classes.snackBarMessage,
          }}
          className={classes[type]}
          message={children}
          action={action}
        />
      </Snackbar>
    </Backdrop>
  );
};

export const InfoDialog = ({open, children, buttonLabel, buttonCallback, fullPage, onClose}) =>
  open ? <SmartDialog type="info" children={children} buttonLabel={buttonLabel} buttonCallback={buttonCallback} fullPage={fullPage} onClose={onClose} /> : <></>;
export const SuccessDialog = ({open, children, buttonLabel, buttonCallback, fullPage, onClose}) =>
  open ? <SmartDialog type="success" children={children} buttonLabel={buttonLabel} buttonCallback={buttonCallback} fullPage={fullPage} onClose={onClose} /> : <></>;
export const WarningDialog = ({open, children, buttonLabel, buttonCallback, fullPage, onClose}) =>
  open ? <SmartDialog type="warning" children={children} buttonLabel={buttonLabel} buttonCallback={buttonCallback} fullPage={fullPage} onClose={onClose} /> : <></>;
export const ErrorDialog = ({open, children, buttonLabel, buttonCallback, fullPage, onClose}) =>
  open ? <SmartDialog type="error" children={children} buttonLabel={buttonLabel} buttonCallback={buttonCallback} fullPage={fullPage} onClose={onClose} /> : <></>;
