import { User, UserAccount, UserAccountDetails, UserSearchSummary } from '../../types/user/User';
import { UserAccountDetailsDto, UserAccountDto, UserDto, UserSearchSummaryDto } from '../../types/user/UserDto';
import {AccountType} from "../../types/account/Account";

export const toUser = (currentUserDto: UserDto): User => {
  return {
    id: currentUserDto.id,
    name: currentUserDto.name,
    mobileNumber: currentUserDto.mobileNumber,
    firstName: currentUserDto.firstName,
    lastName: currentUserDto.lastName,
    dob: currentUserDto.dob ?? undefined,
    email: currentUserDto.email,
    optedOutOfEmail: currentUserDto.optedOutOfEmail,
    emailVerified: currentUserDto.emailVerified,
    blocked: currentUserDto.blocked,
    created: currentUserDto.created,
    lastUpdated: currentUserDto.lastUpdated,
    account: currentUserDto.account ? toUserAccountDetails(currentUserDto.account) : undefined
  };
};

const toUserAccountDetails = (userAccountDetailsDto: UserAccountDetailsDto): UserAccountDetails => {
  return {
    id: userAccountDetailsDto.id,
    name: userAccountDetailsDto.name,
    type: userAccountDetailsDto.type as AccountType
  };
};

export const toUserSearchSummary = (userSearchSummaryDto: UserSearchSummaryDto): UserSearchSummary => {
  return {
    id: userSearchSummaryDto.id,
    name: userSearchSummaryDto.name,
    email: userSearchSummaryDto.email,
    created: userSearchSummaryDto.created
  };
};

export const toUserAccount = (userAccountDto: UserAccountDto): UserAccount => {
  return {
    currency: userAccountDto.currency,
    amount: userAccountDto.amount
  };
};
