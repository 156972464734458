import { InitialStateType } from '../SharedStates';
import { TransactionDetails } from '../transaction/Transaction';

export const lastNumberOfDaysChoices = [7, 14, 30, 90];

export type InsightsFilter = {
  lastNumberOfDaysChoices: number[];
  chargePointOrganisationsChoices: string[];
  chargePointLocationsChoices: string[];
};

export type InsightsFilteredBy = {
  lastNumberOfDays: number;
  chargePointOrganisations: string[];
  chargePointLocations: string[];
};

export type ChargePointInsightsState = {
  loadingFilterState: InitialStateType;
  filter: InsightsFilter;
  filteredBy: InsightsFilteredBy;
  loadingTransactionsState: InitialStateType;
  transactions: TransactionDetails[];
  flowState: ChargePointInsightsChartFlowState;
};

export enum ChargePointInsightsChartEvent {
  PAGE_ENTERED = 'PAGE_ENTERED',
  FILTER_UPDATED = 'FILTER_UPDATED'
}

export enum ChargePointInsightsChartFlowState {
  INIT = 'INIT',
  FILTER_LOADED = 'FILTER_LOADED',
  TRANSACTIONS_LOADED = 'TRANSACTIONS_LOADED',
  ERROR = 'ERROR'
}
