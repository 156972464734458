// reducer
import _ from 'lodash';
import { PropsWithChildren, createContext, useReducer } from 'react';
import { initialState as loadingContainerInitialState } from '../../../../hooks/useLoadingContainerWithErrorPanel';
import { NewChargerFlowState, NewChargerState } from '../../../../types/charger/ChargerInfoState';

// initial state
const initialState = {
  flowState: NewChargerFlowState.INIT,
  loadingState: loadingContainerInitialState,
  locations: []
};

const reducer = (state: any, newState: any) => ({ ...state, ...newState });

// context
export const NewChargerContext = createContext<NewChargerState | any>(initialState);

// provider
export const NewChargerProvider: ({ children }: React.PropsWithChildren<any>) => React.JSX.Element = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <NewChargerContext.Provider value={{ state, dispatch }}>{children}</NewChargerContext.Provider>
  );
};
