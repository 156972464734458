import React, { FC, useEffect } from 'react';
import { withChargerTariffs } from './hook/withChargerTariffs';
import useChargerTariffs from './hook/useChargerTariffs';
import { ChargerTariffsFlowState, ChargerTariffSummary } from '../../../types/charger/ChargerTariffs';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import ChargePointTariff from '../../ChargePointDetails/ChargePointTariffPanel/ChargePointTariff';
import UpdateChargerTariffForm from './UpdateChargerTariffForm';
import { ErrorBar, SuccessBar } from '../../../commons/snackbars/snackbars';
import _ from 'lodash';

type LegacyChargerTariffsProps = {
  emspChargerDisplayName: string;
  isAnonymousChargingAllowed: boolean;
};

const LegacyChargerTariffs: FC<LegacyChargerTariffsProps> = ({ emspChargerDisplayName, isAnonymousChargingAllowed }) => {
  const { state, addEvent, ChargerTariffsEventType } = useChargerTariffs();

  useEffect(() => {
    if (
            !isAnonymousChargingAllowed &&
            (state.flowState === ChargerTariffsFlowState.INIT || state.flowState === ChargerTariffsFlowState.TARIFF_UPDATED)
    ) {
      addEvent({
        type: ChargerTariffsEventType.LOAD_CHARGER_TARIFFS,
        payload: {
          // We are re-using existing emsp endpoint that still refers to chargePointId for display name
          chargePointId: emspChargerDisplayName
        }
      });
    }
  }, [state.flowState, addEvent, emspChargerDisplayName, ChargerTariffsEventType, isAnonymousChargingAllowed]);

  const handleOnEditButtonClicked = () => {
    addEvent({
      type: ChargerTariffsEventType.EDIT_TARIFF_FORM_REQUESTED
    });
  };

  const handleUpdateOnSubmit = (tariff: ChargerTariffSummary) => {
    addEvent({
      type: ChargerTariffsEventType.SUBMIT_TARIFF_UPDATE,
      payload: {
        tariff: tariff,
        chargePointId: emspChargerDisplayName
      }
    });
  };

  const handleOnUpdateCancel = () => {
    addEvent({
      type: ChargerTariffsEventType.CANCEL_TARIFF_UPDATE
    });
  };

  return (
          <LoadingContainerWithErrorPanel {...state.loadingState}>
            {state.flowState === ChargerTariffsFlowState.CHARGER_TARIFFS_LOADED && (
                    <ChargePointTariff tariff={state.tariff} handleOnEdit={handleOnEditButtonClicked} />
            )}
            {(state.flowState === ChargerTariffsFlowState.SHOW_EDIT_TARIFF_FORM ||
                    state.flowState === ChargerTariffsFlowState.UPDATE_CHARGER_TARIFF_FAILED) && (
                    <UpdateChargerTariffForm initialValues={state.tariff} onSubmit={handleUpdateOnSubmit} onCancel={handleOnUpdateCancel} />
            )}
            <ErrorBar fullPage={false} onClose={undefined} open={!_.isEmpty(state.tariffErrorMessage)}>
              {state.tariffErrorMessage}
            </ErrorBar>
            <SuccessBar fullPage={false} onClose={undefined} open={state.tariffUpdated}>
              Tariff has been updated!
            </SuccessBar>
          </LoadingContainerWithErrorPanel>
  );
};

export default withChargerTariffs(LegacyChargerTariffs);