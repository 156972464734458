import Box from '@material-ui/core/Box';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  infoFieldsContainer: {
    display: 'flex',
    minWidth: '400px',
    justifyContent: 'flex-start',
    flexDirection: 'column'
  }
}));

const ReviewChargePointFormInfoDetails = ({ newChargePoint } ) => {
  const classes = useStyles();

  return (
    <Box className={classes.infoContainer}>
      <Box className={classes.infoFieldsContainer}>
        <TextWithLabel label="Ocpp Charge Point Id" text={newChargePoint.ocppChargePointIdentifier} />
        <TextWithLabel label="Name" text={newChargePoint.name} />
        <TextWithLabel label="Latitude" text={newChargePoint.latitude} />
        <TextWithLabel label="Longitude" text={newChargePoint.longitude} />
        <TextWithLabel label="Anonymous Charging" text={(newChargePoint.anonymousChargingMode) ? 'Yes' : 'No'} />
      </Box>
      <Box className={classes.infoFieldsContainer}>
        <TextWithLabel label="Address" text={newChargePoint.address} />
        <TextWithLabel label="City" text={newChargePoint.city} />
        <TextWithLabel label="State" text={newChargePoint.state} />
        <TextWithLabel label="Country" text={newChargePoint.country} />
        <TextWithLabel label="Post Code" text={newChargePoint.postCode} />
      </Box>
    </Box>
  )
}

ReviewChargePointFormInfoDetails.propTypes = {
  newChargePoint: PropTypes.object.isRequired
}

export default ReviewChargePointFormInfoDetails;