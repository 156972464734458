import {Field, Form, Formik} from 'formik';
import React, {createRef, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import useChargePointGroups, {ChargePointGroupEvent, ChargePointGroupFlowState} from './hook/useChargePointGroups';
import SmartTextField from '../../commons/form/SmartTextField';
import SmartCheckboxField from '../../commons/form/SmartCheckboxField';
import {Button} from '@material-ui/core';
import {ErrorBar} from '../../commons/snackbars/snackbars';
import _ from 'lodash';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  createButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const CreateChargePointGroupForm = () => {
  const classes = useStyles(mainTheme);
  const {state, addEvent} = useChargePointGroups();
  const formikRef = createRef();
  useEffect(() => {
    if (state.flowState === ChargePointGroupFlowState.FAILED_TO_CREATE_NEW_CHARGE_POINT_GROUP) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.flowState, formikRef]);
  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          id: '',
          name: '',
          isPublic: false,
        }}
        onSubmit={({id, name, isPublic}) => {
          addEvent({type: ChargePointGroupEvent.SUBMIT_NEW_CHARGE_POINT_GROUP_FORM, payload: {id, name, isPublic}});
        }}
        validationSchema={Yup.object({
          id: Yup.string().required('Required').matches('^[A-Z][A-Z0-9_]+$', 'Please enter upper case letters and underscores e.g. COUNTIES_POWER'),
          name: Yup.string().required('Required'),
          isPublic: Yup.bool().required('Required'),
        })}
      >
        {(formikProps) => (
          <Form>
            <Field label="Id" name="id" fullWidth autoFocus component={SmartTextField} helperText="At least two upper case characters. Underscores are allowed e.g. COUNTIES_POWER" />
            <Field label="Name" name="name" fullWidth component={SmartTextField} helperText="Capitalise e.g. Counties Power" />
            <Field label="Public fleet" name="isPublic" component={SmartCheckboxField} helperText="Is this fleet publicly accessible?" />
            <Button className={classes.createButton} type="submit" variant="contained" color="primary" fullWidth disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>Create</Button>
          </Form>
        )}
      </Formik>
      <ErrorBar open={!_.isBlankString(state.newChargePointGroupErrorMessage)}>{state.newChargePointGroupErrorMessage}</ErrorBar>
    </>
  );
};

export default CreateChargePointGroupForm;
