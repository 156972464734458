import { useContext } from 'react';
import {
  failedState as loadingContainerFailedState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import emspLocationServicev4 from '../../../../services/emspLocationServicev4';
import { AddEventType, EventType } from '../../../../types/SharedStates';

import { LocationUsersEvent, LocationUsersFlowState, LocationUsersState } from '../../../../types/location/user/LocationUserState';
import ConflictException from '../../../../utils/auth/exceptions/ConflictException';
import { LocationUsersContext } from './LocationUserProvider';

interface IUseLocationUsers {
  state: LocationUsersState;
  addEvent: AddEventType;
}

// hook
const useLocationUsers = (): IUseLocationUsers & { LocationUsersEvent: typeof LocationUsersEvent } => {
  const { state, dispatch } = useContext(LocationUsersContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      // Initialisation
      case LocationUsersEvent.LOAD_USERS_FOR_LOCATION:
        emspLocationServicev4.getUsersForLocationId(event.payload?.locationId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              users: result,
              flowState: LocationUsersFlowState.USERS_LOADED_FOR_LOCATION
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: LocationUsersFlowState.FAILED_TO_LOAD_USERS_FOR_LOCATION
            })
        );
        break;
      // Add user to lcoation
      case LocationUsersEvent.SHOW_ADD_USER_DIALOG:
        dispatch({
          loadingState: loadingContainerSucceededState,
          flowState: LocationUsersFlowState.SHOWING_ADD_USER_DIALOG
        });
        break;
      case LocationUsersEvent.CLOSE_ADD_USER_DIALOG:
        dispatch({
          loadingState: loadingContainerSucceededState,
          flowState: LocationUsersFlowState.ADD_USER_DIALOG_CLOSED
        });
        break;
      case LocationUsersEvent.SUBMIT_ADD_USER_DIALOG_TO_LOCATION_REQUESTED:
        dispatch({
          flowState: LocationUsersFlowState.ADDING_USER_TO_LOCATION
        });

        emspLocationServicev4.addUserToLocationId(event.payload?.locationId, event.payload.email).subscribe(
          (_) => {
            dispatch({
              flowState: LocationUsersFlowState.USER_ADDED_TO_LOCATION,
              loadingState: loadingContainerSucceededState,
              userAdded: true
            });
          },
          (error) => {
            error instanceof ConflictException
              ? dispatch({
                  errorMessage: 'User is already a user of this location',
                  flowState: LocationUsersFlowState.FAILED_TO_ADD_USER
                })
              : dispatch({
                  errorMessage: error.message,
                  flowState: LocationUsersFlowState.FAILED_TO_ADD_USER
                });
          }
        );
        break;

      // Remove user from location
      case LocationUsersEvent.SHOW_REMOVE_USER_FROM_LOCATION_WARNING:
        dispatch({
          flowState: LocationUsersFlowState.SHOWING_REMOVE_USER_FROM_LOCATION_WARNING,
          userToRemove: event.payload.user
        });
        break;
      case LocationUsersEvent.CLOSE_REMOVE_USER_FROM_LOCATION_WARNING:
        dispatch({
          flowState: LocationUsersFlowState.USERS_LOADED_FOR_LOCATION
        });
        break;
      case LocationUsersEvent.REMOVE_USER_FROM_LOCATION:
        dispatch({
          flowState: LocationUsersFlowState.REMOVING_USER_FROM_LOCATION
        });
        emspLocationServicev4.removeUserFromLocationId(event.payload.locationId, event.payload.userId).subscribe(
          () =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: LocationUsersFlowState.INIT,
              userRemoved: true
            }),
          (error) =>
            dispatch({
              errorMessage: error.message,
              flowState: LocationUsersFlowState.FAILED_TO_REMOVE_USER_FROM_LOCATION
            })
        );
        break;

      //notificationas
      case LocationUsersEvent.CLEAR_WARNING:
        dispatch({
          warningMessage: ''
        });
        break;
      case LocationUsersEvent.CLEAR_ERROR:
        dispatch({
          errorMessage: ''
        });
        break;
      case LocationUsersEvent.CLEAR_SUCCESS:
        dispatch({
          userAdded: false,
          userRemoved: false
        });
        break;

      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
    LocationUsersEvent
  };
};

export default useLocationUsers;
