import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import NavigationLink from '../../commons/link/NavigationLink';
import MaterialTableWrapper from '../../commons/table/MaterialTableWrapper';
import { chargePointStatus } from '../../domain/chargePoint';
import { usePermissions } from '../../hooks/usePermissions';
import paths from '../../routes/paths';
import { Permission } from '../../types/user/UserPermission';
import UpdateChargePointFormDialog from '../ChargePointDetails/UpdateChargePointFormDialog/UpdateChargePointFormDialog';
import ChargePointStatus from '../ChargePointStatus/ChargePointStatus';
import useChargePoints, { ChargePointEvent } from './hook/useChargePoints';

const ChargePointsTable = () => {
  const history = useHistory();
  const { hasPermission } = usePermissions();
  const { state, addEvent } = useChargePoints();

  // navigate to details page
  const handleClick = (id) => {
    history.push(`${paths.CHARGE_POINTS_LEGACY}/${id}`);
  };

  // navigate to add charge point page
  const handleAddChargePointClick = () => {
    history.push(paths.NEW_CHARGE_POINT);
  };
  // charge point sort: if charge point id contains a number after OPL prefix then sort by the number
  // otherwise perform a lexical sort
  const chargePointSort = (a, b) => {
    // function to parse location id
    const parseChargePointId = (input) => {
      const match = input.match(/^OPL-(\d+)$/);
      if (match) {
        return parseInt(match[1], 10);
      }

      // we can't extract a number, just return the input
      return input;
    };

    const chargePointIdForA = parseChargePointId(a.id);
    const chargePointIdForB = parseChargePointId(b.id);

    // if both ids have a number in them, sort by the number
    if (_.isFinite(chargePointIdForA) && _.isFinite(chargePointIdForB)) {
      return chargePointIdForA - chargePointIdForB;
    }

    // otherwise use a lexical sort
    return ('' + a.id).localeCompare('' + b.id);
  };

  return (
    <>
      <MaterialTableWrapper
        columns={[
          {
            title: 'Id',
            field: 'id',
            width: 'auto',
            customSort: chargePointSort,
            render: (rowData) => <NavigationLink path={`${paths.CHARGE_POINTS_LEGACY}/${rowData.id}`} text={rowData.id} />
          },
          { title: 'Charge Point Fleet', field: 'groupName', width: 'auto' },
          { title: 'Address', field: 'address', width: 'auto' },
          { title: 'Mode', field: 'mode', width: 'auto', cellStyle: { width: 130 } },
          { title: 'Status', field: 'status', width: 'auto', render: (rowData) => <ChargePointStatus status={rowData.status} /> }
        ]}
        data={state.chargePoints.map((chargePoint) => ({
          id: chargePoint.id,
          groupName: chargePoint.groupName,
          address: chargePoint.address,
          mode: chargePoint.mode,
          status: chargePoint.status,
          electricityCost: chargePoint.operationalCost.electricityCost.ratePerKwh.toFixed(2)
        }))}
        exportFileName="Charge Points"
        globalActionButton={
          hasPermission(Permission.CREATE_CHARGE_POINT)
            ? {
                name: 'New',
                tooltip: 'Create a new charge point',
                onClick: () => handleAddChargePointClick()
              }
            : null
        }
        moreActionsButton={{
          actions: [
            { name: 'View details', onClick: (rowData) => handleClick(rowData.id) },
            {
              name: 'Update details',
              onClick: (rowData) =>
                addEvent({
                  type: ChargePointEvent.SHOW_UPDATE_CHARGE_POINT_FORM,
                  payload: {
                    id: rowData.id,
                    groupName: rowData.groupName,
                    mode: rowData.mode,
                    electricityCost: rowData.electricityCost
                  }
                }),
              getVisible: (rowData) => rowData.status !== chargePointStatus.DECOMMISSIONED
            }
          ]
        }}
      />
      <UpdateChargePointFormDialog />
    </>
  );
};

export default ChargePointsTable;
