import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import { ApplicationProvider } from '../../hooks/useApplication';
import { AuthenticationProvider } from '../../hooks/useAuthentication';
import { FeatureFlagProvider } from '../../hooks/useFeatureFlags';
import { PermissionsProvider } from '../../hooks/usePermissions';
import Routes from '../../routes/Routes';
import { AdminPermissionsProvider } from '../../hooks/useAdminPermissions';

const App = () => (
  <>
    <CssBaseline />
    <React.StrictMode>
      <main>
        <AuthenticationProvider>
          <FeatureFlagProvider>
            <PermissionsProvider>
              <AdminPermissionsProvider>
                <ApplicationProvider>
                  <Routes />
                </ApplicationProvider>
              </AdminPermissionsProvider>
            </PermissionsProvider>
          </FeatureFlagProvider>
        </AuthenticationProvider>
      </main>
    </React.StrictMode>
  </>
);

export default App;
