import React, {useCallback, useMemo, useState} from 'react';
import {useLoadingContainerWithErrorPanel} from '../../../hooks/useLoadingContainerWithErrorPanel';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import transactionService from '../../../services/transactionService';
import PropTypes from 'prop-types';
import TransactionsTable from '../../Transaction/TransactionsTable/TransactionsTable';

const ChargePointTransactionsPanel = ({chargePointId}) => {
  const [transactions, setTransactions] = useState([]);

  const observable = useMemo(() => transactionService.getTransactionsByChargePointIdForAdmin(chargePointId), [chargePointId]);
  const onSuccessCallback = useCallback((response) => setTransactions(response), []);
  const onErrorCallback = useCallback((error) => setTransactions([]), []);

  const loadingContainerWithErrorPanelState = useLoadingContainerWithErrorPanel(observable, onSuccessCallback, onErrorCallback);

  return (
    <>
      <LoadingContainerWithErrorPanel {...loadingContainerWithErrorPanelState}>
        <TransactionsTable transactions={transactions} hideChargePointInfo={true} hideOperationalExpenditure={false} />
      </LoadingContainerWithErrorPanel>
    </>
  );
};

ChargePointTransactionsPanel.propTypes = {
  chargePointId: PropTypes.string.isRequired,
};

export default ChargePointTransactionsPanel;
