
import { FC } from "react";
import ChargerProvider from "./ChargerContextProvider";


type WithChargersProps = {
    (Component: FC): (props: Record<string, unknown>) => JSX.Element

}
const withChargers: WithChargersProps = (Component) => (props) => (
    <ChargerProvider>
        <Component {...props} />
    </ChargerProvider>
)


export default withChargers;