import React from 'react';
import {Box, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';

const useStyles = (minWidth) => makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  label: {
    minWidth: minWidth,
    lineHeight: 2,
  },
}));

const BoxWithLabel = ({label, minWidth = 180, children}) => {
  const classes = useStyles(minWidth)(mainTheme);
  return (
    <Box classes={{root: classes.root}}>
      <Typography classes={{root: classes.label}}>{label}:</Typography>
      {children}
    </Box>
  );
};

export default BoxWithLabel;
