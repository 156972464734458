import _ from 'lodash';
import { createContext, useContext, useReducer } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import userGroupService from '../../../services/userGroupService';

// events
export const UserGroupEvent = {
  LOAD_USER_GROUP_TABLE: 'LOAD_USER_GROUP_TABLE',
  LOAD_USER_GROUP: 'LOAD_USER_GROUP'
};

// flow states
export const UserGroupFlowState = {
  INIT: 'INIT',
  SHOWING_USER_GROUPS_TABLE: 'SHOWING_USER_GROUPS_TABLE',
  FAILED_TO_LOAD_USER_GROUP_TABLE: 'FAILED_TO_LOAD_USER_GROUP_TABLE',
  FAILED_TO_LOAD_USER_GROUP: 'FAILED_TO_LOAD_USER_GROUP_TABLE',
  SHOWING_USER_GROUP: 'SHOWING_USER_GROUP'
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  userGroupList: null,
  userGroup: null,
  flowState: UserGroupFlowState.INIT
};

// reducer
const reducer = (state, newState) => {
  return { ...state, ...newState };
};

// context
const userGroupsContext = createContext();

// provider
export const UserGroupsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <userGroupsContext.Provider value={{ state, dispatch }}>{children}</userGroupsContext.Provider>
  );
};

// hook
const useUserGroups = () => {
  const { state, dispatch } = useContext(userGroupsContext);

  const addEvent = (event) => {
    switch (event.type) {
      case UserGroupEvent.LOAD_USER_GROUP_TABLE:
        userGroupService.getAllUserGroups().subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              userGroupList: result,
              flowState: UserGroupFlowState.SHOWING_USER_GROUPS_TABLE
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: UserGroupFlowState.FAILED_TO_LOAD_USER_GROUP_TABLE
            })
        );
        break;
      case UserGroupEvent.LOAD_USER_GROUP:
        userGroupService.getUserGroup(event.payload.userGroupId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              userGroup: result,
              flowState: UserGroupFlowState.SHOWING_USER_GROUP
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message, error.status),
              flowState: UserGroupFlowState.FAILED_TO_LOAD_USER_GROUP
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useUserGroups;
