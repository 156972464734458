import { Box } from '@material-ui/core';
import { Field } from 'formik';
import { FC } from 'react';
import MUIPlacesAutocomplete from '../../../commons/form/AddressAutoComplete/MUIPlacesAutocomplete';
import SmartTextField from '../../../commons/form/SmartTextField';
import PageWithColumns from '../../../commons/layout/PageWithColumns';

interface EditLocationAddressProps {
  setAddressFields: (address: any) => void;
}

const LocationAddressForm: FC<EditLocationAddressProps> = ({ setAddressFields }) => {
  return (
    <PageWithColumns
      columnOne={
        <>
          <MUIPlacesAutocomplete setAddressFields={setAddressFields} />
          <Field label="Address" name="address" component={SmartTextField} fullWidth />
          <Field label="State" name="state" component={SmartTextField} fullWidth />
          <Field label="Latitude" name="latitude" component={SmartTextField} fullWidth />
        </>
      }
      columnTwo={
        <>
          <Box style={{ width: '100%', height: '55px' }} />
          <Field label="City" name="city" component={SmartTextField} fullWidth />
          <Field label="Postcode" name="postalCode" component={SmartTextField} fullWidth />
          <Field label="Longitude" name="longitude" component={SmartTextField} fullWidth />
        </>
      }
    />
  );
};

export default LocationAddressForm;
