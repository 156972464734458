import React, {useEffect, useRef} from 'react';
import {v4 as uuidV4} from 'uuid';
import {isEmail, ReactMultiEmail} from 'react-multi-email';
import {Chip, FormControl, FormHelperText, FormLabel} from '@material-ui/core';
import mainTheme from '../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  label: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  multiEmails: {
    margin: 0,
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
  },
  emailInput: {
    minWidth: '50%',
    border: 0,
    outline: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  chip: {
    marginTop: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
  },
}));

const SmartMultiEmailField = ({label, autoFocus, helperText, onChange}) => {
  const classes = useStyles(mainTheme);

  const multiEmailId = `multi-email-${uuidV4()}`;
  const labelId = `${multiEmailId}-label`;

  const multiEmailRef = useRef();

  useEffect(() => {
    if (autoFocus) {
      multiEmailRef.current.emailInputRef.current.focus();
      multiEmailRef.current.emailInputRef.current.className = classes.emailInput;
    }
  }, [autoFocus, multiEmailRef, classes.emailInput]);

  return (
    <FormControl>
      <FormLabel className={classes.label} htmlFor={multiEmailId}>
        {label ?? 'Emails'}
      </FormLabel>
      <ReactMultiEmail
        className={classes.multiEmails}
        onChange={onChange}
        validateEmail={isEmail}
        getLabel={(email, index, removeEmail) => <Chip className={classes.chip} key={`${labelId}-${index}`} label={email} onDelete={() => removeEmail(index)} color="primary" />}
        ref={multiEmailRef}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

SmartMultiEmailField.propTypes = {};

export default SmartMultiEmailField;
