import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';
import React from 'react';
import * as PropTypes from 'prop-types';

const UserChargePointFleetsTable = ({chargePointFleets}) => (
  <MaterialTableWrapper
    columns={[
      {title: 'Id', field: 'id', width: 'auto'},
      {title: 'Name', field: 'name', width: 'auto'},
      {title: 'Access Type', field: 'accessType', width: 'auto'},
    ]}
    data={chargePointFleets.map((chargePointFleet) => ({
      id: chargePointFleet.id,
      name: chargePointFleet.name,
      accessType: chargePointFleet.accessType,
    }))}
    exportFileName="Charge Point Fleets"
  />
);

UserChargePointFleetsTable.propTypes = {
  chargePointFleets: PropTypes.array.isRequired,
};

export default UserChargePointFleetsTable;
