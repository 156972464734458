import React, {useEffect} from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import * as PropTypes from 'prop-types';
import {withUserChargeTags} from './hook/withUserChargeTags';
import useUserChargeTags, {UserChargeTagsEvent, UserChargeTagsFlowState} from './hook/useUserChargeTags';
import UserChargeTagsDetailsPanels from './UserChargeTagsDetailsPanels';
import _ from 'lodash';
import FormDialog from '../../../commons/dialogs/FormDialog';
import AddChargeTagForm from './AddChargeTagForm';
import {SuccessBar} from '../../../commons/snackbars/snackbars';

const UserChargeTags = ({userId}) => {
  const {state, addEvent} = useUserChargeTags();

  useEffect(() => {
    if (_.includes([UserChargeTagsFlowState.INIT, UserChargeTagsFlowState.SUCCESSFULLY_ADDED_CHARGE_TAG, UserChargeTagsFlowState.SUCCESSFULLY_DELETED_CHARGE_TAG], state.flowState)) {
      addEvent({
        type: UserChargeTagsEvent.PAGE_ENTERED,
        userId: userId,
      });
    }
  }, [state.flowState, addEvent, userId]);

  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      <>
        <UserChargeTagsDetailsPanels />
        <FormDialog
          open={_.includes([UserChargeTagsFlowState.SHOWING_ADD_CHARGE_TAG_FORM, UserChargeTagsFlowState.FAILED_TO_ADD_CHARGE_TAG, UserChargeTagsFlowState.ADDING_CHARGE_TAG], state.flowState)}
          onClose={() => addEvent({type: UserChargeTagsEvent.ADD_CHARGE_TAG_FORM_CLOSED})}
          title={'Add Charge Tag'}
        >
          <AddChargeTagForm />
        </FormDialog>
        <SuccessBar open={!_.isBlankString(state.chargeTagSuccessMessage)}>{state.chargeTagSuccessMessage}</SuccessBar>
      </>
    </LoadingContainerWithErrorPanel>
  );
};

UserChargeTags.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default withUserChargeTags(UserChargeTags);
