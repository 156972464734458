import { FC } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { getModifiedValues } from '../../../commons/form/FormikFunctions';
import { SuccessBar } from '../../../commons/snackbars/snackbars';
import { usePermissions } from '../../../hooks/usePermissions';
import { EmspAccount, EmspAccountSummary } from '../../../types/account/Account';
import { Permission } from '../../../types/user/UserPermission';
import useAccountDetails, { AccountDetailsEvent, AccountDetailsFlowState } from '../hook/useAccountDetails';
import AccountEditPanel from './AccountDetailsEditPanel';
import AccountViewPanel from './AccountDetailsViewPanel';

interface AccountInfoPanelProps {
  account: EmspAccount;
}

const AccountInfoPanel: FC<AccountInfoPanelProps> = ({ account }) => {
  const { hasPermission } = usePermissions();
  const { state, addEvent } = useAccountDetails();

  const showInfoPanel = state.flowState === AccountDetailsFlowState.INIT || state.flowState === AccountDetailsFlowState.SHOWING_ACCOUNT;
  const showEditPanel =
    state.flowState === AccountDetailsFlowState.EDITING_ACCOUNT || state.flowState === AccountDetailsFlowState.FAILED_TO_EDIT_ACCOUNT;

  const handleEdit = () => {
    addEvent({ type: AccountDetailsEvent.SHOW_UPDATE_ACCOUNT_FORM });
  };
  const cancelEdit = () => {
    addEvent({ type: AccountDetailsEvent.CANCEL_UPDATE_ACCOUNT_FORM });
  };

  const submitEdit = (values: Partial<EmspAccountSummary>) => {
    const modifiedValues: Partial<EmspAccountSummary> = getModifiedValues(values, {
      name: account.name,
      orgId: state.organisations ? state.organisations.find((o) => o.name === account.organisationName)?.id : '',
      accountType: account.accountType
    });

    addEvent({
      type: AccountDetailsEvent.SUBMITTING_UPDATE_ACCOUNT_FORM,
      payload: { id: account.id, ...modifiedValues }
    });
  };

  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      {showInfoPanel && <AccountViewPanel account={account} handleEdit={handleEdit} canEdit={hasPermission(Permission.MANAGE_ACCOUNT)} />}
      {showEditPanel && state.organisations && (
        <AccountEditPanel
          loadingState={state.loadingState}
          account={account}
          onSubmit={submitEdit}
          onCancel={cancelEdit}
          organisations={state.organisations}
          editAccountErrorMessage={state.errorMessage}
          onCloseError={() => addEvent({ type: AccountDetailsEvent.CLEAR_ERROR })}
          disableSubmit={state.errorMessage !== '' || state.flowState === AccountDetailsFlowState.FAILED_TO_EDIT_ACCOUNT}
        />
      )}
      <SuccessBar fullPage={false} open={state.accountUpdated} onClose={() => addEvent({ type: AccountDetailsEvent.CLEAR_SUCCESS })}>
        Account {state.account?.name ?? ''} has been updated.
      </SuccessBar>
    </LoadingContainerWithErrorPanel>
  );
};

export default AccountInfoPanel;
