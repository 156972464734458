import React from 'react';
import _ from 'lodash';
import useChargePointGroupUserList, {ChargePointGroupUserListEvent, ChargePointGroupUserListFlowState} from '../hook/useChargePointGroupUserList';
import AddingChargePointGroupUsersByEmailsBody from './AddingChargePointGroupUsersByEmailsBody';
import EnterEmailsBody from './EnterEmailsBody';

const AddChargePointGroupUsersForm = () => {
  const {state, addEvent} = useChargePointGroupUserList();

  const onClose = () => {
    addEvent({
      type: ChargePointGroupUserListEvent.CLOSE_CHARGE_POINT_GROUP_USERS_FORM,
      payload: {
        flowState: state.flowState,
        newChargePointGroupUsers: state.newChargePointGroupUsers,
      },
    });
  };

  const onSubmit = (emails) => {
    addEvent({
      type: ChargePointGroupUserListEvent.ADD_USERS_TO_CHARGE_POINT_GROUP,
      payload: {
        emails: emails,
        chargePointGroup: state.chargePointGroup,
      },
    });
  };

  const showAddingUsersForm = _.includes(
    [
      ChargePointGroupUserListFlowState.ADDING_USERS_TO_CHARGE_POINT_GROUP,
      ChargePointGroupUserListFlowState.SHOWING_CONFIRM_CANCELLING_ADD_USERS_TO_CHARGE_POINT_GROUP,
      ChargePointGroupUserListFlowState.COMPLETED_ADDING_USERS_TO_CHARGE_POINT_GROUP,
    ],
    state.flowState
  );

  return showAddingUsersForm ? (
    <AddingChargePointGroupUsersByEmailsBody
      newChargePointGroupUsers={state.newChargePointGroupUsers}
      onClose={onClose}
      addingUsersCompleted={ChargePointGroupUserListFlowState.COMPLETED_ADDING_USERS_TO_CHARGE_POINT_GROUP === state.flowState}
    />
  ) : (
    <EnterEmailsBody onSubmit={onSubmit} />
  );
};

export default AddChargePointGroupUsersForm;
