import React, {useEffect} from 'react';
import {withUserChargePointFleets} from './hook/withUserChargePointFleets';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PropTypes from 'prop-types';
import useUserChargePointFleets, {UserChargePointFleetsEvent, UserChargePointFleetsFlowState} from './hook/useUserChargePointFleets';
import UserChargePointFleetsTable from './UserChargePointFleetsTable';

const UserChargePointFleets = ({userId}) => {
  const {state, addEvent} = useUserChargePointFleets();

  useEffect(() => {
    if (state.flowState === UserChargePointFleetsFlowState.INIT) {
      addEvent({
        type: UserChargePointFleetsEvent.PAGE_ENTERED,
        userId: userId,
      });
    }
  }, [state.flowState, addEvent, userId]);

  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        {state.flowState === UserChargePointFleetsFlowState.SHOWING_CHARGE_POINT_FLEETS && <UserChargePointFleetsTable chargePointFleets={state.chargePointFleets} />}
      </LoadingContainerWithErrorPanel>
    </>
  );
};

UserChargePointFleets.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default withUserChargePointFleets(UserChargePointFleets);
