import { AddEventType, InitialStateType } from '../SharedStates';
import { EmspAccount } from './Account';
import { AccountFlowState } from '../../components/Accounts/hook/useAccounts';
import { EmspOrganisation } from '../organisation/Organisation';

export enum NewAccountFlowState {
  ENTERING_NEW_ACCOUNT_DETAILS = 'ENTERING_NEW_ACCOUNT_DETAILS',
  SUBMITTING_ACCOUNT_FORM = 'SUBMITTING_ACCOUNT_FORM',
  NEW_ACCOUNT_CREATED = 'NEW_ACCOUNT_CREATED',
  INIT = 'INIT'
}

export enum NewAccountEvent {
  SUBMIT_NEW_ACCOUNT_FORM_REQUESTED = 'SUBMIT_NEW_ACCOUNT_FORM_REQUESTED',
  LOAD_ORGANISATION = 'LOAD_ORGANISATION'
}

export type UseAccountsType = {
  state: AccountState;
  addEvent: AddEventType;
};

export type AccountState = {
  loadingState: InitialStateType;
  accounts?: EmspAccount[];
  flowState: AccountFlowState;
  loading?: boolean;
  account?: EmspAccount;
};

export type NewAccountState = {
  loadingState: InitialStateType;
  newAccount?: EmspAccount;
  flowState: NewAccountFlowState;
  newAccountErrorMessage?: string;
  organisations?: EmspOrganisation[];
};
