import _ from 'lodash';
import { useContext } from 'react';
import { map, mergeMap } from 'rxjs/operators';
import {
  failedState as loadingContainerFailedState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import emspLocationServicev4 from '../../../../services/emspLocationServicev4';
import organisationService from '../../../../services/organisationService';
import transactionService from '../../../../services/transactionService';
import { EventType } from '../../../../types/SharedStates';
import {
  ChargePointInsightsChartEvent,
  ChargePointInsightsChartFlowState,
  ChargePointInsightsState,
  lastNumberOfDaysChoices
} from '../../../../types/insights/InsightsState';
import { TransactionDetails } from '../../../../types/transaction/Transaction';
import dateUtils from '../../../../utils/date/dateUtils';
import { InsightsContext } from './ChargePointInsightsContextProvider';

interface UseChargePointInsightsChartType {
  state: ChargePointInsightsState;
  addEvent: (event: EventType) => void;
}
// hook
const useChargePointInsightsChart = (): UseChargePointInsightsChartType => {
  const { state, dispatch } = useContext(InsightsContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case ChargePointInsightsChartEvent.PAGE_ENTERED:
        organisationService
          .getOrganisations()
          .pipe(mergeMap((organisations) => emspLocationServicev4.getLocations().pipe(map((locations) => ({ locations, organisations })))))
          .subscribe(
            ({ locations, organisations }) => {
              dispatch({
                loadingFilterState: loadingContainerSucceededState,
                filter: {
                  lastNumberOfDaysChoices: lastNumberOfDaysChoices,
                  chargePointOrganisationsChoices: organisations,
                  chargePointLocationsChoices: locations
                },
                flowState: ChargePointInsightsChartFlowState.FILTER_LOADED
              });
              addEvent({
                type: ChargePointInsightsChartEvent.FILTER_UPDATED,
                payload: {
                  filteredBy: state.filteredBy
                }
              });
            },
            (error) =>
              dispatch({
                loadingFilterState: loadingContainerFailedState(error.message),
                flowState: ChargePointInsightsChartFlowState.ERROR
              })
          );
        break;
      case ChargePointInsightsChartEvent.FILTER_UPDATED:
        // update the filteredBy immediately so the filter feels responsive
        dispatch({
          filteredBy: event.payload.filteredBy,
          transactions: []
        });

        // start fetching the transactions
        const dateRange = dateUtils.dateRangeForLastNumberOfDays(event.payload.filteredBy.lastNumberOfDays);

        transactionService.getTransactionsByOrgsForAdmin(dateRange).subscribe(
          (result) => {
            // filter out transactions for selected charge point fleet only
            const chargePointOrgEmpty = _.isEmpty(event.payload.filteredBy.chargePointOrganisations);
            const chargePointLocationEmpty = _.isEmpty(event.payload.filteredBy.chargePointLocations);
            const noFiltersSelected = chargePointOrgEmpty && chargePointLocationEmpty;

            // filter out transactions for selected charge point location only
            const chargePointOrgFilter = (transaction: TransactionDetails) =>
              _.includes(event.payload.filteredBy.chargePointOrganisations, transaction.location.owner);
            const chargePointLocationFilter = (transaction: TransactionDetails) =>
              _.includes(event.payload.filteredBy.chargePointLocations, transaction.location.name);

            const filteredTransactions = noFiltersSelected
              ? result
              : chargePointOrgEmpty
              ? result.filter((transaction: TransactionDetails) => chargePointLocationFilter(transaction))
              : chargePointLocationEmpty
              ? result.filter((transaction: TransactionDetails) => chargePointOrgFilter(transaction))
              : result.filter((transaction: TransactionDetails) => chargePointOrgFilter(transaction) && chargePointLocationFilter(transaction));

            dispatch({
              loadingTransactionsState: loadingContainerSucceededState,
              filteredBy: event.payload.filteredBy,
              transactions: filteredTransactions,
              flowState: ChargePointInsightsChartFlowState.TRANSACTIONS_LOADED
            });
          },
          (error) =>
            dispatch({
              //...state,
              loadingTransactionsState: loadingContainerFailedState(error.message),
              filteredBy: event.payload.filteredBy,
              transactions: [],
              flowState: ChargePointInsightsChartFlowState.ERROR
            })
        );
        break;
      default:
        console.log(event);
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useChargePointInsightsChart;
