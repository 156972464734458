import { Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import _ from 'lodash';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link, useRouteMatch } from 'react-router-dom';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import HasPermission from '../../../commons/permissions/HasPermission';
import { usePermissions } from '../../../hooks/usePermissions';
import paths from '../../../routes/paths';
import { ChargerDetailsFlowState } from '../../../types/charger/ChargeDetailsState';
import { Permission } from '../../../types/user/UserPermission';
import ChargerConfigurations from '../ChargerConfigurations/ChargerConfigurations';
import ChargerLogs from '../ChargerLogs/ChargerLogsPanel';
import ChargerTariffs from '../ChargerTariff/ChargerTariffs';
import ChargerTransactions from '../ChargerTransactions/ChargerTransactions';
import { ChargerDetailsMatchParams } from './ChargerDetails';
import ChargerInfo from './ChargerInfo/ChargerInfo';
import useChargerDetails, { ChargerDetailsEvent } from './hook/useChargerDetails';
import { withChargerDetails } from './hook/withChargerDetails';

export interface ChargerTabsProps {
  locationId: string;
  chargerDisplayName: string;
}

const replaceChargerPath = (path: string, countryCode: string, partyId: string, locationId: string, chargerDisplayName: string): string => {
  return path
    .replace(':cpoCountryCode', countryCode)
    .replace(':cpoPartyId', partyId)
    .replace(':locationId', locationId)
    .replace(':chargerDisplayName', chargerDisplayName);
};

const ChargerTabs: FC<ChargerTabsProps> = () => {
  const { pathname } = useLocation();
  const { state, addEvent } = useChargerDetails();
  const { hasPermission } = usePermissions();

  const detailsMatch = useRouteMatch<ChargerDetailsMatchParams>(paths.CHARGER_DETAILS);
  const logsMatch = useRouteMatch<ChargerDetailsMatchParams>(paths.CHARGER_LOGS);
  const configurationsMatch = useRouteMatch<ChargerDetailsMatchParams>(paths.CHARGER_CONFIGURATIONS);
  const match = detailsMatch || logsMatch || configurationsMatch;

  const chargerDisplayName = match?.params.chargerDisplayName;
  const locationId = match?.params.locationId;

  const cpoCountryCode = match?.params.cpoCountryCode ?? '';
  const cpoPartyId = match?.params.cpoPartyId ?? '';

  const detailsPath =
    locationId && chargerDisplayName
      ? replaceChargerPath(paths.CHARGER_INFO_DETAILS, cpoCountryCode, cpoPartyId, locationId, chargerDisplayName)
      : '';
  const logsPath =
    locationId && chargerDisplayName ? replaceChargerPath(paths.CHARGER_LOGS, cpoCountryCode, cpoPartyId, locationId, chargerDisplayName) : '';

  const transactionsPath =
    locationId && chargerDisplayName
      ? replaceChargerPath(paths.CHARGER_TRANSACTIONS, cpoCountryCode, cpoPartyId, locationId, chargerDisplayName)
      : '';

  const configurationsPath =
    locationId && chargerDisplayName
      ? replaceChargerPath(paths.CHARGER_CONFIGURATIONS, cpoCountryCode, cpoPartyId, locationId, chargerDisplayName)
      : '';

  const tariffPath =
    locationId && chargerDisplayName ? replaceChargerPath(paths.CHARGER_TARIFFS, cpoCountryCode, cpoPartyId, locationId, chargerDisplayName) : '';
  const validPaths = [detailsPath, logsPath, transactionsPath, configurationsPath, tariffPath];
  const currentPath = validPaths.includes(pathname) ? pathname : detailsPath;

  const cpoChargePoint = state.cpoChargePoint;
  const canViewAdvancedManagementTabs = !_.isNil(cpoChargePoint);

  useEffect(() => {
    if (state.flowState === ChargerDetailsFlowState.INIT) {
      addEvent({ type: ChargerDetailsEvent.LOAD_CHARGER, payload: { locationId: locationId, displayName: chargerDisplayName } });
    }
  }, [state.flowState, addEvent, locationId, chargerDisplayName]);

  const handleReload = () => {
    addEvent({ type: ChargerDetailsEvent.RELOAD_CHARGER, payload: { locationId: locationId, displayName: chargerDisplayName } });
  };

  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      <TabContext value={currentPath}>
        <Tabs value={currentPath} aria-label="Charger Details Tabs" indicatorColor="primary" textColor="primary">
          <Tab label="Details" value={detailsPath} to={detailsPath} component={Link} />
          <Tab label="Tariffs" value={tariffPath} to={tariffPath} component={Link} />
          <Tab label="Transactions" value={transactionsPath} to={transactionsPath} component={Link} />
          {canViewAdvancedManagementTabs && hasPermission(Permission.UPDATE_CHARGE_POINT_DETAILS) && (
            <Tab label="Configurations" value={configurationsPath} to={configurationsPath} component={Link} />
          )}
          {canViewAdvancedManagementTabs && <Tab label="Logs" value={logsPath} to={logsPath} component={Link} />}
        </Tabs>
        <TabPanel value={detailsPath}>
          {state.emspCharger && (
            <ChargerInfo emspChargerFromParent={state.emspCharger} cpoChargePointFromParent={cpoChargePoint} handleReload={handleReload} />
          )}
        </TabPanel>
        <TabPanel value={tariffPath}>
          {state.emspCharger && chargerDisplayName && (
            <ChargerTariffs emspChargerFromParent={state.emspCharger} cpoChargePointFromParent={cpoChargePoint} handleReload={handleReload} />
          )}
        </TabPanel>
        <TabPanel value={logsPath}>{canViewAdvancedManagementTabs && <ChargerLogs cpoChargePointId={cpoChargePoint.id} />}</TabPanel>
        <TabPanel value={transactionsPath}>{chargerDisplayName && <ChargerTransactions emspChargerDisplayName={chargerDisplayName} />}</TabPanel>
        <HasPermission permission={Permission.UPDATE_CHARGE_POINT_DETAILS}>
          <TabPanel value={configurationsPath}>
            {canViewAdvancedManagementTabs && <ChargerConfigurations cpoChargePointId={cpoChargePoint.id} />}
          </TabPanel>
        </HasPermission>
      </TabContext>
    </LoadingContainerWithErrorPanel>
  );
};

export default withChargerDetails(ChargerTabs);
