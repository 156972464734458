import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import background from '../../assets/images/openloop_background_large.jpg';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
}));

const OpenLoopPaper = ({children}) => {
  const classes = useStyles(mainTheme);

  return (
    <Container className={classes.background} maxWidth={false}>
      {children}
    </Container>
  );
};

export default OpenLoopPaper;
