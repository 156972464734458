import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Paper} from '@material-ui/core';
import mainTheme from '../../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';
import chargeTagImage from '../../../assets/images/openloop_charge_tag.png';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import useUserChargeTags, {UserChargeTagsEvent} from './hook/useUserChargeTags';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 600,
    margin: 'auto',
  },
  chargeTagImage: {
    width: 80,
    height: 100,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spacedText: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  spacedButton: {
    minWidth: 200,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const NoChargeTagsPanel = props => {
  const classes = useStyles(mainTheme);
  const {addEvent} = useUserChargeTags();
  
  const handleClick = () => {
    addEvent({
      type: UserChargeTagsEvent.ADD_CHARGE_TAG_BUTTON_CLICKED,
    });
  };
  
  return (
    <>
      <Paper classes={{root: classes.paper}}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <img className={classes.chargeTagImage} src={chargeTagImage} title="OpenLoop Charge Tag" alt="OpenLoop Charge Tag"/>
          <Typography classes={{root: classes.spacedText}} variant='h3'>Add a Charge Tag</Typography>
          <Typography classes={{root: classes.spacedText}} variant='body1'>Give this user access to your charging network</Typography>
          <Button onClick={handleClick} classes={{root: classes.spacedButton}} type="button" variant="contained" color="primary">Add Charge Tag</Button>
        </Box>
      </Paper>
    </>
  );
};

NoChargeTagsPanel.propTypes = {};

export default NoChargeTagsPanel;