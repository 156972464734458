import React, {useState} from 'react';
import PropTypes from 'prop-types';

import RegisterForMfaSteps from './RegisterForMfaSteps';
import Devices from '../Devices';
import RegisterForMfaSelectDevice from '../RegisterForMfaSelectDevice/RegisterForMfaSelectDevice';
import RegisterMfaScanQrCode from '../RegisterMfaScanQrCode/RegisterMfaScanQrCode';

const RegisterForMfa = ({handleRegisteredForMfa}) => {
  const [registerForMfaStep, setRegisterForMfaStep] = useState(RegisterForMfaSteps.SELECT_DEVICE);

  const handleDeviceSelected = (device) => {
    if (device === Devices.IPHONE) {
      setRegisterForMfaStep(RegisterForMfaSteps.SETUP_MFA_FOR_IPHONE);
      return;
    }
    if (device === Devices.ANDROID) {
      setRegisterForMfaStep(RegisterForMfaSteps.SETUP_MFA_FOR_ANDROID);
      return;
    }
  };

  const handleReselectDevice = () => {
    setRegisterForMfaStep(RegisterForMfaSteps.SELECT_DEVICE);
  };

  const handleCodeAddedToAuthenticatorApp = () => {
    handleRegisteredForMfa();
  };

  switch (registerForMfaStep) {
    case RegisterForMfaSteps.SETUP_MFA_FOR_ANDROID:
      return (
        <RegisterMfaScanQrCode
          appStoreUrl="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en"
          storeName="Play Store"
          handleReselectDevice={handleReselectDevice}
          handleCodeAddedToAuthenticatorApp={handleCodeAddedToAuthenticatorApp}
        />
      );
    case RegisterForMfaSteps.SETUP_MFA_FOR_IPHONE:
      return (
        <RegisterMfaScanQrCode
          appStoreUrl="https://apps.apple.com/us/app/twilio-authy/id494168017"
          storeName="App Store"
          handleReselectDevice={handleReselectDevice}
          handleCodeAddedToAuthenticatorApp={handleCodeAddedToAuthenticatorApp}
        />
      );
    case RegisterForMfaSteps.SELECT_DEVICE:
    default:
      return <RegisterForMfaSelectDevice handleDeviceSelected={handleDeviceSelected} />;
  }
};

RegisterForMfa.propTypes = {
  handleRegisteredForMfa: PropTypes.func.isRequired,
};

export default RegisterForMfa;
