import PropTypes from 'prop-types';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import EditButton from '../../../commons/form/button/EditButton';

const ChargePointTariff = ({ tariff, handleOnEdit }) => {

  return (
    <>
      <PaperWithTitle title="Tariff" topRightComponent={<EditButton handleEdit={handleOnEdit} />}>
        <>
          <TextWithLabel label="Electricity Fee" text={`$${tariff.ratePerKwh.toFixed(2)}/kWh`} />
          <TextWithLabel label="Duration Fee" text={`$${tariff.ratePerMinute.toFixed(2)}/min`} />
          <TextWithLabel label="Unlock Fee" text={`$${tariff.unlockFee.toFixed(2)}/transaction`} />
        </>
      </PaperWithTitle>
    </>
  );
};

ChargePointTariff.propTypes = {
  tariff: PropTypes.object.isRequired,
};
export default ChargePointTariff;
