import {UserAdminPermissionsDto, UserPermissionDto} from '../../types/user/UserPermissionDto';
import {UserAdminPermissions, UserPermission} from '../../types/user/UserPermission';

export const toUserPermission = (userDto: UserPermissionDto): UserPermission => {
  return {
    action: userDto.action,
    group: userDto.group ?? undefined
  };
};

export const toUserAdminPermissions = (userAdminPermissionsDto: UserAdminPermissionsDto): UserAdminPermissions => {
    return {
        isLocationAdmin: userAdminPermissionsDto.is_location_admin,
        isAccountAdmin: userAdminPermissionsDto.is_account_admin,
        isOrgAdmin: userAdminPermissionsDto.is_org_admin,
        isHelpdesk: userAdminPermissionsDto.is_helpdesk,
        isSuperAdmin: userAdminPermissionsDto.is_super_admin
    };
};
