import _ from 'lodash';
import React, { createContext, PropsWithChildren, useReducer } from 'react';
import { initialState as loadingContainerInitialState } from '../../../../hooks/useLoadingContainerWithErrorPanel';
import { ChargerTariffsFlowState, ChargerTariffsState } from '../../../../types/charger/ChargerTariffs';

const reducer = (state: ChargerTariffsState, newState: ChargerTariffsState) => ({ ...state, ...newState });

const defaultTariff = {
  id: null,
  ratePerMinute: 0.0,
  unlockFee: 0.0,
  ratePerKwh: 0.0
};

// initial state
const initialState: ChargerTariffsState = {
  tariff: defaultTariff,
  flowState: ChargerTariffsFlowState.INIT,
  loadingState: loadingContainerInitialState,
  tariffErrorMessage: '',
  tariffUpdated: false
};

type ChargerTariffsContextType = {
  state: ChargerTariffsState;
  dispatch: (newState: ChargerTariffsState) => void;
};

// context
export const ChargerTariffsContext = createContext<ChargerTariffsContextType>({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

// provider
export const ChargerTariffsProvider: ({ children }: React.PropsWithChildren<any>) => React.JSX.Element = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <ChargerTariffsContext.Provider value={{ state, dispatch }}>{children}</ChargerTariffsContext.Provider>
  );
};
