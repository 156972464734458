export enum ChargerRemoteStartFlowState {
  INIT = 'INIT',
  CHECKING_ELIGIBILITY = 'CHECKING_ELIGIBILITY',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  ELIGIBLE = 'ELIGIBLE',
  CONNECTORS_LOADED = 'CONNECTORS_LOADED',
  REVIEW = 'REVIEW',
  TRANSACTION_STARTING = 'TRANSACTION_STARTING',
  TRANSACTION_STARTED = 'TRANSACTION_STARTED',
  FAILED_TO_START = 'FAILED_TO_START'
}

export interface ChargerRemoteStartState {
  flowState: ChargerRemoteStartFlowState;
  transaction: any;
  errorMessage: string | null;
  isEligible: boolean;
  preview: {
    name: string | null;
    creditCardPaymentRequired: boolean;
    cardDetails: Array<{ brand: string; lastFourDigits: string }> | null;
    postpay: boolean;
    postpayAccountName: string | null;
    userName: string | null;
  };
  email: string | null;
  evses: any | null;
  selectedEvse: any | null;
  selectedConnector: any | null;
}
