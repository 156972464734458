import _ from 'lodash';
import { PropsWithChildren, createContext, useReducer } from 'react';
import { initialState as loadingContainerInitialState } from '../../../hooks/useLoadingContainerWithErrorPanel';
import { NewOrganisationFlowState, OrganisationState } from '../../../types/organisation/OrganisationState';

const reducer = (state: any, newState: any) => ({ ...state, ...newState });

export const initialState: Partial<OrganisationState> = {
  loadingState: loadingContainerInitialState,
  flowState: NewOrganisationFlowState.ENTERING_NEW_ORGANISATION_DETAILS,
  newOrganisation: undefined,
  newOrganisationErrorMessage: null
};

export const NewOrganisationContext = createContext<OrganisationState | any>(initialState);

const NewOrganisationContextProvider: React.FC = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));

  return <NewOrganisationContext.Provider value={{ state, dispatch }}>{children}</NewOrganisationContext.Provider>;
};
export default NewOrganisationContextProvider;
