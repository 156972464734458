import React, { FC } from 'react';
import Dashboard from '../Dashboard/Dashboard';
import OrganisationsList from './OrganisationsList';
import { AdminPermission, requiresAdminPermission } from '../../hooks/useAdminPermissions';

const Organisations: FC = () => (
  <Dashboard pageHeading="Organisations">
    <OrganisationsList />
  </Dashboard>
);

export default requiresAdminPermission(AdminPermission.ORG_ADMIN)(Organisations);