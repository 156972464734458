import React, {createContext, useContext, useReducer} from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState,
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import _ from 'lodash';
import legacyTariffService from '../../../../services/legacyTariffService';
import locationService, {chargePointMode} from '../../../../services/locationService';
import {ChargePointFlowState} from '../../../ChargePoints/hook/useChargePoints';

export const ChargePointTariffEvent = {
  PAGE_ENTERED: 'PAGE_ENTERED',
  EDIT_TARIFF_FORM_REQUESTED: 'EDIT_TARIFF_FORM_REQUESTED',
  SUBMIT_UPDATE_TARIFF_FORM: 'SUBMIT_UPDATE_TARIFF_FORM',
  CANCEL_EDIT_TARIFF_REQUESTED: 'CANCEL_EDIT_TARIFF_REQUESTED',
  LOAD_TARIFF_REQUESTED: 'LOAD_TARIFF_REQUESTED'
};

export const ChargePointTariffFlowState = {
  INIT: 'INIT',
  CHARGE_POINT_TARIFF_LOADED: 'CHARGE_POINT_TARIFF_LOADED',
  FAILED_TO_LOAD_CHARGE_POINT_TARIFF: 'FAILED_TO_LOAD_CHARGE_POINT_TARIFF',
  SHOW_EDIT_TARIFF_FORM: 'SHOW_EDIT_TARIFF_FORM',
  SUBMITTING_UPDATE_TARIFF_FORM: 'SUBMITTING_UPDATE_TARIFF_FORM',
  TARIFF_UPDATED: 'TARIFF_UPDATED',
  FAILED_TO_UPDATE_TARIFF: 'FAILED_TO_UPDATE_TARIFF',
  SHOW_TARIFF_NOT_ALLOWED: 'TARIFF_NOT_ALLOWED',
  LOAD_TARIFF: 'LOAD_TARIFF'
};

const initialState = {
  flowState: ChargePointTariffFlowState.INIT,
  loadingState: loadingContainerInitialState,
  chargePointTariff: null,
  chargePointId: null,
  tariffErrorMessage: null,
  tariffUpdated: false,
};

const reducer = (state, newState) => {
  return {...state, ...newState};
};

const chargePointTariffContext = createContext();

export const ChargePointTariffProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return <chargePointTariffContext.Provider value={{state, dispatch}}>{children}</chargePointTariffContext.Provider>;
};

export const defaultTariff = () => ({
  ratePerMinute: 0,
  unlockFee: 0,
  ratePerKwh: 0,
});

const toDisplayTariff = (result) => {
  // We only support displaying of one tariff at the moment
  return _.isEmpty(result) ? defaultTariff() : result[0];
};

const useChargePointTariff = () => {
  const {state, dispatch} = useContext(chargePointTariffContext);
  
  const addEvent = (event) => {
    switch (event.type) {
      case ChargePointTariffEvent.PAGE_ENTERED:
        locationService.getLocationById(event.payload.id).subscribe(
          (result) => {
            const mode = result.mode;
            const isTariffAllowed = mode === chargePointMode.AUTH;
            dispatch({
              loadingState: loadingContainerSucceededState,
              chargePointId: event.payload.id,
              flowState: isTariffAllowed ? ChargePointTariffFlowState.LOAD_TARIFF : ChargePointTariffFlowState.SHOW_TARIFF_NOT_ALLOWED,
            });
          },
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointFlowState.FAILED_TO_LOAD_CHARGE_POINT,
            })
        );
        break;
      case ChargePointTariffEvent.LOAD_TARIFF_REQUESTED:
        legacyTariffService.getTariffsByChargePointId(event.payload.id).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              chargePointTariff: toDisplayTariff(result),
              flowState: ChargePointTariffFlowState.CHARGE_POINT_TARIFF_LOADED,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointTariffFlowState.FAILED_TO_LOAD_CHARGE_POINT_TARIFF,
              tariffErrorMessage: error.message,
            })
        );
        break;
      case ChargePointTariffEvent.EDIT_TARIFF_FORM_REQUESTED:
        dispatch({
          flowState: ChargePointTariffFlowState.SHOW_EDIT_TARIFF_FORM,
          tariffErrorMessage: null,
          tariffUpdated: false
        });
        break;
      case ChargePointTariffEvent.CANCEL_EDIT_TARIFF_REQUESTED:
        dispatch({
          flowState: ChargePointTariffFlowState.CHARGE_POINT_TARIFF_LOADED,
        });
        break;
      case ChargePointTariffEvent.SUBMIT_UPDATE_TARIFF_FORM:
        dispatch({
          ...state,
          flowState: ChargePointTariffFlowState.SUBMITTING_UPDATE_TARIFF_FORM,
        });
        
        legacyTariffService.createOrUpdateTariff(event.payload.chargePointId, event.payload.tariffId, event.payload.values).subscribe(
          () =>
            dispatch({
              ...state,
              flowState: ChargePointTariffFlowState.TARIFF_UPDATED,
              tariffUpdated: true
            }),
          (error) => {
            dispatch({
              ...state,
              flowState: ChargePointTariffFlowState.FAILED_TO_UPDATE_TARIFF,
              tariffErrorMessage: error.message,
            })
          }
        );
        break;
      default:
        console.log(event);
        throw new Error(`Unhandled event: ${event}`);
    }
  };
  
  return {
    state,
    addEvent,
  };
};

export default useChargePointTariff;
