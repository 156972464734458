import _ from 'lodash';
import React, { createContext, PropsWithChildren, useContext, useReducer } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import organisationService from '../../../services/organisationService';
import { EmspOrganisation, EmspOrganisationAdmin } from '../../../types/organisation/Organisation';
import { NewOrganisationFlowState } from '../../../types/organisation/OrganisationState';
import { AddEventType, EventType, InitialStateType } from '../../../types/SharedStates';
import ConflictException from '../../../utils/auth/exceptions/ConflictException';

// events
export const OrganisationDetailsEvent = {
  LOAD_ORGANISATION: 'LOAD_ORGANISATION',
  LOAD_ORGANISATION_ADMINS: 'LOAD_ORGANISATION_ADMINS',
  CLOSE_ADD_ORG_ADMIN_FORM: 'ADD_ORG_ADMIN_FORM_CLOSED',
  SHOW_ADD_ORG_ADMIN_FORM: 'SHOW_ADD_ORG_ADMIN_FORM',
  ADD_ORG_ADMIN_BY_EMAIL: 'ADD_ORG_ADMIN_BY_EMAIL',
  CLOSE_ERROR_DIALOG: 'CLOSE_ERROR_DIALOG',
  CLOSE_SUCCESS_DIALOG: 'CLOSE_SUCCESS_DIALOG',
  SHOW_REMOVE_ORG_ADMIN_WARNING: 'SHOW_REMOVE_ORG_ADMIN_WARNING',
  REMOVE_ORG_ADMIN_BY_ID: 'REMOVE_ORG_ADMIN_BY_ID',
  REMOVE_ORG_ADMIN_WARNING_CLOSED: 'REMOVE_ORG_ADMIN_WARNING_CLOSED'
};

// flow states
export enum OrganisationDetailsFlowState {
  INIT = 'INIT',
  SHOWING_ORGANISATION = 'SHOWING_ORGANISATION',
  FAILED_TO_LOAD_ORGANISATION = 'FAILED_TO_LOAD_ORGANISATION',
  LOAD_ORGANISATION_ADMIN_LIST = 'LOAD_ORGANISATION_ADMIN_LIST',
  ORGANISATION_ADMIN_LIST_LOADED = 'ORGANISATION_ADMIN_LIST_LOADED',
  FAILED_TO_LOAD_ORGANISATION_ADMIN_LIST = 'FAILED_TO_LOAD_ORGANISATION_ADMIN_LIST',
  SHOWING_ADD_ORG_ADMIN_FORM = 'SHOWING_ADD_ORG_ADMIN_FORM',
  FAILED_TO_ADD_ORG_ADMIN = 'FAILED_TO_ADD_ORG_ADMIN',
  ADDING_ORG_ADMIN = 'ADDING_ORG_ADMIN',
  FAILED_TO_REMOVE_ORG_ADMIN = 'FAILED_TO_REMOVE_ORG_ADMIN',
  SHOWING_REMOVE_ORG_ADMIN_WARNING = 'SHOWING_REMOVE_ORG_ADMIN_WARNING',
  LOADING_ORGANISATION_ADMIN_LIST = 'LOADING_ORGANISATION_ADMIN_LIST'
}

export interface OrganisationDetailsState {
  flowState: OrganisationDetailsFlowState | NewOrganisationFlowState;
  loadingState: InitialStateType;
  organisation: EmspOrganisation | null;
  adminUsers: EmspOrganisationAdmin[] | null;
  warningMessage: string;
  errorMessage: string;
  adminAdded: boolean;
  adminRemoved: boolean;
  adminToRemove: EmspOrganisationAdmin | null;
}

// initial state
const initialState: OrganisationDetailsState = {
  flowState: OrganisationDetailsFlowState.INIT,
  loadingState: loadingContainerInitialState,
  organisation: null,
  adminUsers: null,
  adminAdded: false,
  adminRemoved: false,
  warningMessage: '',
  errorMessage: '',
  adminToRemove: null
};

// reducer
const reducer = (state: any, newState: any) => ({ ...state, ...newState });

// context
const OrganisationDetailsContext = createContext<OrganisationDetailsState | any>(initialState);

// provider
export const OrganisationDetailsProvider: ({ children }: React.PropsWithChildren<any>) => React.JSX.Element = ({
  children
}: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <OrganisationDetailsContext.Provider value={{ state, dispatch }}>{children}</OrganisationDetailsContext.Provider>
  );
};

interface UseOrganisationDetailsType {
  state: OrganisationDetailsState;
  addEvent: AddEventType;
}

// hook
const useOrganisationDetails = (): UseOrganisationDetailsType => {
  const { state, dispatch } = useContext(OrganisationDetailsContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case OrganisationDetailsEvent.LOAD_ORGANISATION:
        organisationService.getOrganisationById(event.payload.id).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              organisation: result,
              flowState: OrganisationDetailsFlowState.SHOWING_ORGANISATION
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message, error.status),
              flowState: OrganisationDetailsFlowState.FAILED_TO_LOAD_ORGANISATION
            })
        );
        break;
      case OrganisationDetailsEvent.LOAD_ORGANISATION_ADMINS:
        dispatch({
          ...state,
          flowState: OrganisationDetailsFlowState.LOADING_ORGANISATION_ADMIN_LIST,
          loadingState: loadingContainerInitialState
        });
        organisationService.getOrganisationAdmins(event.payload.organisationId).subscribe(
          (result) =>
            dispatch({
              ...state,
              loadingState: loadingContainerSucceededState,
              adminUsers: result,
              flowState: OrganisationDetailsFlowState.ORGANISATION_ADMIN_LIST_LOADED
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: OrganisationDetailsFlowState.FAILED_TO_LOAD_ORGANISATION_ADMIN_LIST
            })
        );
        break;
      case OrganisationDetailsEvent.SHOW_ADD_ORG_ADMIN_FORM:
        dispatch({
          flowState: OrganisationDetailsFlowState.SHOWING_ADD_ORG_ADMIN_FORM
        });
        break;
      case OrganisationDetailsEvent.CLOSE_ADD_ORG_ADMIN_FORM:
        dispatch({
          flowState: OrganisationDetailsFlowState.ORGANISATION_ADMIN_LIST_LOADED
        });
        break;
      case OrganisationDetailsEvent.ADD_ORG_ADMIN_BY_EMAIL:
        dispatch({
          flowState: OrganisationDetailsFlowState.ADDING_ORG_ADMIN
        });
        organisationService.addOrganisationAdmin(event.payload.organisationId, event.payload.email).subscribe(
          () =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: OrganisationDetailsFlowState.LOAD_ORGANISATION_ADMIN_LIST,
              adminAdded: true
            }),
          (error) =>
            error instanceof ConflictException
              ? dispatch({
                  warningMessage: 'User is already an admin of this organisation',
                  flowState: OrganisationDetailsFlowState.FAILED_TO_ADD_ORG_ADMIN
                })
              : dispatch({
                  errorMessage: error.message,
                  flowState: OrganisationDetailsFlowState.FAILED_TO_ADD_ORG_ADMIN
                })
        );
        break;
      case OrganisationDetailsEvent.CLOSE_ERROR_DIALOG:
        dispatch({
          warningMessage: '',
          errorMessage: ''
        });
        break;
      case OrganisationDetailsEvent.REMOVE_ORG_ADMIN_BY_ID:
        organisationService.removeOrganisationAdmin(event.payload.organisationId, event.payload.userId).subscribe(
          () =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: OrganisationDetailsFlowState.LOAD_ORGANISATION_ADMIN_LIST,
              adminRemoved: true,
              adminToRemove: null
            }),
          (error) =>
            dispatch({
              errorMessage: error.message,
              flowState: OrganisationDetailsFlowState.FAILED_TO_REMOVE_ORG_ADMIN,
              adminToRemove: null
            })
        );
        break;

      case OrganisationDetailsEvent.SHOW_REMOVE_ORG_ADMIN_WARNING:
        dispatch({
          adminToRemove: event.payload.admin,
          flowState: OrganisationDetailsFlowState.SHOWING_REMOVE_ORG_ADMIN_WARNING
        });
        break;
      case OrganisationDetailsEvent.REMOVE_ORG_ADMIN_WARNING_CLOSED:
        dispatch({
          adminToRemove: null,
          flowState: OrganisationDetailsFlowState.ORGANISATION_ADMIN_LIST_LOADED
        });
        break;
      case OrganisationDetailsEvent.CLOSE_SUCCESS_DIALOG:
        dispatch({
          adminAdded: false,
          adminRemoved: false
        });
        break;

      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useOrganisationDetails;
