import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { requiresPermission } from '../../hocs/requiresPermission';
import paths from '../../routes/paths';
import { NewAccountEvent, NewAccountFlowState } from '../../types/account/AccountState';
import { Permission } from '../../types/user/UserPermission';
import Dashboard from '../Dashboard/Dashboard';
import AccountForm from './AccountForm';
import useNewAccount from './hook/useNewAccount';
import { withNewAccount } from './hook/withNewAccount';

interface LocationState {
  orgId?: string;
  showAccountCreatedSuccessBar?: boolean;
}

const NewAccountContainer = () => {
  const { state, addEvent } = useNewAccount();
  const history = useHistory<LocationState>();
  const orgId = history.location.state?.orgId;

  const handleFormSubmit = (values: { name: string }) => {
    addEvent({
      type: NewAccountEvent.SUBMIT_NEW_ACCOUNT_FORM_REQUESTED,
      payload: values
    });
  };

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    if (state.flowState === NewAccountFlowState.INIT) {
      addEvent({ type: NewAccountEvent.LOAD_ORGANISATION });
    }

    if (state.flowState === NewAccountFlowState.NEW_ACCOUNT_CREATED) {
      const pathname = paths.ACCOUNTS + `/${state.newAccount?.id}/details`;
      history.push({ pathname: pathname }, { showAccountCreatedSuccessBar: true });
    }
  }, [state.flowState, state.newAccount, addEvent, history]);

  return (
    <Dashboard pageHeading="New Account">
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        {state.organisations && (
          <AccountForm
            accountType={state.newAccount?.accountType}
            name={state.newAccount?.name || ''}
            errorMessage={state.newAccountErrorMessage}
            onSubmit={handleFormSubmit}
            onCancel={handleCancel}
            disableSubmit={
              state.flowState !== NewAccountFlowState.SUBMITTING_ACCOUNT_FORM && state.flowState !== NewAccountFlowState.NEW_ACCOUNT_CREATED
            }
            organisations={state.organisations}
            orgId={orgId}
          />
        )}
      </LoadingContainerWithErrorPanel>
    </Dashboard>
  );
};

export default requiresPermission(Permission.MANAGE_ACCOUNT)(withNewAccount(NewAccountContainer));
