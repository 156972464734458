import {Field, Form, Formik} from 'formik';
import React, {createRef, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import Button from '@material-ui/core/Button';
import {ErrorBar, ProcessingBar, WarningBar} from '../../../commons/snackbars/snackbars';
import SmartTextField from '../../../commons/form/SmartTextField';
import _ from 'lodash';
import * as Yup from 'yup';
import useChargePointGroupAdminList, {ChargePointGroupAdminListEvent, ChargePointGroupAdminListFlowState} from './hook/useChargePointGroupAdminList';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const AddChargePointGroupAdminForm = () => {
  const classes = useStyles(mainTheme);
  const {state, addEvent} = useChargePointGroupAdminList();
  const formikRef = createRef();
  useEffect(() => {
    if (state.flowState === ChargePointGroupAdminListFlowState.FAILED_TO_ADD_NEW_CHARGE_POINT_GROUP_ADMIN) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.flowState, formikRef]);
  return (
    <>
      <Formik
        innerRef={formikRef}
        onSubmit={({email}) => {
          addEvent({type: ChargePointGroupAdminListEvent.NEW_CHARGE_POINT_GROUP_ADMIN_FORM_SUBMITTED, payload: {email: email, chargePointGroupId: state.chargePointGroup.id}});
        }}
        validationSchema={Yup.object({
          email: Yup.string().required('Required').email('Invalid email address'),
        })}
        initialValues={{
          email: '',
        }}
      >
        {(formikProps) => (
          <Form>
            <Field label="Email" name="email" fullWidth autoFocus component={SmartTextField} helperText="Please enter the email address of the user you would like to add to this charge point fleet" />
            <Button className={classes.button} type="submit" variant="contained" color="primary" fullWidth disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>
              Add
            </Button>
          </Form>
        )}
      </Formik>
      <WarningBar open={!_.isBlankString(state.chargePointGroupAdminWarningMessage)}>{state.chargePointGroupAdminWarningMessage}</WarningBar>
      <ErrorBar open={!_.isBlankString(state.chargePointGroupAdminErrorMessage)}>{state.chargePointGroupAdminErrorMessage}</ErrorBar>
      <ProcessingBar open={state.flowState === ChargePointGroupAdminListFlowState.ADDING_CHARGE_POINT_GROUP_ADMIN} />
    </>
  );
};

export default AddChargePointGroupAdminForm;
