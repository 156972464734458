import React, {createContext, useContext, useReducer} from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState,
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import _ from 'lodash';
import chargePointGroupService from '../../../../services/chargePointGroupService';
import {catchError, concatMap, tap} from 'rxjs/operators';
import {EMPTY, from} from 'rxjs';
import ConflictException from '../../../../utils/auth/exceptions/ConflictException';

// events
export const ChargePointGroupUserListEvent = {
  LOAD_CHARGE_POINT_GROUP_USER_LIST: 'LOAD_CHARGE_POINT_GROUP_USER_LIST',
  SHOW_ADD_CHARGE_POINT_GROUP_USERS_FORM: 'SHOW_NEW_CHARGE_POINT_GROUP_USERS_FORM',
  CLOSE_CHARGE_POINT_GROUP_USERS_FORM: 'CLOSE_CHARGE_POINT_GROUP_USERS_FORM',
  SHOW_REMOVE_CHARGE_POINT_GROUP_USER_DIALOG: 'SHOW_REMOVE_CHARGE_POINT_GROUP_USER_DIALOG',
  SUBMIT_REMOVE_CHARGE_POINT_GROUP_USER_DIALOG: 'SUBMIT_REMOVE_CHARGE_POINT_GROUP_USER_DIALOG',
  CLOSE_REMOVE_CHARGE_POINT_GROUP_USER_DIALOG: 'CLOSE_REMOVE_CHARGE_POINT_GROUP_USER_DIALOG',
  ADD_USERS_TO_CHARGE_POINT_GROUP: 'ADD_USERS_TO_CHARGE_POINT_GROUP',
  COMPLETE_ADDING_USERS_TO_CHARGE_POINT_GROUP: 'COMPLETE_ADDING_USERS_TO_CHARGE_POINT_GROUP',
  RESUME_ADDING_USERS_TO_CHARGE_POINT_GROUP: 'RESUME_ADDING_USERS_TO_CHARGE_POINT_GROUP',
};

// flow states
export const ChargePointGroupUserListFlowState = {
  INIT: 'INIT',
  CHARGE_POINT_GROUP_USER_LIST_OBTAINED: 'CHARGE_POINT_GROUP_USER_LIST_OBTAINED',
  FAILED_TO_LOAD_CHARGE_POINT_GROUP_USER_LIST: 'FAILED_TO_LOAD_CHARGE_POINT_GROUP_USER_LIST',
  SHOWING_ADD_CHARGE_POINT_GROUP_USERS_FORM: 'SHOWING_ADD_CHARGE_POINT_GROUP_USERS_FORM',
  SHOWING_REMOVE_CHARGE_POINT_GROUP_USER_FORM: 'SHOWING_REMOVE_CHARGE_POINT_GROUP_USER_FORM',
  FAILED_TO_REMOVE_CHARGE_POINT_GROUP_USER: 'FAILED_TO_REMOVE_CHARGE_POINT_GROUP_USER',
  REMOVING_CHARGE_POINT_GROUP_USER: 'REMOVING_CHARGE_POINT_GROUP_USER',
  CHARGE_POINT_GROUP_USER_REMOVED: 'CHARGE_POINT_GROUP_USER_REMOVED',
  ADDING_USERS_TO_CHARGE_POINT_GROUP: 'ADDING_USERS_TO_CHARGE_POINT_GROUP',
  ADDED_USERS_TO_CHARGE_POINT_GROUP: 'ADDED_USERS_TO_CHARGE_POINT_GROUP',
  SHOWING_CONFIRM_CANCELLING_ADD_USERS_TO_CHARGE_POINT_GROUP: 'SHOWING_CONFIRM_CANCELLING_ADD_USERS_TO_CHARGE_POINT_GROUP',
  COMPLETED_ADDING_USERS_TO_CHARGE_POINT_GROUP: 'COMPLETED_ADDING_USERS_TO_CHARGE_POINT_GROUP',
};

// initial state
const initialState = {
  chargePointGroupUserList: null,
  loadingState: loadingContainerInitialState,
  flowState: ChargePointGroupUserListFlowState.INIT,
  chargePointGroup: null,
  chargePointGroupUserErrorMessage: null,
  chargePointGroupUser: null,
  removedChargePointGroupUser: null,
  newChargePointGroupUsers: [],
};

// reducer
const reducer = (state, newState) => {
  return {...state, ...newState};
};

// context
const chargePointGroupUserListContext = createContext();

// provider
export const ChargePointGroupUserListProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <chargePointGroupUserListContext.Provider value={{state, dispatch}}>{children}</chargePointGroupUserListContext.Provider>
  );
};

export const NewChargePointGroupUserStatus = {
  QUEUED: 'Queued',
  ADDING: 'Adding',
  ADDED: 'Added',
  WARNING: 'Warning',
  ERROR: 'Error',
  CANCELLED: 'Cancelled',
  PAUSED: 'Paused',
};

export const incompleteNewChargePointGroupUserStatusList = [NewChargePointGroupUserStatus.QUEUED, NewChargePointGroupUserStatus.ADDING, NewChargePointGroupUserStatus.PAUSED];

let addingQueuedNewUsersObservable;

// hook
const useChargePointGroupUserList = () => {
  const {state, dispatch} = useContext(chargePointGroupUserListContext);

  const buildSortedQueuedNewChargePointGroupUsers = (emails) =>
    _.split(emails, ',').map((email) => ({
      email,
      status: NewChargePointGroupUserStatus.QUEUED,
    }));

  const stopAddingChargePointGroupUsers = () => addingQueuedNewUsersObservable?.unsubscribe();

  const addNewChargePointGroupUsers = (newChargePointGroupUsers, chargePointGroupId) => {
    const queuedNewChargePointGroupUsers = newChargePointGroupUsers.filter((user) => user.status === NewChargePointGroupUserStatus.QUEUED);

    addingQueuedNewUsersObservable = from(queuedNewChargePointGroupUsers)
      .pipe(
        concatMap((queuedNewChargePointGroupUser) => {
          queuedNewChargePointGroupUser.status = NewChargePointGroupUserStatus.ADDING;
          dispatch({
            newChargePointGroupUsers: newChargePointGroupUsers,
          });

          return chargePointGroupService.addUser(chargePointGroupId, queuedNewChargePointGroupUser.email).pipe(
            tap((response) => {
              // we only update the status when status transitions from ADDING
              if (queuedNewChargePointGroupUser.status === NewChargePointGroupUserStatus.ADDING) {
                queuedNewChargePointGroupUser.status = NewChargePointGroupUserStatus.ADDED;

                // update the new charge group users
                // we do it here instead of in finalize because finalize is triggered after complete
                dispatch({
                  newChargePointGroupUsers: newChargePointGroupUsers,
                });
              }

              // TODO This is a work around, we need to find a proper way to terminate the observable ajax
              throw Error();
            }),
            catchError((error) => {
              // we only update the status when status transitions from ADDING
              if (queuedNewChargePointGroupUser.status === NewChargePointGroupUserStatus.ADDING) {
                queuedNewChargePointGroupUser.status = error instanceof ConflictException ? NewChargePointGroupUserStatus.WARNING : NewChargePointGroupUserStatus.ERROR;
                queuedNewChargePointGroupUser.message = error.message;

                // update the new charge group users
                // we do it here instead of in finalize because finalize is triggered after complete
                dispatch({
                  newChargePointGroupUsers: newChargePointGroupUsers,
                });
              }

              // we need to return something so the stream keeps going
              return EMPTY;
            })
          );
        })
      )
      .subscribe(
        (next) => {},
        (error) => {},
        () => {
          addEvent({
            type: ChargePointGroupUserListEvent.COMPLETE_ADDING_USERS_TO_CHARGE_POINT_GROUP,
            payload: {newChargePointGroupUsers},
          });
        }
      );
  };

  const addEvent = (event) => {
    switch (event.type) {
      case ChargePointGroupUserListEvent.LOAD_CHARGE_POINT_GROUP_USER_LIST:
        chargePointGroupService.getUsers(event.payload.chargePointGroup.id).subscribe(
          (result) =>
            dispatch({
              chargePointGroup: event.payload.chargePointGroup,
              loadingState: loadingContainerSucceededState,
              chargePointGroupUserList: result,
              flowState: ChargePointGroupUserListFlowState.CHARGE_POINT_GROUP_USER_LIST_OBTAINED,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              chargePointGroup: event.payload.chargePointGroup,
              flowState: ChargePointGroupUserListFlowState.FAILED_TO_LOAD_CHARGE_POINT_GROUP_USER_LIST,
            })
        );
        break;

      case ChargePointGroupUserListEvent.SHOW_ADD_CHARGE_POINT_GROUP_USERS_FORM:
        dispatch({
          flowState: ChargePointGroupUserListFlowState.SHOWING_ADD_CHARGE_POINT_GROUP_USERS_FORM,
          newChargePointGroupUsers: [],
        });
        break;

      case ChargePointGroupUserListEvent.SHOW_REMOVE_CHARGE_POINT_GROUP_USER_DIALOG:
        dispatch({
          removedChargePointGroupUser: null,
          chargePointGroupUserErrorMessage: null,
          chargePointGroupUser: event.payload.chargePointGroupUser,
          flowState: ChargePointGroupUserListFlowState.SHOWING_REMOVE_CHARGE_POINT_GROUP_USER_FORM,
        });
        break;

      case ChargePointGroupUserListEvent.CLOSE_CHARGE_POINT_GROUP_USERS_FORM:
        // 1. if we haven't started adding users
        if (event.payload.flowState === ChargePointGroupUserListFlowState.SHOWING_ADD_CHARGE_POINT_GROUP_USERS_FORM) {
          dispatch({
            flowState: ChargePointGroupUserListFlowState.CHARGE_POINT_GROUP_USER_LIST_OBTAINED,
          });
          break;
        }

        // 2. if we're still in middle of adding charge point group users process then pause the process and show the confirmation dialog
        if (event.payload.flowState === ChargePointGroupUserListFlowState.ADDING_USERS_TO_CHARGE_POINT_GROUP) {
          // stop adding users
          stopAddingChargePointGroupUsers();

          // turn all "adding" and "queued" users into "paused"
          event.payload.newChargePointGroupUsers
            .filter((chargePointGroupUser) => _.includes(incompleteNewChargePointGroupUserStatusList, chargePointGroupUser.status))
            .forEach((incompleteNewChargePointGroupUser) => {
              incompleteNewChargePointGroupUser.status = NewChargePointGroupUserStatus.PAUSED;
            });

          // show the confirmation dialog in case the customer accidentally closes the add charge point group users form
          dispatch({
            flowState: ChargePointGroupUserListFlowState.SHOWING_CONFIRM_CANCELLING_ADD_USERS_TO_CHARGE_POINT_GROUP,
            newChargePointGroupUsers: event.payload.newChargePointGroupUsers,
          });
          break;
        }

        // 3. if we have completed adding users then refresh the user list
        if (event.payload.flowState === ChargePointGroupUserListFlowState.COMPLETED_ADDING_USERS_TO_CHARGE_POINT_GROUP) {
          // this will trigger a refresh
          dispatch({flowState: ChargePointGroupUserListFlowState.ADDED_USERS_TO_CHARGE_POINT_GROUP});
          break;
        }

        throw Error(`Closing form in an invalid flow state ${event.payload.flowState}`);
      case ChargePointGroupUserListEvent.CLOSE_REMOVE_CHARGE_POINT_GROUP_USER_DIALOG:
        dispatch({
          flowState: ChargePointGroupUserListFlowState.CHARGE_POINT_GROUP_USER_LIST_OBTAINED,
        });
        break;

      case ChargePointGroupUserListEvent.SUBMIT_REMOVE_CHARGE_POINT_GROUP_USER_DIALOG:
        dispatch({
          flowState: ChargePointGroupUserListFlowState.REMOVING_CHARGE_POINT_GROUP_USER,
        });

        chargePointGroupService.removeUser(event.payload.chargePointGroupId, event.payload.email).subscribe(
          (result) =>
            dispatch({
              removedChargePointGroupUser: result,
              flowState: ChargePointGroupUserListFlowState.CHARGE_POINT_GROUP_USER_REMOVED,
            }),
          (error) =>
            dispatch({
              flowState: ChargePointGroupUserListFlowState.FAILED_TO_REMOVE_CHARGE_POINT_GROUP_USER,
              chargePointGroupUserErrorMessage: error.message,
            })
        );
        break;

      case ChargePointGroupUserListEvent.ADD_USERS_TO_CHARGE_POINT_GROUP:
        const sortedQueuedNewChargePointGroupUsers = buildSortedQueuedNewChargePointGroupUsers(event.payload.emails);

        dispatch({
          flowState: ChargePointGroupUserListFlowState.ADDING_USERS_TO_CHARGE_POINT_GROUP,
          newChargePointGroupUsers: sortedQueuedNewChargePointGroupUsers,
        });

        addNewChargePointGroupUsers(sortedQueuedNewChargePointGroupUsers, event.payload.chargePointGroup.id);
        break;

      case ChargePointGroupUserListEvent.COMPLETE_ADDING_USERS_TO_CHARGE_POINT_GROUP:
        stopAddingChargePointGroupUsers();

        // unsubscribe adding
        event.payload.newChargePointGroupUsers
          .filter((user) => _.includes(incompleteNewChargePointGroupUserStatusList, user.status))
          .forEach((unfinishedUser) => {
            unfinishedUser.status = NewChargePointGroupUserStatus.CANCELLED;
          });

        dispatch({
          flowState: ChargePointGroupUserListFlowState.COMPLETED_ADDING_USERS_TO_CHARGE_POINT_GROUP,
          newChargePointGroupUsers: event.payload.newChargePointGroupUsers,
        });
        break;

      case ChargePointGroupUserListEvent.RESUME_ADDING_USERS_TO_CHARGE_POINT_GROUP:
        event.payload.newChargePointGroupUsers
          .filter((user) => user.status === NewChargePointGroupUserStatus.PAUSED)
          .forEach((queuedUser) => {
            queuedUser.status = NewChargePointGroupUserStatus.QUEUED;
          });

        dispatch({
          flowState: ChargePointGroupUserListFlowState.ADDING_USERS_TO_CHARGE_POINT_GROUP,
          newChargePointGroupUsers: event.payload.newChargePointGroupUsers,
        });

        addNewChargePointGroupUsers(event.payload.newChargePointGroupUsers, event.payload.chargePointGroup.id);
        break;

      default:
        console.log(event.type);
        throw new Error(`Unhandled event: ${event}`);
    }
  };
  return {
    state,
    addEvent,
  };
};
export default useChargePointGroupUserList;
