import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  spacedBox: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const SpacedBox = ({children}) => {
  const classes = useStyles(mainTheme);

  return <Box classes={{root: classes.spacedBox}}>{children}</Box>;
};

SpacedBox.propTypes = {};

export default SpacedBox;
