import { CpoChargePoint } from '../charger/Charger';
import { Osp } from '../Osp';

export enum LocationType {
  HOME = 'HOME',
  WORK = 'WORK',
  PUBLIC = 'PUBLIC'
}

export type EmspLocation = {
  id: string;
  name: string;
  address: string;
  city: string;
  latitude: number;
  longitude: number;
  currency: string;
  operator: string;
  decommissioned: boolean;
  owner: string;
  postCode: string;
  electricityCost: number;
  cpoLocationId: string;
  cpoCountryCode: string;
  cpoPartyId: string;
  status: string;
  locationType: LocationType;
};

export type CpoLocation = {
  id: string;
  cpoCountryCode: string;
  cpoPartyId: string;
  mspCountryCode: string;
  mspPartyId: string;
  publish: boolean;
  name: string;
  address: string;
  postalCode: string;
  state: string;
  city: string;
  latitude: number;
  longitude: number;
  timeZone: string;
  public: boolean;
  ownerName: string;
  chargePoints: CpoChargePoint[];
  country: string;
  visibleToOsps: Osp[];
};

export type EmspLocationIdentifier = {
  cpoCountryCode: string;
  cpoPartyId: string;
  locationId: string;
};
