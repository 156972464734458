import * as React from 'react';
import { AccountDetailsProvider } from './useAccountDetails';

export const withAccountDetails =
  <T extends Record<string, unknown>>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: T) =>
    (
      <AccountDetailsProvider>
        <Component {...props} />
      </AccountDetailsProvider>
    );
