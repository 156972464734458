import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { DASHBOARD_PATH } from '../../routes/paths';
import AccountInfoPanel from './AccountInfoPanel/AccountInfoPanel';
import { EmspAccount } from '../../types/account/Account';
import AccountAdminListPanel from './AccountAdminsListPanel/AccountAdminsListPanel';
import AccountUsersListPanel from './AccountUsersListPanel/AccountUsersListPanel';
import AccountTransactionListPanel from './AccountTransactionListPanel/AccountTransactionListPanel';

interface AccountDetailsTabsProps {
  account: EmspAccount;
}

const AccountDetailsTabs: FC<AccountDetailsTabsProps> = ({ account }) => {
  const { pathname } = useLocation();
  const accountId = account.id;
  const detailsPath = `${DASHBOARD_PATH}/accounts/${accountId}/details`;
  const transactionsPath = `${DASHBOARD_PATH}/accounts/${accountId}/transactions`;
  const usersPath = `${DASHBOARD_PATH}/accounts/${accountId}/users`;
  const adminsPath = `${DASHBOARD_PATH}/accounts/${accountId}/admins`;
  return (
    <>
      <TabContext value={pathname}>
        <TabList aria-label="Account Tabs" indicatorColor="primary" textColor="primary">
          <Tab label="Details" value={detailsPath} to={detailsPath} component={Link} />
          <Tab label="Transactions" value={transactionsPath} to={transactionsPath} component={Link} />
          <Tab label="Users" value={usersPath} to={usersPath} component={Link} />
          <Tab label="Admins" value={adminsPath} to={adminsPath} component={Link} />
        </TabList>
        <TabPanel value={detailsPath}>
          <AccountInfoPanel account={account} />
        </TabPanel>
        <TabPanel value={transactionsPath}>
          <AccountTransactionListPanel accountId={accountId} />
        </TabPanel>
        <TabPanel value={usersPath}>
          <AccountUsersListPanel account={account} />
        </TabPanel>
        <TabPanel value={adminsPath}>
          <AccountAdminListPanel account={account} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default AccountDetailsTabs;
