import MaterialTableWrapper from '../../commons/table/MaterialTableWrapper';
import React from 'react';
import useUserGroups from './hook/useUserGroups';
import {useLocation} from 'react-router';
import dateTimeFormatter from '../../utils/date/dateTimeFormatter';
import _ from 'lodash';
import NavigationLink from '../../commons/link/NavigationLink';

const UserGroupsTable = () => {
  const {state} = useUserGroups();
  const location = useLocation();

  return (
    <>
      <MaterialTableWrapper
        columns={[
          {
            title: 'Id',
            field: 'id',
            width: 'auto',
            render: (rowData) => <NavigationLink path={`${location.pathname}/${rowData.id}`} text={rowData.id} />,
          },
          {title: 'Name', field: 'name', width: 'auto'},
          {
            title: 'Created',
            field: 'created_at',
            width: 'auto',
            render: (rowData) => dateTimeFormatter.printReadableDateTime(rowData.created_at),
            customFilterAndSearch: (filter, rowData) => _.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.created_at), filter),
          },
          {
            title: 'Created',
            field: 'readableCreated',
            hidden: true,
            export: true,
          },
        ]}
        data={state.userGroupList.map((userGroup) => ({
          id: userGroup.id,
          name: userGroup.name,
          created_at: userGroup.created,
          readableCreated: dateTimeFormatter.printReadableDateTime(userGroup.created),
        }))}
        exportFileName="User Fleets"
      />
    </>
  );
};

export default UserGroupsTable;
