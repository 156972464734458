import { Button } from "@material-ui/core";
const CreateButton = ({ formikProps }) => {
    return (
        <Button type='submit' variant='contained' color='primary' disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>
            Create
        </Button>

    );
}

export default CreateButton;