export const getModifiedValues = (values, initialValues) => {
    let modifiedValues = {};

    if (values) {
        Object.entries(values).forEach((entry) => {
            let key = entry[0];
            let value = entry[1];

            if (value !== initialValues[key]) {
                modifiedValues[key] = value;
            }
        });
    }

    return modifiedValues;
};

export const handleCoordinates = (modifiedValues, values) => {
    if (Object.keys(modifiedValues).includes('latitude') || Object.keys(modifiedValues).includes('longitude')) {
        return {
            latitude: modifiedValues.latitude || values.latitude,
            longitude: modifiedValues.longitude || values.longitude,
        }
    }
    return null
}