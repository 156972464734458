import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';

const SmartSelectField = ({ field, label, helperText, fullWidth, options, form, displayEmpty, ...rest }) => {
  const selectId = `select-${uuidV4()}`;
  const labelId = `${selectId}-label`;


  return (
    <FormControl fullWidth={fullWidth} disabled={form.isSubmitting}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        inputProps={{
          ...field,
        }}
        {...rest}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}

        displayEmpty={displayEmpty}
      >
        {displayEmpty && <MenuItem value={null}>
          <em>None</em>
        </MenuItem>}
        {options.map((optionEntry) => (
          <MenuItem key={`${selectId}-${optionEntry.value}`} value={optionEntry.value}>
            {optionEntry.displayValue}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

SmartSelectField.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  options: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
};

export default SmartSelectField;
