import { useContext } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import {
  ChargerTransactionsEvent,
  ChargerTransactionsEventType,
  ChargerTransactionsFlowState,
  UseChargerTransactionsType
} from '../../../../types/charger/ChargerTransactions';
import { ChargerTransactionsContext } from './ChargerTransactionsProvider';
import transactionService from '../../../../services/transactionService';

// hook
const useChargerTransactions = (): UseChargerTransactionsType & { ChargerTransactionsEventType: typeof ChargerTransactionsEvent } => {
  const { state, dispatch } = useContext(ChargerTransactionsContext);
  const addEvent = (event: ChargerTransactionsEventType) => {
    const payload = event.payload;
    switch (event.type) {
      case ChargerTransactionsEvent.LOAD_CHARGER_TRANSACTIONS:
        dispatch({
          ...state,
          loadingState: loadingContainerInitialState,
          flowState: ChargerTransactionsFlowState.LOADING_TRANSACTIONS_TABLE
        });
        transactionService.getTransactionsByChargePointIdForAdmin(payload.chargePointId).subscribe(
          (transactions) => {
            dispatch({
              transactions,
              flowState: ChargerTransactionsFlowState.CHARGER_TRANSACTIONS_LOADED,
              loadingState: loadingContainerSucceededState
            });
          },
          (error) => {
            dispatch({
              ...state,
              flowState: ChargerTransactionsFlowState.ERROR,
              loadingState: loadingContainerFailedState
            });
          }
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
    ChargerTransactionsEventType: ChargerTransactionsEvent
  };
};
export default useChargerTransactions;
