import { Box, Step, StepLabel, Stepper, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { ChargerRemoteStartFlowState } from '../../../types/charger/ChargerRemoteStartState';
import ChargerRemoteStartForm from './RemoteStartTransactionForms/ChargerRemoteStartForm';
import useRemoteStartTransactions from './hook/useRemoteStartTransactions';
import { withRemoteStartTransactions } from './hook/withRemoteStartTransactions';

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    backgroundColor: 'transparent'
  }
}));

export interface ChargerRemoteStartContainerProps {
  displayName: string;
  locationId: string;
  countryCode: string;
  partyId: string;
}

const ChargerRemoteStartContainer: FC<ChargerRemoteStartContainerProps> = ({ displayName, locationId, countryCode, partyId }) => {
  const { state } = useRemoteStartTransactions();
  const steps = ['Check Eligibility', 'Choose Connector', 'Review'];
  const classes = useStyles();

  const stateToStepMap: Record<ChargerRemoteStartFlowState, number> = {
    [ChargerRemoteStartFlowState.INIT]: 0,
    [ChargerRemoteStartFlowState.CHECKING_ELIGIBILITY]: 0,
    [ChargerRemoteStartFlowState.NOT_ELIGIBLE]: 0,
    [ChargerRemoteStartFlowState.ELIGIBLE]: 1,
    [ChargerRemoteStartFlowState.CONNECTORS_LOADED]: 1,
    [ChargerRemoteStartFlowState.REVIEW]: 2,
    [ChargerRemoteStartFlowState.TRANSACTION_STARTING]: 3,
    [ChargerRemoteStartFlowState.TRANSACTION_STARTED]: 3,
    [ChargerRemoteStartFlowState.FAILED_TO_START]: 3
  };

  const activeStep = stateToStepMap[state.flowState] || 0;

  return (
    <Box>
      <Stepper activeStep={activeStep} className={classes.stepperContainer}>
        {steps.map((label) => {
          const stepProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <ChargerRemoteStartForm countryCode={countryCode} partyId={partyId} locationId={locationId} displayName={displayName} />
    </Box>
  );
};

export default withRemoteStartTransactions(ChargerRemoteStartContainer);
