import React from 'react';
import * as PropTypes from 'prop-types';
import {Autocomplete} from '@material-ui/lab';
import _ from 'lodash';
import {useField} from 'formik';
import {TextField} from '@material-ui/core';

const SmartAutoCompleteField = ({label, form, field, helperText, defaultValue, options}) => {
  const meta = useField(field.name)[1];
  const helpers = useField(field.name)[2];

  return (
    <>
      <Autocomplete
        disabled={form.isSubmitting}
        disableClearable
        autoHighlight
        openOnFocus
        defaultValue={defaultValue}
        options={options}
        getOptionLabel={(option) => option.displayValue}
        getOptionSelected={(option, value) => _.isEqual(option, value)}
        renderInput={(params) => <TextField error={!_.isBlankString(meta.error)} name={field.name} label={label} helperText={meta.error ?? helperText} {...params} />}
        onChange={(event, option) => {
          helpers.setValue(option?.value);
        }}
      />
    </>
  );
};

SmartAutoCompleteField.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  defaultValue: PropTypes.object,
  options: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
};

export default SmartAutoCompleteField;
