import { EmspOrganisation, EmspOrganisationAdmin } from '../../types/organisation/Organisation';
import { EmspOrganisationAdminDto, EmspOrganisationDto } from '../../types/organisation/OrganisationDto';

export const toEmspOrganisationDto = (response: EmspOrganisation): EmspOrganisationDto => {
  return {
    id: response.id,
    name: response.name,
    website: response.website,
    logo: response.logo,
    admin_ids: response.adminIds,
    user_ids: response.userIds
  };
};

export const toEmspOrganisationAdminDto = (response: EmspOrganisationAdmin): EmspOrganisationAdminDto => {
  return {
    id: response.id,
    name: response.name,
    email: response.email
  };
};
