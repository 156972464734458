import React from 'react';
import Dashboard from '../Dashboard/Dashboard';
import paths from '../../routes/paths';
import { useRouteMatch } from 'react-router';
import RemoteStartTransactionContainer from './RemoteStartTransactionContainer';

const RemoteStartTransaction = () => {
  const match = useRouteMatch(paths.REMOTE_START_LEGACY);
  const chargePointId = match.params.chargePointId;

  return (
    <Dashboard pageHeading={`Start Remote Transaction for ${chargePointId}`}>
      <RemoteStartTransactionContainer chargePointId={chargePointId} />
    </Dashboard>
  );
};

export default RemoteStartTransaction;
