import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { SuccessBar } from '../../commons/snackbars/snackbars';
import ChargePointsTable from './ChargePointsTable';
import useChargePoints, { ChargePointEvent, ChargePointFlowState } from './hook/useChargePoints';
import { withChargePoints } from './hook/withChargePoints';

const ChargePointList = ({ chargePointGroupId }) => {
  const { state, addEvent } = useChargePoints();

  // showLocationCreatedSuccessBar would be set to true in the ReviewChargePointForm component when a new location/charge point has been created
  const location = useLocation();
  const showSuccessBar = location.showLocationCreatedSuccessBar;

  useEffect(() => {
    if (state.flowState === ChargePointFlowState.INIT || state.flowState === ChargePointFlowState.CHARGE_POINT_UPDATED) {
      addEvent({ type: ChargePointEvent.LOAD_CHARGE_POINTS, payload: { chargePointGroupId } });
    }
  }, [state.flowState, addEvent, chargePointGroupId]);

  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        <ChargePointsTable />
      </LoadingContainerWithErrorPanel>
      <SuccessBar open={state.updatedChargePointId}>Charge point "{state.updatedChargePointId}" has been updated!</SuccessBar>
      <SuccessBar open={showSuccessBar} >
        New Charge Point has been created!
      </SuccessBar>
    </>
  );
};

ChargePointList.propTypes = {
  chargePointGroupId: PropTypes.string,
};

export default withChargePoints(ChargePointList);
