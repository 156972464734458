import React, {createRef, useEffect} from 'react';
import useRemoteStartTransactions, {RemoteStartEvent, RemoteStartFlowState} from '../hook/useRemoteStartTransactions';
import * as Yup from 'yup';
import {Field, Form, Formik} from 'formik';
import SmartTextField from '../../../commons/form/SmartTextField';
import {Box, Button, makeStyles} from '@material-ui/core';
import {ErrorBar, ProcessingBar} from '../../../commons/snackbars/snackbars';
import _ from 'lodash';
import mainTheme from '../../../themes/mainTheme';
import PropTypes from 'prop-types';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
}));

const RemoteStartPreviewForm = ({chargePointId}) => {
  const classes = useStyles(mainTheme);
  const {state, addEvent} = useRemoteStartTransactions();
  const formikRef = createRef();
  
  useEffect(() => {
    if (state.flowState === RemoteStartFlowState.NOT_ELIGIBLE) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.flowState, formikRef]);
  
  const initialValues = {
    email: '',
  };
  
  const validationSchema = Yup.object({
    email: Yup.string().required('Required').email('Please enter a valid email'),
  });
  
  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={(values) => {
          addEvent({
            type: RemoteStartEvent.PREVIEW_SUBMITTED,
            payload: {
              locationId: chargePointId,
              email: values.email,
            },
          });
        }}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <PaperWithTitle title="User">
              <Field label="Email" name="email" autoFocus component={SmartTextField} helperText="Email of user to start transaction for e.g. johndoe@countiesenergy.co.nz" fullWidth/>
            </PaperWithTitle>
            <Box className={classes.submitButtonContainer}>
              <Button type="submit" variant="contained" color="primary" className={classes.submitButton} disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}>
                Next
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <ErrorBar open={!_.isNull(state.errorMessage)}>{state.errorMessage}</ErrorBar>
      <ProcessingBar open={state.flowState === RemoteStartFlowState.CHECKING_ELIGIBILITY} text={'Checking user eligibility..'}/>
    </>
  );
};

RemoteStartPreviewForm.propTypes = {
  chargePointId: PropTypes.string.isRequired,
};

export default RemoteStartPreviewForm;
