import React from 'react';
import {useRouteMatch} from 'react-router';
import paths from '../../routes/paths';
import Dashboard from '../Dashboard/Dashboard';
import TransactionInfoPanel from './TransactionInfoPanel';

const TransactionDetails = () => {
  const match = useRouteMatch(paths.TRANSACTION_DETAILS);
  const transactionId = match.params.transactionId;
  
  return (
    <Dashboard pageHeading={'Transaction Summary'}>
      <TransactionInfoPanel transactionId={transactionId}/>
    </Dashboard>
  );
};

export default TransactionDetails;
