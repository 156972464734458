import {Observable, throwError} from 'rxjs';
import {mergeMap, retryWhen, tap} from 'rxjs/operators';
import {ajax} from 'rxjs/ajax';
import appConfig from '../config/appConfig';
import tokenStorage from '../storage/tokenStorage';

const _getAccessToken = () => tokenStorage.get().accessToken;
export const loadAccessTokenObservable = () => new Observable((subscriber) => subscriber.next(_getAccessToken()));

const _getRefreshToken = () => tokenStorage.get().refreshToken;
export const refreshAccessTokenIfExpired = () => (source) =>
  source.pipe(
    retryWhen((errors) =>
      errors.pipe(
        mergeMap((error, i) => {
          const retryAttempt = i + 1;
          // if we have tried 3 times already, throw error
          if (retryAttempt > 2) {
            return throwError(error);
          }
          // retry if accessToken has expired or the call is a 401
          if (error.status === 401) {
            // unauthorized maybe due to access token expired
            return ajax({
              method: 'POST',
              url: `${appConfig.emspApiDomain}/auth/oauth/refresh-access-token`,
              headers: {
                // FIXME this is wrong since refreshToken is not a json object, but DO NOT REMOVE THIS because
                // if we don't declare content type as application/json, ajax rxjs will submit refreshToken as a form data
                // which it will append an equal sign "=" at the end of the value causing the token to be invalid.
                // we need to find a way to send raw data back
                'content-type': 'application/json',
                accept: 'application/json',
              },
              body: _getRefreshToken(),
            }).pipe(
              tap((response) => {
                console.log('SUCCEEDED: Refresh access token');
                const token = tokenStorage.get();
                token.idToken = response.response.idToken;
                token.accessToken = response.response.accessToken;
                token.expiresIn = response.response.expiresIn;
                tokenStorage.save(token);
              })
            );
          }

          // Error status code was not unauthorized .: don't retry, just propagate the error
          return throwError(error);
        })
      )
    )
  );
