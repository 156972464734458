import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { SuccessBar } from '../../commons/snackbars/snackbars';
import { AdminPermission, requiresAdminPermission } from '../../hooks/useAdminPermissions';
import paths from '../../routes/paths';
import { NewOrganisationFlowState } from '../../types/organisation/OrganisationState';
import Dashboard from '../Dashboard/Dashboard';
import OrganisationDetailsTabs from './OrganisationDetailsTabs';
import useOrganisationDetails, { OrganisationDetailsEvent, OrganisationDetailsFlowState } from './hook/useOrganisationDetails';
import { withOrganisationDetails } from './hook/withOrganisationDetails';

export interface OrganisationDetailsMatchParams {
  organisationId: string;
}

type NewOrganisationDetailsState = {
  showOrganisationCreatedSuccessBar: boolean;
};

const OrganisationDetails = () => {
  const { state, addEvent } = useOrganisationDetails();
  const match = useRouteMatch<OrganisationDetailsMatchParams>([
    paths.ORGANISATION_DETAILS,
    paths.ORGANISATION_ADMINS,
    paths.ORGANISATION_ACCOUNTS,
    paths.ORGANISATION_CHARGERS
  ]);
  const organisationId = match?.params.organisationId;
  const location = useLocation<NewOrganisationDetailsState>();
  useEffect(() => {
    if (state.flowState === OrganisationDetailsFlowState.INIT || state.flowState === NewOrganisationFlowState.NEW_ORGANISATION_CREATED) {
      addEvent({ type: OrganisationDetailsEvent.LOAD_ORGANISATION, payload: { id: organisationId } });
    }
  }, [state.flowState, addEvent, organisationId]);

  return (
    <>
      <Dashboard pageHeading={state.organisation?.name}>
        <LoadingContainerWithErrorPanel {...state.loadingState}>
          {state.organisation && <OrganisationDetailsTabs organisation={state.organisation} />}
        </LoadingContainerWithErrorPanel>
      </Dashboard>
      <SuccessBar
        fullPage={false}
        open={location.state?.showOrganisationCreatedSuccessBar}
        onClose={() => window.history.replaceState({ showOrganisationCreatedSuccessBar: false }, '')}
      >
        New Organisation {state.organisation?.name ?? ''} has been created.
      </SuccessBar>
    </>
  );
};

export default requiresAdminPermission(AdminPermission.ORG_ADMIN)(withOrganisationDetails(OrganisationDetails));
