import React, {createContext, useContext, useReducer} from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState,
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import _ from 'lodash';
import transactionService from '../../../../services/transactionService';

// events
export const UserTransactionsEvent = {
  PAGE_ENTERED: 'PAGE_ENTERED',
};

// flow states
export const UserTransactionsFlowState = {
  INIT: 'INIT',
  SHOWING_USER_TRANSACTIONS: 'SHOWING_USER_TRANSACTIONS',
  ERROR: 'ERROR',
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  flowState: UserTransactionsFlowState.INIT,
  transactions: null,
};

// reducer
const reducer = (state, newState) => ({...state, ...newState});

// context
const userTransactionsContext = createContext();

// provider
export const UserTransactionsProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <userTransactionsContext.Provider value={{state, dispatch}}>{children}</userTransactionsContext.Provider>
  );
};

// hook
const useUserTransactionsDetails = () => {
  const {state, dispatch} = useContext(userTransactionsContext);

  const addEvent = (event) => {
    switch (event.type) {
      case UserTransactionsEvent.PAGE_ENTERED:
        transactionService.getTransactionsByUserIdForAdmin(event.userId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: UserTransactionsFlowState.SHOWING_USER_TRANSACTIONS,
              transactions: result,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: UserTransactionsFlowState.ERROR,
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
  };
};

export default useUserTransactionsDetails;
