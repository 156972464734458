import React from 'react';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import CreateChargePointGroupForm from './CreateChargePointGroupForm';
import useChargePointGroups from './hook/useChargePointGroups';

const CreateChargePointGroupFormContainer = () => {
  const {state} = useChargePointGroups();

  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      <CreateChargePointGroupForm />
    </LoadingContainerWithErrorPanel>
  );
};

export default CreateChargePointGroupFormContainer;
