import _ from 'lodash';
import BoxWithLabel from '../../../commons/containers/ComponentWithLabel/BoxWithLabel';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import EditButton from '../../../commons/form/button/EditButton';
import { LocationDetailsFlowState } from '../../../types/LocationDetails';
import { LocationType } from '../../../types/location/Location';
import LocationStatusDisplay from '../LocationStatusDisplay';
import { LocationEvent } from '../hook/useLocations';

const BasicConfigView = ({ emspLocation, state, addEvent }) => {
  const handleEdit = () => {
    addEvent({
      type: LocationEvent.SHOW_UPDATE_BASIC_CONFIG_FORM
    });
  };

  const isOpenLoopLocation = emspLocation?.cpoCountryCode === 'NZ' && emspLocation.cpoPartyId === 'OPL';

  const shouldDisableEdit =
    state.flowState === LocationDetailsFlowState.SHOWING_UPDATE_LOCATION_FORM || state.flowState === LocationDetailsFlowState.UPDATING_LOCATION;

  return (
    <PaperWithTitle
      title="Basic Configuration"
      topRightComponent={emspLocation && isOpenLoopLocation ? <EditButton handleEdit={handleEdit} disabled={shouldDisableEdit} /> : null}
      bottomRightComponent={null}
    >
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        {emspLocation && (
          <>
            <TextWithLabel label="Operator" text={emspLocation.operator} />
            {emspLocation.locationType !== LocationType.PUBLIC && (
              <TextWithLabel label="Location Type" text={`${_.capitalize(emspLocation.locationType)}`} />
            )}
            {isOpenLoopLocation && <TextWithLabel label="Electricity Cost" text={`$${emspLocation.electricityCost.toFixed(2)}/kWh`} />}
            {emspLocation.decommissioned && (
              <BoxWithLabel label="Status">
                <LocationStatusDisplay decommissioned={emspLocation.decommissioned} />
              </BoxWithLabel>
            )}
          </>
        )}
      </LoadingContainerWithErrorPanel>
    </PaperWithTitle>
  );
};

export default BasicConfigView;
