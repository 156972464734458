import {extendMoment} from 'moment-range';
import Moment from 'moment';

const moment = extendMoment(Moment);

const dateUtils = {
  startOfDay: (date) => moment(date).startOf('day'),

  getPastDays: (lastNumberOfDays) => {
    const now = moment();
    const dateFrom = now.clone().subtract(lastNumberOfDays - 1, 'day');
    const dateTo = now;
    return Array.from(moment.range(dateFrom, dateTo).by('day'));
  },

  dateRangeForLastNumberOfDays: (lastNumberOfDays) => {
    const now = moment();
    return {
      dateFrom: now
        .clone()
        .subtract(lastNumberOfDays - 1, 'days')
        .startOf('day'),
      dateTo: now,
    };
  },
};

export default dateUtils;
