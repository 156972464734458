import React, { useEffect, useState } from 'react';
import { ErrorBar, ProcessingBar, SuccessBar } from '../../../commons/snackbars/snackbars';
import { LocationDetailsFlowState } from '../../../types/LocationDetails';
import { AddEventType } from '../../../types/SharedStates';
import { LocationState } from '../../../types/location/LocationState';
import BasicConfigEditForm from '../AdvancedConfig/BasicConfigEditForm';
import BasicConfigView from '../AdvancedConfig/BasicConfigView';
import LocationEditForm from './LocationEditForm';
import LocationViewForm from './LocationViewForm';

interface LocationViewProps {
  state: LocationState;
  addEvent: AddEventType;
}

const LocationView: React.FC<LocationViewProps> = ({ state, addEvent }) => {
  const [showSuccessBarLocationEdit, setShowSuccessBarLocationEdit] = useState(false);
  const [showSuccessBarAdvancedConfig, setShowSuccessBarAdvancedConfig] = useState(false);

  useEffect(() => {
    if (state.updateLocation && state.flowState === LocationDetailsFlowState.LOCATION_UPDATED) {
      setShowSuccessBarLocationEdit(true);
      setTimeout(() => setShowSuccessBarLocationEdit(false), 3000);
    }
    if (state.updateLocation && state.flowState === LocationDetailsFlowState.BASIC_CONFIG_UPDATED) {
      setShowSuccessBarAdvancedConfig(true);
      setTimeout(() => setShowSuccessBarAdvancedConfig(false), 3000);
    }
  }, [state.flowState, state.updateLocation]);

  const showLocationEdit =
    state.flowState === LocationDetailsFlowState.SHOWING_UPDATE_LOCATION_FORM || state.flowState === LocationDetailsFlowState.UPDATING_LOCATION;
  const showAdvancedConfigEdit = state.flowState === LocationDetailsFlowState.SHOWING_UPDATE_BASIC_CONFIG_FORM && state.emspLocation;

  return (
    <>
      {showLocationEdit ? <LocationEditForm /> : <LocationViewForm addEvent={addEvent} location={state.cpoLocation} state={state} />}
      {showAdvancedConfigEdit ? <BasicConfigEditForm /> : <BasicConfigView emspLocation={state.emspLocation} state={state} addEvent={addEvent} />}
      <ErrorBar fullPage={false} open={state.updateLocationErrorMessage !== null} onClose={() => (state.updateLocationErrorMessage = null)}>
        {state.updateLocationErrorMessage}
      </ErrorBar>
      <SuccessBar fullPage={false} open={showSuccessBarLocationEdit} onClose={() => setShowSuccessBarLocationEdit(false)}>
        Location {state?.updateLocation?.name} has been updated and will be available after a page refresh.
      </SuccessBar>
      <SuccessBar fullPage={false} open={showSuccessBarAdvancedConfig} onClose={() => setShowSuccessBarAdvancedConfig(false)}>
        Advanced configuration for {state?.updateLocation?.name} has been updated.
      </SuccessBar>
      <ProcessingBar open={state.flowState === LocationDetailsFlowState.UPDATING_LOCATION} fullPage={false} />
    </>
  );
};

export default LocationView;
