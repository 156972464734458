import React from 'react';
import { Box } from '@material-ui/core';
import chargeTagImage from '../../../assets/images/openloop_charge_tag.png';
import { makeStyles } from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import PropTypes from 'prop-types';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import BoxWithLabel from '../../../commons/containers/ComponentWithLabel/BoxWithLabel';
import ChargeTagStatus from '../../ChargeTag/ChargeTagStatus';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import Button from '@material-ui/core/Button';
import useUserChargeTags, { UserChargeTagsEvent, UserChargeTagsFlowState } from './hook/useUserChargeTags';
import { WarningDialog } from '../../../commons/dialogs/dialogs';
import _ from 'lodash';
import { ErrorBar, ProcessingBar } from '../../../commons/snackbars/snackbars';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2)
  },
  chargeTagImage: {
    width: 58,
    height: 72,
    marginRight: theme.spacing(2)
  },
  chargeTagAndImage: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px dashed ${theme.palette.divider}`,
    paddingBottom: theme.spacing(0.5),
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0
    }
  },
  chargeTag: {
    display: 'flex',
    flexDirection: 'row'
  },
  addChargeTagButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const UserChargeTagDetails = ({ chargeTags }) => {
  const classes = useStyles(mainTheme);
  const { state, addEvent } = useUserChargeTags();
  const handleClick = () => {
    addEvent({
      type: UserChargeTagsEvent.ADD_CHARGE_TAG_BUTTON_CLICKED
    });
  };
  return (
    <>
      <PaperWithTitle>
        <>
          {chargeTags.map((chargeTag) => (
            <Box classes={{ root: classes.chargeTagAndImage }} key={chargeTag.id}>
              <Box className={classes.chargeTag}>
                <img className={classes.chargeTagImage} src={chargeTagImage} title="OpenLoop Charge Tag" alt="OpenLoop Charge Tag" />
                <Box>
                  <TextWithLabel text={chargeTag.id} label="Id" />
                  <BoxWithLabel label="Status">
                    <ChargeTagStatus active={chargeTag.active} />
                  </BoxWithLabel>
                </Box>
              </Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  addEvent({
                    type: UserChargeTagsEvent.DELETE_CHARGE_TAG_BUTTON_CLICKED,
                    chargeTagIdToDelete: chargeTag.id
                  })
                }
              >
                Delete
              </Button>
            </Box>
          ))}
        </>
      </PaperWithTitle>
      <Box className={classes.addChargeTagButton}>
        <Button onClick={handleClick} classes={{ root: classes.spacedButton }} type="button" variant="contained" color="primary">
          Add Charge Tag
        </Button>
      </Box>
      <WarningDialog
        open={_.includes([UserChargeTagsFlowState.SHOWING_DELETE_CHARGE_TAG_DIALOG], state.flowState)}
        buttonLabel="Delete Charge Tag"
        buttonCallback={() =>
          addEvent({
            type: UserChargeTagsEvent.DELETE_CHARGE_TAG_CONFIRMED,
            userId: state.userId,
            chargeTagIdToDelete: state.chargeTagIdToDelete
          })
        }
        onClose={() => addEvent({ type: UserChargeTagsEvent.DELETE_CHARGE_TAG_DIALOG_CLOSED })}
      >
        Are you sure you want to delete charge tag?
      </WarningDialog>
      <ProcessingBar open={state.flowState === UserChargeTagsFlowState.DELETING_CHARGE_TAG} />
      <ErrorBar open={!_.isBlankString(state.chargeTagErrorMessage)}>{state.chargeTagErrorMessage}</ErrorBar>
    </>
  );
};

UserChargeTagDetails.propTypes = {
  chargeTags: PropTypes.array.isRequired
};

export default UserChargeTagDetails;
