import React, {useState} from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {MoreHoriz} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import * as PropTypes from 'prop-types';
import {v4 as uuidV4} from 'uuid';

const ActionMenu = ({actions}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const actionMenuId = `menu-id-${uuidV4()}`;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreHoriz color="primary" />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {actions.map((action) => (
          <MenuItem key={`${actionMenuId}-${action.name}`} onClick={() => action.onClick()}>
            {action.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

ActionMenu.propTypes = {
  actions: PropTypes.array.isRequired,
};
export default ActionMenu;
