import _ from 'lodash';
import moment from 'moment';
import { createContext, useContext, useReducer } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import transactionService from '../../../services/transactionService';

// events
export const ChargePointFleetTransactionsEvent = {
  PAGE_ENTERED: 'PAGE_ENTERED',
  DATE_RANGE_CHANGED: 'DATE_RANGE_CHANGED'
};

// flow states
export const ChargePointFleetTransactionsFlowState = {
  INIT: 'INIT',
  SHOWING_TRANSACTIONS_TABLE: 'SHOWING_TRANSACTIONS_TABLE',
  ERROR: 'ERROR'
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  transactions: [],
  flowState: ChargePointFleetTransactionsFlowState.INIT,
  dateRange: {
    dateFrom: moment().startOf('month'),
    dateTo: moment()
  }
};

// reducer
const reducer = (state, newState) => ({ ...state, ...newState });

// context
const chargePointFleetTransactionsContext = createContext();

// provider
export const ChargePointFleetTransactionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <chargePointFleetTransactionsContext.Provider value={{ state, dispatch }}>{children}</chargePointFleetTransactionsContext.Provider>
  );
};

// hook
const useChargePointFleetTransactions = () => {
  const { state, dispatch } = useContext(chargePointFleetTransactionsContext);

  const addEvent = (event) => {
    switch (event.type) {
      case ChargePointFleetTransactionsEvent.PAGE_ENTERED:
        transactionService.getTransactionsByOrgsForAdmin(state.dateRange).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              transactions: result,
              flowState: ChargePointFleetTransactionsFlowState.SHOWING_TRANSACTIONS_TABLE
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointFleetTransactionsFlowState.ERROR
            })
        );
        break;
      case ChargePointFleetTransactionsEvent.DATE_RANGE_CHANGED:
        transactionService.getTransactionsByOrgsForAdmin(event.dateRange).subscribe(
          (result) => {
            dispatch({
              loadingState: loadingContainerSucceededState,
              transactions: result,
              flowState: ChargePointFleetTransactionsFlowState.SHOWING_TRANSACTIONS_TABLE,
              dateRange: event.dateRange
            });
          },
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointFleetTransactionsFlowState.ERROR
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useChargePointFleetTransactions;
