import React, {useEffect} from 'react';
import Dashboard from '../Dashboard/Dashboard';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import useChargePointFleetTransactions, {ChargePointFleetTransactionsEvent, ChargePointFleetTransactionsFlowState} from './hook/useChargePointFleetTransactions';
import {withChargePointFleetTransactions} from './hook/withChargePointFleetTransactions';
import TransactionsTable from '../Transaction/TransactionsTable/TransactionsTable';

const ChargePointFleetTransactions = () => {
  const {state, addEvent} = useChargePointFleetTransactions();
  useEffect(() => {
    if (state.flowState === ChargePointFleetTransactionsFlowState.INIT) {
      addEvent({type: ChargePointFleetTransactionsEvent.PAGE_ENTERED});
    }
  }, [state.flowState, addEvent]);

  const onDateRangeChange = (newValue) => {
    addEvent({
      type: ChargePointFleetTransactionsEvent.DATE_RANGE_CHANGED,
      dateRange: newValue,
    });
  };
  return (
    <Dashboard pageHeading="Transactions">
      <>
        <LoadingContainerWithErrorPanel {...state.loadingState}>
          <TransactionsTable
            transactions={state.transactions}
            dateRangeFilter={{dateRange: state.dateRange, onDateRangeChange: onDateRangeChange, maxRangeInMonths: 3}}
            hideOperationalExpenditure={false}
          ></TransactionsTable>
        </LoadingContainerWithErrorPanel>
      </>
    </Dashboard>
  );
};

export default withChargePointFleetTransactions(ChargePointFleetTransactions);
