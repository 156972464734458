import { useEffect } from 'react';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { requiresPermission } from '../../hocs/requiresPermission';
import { FeatureFlag, requiresFeatureFlag } from '../../hooks/useFeatureFlags';
import paths from '../../routes/paths';
import { Permission } from '../../types/user/UserPermission';
import Dashboard from '../Dashboard/Dashboard';
import UserGroupsTable from './UserGroupsTable';
import useUserGroups, { UserGroupEvent, UserGroupFlowState } from './hook/useUserGroups';
import { withUserGroups } from './hook/withUserGroups';

const UserGroups = () => {
  const { state, addEvent } = useUserGroups();
  useEffect(() => {
    if (state.flowState === UserGroupFlowState.INIT) {
      addEvent({ type: UserGroupEvent.LOAD_USER_GROUP_TABLE });
    }
  }, [state.flowState, addEvent]);

  return (
    <Dashboard pageHeading="User Fleets">
      <>
        <LoadingContainerWithErrorPanel {...state.loadingState}>
          <UserGroupsTable />
        </LoadingContainerWithErrorPanel>
      </>
    </Dashboard>
  );
};

export default requiresFeatureFlag(FeatureFlag.LEGACY_CHARGE_POINT_MANAGEMENT, { redirectTo: paths.ACCOUNTS })(
  requiresPermission(Permission.MANAGE_USER_GROUP)(withUserGroups(UserGroups))
);
