import { Box } from '@material-ui/core';
import React from 'react';
import { Bar } from 'react-chartjs-2';

interface BarChartProps {
  labels: string[];
  values: number[];
  colors: string[];
  height: number;
}

const BarChart: React.FC<BarChartProps> = ({ labels, values, colors, height }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        barPercentage: values.length > 40 ? 0.9 : 0.3
      }
    ]
  };

  return (
    <Box>
      <Bar
        data={data}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false
            }
          }

          //  barPercentage: 0.3
        }}
        height={height}
      />
    </Box>
  );
};

export default BarChart;
