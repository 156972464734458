import React from 'react';
import PropTypes from 'prop-types';
import PaperWithTitle from '../../commons/containers/PaperWithTile/PaperWithTitle';
import TextWithLabel from '../../commons/containers/ComponentWithLabel/TextWithLabel';
import BoxWithLabel from '../../commons/containers/ComponentWithLabel/BoxWithLabel';
import ChargePointStatus from '../ChargePointStatus/ChargePointStatus';

const ChargePointDecommissionedPanel = ({chargePoint}) => (
  <>
    <PaperWithTitle title="General">
      <>
        <TextWithLabel label="Id" text={chargePoint.id} />
        <TextWithLabel label="Charge Point Fleet" text={chargePoint.groupName} />
        <TextWithLabel label="Address" text={chargePoint.address} />
        <BoxWithLabel label="Status">
          <ChargePointStatus status={chargePoint.status} />
        </BoxWithLabel>
      </>
    </PaperWithTitle>
  </>
);

ChargePointDecommissionedPanel.propTypes = {
  chargePoint: PropTypes.object.isRequired,
};

export default ChargePointDecommissionedPanel;