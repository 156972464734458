export enum AccountTransactionEvent {
  LOAD_ACCOUNT_TRANSACTIONS = 'LOAD_ACCOUNT_TRANSACTIONS'
}

interface LoadAccountTransactionsEvent {
  type: AccountTransactionEvent.LOAD_ACCOUNT_TRANSACTIONS;
  payload: { accountId: string };
}

export type AccountTransactionEventTypes = LoadAccountTransactionsEvent;
