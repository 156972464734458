import { Box, Card, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { FcEngineering } from 'react-icons/fc';
import mainTheme from '../../themes/mainTheme';

const useStyles = makeStyles(() => ({
  dialog: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0, 0.5)',
    zIndex: 2,
    backdropFilter: 'blur(8px)'
  },
  overlay: {
    position: 'absolute',
    height: 'fit-content',
    top: '40%',
    left: '30%',
    zIndex: 10,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
const SimpleMaintenanceOverlay: FC = () => {
  const classes = useStyles(mainTheme);

  return (
    <>
      <Card className={classes.overlay}>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <FcEngineering size={100} />
            </Grid>
            <Grid item xs={9} alignContent="center">
              <Typography variant="h3">Under Maintenance</Typography>
              <Typography variant="body1">The dashboard page is currently under maintenance. We will restore it back soon.</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Box className={classes.dialog}>
        <Typography variant="h3">Under Maintenance</Typography>
      </Box>
    </>
  );
};

export default SimpleMaintenanceOverlay;
