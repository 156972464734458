import {loadAccessTokenObservable, refreshAccessTokenIfExpired} from '../access_token/accessTokenReactive';
import {switchMap} from 'rxjs/operators';

/**
 * Function that takes a callback to execute with a user access token.
 * Implementation will attempt to refresh the user access token if the current token has expired
 * @param callback The callback to invoke with the access token. The callback should return an Observable
 * @returns an Observable
 */
export const withAccessToken = (callback) =>
  loadAccessTokenObservable().pipe(
    switchMap((accessToken) => callback(accessToken)),
    refreshAccessTokenIfExpired()
  );
