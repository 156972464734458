import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import useCentralServerChargePoints, {CentralServerChargePointsEvent, CentralServerChargePointsFlowState} from './hook/useCentralServerChargePoints';
import {withCentralServerChargePoints} from './hook/withCentralServerChargePoints';
import ChargePointGeneralInfo from './ChargePointGeneralInfo';
import CentralServerConnectors from './CentralServerConnectors';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';

const CentralServerChargePoint = ({chargePoint, handleEdit, handleReset, handleRemoteStart}) => {
  const {state, addEvent} = useCentralServerChargePoints();
  const locationId = chargePoint.id;

  useEffect(() => {
    if (state.flowState === CentralServerChargePointsFlowState.INIT || state.flowState === CentralServerChargePointsFlowState.CONNECTORS_UPDATED) {
      addEvent({
        type: CentralServerChargePointsEvent.LOAD_CENTRAL_SERVER_CHARGE_POINT,
        payload: {
          id: locationId,
        },
      });
    }
  }, [locationId, chargePoint, state.flowState, addEvent]);

  const handleConnectorsEdit = () => {
    addEvent({
      type: CentralServerChargePointsEvent.SHOW_EDIT_CHARGE_POINT_CONNECTORS,
    });
  };

  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      {chargePoint && state.chargePoint && locationId && (
        <>
          <ChargePointGeneralInfo chargePoint={{...chargePoint, ...state.chargePoint}} handleEdit={handleEdit} />
          <CentralServerConnectors
            locationId={locationId}
            evses={state.chargePoint.evses}
            onChargePointReset={handleReset}
            onChargePointEdit={handleConnectorsEdit}
            handleRemoteStart={handleRemoteStart}
          />
        </>
      )}
    </LoadingContainerWithErrorPanel>
  );
};

CentralServerChargePoint.propTypes = {
  chargePoint: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleRemoteStart: PropTypes.func.isRequired,
};

export default withCentralServerChargePoints(CentralServerChargePoint);
