import _ from 'lodash';
import { createContext, FC, PropsWithChildren, useContext, useReducer } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import organisationService from '../../../services/organisationService';
import { OrganisationState } from '../../../types/organisation/OrganisationState';
import { AddEventType, EventType } from '../../../types/SharedStates';

// events
export const OrganisationEvent = {
  LOAD_ORGANISATIONS: 'LOAD_ORGANISATIONS'
};

// flow states
export const OrganisationFlowState = {
  INIT: 'INIT',
  ORGANISATIONS_LOADED: 'ORGANISATIONS_LOADED',
  FAILED_TO_LOAD_ORGANISATIONS: 'FAILED_TO_LOAD_ORGANISATIONS'
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  organisations: [],
  flowState: OrganisationFlowState.INIT
};

// reducer
const reducer = (state: any, newState: any) => ({ ...state, ...newState });

// context
const organisationContext = createContext<OrganisationState | any>(initialState);

// provider
export const OrganisationProvider: FC = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <organisationContext.Provider value={{ state, dispatch }}>{children}</organisationContext.Provider>
  );
};

export type UseOrganisationsType = {
  state: OrganisationState;
  addEvent: AddEventType;
};

// hook
const useOrganisations = (): UseOrganisationsType => {
  const { state, dispatch } = useContext(organisationContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case OrganisationEvent.LOAD_ORGANISATIONS:
        organisationService.getOrganisations().subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              organisations: result,
              flowState: OrganisationFlowState.ORGANISATIONS_LOADED
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: OrganisationFlowState.FAILED_TO_LOAD_ORGANISATIONS
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useOrganisations;
