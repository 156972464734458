import _ from 'lodash';
import * as PropTypes from 'prop-types';
import HorizontalStackedBarChart from '../../../commons/charts/HorizontalStackedBarChart';
import { chargePointStatus } from '../../../domain/chargePoint';
import mainTheme from '../../../themes/mainTheme';

const ChargePointStatusLine = ({ statusToNumberOfChargePointsList }) => {
  const toLabel = (key) => _.titleCase(key);

  const toColor = (status) => {
    switch (status) {
      case chargePointStatus.AVAILABLE:
        return mainTheme.palette.chargePointStatus.available;
      case chargePointStatus.UNAVAILABLE:
        return mainTheme.palette.chargePointStatus.unavailable;
      case chargePointStatus.CHARGING:
        return mainTheme.palette.chargePointStatus.charging;
      case chargePointStatus.DECOMMISSIONED:
        return mainTheme.palette.chargePointStatus.decommissioned;
      default:
        throw new Error(`Invalid status: ${status}`);
    }
  };

  const data = statusToNumberOfChargePointsList.map((statusToNumberOfChargePoints) => ({
    label: toLabel(statusToNumberOfChargePoints.key),
    value: statusToNumberOfChargePoints.value,
    color: toColor(statusToNumberOfChargePoints.key),
  }));

  return <HorizontalStackedBarChart data={data} />;
};

ChargePointStatusLine.propTypes = {
  statusToNumberOfChargePointsList: PropTypes.array.isRequired,
};

export default ChargePointStatusLine;
