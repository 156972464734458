import {ajax} from 'rxjs/ajax';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import appConfig from '../utils/config/appConfig';
import UnauthorisedException from '../utils/auth/exceptions/UnauthorisedException';

const loginService = {
  handleSubmit: (email, password) => {
    return ajax({
      url: `${appConfig.emspAdminApiDomain}/auth/oauth/access-token`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: {
        username: email,
        password: password,
      },
    }).pipe(
      map((response) => response.response),
      catchError((error) => {
        if (error.status === 401) {
          return throwError(new UnauthorisedException('The username or password you entered is incorrect'));
        }

        if (error.status === 403) {
          return throwError(new UnauthorisedException('Admin access is required to log into OpenLoop console'));
        }
        return throwError(new Error(error.message));
      })
    );
  },
};

export default loginService;
