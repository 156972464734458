import React from 'react';
import {CardContent, Container} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import _ from 'lodash';
import * as PropTypes from 'prop-types';

const TabbedPaper = ({children, maxWidth, backgroundColor, ...otherArguments}) => {
  return (
    <>
      <Container maxWidth={_.isNil(maxWidth) ? 'md' : maxWidth} disableGutters {...otherArguments}>
        <Card style={{backgroundColor: backgroundColor ? backgroundColor : 'white'}} elevation={2}>
          <CardContent>{children}</CardContent>
        </Card>
      </Container>
    </>
  );
};

TabbedPaper.propTypes = {
  children: PropTypes.array.isRequired,
  maxWidth: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default TabbedPaper;
