import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import NavigationLink from '../../commons/link/NavigationLink';
import MaterialTableWrapper from '../../commons/table/MaterialTableWrapper';
import { chargePointStatus } from '../../domain/chargePoint';
import { locationStatus } from '../../domain/locationStatus';
import { usePermissions } from '../../hooks/usePermissions';
import paths from '../../routes/paths';
import { Permission } from '../../types/user/UserPermission';
import lodashExtras from '../../utils/lodash_extras/lodashExtras';
import LocationStatusDisplay from './LocationStatusDisplay';
import useLocations, { LocationEvent } from './hook/useLocations';

const LocationsTable = () => {
  const history = useHistory();
  const {
    state: { locations },
    addEvent
  } = useLocations();
  const { hasPermission } = usePermissions();

  const renderRoute = (id, countryCode, partyId) => {
    return `${paths.LOCATIONS}/${countryCode}/${partyId}/${id}/details`;
  };

  // navigate to details page
  const handleClick = (id, countryCode, partyId) => {
    // add internal/external to route
    history.push(renderRoute(id, countryCode, partyId));
  };

  // navigate to add location page
  const handleAddlocationClick = () => {
    history.push(paths.NEW_LOCATION);
  };

  const statusDisplayValue = (status) => {
    return status ? locationStatus.DECOMMISSIONED : locationStatus.ACTIVE;
  };

  return (
    <MaterialTableWrapper
      columns={[
        {
          title: 'ID',
          field: 'id',
          width: 'auto',
          hidden: true,
          hiddenByColumnsButton: true
        },
        {
          title: 'Name',
          field: 'name',
          width: 'auto',
          render: (rowData) => <NavigationLink path={renderRoute(rowData.id, rowData.cpoCountryCode, rowData.cpoPartyId)} text={rowData.name} />
        },
        { title: 'Address', field: 'address', width: 'auto' },
        { title: 'Organisation', field: 'organisation', width: 'auto' },
        { title: 'Operator', field: 'operator', width: 'auto' },
        {
          title: 'Location Type',
          field: 'locationType',
          width: 'auto',
          cellStyle: { width: 160 },
          render: (rowData) => _.capitalize(rowData.locationType)
        },
        {
          title: 'Status',
          field: 'decomissioned',
          width: 'auto',
          render: (rowData) => <LocationStatusDisplay decommissioned={rowData.decommissioned} />,
          customFilterAndSearch: (filter, rowData) =>
            _.isNil(rowData.decommissioned) ? false : lodashExtras.includesSubstringIgnoreCase(statusDisplayValue(rowData.decommissioned), filter)
        }
      ]}
      data={locations.map((location) => {
        return {
          ...location,
          organisation: location.owner
        };
      })}
      exportFileName="locations"
      globalActionButton={
        hasPermission(Permission.CREATE_CHARGE_POINT)
          ? {
              name: 'New',
              tooltip: 'Create a new location',
              onClick: () => handleAddlocationClick()
            }
          : null
      }
      moreActionsButton={{
        actions: [
          { name: 'View details', onClick: (rowData) => handleClick(rowData.id, rowData.cpoCountryCode, rowData.cpoPartyId) },
          {
            name: 'Update details',
            onClick: (rowData) =>
              addEvent({
                type: LocationEvent.SHOW_UPDATE_LOCATION_FORM,
                payload: {
                  id: rowData.id,
                  groupName: rowData.groupName,
                  mode: rowData.mode
                }
              }),
            getVisible: (rowData) => rowData.status !== chargePointStatus.DECOMMISSIONED
          }
        ]
      }}
    />
  );
};

export default LocationsTable;
