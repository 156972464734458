import _ from 'lodash';
import { createContext, FC, useContext, useReducer } from 'react';
import { EMPTY, from, of, Subscription } from 'rxjs';
import { catchError, concatMap, first, map } from 'rxjs/operators';
import { AddUserProgress, AddUserStatus } from '../../../../commons/form/AddMultipleUsersForm/AddingMultipleUsersProgress';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import accountService from '../../../../services/accountService';
import { EmspAccountUser } from '../../../../types/account/Account';
import { AccountUserEvent, AccountUserEventTypes } from '../../../../types/account/AccountUserEvent';
import { AddEventType, InitialStateType, ReducerContext } from '../../../../types/SharedStates';
import ConflictException from '../../../../utils/auth/exceptions/ConflictException';

export enum AccountUsersFlowState {
  INIT = 'INIT',
  LOADING_ACCOUNT_USERS = 'LOADING_ACCOUNT_USERS',
  LOADED_ACCOUNT_USERS = 'LOADED_ACCOUNT_USERS',
  FAILED_LOADING_ACCOUNT_USERS = 'FAILED_LOADING_ACCOUNT_USERS',
  // Adding
  SHOWING_ADD_ACCOUNT_USER_FORM = 'SHOWING_ADD_ACCOUNT_USER_FORM',
  ADDING_ACCOUNT_USERS = 'ADDING_ACCOUNT_USERS',
  PAUSED_ADDING_ACCOUNT_USERS = 'PAUSED_ADDING_ACCOUNT_USERS',
  COMPLETED_ADDING_ACCOUNT_USERS = 'COMPLETED_ADDING_ACCOUNT_USERS',
  // Removing
  SHOWING_REMOVE_ACCOUNT_USER_WARNING = 'SHOWING_REMOVE_ACCOUNT_USER_WARNING',
  FAILED_TO_REMOVE_ACCOUNT_USER = 'FAILED_TO_REMOVE_ACCOUNT_USER',
  REMOVING_ACCOUNT_USER = 'REMOVING_ACCOUNT_USER'
}

export type NewAccountUser = AddUserProgress & { accountId: string };

export interface AccountUsersState {
  flowState: AccountUsersFlowState;
  accountUsers?: EmspAccountUser[];
  loadingState: InitialStateType;
  userRemoved?: boolean;
  userToRemove?: EmspAccountUser;
  newAccountUsers?: NewAccountUser[];
  newAccountSubscription?: Subscription | null;
  warningMessage?: string;
  errorMessage?: string;
}

const initialState: AccountUsersState = {
  flowState: AccountUsersFlowState.INIT,
  loadingState: loadingContainerInitialState
};

const AccountUsersContext = createContext<ReducerContext<AccountUsersState>>({
  state: initialState,
  dispatch: () => initialState
});

const reducer = (state: AccountUsersState, newState: Partial<AccountUsersState>) => ({ ...state, ...newState });

export const AccountUsersProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));

  return <AccountUsersContext.Provider value={{ state, dispatch }}>{children}</AccountUsersContext.Provider>;
};

interface UseAccountUsersType {
  state: AccountUsersState;
  addEvent: AddEventType<AccountUserEventTypes>;
}

const useAccountUsers = (): UseAccountUsersType => {
  const { state, dispatch } = useContext(AccountUsersContext);

  const processPendingAccountUsers = (newAccountUsers: NewAccountUser[]) => {
    const queuedNewAccountUsers = newAccountUsers.filter((newAccountUser) => newAccountUser.status === AddUserStatus.QUEUED);
    const newAccountSubscription = from(queuedNewAccountUsers)
      .pipe(
        concatMap((newAccountUser) => {
          newAccountUser.status = AddUserStatus.ADDING;
          dispatch({ newAccountUsers });

          return accountService.addAccountUser(newAccountUser.accountId, newAccountUser.email).pipe(
            map(() => [newAccountUser, AddUserStatus.ADDED] as const),
            first(),
            catchError((error) => {
              if (error instanceof ConflictException) return of([newAccountUser, AddUserStatus.WARNING, error.message] as const);
              else return of([newAccountUser, AddUserStatus.ERROR, `Error: ${error.message}`] as const);
            })
          );
        })
      )
      .subscribe(
        ([newAccountUser, resultStatus, message]) => {
          newAccountUser.status = resultStatus;
          newAccountUser.message = message;
          dispatch({ newAccountUsers });
        },
        () => EMPTY,
        () => {
          dispatch({
            flowState: AccountUsersFlowState.COMPLETED_ADDING_ACCOUNT_USERS,
            newAccountSubscription: null
          });
        }
      );

    dispatch({
      flowState: AccountUsersFlowState.ADDING_ACCOUNT_USERS,
      newAccountUsers,
      newAccountSubscription
    });
  };

  const addEvent = (event: AccountUserEventTypes) => {
    switch (event.type) {
      // Initialization
      case AccountUserEvent.LOAD_ACCOUNT_USERS:
        dispatch({
          flowState: AccountUsersFlowState.LOADING_ACCOUNT_USERS,
          loadingState: loadingContainerInitialState
        });
        accountService.getAccountUsers(event.payload.accountId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              accountUsers: result,
              flowState: AccountUsersFlowState.LOADED_ACCOUNT_USERS
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: AccountUsersFlowState.FAILED_LOADING_ACCOUNT_USERS
            })
        );
        break;
      // Add Account User
      case AccountUserEvent.SHOW_ADD_ACCOUNT_USER_FORM:
        dispatch({
          flowState: AccountUsersFlowState.SHOWING_ADD_ACCOUNT_USER_FORM
        });
        break;
      case AccountUserEvent.CLOSE_ADD_ACCOUNT_USER_FORM:
        dispatch({
          flowState: AccountUsersFlowState.LOADED_ACCOUNT_USERS
        });
        break;
      case AccountUserEvent.ADD_ACCOUNT_USERS_BY_EMAIL:
        const newAccountUsers: NewAccountUser[] = event.payload.emails.map((email) => ({
          email,
          accountId: event.payload.accountId,
          status: AddUserStatus.QUEUED
        }));
        processPendingAccountUsers(newAccountUsers);
        break;
      case AccountUserEvent.RESUME_ADDING_ACCOUNT_USERS:
        if (state.newAccountUsers !== undefined) processPendingAccountUsers(state.newAccountUsers);
        break;
      case AccountUserEvent.CANCEL_ADDING_ACCOUNT_USERS:
        state.newAccountSubscription?.unsubscribe();
        dispatch({
          flowState: AccountUsersFlowState.PAUSED_ADDING_ACCOUNT_USERS,
          newAccountUsers: state.newAccountUsers?.map((newAccountUser) => ({
            ...newAccountUser,
            status: newAccountUser.status === AddUserStatus.ADDING ? AddUserStatus.QUEUED : newAccountUser.status
          })),
          newAccountSubscription: null
        });
        break;
      // Remove Account User
      case AccountUserEvent.SHOW_REMOVE_ACCOUNT_USER_WARNING:
        dispatch({
          flowState: AccountUsersFlowState.SHOWING_REMOVE_ACCOUNT_USER_WARNING,
          userToRemove: event.payload.user
        });
        break;
      case AccountUserEvent.CLOSE_REMOVE_ACCOUNT_USER_WARNING:
        dispatch({
          flowState: AccountUsersFlowState.LOADED_ACCOUNT_USERS
        });
        break;
      case AccountUserEvent.REMOVE_ACCOUNT_USER_BY_ID:
        dispatch({
          flowState: AccountUsersFlowState.REMOVING_ACCOUNT_USER
        });
        accountService.removeAccountUser(event.payload.accountId, event.payload.userId).subscribe(
          () =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: AccountUsersFlowState.INIT,
              userRemoved: true
            }),
          (error) =>
            dispatch({
              errorMessage: error.message,
              flowState: AccountUsersFlowState.FAILED_TO_REMOVE_ACCOUNT_USER
            })
        );
        break;
      // Notifications
      case AccountUserEvent.CLEAR_ERROR:
        dispatch({
          errorMessage: ''
        });
        break;
      case AccountUserEvent.CLEAR_SUCCESS:
        dispatch({
          userRemoved: false
        });
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return { state, addEvent };
};

export default useAccountUsers;
