import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import paths from '../../../routes/paths';
import Dashboard from '../../Dashboard/Dashboard';
import ChargerTabs from './ChargerTabs';
import { AdminPermission, requiresAdminPermission } from '../../../hooks/useAdminPermissions';

export type ChargerDetailsMatchParams = {
  cpoCountryCode: string;
  cpoPartyId: string;
  locationId: string;
  chargerDisplayName: string;
}

const ChargerDetails: React.FC<ChargerDetailsMatchParams> = () => {
  const detailsMatch = useRouteMatch<ChargerDetailsMatchParams>(paths.CHARGER_DETAILS);
  const chargerDisplayName = detailsMatch?.params.chargerDisplayName;
  const locationId = detailsMatch?.params.locationId;
  return (
    <>
      {locationId && chargerDisplayName && (
        <Dashboard pageHeading={chargerDisplayName}>
          <ChargerTabs locationId={locationId} chargerDisplayName={chargerDisplayName} />
        </Dashboard>
      )}
    </>
  );
};
export default requiresAdminPermission(AdminPermission.LOCATION_ADMIN)(ChargerDetails);
