import * as queryString from 'query-string';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { debounceTime } from 'rxjs/operators';
import * as yup from 'yup';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { ROWS_PER_PAGE_OPTIONS } from '../../commons/table/MaterialTableWrapper';
import { requiresPermission } from '../../hocs/requiresPermission';
import { useLoadingContainerWithErrorPanel } from '../../hooks/useLoadingContainerWithErrorPanel';
import userService from '../../services/userService';
import { Permission } from '../../types/user/UserPermission';
import Dashboard from '../Dashboard/Dashboard';
import UsersTable from './UsersTable';

/**
 * Parses the given params object and provide appropriate default values if the params values are not valid
 * @param params
 * @returns {{search: string, sort_direction: string, count_per_page: number, sort_by: string, page: number}|*}
 */
const paramsOrDefault = (params) => {
  // define schema for validation/casting
  let schema = yup.object().shape({
    search: yup.string().notRequired().nullable().default(null),
    sort_by: yup.string().notRequired().nullable().default(null),
    sort_direction: yup.string().notRequired().nullable().default(null),
    page: yup
      .number()
      .transform((currentValue) => (isNaN(currentValue) ? undefined : currentValue))
      .notRequired()
      .nullable()
      .integer()
      .min(1)
      .default(1),
    count_per_page: yup
      .number()
      .transform((currentValue) => (isNaN(currentValue) ? undefined : currentValue))
      .notRequired()
      .nullable()
      .integer()
      .oneOf(ROWS_PER_PAGE_OPTIONS)
      .default(ROWS_PER_PAGE_OPTIONS[0])
  });

  const validSync = schema.isValidSync(params);
  if (validSync) {
    const cast = schema.cast(params);
    return cast;
  }

  // not valid so we return defaults
  return {
    search: null,
    sort_by: null,
    sort_direction: null,
    page: 1,
    count_per_page: ROWS_PER_PAGE_OPTIONS[0]
  };
};

const Users = () => {
  const location = useLocation();

  const params = queryString.parse(location.search);
  const { search, sort_by, sort_direction, page, count_per_page } = paramsOrDefault(params);

  const [users, setUsers] = useState([]);

  const observable = useMemo(
    () => userService.fetchUsersForAdmin(search, sort_by, sort_direction, page, count_per_page).pipe(debounceTime(300)),
    [search, sort_by, sort_direction, page, count_per_page]
  );
  const onSuccessCallback = useCallback((response) => setUsers(response), []);
  const onErrorCallback = useCallback((error) => setUsers([]), []);

  const loadingContainerWithErrorPanelState = useLoadingContainerWithErrorPanel(observable, onSuccessCallback, onErrorCallback);

  return (
    <Dashboard pageHeading="Users">
      <>
        <LoadingContainerWithErrorPanel {...loadingContainerWithErrorPanelState}>
          <UsersTable users={users} search={search} sortBy={sort_by} sortDirection={sort_direction} page={page} countPerPage={count_per_page} />
        </LoadingContainerWithErrorPanel>
      </>
    </Dashboard>
  );
};

export default requiresPermission(Permission.VIEW_ALL_USERS)(Users);
