import { EmspCharger } from '../charger/Charger';
import { InitialStateType } from '../SharedStates';

export enum OrganisationChargersFlowState {
  INIT = 'INIT',
  CHARGERS_LOADED = 'CHARGERS_LOADED',
  FAILED_TO_LOAD_CHARGERS = 'FAILED_TO_LOAD_CHARGERS'
}

export enum OrganisationChargersEvent {
  LOAD_CHARGERS = 'LOAD_CHARGERS'
}

export type OrganisationChargersState = {
  loadingState: InitialStateType;
  chargers: EmspCharger[];
  flowState: OrganisationChargersFlowState;
};
