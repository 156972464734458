import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { DASHBOARD_PATH } from '../../routes/paths';
import OrganisationAdminListPanel from './OrganisationAdminListPanel/OrganisationAdminListPanel';
import OrganisationInfoPanel from './OrganisationsInfoPanel/OrganisationInfoPanel';
import { EmspOrganisation } from '../../types/organisation/Organisation';
import OrganisationAccountListPanel from './OrganisationAccountListPanel/OrganisationAccountListPanel';
import OrganisationChargersListPanel from './OrganisationChargersListPanel/OrganisationChargersListPanel';

interface OrganisationDetailsTabsProps {
  organisation: EmspOrganisation;
}

const OrganisationDetailsTabs: FC<OrganisationDetailsTabsProps> = ({ organisation }) => {
  const { pathname } = useLocation();
  const organisationId = organisation.id;
  const detailsPath = `${DASHBOARD_PATH}/organisations/${organisationId}/details`;
  const adminsPath = `${DASHBOARD_PATH}/organisations/${organisationId}/admins`;
  const accountPath = `${DASHBOARD_PATH}/organisations/${organisationId}/accounts`;
  const chargersPath = `${DASHBOARD_PATH}/organisations/${organisationId}/chargers`;
  return (
    <>
      <TabContext value={pathname}>
        <TabList aria-label="Organisation Tabs" indicatorColor="primary" textColor="primary">
          <Tab label="Details" value={detailsPath} to={detailsPath} component={Link} />
          <Tab label="Accounts" value={accountPath} to={accountPath} component={Link} />
          <Tab label="Chargers" value={chargersPath} to={chargersPath} component={Link} />
          <Tab label="Admins" value={adminsPath} to={adminsPath} component={Link} />
        </TabList>
        <TabPanel value={detailsPath}>{<OrganisationInfoPanel organisation={organisation} />}</TabPanel>
        <TabPanel value={adminsPath}>
          <OrganisationAdminListPanel />
        </TabPanel>
        <TabPanel value={accountPath}>{<OrganisationAccountListPanel organisationId={organisation.id} />}</TabPanel>
        <TabPanel value={chargersPath}>{<OrganisationChargersListPanel organisationId={organisation.id} />}</TabPanel>
      </TabContext>
    </>
  );
};

export default OrganisationDetailsTabs;
