import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { handleError } from '../utils/error/handleApiError';
import { buildUrlWithQueryString } from '../utils/url/buildQueryString';
import { CpoTariffDto } from '../types/tariff/CpoTariffDto';
import { toCpoTariff } from '../converter/tariff/ToCpoTariff';
import { CpoTariff } from '../types/tariff/CpoTariff';
import { EmspTariff } from '../types/tariff/EmspTariff';
import { EmspTariffDto } from '../types/tariff/EmspTariffDto';
import { toEmspTariff } from '../converter/tariff/ToEmspTariff';

const tariffService = {
  getCpoTariffs(countryCode: string, partyId: string): Observable<CpoTariff[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/tariffs`, {
      country_code: countryCode,
      party_id: partyId
    });
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return response.response.data.map((cpoTariffDto: CpoTariffDto) => toCpoTariff(cpoTariffDto));
      }),
      catchError((error) => handleError(error))
    );
  },
  getEmspTariffs(): Observable<EmspTariff[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/tariff-overrides`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return response.response.data.map((emspTariffDto: EmspTariffDto) => toEmspTariff(emspTariffDto));
      }),
      catchError((error) => handleError(error, 'account'))
    );
  }
};

export default tariffService;
