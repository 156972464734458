import { FC, PropsWithChildren, createContext, useContext, useEffect, useReducer } from 'react';
import userService from '../services/userService'; // action types
import { Permission, PortalPermissionState } from '../types/user/UserPermission';
import useAuthentication from './useAuthentication';

// initial state
const initialState: PortalPermissionState = {
  permissions: [],
  initialised: false,
  errorMessage: null
};

const reducer = (state: PortalPermissionState, newState: Partial<PortalPermissionState>) => {
  return { ...state, ...newState };
};

const PortalPermissionsContext = createContext<PortalPermissionState>(initialState);

export const PermissionsProvider: FC = ({ children }: PropsWithChildren<any>) => {
  const { user } = useAuthentication();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!user) return;

    userService.getCurrentUserPermissions().subscribe(
      (result) => dispatch({ permissions: result, initialised: true, errorMessage: null }),
      () => dispatch({ initialised: true, errorMessage: 'Unable to load user permissions!' })
    );
  }, [user]);
  return <PortalPermissionsContext.Provider value={state}> {children} </PortalPermissionsContext.Provider>;
};

interface PortalPermissionsType {
  hasPermission: (permission: Permission) => boolean;
  permissions: PortalPermissionState;
}

// hook
export const usePermissions = (): PortalPermissionsType => {
  const permissions = useContext(PortalPermissionsContext);

  const hasPermission = (userPermission: Permission) => {
    if (permissions.permissions.length === 0) return false;
    return permissions.permissions.some((permission) => permission.action === userPermission);
  };

  return {
    hasPermission,
    permissions
  };
};
