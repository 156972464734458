import Button from '@material-ui/core/Button';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import { WarningDialog } from '../../../commons/dialogs/dialogs';
import HasPermission from '../../../commons/permissions/HasPermission';
import { ErrorBar } from '../../../commons/snackbars/snackbars';
import useApplication, { ApplicationEvent } from '../../../hooks/useApplication';
import paths from '../../../routes/paths';
import { Permission } from '../../../types/user/UserPermission';
import useChargePointGroupInfo, { ChargePointGroupInfoEvent, ChargePointGroupInfoFlowState } from './hook/useChargePointGroupInfo';
import { withChargePointGroupInfo } from './hook/withChargePointGroupInfo';

const ChargePointGroupInfoPanel = ({ chargePointGroup }) => {
  const { state, addEvent } = useChargePointGroupInfo();
  const history = useHistory();
  const { addApplicationEvent } = useApplication();

  useEffect(() => {
    if (state.flowState === ChargePointGroupInfoFlowState.CHARGE_POINT_GROUP_REMOVED) {
      addApplicationEvent({
        type: ApplicationEvent.CHARGE_POINT_GROUP_REMOVED,
        payload: { removedChargePointGroupName: chargePointGroup.name }
      });
      history.replace(paths.CHARGE_POINT_FLEETS);
    }
  }, [state.flowState, history, chargePointGroup.name, addApplicationEvent]);

  const handleDelete = () =>
    addEvent({
      type: ChargePointGroupInfoEvent.REMOVE_CHARGE_POINT_GROUP_DIALOG_REQUESTED
    });

  const DeleteButton = () => (
    <HasPermission permission={Permission.DELETE_CHARGE_POINT_GROUP}>
      <>
        <Button variant="contained" color="secondary" onClick={handleDelete}>
          Delete
        </Button>
      </>
    </HasPermission>
  );

  return (
    <>
      <PaperWithTitle title="General" topRightComponent={<DeleteButton />}>
        <>
          <TextWithLabel label="Id" text={chargePointGroup.id} />
          <TextWithLabel label="Name" text={chargePointGroup.name} />
          <TextWithLabel label="Access Type" text={chargePointGroup.accessType} />
        </>
      </PaperWithTitle>
      <WarningDialog
        open={_.includes([ChargePointGroupInfoFlowState.SHOWING_REMOVE_CHARGE_POINT_GROUP_DIALOG], state.flowState)}
        buttonLabel="Remove fleet"
        buttonCallback={() =>
          addEvent({
            type: ChargePointGroupInfoEvent.REMOVE_CHARGE_POINT_GROUP_DIALOG_SUBMITTED,
            payload: { chargePointGroupId: chargePointGroup.id }
          })
        }
        onClose={() => addEvent({ type: ChargePointGroupInfoEvent.REMOVE_CHARGE_POINT_GROUP_DIALOG_CLOSED })}
      >
        Are you sure you want to remove {chargePointGroup.name}?
      </WarningDialog>
      <ErrorBar open={!_.isBlankString(state.chargePointGroupErrorMessage)}>{state.chargePointGroupErrorMessage}</ErrorBar>
    </>
  );
};
ChargePointGroupInfoPanel.propTypes = {
  chargePointGroup: PropTypes.object.isRequired
};

export default withChargePointGroupInfo(ChargePointGroupInfoPanel);
