import { EmspAccountAdmin } from './Account';

export enum AccountAdminsEvent {
  LOAD_ACCOUNT_ADMINS = 'LOAD_ACCOUNT_ADMINS',
  // Add Account Admin
  SHOW_ADD_ACCOUNT_ADMIN_FORM = 'SHOW_ADD_ACCOUNT_ADMIN_FORM',
  CLOSE_ADD_ACCOUNT_ADMIN_FORM = 'CLOSE_ADD_ACCOUNT_ADMIN_FORM',
  ADD_ACCOUNT_ADMIN_BY_EMAIL = 'ADD_ACCOUNT_ADMIN_BY_EMAIL',
  // Remove Account Admin
  SHOW_REMOVE_ACCOUNT_ADMIN_WARNING = 'SHOW_REMOVE_ACCOUNT_ADMIN_WARNING',
  CLOSE_REMOVE_ACCOUNT_ADMIN_WARNING = 'CLOSE_REMOVE_ACCOUNT_ADMIN_WARNING',
  REMOVE_ACCOUNT_ADMIN_BY_ID = 'REMOVE_ACCOUNT_ADMIN_BY_ID',
  // Notifications
  CLEAR_WARNING = 'CLEAR_WARNING',
  CLEAR_ERROR = 'CLEAR_ERROR',
  CLEAR_SUCCESS = 'CLEAR_SUCCESS'
}

interface AccountAdminEventWithoutPayload {
  type:
    | AccountAdminsEvent.SHOW_ADD_ACCOUNT_ADMIN_FORM
    | AccountAdminsEvent.CLOSE_ADD_ACCOUNT_ADMIN_FORM
    | AccountAdminsEvent.CLOSE_REMOVE_ACCOUNT_ADMIN_WARNING
    | AccountAdminsEvent.CLEAR_WARNING
    | AccountAdminsEvent.CLEAR_ERROR
    | AccountAdminsEvent.CLEAR_SUCCESS;
}

interface LoadAccountAdminsEvent {
  type: AccountAdminsEvent.LOAD_ACCOUNT_ADMINS;
  payload: { accountId: string };
}

interface AddAccountAdminByEmailEvent {
  type: AccountAdminsEvent.ADD_ACCOUNT_ADMIN_BY_EMAIL;
  payload: { email: string; accountId: string };
}

interface ShowRemoveAccountAdminWarningEvent {
  type: AccountAdminsEvent.SHOW_REMOVE_ACCOUNT_ADMIN_WARNING;
  payload: { admin: EmspAccountAdmin };
}

interface RemoveAccountAdminByIdEvent {
  type: AccountAdminsEvent.REMOVE_ACCOUNT_ADMIN_BY_ID;
  payload: { userId: string; accountId: string };
}

export type AccountAdminEventTypes =
  | AccountAdminEventWithoutPayload
  | LoadAccountAdminsEvent
  | AddAccountAdminByEmailEvent
  | ShowRemoveAccountAdminWarningEvent
  | RemoveAccountAdminByIdEvent;
