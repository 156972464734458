import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import {Typography} from '@material-ui/core';
import useAuthentication from '../../hooks/useAuthentication';
import {AccountBox} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.primary.contrastText,
  },
  listItemIcon: {
    marginRight: theme.spacing(0.5),
    minWidth: 24,
    color: theme.palette.primary.contrastText,
  },
  listItemText: {
    marginBottom: theme.spacing(0.5),
  },
  userNameText: {
    fontWeight: 500,
  },
}));

const ProfileMenuItem = () => {
  const classes = useStyles(mainTheme);

  const {user} = useAuthentication();

  return (
    <ListItem
      classes={{
        root: classes.root,
      }}
      key={'profile'}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <AccountBox fontSize="large" />
      </ListItemIcon>
      <ListItemText
        classes={{multiline: classes.listItemText}}
        primary={<Typography className={classes.userNameText}>{user.name}</Typography>}
        secondary={
          // to use noWrap with variant, we need display block https://github.com/mui-org/material-ui/pull/17206
          <Typography noWrap display="block" variant="caption">
            {user.email}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default ProfileMenuItem;
