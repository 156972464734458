import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import SpacedBox from '../SpacedBox/SpacedBox';

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    alignItems: 'center',
    '& .MuiTypography-root': {
      color: theme.palette.error.main,
    },
  },
  errorIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
}));

const ErrorContainer = ({message}) => {
  const classes = useStyles(mainTheme);

  return (
    <Container classes={{root: classes.errorContainer}}>
      <SpacedBox>
        <ErrorIcon classes={{root: classes.errorIcon}} />
        <Typography>Error: {message}</Typography>
      </SpacedBox>
    </Container>
  );
};

ErrorContainer.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorContainer;
