import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import React, { createContext, useContext, useReducer } from 'react';
import _, { delay } from 'lodash';
import locationService from '../../../../services/locationService';

// events
export const CentralServerChargePointsEvent = {
  LOAD_CENTRAL_SERVER_CHARGE_POINT: 'LOAD_CENTRAL_SERVER_CHARGE_POINT',
  SHOW_EDIT_CHARGE_POINT_CONNECTORS: 'SHOW_EDIT_CHARGE_POINT_CONNECTORS',
  SUBMIT_UPDATE_CONNECTORS_FORM_REQUESTED: 'SUBMIT_UPDATE_CONNECTORS_FORM_REQUESTED',
  CANCEL_EDIT_CHARGE_POINT_CONNECTORS_REQUESTED: 'CANCEL_EDIT_CHARGE_POINT_CONNECTORS_REQUESTED'
};

// flow states
export const CentralServerChargePointsFlowState = {
  INIT: 'INIT',
  CENTRAL_SERVER_CHARGE_POINT_LOADED: 'CENTRAL_SERVER_CHARGE_POINT_LOADED',
  FAILED_TO_LOAD_CHARGE_POINTS: 'FAILED_TO_LOAD_CHARGE_POINTS',
  SHOWING_EDIT_CHARGE_POINT_CONNECTORS: 'SHOWING_EDIT_CHARGE_POINT_CONNECTORS',
  SUBMITTING_UPDATE_CONNECTORS_FORM: 'SUBMITTING_UPDATE_CONNECTORS_FORM',
  CONNECTORS_UPDATED: 'CONNECTORS_UPDATED',
  FAILED_TO_UPDATE_EVSES: 'FAILED_TO_UPDATE_EVSES',
  EDIT_CHARGE_POINT_CONNECTORS_CANCELED: 'EDIT_CHARGE_POINT_CONNECTORS_CANCELED'
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  flowState: CentralServerChargePointsFlowState.INIT,
  chargePoint: null,
  chargePointsErrorMessage: null,
  updateConnectorsErrorMessage: null,
  connectorsUpdated: false
};

// reducer
const reducer = (state, newState) => ({ ...state, ...newState });

// context
const centralServerChargePointsContext = createContext();

// provider
export const CentralServerChargePointsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <centralServerChargePointsContext.Provider value={{ state, dispatch }}>{children}</centralServerChargePointsContext.Provider>
  );
};

// hook
const useCentralServerChargePoints = () => {
  const { state, dispatch } = useContext(centralServerChargePointsContext);

  const addEvent = (event) => {
    switch (event.type) {
      case CentralServerChargePointsEvent.LOAD_CENTRAL_SERVER_CHARGE_POINT:
        const locationId = event.payload.id;
        locationService.getChargePointById(locationId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              chargePoint: result,
              chargePointsErrorMessage: null,
              flowState: CentralServerChargePointsFlowState.CENTRAL_SERVER_CHARGE_POINT_LOADED
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: CentralServerChargePointsFlowState.FAILED_TO_LOAD_CHARGE_POINTS
            })
        );
        break;
      case CentralServerChargePointsEvent.SHOW_EDIT_CHARGE_POINT_CONNECTORS:
        dispatch({
          flowState: CentralServerChargePointsFlowState.SHOWING_EDIT_CHARGE_POINT_CONNECTORS,
          connectorsUpdated: false,
          updateConnectorsErrorMessage: null
        });
        break;
      case CentralServerChargePointsEvent.SUBMIT_UPDATE_CONNECTORS_FORM_REQUESTED:
        // Call service method to create location/charge point
        dispatch({
          ...state,
          flowState: CentralServerChargePointsFlowState.SUBMITTING_UPDATE_CONNECTORS_FORM
        });

        locationService.updateConnectors(event.payload.locationId, event.payload.connectors).subscribe(
          () =>
            delay(() => {
              dispatch({
                ...state,
                flowState: CentralServerChargePointsFlowState.CONNECTORS_UPDATED,
                connectorsUpdated: true
              });
            }, event.payload.waitForSync),
          (error) => {
            dispatch({
              ...state,
              flowState: CentralServerChargePointsFlowState.FAILED_TO_UPDATE_EVSES,
              updateConnectorsErrorMessage: error.message
            });
          }
        );
        break;
      case CentralServerChargePointsEvent.CANCEL_EDIT_CHARGE_POINT_CONNECTORS_REQUESTED:
        dispatch({
          flowState: CentralServerChargePointsFlowState.EDIT_CHARGE_POINT_CONNECTORS_CANCELED
        });
        break;

      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useCentralServerChargePoints;
