import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { useEffect, useMemo, useState } from 'react';
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const MUIPlacesAutocomplete = ({ setAddressFields }) => {
  const debounceTime = 1500;
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const service = useMemo(() => new window.google.maps.places.AutocompleteService(), []);
  const placesService = useMemo(() => new window.google.maps.places.PlacesService(document.createElement('div')), []);

  const resolvePlace = (placeId) => {
    placesService.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        let address1 = "";
        let address2 = "";
        let postcode = "";
        let locality = "";
        let state = "";
        let route = "";

        for (const component of place.address_components) {
          address1 = component.types.includes("street_number") ? component.long_name : address1;
          address2 = component.types.includes("route") ? component.long_name : address2;
          postcode = component.types.includes("postal_code") ? component.long_name : postcode;
          route = component.types.includes("route") ? component.long_name : route;
          locality = component.types.includes("locality") ? component.long_name : locality;
          state = component.types.includes("administrative_area_level_1") ? component.short_name : state;

        }

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        const address = {
          address1,
          address2,
          postcode,
          locality,
          state,
          route,
          latitude: lat.toFixed(7),
          longitude: lng.toFixed(7)
        }

        setAddressFields(address);
      }
    });
  }

  const fetch = useMemo(() =>
    throttle((request, callback) => {
      service.getPlacePredictions({
        ...request,
        componentRestrictions: { country: "nz" },
        fields: ["address_components", "place_id"],
      }, callback);
    }, debounceTime), [service]);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      };
      return () => {
        active = false;
      };
    });
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      fullWidth
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(_, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        // This checks the place_id exists in the response, otherwise the user has selected and address that is not valid
        // or they have manually entered an address after selecting an initial address
        if (newValue && newValue.place_id) {
          resolvePlace(newValue.place_id)
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params}
          label="Search for an address"
          fullWidth
        />
      )}
      renderOption={(option) => {
        // The matches and parts uses autohighlighting to indicate what parts of the string entered by the use match the results returned by the API.
        // As a part of the google places API response, the main_text_matched_substrings are also returned which is used to highlight the matching parts of the string.
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

export default MUIPlacesAutocomplete;
