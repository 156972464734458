import React, { FC, useEffect } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';

import { withChargerConfigurations } from './hook/withChargerConfigurations';

import useChargerConfigurations from './hook/useChargerConfigurations';
import { ChargerConfigurationsFlowState } from '../../../types/charger/ChargerConfiguration';
import ChargerConfigurationsPanel from './ChargerConfigurationsPanel';
import { useRouteMatch } from 'react-router-dom';
import paths from '../../../routes/paths';

export interface ChargerConfigurationsProps {
  cpoChargePointId: string;
}

const ChargerConfigurations: FC<ChargerConfigurationsProps> = ({ cpoChargePointId }) => {
  const { state, addEvent, ChargerConfigurationsEvent } = useChargerConfigurations();
  const configMatch = useRouteMatch(paths.CHARGER_CONFIGURATIONS);

  useEffect(() => {
    switch (configMatch?.isExact && state.flowState) {
      case ChargerConfigurationsFlowState.LOADING_CHARGE_POINT_CONFIGS:
        addEvent({ type: ChargerConfigurationsEvent.LOAD_CHARGE_POINT_CONFIGS, payload: { id: cpoChargePointId } });
        break;
      case ChargerConfigurationsFlowState.REFRESH_CHARGE_POINT_CONFIGS_REQUESTED:
        addEvent({ type: ChargerConfigurationsEvent.REFRESH_CHARGE_POINT_CONFIGS, payload: { id: cpoChargePointId } });
        break;
      case ChargerConfigurationsFlowState.UPDATE_CHARGE_POINT_CONFIG_COMPLETED:
        addEvent({
          type: ChargerConfigurationsEvent.LOAD_UPDATED_CHARGE_POINT_CONFIGS,
          payload: { id: cpoChargePointId }
        });
        break;
      case ChargerConfigurationsFlowState.REFRESH_CHARGE_POINT_CONFIGS_COMPLETED:
        addEvent({
          type: ChargerConfigurationsEvent.LOAD_REFRESHED_CHARGE_POINT_CONFIGS,
          payload: { id: cpoChargePointId }
        });
        break;
      default:
        break;
    }
  }, [state.flowState, addEvent, cpoChargePointId, ChargerConfigurationsEvent, configMatch?.isExact]);

  const loadingState = {
    hasError: false,
    loading: false,
    errorMessage: ''
  };
  return (
    <LoadingContainerWithErrorPanel {...loadingState}>
      {state.configurations && (
        <ChargerConfigurationsPanel
          chargePointId={cpoChargePointId}
          configurations={state.configurations}
          rebootRequired={state.rebootRequired}
          refreshRequested={state.refreshRequested}
          updateRequested={state.updateRequested}
        />
      )}
    </LoadingContainerWithErrorPanel>
  );
};

export default withChargerConfigurations(ChargerConfigurations);
