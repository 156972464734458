import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldArray, Form, Formik } from 'formik';
import React, { createRef } from 'react';
import * as Yup from 'yup';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import CancelButton from '../../../commons/form/button/CancelButton';
import UpdateButton from '../../../commons/form/button/UpdateButton';
import mainTheme from '../../../themes/mainTheme';
import ConnectorActionButtonsContainer from '../../NewChargePoint/NewChargePointForm/ConnectorActionButtonsContainer';
import NewConnectorsDetails from '../../NewChargePoint/NewChargePointForm/NewConnectorDetails';
import useCentralServerChargePoints, { CentralServerChargePointsEvent } from './hook/useCentralServerChargePoints';

const useStyles = makeStyles((theme) => ({
  connectorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2)
  },
}));

const EditCentralServerConnectorsForm = ({ locationId }) => {
  const classes = useStyles(mainTheme);
  const { state, addEvent } = useCentralServerChargePoints();
  const formikRef = createRef();
  const handleCancelBtnClick = () => {
    addEvent({
      type: CentralServerChargePointsEvent.CANCEL_EDIT_CHARGE_POINT_CONNECTORS_REQUESTED,
    });
  };

  const handleAddConnectorOnClick = () => {
    const values = formikRef.current.values;
    const connectors = [...values.connectors];
    const newConnector = {
      id: connectors.length + 1,
      format: 'CABLE',
      maxAmperage: '',
      maxVoltage: '',
      maxPower: '',
      powerType: '',
      standard: '',
    };

    connectors.push(newConnector);
    formikRef.current.setValues({ ...values, connectors });
  };

  const handleRemoveConnectorOnClick = () => {
    const values = formikRef.current.values;
    const connectors = [...values.connectors];
    connectors.pop();
    formikRef.current.setValues({ ...values, connectors });
  };

  const initialValues = {
    connectors: state.chargePoint.evses.flat().map((evse) => {
      const maxPower = evse.connector.maxVoltage * evse.connector.maxAmperage;
      return {
        ...evse.connector,
        maxPower: maxPower
      };
    }
    ),
  };

  const validationSchema = Yup.object({
    connectors: Yup.array().of(
      Yup.object({
        id: Yup.number().typeError('Should be a number').required('Required'),
        format: Yup.string().required('Required'),
        maxAmperage: Yup.number().typeError('Should be a number').required('Required'),
        maxVoltage: Yup.number().typeError('Should be a number').required('Required'),
        powerType: Yup.string().required('Required'),
        standard: Yup.string().required('Required'),
      })
    ),
  });

  const handleChange = (e) => {
    // We need the current connector element's index so we can grab the maxAmperage and maxVoltage field values for this connector block.
    // We can get this index from the "name" attribute of the field
    const connectorFieldIndex = e.target.name.split('.')[1];
    const currentConnector = formikRef.current.values.connectors[connectorFieldIndex];
    const maxAmperage = currentConnector.maxAmperage;
    const maxVoltage = currentConnector.maxVoltage;
    formikRef.current.setFieldValue(`connectors.${connectorFieldIndex}.maxPower`, maxAmperage * maxVoltage);
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        enableReinitialize
        validateOnMount
        onSubmit={(values) => {
          addEvent({
            type: CentralServerChargePointsEvent.SUBMIT_UPDATE_CONNECTORS_FORM_REQUESTED,
            payload: { locationId: locationId, connectors: values.connectors, waitForSync: 3000 },
            createChargePointErrorMessage: null,
          });
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Form>
            <PaperWithTitle
              title='Connectors'
              topRightComponent={
                <ConnectorActionButtonsContainer handleAddConnector={handleAddConnectorOnClick} handleRemoveConnector={handleRemoveConnectorOnClick} connectors={formikProps.values.connectors} />
              }
              children={
                <Box className={classes.connectorsContainer}>
                  <FieldArray name='connectors'>
                    {() =>
                      formikProps.values.connectors.map((connector, i) => {
                        return <NewConnectorsDetails key={i} handleChange={handleChange} index={i} />;
                      })
                    }
                  </FieldArray>
                </Box>
              }
              bottomRightComponent={
                <>
                  <CancelButton handleCancel={handleCancelBtnClick} />
                  <UpdateButton formikProps={formikProps} />
                </>
              }
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditCentralServerConnectorsForm;