import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { toEmspChargerFromChargerResponseDto, toEmspChargerFromChargerSummaryDto } from '../converter/charger/ToCharger';
import { toEmspLocation } from '../converter/location/ToLocation';
import { toEmspUpdateLocationDto } from '../converter/location/ToLocationDto';
import { toLocationUser } from '../converter/location/locationUser/ToLocationUser';
import { EmspCharger } from '../types/charger/Charger';
import { EmspChargerSummaryDto } from '../types/charger/ChargerDto';
import { EmspLocation, LocationType } from '../types/location/Location';
import { EmspLocationDto } from '../types/location/LocationDto';
import { LocationUser } from '../types/location/user/LocationUser';
import { LocationUserDto } from '../types/location/user/LocationUserDto';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { handleError } from '../utils/error/handleApiError';
import { buildUrlWithQueryString } from '../utils/url/buildQueryString';

const emspLocationServicev4 = {
  getLocations(): Observable<EmspLocation[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/locations`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return response.response.data.map((location: EmspLocationDto) => toEmspLocation(location));
      }),
      catchError((error) => handleError(error))
    );
  },
  getOPLLocationsSummary(): Observable<EmspLocation[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/locations?cpoCountryCode=NZ&cpoPartyId=OPL`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return response.response.data.map((location: EmspLocationDto) => toEmspLocation(location));
      }),
      catchError((error) => handleError(error))
    );
  },
  getLocationById: (locationId: string): Observable<EmspLocation> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV4}/locations/${locationId}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toEmspLocation(response.response)),
      catchError((error) => handleError(error, 'location'))
    ),
  updateLocation: (locationId: string, electricityCost: number, locationType: LocationType): Observable<EmspLocation> => {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV4}/locations/${locationId}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'PATCH',
        body: toEmspUpdateLocationDto(electricityCost, locationType)
      }).pipe(catchError((error) => handleError(error)))
    );
  },
  getChargersForLocationId(locationId: string): Observable<EmspCharger> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/locations/${locationId}/chargers`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((observable) => {
        return observable.response.data.map((emspChargerResponseDto: EmspChargerSummaryDto) => {
          return toEmspChargerFromChargerSummaryDto(emspChargerResponseDto);
        });
      }),
      catchError((error) => handleError(error, 'location'))
    );
  },
  getChargersByLocationIdAndDisplayName(locationId: string, displayName: string): Observable<EmspCharger> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/locations/${locationId}/chargers/${displayName}`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toEmspChargerFromChargerResponseDto(response.response)),
      catchError((error) => handleError(error, 'charger'))
    );
  },
  getUsersForLocationId(locationId: string): Observable<LocationUser[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/locations/${locationId}/users`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return response.response.data.map((user: LocationUserDto) => toLocationUser(user));
      }),
      catchError((error) => handleError(error, 'location'))
    );
  },
  addUserToLocationId(locationId: string, email: string): Observable<string[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/locations/${locationId}/users`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'POST',
        body: {
          email: email
        }
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => handleError(error))
    );
  },
  removeUserFromLocationId(locationId: string, userId: string): Observable<string[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/locations/${locationId}/users/${userId}`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'DELETE'
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => handleError(error))
    );
  },
  getAdminsForLocationId(locationId: string): Observable<LocationUser[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/locations/${locationId}/admins`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => response.response.data.map((user: LocationUserDto) => toLocationUser(user))),
      catchError((error) => handleError(error, 'location'))
    );
  },
  addAdminToLocationId(locationId: string, email: string): Observable<string[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/locations/${locationId}/admins`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'POST',
        body: {
          email: email
        }
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => handleError(error))
    );
  },
  removeAdminFromLocationId(locationId: string, adminId: string): Observable<string[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/locations/${locationId}/admins/${adminId}`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'DELETE'
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => handleError(error))
    );
  }
};

export default emspLocationServicev4;
