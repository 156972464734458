import useChargePointGroupAdminList, {ChargePointGroupAdminListEvent, ChargePointGroupAdminListFlowState} from './hook/useChargePointGroupAdminList';
import React, {useEffect} from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import ChargePointGroupAdminListTable from './ChargePointGroupAdminListTable';
import PropTypes from 'prop-types';
import {withChargePointGroupAdminList} from './hook/withChargePointGroupAdminList';
import {ErrorBar, ProcessingBar, SuccessBar, WarningBar} from '../../../commons/snackbars/snackbars';
import _ from 'lodash';

const ChargePointGroupAdminList = ({chargePointGroup}) => {
  const {state, addEvent} = useChargePointGroupAdminList();
  useEffect(() => {
    if (
      state.flowState === ChargePointGroupAdminListFlowState.INIT ||
      state.flowState === ChargePointGroupAdminListFlowState.NEW_CHARGE_POINT_GROUP_ADMIN_ADDED ||
      state.flowState === ChargePointGroupAdminListFlowState.CHARGE_POINT_GROUP_ADMIN_REMOVED
    ) {
      addEvent({type: ChargePointGroupAdminListEvent.LOAD_CHARGE_POINT_GROUP_ADMIN_LIST, payload: {chargePointGroup}});
    }
  }, [state.flowState, addEvent, chargePointGroup]);
  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        <ChargePointGroupAdminListTable />
      </LoadingContainerWithErrorPanel>
      <SuccessBar open={state.newChargePointGroupAdmin}>
        {state.newChargePointGroupAdmin?.name} ({state.newChargePointGroupAdmin?.email}) has been added!
      </SuccessBar>
      <SuccessBar open={state.removedChargePointGroupAdmin}>
        {state.removedChargePointGroupAdmin?.name} ({state.removedChargePointGroupAdmin?.email}) has been removed!
      </SuccessBar>
      <WarningBar open={!_.isBlankString(state.chargePointGroupAdminWarningMessage)}>{state.chargePointGroupAdminWarningMessage}</WarningBar>
      <ErrorBar open={!_.isBlankString(state.chargePointGroupAdminErrorMessage)}>{state.chargePointGroupAdminErrorMessage}</ErrorBar>
      <ProcessingBar open={state.flowState === ChargePointGroupAdminListFlowState.REMOVING_CHARGE_POINT_GROUP_ADMIN} />
    </>
  );
};
ChargePointGroupAdminList.propTypes = {
  chargePointGroup: PropTypes.object.isRequired,
};

export default withChargePointGroupAdminList(ChargePointGroupAdminList);
