import _ from 'lodash';
import randomstring from 'randomstring';

const isEmptyString = (s) => !s || s.length === 0;

const isBlankString = (s) => !s || /^\s*$/.test(s);

const includesSubstringIgnoreCase = (s, substring) => {
  if (isEmptyString(s)) {
    throw Error('String cannot be empty');
  }

  if (isEmptyString(substring)) {
    throw Error('Substring cannot be empty');
  }

  return s.toLowerCase().includes(substring.toLowerCase());
};

const snakeCaseToTitleCase = (s) => _.startCase(_.toLower(s));

// more random options see at https://www.npmjs.com/package/randomstring
const randomAlphabetic = (number = 10) =>
  randomstring.generate({
    length: number,
    charset: 'alphabetic',
  });

const randomNumeric = (number = 10) =>
  randomstring.generate({
    length: number,
    charset: 'numeric',
  });

const randomAlphanumeric = (number = 10) => randomstring.generate(number);

const lodashExtras = {
  includesSubstringIgnoreCase,
  isEmptyString,
  isBlankString,
  titleCase: snakeCaseToTitleCase,
  randomAlphabetic,
  randomNumeric,
  randomAlphanumeric,
};

export default lodashExtras;
