import React, {useState} from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import Link from '@material-ui/core/Link';
import {ClickAwayListener, Tooltip} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  verticalInlineBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  carbonOffsetText: {
    minWidth: 45,
    marginLeft: theme.spacing(1),
  },
  tooltip: {
    padding: 0,
    background: theme.palette.tooltip.background,
    fontSize: 'initial',
    color: 'inherit',
    borderRadius: 12,
    border: `1px solid ${theme.palette.tooltip.border}`,
    boxShadow: `0px 0px 4px ${theme.palette.tooltip.boxShadow}`,
  },
  infoIcon: {
    cursor: 'pointer',
  },
  headerBox: {
    borderBottom: `1px solid ${theme.palette.tooltip.divider}`,
  },
  header: {
    margin: '16px',
  },
  petrolAndElectricityPanels: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'no-wrap',
    borderBottom: `1px solid ${theme.palette.tooltip.divider}`,
  },
  infoBox: {
    flex: 1,
    padding: '8px 16px',
  },
  verticalDivider: {
    width: 1,
    background: `${theme.palette.tooltip.divider}`,
  },
  disclaimerBox: {
    padding: '8px 16px 16px 16px',
  },
  disclaimerText: {
    color: theme.palette.tooltip.secondary,
  },
}));

const TransactionCarbonOffsetWithTooltip = ({carbonOffset, electricityEmissions, petrolEmissions}) => {
  const classes = useStyles(mainTheme);
  
  const [open, setOpen] = useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleOpen = () => {
    setOpen(true);
  };
  
  const emissionsGuidelineUrl = 'https://environment.govt.nz/assets/Publications/Files/Measuring-Emissions-Detailed-Guide-2020.pdf';
  
  return (
    <>
      <Box classes={{root: classes.verticalInlineBox}}>
        <ClickAwayListener onClickAway={handleClose}>
          <Tooltip
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            interactive
            classes={{
              tooltip: classes.tooltip,
            }}
            title={<>
              <Box>
                <Box classes={{root: classes.headerBox}}>
                  <Typography variant='h5' classes={{root: classes.header}}>Estimated Emissions (kg CO2)</Typography>
                </Box>
                <Box classes={{root: classes.petrolAndElectricityPanels}}>
                  <Box classes={{root: classes.infoBox}}>
                    <Typography>Electricity</Typography>
                    <Link href={`${emissionsGuidelineUrl}#page=36&zoom=100,90,102`} target='_blank' rel='noreferrer'>{electricityEmissions.toFixed(3)}</Link>
                  </Box>
                  <Box classes={{root: classes.verticalDivider}}/>
                  <Box classes={{root: classes.infoBox}}>
                    <Typography>Petrol</Typography>
                    <Link href={`${emissionsGuidelineUrl}#page=48&zoom=100,90,610`} target='_blank' rel='noreferrer'>{petrolEmissions.toFixed(3)}</Link>
                  </Box>
                </Box>
                <Box classes={{root: classes.disclaimerBox}}>
                  <Typography display='inline' variant='body2' classes={{root: classes.disclaimerText}}>Estimate is based on Ministry for the Environment guidelines - </Typography>
                  <Link href={`${emissionsGuidelineUrl}`} target='_blank' rel='noreferrer' variant='body2'>Learn more</Link>
                </Box>
              </Box>
            </>}>
            <InfoIcon onClick={handleOpen} color='primary' classes={{root: classes.infoIcon}}/>
          </Tooltip>
        </ClickAwayListener>
        <Typography classes={{root: classes.carbonOffsetText}} display='inline'>{carbonOffset.toFixed(3)}</Typography>
      </Box>
    </>
  );
};

TransactionCarbonOffsetWithTooltip.propTypes = {
  carbonOffset: PropTypes.number.isRequired,
  electricityEmissions: PropTypes.number.isRequired,
  petrolEmissions: PropTypes.number.isRequired,
};

export default TransactionCarbonOffsetWithTooltip;