import React from 'react';
import useRemoteStartTransactions, { RemoteStartFlowState } from '../hook/useRemoteStartTransactions';
import RemoteStartPreviewForm from './RemoteStartPreviewForm';
import RemoteStartChooseConnectorForm from './RemoteStartChooseConnectorForm';
import RemoteStartTransactionReviewSteps from '../RemoteStartTransactionReviewSteps';
import PropTypes from 'prop-types';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import { useHistory } from 'react-router-dom';
import paths from '../../../routes/paths';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const RemoteStartTransactionForm = ({ chargePointId }) => {
  const { state } = useRemoteStartTransactions();
  const history = useHistory();
  const classes = useStyles();

  const handleGoToChargePoint = () => {
    return history.push(`${paths.CHARGE_POINTS_LEGACY}/${chargePointId}`);
  };
  const TransactionStartedCard = () => {
    return (
      <>
        <PaperWithTitle title="Remote Start Completed">
          <Typography>
            Start transaction command has been sent to the charger. The transaction could take up to a minute to show in the admin portal. If the
            customer hasn't plugged in their EV yet, please prompt them to do so.
          </Typography>
        </PaperWithTitle>
        <Box className={classes.buttonContainer}>
          <Button onClick={handleGoToChargePoint} variant="contained" color="primary">
            Go to Charge Point
          </Button>
        </Box>
      </>
    );
  };
  switch (state.flowState) {
    case RemoteStartFlowState.INIT:
    case RemoteStartFlowState.NOT_ELIGIBLE:
    case RemoteStartFlowState.CHECKING_ELIGIBILITY:
      return <RemoteStartPreviewForm chargePointId={chargePointId} />;
    case RemoteStartFlowState.ELIGIBLE:
      return <RemoteStartChooseConnectorForm chargePointId={chargePointId} />;
    case RemoteStartFlowState.REVIEW:
    case RemoteStartFlowState.FAILED_TO_START:
    case RemoteStartFlowState.TRANSACTION_STARTING:
      return <RemoteStartTransactionReviewSteps chargePointId={chargePointId} />;
    case RemoteStartFlowState.TRANSACTION_STARTED:
      return <TransactionStartedCard />;
    default:
      return null;
  }
};

RemoteStartTransactionForm.propTypes = {
  chargePointId: PropTypes.string.isRequired
};

export default RemoteStartTransactionForm;
