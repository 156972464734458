import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import AccountDetails from '../components/AccountDetails/AccountDetails';
import Accounts from '../components/Accounts/Accounts';
import ChargePointGroupDetails from '../components/ChagePointGroupDetails/ChargePointGroupDetails';
import ChargePointFleetTransactions from '../components/ChargePointFleetTransactions/ChargePointFleetTransactions';
import ChargePointGroups from '../components/ChargePointGroups/ChargePointGroups';
import ChargePoints from '../components/ChargePoints/ChargePoints';
import ChargerDetails from '../components/Chargers/ChargerDetails/ChargerDetails';
import ChargerRemoteStart from '../components/Chargers/ChargerRemoteStart/ChargerRemoteStart';
import NewChargerContainer from '../components/Chargers/NewCharger/NewChargerContainer';
import NotFoundPage from '../components/GenericNotFound/GenericNotFound';
import Insights from '../components/Insights/Insights';
import Locations from '../components/Location/Locations';
import LocationsDetails from '../components/Location/LocationsDetails';
import NewLocation from '../components/Location/NewLocation/NewLocation';
import LoginFlow from '../components/LoginFlow/LoginFlow';
import NewAccountContainer from '../components/NewAccount/NewAccountContainer';
import NewChargePoint from '../components/NewChargePoint/NewChargePoint';
import NewOrganisationContainer from '../components/NewOrganisation/NewOrganisationContainer';
import OrganisationDetails from '../components/OrganisationDetails/OrganisationDetails';
import Organisations from '../components/Organisations/Organisations';
import RemoteStartTransaction from '../components/RemoteStartTransaction/RemoteStartTransaction';
import Reports from '../components/Reports/Reports';
import TransactionDetails from '../components/TransactionDetails/TransactionDetails';
import UserDetails from '../components/UserDetails/UserDetails';
import UserGroupDetails from '../components/UserGroupDetails/UserGroupDetails';
import UserGroups from '../components/UserGroups/UserGroups';
import Users from '../components/Users/Users';
import PrivateRoute from './PrivateRoute';
import paths from './paths';
import RedirectToChargerDetails from '../components/Chargers/RedirectToChargerDetails';
import ChargePointDetails from '../components/ChargePointDetails/ChargePointDetails';
import ChargersList from '../components/Chargers/ListChargers/ChargersList';

const Routes = () => (
  <Router>
    <Switch>
      <Redirect exact from={paths.HOME} to={paths.LOGIN} />
      <PrivateRoute exact path={paths.LOGIN} component={LoginFlow} />
      <PrivateRoute exact path={paths.LOGOUT} component={LoginFlow} />
      <PrivateRoute exact path={paths.INSIGHTS} component={Insights} />
      <PrivateRoute exact path={paths.CHARGE_POINTS_LEGACY} component={ChargePoints} />
      <PrivateRoute exact path={paths.CHARGE_POINT_DETAILS_LEGACY} component={ChargePointDetails} />
      <PrivateRoute exact path={paths.CHARGE_POINT_DETAILS} component={RedirectToChargerDetails} />
      <PrivateRoute exact path={paths.TRANSACTION_DETAILS} component={TransactionDetails} />
      <PrivateRoute exact path={paths.USERS} component={Users} />
      <PrivateRoute exact path={paths.USER_DETAILS} component={UserDetails} />
      <PrivateRoute exact path={paths.REPORTS} component={Reports} />
      <PrivateRoute exact path={paths.REPORTS_CHARGE_POINTS} component={Reports} />
      <PrivateRoute exact path={paths.REPORTS_POSTPAY_TRANSACTIONS} component={Reports} />
      <PrivateRoute exact path={paths.REPORTS_CHARGERS} component={Reports} />
      <PrivateRoute exact path={paths.CHARGE_POINT_FLEETS} component={ChargePointGroups} />
      <PrivateRoute exact path={paths.CHARGE_POINT_FLEET_DETAILS} component={ChargePointGroupDetails} />
      <Redirect exact from={paths.CHARGE_POINT_FLEET_TRANSACTIONS} to={paths.TRANSACTIONS} />
      <PrivateRoute exact path={paths.TRANSACTIONS} component={ChargePointFleetTransactions} />
      <PrivateRoute exact path={paths.USER_FLEETS} component={UserGroups} />
      <PrivateRoute exact path={paths.USER_FLEET_DETAILS} component={UserGroupDetails} />
      <PrivateRoute exact path={paths.USER_FLEET_USER_DETAILS} component={UserDetails} />
      <PrivateRoute exact path={paths.NEW_CHARGE_POINT} component={NewChargePoint} />
      <PrivateRoute exact path={paths.REMOTE_START_LEGACY} component={RemoteStartTransaction} />
      <PrivateRoute exact path={paths.LOCATIONS} component={Locations} />
      <PrivateRoute exact path={paths.LOCATION_DETAILS} component={LocationsDetails} />
      <PrivateRoute exact path={paths.LOCATION_CHARGERS} component={LocationsDetails} />
      <PrivateRoute exact path={paths.LOCATION_USERS} component={LocationsDetails} />
      <PrivateRoute exact path={paths.LOCATION_ADMINS} component={LocationsDetails} />
      <PrivateRoute exact path={paths.NEW_LOCATION} component={NewLocation} />
      <PrivateRoute exact path={paths.ORGANISATIONS} component={Organisations} />
      <PrivateRoute exact path={paths.ORGANISATION_DETAILS} component={OrganisationDetails} />
      <PrivateRoute exact path={paths.ORGANISATION_ADMINS} component={OrganisationDetails} />
      <PrivateRoute exact path={paths.ORGANISATION_ACCOUNTS} component={OrganisationDetails} />
      <PrivateRoute exact path={paths.ORGANISATION_CHARGERS} component={OrganisationDetails} />
      <PrivateRoute exact path={paths.NEW_ORGANISATION} component={NewOrganisationContainer} />
      <PrivateRoute exact path={paths.NEW_CHARGER} component={NewChargerContainer} />
      {/*The order of the routes is important. NEW_CHARGER_FOR_LOCATION should be defined before CHARGER_DETAILS, so that it is matched first.*/}
      <PrivateRoute exact path={paths.NEW_CHARGER_FOR_LOCATION} component={NewChargerContainer} />
      <PrivateRoute exact path={paths.CHARGERS} component={ChargersList} />
      <PrivateRoute exact path={paths.CHARGER} component={RedirectToChargerDetails} />
      <PrivateRoute exact path={paths.CHARGER_DETAILS} component={ChargerDetails} />
      <PrivateRoute exact path={paths.CHARGER_INFO_DETAILS} component={ChargerDetails} />
      <PrivateRoute exact path={paths.CHARGER_TRANSACTIONS} component={ChargerDetails} />
      <PrivateRoute exact path={paths.CHARGER_TARIFFS} component={ChargerDetails} />
      <PrivateRoute exact path={paths.CHARGER_LOGS} component={ChargerDetails} />
      <PrivateRoute exact path={paths.CHARGER_CONFIGURATIONS} component={ChargerDetails} />
      <PrivateRoute exact path={paths.REMOTE_START_CHARGER} component={ChargerRemoteStart} />
      <PrivateRoute exact path={paths.ACCOUNTS} component={Accounts} />
      <PrivateRoute exact path={paths.NEW_ACCOUNT} component={NewAccountContainer} />
      <PrivateRoute exact path={paths.ACCOUNT_DETAILS} component={AccountDetails} />
      <PrivateRoute exact path={paths.ACCOUNT_ADMINS} component={AccountDetails} />
      <PrivateRoute exact path={paths.ACCOUNT_USERS} component={AccountDetails} />
      <PrivateRoute exact path={paths.ACCOUNT_TRANSACTION} component={AccountDetails} />
      <PrivateRoute exact path={paths.PAGE_NOT_FOUND} component={NotFoundPage} />
      <Route exact path="*" component={NotFoundPage} />
    </Switch>
  </Router>
);

export default Routes;
