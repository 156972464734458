import { delay } from 'lodash';
import { useContext } from 'react';

import emspLocationServicev4 from '../../../../services/emspLocationServicev4';
import locationService from '../../../../services/locationService';
import { AddEventType, EventType } from '../../../../types/SharedStates';
import { ChargerRemoteStartFlowState, ChargerRemoteStartState } from '../../../../types/charger/ChargerRemoteStartState';

import { ChargerRemoteStartContext } from './RemoteStartProvider';

// events
export enum RemoteStartEvent {
  PREVIEW_SUBMITTED = 'PREVIEW_SUBMITTED',
  START_TRANSACTION_SUBMITTED = 'START_TRANSACTION_SUBMITTED',
  CONNECTORS_OPENED = 'CONNECTORS_OPENED',
  CONNECTOR_SUBMITTED = 'CONNECTOR_SUBMITTED',
  REVIEW_REMOTE_START_CANCELLED = 'REVIEW_REMOTE_START_CANCELLED'
}

export type UseRemoteStartTransactionsType = {
  state: ChargerRemoteStartState;
  addEvent: AddEventType;
};

// hook
const useRemoteStartTransactions = (): UseRemoteStartTransactionsType => {
  const { state, dispatch } = useContext(ChargerRemoteStartContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case RemoteStartEvent.PREVIEW_SUBMITTED:
        dispatch({
          flowState: ChargerRemoteStartFlowState.CHECKING_ELIGIBILITY,
          errorMessage: null,
          isEligible: false
        });
        locationService.checkEligibility(event.payload.locationId, event.payload.displayName, event.payload.email).subscribe(
          (response: any) =>
            delay(() => {
              if (response.creditCardPaymentRequired && response.cardDetails.length === 0) {
                return dispatch({
                  flowState: ChargerRemoteStartFlowState.NOT_ELIGIBLE,
                  errorMessage: 'User does not have a stored credit card'
                });
              }
              return dispatch({
                flowState: ChargerRemoteStartFlowState.ELIGIBLE,
                preview: response,
                email: event.payload.email
              });
            }, 3000),
          (error: any) => {
            dispatch({
              flowState: ChargerRemoteStartFlowState.NOT_ELIGIBLE,
              errorMessage: error.message
            });
          }
        );
        break;
      case RemoteStartEvent.CONNECTOR_SUBMITTED:
        dispatch({
          flowState: ChargerRemoteStartFlowState.REVIEW,
          selectedEvse: event.payload.selectedEvse,
          selectedConnector: event.payload.selectedConnector
        });
        break;
      case RemoteStartEvent.START_TRANSACTION_SUBMITTED:
        dispatch({ flowState: ChargerRemoteStartFlowState.TRANSACTION_STARTING, errorMessage: null });
        if (event.payload.sessionsEnabledInRds) {
          locationService
            .createStartTransactionRequest(event.payload.locationId, state.email, state.selectedEvse.id, state.selectedConnector.id)
            .subscribe(
              () =>
                delay(() => {
                  return dispatch({ flowState: ChargerRemoteStartFlowState.TRANSACTION_STARTED });
                }, 3000),
              (error: any) => dispatch({ flowState: ChargerRemoteStartFlowState.FAILED_TO_START, errorMessage: error.message })
            );
        } else {
          locationService
            .createStartTransactionRequestLegacy(event.payload.locationId, state.email, state.selectedEvse.id, state.selectedConnector.id)
            .subscribe(
              () =>
                delay(() => {
                  dispatch({ flowState: ChargerRemoteStartFlowState.TRANSACTION_STARTED });
                }, 3000),
              (error: any) => dispatch({ flowState: ChargerRemoteStartFlowState.FAILED_TO_START, errorMessage: error.message })
            );
        }
        break;
      case RemoteStartEvent.CONNECTORS_OPENED:
        emspLocationServicev4.getChargersByLocationIdAndDisplayName(event.payload.locationId, event.payload.displayName).subscribe(
          (response: any) => {
            if (response.evses.length > 0) {
              dispatch({
                flowState: ChargerRemoteStartFlowState.CONNECTORS_LOADED,
                evses: response.evses
              });
            }
          },
          (error: any) => dispatch({ errorMessage: error.message })
        );
        break;
      case RemoteStartEvent.REVIEW_REMOTE_START_CANCELLED:
        dispatch({
          flowState: ChargerRemoteStartFlowState.ELIGIBLE
        });
        break;
      default:
        break;
    }
  };

  return { state, addEvent };
};

export default useRemoteStartTransactions;
