import React from 'react';
import PropTypes from 'prop-types';
import {ErrorBar, ProcessingBar, SuccessBar} from '../../commons/snackbars/snackbars';
import useChargePointConfigurations, {ChargePointConfigurationsEvent, ChargePointConfigurationsFlowState} from './hook/useChargePointConfigurations';
import {makeStyles} from '@material-ui/core/styles';
import { Paper} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import MaterialTableWrapper from '../../commons/table/MaterialTableWrapper';

const useStyles = makeStyles((theme) => ({
  warning: {
    marginBottom: theme.spacing(2),
  }
}));



const ChargePointConfigurationsPanel = ({configurations, rebootRequired, refreshRequested, updateRequested}) => {
  const {state, addEvent} = useChargePointConfigurations();
  const classes = useStyles();
  const handleRowUpdate = (newData) => {
    return new Promise((resolve, reject) => {
      addEvent({
        type: ChargePointConfigurationsEvent.UPDATE_CHARGE_POINT_CONFIG_REQUESTED,
        payload: {id: state.locationId, updatedConfigItem: newData, onConfigItemUpdate: () =>  resolve()},
      });
    })
  }
  return (
    <>
      <Paper className={classes.warning}>
       {rebootRequired && <Alert severity="warning">Charge point requires a reset! Please reset the charge point for the configurations to take effect!</Alert>}
      </Paper>
      <Paper>
        <MaterialTableWrapper
          columns={[
            { title: 'Key', field: 'key', editable: 'never', cellStyle: { width: '25%'}},
            { title: 'Value', field: 'value', editable: 'onUpdate', cellStyle: { width: '72%'}}
          ]}
          data={configurations}
          title='Charge Point Configurations'
          exportFileName='all_configurations'
          pageSize={25}
          editable= {{
            isEditable: rowData => (rowData.readonly === false && !refreshRequested),
            onRowUpdate: (newData, oldData) =>
              handleRowUpdate(newData)
          }}
       
        />
      </Paper>
      <ErrorBar open={state.getConfigurationsErrorMessage}>{state.getConfigurationsErrorMessage}</ErrorBar>
      
      <SuccessBar open={state.flowState === ChargePointConfigurationsFlowState.CHARGE_POINT_CONFIG_UPDATED}>
        Charge Point configuration item updated!
      </SuccessBar>
      {state.updateRequested && <ProcessingBar open={state.updateRequested && !state.getConfigurationsErrorMessage} text={ "Updating charge point configuration...Please wait..."} />}
      {state.refreshRequested && <ProcessingBar open={state.refreshRequested && !state.getConfigurationsErrorMessage} text={"Refreshing charge point configurations...Please wait..."}/>}
    </>
  );
};

ChargePointConfigurationsPanel.propTypes = {
  configurations: PropTypes.array.isRequired
};

export default ChargePointConfigurationsPanel;
