import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import { WarningBar } from '../../../commons/snackbars/snackbars';
import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';
import mainTheme from '../../../themes/mainTheme';
import dateTimeFormatter from '../../../utils/date/dateTimeFormatter';

const useStyles = makeStyles((theme) => ({
  message: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(8),
    lineHeight: '1.6',
    color: theme.palette.logText.primary
  }
}));

function formatLogMessage(message) {
  try {
    return JSON.stringify(JSON.parse(message), null, 2);
  } catch (e) {
    // For events that are not in JSON format, we return message with new lines to make it more readable for the user
    return message.replace(/, /g, ',\n');
  }
}

const ChargePointLogsTable = ({ logs, dateTimeRangeFilter, isPartialLoad }) => {
  const classes = useStyles(mainTheme);
  return (
    <>
      <MaterialTableWrapper
        columns={[
          {
            title: 'Server Timestamp',
            field: 'serverTimestamp',
            cellStyle: { width: '25%' },
            render: (rowData) => dateTimeFormatter.printReadableDateTime(rowData.serverTimestamp),
            customFilterAndSearch: (filter, rowData) =>
              _.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.serverTimestamp), filter),
            defaultSort: 'desc'
          },
          {
            title: 'Event',
            field: 'event',
            cellStyle: { width: '25%' }
          },
          {
            title: 'Charge Point Timestamp',
            field: 'chargePointTimestamp',
            render: (rowData) => dateTimeFormatter.printReadableDateTime(rowData.chargePointTimestamp),
            customFilterAndSearch: (filter, rowData) =>
              _.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.serverTimestamp), filter),
            cellStyle: { width: '25%' }
          },
          {
            title: 'Message Id',
            field: 'messageId',
            cellStyle: { width: '25%' }
          },
          {
            title: 'Message',
            field: 'message',
            hidden: true,
            searchable: true,
            export: true
          }
        ]}
        data={logs.map((log) => ({
          message: log.message,
          messageId: log.messageId,
          event: log.event,
          serverTimestamp: log.serverTimestamp,
          chargePointTimestamp: log.chargePointTimestamp
        }))}
        detailPanel={(rowData) => {
          return (
            <Box>
              <pre className={classes.message}>{formatLogMessage(rowData.message)}</pre>
            </Box>
          );
        }}
        exportFileName="Logs"
        dateTimeRangeFilter={dateTimeRangeFilter}
        pageSizeOptions={[50, 100, 150]}
        pageSize={50}
      />
      <WarningBar open={isPartialLoad}>
        Unable to display all event logs for the given search criteria, please narrow down your search to filter logs
      </WarningBar>
    </>
  );
};

export default ChargePointLogsTable;
