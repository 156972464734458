import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { FieldArray, Form, Formik } from 'formik';
import _ from 'lodash';
import { createRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import { ErrorBar, ProcessingBar } from '../../../commons/snackbars/snackbars';
import paths from '../../../routes/paths';
import useNewChargePoint, { NewChargePointEvent, NewChargePointFlowState } from '../hook/useNewChargePoint';
import ReviewChargePointFormInfoDetails from './ReviewChargePointFormInfoDetails';
import ReviewConnectorFormDetails from './ReviewConnectorFormDetails';

const useStyles = makeStyles((theme) => ({
  connectorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2)
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionButton: {
    marginLeft: theme.spacing(2)
  }
}));

const ReviewChargePointForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state, addEvent } = useNewChargePoint();
  const formikRef = createRef();

  useEffect(() => {
    if (
      state.flowState !== NewChargePointFlowState.SUBMITTING_CHARGE_POINT_FORM &&
      state.flowState !== NewChargePointFlowState.NEW_CHARGE_POINT_CREATED
    ) {
      formikRef.current.setSubmitting(false);
    }
    if (state.flowState === NewChargePointFlowState.NEW_CHARGE_POINT_CREATED) {
      history.push({ pathname: paths.CHARGE_POINTS_LEGACY, showLocationCreatedSuccessBar: true });
    }
  }, [state.flowState, formikRef, history]);

  const newChargePoint = state.newChargePoint;

  const handleCancelBtnClick = () => {
    addEvent({
      type: NewChargePointEvent.REVIEW_NEW_CHARGE_POINT_FORM_CANCELLED,
      createChargePointErrorMessage: null
    });
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={newChargePoint}
        onSubmit={(values) => {
          addEvent({
            type: NewChargePointEvent.SUBMIT_NEW_CHARGE_POINT_FORM_REQUESTED,
            payload: values,
            createChargePointErrorMessage: null
          });
        }}
      >
        {(formikProps) => (
          <Form>
            <PaperWithTitle title="General">
              <ReviewChargePointFormInfoDetails newChargePoint={state.newChargePoint} />
            </PaperWithTitle>
            <PaperWithTitle title="Connectors">
              <Box className={classes.connectorsContainer}>
                <FieldArray name="connectors">
                  {() =>
                    newChargePoint.connectors.map((connector, index) => {
                      return <ReviewConnectorFormDetails connector={connector} index={index} />;
                    })
                  }
                </FieldArray>
              </Box>
            </PaperWithTitle>
            <Box className={classes.submitButtonContainer}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                className={classes.actionButton}
                disabled={formikProps.isSubmitting}
                onClick={() => handleCancelBtnClick()}
              >
                Back
              </Button>
              <Button type="submit" variant="contained" color="primary" className={classes.actionButton} disabled={formikProps.isSubmitting}>
                Confirm
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <ErrorBar open={!_.isBlankString(state.createChargePointErrorMessage)}>{state.createChargePointErrorMessage}</ErrorBar>
      <ProcessingBar open={state.flowState === NewChargePointFlowState.SUBMITTING_CHARGE_POINT_FORM} />
    </>
  );
};

export default ReviewChargePointForm;
