import React, { FC, useEffect } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { ChargerTransactionsFlowState } from '../../../types/charger/ChargerTransactions';
import useChargerTransactions from './hook/useChargerTransactions';
import { withChargerTransactions } from './hook/withChargerTransactions';
import TransactionsTable from '../../Transaction/TransactionsTable/TransactionsTable';

type ChargerTransactionsProps = {
  emspChargerDisplayName: string;
};

const ChargerTransactions: FC<ChargerTransactionsProps> = ({ emspChargerDisplayName }) => {
  const { state, addEvent, ChargerTransactionsEventType } = useChargerTransactions();

  useEffect(() => {
    if (state.flowState === ChargerTransactionsFlowState.INIT) {
      addEvent({
        type: ChargerTransactionsEventType.LOAD_CHARGER_TRANSACTIONS,
        payload: {
          // We are re-using existing emsp endpoint that still refers to chargePointId for display name
          chargePointId: emspChargerDisplayName
        }
      });
    }
  }, [state.flowState, addEvent, emspChargerDisplayName, ChargerTransactionsEventType]);

  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      <TransactionsTable transactions={state.transactions} hideChargePointInfo={true} />
    </LoadingContainerWithErrorPanel>
  );
};
export default withChargerTransactions(ChargerTransactions);
