import React, {createRef, useEffect} from 'react';
import * as Yup from 'yup';
import {Field, Form, Formik} from 'formik';
import SmartTextField from '../../../commons/form/SmartTextField';
import {Button} from '@material-ui/core';
import useUserChargeTags, {UserChargeTagsEvent, UserChargeTagsFlowState} from './hook/useUserChargeTags';
import {ErrorBar, ProcessingBar} from '../../../commons/snackbars/snackbars';
import _ from 'lodash';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  createButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const AddChargeTagForm = (props) => {
  const classes = useStyles(mainTheme);
  const {state, addEvent} = useUserChargeTags();
  const formikRef = createRef();
  useEffect(() => {
    if (state.flowState === UserChargeTagsFlowState.FAILED_TO_ADD_CHARGE_TAG) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.flowState, formikRef]);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          rfidUid: '',
        }}
        onSubmit={({rfidUid}) => {
          addEvent({
            type: UserChargeTagsEvent.ADD_CHARGE_TAG_FORM_SUBMITTED,
            chargeTagId: rfidUid,
          });
        }}
        validationSchema={Yup.object({
          rfidUid: Yup.string().required('Required').min(8, 'Tag Id must be at least 8 characters').matches('[0-9A-Fa-f]{8,}', 'Tag Id must only contain hexadecimal values'),
        })}
      >
        {(formikProps) => (
          <Form>
            <Field
              label="Tag Id"
              name="rfidUid"
              fullWidth
              autoFocus
              disabled={formikProps.isSubmitting}
              component={SmartTextField}
              helperText="The RFID UID of the charge tag to assign to this user"
            />
            <Button className={classes.createButton} type="submit" variant="contained" color="primary" fullWidth disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>
              Add Charge Tag
            </Button>
          </Form>
        )}
      </Formik>
      <ErrorBar open={!_.isBlankString(state.chargeTagErrorMessage)}>{state.chargeTagErrorMessage}</ErrorBar>
      <ProcessingBar open={state.flowState === UserChargeTagsFlowState.ADDING_CHARGE_TAG} />
    </>
  );
};

export default AddChargeTagForm;
