import React from 'react';
import {FormControl, MenuItem, Select} from '@material-ui/core';
import {v4 as uuidV4} from 'uuid';
import * as PropTypes from 'prop-types';
import mainTheme from '../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
  select: {
    padding: '10px 15px 11px 15px',
  },
}));

const SimpleSelect = ({defaultValue, options, onChange}) => {
  const classes = useStyles(mainTheme);
  const selectId = `select-${uuidV4()}`;

  const handleChange = (event) => onChange(event.target.value);

  return (
    <FormControl classes={{root: classes.root}}>
      <Select
        classes={{root: classes.select}}
        value={defaultValue}
        onChange={handleChange}
        variant="outlined"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {options.map((option) => (
          <MenuItem key={`${selectId}-${option.value}`} value={option.value}>
            {option.displayValue}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SimpleSelect.propTypes = {
  defaultValue: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SimpleSelect;
