import {DatePicker} from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import * as PropTypes from 'prop-types';
import {Box, InputAdornment, TextField} from '@material-ui/core';
import _ from 'lodash';
import mainTheme from '../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';
import {BsCalendar} from 'react-icons/all';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  datePicker: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  input: {
    width: 238,
  },
  calendarIcon: {
    paddingBottom: theme.spacing(0.5),
    width: 18,
    height: 18,
  },
}));

const DateRangePicker = ({dateRangeFilter}) => {
  const classes = useStyles(mainTheme);

  const renderTextField = (props) => (
    <TextField
      variant="standard"
      {...props}
      inputProps={{
        className: classes.input,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <BsCalendar className={classes.calendarIcon} />
          </InputAdornment>
        ),
      }}
    />
  );

  return (
    <Box className={classes.root}>
      <DatePicker
        className={classes.datePicker}
        label="From Begin Of"
        value={moment(dateRangeFilter.dateRange.dateFrom).startOf('day')}
        format="dd MMM yy"
        onChange={(value) => dateRangeFilter.onDateRangeChange({...dateRangeFilter.dateRange, dateFrom: value})}
        minDate={_.isNil(dateRangeFilter.maxRangeInMonths) ? null : moment().subtract(dateRangeFilter.maxRangeInMonths, 'months').add(1, 'days')}
        maxDate={dateRangeFilter.dateRange.dateTo}
        TextFieldComponent={renderTextField}
        disableToolbar={true}
      />
      <DatePicker
        className={classes.datePicker}
        label="To End Of"
        value={moment(dateRangeFilter.dateRange.dateTo).endOf('day')}
        format="dd MMM yy"
        onChange={(value) => dateRangeFilter.onDateRangeChange({...dateRangeFilter.dateRange, dateTo: value})}
        disableFuture
        minDate={dateRangeFilter.dateRange.dateFrom}
        showTodayButton={true}
        TextFieldComponent={renderTextField}
        disableToolbar={true}
      />
    </Box>
  );
};

DateRangePicker.propTypes = {
  dateRangeFilter: PropTypes.object.isRequired,
  maxRangeInMonths: PropTypes.number,
};

export default DateRangePicker;
