import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';
import logo from '../../../assets/images/openloop_logo.png';
import TabbedPaper from '../../../commons/containers/TabbedPaper/TabbedPaper';
import { WarningDialog } from '../../../commons/dialogs/dialogs';
import SmartPasswordField from '../../../commons/form/SmartPasswordField';
import SmartTextField from '../../../commons/form/SmartTextField';
import LoadingModal from '../../../commons/layout/LoadingModal';
import OpenLoopPaper from '../../../commons/layout/OpenLoopPaper';
import { ErrorBar, SuccessBar } from '../../../commons/snackbars/snackbars';
import loginService from '../../../services/loginService';
import userService from '../../../services/userService';
import mainTheme from '../../../themes/mainTheme';
import UnauthorisedException from '../../../utils/auth/exceptions/UnauthorisedException';
import jwt from '../../../utils/jwt/jwt';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '20em',
  },
  root: {
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  forgottenPasswordBox: {
    marginTop: theme.spacing(2),
  },
  signUpBox: {
    marginTop: theme.spacing(2),
  },
}));

const Login = ({ setUsernamePasswordAuthenticated }) => {
  const classes = useStyles(mainTheme);

  const [failedLogin, setFailedLogin] = useState(false);
  const [failedLoginErrorMessage, setFailedLoginErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [showSendEmailVerification, setShowSendEmailVerification] = useState(false);
  const [showSentEmailVerificationSuccessfully, setShowSentEmailVerificationSuccessfully] = useState(false);
  const [showFailedToSendEmailVerification, setShowFailedToSendEmailVerification] = useState(false);

  const handleAuthResult = (authResult) => {
    const idToken = authResult.idToken;
    setEmail(jwt.getEmail(idToken));
    setShowSentEmailVerificationSuccessfully(false);
    setShowFailedToSendEmailVerification(false);

    if (!idToken) {
      setFailedLogin(true);
      setFailedLoginErrorMessage('Login service failed to return id token');
      setShowSendEmailVerification(false);
      return;
    }

    if (!jwt.isEmailVerified(idToken)) {
      setFailedLogin(true);
      setShowSendEmailVerification(true);
      return;
    }

    setShowSendEmailVerification(false);
    setUsernamePasswordAuthenticated(authResult);
  };

  const sendEmailVerification = () => {
    userService.sendVerificationEmail(email).subscribe(
      (result) => {
        setShowSentEmailVerificationSuccessfully(true);
        setShowFailedToSendEmailVerification(false);
      },
      (error) => {
        setShowFailedToSendEmailVerification(true);
        setShowSentEmailVerificationSuccessfully(false);
      }
    );
  };

  return (
    <OpenLoopPaper>
      <Box display="flex" minHeight="100vh" alignItems="center">
        <TabbedPaper className={classes.root} maxWidth="xs">
          <img className={classes.logo} src={logo} alt={'OpenLoop'} />
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={({ email, password }, { setSubmitting }) => {
              // reset failed login
              setFailedLogin(false);
              setFailedLoginErrorMessage('');

              // submit
              loginService.handleSubmit(email, password).subscribe(
                (result) => {
                  setSubmitting(false);
                  handleAuthResult(result);
                },
                (error) => {
                  setSubmitting(false);
                  if (error instanceof UnauthorisedException) {
                    setFailedLogin(true);
                    setFailedLoginErrorMessage(error.message);
                  }
                }
              );
            }}
            validationSchema={Yup.object({
              email: Yup.string().required('Required').email('Invalid email address'),
              password: Yup.string().required('Required').min(8, 'Password must be at least 8 characters').matches('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])', 'Invalid Password'),
            })}
          >
            {(formikProps) => (
              <>
                <Form>
                  <Field label="Email" name="email" type="email" fullWidth component={SmartTextField} />
                  <br />
                  <Field label="Password" name="password" type="password" fullWidth component={SmartPasswordField} />
                  <br />
                  <Button className={classes.button} type="submit" variant="contained" color="primary" fullWidth disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>
                    Login
                  </Button>
                </Form>
                <LoadingModal open={formikProps.isSubmitting} />
              </>
            )}
          </Formik>

          <ErrorBar open={failedLogin && failedLoginErrorMessage} fullPage>
            {failedLoginErrorMessage}
          </ErrorBar>
          <WarningDialog open={failedLogin && showSendEmailVerification && !failedLoginErrorMessage} buttonLabel="Resend Email" buttonCallback={sendEmailVerification} fullPage>
            Your email address is not verified, please check your inbox and try to login again once you are verified.
          </WarningDialog>
          <SuccessBar open={showSentEmailVerificationSuccessfully} fullPage>
            We sent an email to {email} to verify your account
          </SuccessBar>
          <ErrorBar open={showFailedToSendEmailVerification} fullPage>
            Unable to resend verification email
          </ErrorBar>

        </TabbedPaper>
      </Box>
    </OpenLoopPaper>
  );
};

Login.propTypes = {
  setUsernamePasswordAuthenticated: PropTypes.func.isRequired,
};

export default Login;
