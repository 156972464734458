import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import OpenLoopPaper from '../../../../commons/layout/OpenLoopPaper';
import LoadingModal from '../../../../commons/layout/LoadingModal';
import {ErrorBar} from '../../../../commons/snackbars/snackbars';
import mainTheme from '../../../../themes/mainTheme';
import SmartTextField from '../../../../commons/form/SmartTextField';
import {Box} from '@material-ui/core';
import TabbedPaper from '../../../../commons/containers/TabbedPaper/TabbedPaper';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  mfaCodeInput: {
    margin: `${theme.spacing(2)}px 0`,
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
      fontSize: 28,
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'center',
      fontSize: 16,
    },
  },
}));

const VerifyMfaCode = ({verifyAuthenticatorOneTimePassword, initialise, saveToken, idToken}) => {
  const classes = useStyles(mainTheme);
  
  const [failedVerify, setFailedVerify] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  return (
    <OpenLoopPaper>
      <Box display='flex' minHeight='100vh' alignItems='center'>
        <TabbedPaper className={classes.root} maxWidth='xs'>
          <Typography variant='h5' className={classes.title}>
            Two-factor authentication
          </Typography>
          <Typography variant='body1'>Open the two-factor authentication app on your device to view your authentication code and verify your identity.</Typography>
          <Formik
            initialValues={{code: ''}}
            validationSchema={Yup.object({
              code: Yup.string()
                .matches(/^([0-9]\s*){6}$/, 'Must be 6 digits')
                .required('Required'),
            })}
            onSubmit={(values, {setSubmitting}) => {
              setFailedVerify(false);
              setErrorMessage('');
              
              // remove all whitespace from code
              const code = values.code.replace(/\s/g, '');
              verifyAuthenticatorOneTimePassword(idToken, code).subscribe(
                (result) => {
                  setSubmitting(false);
                  saveToken(idToken, result);
                  initialise();
                },
                (error) => {
                  setSubmitting(false);
                  setFailedVerify(true);
                  if (error instanceof Error) {
                    setErrorMessage(error.message);
                  }
                }
              );
            }}
          >
            {(formikProps) => (
              <>
                <Form>
                  <Field className={classes.mfaCodeInput} label='Code' name='code' type='text' component={SmartTextField} variant='outlined' autoFocus />
                  <Button type='submit' variant='contained' color='primary' fullWidth disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>Verify</Button>
                </Form>
                <LoadingModal open={formikProps.isSubmitting} />
                <ErrorBar open={failedVerify} fullPage>
                  {errorMessage}
                </ErrorBar>
              </>
            )}
          </Formik>
        </TabbedPaper>
      </Box>
    </OpenLoopPaper>
  );
};

VerifyMfaCode.propTypes = {
  verifyAuthenticatorOneTimePassword: PropTypes.func.isRequired,
  initialise: PropTypes.func.isRequired,
  saveToken: PropTypes.func.isRequired,
  idToken: PropTypes.string.isRequired,
};

export default VerifyMfaCode;
