import queryString from 'query-string';

export const buildQueryString = (params) =>
  queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });

export const buildUrlWithQueryString = (url, params) =>
  queryString.stringifyUrl(
    {url: url, query: params},
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );
