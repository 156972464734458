import { Button } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import { createRef, useEffect } from 'react';
import * as Yup from 'yup';
import SmartAutoCompleteField from '../../../commons/form/SmartAutoCompleteField';
import SmartSelectField from '../../../commons/form/SmartSelectField';
import SmartTextField from '../../../commons/form/SmartTextField';
import HasPermission from '../../../commons/permissions/HasPermission';
import { ErrorBar } from '../../../commons/snackbars/snackbars';
import { usePermissions } from '../../../hooks/usePermissions';
import mainTheme from '../../../themes/mainTheme';
import { Permission } from '../../../types/user/UserPermission';
import useChargePoints, { ChargePointEvent, ChargePointFlowState } from '../../ChargePoints/hook/useChargePoints';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const UpdateChargePointForm = () => {
  const classes = useStyles(mainTheme);
  const { state, addEvent } = useChargePoints();
  const { hasPermission } = usePermissions();
  const formikRef = createRef();

  useEffect(() => {
    if (state.flowState === ChargePointFlowState.FAILED_TO_UPDATE_CHARGE_POINT) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.flowState, formikRef]);

  const updateChargePointGroup = state.chargePointGroups.find(
    (group) => group.id === state.updateChargePoint.group || group.name === state.updateChargePoint.groupName
  );

  const chargePointFleetOptions = _.sortBy(
    state.chargePointGroups.map((chargePointGroup) => ({ value: chargePointGroup.id, displayValue: chargePointGroup.name })),
    ['displayValue']
  );

  const initialValues = hasPermission(Permission.UPDATE_CHARGE_POINT_DETAILS)
    ? {
        id: state.updateChargePoint.id,
        mode: state.updateChargePoint.mode,
        group: updateChargePointGroup.id,
        electricityCost: state.updateChargePoint.electricityCost
      }
    : {
        electricityCost: state.updateChargePoint.electricityCost
      };

  const electricityCostValidation = Yup.string()
    .required('Required')
    .matches(/^\d+\.?\d{0,2}$/, 'Please enter a number that is up to 2 decimal places. e.g. 0.30');

  const validationSchema = hasPermission(Permission.UPDATE_CHARGE_POINT_DETAILS)
    ? Yup.object({
        id: Yup.string()
          .required('Required')
          .matches(/^OPL-[A-Z0-9][A-Z0-9-]*$/, 'Please enter a valid id. e.g. OPL-{number}'),
        group: Yup.string()
          .required('Required')
          .matches(/^((?!UNASSIGNED).)*$/, 'Fleet should not be Unassigned'),
        mode: Yup.string().required('Required'),
        electricityCost: electricityCostValidation
      })
    : Yup.object({
        electricityCost: electricityCostValidation
      });

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={(values) => {
          addEvent({
            type: ChargePointEvent.SUBMIT_UPDATE_CHARGE_POINT_FORM,
            payload: {
              initialId: state.updateChargePoint.id,
              values
            }
          });
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Form>
            <HasPermission permission={Permission.UPDATE_CHARGE_POINT_DETAILS}>
              <>
                <Field
                  label="Id"
                  name="id"
                  autoFocus
                  component={SmartTextField}
                  helperText="Upper case with dash delimiter e.g. OPL-{number}"
                  fullWidth
                />
                <Field
                  label="Charge Point Fleet"
                  name="group"
                  component={SmartAutoCompleteField}
                  defaultValue={{ value: updateChargePointGroup.id, displayValue: updateChargePointGroup.name }}
                  options={chargePointFleetOptions}
                  helperText="Select the charge point fleet that this charge point belongs to"
                  fullWidth
                />
                <Field
                  label="Mode"
                  name="mode"
                  component={SmartSelectField}
                  options={[
                    { value: 'FREE', displayValue: 'FREE' },
                    { value: 'AUTH', displayValue: 'AUTH' }
                  ]}
                  helperText="Select mode for this charge point"
                  fullWidth={true}
                />
              </>
            </HasPermission>
            <Field
              label="Electricity Cost"
              name="electricityCost"
              autoFocus={!hasPermission(Permission.UPDATE_CHARGE_POINT_DETAILS)}
              component={SmartTextField}
              helperText="Cost per kWh. Up to 2 decimal places, e.g. 0.30"
              fullWidth
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              endAdornment={<InputAdornment position="end">/kWh</InputAdornment>}
            />
            <Button
              className={classes.updateButton}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}
            >
              Update
            </Button>
          </Form>
        )}
      </Formik>
      <ErrorBar open={!_.isNull(state.updateChargePointErrorMessage)}>{state.updateChargePointErrorMessage}</ErrorBar>
    </>
  );
};

export default UpdateChargePointForm;
