import _ from 'lodash';
import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import NavigationLink from '../../../commons/link/NavigationLink';
import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';
import { usePermissions } from '../../../hooks/usePermissions';
import paths from '../../../routes/paths';
import { EmspCharger } from '../../../types/charger/Charger';
import { Permission } from '../../../types/user/UserPermission';
import ChargePointStatus from '../../ChargePointStatus/ChargePointStatus';

interface ChargersTableProps {
  chargers: EmspCharger[];
  locationId?: string;
  showAddCharger?: boolean;
  hideOrganisationColumn?: boolean;
}

const ChargersTable: FC<ChargersTableProps> = ({ chargers, locationId, showAddCharger, hideOrganisationColumn }) => {
  const history = useHistory();
  const location = useLocation();
  const renderLocationRoute = (id: string, countryCode: string, partyId: string) => {
    return `${paths.LOCATIONS}/${countryCode}/${partyId}/${id}`;
  };

  const renderChargerRoute = (locationId: string, countryCode: string, partyId: string, displayName: string) => {
    return renderLocationRoute(locationId, countryCode, partyId) + `/chargers/${displayName}`;
  };

  const handleAddCharger = () => {
    history.push(location.pathname + '/new', { locationId: locationId });
  };

  const { hasPermission } = usePermissions();

  return (
    <MaterialTableWrapper
      columns={[
        {
          title: 'Name',
          field: 'displayName',
          width: 'auto',
          render: (rowData: EmspCharger) => (
            <NavigationLink
              path={`${renderChargerRoute(rowData.locationId, rowData.countryCode, rowData.partyId, rowData.displayName)}/details`}
              text={rowData.displayName}
            />
          )
        },
        {
          title: 'Location',
          field: 'locationName',
          width: 'auto',
          render: (rowData: EmspCharger) => (
            <NavigationLink
              path={`${renderLocationRoute(rowData.locationId, rowData.countryCode, rowData.partyId)}/details`}
              text={rowData.locationName}
            />
          )
        },
        {
          title: 'Organisation',
          field: 'orgName',
          width: 'auto',
          hidden: !!hideOrganisationColumn,
          hiddenByColumnsButton: !!hideOrganisationColumn,
          render: (rowData: EmspCharger) => rowData.orgName
        },
        { title: 'Operator', field: 'operator', width: 'auto' },
        {
          title: 'Location Type',
          field: 'locationType',
          width: 'auto',
          cellStyle: { width: 160 },
          render: (rowData: EmspCharger) => _.capitalize(rowData.locationType)
        },
        { title: 'Status', field: 'status', width: 'auto', render: (rowData: EmspCharger) => <ChargePointStatus status={rowData.status} /> }
      ]}
      data={chargers}
      exportFileName="chargers"
      globalActionButton={
        hasPermission(Permission.CREATE_CHARGE_POINT) &&
        showAddCharger && {
          name: 'New',
          tooltip: 'Create a new charge point',
          onClick: () => handleAddCharger()
        }
      }
    />
  );
};

export default ChargersTable;
