import _ from 'lodash';
import { Observable, throwError } from 'rxjs';
import { AjaxRequest, ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { toUser, toUserAccount, toUserSearchSummary } from '../converter/user/toUser';
import { toUserAdminPermissions, toUserPermission } from '../converter/user/toUserPermission';
import { ChargePointGroupForUser, User, UserAccount, UserSearchSummary } from '../types/user/User';
import { UserAdminPermissions, UserPermission } from '../types/user/UserPermission';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { errorMessageObservable } from '../utils/error/errorUtils';
import { handleError } from '../utils/error/handleApiError';
import { buildQueryString } from '../utils/url/buildQueryString';
import { toChargePointGroups } from './chargePointGroupService';

const userService = {
  getCurrentUser: (): Observable<User> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspApiDomain}/users/current`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toUser(response.response)),
      catchError((error) => errorMessageObservable(error))
    ),
  getCurrentUserPermissions: (): Observable<UserPermission[]> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspApiDomain}/users/current/permissions`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => response.response.data.map(toUserPermission)),
      catchError((error) => errorMessageObservable(error))
    ),
  getCurrentUserAdminPermissions: (): Observable<UserAdminPermissions> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspApiDomain}/users/current/admin-permissions`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toUserAdminPermissions(response.response)),
      catchError((error) => errorMessageObservable(error))
    ),
  sendVerificationEmail: (email: string): Observable<AjaxRequest> =>
    ajax({
      url: `${appConfig.emspApiDomain}/auth/send-email-verification`,
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: email
    }),
  fetchUsersForAdmin: (
    search: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    countPerPage: number
  ): Observable<UserSearchSummary[]> => {
    if (search) {
      try {
        const searchTerms = _.split(search, /\s+/);
        searchTerms.forEach((el) => {
          if (el.length < 3) {
            search = '';
          }
        });
      } catch (e) {
        return throwError(e);
      }
    }

    const queryString = buildQueryString({
      search: search,
      sort_by: sortBy,
      sort_direction: sortDirection,
      page: page - 1,
      count_per_page: countPerPage
    });

    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/users/summary?${queryString}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => response.response.data.map(toUserSearchSummary)),
      catchError((error) => errorMessageObservable(error))
    );
  },
  getUserByUuid: (userId: string): Observable<User> => {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/users/${userId}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toUser(response.response)),
      catchError((error) => handleError(error, 'user'))
    );
  },
  getAccountByUuid: (userId: string): Observable<UserAccount> => {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/users/${userId}/account`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toUserAccount(response.response)),
      catchError((error) => handleError(error, 'user'))
    );
  },
  getChargePointFleetsByUserId: (userId: string): Observable<ChargePointGroupForUser[]> => {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/users/${userId}/charge-point-groups`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toChargePointGroups(response.response.data)),
      catchError((error) => handleError(error, 'user'))
    );
  }
};
export default userService;
