import { InitialStateType } from '../../SharedStates';
import { LocationUser } from './LocationUser';

// ***************************************************
// Location Users List
export enum LocationUsersFlowState {
  INIT = 'INIT',
  USERS_LOADED_FOR_LOCATION = 'USERS_LOADED_FOR_LOCATION',
  FAILED_TO_LOAD_USERS_FOR_LOCATION = 'FAILED_TO_LOAD_USERS_FOR_LOCATION',
  SHOWING_ADD_USER_DIALOG = 'SHOWING_ADD_USER_FORM',
  ADDING_USER_TO_LOCATION = 'ADDING_USER_TO_LOCATION',
  ADD_USER_DIALOG_CLOSED = 'ADD_USER_FORM_CLOSED',
  USER_ADDED_TO_LOCATION = 'NEW_USER_ADDED',
  FAILED_TO_ADD_USER = 'FAILED_TO_ADD_USER',
  FAILED_TO_REMOVE_USER_FROM_LOCATION = 'FAILED_TO_REMOVE_USER_FROM_LOCATION',
  SHOWING_REMOVE_USER_FROM_LOCATION_WARNING = 'SHOWING_REMOVE_USER_FROM_LOCATION_WARNING',
  REMOVING_USER_FROM_LOCATION = 'REMOVING_USER_FROM_LOCATION'
}
// events
export enum LocationUsersEvent {
  INIT = 'INIT',
  LOAD_USERS_FOR_LOCATION = 'LOAD_USERS_FOR_LOCATION',
  SHOW_ADD_USER_DIALOG = 'SHOW_ADD_USER_DIALOG',
  SUBMIT_ADD_USER_DIALOG_TO_LOCATION_REQUESTED = 'SUBMIT_ADD_USER__DIALOG_TO_LOCATION_REQUESTED',
  CLOSE_ADD_USER_DIALOG = 'CLOSE_ADD_USER_DIALOG',
  CLEAR_ERROR = 'CLEAR_ERROR',
  CLEAR_WARNING = 'CLEAR_WARNING',
  CLEAR_SUCCESS = 'CLEAR_SUCCESS',
  SHOW_REMOVE_USER_FROM_LOCATION_WARNING = 'SHOW_REMOVE_USER_FROM_LOCATION_WARNING',
  CLOSE_REMOVE_USER_FROM_LOCATION_WARNING = 'CLOSE_REMOVE_USER_FROM_LOCATION_WARNING',
  REMOVE_USER_FROM_LOCATION = 'REMOVE_USER_FROM_LOCATION'
}
export type LocationUsersState = {
  flowState: LocationUsersFlowState;
  loadingState: InitialStateType;
  users: LocationUser[];
  errorMessage?: string;
  warningMessage?: string;
  userAdded?: boolean;
  userRemoved?: boolean;
  userToRemove?: LocationUser;
};
