import React, { createRef, FC, useEffect } from 'react';
import { CpoChargePoint } from '../../../../types/charger/Charger';
import PaperWithTitle from '../../../../commons/containers/PaperWithTile/PaperWithTitle';
import CancelButton from '../../../../commons/form/button/CancelButton';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import SmartTextField from '../../../../commons/form/SmartTextField';
import UpdateButton from '../../../../commons/form/button/UpdateButton';
import SmartSelectField from '../../../../commons/form/SmartSelectField';
import { getModifiedValues } from '../../../../commons/form/FormikFunctions';
import { UpdateCpoChargePoint } from './ChargerInfo';

export interface EditChargerDetailsPanelProps {
  cpoChargePoint: CpoChargePoint;
  handleCancel: () => void;
  handleSubmit: (values: UpdateCpoChargePoint) => void;
  hasFailed: boolean;
}

const EditChargerInfoForm: FC<EditChargerDetailsPanelProps> = ({ cpoChargePoint, handleCancel, handleSubmit, hasFailed }) => {
  const formikRef = createRef<FormikProps<Partial<UpdateCpoChargePoint>>>();
  useEffect(() => {
    if (hasFailed && formikRef.current) {
      formikRef.current.setSubmitting(false);
    }
  }, [hasFailed, formikRef]);

  const initialValues = {
    ocppChargePointIdentifier: cpoChargePoint.ocppChargePointIdentifier,
    anonymousChargingAllowed: cpoChargePoint.anonymousChargingAllowed
  };

  const onSubmit = (values: Partial<UpdateCpoChargePoint>) => {
    const modifiedValues = getModifiedValues(values, initialValues);
    handleSubmit({ cpoChargePointId: cpoChargePoint.id, ...modifiedValues });
  };

  const validationSchema = Yup.object({
    ocppChargePointIdentifier: Yup.string()
      .required('Required')
      .min(5)
      .max(50)
      .matches(/^[A-Z0-9]+([A-Z0-9_-])*$/, 'Should be in uppercase and alphanumeric'),
    anonymousChargingAllowed: Yup.boolean().required('Required')
  });

  return (
    <Formik innerRef={formikRef} onSubmit={onSubmit} validationSchema={validationSchema} initialValues={initialValues}>
      {(formikProps) => (
        <Form>
          <PaperWithTitle
            title="General"
            bottomRightComponent={
              <>
                <CancelButton handleCancel={handleCancel} />
                <UpdateButton formikProps={formikProps} />
              </>
            }
          >
            <>
              <Field label="Ocpp Identifier" name="ocppChargePointIdentifier" autoFocus component={SmartTextField} fullWidth />
              <Field
                label="Mode"
                name="anonymousChargingAllowed"
                component={SmartSelectField}
                options={[
                  { value: true, displayValue: 'FREE' },
                  { value: false, displayValue: 'AUTH' }
                ]}
                helperText="Switching modes alters the software settings; when set to FREE, the charger will not identify or authenticate the user that is charging."
                fullWidth={true}
              />
            </>
          </PaperWithTitle>
        </Form>
      )}
    </Formik>
  );
};

export default EditChargerInfoForm;
