import * as React from 'react';
import LocationAdminsProvider from './LocationAdminProvider';

export const withLocationAdmins =
  <T extends Record<string, any>>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: T) =>
    (
      <LocationAdminsProvider>
        <Component {...props} />
      </LocationAdminsProvider>
    );
