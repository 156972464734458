import { LocationDetailsFlowState } from '../LocationDetails';
import { Osp } from '../Osp';
import { AddEventType, InitialStateType } from '../SharedStates';
import { EmspCharger } from '../charger/Charger';
import { EmspOrganisation } from '../organisation/Organisation';
import { EmspLocation } from './Location';
import { LocationUser } from './user/LocationUser';
import { CpoLocationResponseDto } from './LocationDto';

export enum LocationsFlowState {
  INIT = 'INIT',
  LOCATIONS_LOADED = 'LOCATIONS_LOADED',
  FAILED_TO_LOAD_LOCATIONS = 'FAILED_TO_LOAD_LOCATIONS',
  INIT_CHARGERS_FOR_LOCATION = 'INIT_CHARGERS_FOR_LOCATION',
  CHARGERS_LOADED_FOR_LOCATION = 'CHARGERS_LOADED_FOR_LOCATION',
  FAILED_TO_LOAD_CHARGERS_FOR_LOCATION = 'FAILED_TO_LOAD_CHARGERS_FOR_LOCATION'
}

export type LocationState = {
  loadingState: InitialStateType;
  locations: [] | null;
  loading: boolean;
  location: Record<string, unknown> | null;
  emspLocation: EmspLocation | null;
  cpoLocation: CpoLocationResponseDto | null;
  cpoLocationId: string | null;
  flowState: LocationDetailsFlowState | LocationsFlowState | null;
  newLocation: Record<string, unknown> | null;
  newLocationId: string | null;
  updateLocation: Record<string, unknown> | null;
  updatedLocationId: string | null;
  updateLocationErrorMessage: string | null;
  showLocationUpdatedSuccessBar: boolean;
  newLocationErrorMessage: string | null;
  osps: Osp[] | [];
  organisations: EmspOrganisation[] | [];
  chargers: EmspCharger[] | [];
  users: LocationUser[] | [];
};

export type UseLocationsType = {
  state: LocationState;
  addEvent: AddEventType;
};

export type EmspLocationDetailsFlowState = LocationDetailsFlowState & {
  location: EmspLocation;
  chargers: EmspCharger[];
  users: LocationUser[];
  osp: Osp;
  organisation: EmspOrganisation;
};
