import React, {createContext, useContext, useReducer} from 'react';
import _ from 'lodash';
import chargePointGroupService from '../../../../services/chargePointGroupService';

// events
export const ChargePointGroupInfoEvent = {
  REMOVE_CHARGE_POINT_GROUP_DIALOG_REQUESTED: 'REMOVE_CHARGE_POINT_GROUP_DIALOG_REQUESTED',
  REMOVE_CHARGE_POINT_GROUP_DIALOG_SUBMITTED: 'REMOVE_CHARGE_POINT_GROUP_DIALOG_SUBMITTED',
  REMOVE_CHARGE_POINT_GROUP_DIALOG_CLOSED: 'REMOVE_CHARGE_POINT_GROUP_DIALOG_CLOSED',
};

// flow states
export const ChargePointGroupInfoFlowState = {
  INIT: 'INIT',
  SHOWING_REMOVE_CHARGE_POINT_GROUP_DIALOG: 'SHOWING_REMOVE_CHARGE_POINT_GROUP_DIALOG',
  CHARGE_POINT_GROUP_REMOVED: 'CHARGE_POINT_GROUP_REMOVED',
  FAILED_TO_REMOVE_CHARGE_POINT_GROUP: 'FAILED_TO_REMOVE_CHARGE_POINT_GROUP',
};

// initial state
const initialState = {
  flowState: ChargePointGroupInfoFlowState.INIT,
  chargePointGroupErrorMessage: null,
};

// reducer
const reducer = (state, newState) => {
  return {...state, ...newState};
};

// context
const chargePointGroupInfoContext = createContext();

// provider
export const ChargePointGroupInfoProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <chargePointGroupInfoContext.Provider value={{state, dispatch}}>{children}</chargePointGroupInfoContext.Provider>
  );
};

// hook
const useChargePointGroupInfo = () => {
  const {state, dispatch} = useContext(chargePointGroupInfoContext);

  const addEvent = (event) => {
    switch (event.type) {
      case ChargePointGroupInfoEvent.REMOVE_CHARGE_POINT_GROUP_DIALOG_REQUESTED:
        dispatch({
          flowState: ChargePointGroupInfoFlowState.SHOWING_REMOVE_CHARGE_POINT_GROUP_DIALOG,
          chargePointGroupErrorMessage: null,
        });
        break;
      case ChargePointGroupInfoEvent.REMOVE_CHARGE_POINT_GROUP_DIALOG_CLOSED:
        dispatch({
          flowState: ChargePointGroupInfoFlowState.INIT,
        });
        break;
      case ChargePointGroupInfoEvent.REMOVE_CHARGE_POINT_GROUP_DIALOG_SUBMITTED:
        chargePointGroupService.removeGroup(event.payload.chargePointGroupId).subscribe(
          () =>
            dispatch({
              flowState: ChargePointGroupInfoFlowState.CHARGE_POINT_GROUP_REMOVED,
            }),
          (error) => {
            dispatch({
              flowState: ChargePointGroupInfoFlowState.FAILED_TO_REMOVE_CHARGE_POINT_GROUP,
              chargePointGroupErrorMessage: error.message,
            });
          }
        );
        break;
      default:
        console.log(event.type);
        throw new Error(`Unhandled event: ${event}`);
    }
  };
  return {
    state,
    addEvent,
  };
};
export default useChargePointGroupInfo;
