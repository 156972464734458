import React, {useEffect} from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PropTypes from 'prop-types';
import useUserTransactions, {UserTransactionsEvent, UserTransactionsFlowState} from './hook/useUserTransactions';
import {withUserTransactions} from './hook/withUserTransactions';
import TransactionsTable from '../../Transaction/TransactionsTable/TransactionsTable';

const UserTransactions = ({userId}) => {
  const {state, addEvent} = useUserTransactions();

  useEffect(() => {
    if (state.flowState === UserTransactionsFlowState.INIT) {
      addEvent({
        type: UserTransactionsEvent.PAGE_ENTERED,
        userId: userId,
      });
    }
  }, [state.flowState, addEvent, userId]);

  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        {state.flowState === UserTransactionsFlowState.SHOWING_USER_TRANSACTIONS && <TransactionsTable transactions={state.transactions} hideUserInfo={true} />}
      </LoadingContainerWithErrorPanel>
    </>
  );
};

UserTransactions.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default withUserTransactions(UserTransactions);
