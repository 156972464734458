import React, {createContext, useContext, useReducer} from 'react';
import chargePointGroupService from '../../../services/chargePointGroupService';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState,
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import locationService from '../../../services/locationService';
import _ from 'lodash';

// events
export const ChargePointEvent = {
  LOAD_CHARGE_POINTS: 'LOAD_CHARGE_POINTS',
  LOAD_CHARGE_POINT: 'LOAD_CHARGE_POINT',
  SHOW_UPDATE_CHARGE_POINT_FORM: 'SHOW_UPDATE_CHARGE_POINT_FORM',
  CLOSE_UPDATE_CHARGE_POINT_FORM: 'CLOSE_UPDATE_CHARGE_POINT_FORM',
  SUBMIT_UPDATE_CHARGE_POINT_FORM: 'SUBMIT_UPDATE_CHARGE_POINT_FORM',
  SHOW_RESET_CHARGE_POINT_FORM: 'SHOW_RESET_CHARGE_POINT_FORM',
  CLOSE_RESET_CHARGE_POINT_FORM: 'CLOSE_RESET_CHARGE_POINT_FORM',
  SUBMIT_RESET_CHARGE_POINT_FORM: 'SUBMIT_RESET_CHARGE_POINT_FORM',
};

// flow states
export const ChargePointFlowState = {
  INIT: 'INIT',
  CHARGE_POINTS_LOADED: 'CHARGE_POINTS_LOADED',
  FAILED_TO_LOAD_CHARGE_POINTS: 'FAILED_TO_LOAD_CHARGE_POINTS',
  CHARGE_POINT_LOADED: 'CHARGE_POINT_LOADED',
  FAILED_TO_LOAD_CHARGE_POINT: 'FAILED_TO_LOAD_CHARGE_POINT',
  SHOWING_UPDATE_CHARGE_POINT_FORM: 'SHOWING_UPDATE_CHARGE_POINT_FORM',
  FAILED_TO_LOAD_CHARGE_POINT_GROUPS: 'FAILED_TO_LOAD_CHARGE_POINT_GROUPS',
  CHARGE_POINT_UPDATED: 'CHARGE_POINT_UPDATED',
  FAILED_TO_UPDATE_CHARGE_POINT: 'FAILED_TO_UPDATE_CHARGE_POINT',
  UPDATE_CHARGE_POINT_FORM_CLOSED: 'UPDATE_CHARGE_POINT_FORM_CLOSED',
  SHOWING_RESET_CHARGE_POINT_FORM: 'SHOWING_RESET_CHARGE_POINT_FORM',
  FAILED_TO_RESET_CHARGE_POINT: 'FAILED_TO_RESET_CHARGE_POINT',
  RESET_CHARGE_POINT_FORM_CLOSED: 'RESET_CHARGE_POINT_FORM_CLOSED',
  CHARGE_POINT_RESET_SUBMITTED: 'CHARGE_POINT_RESET_SUBMITTED',
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  chargePoints: [],
  chargePoint: null,
  chargePointGroups: [],
  flowState: ChargePointFlowState.INIT,
  updateChargePoint: null,
  updateChargePointErrorMessage: null,
  updatedChargePointId: null,
  resetChargePoint: null,
  resetChargePointErrorMessage: null,
  resetSuccessMessage: null,
};

// reducer
const reducer = (state, newState) => ({...state, ...newState});

// context
const chargePointContext = createContext();

// provider
export const ChargePointProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <chargePointContext.Provider value={{state, dispatch}}>{children}</chargePointContext.Provider>
  );
};

// hook
const useChargePoints = () => {
  const {state, dispatch} = useContext(chargePointContext);

  const addEvent = (event) => {
    switch (event.type) {
      case ChargePointEvent.LOAD_CHARGE_POINTS:
        locationService.getLocations(event.payload?.chargePointGroupId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              chargePoints: result,
              flowState: ChargePointFlowState.CHARGE_POINTS_LOADED,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointFlowState.FAILED_TO_LOAD_CHARGE_POINTS,
            })
        );
        break;
      case ChargePointEvent.LOAD_CHARGE_POINT:
        locationService.getLocationById(event.payload.id).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              chargePoint: result,
              flowState: ChargePointFlowState.CHARGE_POINT_LOADED,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointFlowState.FAILED_TO_LOAD_CHARGE_POINT,
            })
        );
        break;
      case ChargePointEvent.SHOW_UPDATE_CHARGE_POINT_FORM:
        chargePointGroupService.getAllGroupsForAdmin().subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              chargePointGroups: result,
              updateChargePoint: event.payload,
              updatedChargePointId: null,
              updateChargePointErrorMessage: null,
              flowState: ChargePointFlowState.SHOWING_UPDATE_CHARGE_POINT_FORM,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointFlowState.FAILED_TO_LOAD_CHARGE_POINT_GROUPS,
            })
        );
        break;
      case ChargePointEvent.CLOSE_UPDATE_CHARGE_POINT_FORM:
        dispatch({
          flowState: ChargePointFlowState.UPDATE_CHARGE_POINT_FORM_CLOSED,
        });
        break;
      case ChargePointEvent.SUBMIT_UPDATE_CHARGE_POINT_FORM:
        const values = event.payload.values;
        dispatch({
          updateChargePointErrorMessage: null,
        });

        locationService.updateLocation(event.payload.initialId, values.id, values.mode, values.group, values.electricityCost).subscribe(
          (_) => {
            dispatch({
              flowState: ChargePointFlowState.CHARGE_POINT_UPDATED,
              updatedChargePointId: values.id,
            });
          },
          (error) => {
            dispatch({
              flowState: ChargePointFlowState.FAILED_TO_UPDATE_CHARGE_POINT,
              updateChargePointErrorMessage: error.message,
            });
          }
        );
        break;
      case ChargePointEvent.SHOW_RESET_CHARGE_POINT_FORM:
        dispatch({
          flowState: ChargePointFlowState.SHOWING_RESET_CHARGE_POINT_FORM,
        });
        break;
      case ChargePointEvent.CLOSE_RESET_CHARGE_POINT_FORM:
        dispatch({
          resetChargePointErrorMessage: null,
          flowState: ChargePointFlowState.RESET_CHARGE_POINT_FORM_CLOSED,
        });
        break;
      case ChargePointEvent.SUBMIT_RESET_CHARGE_POINT_FORM:
        const resetChargePointId = event.payload.id;
        const type = event.payload.resetType; // hard or soft
        dispatch({
          resetChargePoint: null,
          resetChargePointErrorMessage: null,
          resetSuccessMessage: null,
        });

        locationService.resetLocation(resetChargePointId, type).subscribe(
          (result) => {
            dispatch({
              flowState: ChargePointFlowState.CHARGE_POINT_RESET_SUBMITTED,
              resetChargePoint: event.payload,
              resetSuccessMessage: `Charge point ${state.chargePoint.id} is resetting. Please refresh the page in ${(result.timeToWait / 1000).toString()} seconds`,
            });
          },
          (error) => {
            dispatch({
              flowState: ChargePointFlowState.FAILED_TO_RESET_CHARGE_POINT,
              resetChargePointErrorMessage: error.message,
            });
          }
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
  };
};

export default useChargePoints;
