import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { requiresPermission } from '../../hocs/requiresPermission';
import { DASHBOARD_PATH } from '../../routes/paths';
import { AccountType } from '../../types/account/Account';
import { Permission } from '../../types/user/UserPermission';
import Dashboard from '../Dashboard/Dashboard';
import ChargePointsReport from './ChargePointsReport';
import ChargersReport from './ChargersReport';
import PostpayTransactionsReportTable from './PostpayTransactionsReportTable';
import TransactionsReport from './TransactionsReport';
import TransactionsReportTable from './TransactionsReportTable';

const Reports = () => {
  const { pathname } = useLocation();
  const transactionsPath = `${DASHBOARD_PATH}/reports`;
  const chargePointsPath = `${DASHBOARD_PATH}/reports/charge-points`;
  const postpayTransactionsPath = `${DASHBOARD_PATH}/reports/post-pay-transactions`;
  const chargersPath = `${DASHBOARD_PATH}/reports/chargers`;

  return (
    <Dashboard pageHeading="Reports">
      <TabContext value={pathname}>
        <TabList aria-label="Report Tabs" indicatorColor="primary" textColor="primary">
          <Tab label="Transactions" value={transactionsPath} to={transactionsPath} component={Link} />
          <Tab label="Charge Points" value={chargePointsPath} to={chargePointsPath} component={Link} />
          <Tab label="Postpay Transactions" value={postpayTransactionsPath} to={postpayTransactionsPath} component={Link} />
          <Tab label="Chargers" value={chargersPath} to={chargersPath} component={Link} />
        </TabList>
        <TabPanel value={transactionsPath}>
          <TransactionsReport tableComponent={TransactionsReportTable} accountType={undefined} />
        </TabPanel>
        <TabPanel value={chargePointsPath}>
          <ChargePointsReport />
        </TabPanel>
        <TabPanel value={postpayTransactionsPath}>
          <TransactionsReport tableComponent={PostpayTransactionsReportTable} accountType={AccountType.POSTPAY} />
        </TabPanel>
        <TabPanel value={chargersPath}>
          <ChargersReport />
        </TabPanel>
      </TabContext>
    </Dashboard>
  );
};

export default requiresPermission(Permission.VIEW_REPORTS)(Reports);
