import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import mainTheme from '../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Divider} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subheading: {
    marginTop: theme.spacing(2),
  },
}));

const SubHeading = ({text}) => {
  const classes = useStyles(mainTheme);
  return (
    <Box className={classes.root}>
      <Divider className={classes.divider} />
      <Typography className={classes.subheading} variant="h2">
        {text}
      </Typography>
    </Box>
  );
};

SubHeading.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SubHeading;
