import { AddEventType, InitialStateType } from '../SharedStates';
import { EmspCharger } from './Charger';

export type ChargerState = {
  loadingState: InitialStateType;
  flowState: ChargersFlowState | null;
  chargers: EmspCharger[];
};

export type UseChargersType = {
  state: ChargerState;
  addEvent: AddEventType;
};

export enum ChargersFlowState {
  INIT = 'INIT',
  CHARGERS_LOADED = 'CHARGERS_LOADED',
  FAILED_TO_LOAD_CHARGERS = 'FAILED_TO_LOAD_CHARGERS'
}
