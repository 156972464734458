import TablePagination from '@material-ui/core/TablePagination';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import NavigationLink from '../../commons/link/NavigationLink';
import { WarningBar } from '../../commons/snackbars/snackbars';
import MaterialTableWrapper, { ROWS_PER_PAGE_OPTIONS } from '../../commons/table/MaterialTableWrapper';
import dateTimeFormatter from '../../utils/date/dateTimeFormatter';
import { buildQueryString } from '../../utils/url/buildQueryString';

const UsersTable = ({ users, search, sortBy, sortDirection, page, countPerPage }) => {
  const history = useHistory();
  const location = useLocation();
  const tableRef = useRef();

  // handle search
  const handleChangeSearch = (newSearch) => navigate(newSearch, sortBy, sortDirection, 1, countPerPage);

  // handle sort
  const handleOrderChange = (newSortBy, newSortDirection) => {
    // TODO: find out why newSortBy = -1 and newSortDirection = "" ??????
    if (newSortBy >= 0) {
      navigate(search, tableRef.current.dataManager.columns[newSortBy].field, newSortDirection, page, countPerPage);
    }
  };

  // handle page change (next/previous)
  const handleChangePage = (newPage) => navigate(search, sortBy, sortDirection, newPage, countPerPage);

  // handle count change
  const handleChangeRowsPerPage = (newCountPerPage) => navigate(search, sortBy, sortDirection, page, newCountPerPage);

  // update location to refresh the table
  const navigate = (search, sortBy, sortDirection, page, countPerPage) => {
    const queryString = buildQueryString({
      search: search,
      sort_by: sortBy,
      sort_direction: sortDirection,
      page: page,
      count_per_page: countPerPage,
    });
    history.push(`${location.pathname}?${queryString}`);
  };

  return (
    <>
      <MaterialTableWrapper
        tableRef={tableRef}
        columns={[
          {
            title: 'Id',
            field: 'id',
            width: 'auto',
            hidden: true,
            hiddenByColumnsButton: true
          },
          {
            title: 'Name',
            field: 'name',
            width: 'auto',
            defaultSort: sortBy === 'name' ? sortDirection : null,
            customSort: (data1, data2, type) => { },
            render: (rowData) => <NavigationLink path={`${location.pathname}/${rowData.id}`} text={rowData.name} />,
            // Searching/filtering is already done by HandleChangeSearch, so return all users
            customFilterAndSearch: (term, rowData) => true,
          },
          {
            title: 'Email',
            field: 'email',
            width: 'auto',
            defaultSort: sortBy === 'email' ? sortDirection : null,
            customSort: (data1, data2, type) => { },
          },
          {
            title: 'Created',
            field: 'created_at',
            width: 'auto',
            render: (rowData) => dateTimeFormatter.printReadableDateTime(rowData.created_at),
            customFilterAndSearch: (filter, rowData) => _.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.created_at), filter),
            defaultSort: sortBy === 'created_at' ? sortDirection : null,
            customSort: (data1, data2, type) => { },
            export: false,
          },
          {
            title: 'Created',
            field: 'readableCreated',
            hidden: true,
            export: true,
          },
        ]}
        data={users.map((user) => ({
          id: user.id,
          name: user.name,
          email: user.email,
          created_at: user.created,
          readableCreated: dateTimeFormatter.printReadableDateTime(user.created),
        }))}
        exportFileName="Users"
        onSearchChange={handleChangeSearch}
        debounceInterval={300}
        onOrderChange={handleOrderChange}
        pageSize={countPerPage}
        searchText={search}
        filtering={false}
        components={{
          Pagination: (props) => (
            <TablePagination
              SelectProps={props.SelectProps}
              labelRowsPerPage=""
              labelDisplayedRows={({ from, to, count }) =>
                !from ? `No records found on page ${page}${page !== 1 ? '. Please go back!' : ''}` : `${count > 0 ? count : countPerPage} Records on Page ${page}`
              }
              page={page - 1}
              count={_.isEmpty(users) ? 0 : users.length < countPerPage ? users.length : -1}
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              rowsPerPage={countPerPage}
              onPageChange={(e, page) => handleChangePage(page + 1)}
              onRowsPerPageChange={(event) => {
                props.onChangeRowsPerPage(event);
                handleChangeRowsPerPage(event.target.value);
              }}
            />
          ),
        }}
      />
      <WarningBar open={_.isEmpty(users) && page !== 1}>No more records found on page {page}. Please go back to previous pages!</WarningBar>
    </>
  );
};

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  search: PropTypes.string,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  page: PropTypes.number.isRequired,
  countPerPage: PropTypes.number.isRequired,
};

export default UsersTable;
