import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { FC, createRef, useEffect } from 'react';
import * as Yup from 'yup';
import PaperWithTitle from '../../commons/containers/PaperWithTile/PaperWithTitle';
import SmartRadioField from '../../commons/form/SmartRadioField';
import SmartSelectField from '../../commons/form/SmartSelectField';
import SmartTextField from '../../commons/form/SmartTextField';
import CancelButton from '../../commons/form/button/CancelButton';
import PageWithColumns from '../../commons/layout/PageWithColumns';
import { ErrorBar } from '../../commons/snackbars/snackbars';
import mainTheme from '../../themes/mainTheme';
import { AccountType } from '../../types/account/Account';
import { EmspOrganisation } from '../../types/organisation/Organisation';

const useStyles = makeStyles(() => ({
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16
  }
}));

interface NewAccountFormProps {
  accountType?: AccountType;
  name: string;
  errorMessage?: string;
  onSubmit: (values: { name: string }) => void;
  onCancel: () => void;
  disableSubmit: boolean;
  organisations: EmspOrganisation[];
  orgId?: string;
  onCloseError?: () => void;
}

const AccountForm: FC<NewAccountFormProps> = ({
  name,
  errorMessage,
  onSubmit,
  onCancel,
  disableSubmit,
  organisations,
  orgId,
  onCloseError,
  accountType
}) => {
  const classes = useStyles(mainTheme);
  const formikRef = createRef<any>();

  const initialValues = {
    name,
    orgId: orgId || '',
    accountType: accountType ? AccountType[accountType] : ''
  };

  const validationSchema = Yup.object({
    name: Yup.string().max(50).required('Required'),
    orgId: Yup.string().required('Required')
  });

  useEffect(() => {
    if (disableSubmit) {
      formikRef.current.setSubmitting(false);
    }
  }, [disableSubmit, formikRef]);

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        enableReinitialize
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isValid }) => (
          <Form>
            <PaperWithTitle title="General">
              <>
                <PageWithColumns
                  columnOne={
                    <>
                      <Field label="Name" name="name" component={SmartTextField} helperText="Name of Account" autoFocus />
                      <Field
                        label="Organisation"
                        name="orgId"
                        component={SmartSelectField}
                        options={organisations.map((organisation) => {
                          return {
                            value: organisation.id,
                            displayValue: organisation.name
                          };
                        })}
                        defaultValue={orgId || ''}
                        helperText="Select organisation for this account"
                        fullWidth={true}
                      />
                      <Field
                        label="Account Type"
                        name="accountType"
                        component={SmartRadioField}
                        fullWidth={true}
                        options={[
                          { value: AccountType.PAYG, displayValue: AccountType.PAYG },
                          { value: AccountType.POSTPAY, displayValue: AccountType.POSTPAY }
                        ]}
                        helperText="Specify whether the account is PostPay or Pay-As-You-Go enabled"
                      />
                    </>
                  }
                  columnTwo={<></>}
                />

                <Box className={classes.submitButtonContainer}>
                  <CancelButton handleCancel={onCancel} />
                  <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
                    Submit
                  </Button>
                </Box>
              </>
            </PaperWithTitle>
          </Form>
        )}
      </Formik>
      <ErrorBar fullPage={false} open={!!errorMessage} onClose={onCloseError}>
        {errorMessage}
      </ErrorBar>
    </>
  );
};

export default AccountForm;
