import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

/**
 * Functional component for a smart multi-select field.
 *
 * @param {Object} field - the name of the field
 * @param {boolean} allowNull - indicates if select field allows a null component
 * @param {string} label - the label for the field
 * @param {string} helperText - the helper text for the field
 * @param {boolean} fullWidth - indicates if the field should take up the full width
 * @param {Array} options - the dropdown options for the multi-select field
 * @param {Object} form - the form object
 * @param {...any} rest - any other additional props
 * @return {JSX.Element} the smart multi-select field component
 */
const SmartMultiSelectField = ({ field, allowNull, label, helperText, fullWidth, options, form, ...rest }) => {
  const selectId = `select-${uuidV4()}`;
  const labelId = `${selectId}-label`;

  const [ids, setIds] = useState(field.value);

  /**
   * Handles the change event and updates the ids state accordingly.
   *
   * @param {Object} event - The event object
   * @return {void} 
   */
  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setIds(
      typeof value === "string" ? value.split(",") : value
    );
  }

  return (
    <FormControl fullWidth={fullWidth} disabled={form.isSubmitting}>
      <InputLabel id={labelId} shrink={true}>{label}</InputLabel>
      <Select
        labelId={labelId}
        inputProps={{
          ...field,
        }}
        multiple
        {...rest}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        value={ids}
        onChange={handleChange}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return "None"
          }
          return options
            .filter((option) => selected.indexOf(option.id) > -1)
            .map((option) => option.value)
            .join(", ");
        }}
        displayEmpty={allowNull}
      >
        {options.map((optionEntry) => {
          return (
            <MenuItem dense key={optionEntry.id} value={optionEntry.id}>
              <Checkbox size="small" checked={ids.indexOf(optionEntry.id) > -1} />
              <ListItemText primary={optionEntry.value} />
            </MenuItem>
          )
        })}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

SmartMultiSelectField.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  options: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
};

export default SmartMultiSelectField;
