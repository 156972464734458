import _ from 'lodash';
import { createContext, useContext, useReducer } from 'react';
import { chargePointStatus } from '../../../../domain/chargePoint';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import emspChargerServicev4 from '../../../../services/emspChargerServicev4';

// events
export const ChargePointStatusChartEvent = {
  PAGE_ENTERED: 'PAGE_ENTERED'
};

// flow states
export const ChargePointStatusChartFlowState = {
  INIT: 'INIT',
  LOADED: 'LOADED',
  ERROR: 'ERROR'
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  statusToNumberOfChargePointsList: [],
  flowState: ChargePointStatusChartFlowState.INIT
};

// reducer
const reducer = (state, newState) => ({ ...state, ...newState });

// context
const chargePointStatusChartContext = createContext();

// provider
export const ChargePointStatusChartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <chargePointStatusChartContext.Provider value={{ state, dispatch }}>{children}</chargePointStatusChartContext.Provider>
  );
};

// hook
const useChargePointStatusChart = () => {
  const { state, dispatch } = useContext(chargePointStatusChartContext);

  const buildStatusToNumberOfChargePointsList = (locations) => {
    const statusToNumberOfChargePointsMap = {};
    locations
      .filter((location) => location.status !== chargePointStatus.DECOMMISSIONED)
      .forEach((location) => (statusToNumberOfChargePointsMap[location.status] = (statusToNumberOfChargePointsMap[location.status] ?? 0) + 1));

    const statusToNumberOfChargePointsList = Object.entries(chargePointStatus)
      .map(([_, status]) => status)
      .filter((status) => status !== chargePointStatus.DECOMMISSIONED)
      .map((status) => ({
        key: status,
        value: statusToNumberOfChargePointsMap[status] ?? 0
      }));

    return _.sortBy(statusToNumberOfChargePointsList, ['key']);
  };

  const addEvent = (event) => {
    switch (event.type) {
      case ChargePointStatusChartEvent.PAGE_ENTERED:
        emspChargerServicev4.getChargers().subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              statusToNumberOfChargePointsList: buildStatusToNumberOfChargePointsList(result),
              flowState: ChargePointStatusChartFlowState.LOADED
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointStatusChartFlowState.ERROR
            })
        );
        break;
      default:
        console.log(event);
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useChargePointStatusChart;
