import MaterialTableWrapper from '../../commons/table/MaterialTableWrapper';
import React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import dateTimeFormatter from '../../utils/date/dateTimeFormatter';

const ChargePointsTable = ({locations}) => (
  <MaterialTableWrapper
    columns={[
      {
        title: 'Fleet',
        field: 'owner',
      },
      {
        title: 'Charge Point Id',
        field: 'chargePointId',
      },
      {
        title: 'Address',
        field: 'address',
        width: 'auto',
      },
      {
        title: 'Power Type',
        field: 'powerType',
        width: 'auto',
        cellStyle: {width: 70},
      },
      {
        title: 'Commissioned',
        field: 'commissioned',
        render: (rowData) => dateTimeFormatter.printReadableDateTime(rowData.commissioned),
        customFilterAndSearch: (filter, rowData) => _.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.commissioned), filter),
        export: false,
      },
      {
        title: 'Commissioned',
        field: 'readableCommissioned',
        hidden: true,
        export: true,
      },
      {
        title: 'Decommissioned',
        field: 'decommissioned',
        render: (rowData) => (_.isNil(rowData.decommissioned) ? '' : dateTimeFormatter.printReadableDateTime(rowData.decommissioned)),
        customFilterAndSearch: (filter, rowData) => (_.isNil(rowData.decommissioned) ? false : _.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.decommissioned), filter)),
        export: false,
      },
      {
        title: 'Decommissioned',
        field: 'readableDecommissioned',
        hidden: true,
        export: true,
      },
    ]}
    data={locations.map((location) => ({
      owner: location.owner,
      chargePointId: location.id,
      address: location.address,
      powerType: location.powerType,
      commissioned: location.commissioned,
      readableCommissioned: dateTimeFormatter.printReadableDateTime(location.commissioned),
      decommissioned: location.decommissioned,
      readableDecommissioned: _.isNil(location.decommissioned) ? '' : dateTimeFormatter.printReadableDateTime(location.decommissioned),
    }))}
    exportFileName="Charge Points"
  />
);

ChargePointsTable.propTypes = {
  locations: PropTypes.array.isRequired,
};

export default ChargePointsTable;
