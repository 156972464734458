import { FC } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import ValueWithUnit from '../../../commons/texts/ValueWithUnit';
import transactionInsightService from '../../../services/transactionInsightService';
import mainTheme from '../../../themes/mainTheme';
import ChargePointInsightBarGraph from './ChargePointInsightBarGraphs';

interface ChargePointCarbonOffsetProps {
  loadingTransactionsState: any;
  transactions: any;
  lastNumberOfDays: number;
}

const ChargePointCarbonOffset: FC<ChargePointCarbonOffsetProps> = ({ loadingTransactionsState, transactions, lastNumberOfDays }) => (
  <PaperWithTitle
    title="Carbon Offset"
    topRightComponent={
      <ValueWithUnit value={transactionInsightService.calculateTotalCarbonOffset(transactions).toString()} unit="kg CO2" textAlign="right" />
    }
  >
    <LoadingContainerWithErrorPanel {...loadingTransactionsState}>
      <ChargePointInsightBarGraph
        dataset={transactionInsightService.buildCarbonOffsetData(transactions, lastNumberOfDays)}
        color={mainTheme.palette.chart.carbonOffset}
      />
    </LoadingContainerWithErrorPanel>
  </PaperWithTitle>
);

export default ChargePointCarbonOffset;
