import * as React from 'react';
import { AccountUsersProvider } from './useAccountUsers';

export const withAccountUsers =
  <T extends Record<string, any>>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: T) =>
    (
      <AccountUsersProvider>
        <Component {...props} />
      </AccountUsersProvider>
    );
