import { ChargerRemoteStartContainerProps } from '../ChargerRemoteStartContainer';
import { ChargerRemoteStartProvider } from './RemoteStartProvider';

interface WithRemoteStartTransactionsProps {
  (Component: React.FC<ChargerRemoteStartContainerProps>): (props: any) => JSX.Element;
}

export const withRemoteStartTransactions: WithRemoteStartTransactionsProps = (Component) => (props) =>
  (
    <ChargerRemoteStartProvider>
      <Component {...props} />
    </ChargerRemoteStartProvider>
  );
