import { Observable, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import ConflictException from '../auth/exceptions/ConflictException';
import ForbiddenException from '../auth/exceptions/ForbiddenException';
import PageNotFoundException from '../auth/exceptions/PageNotFoundException';

export const handleError = (error: AjaxError, resource?: string): Observable<never> => {
  const errorMessage = error.response?.message ?? error.response?.error ?? 'An unknown error occurred';
  if (error.status === 409) return throwError(new ConflictException(errorMessage));
  if (error.status === 404) return throwError(new PageNotFoundException(`Sorry, we can't find that ${resource ? resource : 'page'}!`));
  if (error.status === 403)
    return throwError(
      new ForbiddenException('Sorry, you do not have permission to access this page. Please contact support if this is not what you expect.')
    );
  return throwError(new Error(errorMessage));
};
