import * as Yup from 'yup';

const patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/;

export const electrictyCostValidation = Yup.number()
  .test('is-decimal', 'Please enter a number that is up to 2 decimal places. e.g. 0.30', (val) => {
    if (val !== undefined) {
      return patternTwoDigitsAfterComma.test(val);
    }
    return true;
  })
  .min(0);
