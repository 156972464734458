import * as React from 'react';
import { ChargerDetailsProvider } from './ChargerDetailsProvider';
import { ChargerTabsProps } from '../ChargerTabs';

interface WithChargerDetailsProps {
  (Component: React.FC<ChargerTabsProps>): (props: ChargerTabsProps) => JSX.Element;
}

export const withChargerDetails: WithChargerDetailsProps = (Component) => (props) =>
  (
    <ChargerDetailsProvider>
      <Component {...props} />
    </ChargerDetailsProvider>
  );
