import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import mainTheme from '../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(2),
    flexWrap: 'wrap',
    maxWidth: '100%',
    minWidth: 0
  },
  infoFieldsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    minWidth: 300,
    width: '49%'
  }
}));

type PageWithColumnsProps = {
  columnOne: JSX.Element | Element;
  columnTwo: JSX.Element | Element;
};
/**
 * Renders a page with two columns.
 *
 * @param {JSX.Element} columnOne - The content for the first column
 * @param {JSX.Element} columnTwo - The content for the second column
 * @return {JSX.Element} The page with two columns
 */
const PageWithColumns: FC<PageWithColumnsProps> = ({ columnOne, columnTwo }) => {
  const classes = useStyles(mainTheme);

  return (
    <Box className={classes.infoContainer}>
      <Box className={classes.infoFieldsContainer}>{columnOne}</Box>
      <Box className={classes.infoFieldsContainer}>{columnTwo}</Box>
    </Box>
  );
};

export default PageWithColumns;
