import SmartMultiEmailField from '../../../../commons/form/SmartMultiEmailField';
import Button from '@material-ui/core/Button';
import React, {useState} from 'react';
import mainTheme from '../../../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const EnterEmailsBody = ({onSubmit}) => {
  const classes = useStyles(mainTheme);
  const [emails, setEmails] = useState([]);

  const handleOnClick = (event) => {
    event.preventDefault();
    onSubmit(emails);
  };

  return (
    <>
      <SmartMultiEmailField
        label="Emails"
        name="emails"
        autoFocus
        helperText="Please enter the email addresses (space delimited) of the users you would like to add to this charge point fleet e.g. user1@organisation.com user2@organisation.com"
        onChange={setEmails}
      />
      <Button className={classes.button} type="button" variant="contained" color="primary" fullWidth onClick={handleOnClick}>
        Add
      </Button>
    </>
  );
};

EnterEmailsBody.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default EnterEmailsBody;
