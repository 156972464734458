import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoxWithLabel from '../../../../commons/containers/ComponentWithLabel/BoxWithLabel';
import TextWithLabel from '../../../../commons/containers/ComponentWithLabel/TextWithLabel';
import EditButton from '../../../../commons/form/button/EditButton';
import { ConnectorPowerTypeDisplay, ConnectorStandardDisplay, CpoConnector, EmspConnector } from '../../../../types/charger/Charger';
import ChargePointConnectorStatus from '../../../ChargePointDetails/ChargePointConnector/ChargePointConnectorStatus';
import ChargerConnectorIcon from '../../ChargerConnectors/ChargerConnectorIcon';
import BoxWithTitle from '../../../../commons/containers/PaperWithTile/BoxWithTitle';

type CpoOrEmspConnector = CpoConnector | EmspConnector;

interface ChargerConnectorsPanelProps {
  connectors: CpoOrEmspConnector[];
  canEditConnectors?: boolean;
  handleEditConnectors?: () => void;
}

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(2),
    flexWrap: 'wrap',
    maxWidth: '100%',
    minWidth: 0,
    borderBottom: `1px dashed ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),

    '&:last-of-type': {
      minWidth: 0,
      borderBottom: 'none',
      paddingBottom: 0
    }
  },
  connectorTitleHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  column: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    minWidth: 300,
    width: '49%'
  }
}));

export const ChargerConnectorsPanel: React.FC<ChargerConnectorsPanelProps> = ({ connectors, canEditConnectors, handleEditConnectors }) => {
  const classes = useStyles();
  const isEmspConnector = (connector: CpoOrEmspConnector): connector is EmspConnector => {
    return (connector as EmspConnector).cpoConnectorId !== undefined;
  };
  const sortEmspConnectors = (connectors: EmspConnector[]): EmspConnector[] => {
    return connectors.sort((a, b) =>
      a.evseReference === b.evseReference ? Number(a.cpoConnectorId) - Number(b.cpoConnectorId) : Number(a.evseReference) - Number(b.evseReference)
    );
  };

  const sortCpoConnectors = (connectors: CpoConnector[]): CpoConnector[] => {
    return connectors.sort((a, b) =>
      a.evseReference === b.evseReference ? a.ocppConnectorId - b.ocppConnectorId : Number(a.evseReference) - Number(b.evseReference)
    );
  };

  const allConnectors = connectors.flatMap((connector) => connector);
  const sortedConnectors = isEmspConnector(connectors[0])
    ? sortEmspConnectors(allConnectors as EmspConnector[])
    : sortCpoConnectors(allConnectors as CpoConnector[]);
  const connectorActions = <>{canEditConnectors ? <EditButton disabled={false} handleEdit={handleEditConnectors} /> : null}</>;

  return (
    <BoxWithTitle title="Connectors" topRightComponent={connectorActions}>
      <>
        {sortedConnectors?.map((connector, index) => {
          const evseReference = connector.evseReference;
          const connectorReference = connector.connectorReference;

          return (
            <Box key={index} className={classes.infoContainer}>
              <Box className={classes.column}>
                <ChargerConnectorIcon type={connector.standard} />
                {evseReference !== null && <TextWithLabel label="Evse Reference" text={connector.evseReference} />}
                {connectorReference !== null && <TextWithLabel label="Connector Reference" text={connectorReference} />}
                <TextWithLabel label="Type" text={ConnectorStandardDisplay.get(connector.standard)} />
                {connectors && connector.status !== undefined && (
                  <BoxWithLabel label="Status">
                    <ChargePointConnectorStatus status={connector.status} />
                  </BoxWithLabel>
                )}
              </Box>

              <Box className={classes.column}>
                <TextWithLabel label="Format" text={connector.format} />
                <TextWithLabel label="Power Type" text={ConnectorPowerTypeDisplay.get(connector.powerType)} />
                <TextWithLabel label="Max Amperage (A)" text={connector.maxAmperage} />
                <TextWithLabel label="Max Voltage (V)" text={connector.maxVoltage} />
                <TextWithLabel label="Max Power (Watts)" text={connector.maxElectricPower} />
              </Box>
            </Box>
          );
        })}
      </>
    </BoxWithTitle>
  );
};
