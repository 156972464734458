import moment from 'moment';
import { ajax } from 'rxjs/ajax';
import { catchError, delay, map } from 'rxjs/operators';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { errorMessageObservable } from '../utils/error/errorUtils';
import { handleError } from '../utils/error/handleApiError';
import { buildQueryString } from '../utils/url/buildQueryString';

const toTransactionSummary = (source) => ({
  id: source.id,
  groupId: source.group_id,
  status: source.status,
  user: {
    id: source.user.id,
    name: source.user.name,
    email: source.user.email,
    mobileNumber: source.user.mobile_number,
    chargeTagId: source.user.charge_tag_id,
    accountName: source.user.account_name
  },
  location: {
    id: source.location.id,
    name: source.location.name,
    evseUid: source.location.evse_uid,
    address: source.location.address,
    coordinates: {
      latitude: source.location.coordinates.latitude,
      longitude: source.location.coordinates.longitude
    },
    owner: source.location.owner
  },
  session: {
    id: source.session.id,
    startDateTime: source.session.start_date_time,
    durationInSeconds: source.session.duration_in_seconds,
    kwh: source.session.kwh,
    latestKw: source.session.latest_kw,
    startStateOfCharge: source.session.start_state_of_charge,
    latestStateOfCharge: source.session.latest_state_of_charge
  },
  tariff: {
    ratePerKwh: source.tariff.rate_per_kwh,
    ratePerMinute: source.tariff.rate_per_minute,
    unlockFee: source.tariff.unlock_fee
  },
  payment: {
    unlockFeeTotal: source.payment.unlock_fee_total,
    kwhFeeTotal: source.payment.kwh_fee_total,
    minuteFeeTotal: source.payment.minute_fee_total,
    total: source.payment.total
  },
  carbonEmissions: {
    petrolEmissions: {
      emissionFactor: {
        value: source.carbon_emissions.petrol_emissions.emission_factor.value,
        unit: source.carbon_emissions.petrol_emissions.emission_factor.unit
      },
      amount: source.carbon_emissions.petrol_emissions.amount
    },
    kwhToKmConversionRate: source.carbon_emissions.kwh_to_km_conversion_rate,
    electricityEmissions: {
      emissionFactor: {
        value: source.carbon_emissions.electricity_emissions.emission_factor.value,
        unit: source.carbon_emissions.electricity_emissions.emission_factor.unit
      },
      amount: source.carbon_emissions.electricity_emissions.amount
    },
    carbonOffset: source.carbon_emissions.carbon_offset
  },
  operationalExpenditure: {
    // this is null for getTransactionsByUserIdForAdmin
    electricityCostTotal: source.operational_expenditure?.electricity_cost_total
  },
  paymentBreakdown: {
    // Payment breakdown will be null for active transactions
    prepaidAmount: source.payment_breakdown?.prepaid_amount ?? 0,
    creditCardPayment: source.payment_breakdown?.credit_card_payment ?? 0,
    postpayAmount: source.payment_breakdown?.postpay_amount ?? 0
  }
});
const toTransactionSummaries = (transactionSummaries) => transactionSummaries.map(toTransactionSummary);

const transactionService = {
  getTransactionsByChargePointIdForAdmin: (chargePointId) => {
    const queryString = buildQueryString({
      location_id: chargePointId
    });

    return withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV3}/transactions?${queryString}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toTransactionSummaries(response.response.data)),
      catchError((error) => handleError(error, 'transactions'))
    );
  },
  getTransactionsByUserIdForAdmin: (userId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV3}/users/${userId}/transactions`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toTransactionSummaries(response.response.data)),
      catchError((error) => handleError(error, 'user'))
    ),
  getTransactionsByOrgsForAdmin: (dateRange) => {
    const queryString = buildQueryString({
      date_from: dateRange.dateFrom.toISOString(),
      // moment.min(dateRange.dateTo, moment()) is to avoid posting dateTo in the future
      date_to: moment.min(dateRange.dateTo, moment()).toISOString()
    });

    return withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV3}/transactions?${queryString}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toTransactionSummaries(response.response.data)),
      catchError((error) => errorMessageObservable(error))
    );
  },
  getTransactionsById: (transactionId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV3}/transactions/${transactionId}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toTransactionSummary(response.response)),
      catchError((error) => handleError(error, 'transaction'))
    ),
  stopTransaction: (transactionId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV3}/transactions/${transactionId}/stop-transaction-requests`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      delay(5000),
      catchError((error) => errorMessageObservable(error))
    ),
  retryFailedPayment: (transactionId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV4}/transactions/${transactionId}/retry-payment`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      delay(5000),
      catchError((error) => errorMessageObservable(error))
    )
};

export default transactionService;
