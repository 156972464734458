import React, { useCallback, useMemo, useState } from 'react';
import { useLoadingContainerWithErrorPanel } from '../../hooks/useLoadingContainerWithErrorPanel';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import reportService from '../../services/reportService';
import moment from 'moment';

const DEFAULT_DATE_RANGE = {
  dateFrom: moment().subtract(1, 'months').startOf('month'),
  dateTo: moment().subtract(1, 'months').endOf('month'),
};
const TransactionsReport = ({ accountType, tableComponent }) => {
  const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);

  const onDateRangeChange = (dateRange) => setDateRange(dateRange);

  const [transactions, setTransactions] = useState([]);

  const observable = useMemo(() => reportService.getTransactions(dateRange, accountType), [dateRange, accountType]);
  const onSuccessCallback = useCallback((response) => setTransactions(response), []);
  const onErrorCallback = useCallback((error) => setTransactions([]), []);

  const loadingContainerWithErrorPanelState = useLoadingContainerWithErrorPanel(observable, onSuccessCallback, onErrorCallback);

  return (
    <>
      <LoadingContainerWithErrorPanel {...loadingContainerWithErrorPanelState}>
        { tableComponent({ transactions, dateRangeFilter: { dateRange, onDateRangeChange, maxRangeInMonths: 3 }}) }
      </LoadingContainerWithErrorPanel>
    </>
  );
};

export default TransactionsReport;
