import { FC } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import ValueWithUnit from '../../../commons/texts/ValueWithUnit';
import transactionInsightService from '../../../services/transactionInsightService';
import mainTheme from '../../../themes/mainTheme';

import ChargePointInsightBarGraph from './ChargePointInsightBarGraphs';

interface ChargePointTransactionCountProps {
  loadingTransactionsState: any;
  transactions: any;
  lastNumberOfDays: number;
}

const ChargePointTransactionCount: FC<ChargePointTransactionCountProps> = ({ loadingTransactionsState, transactions, lastNumberOfDays }) => {
  return (
    <PaperWithTitle
      title="Transaction Count"
      topRightComponent={
        <ValueWithUnit
          value={transactionInsightService.calculateTotalNumberOfTransactions(transactions).toString()}
          unit="transactions"
          textAlign="right"
        />
      }
    >
      <LoadingContainerWithErrorPanel {...loadingTransactionsState}>
        <ChargePointInsightBarGraph
          dataset={transactionInsightService.buildTransactionCountData(transactions, lastNumberOfDays)}
          color={mainTheme.palette.chart.transactionCount}
        />
      </LoadingContainerWithErrorPanel>
    </PaperWithTitle>
  );
};

export default ChargePointTransactionCount;
