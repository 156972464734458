import React from 'react';

import useNewChargePoint, {NewChargePointFlowState} from './hook/useNewChargePoint';
import NewChargePointForm from './NewChargePointForm/NewChargePointForm';
import {withNewChargePoint} from './hook/withNewChargePoint';
import ReviewChargePointForm from './ReviewChargePointForm/ReviewChargePointForm';
import {Step, StepLabel, Stepper} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    backgroundColor: 'transparent'
  }
}));

const NewChargePointContainer = () => {
  
  const {state} = useNewChargePoint();
  let createComponent;
  const steps = ['Enter Details', 'Review'];
  let activeStep;
  
  const classes = useStyles(mainTheme);
  switch (state.flowState) {

    case NewChargePointFlowState.ENTERING_NEW_CHARGE_POINT_DETAILS:
      activeStep = 0;
      createComponent = <NewChargePointForm />
      break;
    default:
      activeStep = 1;
      createComponent = <ReviewChargePointForm />
  }
  
  return (
    <Box>
        <Stepper activeStep={activeStep} className={classes.stepperContainer}>
          {steps.map((label) => {
            const stepProps = {};
           
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
      </Stepper>
      {createComponent}
    </Box>
  )
};

export default withNewChargePoint(NewChargePointContainer);
