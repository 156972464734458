import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import mainTheme from '../../../themes/mainTheme';
import ChargePointStatusLine from './ChargePointStatusLine';
import useChargePointStatusChart, { ChargePointStatusChartEvent, ChargePointStatusChartFlowState } from './hook/useChargePointStatusChart';
import { withChargePointStatusChart } from './hook/withChargePointStatusChart';

const useStyles = makeStyles((theme) => ({
  container: {},
  divider: {
    marginTop: theme.spacing(1)
  },
  footer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  footerRightArrow: {
    marginLeft: theme.spacing(1.5)
  }
}));

const ChargePointStatusChart = () => {
  const classes = useStyles(mainTheme);

  const { state, addEvent } = useChargePointStatusChart();
  useEffect(() => {
    if (state.flowState === ChargePointStatusChartFlowState.INIT) {
      addEvent({ type: ChargePointStatusChartEvent.PAGE_ENTERED });
    }
  }, [state.flowState, addEvent]);

  return (
    <>
      <PaperWithTitle title="Charger Status" className={clsx({ [classes.container]: !isMobile })}>
        <LoadingContainerWithErrorPanel {...state.loadingState}>
          {state.statusToNumberOfChargePointsList && (
            <ChargePointStatusLine statusToNumberOfChargePointsList={state.statusToNumberOfChargePointsList} />
          )}
        </LoadingContainerWithErrorPanel>
      </PaperWithTitle>
    </>
  );
};

export default withChargePointStatusChart(ChargePointStatusChart);
