import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import {chargePointStatus} from '../../domain/chargePoint';

const useStyles = makeStyles((theme) => ({
  chargePointStatus: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: 500,
  },
  available: {
    backgroundColor: theme.palette.chargePointStatus.available,
  },
  unavailable: {
    backgroundColor: theme.palette.chargePointStatus.unavailable,
  },
  charging: {
    backgroundColor: theme.palette.chargePointStatus.charging,
  },
  decommissioned: {
    backgroundColor: theme.palette.chargePointStatus.decommissioned,
  },
}));

const ChargePointStatus = ({status}) => {
  const classes = useStyles(mainTheme);
  return (
    <Box
      width={150}
      className={clsx(classes.chargePointStatus, {
        [classes.available]: status === chargePointStatus.AVAILABLE,
        [classes.unavailable]: status === chargePointStatus.UNAVAILABLE,
        [classes.charging]: status === chargePointStatus.CHARGING,
        [classes.decommissioned]: status === chargePointStatus.DECOMMISSIONED,
      })}
    >
      {status}
    </Box>
  );
};

ChargePointStatus.defaultProps = {
  status: 'LOADING...',
};

ChargePointStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ChargePointStatus;
