import {Box, CircularProgress, List, ListItem, ListItemText} from '@material-ui/core';
import React from 'react';
import mainTheme from '../../../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as PropTypes from 'prop-types';
import {NewChargePointGroupUserStatus} from '../hook/useChargePointGroupUserList';
import {CheckCircle, Error, FastForward, WatchLater} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import ScrollIntoViewIfNeeded from '../../../../commons/scrolls/ScrollIntoViewIfNeeded';
import AddingChargePointGroupUsersProgressBar from './AddingChargePointGroupUsersProgressBar';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    maxHeight: 124,
    overflow: 'auto',
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.box.border,
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    // remove the border bottom of the last list item
    '& > ul > div:last-child > $listItem': {
      borderBottomWidth: 0,
    },
    borderTopWidth: 0,
  },
  listContainerRoundTopCorners: {
    borderTopWidth: 1,
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  listItem: {
    padding: theme.spacing(0.5),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'dashed',
  },
  secondaryTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 24,
    marginRight: theme.spacing(1),
  },
  info: {
    color: theme.palette.notification.info,
  },
  processingIcon: {
    marginRight: theme.spacing(1.25),
    marginLeft: theme.spacing(0.25),
  },
  statusMessage: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(4),
  },
  statusQueued: {
    color: theme.palette.progress.queued,
  },
  statusAdding: {
    color: theme.palette.progress.processing,
  },
  statusPaused: {
    color: theme.palette.progress.paused,
  },
  statusAdded: {
    color: theme.palette.notification.success,
  },
  statusCancelled: {
    color: theme.palette.progress.cancelled,
  },
  statusWarning: {
    color: theme.palette.notification.warning,
  },
  statusError: {
    color: theme.palette.notification.error,
  },
}));

const AddingChargePointGroupUsersByEmailsBody = ({newChargePointGroupUsers, onClose, addingUsersCompleted}) => {
  const classes = useStyles(mainTheme);

  const statusIcon = (newChargePointGroupUserStatus) => {
    switch (newChargePointGroupUserStatus) {
      case NewChargePointGroupUserStatus.QUEUED:
        return <FastForward className={clsx(classes.icon, classes.statusQueued)} />;
      case NewChargePointGroupUserStatus.ADDING:
        return <CircularProgress className={clsx(classes.processingIcon)} size={20} color="inherit" />;
      case NewChargePointGroupUserStatus.PAUSED:
        return <WatchLater className={clsx(classes.icon, classes.statusPaused)} />;
      case NewChargePointGroupUserStatus.ADDED:
        return <CheckCircle className={clsx(classes.icon, classes.statusAdded)} />;
      case NewChargePointGroupUserStatus.CANCELLED:
        return <Error className={clsx(classes.icon, classes.statusCancelled)} />;
      case NewChargePointGroupUserStatus.WARNING:
        return <Error className={clsx(classes.icon, classes.statusWarning)} />;
      case NewChargePointGroupUserStatus.ERROR:
        return <Error className={clsx(classes.icon, classes.statusError)} />;
      default:
        throw Error(`Unknown status ${newChargePointGroupUserStatus}`);
    }
  };

  const statusMessage = (newChargePointGroupUser) => {
    switch (newChargePointGroupUser.status) {
      case NewChargePointGroupUserStatus.QUEUED:
        return 'Queued';
      case NewChargePointGroupUserStatus.ADDING:
      case NewChargePointGroupUserStatus.ADDED:
        return null;
      case NewChargePointGroupUserStatus.PAUSED:
        return 'Paused';
      case NewChargePointGroupUserStatus.CANCELLED:
        return 'Cancelled';
      case NewChargePointGroupUserStatus.WARNING:
        return `Warning: ${newChargePointGroupUser.message}`;
      case NewChargePointGroupUserStatus.ERROR:
        return `Error: ${newChargePointGroupUser.message}`;
      default:
        throw Error(`Unknown status ${newChargePointGroupUser.status}`);
    }
  };

  const hasMoreThanOneUsers = newChargePointGroupUsers.length > 1;

  const buttonLabel = addingUsersCompleted ? 'Close' : 'Stop Adding Users';

  return (
    <Box>
      {hasMoreThanOneUsers && <AddingChargePointGroupUsersProgressBar newChargePointGroupUsers={newChargePointGroupUsers} />}
      <Box className={clsx(classes.listContainer, {[classes.listContainerRoundTopCorners]: !hasMoreThanOneUsers})}>
        <List>
          {newChargePointGroupUsers.map((newChargePointGroupUser, index) => (
            <ScrollIntoViewIfNeeded
              key={index}
              active={newChargePointGroupUser.status === NewChargePointGroupUserStatus.ADDING}
              options={{
                block: 'end',
                behavior: 'smooth',
                scrollMode: 'if-needed',
              }}
            >
              <ListItem className={classes.listItem} button={false}>
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center">
                      {statusIcon(newChargePointGroupUser.status)}
                      <Typography
                        component="span"
                        className={clsx({
                          [classes.statusQueued]: newChargePointGroupUser.status === NewChargePointGroupUserStatus.QUEUED,
                          [classes.statusPaused]: newChargePointGroupUser.status === NewChargePointGroupUserStatus.PAUSED,
                          [classes.statusWarning]: newChargePointGroupUser.status === NewChargePointGroupUserStatus.WARNING,
                          [classes.statusError]: newChargePointGroupUser.status === NewChargePointGroupUserStatus.ERROR,
                          [classes.statusCancelled]: newChargePointGroupUser.status === NewChargePointGroupUserStatus.CANCELLED,
                        })}
                      >
                        {newChargePointGroupUser.email}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Typography className={classes.secondaryTextContainer} component="span">
                      {statusMessage(newChargePointGroupUser) && (
                        <Typography
                          component="span"
                          className={clsx(classes.statusMessage, {
                            [classes.statusQueued]: newChargePointGroupUser.status === NewChargePointGroupUserStatus.QUEUED,
                            [classes.statusAdding]: newChargePointGroupUser.status === NewChargePointGroupUserStatus.ADDING,
                            [classes.statusPaused]: newChargePointGroupUser.status === NewChargePointGroupUserStatus.PAUSED,
                            [classes.statusAdded]: newChargePointGroupUser.status === NewChargePointGroupUserStatus.ADDED,
                            [classes.statusWarning]: newChargePointGroupUser.status === NewChargePointGroupUserStatus.WARNING,
                            [classes.statusError]: newChargePointGroupUser.status === NewChargePointGroupUserStatus.ERROR,
                            [classes.statusCancelled]: newChargePointGroupUser.status === NewChargePointGroupUserStatus.CANCELLED,
                          })}
                        >
                          {statusMessage(newChargePointGroupUser)}
                        </Typography>
                      )}
                    </Typography>
                  }
                />
              </ListItem>
            </ScrollIntoViewIfNeeded>
          ))}
        </List>
      </Box>
      <Button className={classes.button} type="button" variant="contained" color="primary" fullWidth onClick={onClose}>
        {buttonLabel}
      </Button>
    </Box>
  );
};
AddingChargePointGroupUsersByEmailsBody.propTypes = {
  newChargePointGroupUsers: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  addingUsersCompleted: PropTypes.bool.isRequired,
};

export default AddingChargePointGroupUsersByEmailsBody;
