// The stable createMuiTheme does not support ReactStrictMode yet and it's rolling out the support in the next version
// which is in unstable_createMuiStrictModeTheme.
// Once we upgraded @material-ui/core to the next version we can pull in the stable createMuiTheme.
// I have done regression testing on the theme and found that there is no issues using this "unstable" version
// It helps remove the noisy warnings from React linter and helps us more focused on the real issues.
// https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
import { unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import DMSansBold from '../assets/fonts/DMSans/DMSans-Bold.ttf';
import DMSansBoldItalic from '../assets/fonts/DMSans/DMSans-BoldItalic.ttf';
import DMSansItalic from '../assets/fonts/DMSans/DMSans-Italic.ttf';
import DMSansMedium from '../assets/fonts/DMSans/DMSans-Medium.ttf';
import DMSansMediumItalic from '../assets/fonts/DMSans/DMSans-MediumItalic.ttf';
import DMSansRegular from '../assets/fonts/DMSans/DMSans-Regular.ttf';

//import createMuiTheme, { Theme, ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
/**
 * IMPORTANT: These fonts defined down here must be loaded to material ui via
 * this call down here
 *
 * MuiCssBaseline: {
 '@global': {
 '@font-face': [dmSansRegular, dmSansItalic, dmSansMedium, dmSansMediumItalic, dmSansBold, dmSansBoldItalic],
 },
 },
 *
 * Font weight:
 *  * normal  = 400
 *  * medium  = 500
 *  * bold    = 600
 *
 * Font weight and size has been set properly for material ui typography
 */

// fonts
const dmSansRegular = {
  fontFamily: 'DMSans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${DMSansRegular}) format('truetype')`
};

const dmSansItalic = {
  fontFamily: 'DMSans',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${DMSansItalic}) format('truetype')`
};

const dmSansMedium = {
  fontFamily: 'DMSans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `url(${DMSansMedium}) format('truetype')`
};

const dmSansMediumItalic = {
  fontFamily: 'DMSans',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `url(${DMSansMediumItalic}) format('truetype')`
};

const dmSansBold = {
  fontFamily: 'DMSans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `url(${DMSansBold}) format('truetype')`
};

const dmSansBoldItalic = {
  fontFamily: 'DMSans',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `url(${DMSansBoldItalic}) format('truetype')`
};

// breakpoints
const values = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
};

export const colors = {
  // white
  xxLightWhite: 'rgba(255, 255, 255, 0.1)',
  xLightWhite: 'rgba(255, 255, 255, 0.8)',
  lightWhite: 'rgba(255, 255, 255, 0.9)',
  white: 'rgba(255, 255, 255, 1)',

  // red
  red: 'rgba(244, 67, 54, 1)',

  // pink
  lightPink: 'rgba(224,55,106,0.8)',
  pink: 'rgb(244, 54, 111)',
  darkPink: 'rgb(203,22,75)',

  // orange
  orange: 'rgba(255, 152, 0, 1)',
  darkOrange: 'rgba(255, 112, 0, 1)',

  // green
  green: 'rgba(76, 175, 80, 1)',
  darkGreen: 'rgb(56,147,61)',

  // blue
  xxLightBlue: 'rgb(84,177,248, 0.1)',
  xLightBlue: 'rgb(84,177,248, 0.2)',
  lightBlue: 'rgb(84,177,248)',
  blue: 'rgb(1,153,208)',
  darkBlue: 'rgba(1, 87, 155, 1)',
  xDarkBlue: 'rgba(20, 0, 100, 1)',
  xxDarkBlue: 'rgb(12, 59, 85, 0.8)',
  xxxDarkBlue: 'rgba(5, 30, 52, 1)',
  xxxxDarkBlue: 'rgb(2,18,33)',

  // purple
  lightPurple: 'rgb(129,5,196, 0.1)',
  purple: 'rgb(129,5,196)',
  darkPurple: 'rgba(60, 19, 146, 1)',

  // grey
  xxLightGrey: 'rgba(174, 174, 174, 0.1)',
  xLightGrey: 'rgba(174, 174, 174, 0.5)',
  lightGrey: 'rgba(174, 174, 174, 0.9)',
  grey: 'rgba(124, 124, 124, 0.4)',
  darkGrey: 'rgba(79, 79, 79, 0.7)',
  xDarkGrey: 'rgb(71,71,71)',
  matteGrey: 'rgba(125, 125, 125, 1)',
  solidLightGrey: 'rgb(189,189,189)',
  lightTextGrey: 'rgba(119, 119, 119, 1)',
  dividerGrey: 'rgba(196, 196, 196, 1)',
  borderGrey: 'rgba(196, 196, 196, 1)',
  boxShadowGrey: 'rgba(0, 0, 0, 0.25)',

  // brown
  rootBeer: 'rgb(38, 13, 14)',

  // black
  lightBlack: 'rgba(0, 0, 0, 0.8)',
  black: 'rgba(0, 0, 0, 1)',

  // soft colours
  softGrey: 'rgba(50, 50, 93, 0.1)',
  softBlack: 'rgba(0, 0, 0, 0.2)',
  softWhite: 'rgba(255, 255, 255, 0.5)',

  panelGrey: 'rgba(233, 233, 233, 0.8)'
};

// mainTheme
let mainTheme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true // No more ripple, on the whole application 💣!
    }
  },
  typography: {
    fontFamily: ['DMSans'],
    h1: {
      fontSize: 40,
      fontWeight: 600
    },
    h2: {
      fontSize: 32,
      fontWeight: 600
    },
    h3: {
      fontSize: 24,
      fontWeight: 600
    },
    h4: {
      fontSize: 20,
      fontWeight: 500
    },
    h5: {
      fontSize: 16,
      fontWeight: 500
    },
    h6: {
      fontSize: 12,
      fontWeight: 500
    },
    body1: {
      fontSize: 16,
      fontWeight: 400
    },
    body2: {
      fontSize: 14,
      fontWeight: 400
    }
  },
  overrides: {
    MuiToggleButton: {
      root: {
        //textTransform: "none",
        '&$selected': {
          backgroundColor: colors.darkPurple,
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.purple
          }
        }
      }
    },
    MuiButton: {
      root: {
        fontSize: 16,
        textTransform: 'capitalize'
      },
      contained: {
        // FIXME: Should not use .Mui-XXX as a selector
        '&.Mui-disabled': {
          color: colors.xLightWhite,
          backgroundColor: colors.lightGrey
        },
        borderBottomRightRadius: 13
      },
      outlined: {
        // FIXME: Should not use .Mui-XXX as a selector
        '&.Mui-disabled': {
          color: colors.xLightWhite,
          backgroundColor: colors.lightGrey
        },
        borderBottomRightRadius: 13
      }
    },
    MuiSnackbar: {
      root: {
        left: 16,
        right: 16
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [dmSansRegular, dmSansItalic, dmSansMedium, dmSansMediumItalic, dmSansBold, dmSansBoldItalic]
      }
    },
    MuiToolbar: {
      gutters: {
        '@media (min-width: 600px)': {
          paddingLeft: 16
        }
      },
      regular: {
        '@media (min-width: 600px)': {
          minHeight: 42
        }
      }
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: colors.xxLightGrey
        }
      }
    },
    MuiTableCell: {
      root: {
        color: colors.grey,
        padding: '8px 16px 8px 16px',
        borderBottom: 'none'
      },
      head: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: colors.panelGrey,
        color: colors.xDarkBlue
      },
      body: {
        color: colors.xDarkBlue
      }
    },
    MuiTableSortLabel: {
      root: {
        color: colors.white,
        '&:focus': {
          color: colors.white
        },
        '&:hover': {
          fontWeight: 500,
          color: colors.white,
          '& $icon': {
            fontWeight: 500,
            color: colors.white,
            opacity: 1
          }
        },
        '&$active': {
          color: colors.white,
          fontWeight: 500,
          '&:hover': {
            color: colors.white
          }
        },
        // This is horrible but it's necessary to fix the damn bug in material table sort label
        // It's used to defeat this selector
        // .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon
        '&$active$root$active$root $icon': {
          color: colors.white,
          fontWeight: 500
        }
      }
    },
    MuiTabs: {
      root: {
        borderBottom: `1px solid`,
        borderBottomColor: colors.xLightGrey,
        marginBottom: 16
      }
    },
    MuiTab: {
      root: {
        fontSize: 16,
        textTransform: 'none'
      },
      textColorPrimary: {
        color: colors.darkPurple,
        // FIXME: Should not use .Mui-XXX as a selector
        '&.Mui-selected': {
          color: colors.xDarkGrey
        }
      }
    },
    PrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: colors.blue
      }
    },
    MuiTabPanel: {
      root: {
        padding: 0
      }
    },
    MuiLink: {
      root: {
        textDecoration: 'underline',
        cursor: 'pointer'
      },
      underlineHover: {
        fontWeight: 500,
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontWeight: 500
      }
    },
    MuiInput: {
      root: {
        color: colors.xDarkBlue
      }
    },
    MuiInputAdornment: {
      root: {
        color: colors.xDarkGrey
      }
    },
    MuiTablePagination: {
      root: {
        color: colors.xDarkBlue,
        display: 'flex',
        justifyContent: 'flex-end'
      },
      caption: {
        fontSize: '0.75rem'
      }
    },
    MuiSelect: {
      root: {
        color: colors.xDarkGrey
      },
      icon: {
        color: colors.xDarkBlue
      },
      select: {
        '&$disabled': {
          color: colors.darkGrey
        }
      }
    },
    MuiContainer: {
      root: {
        '@media (min-width: 600px)': {
          paddingLeft: 16,
          paddingRight: 16
        },
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiDialogTitle: {
      root: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
        paddingBottom: 8
      }
    },
    MuiDialogContent: {
      root: {
        paddingLeft: 16,
        paddingRight: 16
      }
    },
    MuiInputBase: {
      input: {
        color: colors.xDarkGrey,
        '&$disabled': {
          color: colors.darkGrey
        }
      }
    },
    Mui: {
      '&$disabled': {
        color: colors.darkGrey
      }
    },
    MuiAlert: {
      filledWarning: {
        backgroundColor: colors.darkOrange
      }
    },
    MuiChip: {
      root: {
        fontSize: '1rem'
      }
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        fontSize: 48
      }
    },
    MuiOutlinedInput: {
      root: {
        borderBottomRightRadius: 13
      }
    },
    MuiFormControl: {
      root: {
        marginTop: 4,
        marginBottom: 4
      }
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16
        }
      }
    }
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    up: (key) => `@media (min-width:${values[key]}px)`
  },
  spacing: 8,
  palette: {
    primary: {
      light: colors.white,
      main: colors.darkPurple,
      dark: colors.purple,
      contrastText: colors.white
    },
    secondary: {
      light: colors.lightPink,
      main: colors.darkPink,
      dark: colors.pink,
      contrastText: colors.white
    },
    smartPasswordHint: {
      satisfied: colors.green,
      notSatisfied: colors.darkGrey
    },
    backdrop: colors.xxDarkBlue,
    drawer: colors.xxxDarkBlue,
    drawerItem: {
      selectedBackgroundColor: colors.xxxDarkBlue,
      divider: colors.xxLightWhite,
      menuHeading: colors.solidLightGrey
    },
    chargePointStatus: {
      available: colors.blue,
      unavailable: colors.darkGrey,
      charging: colors.purple,
      decommissioned: colors.rootBeer
    },
    accountType: {
      payg: colors.lightBlue,
      postpay: colors.lightPink
    },
    connectorStatus: {
      available: colors.blue,
      unavailable: colors.darkGrey,
      charging: colors.purple
    },
    sessionStatus: {
      active: colors.green,
      completed: colors.darkGrey,
      other: colors.red
    },
    chargeTagStatus: {
      active: colors.green,
      deactivated: colors.red
    },
    notification: {
      info: colors.blue,
      success: colors.green,
      warning: colors.darkOrange,
      error: colors.red
    },
    progress: {
      queued: colors.darkGrey,
      processing: colors.purple,
      paused: colors.darkGrey,
      cancelled: colors.xDarkGrey
    },
    processingBar: {
      backgroundColor: colors.purple
    },
    tab: {
      divider: colors.xLightGrey
    },
    divider: colors.xLightGrey,
    chart: {
      default: colors.green,
      transactionCount: colors.darkBlue,
      electricityConsumption: colors.orange,
      electricityCost: colors.blue,
      carbonOffset: colors.green
    },
    box: {
      border: colors.xLightGrey
    },
    table: {
      header: colors.blue
    },
    button: {
      delete: colors.red
    },
    tooltip: {
      divider: colors.dividerGrey,
      secondary: colors.lightTextGrey,
      border: colors.borderGrey,
      boxShadow: colors.boxShadowGrey,
      background: colors.white
    },
    logText: {
      primary: colors.xDarkGrey,
      secondary: colors.darkGrey
    },
    remoteStartButton: {
      active: colors.darkBlue,
      hover: colors.blue
    }
  },
  leftDrawer: {
    width: 280
  }
});
mainTheme = responsiveFontSizes(mainTheme);

export default mainTheme;
