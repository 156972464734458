import _ from 'lodash';
import { createContext, FC, useContext, useReducer } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingSuccessState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import accountService from '../../../../services/accountService';
import { AccountTransactionEvent, AccountTransactionEventTypes } from '../../../../types/account/AccountTransactionEvent';
import { AddEventType, InitialStateType, ReducerContext } from '../../../../types/SharedStates';
import { TransactionSummary } from '../../../../types/transaction/Transaction';

export enum AccountTransactionsFlowState {
  INIT = 'INIT',
  LOADING_ACCOUNT_TRANSACTIONS = 'LOADING_ACCOUNT_TRANSACTIONS',
  ACCOUNT_TRANSACTIONS_LOADED = 'ACCOUNT_TRANSACTIONS_LOADED',
  FAILED_TO_LOAD_ACCOUNT_TRANSACTIONS = 'FAILED_TO_LOAD_ACCOUNT_TRANSACTIONS'
}

export interface AccountTransactionsState {
  flowState: AccountTransactionsFlowState;
  loadingState: InitialStateType;
  transactions: TransactionSummary[];
}

const initialState: AccountTransactionsState = {
  flowState: AccountTransactionsFlowState.INIT,
  loadingState: loadingContainerInitialState,
  transactions: []
};

const AccountTransactionsContext = createContext<ReducerContext<AccountTransactionsState>>({
  state: initialState,
  dispatch: () => initialState
});

const reducer = (state: AccountTransactionsState, newState: Partial<AccountTransactionsState>) => ({ ...state, ...newState });

export const AccountTransactionsProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));

  return <AccountTransactionsContext.Provider value={{ state, dispatch }}>{children}</AccountTransactionsContext.Provider>;
};

interface UseAccountTransactionsType {
  state: AccountTransactionsState;
  addEvent: AddEventType<AccountTransactionEventTypes>;
}

const useAccountTransactions = (): UseAccountTransactionsType => {
  const { state, dispatch } = useContext(AccountTransactionsContext);
  const addEvent = (event: AccountTransactionEventTypes) => {
    switch (event.type) {
      case AccountTransactionEvent.LOAD_ACCOUNT_TRANSACTIONS:
        dispatch({ flowState: AccountTransactionsFlowState.LOADING_ACCOUNT_TRANSACTIONS });
        accountService.getAccountTransactions(event.payload.accountId).subscribe(
          (transactions) =>
            dispatch({
              flowState: AccountTransactionsFlowState.ACCOUNT_TRANSACTIONS_LOADED,
              transactions: transactions,
              loadingState: loadingSuccessState
            }),
          (error) =>
            dispatch({
              flowState: AccountTransactionsFlowState.FAILED_TO_LOAD_ACCOUNT_TRANSACTIONS,
              loadingState: loadingContainerFailedState(error.message)
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };
  return { state, addEvent };
};

export default useAccountTransactions;
