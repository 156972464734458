import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { errorMessageObservable } from '../utils/error/errorUtils';

export const featureFlagService = {
    getFeatureFlag: (featureFlagName) =>
        withAccessToken((accessToken) =>
            ajax({
                url: `${appConfig.emspApiDomainV3}/feature-flags/${featureFlagName}`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${accessToken}`,
                },
            }).pipe(
                map((response) => response.response),
                catchError((error) => errorMessageObservable(error))
            )
        ),
};
