import React from 'react';
import {Dialog, DialogContent, DialogTitle, IconButton, Typography} from '@material-ui/core';
import clsx from 'clsx';
import mainTheme from '../../themes/mainTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {CloseOutlined} from '@material-ui/icons';
import * as PropTypes from 'prop-types';
import {isMobile} from 'react-device-detect';



const FormDialog = ({open, onClose, title, fullPage, children, maxWidth=600}) => {
  
  const useStyles = makeStyles((theme) => ({
    desktopWithDrawer: {
      margin: 0,
      marginLeft: theme.leftDrawer.width,
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paperWidthSm: {
      maxWidth: maxWidth,
    },
  }));
  
  const classes = useStyles(mainTheme);
  return (
    <Dialog open={open} onClose={onClose} classes={{paper: clsx({[classes.desktopWithDrawer]: !isMobile && !fullPage}), paperWidthSm: classes.paperWidthSm}} fullWidth>
      <DialogTitle disableTypography classes={{root: classes.dialogTitle}}>
        <Typography variant="h2">{title}</Typography>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default FormDialog;
