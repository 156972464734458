import React from 'react';
import BoxWithLabel from './BoxWithLabel';
import {Typography} from '@material-ui/core';

const TextWithLabel = ({label, text, minWidth = 180}) => (
  <BoxWithLabel label={label} minWidth={minWidth}>
    <Typography>{text}</Typography>
  </BoxWithLabel>
);

export default TextWithLabel;
