import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router';

/**
 * A Hook that takes in a onRouteChange callback function, and execute the onRouteChange when the route of the component changes.
 * The hook unsubscribes from the listener on unmount.
 *
 * @param onRouteChange - Callback func that will get executed when the route of the component changes
 */

const useRouteListener = (onRouteChange) => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      onRouteChange();
    });
    return () => {
      unlisten();
    };
  }, [history, onRouteChange]);
};

useRouteListener.propTypes = {
  callback: PropTypes.func.isRequired,
  deps: PropTypes.array,
};

export default useRouteListener;
