import _ from 'lodash';
import { createContext, FC, PropsWithChildren, useReducer } from 'react';
import { initialState as loadingContainerInitialState } from '../../../hooks/useLoadingContainerWithErrorPanel';
import { LocationDetailsFlowState } from '../../../types/LocationDetails';
import { LocationState } from '../../../types/location/LocationState';

const reducer = (state: any, newState: any) => ({ ...state, ...newState });

export const initialState: Partial<LocationState> = {
  loadingState: loadingContainerInitialState,
  locations: [],
  location: null,
  flowState: LocationDetailsFlowState.INIT,
  updateLocation: null,
  updateLocationErrorMessage: null,
  newLocationErrorMessage: null,
  updatedLocationId: null,
  osps: [],
  organisations: [],
  chargers: []
};
export const LocationContext = createContext<LocationState | any>(initialState);

const LocationContextProvider: FC = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));

  return <LocationContext.Provider value={{ state, dispatch }}>{children}</LocationContext.Provider>;
};
export default LocationContextProvider;
