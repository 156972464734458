import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {Typography} from '@material-ui/core';
import React from 'react';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import {connectorPowerTypeMap, connectorTypeMap} from '../../../domain/connector';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  connectorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  connectorFieldsContainer: {
    border: 'dashed lightgray',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    minWidth: '200px',
    justifyContent: 'flex-start',
  },
  connectorTitleHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }
}));

const ReviewConnectorFormDetails = ({connector, index}) => {
  const classes = useStyles();
  
  return (
    <Box key={index} className={classes.connectorFieldsContainer}>
      <Typography className={classes.connectorTitleHeading} variant="h4">Connector</Typography>
      <TextWithLabel label="Id" text={connector.id}/>
      <TextWithLabel label="Format" text={connector.format}/>
      <TextWithLabel label="Power Type" text={connectorPowerTypeMap.get(connector.powerType)}/>
      <TextWithLabel label="Standard" text={connectorTypeMap.get(connector.standard)}/>
      <TextWithLabel label="Max Amperage (A)" text={connector.maxAmperage}/>
      <TextWithLabel label="Max Voltage (V)" text={connector.maxVoltage}/>
      <TextWithLabel label="Max Power (Watts)" text={connector.maxPower}/>
    </Box>
  )
}
ReviewConnectorFormDetails.propTypes = {
  connector: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}
export default ReviewConnectorFormDetails;

