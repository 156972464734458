import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import {Tooltip} from '@material-ui/core';
import _ from 'lodash';

export const chargeTagStatus = {
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  UNASSIGNED: 'Unassigned',
};

const useStyles = makeStyles((theme) => ({
  chargeTagStatus: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: 500,
  },
  active: {
    backgroundColor: theme.palette.chargeTagStatus.active,
  },
  deactivated: {
    backgroundColor: theme.palette.chargeTagStatus.deactivated,
  },
}));

export const mapActiveFlagToChargeTagStatus = (active) => (active ? chargeTagStatus.ACTIVE : chargeTagStatus.DEACTIVATED);

const ChargeTagStatus = ({active, chargeTagId}) => {
  const classes = useStyles(mainTheme);
  const status = mapActiveFlagToChargeTagStatus(active);
  const displayText = chargeTagId ?? _.upperCase(status);

  return (
    <>
      <Tooltip title={`Charge tag is ${status}`}>
        <Box
          width={150}
          className={clsx(classes.chargeTagStatus, {
            [classes.active]: active,
            [classes.deactivated]: !active,
          })}
        >
          {displayText}
        </Box>
      </Tooltip>
    </>
  );
};

ChargeTagStatus.defaultProps = {
  active: false,
};

ChargeTagStatus.propTypes = {
  active: PropTypes.bool.isRequired,
  chargeTagId: PropTypes.string,
};

export default ChargeTagStatus;
