import * as React from 'react';
import { ChargerTariffsProvider } from './ChargerTariffsProvider';

interface WithChargerTariffsProps {
  (Component: React.FC<any>): (props: any) => JSX.Element;
}

export const withChargerTariffs: WithChargerTariffsProps = (Component) => (props) =>
  (
    <ChargerTariffsProvider>
      <Component {...props} />
    </ChargerTariffsProvider>
  );
