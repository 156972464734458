import React, { createContext, PropsWithChildren, useReducer } from 'react';
import _ from 'lodash';
import { ChargerDetailsFlowState, ChargerDetailsState } from '../../../../types/charger/ChargeDetailsState';
import { initialState as loadingContainerInitialState } from '../../../../hooks/useLoadingContainerWithErrorPanel';

// initial state
const initialState: ChargerDetailsState = {
  flowState: ChargerDetailsFlowState.INIT,
  loadingState: loadingContainerInitialState,
  emspCharger: undefined,
  cpoChargePoint: undefined,
  errorMessage: '',
  successMessage: ''
};

const reducer = (state: any, newState: any) => ({ ...state, ...newState });

// context
export const ChargerDetailsContext = createContext<ChargerDetailsState | any>(initialState);

// provider
export const ChargerDetailsProvider: ({ children }: React.PropsWithChildren<any>) => React.JSX.Element = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <ChargerDetailsContext.Provider value={{ state, dispatch }}>{children}</ChargerDetailsContext.Provider>
  );
};
