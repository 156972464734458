import { Box, Divider } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { createRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import { getModifiedValues, handleCoordinates } from '../../../commons/form/FormikFunctions';
import CancelButton from '../../../commons/form/button/CancelButton';
import CreateButton from '../../../commons/form/button/CreateButton';
import { LocationDetailsFlowState } from '../../../types/LocationDetails';
import { Osp } from '../../../types/Osp';
import { LocationsFlowState } from '../../../types/location/LocationState';
import { EmspOrganisation } from '../../../types/organisation/Organisation';
import LocationAddressForm from '../Details/EditLocationAddress';
import LocationDetailsForm from '../Details/EditLocationDetails';
import { LocationEvent } from '../hook/useLocations';

type NewLocationFormProps = {
  organisations: EmspOrganisation[];
  osps: Osp[];
  newLocationId: string;
  flowState: LocationDetailsFlowState | LocationsFlowState | null;
  addEvent: any;
};
const NewLocationForm: React.FC<NewLocationFormProps> = ({ organisations, osps, newLocationId, flowState, addEvent }) => {
  const history = useHistory();
  const formikRef = createRef<any>();

  const handleCancel = () => {
    addEvent({
      type: LocationEvent.CANCEL_CREATE_LOCATION_FORM
    });
  };

  useEffect(() => {
    if (flowState === LocationDetailsFlowState.FAILED_TO_CREATE_LOCATION) {
      addEvent({ type: LocationEvent.SHOW_CREATE_LOCATION_FORM, state: { newLocationErrorMessage: null } });
      formikRef.current.setSubmitting(false);
    }
  }, [flowState, formikRef, history, newLocationId, addEvent]);

  const initialValues = {
    name: '',
    public: false,
    visibleToOsps: [],
    ownerName: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    latitude: '',
    longitude: '',
    id: null
  };

  const validationSchema = Yup.object({
    name: Yup.string().max(50).required('Required'),
    latitude: Yup.string()
      .required('Required')
      .matches(/^-?([0-9]{1,2})\.([0-9]{5,7})$/, 'Should be in valid latitude format e.g. -36.848461'),
    longitude: Yup.string()
      .required('Required')
      .matches(/^-?([0-9]{1,3})\.([0-9]{5,7})$/, 'Should be in valid longitude format e.g 174.763336'),
    address: Yup.string().required('Required').max(45),
    city: Yup.string().required('Required').max(45),
    postCode: Yup.string().max(10),
    state: Yup.string().max(20)
  });

  const setAddressFields = (address: any) => {
    if (address) {
      formikRef.current.setFieldValue('address', address.address1 + ' ' + address.address2);
      formikRef.current.setFieldValue('city', address.locality);
      formikRef.current.setFieldValue('state', address.state);
      formikRef.current.setFieldValue('postalCode', address.postcode);
      formikRef.current.setFieldValue('latitude', address.latitude);
      formikRef.current.setFieldValue('longitude', address.longitude);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      onSubmit={(values) => {
        const modifiedValues = getModifiedValues(values, initialValues);
        const coordinateParameters = handleCoordinates(modifiedValues, values);

        addEvent({
          type: LocationEvent.SUBMIT_CREATE_LOCATION_FORM,
          payload: {
            id: values.id,
            values: { ...modifiedValues, ...coordinateParameters }
          }
        });
      }}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        if (formikProps.values.public === false) {
          formikProps.values.visibleToOsps = [];
        }

        return (
          <Form>
            <PaperWithTitle
              title="General"
              children={
                <Box>
                  <LocationDetailsForm formikProps={formikProps} organisations={organisations} osps={osps} />
                  <Divider variant="fullWidth" style={{ marginTop: '20px', marginBottom: '5px' }} />
                  <LocationAddressForm setAddressFields={setAddressFields} />
                </Box>
              }
              bottomRightComponent={
                <>
                  <CancelButton handleCancel={handleCancel} />
                  <CreateButton formikProps={formikProps} />
                </>
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default NewLocationForm;
