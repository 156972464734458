import { FC } from 'react';
import NavigationLink from '../../commons/link/NavigationLink';
import MaterialTableWrapper from '../../commons/table/MaterialTableWrapper';
import { usePermissions } from '../../hooks/usePermissions';
import paths from '../../routes/paths';
import { EmspAccount } from '../../types/account/Account';
import { Permission } from '../../types/user/UserPermission';

interface AccountsTableProps {
  handleAddAccount: () => void;
  accounts: EmspAccount[];
  hideOrganisationColumn?: boolean;
}

const AccountsTable: FC<AccountsTableProps> = ({ accounts, handleAddAccount, hideOrganisationColumn }) => {
  const { hasPermission } = usePermissions();

  return (
    <MaterialTableWrapper
      columns={[
        {
          title: 'Name',
          field: 'name',
          width: 'auto',
          render: (rowData: EmspAccount) => <NavigationLink path={`${paths.ACCOUNTS}/${rowData.id}/details`} text={rowData.name} />
        },
        {
          title: 'Organisation',
          field: 'organisationName',
          width: 'auto',
          hidden: !!hideOrganisationColumn,
          hiddenByColumnsButton: !!hideOrganisationColumn
        },
        {
          title: 'Account Type',
          field: 'accountType',
          width: 'auto'
        },
        {
          title: 'Users',
          field: 'numberOfUsers',
          width: 'auto',
          render: (rowData: EmspAccount) => <NavigationLink path={`${paths.ACCOUNTS}/${rowData.id}/users`} text={rowData.numberOfUsers.toString()} />
        },
        {
          title: 'Admins',
          field: 'admins',
          width: 'auto',
          render: (rowData: EmspAccount) => (
            <NavigationLink path={`${paths.ACCOUNTS}/${rowData.id}/admins`} text={rowData.numberOfAdmins.toString()} />
          )
        }
      ]}
      data={accounts}
      globalActionButton={
        hasPermission(Permission.MANAGE_ACCOUNT)
          ? {
              name: 'New',
              tooltip: 'Create a new account',
              onClick: () => handleAddAccount()
            }
          : {}
      }
      exportFileName="Accounts"
    />
  );
};

export default AccountsTable;
