import { Box, Button, makeStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import { FC, createRef, useEffect } from 'react';
import * as Yup from 'yup';

import PaperWithTitle from '../../../../commons/containers/PaperWithTile/PaperWithTitle';
import SmartTextField from '../../../../commons/form/SmartTextField';
import { ErrorBar, ProcessingBar } from '../../../../commons/snackbars/snackbars';
import { ChargerRemoteStartFlowState } from '../../../../types/charger/ChargerRemoteStartState';
import useRemoteStartTransactions, { RemoteStartEvent } from '../hook/useRemoteStartTransactions';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

interface RemoteStartPreviewFormProps {
  locationId: string;
  displayName?: string;
}
const RemoteStartPreviewForm: FC<RemoteStartPreviewFormProps> = ({ locationId, displayName }) => {
  const classes = useStyles();
  const { state, addEvent } = useRemoteStartTransactions();
  const formikRef = createRef<any>();

  useEffect(() => {
    if (state.flowState === ChargerRemoteStartFlowState.NOT_ELIGIBLE) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.flowState, formikRef]);

  const initialValues = {
    email: ''
  };

  const validationSchema = Yup.object({
    email: Yup.string().required('Required').email('Please enter a valid email')
  });

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={(values) => {
          addEvent({
            type: RemoteStartEvent.PREVIEW_SUBMITTED,
            payload: {
              displayName: displayName,
              locationId: locationId,
              email: values.email
            }
          });
        }}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <PaperWithTitle title="User">
              <Field
                label="Email"
                name="email"
                autoFocus
                component={SmartTextField}
                helperText="Email of user to start transaction for e.g. johndoe@countiesenergy.co.nz"
                fullWidth
              />
            </PaperWithTitle>
            <Box className={classes.submitButtonContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
              >
                Next
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <ErrorBar open={!_.isNull(state.errorMessage)} fullPage={undefined} onClose={undefined}>
        {state.errorMessage}
      </ErrorBar>
      <ProcessingBar
        open={state.flowState === ChargerRemoteStartFlowState.CHECKING_ELIGIBILITY}
        text={'Checking user eligibility..'}
        fullPage={undefined}
      />
    </>
  );
};

export default RemoteStartPreviewForm;
