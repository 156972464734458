import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { FC, createRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import PaperWithTitle from '../../commons/containers/PaperWithTile/PaperWithTitle';
import SmartTextField from '../../commons/form/SmartTextField';
import PageWithColumns from '../../commons/layout/PageWithColumns';
import { ErrorBar } from '../../commons/snackbars/snackbars';
import paths from '../../routes/paths';
import mainTheme from '../../themes/mainTheme';
import { AddEventType } from '../../types/SharedStates';
import { NewOrganisationEvent, NewOrganisationFlowState, OrganisationState } from '../../types/organisation/OrganisationState';

const useStyles = makeStyles(() => ({
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

interface NewOrganisationFormProps {
  state: OrganisationState;
  addEvent: AddEventType;
}

const NewOrganisationForm: FC<NewOrganisationFormProps> = ({ state, addEvent }) => {
  const classes = useStyles(mainTheme);
  const formikRef = createRef<any>();
  const history = useHistory();

  useEffect(() => {
    if (state.flowState === NewOrganisationFlowState.NEW_ORGANISATION_CREATED) {
      const pathname = paths.ORGANISATIONS + `/${state.newOrganisation.id}/details`;
      history.push({ pathname: pathname }, { showOrganisationCreatedSuccessBar: true });
    }
    if (state.flowState !== NewOrganisationFlowState.SUBMITTING_ORGANISATION_FORM && state.flowState !== NewOrganisationFlowState.NEW_ORGANISATION_CREATED) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.flowState, formikRef, history, state.newOrganisation?.id, addEvent]);

  const initialValues = state.newOrganisation
    ? state.newOrganisation
    : {
        name: '',
        website: ''
      };

  const validationSchema = Yup.object({
    name: Yup.string().max(50).required('Required'),
    website: Yup.string().url('Invalid url. Must contain http:// or https://')
  });

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        enableReinitialize
        validateOnMount
        onSubmit={(values) => {
          addEvent({
            type: NewOrganisationEvent.SUBMIT_NEW_ORGANISATION_FORM_REQUESTED,
            payload: values
          });
        }}
        validationSchema={validationSchema}
      >
        {({ isValid }) => (
          <Form>
            <PaperWithTitle title="General" bottomRightComponent={null} className={undefined} maxWidth={undefined} backgroundColor={undefined}>
              <PageWithColumns
                columnOne={
                  <>
                    <Field label="Name" name="name" component={SmartTextField} helperText="Name of Organisation" />
                    <Field label="Website" name="website" component={SmartTextField} helperText="Website URL" />
                  </>
                }
                columnTwo={<></>}
              />
            </PaperWithTitle>
            <Box className={classes.submitButtonContainer}>
              <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <ErrorBar fullPage={false} open={state.newOrganisationErrorMessage} onClose={undefined}>
        {state.newOrganisationErrorMessage}
      </ErrorBar>
    </>
  );
};

export default NewOrganisationForm;
