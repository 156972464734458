import { InitialStateType } from '../SharedStates';
import { EmspAccount } from '../account/Account';

export enum OrganisationAccountsFlowState {
  INIT = 'INIT',
  ACCOUNTS_LOADED = 'ACCOUNTS_LOADED',
  ERROR_LOADING_ACCOUNTS = 'ERROR_LOADING_ACCOUNTS'
}

export enum OrganisationAccountsEvent {
  LOAD_ACCOUNTS = 'LOAD_ACCOUNTS'
}

export type OrganisationAccountsState = {
  loadingState: InitialStateType;
  accounts: EmspAccount[];
  flowState: OrganisationAccountsFlowState;
};
