import { ChargerInfoFlowState, ChargerInfoState } from '../../../../../types/charger/ChargerInfoState';
import React, { createContext, PropsWithChildren, useReducer } from 'react';
import _ from 'lodash';
import { initialState as loadingContainerInitialState } from '../../../../../hooks/useLoadingContainerWithErrorPanel';

// initial state
const initialState: ChargerInfoState = {
  flowState: ChargerInfoFlowState.INIT,
  loadingState: loadingContainerInitialState,
  emspCharger: undefined,
  cpoChargePoint: undefined,
  errorMessage: '',
  successMessage: ''
};

const reducer = (state: any, newState: any) => ({ ...state, ...newState });

// context
export const ChargerInfoContext = createContext<ChargerInfoState | any>(initialState);

// provider
export const ChargerInfoProvider: ({ children }: React.PropsWithChildren<any>) => React.JSX.Element = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <ChargerInfoContext.Provider value={{ state, dispatch }}>{children}</ChargerInfoContext.Provider>
  );
};
