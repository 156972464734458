import {ajax} from 'rxjs/ajax';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import appConfig from '../utils/config/appConfig';
import TotpEntryCreationException from '../utils/auth/exceptions/TotpEntryCreationException';
import UnauthorisedException from '../utils/auth/exceptions/UnauthorisedException';
import UserNotFoundException from '../utils/auth/exceptions/UserNotFoundException';
import MfaAlreadyEnabledException from '../utils/auth/exceptions/MfaAlreadyEnabledException';

const authenticatorService = {
  generateAuthenticatorQrCode: (idToken) => {
    return ajax({
      url: `${appConfig.emspAdminApiDomain}/auth/mfa`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: {
        idToken: idToken,
      },
    }).pipe(
      map((response) => response.response),
      catchError((error) => {
        if (error.status === 400) {
          return throwError(new TotpEntryCreationException('Invalid TOTP url'));
        }
        if (error.status === 401) {
          return throwError(new UnauthorisedException('You are not authorized to activate MFA. Please log in again'));
        }
        if (error.status === 404) {
          return throwError(new UserNotFoundException('User not found. Please contact your administrator'));
        }

        if (error.status === 409) {
          return throwError(new MfaAlreadyEnabledException('MFA is already enabled for this user'));
        }
        return throwError(new Error(error.message));
      })
    );
  },
  verifyAuthenticatorOneTimePassword: (idToken, verificationCode) => {
    return ajax({
      url: `${appConfig.emspAdminApiDomain}/auth/mfa/verify`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: {
        idToken: idToken,
        verificationCode: verificationCode,
      },
    }).pipe(
      map((response) => response.response),
      catchError((error) => {
        //    Possible response codes
        //    404 ResourceNotFoundException
        //    401 AuthUnauthorizedException
        //    410 AdminTokenHolderNotFoundException
        //    410 MfaSecretNotFoundException
        //    401 AuthException
        //    410 ExpiredAdminTokenHolderException
        if (error.status === 401) {
          return throwError(new UnauthorisedException('Invalid verification code'));
        }
        if (error.status === 404) {
          return throwError(new UserNotFoundException('User not found. Please contact your administrator'));
        }
        return throwError(new Error(error.message));
      })
    );
  },
};

export default authenticatorService;
