import * as React from 'react';
import { AccountProvider } from './useAccounts';

interface WithAccountsProps {
  (Component: React.FC): (props: Record<string, unknown>) => JSX.Element;
}

export const withAccounts: WithAccountsProps = (Component) => (props) =>
  (
    <AccountProvider>
      <Component {...props} />
    </AccountProvider>
  );
