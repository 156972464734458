import { LocationType } from '../location/Location';

export type EmspCharger = {
  displayName: string;
  locationId: string;
  locationName: string;
  orgId: string;
  orgName: string;
  operator: string;
  countryCode: string;
  partyId: string;
  status: ChargerStatus;
  cpoLocationId: string;
  evses?: EmspEvse[];
  locationType: LocationType;
};

export type EmspEvse = {
  id: string;
  status: string;
  cpoEvseId: string;
  cpoEvseUid: string;
  locationId: string;
  connectors: EmspConnector[];
  displayName: string;
  evseReference: string;
};

export type EmspConnector = {
  id: string;
  cpoConnectorId: string;
  standard: ConnectorStandard;
  format: ConnectorFormat;
  powerType: ConnectorPowerType;
  maxVoltage: number;
  maxAmperage: number;
  maxElectricPower: number;
  status?: string;
  emspTariffOverrideId?: string;
  cpoTariffId?: string;
  evseReference?: string;
  connectorReference?: string;
};

export type CpoConnector = {
  id: string;
  ocppConnectorId: number;
  standard: ConnectorStandard;
  format: ConnectorFormat;
  powerType: ConnectorPowerType;
  maxVoltage: number;
  maxAmperage: number;
  maxElectricPower: number;
  status?: string;
  tariffId: string | null;
  evseReference?: string;
  connectorReference?: string;
};

export type CpoEvse = {
  id: string;
  status: CpoEvseStatus;
  evseId: string;
  connectors: CpoConnector[];
  evseReference: string;
};

export type Chargers = {
  id: string;
  name: string;
  status: string;
};

export type CpoChargePoint = {
  id: string;
  created: string;
  lastUpdated: string;
  chargeBoxSerialNumber: string;
  chargePointModel: string;
  chargePointSerialNumber: string;
  chargePointVendor: string;
  firmwareVersion: string;
  status: string;
  evses: CpoEvse[];
  anonymousChargingAllowed: boolean;
  locationId: string;
  ocppChargePointIdentifier: string;
  physicalReference: string;
};

export type NewCpoChargePoint = {
  anonymousChargingAllowed: boolean | string;
  locationId: string;
  ocppChargePointIdentifier: string;
  physicalReference: string;
  evses: CpoEvse[];
  locationName?: string;
};

export enum ChargerStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  CHARGING = 'CHARGING',
  DECOMMISSIONED = 'DECOMMISSIONED'
}

export enum CpoEvseStatus {
  AVAILABLE = 'AVAILABLE',
  INOPERATIVE = 'INOPERATIVE',
  OUTOFORDER = 'OUTOFORDER',
  REMOVED = 'REMOVED'
}

export enum ConnectorStandard {
  CHADEMO = 'CHADEMO',
  IEC_62196_T1 = 'IEC_62196_T1',
  IEC_62196_T2 = 'IEC_62196_T2',
  IEC_62196_T2_COMBO = 'IEC_62196_T2_COMBO'
}

export const ConnectorStandardDisplay = new Map<string, string>([
  [ConnectorStandard.CHADEMO, 'CHAdeMO'],
  [ConnectorStandard.IEC_62196_T1, 'Type 1'],
  [ConnectorStandard.IEC_62196_T2, 'Type 2'],
  [ConnectorStandard.IEC_62196_T2_COMBO, 'CCS']
]);

export enum ConnectorFormat {
  CABLE = 'CABLE',
  SOCKET = 'SOCKET'
}

export enum ConnectorPowerType {
  AC_1_PHASE = 'AC_1_PHASE',
  AC_3_PHASE = 'AC_3_PHASE',
  DC = 'DC'
}

export const ConnectorPowerTypeDisplay = new Map<string, string>([
  [ConnectorPowerType.AC_1_PHASE, 'AC, single phase'],
  [ConnectorPowerType.AC_3_PHASE, 'AC, 3-phase'],
  [ConnectorPowerType.DC, 'DC']
]);

export enum ChargerMode {
  FREE = 'FREE',
  AUTH = 'AUTH'
}

export function getChargerModeFromAnonymousChargingAllowed(anonymousChargingAllowed: boolean | string): ChargerMode {
  if (typeof anonymousChargingAllowed === 'boolean') {
    return anonymousChargingAllowed ? ChargerMode.FREE : ChargerMode.AUTH;
  } else {
    return anonymousChargingAllowed.toLowerCase() === 'true' ? ChargerMode.FREE : ChargerMode.AUTH;
  }
}

export interface CSChargerReport {
  id: string;
  address: string;
  location_name: string;
  owner: string;
  commissioned: string;
  decommissioned: string | null;
  power_type: string;
}

export interface ChargerReport {
  id: string;
  address: string;
  locationName: string;
  owner: string;
  commissioned: string;
  decommissioned: string | null;
  powerType: string;
}
