import { FC } from 'react';
import { useRouteMatch } from 'react-router';
import paths from '../../../routes/paths';
import Dashboard from '../../Dashboard/Dashboard';
import { ChargerDetailsMatchParams } from '../ChargerDetails/ChargerDetails';
import ChargerRemoteStartContainer from './ChargerRemoteStartContainer';

const ChargerRemoteStart: FC = () => {
  const match = useRouteMatch<ChargerDetailsMatchParams>(paths.REMOTE_START_CHARGER);

  const chargerDisplayName = match?.params.chargerDisplayName || '';
  const locationId = match?.params.locationId || '';

  return (
    <Dashboard pageHeading={`Start Remote Transaction for ${chargerDisplayName}`}>
      <ChargerRemoteStartContainer
        locationId={locationId}
        countryCode={match?.params.cpoCountryCode}
        partyId={match?.params.cpoPartyId}
        displayName={chargerDisplayName}
      />
    </Dashboard>
  );
};

export default ChargerRemoteStart;
