import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';

import _ from 'lodash';

import PropTypes from 'prop-types';
import { createRef, useEffect } from 'react';
import * as Yup from 'yup';
import BoxWithLabel from '../../commons/containers/ComponentWithLabel/BoxWithLabel';
import TextWithLabel from '../../commons/containers/ComponentWithLabel/TextWithLabel';
import PaperWithTitle from '../../commons/containers/PaperWithTile/PaperWithTitle';
import SmartCheckboxField from '../../commons/form/SmartCheckboxField';
import { ErrorBar, ProcessingBar } from '../../commons/snackbars/snackbars';
import { connectorTypeMap } from '../../domain/connector';
import mainTheme from '../../themes/mainTheme';
import ChargePointConnectorStatus from '../ChargePointDetails/ChargePointConnector/ChargePointConnectorStatus';
import useRemoteStartTransactions, { RemoteStartEvent, RemoteStartFlowState } from './hook/useRemoteStartTransactions';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center'
  },
  infoText: {
    padding: theme.spacing(1)
  }
}));

const RemoteStartTransactionReviewSteps = ({ chargePointId }) => {
  const classes = useStyles(mainTheme);
  const { state, addEvent } = useRemoteStartTransactions();
  const formikRef = createRef();

  const handleBackBtn = () => {
    addEvent({
      type: RemoteStartEvent.REVIEW_REMOTE_START_CANCELLED
    });
  };

  useEffect(() => {
    if (state.flowState === RemoteStartFlowState.FAILED_TO_START) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.flowState, formikRef]);

  const initialValues = {
    confirmation: false
  };
  const validationSchema = Yup.object({
    confirmation: Yup.boolean().oneOf([true], 'Please confirm the details above')
  });
  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={(values) => {
          addEvent({
            type: RemoteStartEvent.START_TRANSACTION_SUBMITTED,
            payload: {
              locationId: chargePointId,
              confirmation: values.confirmation
            }
          });
        }}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <PaperWithTitle title={'Review Remote Start Transaction'}>
              <>
                <Box>
                  <TextWithLabel label="Charge Point ID" text={chargePointId} />
                  <TextWithLabel label="Connector Type" text={connectorTypeMap.get(state.evse.connector.standard)} />
                  <BoxWithLabel label="Connector Status">
                    <ChargePointConnectorStatus status={state.evse.status} />
                  </BoxWithLabel>
                  <TextWithLabel label="User" text={state.preview.name} />
                  <TextWithLabel label="Email" text={state.email} />
                  {state.preview.hasTariff && (
                    <TextWithLabel label="Credit Card" text={`●●●● ●●●● ●●●● ${state.preview.cardDetails.lastFourDigits}`} />
                  )}
                  {state.preview.isPostpay && <TextWithLabel label="Postpay Account" text={state.preview.postpayAccountName} />}
                </Box>
                <Field
                  label="I have confirmed the customer's identity and obtained their consent to initiate transactions using their stored payment method."
                  name="confirmation"
                  component={SmartCheckboxField}
                />
                <Box className={classes.infoBox}>
                  <InfoOutlined />
                  <Box className={classes.infoText}>
                    <Typography>Please advise user to plug in their vehicle.</Typography>
                  </Box>
                </Box>
              </>
            </PaperWithTitle>
            <Box className={classes.submitButtonContainer}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                className={classes.submitButton}
                disabled={formik.isSubmitting}
                onClick={() => handleBackBtn()}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
              >
                Remote Start Transaction
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <ErrorBar open={!_.isNull(state.errorMessage)}>{state.errorMessage}</ErrorBar>
      <ProcessingBar open={state.flowState === RemoteStartFlowState.TRANSACTION_STARTING} text={'Sending Remote Start Command..'} />
    </>
  );
};

RemoteStartTransactionReviewSteps.propTypes = {
  chargePointId: PropTypes.string.isRequired
};

export default RemoteStartTransactionReviewSteps;
