export enum ChargerTariffsFlowState {
  INIT = 'INIT',
  UPDATE_CHARGER_TARIFF_FAILED = 'UPDATE_CHARGER_TARIFF_FAILED',
  CHARGER_TARIFFS_LOADED = 'CHARGER_TARIFFS_LOADED',
  LOADING_TARIFFS = 'LOADING_TARIFFS',
  SHOW_EDIT_TARIFF_FORM = 'SHOW_EDIT_TARIFF_FORM',
  UPDATING_TARIFF = 'UPDATING_TARIFF',
  TARIFF_UPDATED = 'TARIFF_UPDATED',
  LOAD_CHARGER_TARIFF_FAILED = 'LOAD_CHARGER_TARIFF_FAILED'
}

// state
export type ChargerTariffsState = {
  tariffUpdated: boolean;
  tariffErrorMessage: string;
  tariff: ChargerTariffSummary;
  loadingState: any;
  flowState: ChargerTariffsFlowState;
};

export type UseChargerTariffsType = {
  state: ChargerTariffsState;
  addEvent: (event: ChargerTariffsEventType) => void;
};
export type ChargerTariffsEventType = {
  type: ChargerTariffsEvent;
  payload?: any;
};

// events
export enum ChargerTariffsEvent {
  LOAD_CHARGER_TARIFFS = 'LOAD_CHARGER_TARIFFS',
  EDIT_TARIFF_FORM_REQUESTED = 'EDIT_TARIFF_FORM_REQUESTED',
  SUBMIT_TARIFF_UPDATE = 'SUBMIT_TARIFF_UPDATE',
  CANCEL_TARIFF_UPDATE = 'CANCEL_TARIFF_UPDATE'
}

export type ChargerTariffSummary = {
  id: string | null;
  ratePerKwh: number;
  ratePerMinute: number;
  unlockFee: number;
};
