import _ from 'lodash';
import React, { createContext, PropsWithChildren, useReducer } from 'react';
import { initialState as loadingContainerInitialState } from '../../../../hooks/useLoadingContainerWithErrorPanel';
import { ChargerTransactionsFlowState, ChargerTransactionsState } from '../../../../types/charger/ChargerTransactions';

const reducer = (state: ChargerTransactionsState, newState: ChargerTransactionsState) => ({ ...state, ...newState });

// initial state
const initialState: ChargerTransactionsState = {
  transactions: [],
  flowState: ChargerTransactionsFlowState.INIT,
  loadingState: loadingContainerInitialState
};

type ChargerTransactionsContextType = {
  state: ChargerTransactionsState;
  dispatch: (newState: ChargerTransactionsState) => void;
};

// context
export const ChargerTransactionsContext = createContext<ChargerTransactionsContextType>({
  state: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

// provider
export const ChargerTransactionsProvider: ({ children }: React.PropsWithChildren<any>) => React.JSX.Element = ({
  children
}: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <ChargerTransactionsContext.Provider value={{ state, dispatch }}>{children}</ChargerTransactionsContext.Provider>
  );
};
