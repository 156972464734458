import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import PaperWithTitle from '../../../../commons/containers/PaperWithTile/PaperWithTitle';

import paths from '../../../../routes/paths';
import { ChargerRemoteStartFlowState } from '../../../../types/charger/ChargerRemoteStartState';
import RemoteStartTransactionReviewSteps from '../RemoteStartTransactionReviewSteps';
import useRemoteStartTransactions from '../hook/useRemoteStartTransactions';
import RemoteStartChooseConnectorForm from './RemoteStartChooseConnectorForm';
import RemoteStartPreviewForm from './RemoteStartPreviewForm';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

interface ChargerRemoteStartFormProps {
  displayName?: string;
  countryCode?: string;
  partyId?: string;
  locationId: string;
}
const ChargerRemoteStartForm: FC<ChargerRemoteStartFormProps> = ({ countryCode, partyId, locationId, displayName }) => {
  const { state } = useRemoteStartTransactions();
  const history = useHistory();
  const classes = useStyles();

  const handleGoToCharger = () => {
    const url = `${paths.LOCATIONS}/${countryCode}/${partyId}/${locationId}/chargers/${displayName}/details`;
    return history.push(url);
  };

  const TransactionStartedCard = () => {
    return (
      <>
        <PaperWithTitle title="Remote Start Completed">
          <Typography>
            Start transaction command has been sent to the charger. The transaction could take up to a minute to show in the admin portal. If the
            customer hasn't plugged in their EV yet, please prompt them to do so.
          </Typography>
        </PaperWithTitle>
        <Box className={classes.buttonContainer}>
          <Button onClick={handleGoToCharger} variant="contained" color="primary">
            Go to Charger
          </Button>
        </Box>
      </>
    );
  };

  switch (state.flowState) {
    case ChargerRemoteStartFlowState.INIT:
    case ChargerRemoteStartFlowState.NOT_ELIGIBLE:
    case ChargerRemoteStartFlowState.CHECKING_ELIGIBILITY:
      return <RemoteStartPreviewForm locationId={locationId} displayName={displayName} />;
    case ChargerRemoteStartFlowState.ELIGIBLE:
    case ChargerRemoteStartFlowState.CONNECTORS_LOADED:
      return <RemoteStartChooseConnectorForm locationId={locationId} displayName={displayName} />;
    case ChargerRemoteStartFlowState.REVIEW:
    case ChargerRemoteStartFlowState.FAILED_TO_START:
    case ChargerRemoteStartFlowState.TRANSACTION_STARTING:
      return <RemoteStartTransactionReviewSteps locationId={locationId} displayName={displayName} />;
    case ChargerRemoteStartFlowState.TRANSACTION_STARTED:
      return <TransactionStartedCard />;
    default:
      return null;
  }
};

export default ChargerRemoteStartForm;
