export type EmspAccount = {
  id: string;
  name: string;
  accountType: AccountType;
  organisationName: string;
  numberOfAdmins: number;
  numberOfUsers: number;
};

export type EmspAccountSummary = {
  name: string;
  orgId: string;
  accountType: AccountType;
};

export type EmspAccountAdmin = {
  id: string;
  name: string;
  email: string;
};

export type EmspAccountUserChargeTag = {
  id: string;
  isActive: boolean;
};

export type EmspAccountUser = {
  id: string;
  name: string;
  email: string;
  chargeTags: EmspAccountUserChargeTag[];
};

export enum AccountType {
  PAYG = 'PAYG',
  POSTPAY = 'POSTPAY'
}
