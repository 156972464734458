import React, {createContext, useContext, useReducer} from 'react';
import chargePointGroupService from '../../../services/chargePointGroupService';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState,
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import _ from 'lodash';

// events
export const ChargePointGroupEvent = {
  LOAD_CHARGE_POINT_GROUP_TABLE: 'LOAD_CHARGE_POINT_GROUP_TABLE',
  SHOW_NEW_CHARGE_POINT_GROUP_FORM: 'SHOW_NEW_CHARGE_POINT_GROUP_FORM',
  CLOSE_NEW_CHARGE_POINT_GROUP_FORM: 'CLOSE_NEW_CHARGE_POINT_GROUP_FORM',
  SUBMIT_NEW_CHARGE_POINT_GROUP_FORM: 'SUBMIT_NEW_CHARGE_POINT_GROUP_FORM',
};

// flow states
export const ChargePointGroupFlowState = {
  INIT: 'INIT',
  SHOWING_CHARGE_POINT_GROUPS_TABLE: 'SHOWING_CHARGE_POINT_GROUPS_TABLE',
  FAILED_TO_LOAD_CHARGE_POINT_GROUPS: 'FAILED_TO_LOAD_CHARGE_POINT_GROUPS',
  SHOWING_NEW_CHARGE_POINT_GROUP_FORM: 'SHOWING_NEW_CHARGE_POINT_GROUP_FORM',
  NEW_CHARGE_POINT_GROUP_CREATED: 'NEW_CHARGE_POINT_GROUP_CREATED',
  FAILED_TO_CREATE_NEW_CHARGE_POINT_GROUP: 'FAILED_TO_CREATE_NEW_CHARGE_POINT_GROUP',
  NEW_CHARGE_POINT_GROUP_FORM_CLOSED: 'NEW_CHARGE_POINT_GROUP_FORM_CLOSED',
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  chargePointGroups: [],
  flowState: ChargePointGroupFlowState.INIT,
  newChargePointGroup: null,
  newChargePointGroupErrorMessage: null,
};

// reducer
const reducer = (state, newState) => ({...state, ...newState});

// context
const chargePointGroupsContext = createContext();

// provider
export const ChargePointGroupsProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <chargePointGroupsContext.Provider value={{state, dispatch}}>{children}</chargePointGroupsContext.Provider>
  );
};

// hook
const useChargePointGroups = () => {
  const {state, dispatch} = useContext(chargePointGroupsContext);

  const addEvent = (event) => {
    switch (event.type) {
      case ChargePointGroupEvent.LOAD_CHARGE_POINT_GROUP_TABLE:
        chargePointGroupService.getAllGroupsForAdmin().subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              chargePointGroups: result,
              flowState: ChargePointGroupFlowState.SHOWING_CHARGE_POINT_GROUPS_TABLE,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointGroupFlowState.FAILED_TO_LOAD_CHARGE_POINT_GROUPS,
            })
        );
        break;
      case ChargePointGroupEvent.SHOW_NEW_CHARGE_POINT_GROUP_FORM:
        dispatch({
          loadingState: loadingContainerSucceededState,
          newChargePointGroup: null,
          newChargePointGroupErrorMessage: null,
          flowState: ChargePointGroupFlowState.SHOWING_NEW_CHARGE_POINT_GROUP_FORM,
        });
        break;
      case ChargePointGroupEvent.CLOSE_NEW_CHARGE_POINT_GROUP_FORM:
        dispatch({
          flowState: ChargePointGroupFlowState.NEW_CHARGE_POINT_GROUP_FORM_CLOSED,
        });
        break;
      case ChargePointGroupEvent.SUBMIT_NEW_CHARGE_POINT_GROUP_FORM:
        const payload = event.payload;
        // submit
        chargePointGroupService.createGroup(payload.id, payload.name, payload.isPublic).subscribe(
          (result) => {
            dispatch({
              flowState: ChargePointGroupFlowState.NEW_CHARGE_POINT_GROUP_CREATED,
              newChargePointGroup: {id: payload.id, name: payload.name, isPublic: payload.isPublic},
            });
          },
          (error) => {
            dispatch({
              flowState: ChargePointGroupFlowState.FAILED_TO_CREATE_NEW_CHARGE_POINT_GROUP,
              newChargePointGroupErrorMessage: error.message,
            });
          }
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
  };
};

export default useChargePointGroups;
