import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import SimpleMultiAutoComplete from '../../../commons/selects/SimpleMultiAutoComplete';
import SimpleMultiChips from '../../../commons/selects/SimpleMultiChips';
import SimpleSelect from '../../../commons/selects/SimpleSelect';
import mainTheme from '../../../themes/mainTheme';
import { InitialStateType } from '../../../types/SharedStates';
import { initialState } from './hook/ChargePointInsightsContextProvider';

const useStyles = makeStyles((theme) => ({
  filter: {
    marginTop: theme.spacing(2)
  },
  filterHeading: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  resetButton: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2)
  }
}));

interface ChargePointInsightsFilterProps {
  loadingFilterState: InitialStateType;
  filter: any;
  filteredBy: any;
  onFilterUpdated: (filter: any) => void;
}

const createOptionsForSelect = (options: any) =>
  _.sortBy(
    options.map((organisation: any) => ({
      value: organisation.name,
      displayValue: organisation.name
    })),
    ['displayValue']
  );

const ChargePointInsightsFilter: React.FC<ChargePointInsightsFilterProps> = ({ loadingFilterState, filter, filteredBy, onFilterUpdated }) => {
  const classes = useStyles(mainTheme);
  const [locationsOptions, setChargePointLocationOptions] = useState(createOptionsForSelect(filter.chargePointLocationsChoices));

  useEffect(() => {
    setChargePointLocationOptions(createOptionsForSelect(filter.chargePointLocationsChoices));
  }, [filter.chargePointLocationsChoices]);

  const organisationsOptions = createOptionsForSelect(filter.chargePointOrganisationsChoices);

  const lastNumberOfDaysOptions = filter.lastNumberOfDaysChoices.map((lastNumberOfDays: number) => ({
    value: lastNumberOfDays,
    displayValue: `Last ${lastNumberOfDays} days`
  }));

  const filteredLocationsOptionsByOrganisation = (orgs: any) => {
    if (!orgs || orgs.length === 0) {
      return filter.chargePointLocationsChoices;
    }
    const filtered = filter.chargePointLocationsChoices.filter((location: any) => {
      return orgs.includes(location.owner);
    });
    const getValuesForChoices = createOptionsForSelect(filtered);
    return getValuesForChoices;
  };

  const handleLastNumberOfDaysChange = (value: number) => {
    // update selected last number of days
    const updatedSelectedFilter = { ...filteredBy, lastNumberOfDays: value };
    onFilterUpdated(updatedSelectedFilter);
  };

  const handleOrganisationsChange = (values: number[]) => {
    let updatedSelectedFilter = { ...filteredBy, chargePointOrganisations: values };
    if (values.length === 0) {
      setChargePointLocationOptions(createOptionsForSelect(filter.chargePointLocationsChoices));
      updatedSelectedFilter = { ...updatedSelectedFilter, chargePointLocations: [] };
    }
    if (values.length > 0) {
      const filteredLocations = filteredLocationsOptionsByOrganisation(values);
      setChargePointLocationOptions(filteredLocations);
    }
    onFilterUpdated(updatedSelectedFilter);
  };

  const handleLocationsChange = (values: number[]) => {
    const updatedSelectedFilter = { ...filteredBy, chargePointLocations: values };
    onFilterUpdated(updatedSelectedFilter);
  };

  const resetFilter = () => onFilterUpdated(initialState.filteredBy);
  return (
    <Box className={classes.filter}>
      <LoadingContainerWithErrorPanel {...loadingFilterState}>
        <Box>
          {filter.chargePointOrganisationsChoices.length > 0 && (
            <SimpleMultiAutoComplete
              placeholderWithNoValues="All Organisations"
              placeholderWithSelectedValues="Select More Organisations"
              defaultValues={filteredBy.chargePointOrganisations}
              options={organisationsOptions}
              onChange={handleOrganisationsChange}
            />
          )}
          <SimpleMultiAutoComplete
            placeholderWithNoValues="All Locations"
            placeholderWithSelectedValues="Select More Locations"
            defaultValues={filteredBy.chargePointLocations}
            options={locationsOptions}
            onChange={handleLocationsChange}
          />
          <SimpleSelect defaultValue={filteredBy.lastNumberOfDays} options={lastNumberOfDaysOptions} onChange={handleLastNumberOfDaysChange} />
          <Button className={classes.resetButton} variant="contained" color="secondary" onClick={resetFilter}>
            Reset Filter
          </Button>
        </Box>
        <Box>
          <SimpleMultiChips defaultValues={filteredBy.chargePointOrganisations} options={organisationsOptions} onChange={handleOrganisationsChange} />
        </Box>
      </LoadingContainerWithErrorPanel>
    </Box>
  );
};

export default ChargePointInsightsFilter;
