import CardMedia from '@material-ui/core/CardMedia';
import React from 'react';
import ccs from '../../../assets/images/connectors/ccs.svg';
import chademo from '../../../assets/images/connectors/chademo.svg';
import type1 from '../../../assets/images/connectors/type1.svg';
import type2 from '../../../assets/images/connectors/type2.svg';
import { ConnectorStandard } from '../../../types/charger/Charger';

interface ChargerConnectorIconProps {
  type: ConnectorStandard;
}
const ChargerConnectorIcon: React.FC<ChargerConnectorIconProps> = ({ type }) => {
  const getConnector = (type: ConnectorStandard) => {
    switch (type) {
      case ConnectorStandard.CHADEMO:
        return chademo;
      case ConnectorStandard.IEC_62196_T1:
        return type1;
      case ConnectorStandard.IEC_62196_T2:
        return type2;
      case ConnectorStandard.IEC_62196_T2_COMBO:
        return ccs;
      default:
        return type1;
    }
  };
  return (
    <CardMedia>
      <img src={getConnector(type)} alt="Connector" height={72} width={72} />
    </CardMedia>
  );
};

export default ChargerConnectorIcon;
