import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { SuccessBar } from '../../commons/snackbars/snackbars';
import paths from '../../routes/paths';
import { LocationDetailsFlowState } from '../../types/LocationDetails';
import { LocationsFlowState } from '../../types/location/LocationState';
import Dashboard from '../Dashboard/Dashboard';
import LocationsTable from './LocationsTable';
import useLocations, { LocationEvent } from './hook/useLocations';
import { withLocations } from './hook/withLocations';
import { AdminPermission, requiresAdminPermission } from '../../hooks/useAdminPermissions';

type LocationState = {
  showLocationCreatedSuccessBar: boolean;
};

const Locations = () => {
  const { state, addEvent } = useLocations();
  const location = useLocation<LocationState>();
  const history = useHistory();

  useEffect(() => {
    if (state.flowState === LocationsFlowState.INIT || state.flowState === LocationDetailsFlowState.LOCATION_UPDATED || state.flowState === LocationDetailsFlowState.NEW_LOCATION_CREATED)
      addEvent({ type: LocationEvent.LOAD_LOCATIONS });
  }, [state.flowState, addEvent, location.state, history]);

  return (
    <Dashboard pageHeading="Locations">
      <>
        <LoadingContainerWithErrorPanel {...state.loadingState}>
          <LocationsTable />
        </LoadingContainerWithErrorPanel>
        <SuccessBar open={location?.state?.showLocationCreatedSuccessBar} fullPage={false} onClose={() => history.push(paths.LOCATIONS)}>
          New Location has been created!
        </SuccessBar>
      </>
    </Dashboard>
  );
};

export default requiresAdminPermission(AdminPermission.LOCATION_ADMIN)(withLocations(Locations));
