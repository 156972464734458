import {ajax} from 'rxjs/ajax';
import {catchError, map} from 'rxjs/operators';
import {buildQueryString} from '../utils/url/buildQueryString';
import {withAccessToken} from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import {errorMessageObservable} from '../utils/error/errorUtils';

const chargeTagService = {
  fetchChargeTagsByUserUuid: (userId) => {
    const queryString = buildQueryString({
      user_id: userId,
    });
    return withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-tags?${queryString}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
    ).pipe(
      map((response) => response.response.data),
      catchError((error) => errorMessageObservable(error))
    );
  },
  addChargeTag: (userId, chargeTagId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-tags`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          user_id: userId,
          charge_tag_id: chargeTagId,
        },
      })
    ).pipe(
      map((response) => response),
      catchError((error) => errorMessageObservable(error))
    ),
  deleteChargeTag: (userId, chargeTagId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-tags`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: {
          user_id: userId,
          charge_tag_id: chargeTagId,
        },
      })
    ).pipe(
      map((response) => response),
      catchError((error) => errorMessageObservable(error))
    ),
};

export default chargeTagService;
