import { OrganisationAccountsProvider } from './useOrganisationAccounts';
import React from 'react';

export const withOrganisationAccounts =
  <T extends Record<string, any>>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: T) => {
    return (
      <OrganisationAccountsProvider>
        <Component {...props} />
      </OrganisationAccountsProvider>
    );
  };
