import * as yup from 'yup';
import { ArraySchema, ValidationError } from 'yup';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ArrayType = any[] | null | undefined;

function distinctOnProperty<TIn extends ArrayType, TContext, TDefault = undefined, TFlags extends yup.Flags = ''>(
  this: ArraySchema<TIn, TContext, TDefault, TFlags>,
  property: string,
  message: string
) {
  return this.test('distinct-on-property', message, (array, context) => {
    if (!array) {
      return true;
    }

    const errors: ValidationError[] = [];
    const duplicateProperties = array.filter((e, i) => array.findIndex((e2) => e2[property] === e[property]) !== i).map((e) => e[property]);
    for (let i = 0; i < array.length; i += 1) {
      const element = array[i];
      if (element[property] !== '' && duplicateProperties.includes(element[property])) {
        errors.push(new ValidationError(message, element, `${context.path}[${i}].${property}`));
      }
    }

    if (errors.length > 0) {
      return context.createError({ message: () => errors });
    }

    return true;
  });
}

function distinctOnNestedProperty<TIn extends ArrayType, TContext, TDefault = undefined, TFlags extends yup.Flags = ''>(
  this: ArraySchema<TIn, TContext, TDefault, TFlags>,
  nestedArray: string, // The nested array 'b' within each 'a'
  nestedProperty: string, // The 'c' property within each 'b'
  message: string
) {
  return this.test('distinct-on-nested-property', message, (array, context) => {
    if (!array) {
      return true;
    }

    const errors: ValidationError[] = [];
    const seenValues = new Set();

    for (let i = 0; i < array.length; i += 1) {
      const outerElement = array[i];
      const innerArray = outerElement[nestedArray];

      if (Array.isArray(innerArray)) {
        for (let j = 0; j < innerArray.length; j += 1) {
          const innerElement = innerArray[j];
          const value = innerElement[nestedProperty];

          if (value !== undefined && value !== null) {
            if (seenValues.has(value)) {
              errors.push(new ValidationError(message, innerElement, `${context.path}[${i}].${nestedArray}[${j}].${nestedProperty}`));
            } else {
              seenValues.add(value);
            }
          }
        }
      }
    }

    if (errors.length > 0) {
      return context.createError({ message: () => errors });
    }

    return true;
  });
}

yup.addMethod(yup.array, 'distinctOnProperty', distinctOnProperty);
yup.addMethod(yup.array, 'distinctOnNestedProperty', distinctOnNestedProperty);

declare module 'yup' {
  interface ArraySchema<TIn, TContext = any, TDefault = undefined> extends yup.Schema<TIn[], TContext, TDefault> {
    distinctOnProperty(property: string, message: string): this;

    distinctOnNestedProperty(nestedArray: string, nestedProperty: string, message: string): this;
  }
}

export default yup;
