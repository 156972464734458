// reducer
import _ from 'lodash';
import { PropsWithChildren, createContext, useReducer } from 'react';
import { initialState as loadingContainerInitialState } from '../../../../hooks/useLoadingContainerWithErrorPanel';
import { ReducerContext } from '../../../../types/SharedStates';
import { LocationAdminsFlowState, LocationAdminsState } from '../../../../types/location/admin/LocationAdminState';

// initial state
const initialState = {
  flowState: LocationAdminsFlowState.INIT,
  loadingState: loadingContainerInitialState,
  admins: [],
  errorMessage: ''
};

const reducer = (state: LocationAdminsState, newState: Partial<LocationAdminsState>) => ({ ...state, ...newState });

// context
export const LocationAdminsContext = createContext<ReducerContext<LocationAdminsState>>({ state: initialState, dispatch: () => initialState });

// provider
const LocationAdminsProvider: ({ children }: React.PropsWithChildren<any>) => React.JSX.Element = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));

  return <LocationAdminsContext.Provider value={{ state, dispatch }}>{children}</LocationAdminsContext.Provider>;
};

export default LocationAdminsProvider;
