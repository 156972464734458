import { Box, Button, makeStyles } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import { FC, RefObject } from 'react';
import PaperWithTitle from '../../../../commons/containers/PaperWithTile/PaperWithTitle';
import { NewCpoChargePoint } from '../../../../types/charger/Charger';
import { ChargerConnectorsPanel } from '../../ChargerDetails/ChargerInfo/ChargerConnectorsPanel';
import NewChargerReviewDetails from './NewChargerReviewDetails';

const useStyles = makeStyles((theme) => ({
  connectorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2)
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionButton: {
    marginLeft: theme.spacing(2)
  }
}));

interface NewChargerReviewProps {
  newChargePoint: NewCpoChargePoint;
  onSubmit: (values: NewCpoChargePoint) => void;
  onCancel: () => void;
  formikRef: RefObject<FormikProps<any>>;
}

const NewChargerReviewForm: FC<NewChargerReviewProps> = ({ newChargePoint, onSubmit, onCancel, formikRef }) => {
  const classes = useStyles();

  return (
    <Formik innerRef={formikRef} initialValues={newChargePoint} onSubmit={onSubmit}>
      {(formikProps) => (
        <Form>
          <PaperWithTitle title="General">
            <NewChargerReviewDetails newChargePoint={newChargePoint} />
          </PaperWithTitle>
          {newChargePoint &&
            newChargePoint.evses.map((evse, index) => {
              const connectorsWithEvseReference = evse.connectors.map((connector) => ({ ...connector, evseReference: evse.evseReference }));

              return (
                <PaperWithTitle key={index} title={`EVSE ${evse.evseReference}`}>
                  <ChargerConnectorsPanel connectors={connectorsWithEvseReference} />
                </PaperWithTitle>
              );
            })}
          <Box className={classes.submitButtonContainer}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              className={classes.actionButton}
              disabled={formikProps.isSubmitting}
              onClick={onCancel}
            >
              Back
            </Button>
            <Button type="submit" variant="contained" color="primary" className={classes.actionButton} disabled={formikProps.isSubmitting}>
              Confirm
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default NewChargerReviewForm;
