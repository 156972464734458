import { requiresPermission } from '../../hocs/requiresPermission';
import { Permission } from '../../types/user/UserPermission';
import Dashboard from '../Dashboard/Dashboard';
import NewOrganisationForm from './NewOrganisationForm';
import useNewOrganisation from './hook/useNewOrganisation';
import { withNewOrganisation } from './hook/withNewOrganisation';

const NewOrganisationContainer = () => {
  const { state, addEvent } = useNewOrganisation();

  return (
    <Dashboard pageHeading="New Organisation">
      <NewOrganisationForm state={state} addEvent={addEvent} />
    </Dashboard>
  );
};

export default requiresPermission(Permission.MANAGE_ORGANISATION)(withNewOrganisation(NewOrganisationContainer));
