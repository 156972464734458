import {Box, Button, makeStyles, Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import useRemoteStartTransactions, {RemoteStartEvent} from '../hook/useRemoteStartTransactions';
import PropTypes from 'prop-types';
import ChargePointConnectorIcon from '../../ChargePointDetails/ChargePointConnector/ChargePointConnectorIcon';
import mainTheme from '../../../themes/mainTheme';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import {connectorTypeMap} from '../../../domain/connector';
import BoxWithLabel from '../../../commons/containers/ComponentWithLabel/BoxWithLabel';
import ChargePointConnectorStatus from '../../ChargePointDetails/ChargePointConnector/ChargePointConnectorStatus';
import {MdChevronRight} from 'react-icons/md';

const useStyles = makeStyles((theme) => ({
  connectorBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(1),
  },
  connectorButton: {
    margin: theme.spacing(1),
  },
}));

const RemoteStartChooseConnectorForm = ({chargePointId}) => {
  const classes = useStyles(mainTheme);
  const {state, addEvent} = useRemoteStartTransactions();
  
  const handleChooseConnector = (evse) => {
    addEvent({
      type: RemoteStartEvent.CONNECTOR_SUBMITTED,
      payload: {
        evse: evse,
      },
    });
  };
  
  useEffect(() => {
    addEvent({
      type: RemoteStartEvent.CONNECTORS_OPENED,
      payload: {
        locationId: chargePointId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <>
      <Typography variant="h3">Choose connector</Typography>
      {state.connectors.length > 0 &&
        state.connectors.map((evse) => (
          <Button className={classes.connectorButton} key={evse.id} onClick={() => handleChooseConnector(evse)} fullWidth>
            <Box className={classes.connectorBox}>
              <ChargePointConnectorIcon type={evse.connector.standard}/>
              <TextWithLabel label="Type" text={connectorTypeMap.get(evse.connector.standard)}/>
              <BoxWithLabel label="Status">
                <ChargePointConnectorStatus status={evse.status}/>
              </BoxWithLabel>
              {evse.connector.id && <TextWithLabel label="OCPP ID" text={evse.connector.id}/>}
              <MdChevronRight size={30}/>
            </Box>
          </Button>
        ))}
    </>
  );
};

RemoteStartChooseConnectorForm.propTypes = {
  chargePointId: PropTypes.string.isRequired,
};

export default RemoteStartChooseConnectorForm;
