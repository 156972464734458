import React from 'react';
import useUserChargeTags from './hook/useUserChargeTags';
import UserChargeTagDetails from './UserChargeTagDetails';
import _ from 'lodash';
import NoChargeTagsPanel from './NoChargeTagsPanel';

const UserChargeTagsDetailsPanels = props => {
  const {state} = useUserChargeTags();
  
  if (_.isEmpty(state.chargeTags)) {
    return <NoChargeTagsPanel/>;
  }
  
  return (
    <>
      <UserChargeTagDetails chargeTags={state.chargeTags}/>
    </>
  );
};

UserChargeTagsDetailsPanels.propTypes =
{

};

export default UserChargeTagsDetailsPanels;