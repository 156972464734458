import { useContext } from 'react';
import {
  failedState as loadingContainerFailedState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import cpoAdminService from '../../../../services/cpoAdminService';
import emspLocationServicev4 from '../../../../services/emspLocationServicev4';
import { EventType } from '../../../../types/SharedStates';
import { NewChargerFlowState, UseNewChargerType } from '../../../../types/charger/ChargerInfoState';
import { NewChargerContext } from './NewChargerProvider';
import { delay } from 'lodash';
// events
export enum NewChargerEvent {
  ENTERING_NEW_CHARGE_POINT_DETAILS = 'ENTERING_NEW_CHARGE_POINT_DETAILS',
  REVIEW_NEW_CHARGE_POINT_FORM_REQUESTED = 'REVIEW_NEW_CHARGE_POINT_FORM_REQUESTED',
  SUBMIT_NEW_CHARGE_POINT_FORM_REQUESTED = 'SUBMIT_NEW_CHARGE_POINT_FORM_REQUESTED',
  REVIEW_NEW_CHARGE_POINT_FORM_CANCELLED = 'REVIEW_NEW_CHARGE_POINT_FORM_CANCELLED'
}

// hook
const useNewCharger = (): UseNewChargerType & { NewChargerEvent: typeof NewChargerEvent } => {
  const { state, dispatch } = useContext(NewChargerContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case NewChargerEvent.ENTERING_NEW_CHARGE_POINT_DETAILS:
        emspLocationServicev4.getOPLLocationsSummary().subscribe(
          (result) =>
            dispatch({
              ...state,
              loadingState: loadingContainerSucceededState,
              flowState: NewChargerFlowState.ENTERING_NEW_CHARGE_POINT_DETAILS,
              locations: result
            }),
          (error) =>
            dispatch({
              ...state,
              loadingState: loadingContainerFailedState(error.message),
              flowState: NewChargerFlowState.ENTERING_NEW_CHARGE_POINT_DETAILS
            })
        );
        break;
      case NewChargerEvent.REVIEW_NEW_CHARGE_POINT_FORM_REQUESTED:
        dispatch({
          ...state,
          loadingState: loadingContainerSucceededState,
          flowState: NewChargerFlowState.REVIEWING_NEW_CHARGE_POINT_FORM,
          newChargePoint: { ...event.payload },
          createChargePointErrorMessage: null
        });

        break;

      case NewChargerEvent.SUBMIT_NEW_CHARGE_POINT_FORM_REQUESTED:
        dispatch({
          ...state,
          loadingState: loadingContainerSucceededState,
          flowState: NewChargerFlowState.SUBMITTING_CHARGE_POINT_FORM,
          createChargePointErrorMessage: null
        });

        cpoAdminService.createChargePoint({ ...event.payload }).subscribe(
          () =>
            delay(() => {
              dispatch({
                ...state,
                loadingState: loadingContainerSucceededState,
                flowState: NewChargerFlowState.NEW_CHARGE_POINT_CREATED,
                createChargePointErrorMessage: null
              });
            }, 1000),
          (error) =>
            dispatch({
              ...state,
              loadingState: loadingContainerFailedState(error.message),
              flowState: NewChargerFlowState.ENTERING_NEW_CHARGE_POINT_DETAILS,
              createChargePointErrorMessage: error.message
            })
        );
        break;

      case NewChargerEvent.REVIEW_NEW_CHARGE_POINT_FORM_CANCELLED:
        dispatch({
          ...state,
          loadingState: loadingContainerSucceededState,
          flowState: NewChargerFlowState.ENTERING_NEW_CHARGE_POINT_DETAILS
        });
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
    NewChargerEvent
  };
};

export default useNewCharger;
