import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {Box} from '@material-ui/core';

const ValueWithUnit = ({value, unit, textAlign}) => (
  <Box textAlign={textAlign}>
    <Typography display="inline" variant="h3">
      {value}
    </Typography>
    {unit && (
      <Typography display="inline" variant="h5">
        {' '}
        {unit}
      </Typography>
    )}
  </Box>
);

ValueWithUnit.defaultValue = {
  textAlign: 'left',
};

ValueWithUnit.propTypes = {
  value: PropTypes.string.isRequired,
  unit: PropTypes.string,
  textAlign: PropTypes.string,
};

export default ValueWithUnit;
