import { useContext } from 'react';
import { succeededState as loadingContainerSucceededState } from '../../../../../hooks/useLoadingContainerWithErrorPanel';
import cpoAdminService from '../../../../../services/cpoAdminService';
import { EventType } from '../../../../../types/SharedStates';
import { ChargerInfoFlowState, UseChargerInfoType } from '../../../../../types/charger/ChargerInfoState';

import { delay } from 'lodash'; // events
import { ChargerInfoContext } from './ChargerInfoProvider';
import { CpoConnector, CpoEvse } from '../../../../../types/charger/Charger';

// events
enum ChargerInfoEvent {
  VIEW_CHARGER = 'VIEW_CHARGER',
  RELOAD_CHARGER = 'RELOAD_CHARGER',
  EDIT_CHARGER_REQUESTED = 'EDIT_CHARGER_REQUESTED',
  CHARGER_ACTION_CANCELLED = 'CHARGER_ACTION_CANCELLED',
  SUBMIT_EDIT_CHARGER = 'SUBMIT_EDIT_CHARGER',
  CLOSE_SUCCESS_DIALOG = 'CLOSE_SUCCESS_DIALOG',
  CLOSE_ERROR_DIALOG = 'CLOSE_ERROR_DIALOG',
  RESET_CHARGER_REQUESTED = 'RESET_CHARGER_REQUESTED',
  SUBMIT_RESET_CHARGER = 'SUBMIT_RESET_CHARGER',
  EDIT_CONNECTORS_REQUESTED = 'EDIT_CONNECTORS_REQUESTED',
  EDIT_CONNECTORS_TARIFF_REQUESTED = 'EDIT_CONNECTORS_TARIFF_REQUESTED',
  SUBMIT_EDIT_CONNECTORS = 'SUBMIT_EDIT_CONNECTORS',
  SUBMIT_EDIT_CONNECTORS_TARIFF = 'SUBMIT_EDIT_CONNECTORS_TARIFF',
  REMOTE_START_CHARGER_REQUESTED = 'REMOTE_START_CHARGER_REQUESTED',
  DECOMMISSION_CHARGER_REQUESTED = 'DECOMMISSION_CHARGER_REQUESTED'
}

// hook
const useChargerInfo = (): UseChargerInfoType & { ChargerInfoEvent: typeof ChargerInfoEvent } => {
  const { state, dispatch } = useContext(ChargerInfoContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case ChargerInfoEvent.VIEW_CHARGER:
        dispatch({
          flowState: ChargerInfoFlowState.VIEWING_CHARGER,
          emspCharger: event.payload.emspCharger,
          cpoChargePoint: event.payload.cpoChargePoint,
          loadingState: loadingContainerSucceededState
        });
        break;
      case ChargerInfoEvent.RELOAD_CHARGER:
        dispatch({
          flowState: ChargerInfoFlowState.INIT,
          emspCharger: event.payload.emspCharger,
          cpoChargePoint: event.payload.cpoChargePoint
        });
        break;
      case ChargerInfoEvent.EDIT_CHARGER_REQUESTED:
        dispatch({ flowState: ChargerInfoFlowState.SHOW_EDIT_CHARGER });
        break;
      case ChargerInfoEvent.CHARGER_ACTION_CANCELLED:
        dispatch({ flowState: ChargerInfoFlowState.VIEWING_CHARGER });
        break;
      case ChargerInfoEvent.SUBMIT_EDIT_CHARGER:
        dispatch({
          flowState: ChargerInfoFlowState.UPDATING_CHARGER
        });
        cpoAdminService
          .updateCharger(event.payload.cpoChargePointId, event.payload.ocppChargePointIdentifier, event.payload.anonymousChargingAllowed)
          .subscribe(
            () => {
              dispatch({
                flowState: ChargerInfoFlowState.CHARGER_UPDATED,
                successMessage: 'Charger has been updated'
              });
            },
            (error) =>
              dispatch({
                flowState: ChargerInfoFlowState.FAILED_TO_UPDATE_CHARGER,
                errorMessage: `Unable to update charger. ${error.message}`
              })
          );
        break;
      case ChargerInfoEvent.CLOSE_SUCCESS_DIALOG:
        dispatch({ successMessage: '' });
        break;
      case ChargerInfoEvent.CLOSE_ERROR_DIALOG:
        dispatch({ errorMessage: '' });
        break;
      case ChargerInfoEvent.RESET_CHARGER_REQUESTED:
        dispatch({ flowState: ChargerInfoFlowState.SHOW_RESET_CHARGER });
        break;
      case ChargerInfoEvent.SUBMIT_RESET_CHARGER:
        cpoAdminService.resetCharger(event.payload.chargerId, event.payload.resetType).subscribe(
          (result) =>
            dispatch({
              flowState: ChargerInfoFlowState.VIEWING_CHARGER,
              successMessage: `Charger is resetting. Please refresh the page in ${(result / 1000).toString()} seconds`
            }),
          (error) =>
            dispatch({
              flowState: ChargerInfoFlowState.FAILED_TO_RESET_CHARGER,
              errorMessage: `Unable to reset charger. ${error.message}`
            })
        );
        break;
      case ChargerInfoEvent.EDIT_CONNECTORS_REQUESTED:
        dispatch({ flowState: ChargerInfoFlowState.SHOW_EDIT_EVSES });
        break;

      case ChargerInfoEvent.SUBMIT_EDIT_CONNECTORS:
        dispatch({ flowState: ChargerInfoFlowState.UPDATING_EVSES });
        console.log(event.payload);
        cpoAdminService.updateEvses(state.cpoChargePoint, event.payload.evses).subscribe(
          () =>
            delay(() => {
              dispatch({
                flowState: ChargerInfoFlowState.EVSES_UPDATED,
                successMessage: `Evses and Connectors have been updated. Please refresh the page to see the changes.`
              });
            }, 2000),
          (error) =>
            dispatch({
              flowState: ChargerInfoFlowState.FAILED_TO_UPDATE_EVSES,
              errorMessage: `Unable to update connectors. ${error.message}`
            })
        );
        break;

      case ChargerInfoEvent.EDIT_CONNECTORS_TARIFF_REQUESTED:
        dispatch({ flowState: ChargerInfoFlowState.SHOW_EDIT_EVSES });
        break;

      case ChargerInfoEvent.SUBMIT_EDIT_CONNECTORS_TARIFF:
        dispatch({ flowState: ChargerInfoFlowState.UPDATING_EVSES });

        const chargePointId = event.payload.chargePointId;
        const evses = event.payload.evses;

        evses.forEach((evse: CpoEvse) => {
          evse.connectors.forEach((connector: CpoConnector) => {
            cpoAdminService.updateConnector(connector.tariffId, chargePointId, evse.evseId, connector.id).subscribe(
              () =>
                delay(() => {
                  dispatch({
                    flowState: ChargerInfoFlowState.EVSES_UPDATED,
                    successMessage: `Connectors have been updated. Please refresh the page to see the changes.`
                  });
                }, 2000),
              (error) =>
                dispatch({
                  flowState: ChargerInfoFlowState.FAILED_TO_UPDATE_EVSES,
                  errorMessage: `Unable to update connectors. ${error.message}`
                })
            );
          });
        });
        break;

      case ChargerInfoEvent.DECOMMISSION_CHARGER_REQUESTED:
        dispatch({ flowState: ChargerInfoFlowState.SHOW_DECOMMISSION_CHARGER_FORM });
        break;

      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
    ChargerInfoEvent: ChargerInfoEvent
  };
};
export default useChargerInfo;
