const appConfig = {
  isDevelopment: process.env.NODE_ENV === 'development',
  isTest: process.env.NODE_ENV === 'test',
  isProduction: process.env.NODE_ENV === 'production',
  emspApiDomain: process.env.REACT_APP_EMSP_API_DOMAIN,
  emspApiDomainV3: process.env.REACT_APP_EMSP_API_DOMAIN_V3,
  emspAdminApiDomain: process.env.REACT_APP_EMSP_ADMIN_API_DOMAIN,
  emspAdminApiDomainV2: process.env.REACT_APP_EMSP_ADMIN_API_DOMAIN_V2,
  emspAdminApiDomainV3: process.env.REACT_APP_EMSP_ADMIN_API_DOMAIN_V3,
  emspAdminApiDomainV4: process.env.REACT_APP_EMSP_ADMIN_API_DOMAIN_V4,
  emspSuperAdminApiDomain: process.env.REACT_APP_EMSP_SUPER_ADMIN_API_DOMAIN,
  secureStorageSecretKey: process.env.REACT_APP_SECURE_STORAGE_SECRET_KEY,
  csCpoAdmin: process.env.REACT_APP_CS_CPO_ADMIN,
  googlePlacesApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
};

export default appConfig;
