import debounceRender from 'react-debounce-render';
import { Redirect, Route, withRouter } from 'react-router';
import LoadingModal from '../commons/layout/LoadingModal';
import LoginFlow from '../components/LoginFlow/LoginFlow';
import { AdminPermission, useAdminPermissions } from '../hooks/useAdminPermissions';
import useAuthentication from '../hooks/useAuthentication';
import { usePermissions } from '../hooks/usePermissions';
import paths, { DASHBOARD_PATH } from './paths';

const PrivateRoute = ({ component: Component, path, history, ...rest }) => {
  const { initialised, user, authenticate, unauthenticate } = useAuthentication();
  const { permissions } = usePermissions();
  const { hasAdminPermission } = useAdminPermissions();

  const dashboardInsightsRoute = <Redirect to={paths.INSIGHTS} />;
  const accountsRoute = <Redirect {...rest} to={paths.ACCOUNTS} />;
  const transactionRoute = <Redirect {...rest} to={paths.TRANSACTIONS} />;

  const dashboardRoute = <Route {...rest} render={(props) => <Component {...props} />} />;
  const spinningWheelRoute = <Route {...rest} component={debounceRender(LoadingModal, 1000, { leading: false })} />;
  const loginRoute = <Route {...rest} component={LoginFlow} />;

  // if not initialised yet!
  if (!initialised) {
    authenticate();
  }

  // authenticated
  // redirect to dashboard/insights if trying to access non dashboard page
  if (user) {
    // log out if user hits /logout path
    if (path === paths.LOGOUT) {
      unauthenticate();
      return loginRoute;
    }

    // load user permissions
    // check permissions initialised
    const permissionsInitialised = permissions.initialised;

    // if permissions have not been loaded then show spinning wheel
    if (!permissionsInitialised) {
      return spinningWheelRoute;
    }

    // if org admin (or infered greater permissions) then redirect to dashboard
    if (hasAdminPermission(AdminPermission.ORG_ADMIN) || hasAdminPermission(AdminPermission.LOCATION_ADMIN)) {
      return path.startsWith(DASHBOARD_PATH) ? dashboardRoute : dashboardInsightsRoute;
    }
    // if account admin, dont have permission to view dashboard, so redirect elsewhere
    if (hasAdminPermission(AdminPermission.ACCOUNT_ADMIN)) {
      return path.startsWith(DASHBOARD_PATH) ? dashboardRoute : accountsRoute;
    }

    // else return the only path available
    return path.startsWith(DASHBOARD_PATH) ? dashboardRoute : transactionRoute;
  }

  // if initialised and still not authenticated then need to login
  return !initialised && !user ? spinningWheelRoute : loginRoute;
};

export default withRouter(PrivateRoute);
