import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import NavigationLink from '../../commons/link/NavigationLink';
import MaterialTableWrapper from '../../commons/table/MaterialTableWrapper';
import { usePermissions } from '../../hooks/usePermissions';
import paths from '../../routes/paths';
import { Permission } from '../../types/user/UserPermission';
import useOrganisations from './hook/useOrganisations';

const OrganisationsTable: FC = () => {
  const history = useHistory();
  const { state } = useOrganisations();

  const handleAddOrganisation = () => {
    history.push(paths.NEW_ORGANISATION);
  };
  const { hasPermission } = usePermissions();

  return (
    <MaterialTableWrapper
      columns={[
        {
          title: 'Name',
          field: 'name',
          width: 'auto',
          render: (rowData: { id: string; name: string }) => (
            <NavigationLink path={`${paths.ORGANISATIONS}/${rowData.id}/details`} text={rowData.name} />
          )
        },
        {
          title: 'Admins',
          field: 'admins',
          width: 'auto',
          render: (rowData: { id: string; admins: string[] }) => {
            const numberOfAdmins = rowData.admins ? `${rowData.admins.length}` : '0';
            return <NavigationLink path={`${paths.ORGANISATIONS}/${rowData.id}/admins`} text={numberOfAdmins} />;
          }
        }
      ]}
      data={state.organisations?.map((organisation) => ({
        name: organisation.name,
        admins: organisation.adminIds,
        id: organisation.id
      }))}
      globalActionButton={
        hasPermission(Permission.MANAGE_ORGANISATION) && {
          name: 'New',
          tooltip: 'Create a new organisation',
          onClick: () => handleAddOrganisation()
        }
      }
      exportFileName="Organisations"
    />
  );
};

export default OrganisationsTable;
