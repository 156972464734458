import { Button } from '@material-ui/core';

const ConfirmButton = ({ formikProps }) => {
  return (
    <Button type="submit" variant="contained" color="secondary" disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>
      Confirm
    </Button>
  );
};

export default ConfirmButton;
