import { EmspTariffDto } from '../../types/tariff/EmspTariffDto';
import { EmspTariff } from '../../types/tariff/EmspTariff';

export const toEmspTariff = (dto: EmspTariffDto): EmspTariff => ({
  id: dto.id,
  name: dto.name,
  ratePerMinute: dto.rate_per_minute,
  ratePerKwh: dto.rate_per_kwh,
  ratePerParkingMinute: dto.rate_per_parking_minute,
  unlockFee: dto.unlock_fee,
  appliedMargin: dto.applied_margin
});
