import { useEffect } from 'react';
import SubHeading from '../../../commons/texts/SubHeading';
import { ChargePointInsightsChartEvent, ChargePointInsightsChartFlowState } from '../../../types/insights/InsightsState';
import ChargePointCarbonOffset from './ChargePointCarbonOffset';
import ChargePointElectricityCost from './ChargePointElectricityCost';
import ChargePointEnergyConsumption from './ChargePointEnergyConsumption';
import ChargePointTransactionCount from './ChargePointTransactionCount';
import ChargePointInsightsFilter from './ChargerInsightsFilter';
import useChargePointInsightsChart from './hook/useChargePointInsightsChart';
import { withChargePointInsightsChart } from './hook/withChargePointInsightsChart';

const ChargePointInsightsChart = () => {
  const { state, addEvent } = useChargePointInsightsChart();

  useEffect(() => {
    if (state.flowState === ChargePointInsightsChartFlowState.INIT) {
      addEvent({ type: ChargePointInsightsChartEvent.PAGE_ENTERED });
    }
  }, [state.flowState, addEvent]);

  const onFilterUpdated = (updatedSelectedFilter: any) => {
    addEvent({
      type: ChargePointInsightsChartEvent.FILTER_UPDATED,
      payload: {
        filteredBy: updatedSelectedFilter
      }
    });
  };

  return (
    <>
      <SubHeading text="Insights" />
      <ChargePointInsightsFilter
        loadingFilterState={state.loadingFilterState}
        filter={state.filter}
        filteredBy={state.filteredBy}
        onFilterUpdated={onFilterUpdated}
      />
      <ChargePointTransactionCount
        loadingTransactionsState={state.loadingTransactionsState}
        transactions={state.transactions}
        lastNumberOfDays={state.filteredBy.lastNumberOfDays}
      />
      <ChargePointEnergyConsumption
        loadingTransactionsState={state.loadingTransactionsState}
        transactions={state.transactions}
        lastNumberOfDays={state.filteredBy.lastNumberOfDays}
      />
      <ChargePointElectricityCost
        loadingTransactionsState={state.loadingTransactionsState}
        transactions={state.transactions}
        lastNumberOfDays={state.filteredBy.lastNumberOfDays}
      />
      <ChargePointCarbonOffset
        loadingTransactionsState={state.loadingTransactionsState}
        transactions={state.transactions}
        lastNumberOfDays={state.filteredBy.lastNumberOfDays}
      />
    </>
  );
};

export default withChargePointInsightsChart(ChargePointInsightsChart);
