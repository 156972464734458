import Button from '@material-ui/core/Button';
import {Tooltip} from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  actionButtonTooltipPopper: {
    marginTop: '6px',
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
}));

const CreateActionButton = ({name, tooltip, onClick}) => {
  const classes = useStyles(mainTheme);
  return (
    <Tooltip classes={{popper: classes.actionButtonTooltipPopper}} title={tooltip ?? name}>
      <Button className={classes.actionButton} variant='contained' color='primary' onClick={onClick}>
        {name}
      </Button>
    </Tooltip>
  );
};

CreateActionButton.propTypes = {
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default CreateActionButton;
