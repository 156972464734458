import Backdrop from '@material-ui/core/Backdrop';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Spinner from 'react-spinkit';
import mainTheme from '../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 2,
    backgroundColor: theme.palette.backdrop,
  },
}));

const LoadingModal = ({open = true}) => {
  const classes = useStyles(mainTheme);
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Spinner name="three-bounce" color="white" />
    </Backdrop>
  );
};

export default LoadingModal;
