import { Box, CircularProgress, List, ListItem, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle, Error as ErrorIcon, FastForward, WatchLater } from '@material-ui/icons';
import clsx from 'clsx';
import { FC } from 'react';
import mainTheme from '../../../themes/mainTheme';
import ScrollIntoViewIfNeeded from '../../scrolls/ScrollIntoViewIfNeeded';

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  listContainer: {
    maxHeight: 200,
    overflow: 'auto'
  },
  listItem: {
    width: '100%',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'dashed',
    '&:last-child': {
      borderBottomStyle: 'none'
    }
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1)
  },
  button: {
    flex: 1
  },
  icon: {
    fontSize: 24,
    marginRight: theme.spacing(1)
  },
  processingIcon: {
    marginRight: theme.spacing(1.25),
    marginLeft: theme.spacing(0.25)
  },
  statusQueued: {
    color: theme.palette.progress.queued
  },
  statusAdding: {
    color: theme.palette.progress.processing
  },
  statusPaused: {
    color: theme.palette.progress.paused
  },
  statusAdded: {
    color: theme.palette.notification.success
  },
  statusCancelled: {
    color: theme.palette.progress.cancelled
  },
  statusWarning: {
    color: theme.palette.notification.warning
  },
  statusError: {
    color: theme.palette.notification.error
  }
}));

export type AddUserProgress = { email: string; status: AddUserStatus; message?: string };

export enum AddUserStatus {
  QUEUED = 'QUEUED',
  ADDING = 'ADDING',
  ADDED = 'ADDED',
  PAUSED = 'PAUSED',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED'
}

type AddingMultipleUsersProgressProps = {
  newUsers: AddUserProgress[];
  handleCancel: () => void;
  handleResume: () => void;
  handleClose?: () => void;
  isAdding: boolean;
  isPaused: boolean;
};

const AddingMultipleUsersProgress: FC<AddingMultipleUsersProgressProps> = ({
  newUsers,
  handleClose,
  handleCancel,
  handleResume,
  isAdding,
  isPaused
}) => {
  const classes = useStyles(mainTheme);

  const statusIcon = (status: AddUserStatus) => {
    switch (status) {
      case AddUserStatus.QUEUED:
        return <FastForward className={clsx(classes.icon, classes.statusQueued)} />;
      case AddUserStatus.ADDING:
        return <CircularProgress className={clsx(classes.processingIcon)} size={20} color="inherit" />;
      case AddUserStatus.PAUSED:
        return <WatchLater className={clsx(classes.icon, classes.statusPaused)} />;
      case AddUserStatus.ADDED:
        return <CheckCircle className={clsx(classes.icon, classes.statusAdded)} />;
      case AddUserStatus.CANCELLED:
        return <ErrorIcon className={clsx(classes.icon, classes.statusCancelled)} />;
      case AddUserStatus.WARNING:
        return <ErrorIcon className={clsx(classes.icon, classes.statusWarning)} />;
      case AddUserStatus.ERROR:
        return <ErrorIcon className={clsx(classes.icon, classes.statusError)} />;
      default:
        throw new Error(`Unknown status ${status}`);
    }
  };

  const processed = newUsers.filter((newUser) => newUser.status !== AddUserStatus.QUEUED && newUser.status !== AddUserStatus.ADDING);

  return (
    <Box className={classes.container}>
      <Paper className={classes.listContainer} variant="outlined">
        <List>
          {newUsers.map((newUser, index) => (
            <ListItem className={classes.listItem} button={false} key={index}>
              <ScrollIntoViewIfNeeded
                active={newUser.status === AddUserStatus.ADDING}
                options={{
                  block: 'end',
                  behavior: 'smooth',
                  scrollMode: 'if-needed'
                }}
              >
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center">
                      {statusIcon(newUser.status)}
                      <Typography>{newUser.email}</Typography>
                    </Box>
                  }
                  secondary={
                    newUser.message && (
                      <Typography variant="caption" color="textSecondary">
                        {newUser.message}
                      </Typography>
                    )
                  }
                />
              </ScrollIntoViewIfNeeded>
            </ListItem>
          ))}
        </List>
      </Paper>
      <LinearProgress variant="determinate" value={(processed.length / (newUsers.length ?? 1)) * 100} />
      {isAdding && (
        <Button type="button" variant="contained" color="primary" fullWidth onClick={handleCancel}>
          Cancel
        </Button>
      )}
      {isPaused && (
        <Box className={classes.buttonRow}>
          <Button className={classes.button} type="button" variant="contained" color="primary" onClick={handleResume}>
            Resume
          </Button>
          <Button className={classes.button} type="button" variant="outlined" color="primary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AddingMultipleUsersProgress;
