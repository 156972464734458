import { useEffect } from 'react';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import Dashboard from '../Dashboard/Dashboard';

import { useParams } from 'react-router-dom';
import UserDetailsTabs from './UserDetailsTabs';
import useUserPersonalDetails, { UserPersonalDetailsEvent, UserPersonalDetailsFlowState } from './UserPersonalDetails/hook/useUserPersonalDetails';
import { withUserPersonalDetails } from './UserPersonalDetails/hook/withUserPersonalDetails';

interface UserDetailsMatchParams {
  userId: string;
}

const UserDetails: React.FC = () => {
  const params = useParams<UserDetailsMatchParams>();
  const userId = params.userId;
  const { state, addEvent } = useUserPersonalDetails();

  useEffect(() => {
    if (state.flowState === UserPersonalDetailsFlowState.INIT) {
      addEvent({ type: UserPersonalDetailsEvent.PAGE_ENTERED, userId: userId });
    }
  }, [state.flowState, userId, addEvent]);

  return (
    <Dashboard pageHeading={state.user?.name}>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        <UserDetailsTabs userId={userId} state={state} />
      </LoadingContainerWithErrorPanel>
    </Dashboard>
  );
};

export default withUserPersonalDetails(UserDetails);
