import React, {createContext, useContext, useReducer} from 'react';
import {useHistory} from 'react-router-dom';
import userService from '../services/userService';
import paths from '../routes/paths';
import tokenStorage from '../utils/storage/tokenStorage';
import _ from 'lodash';

// action types
const actionTypes = {
  setAuthenticated: 'setAuthenticated',
  setUnauthenticated: 'setUnauthenticated',
};

// initial state
const initialState = {
  initialised: false,
  user: null,
};

// reducer
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.setAuthenticated:
      return {
        ...state,
        initialised: true,
        user: action.user,
      };
    case actionTypes.setUnauthenticated:
      return {
        ...state,
        initialised: true,
        user: null,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

// context
const authenticationContext = createContext();

// provider
export const AuthenticationProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <authenticationContext.Provider value={{state, dispatch}}>{children}</authenticationContext.Provider>
  );
};

// hook
const useAuthentication = () => {
  const history = useHistory();
  const {state, dispatch} = useContext(authenticationContext);

  const unauthenticate = () => {
    tokenStorage.clear();
    history.push(paths.LOGIN);
    dispatch({type: actionTypes.setUnauthenticated});
  };

  const authenticate = () =>
    userService.getCurrentUser().subscribe(
      (result) =>
        dispatch({
          type: actionTypes.setAuthenticated,
          user: result,
        }),
      (error) => unauthenticate()
    );

  return {
    initialised: state.initialised,
    user: state.user,
    authenticate,
    unauthenticate,
  };
};

export default useAuthentication;
