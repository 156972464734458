import React, {createContext, useContext, useReducer} from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState,
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import _ from 'lodash';
import chargeTagService from '../../../../services/chargeTagService';

// events
export const UserChargeTagsEvent = {
  PAGE_ENTERED: 'PAGE_ENTERED',
  ADD_CHARGE_TAG_BUTTON_CLICKED: 'ADD_CHARGE_TAG_BUTTON_CLICKED',
  ADD_CHARGE_TAG_FORM_CLOSED: 'ADD_CHARGE_TAG_FORM_CLOSED',
  ADD_CHARGE_TAG_FORM_SUBMITTED: 'ADD_CHARGE_TAG_FORM_SUBMITTED',
  DELETE_CHARGE_TAG_BUTTON_CLICKED: 'DELETE_CHARGE_TAG_BUTTON_CLICKED',
  DELETE_CHARGE_TAG_DIALOG_CLOSED: 'DELETE_CHARGE_TAG_DIALOG_CLOSED',
  DELETE_CHARGE_TAG_CONFIRMED: 'DELETE_CHARGE_TAG_CONFIRMED',
};

// flow states
export const UserChargeTagsFlowState = {
  INIT: 'INIT',
  SHOWING_CHARGE_TAGS: 'SHOWING_CHARGE_TAGS',
  SHOWING_ADD_CHARGE_TAG_FORM: 'SHOWING_ADD_CHARGE_TAG_FORM',
  ADDING_CHARGE_TAG: 'ADDING_CHARGE_TAG',
  DELETING_CHARGE_TAG: 'DELETING_CHARGE_TAG',
  FAILED_TO_ADD_CHARGE_TAG: 'FAILED_TO_ADD_CHARGE_TAG',
  SUCCESSFULLY_ADDED_CHARGE_TAG: 'SUCCESSFULLY_ADDED_CHARGE_TAG',
  SUCCESSFULLY_DELETED_CHARGE_TAG: 'SUCCESSFULLY_DELETED_CHARGE_TAG',
  ERROR: 'ERROR',
  SHOWING_DELETE_CHARGE_TAG_DIALOG: 'SHOWING_DELETE_CHARGE_TAG_DIALOG',
  FAILED_TO_DELETE_CHARGE_TAG: 'FAILED_TO_DELETE_CHARGE_TAG',
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  flowState: UserChargeTagsFlowState.INIT,
  chargeTags: null,
  userId: null,
  chargeTagIdToDelete: null,
  chargeTagErrorMessage: null,
  chargeTagSuccessMessage: null,
};

// reducer
const reducer = (state, newState) => ({...state, ...newState});

// context
const userChargeTagsContext = createContext(null);

// provider
export const UserChargeTagsProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <userChargeTagsContext.Provider value={{state, dispatch}}>{children}</userChargeTagsContext.Provider>
  );
};

// hook
const useUserChargeTags = () => {
  const {state, dispatch} = useContext(userChargeTagsContext);

  const addEvent = (event) => {
    switch (event.type) {
      case UserChargeTagsEvent.PAGE_ENTERED:
        chargeTagService.fetchChargeTagsByUserUuid(event.userId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: UserChargeTagsFlowState.SHOWING_CHARGE_TAGS,
              chargeTags: result,
              userId: event.userId,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: UserChargeTagsFlowState.ERROR,
              chargeTags: null,
              userId: null,
            })
        );
        break;
      case UserChargeTagsEvent.ADD_CHARGE_TAG_BUTTON_CLICKED:
        dispatch({
          flowState: UserChargeTagsFlowState.SHOWING_ADD_CHARGE_TAG_FORM,
          chargeTagErrorMessage: null,
          chargeTagSuccessMessage: null,
        });
        break;
      case UserChargeTagsEvent.ADD_CHARGE_TAG_FORM_CLOSED:
      case UserChargeTagsEvent.DELETE_CHARGE_TAG_DIALOG_CLOSED:
        dispatch({
          flowState: UserChargeTagsFlowState.SHOWING_CHARGE_TAGS,
          chargeTagIdToDelete: null,
          chargeTagErrorMessage: null,
          chargeTagSuccessMessage: null,
        });
        break;
      case UserChargeTagsEvent.ADD_CHARGE_TAG_FORM_SUBMITTED:
        dispatch({
          flowState: UserChargeTagsFlowState.ADDING_CHARGE_TAG,
          chargeTagErrorMessage: null,
          chargeTagSuccessMessage: null,
        });
        chargeTagService.addChargeTag(state.userId, event.chargeTagId).subscribe(
          (response) =>
            dispatch({
              flowState: UserChargeTagsFlowState.SUCCESSFULLY_ADDED_CHARGE_TAG,
              chargeTagSuccessMessage: 'Charge tag successfully added',
            }),
          (error) =>
            dispatch({
              flowState: UserChargeTagsFlowState.FAILED_TO_ADD_CHARGE_TAG,
              chargeTagErrorMessage: error.message,
            })
        );
        break;
      case UserChargeTagsEvent.DELETE_CHARGE_TAG_BUTTON_CLICKED:
        dispatch({
          flowState: UserChargeTagsFlowState.SHOWING_DELETE_CHARGE_TAG_DIALOG,
          chargeTagIdToDelete: event.chargeTagIdToDelete,
          chargeTagErrorMessage: null,
          chargeTagSuccessMessage: null,
        });
        break;
      case UserChargeTagsEvent.DELETE_CHARGE_TAG_CONFIRMED:
        dispatch({
          flowState: UserChargeTagsFlowState.DELETING_CHARGE_TAG,
          chargeTagErrorMessage: null,
          chargeTagSuccessMessage: null,
        });
        chargeTagService.deleteChargeTag(event.userId, event.chargeTagIdToDelete).subscribe(
          (response) =>
            dispatch({
              flowState: UserChargeTagsFlowState.SUCCESSFULLY_DELETED_CHARGE_TAG,
              chargeTagSuccessMessage: 'Charge tag successfully deleted',
            }),
          (error) =>
            dispatch({
              flowState: UserChargeTagsFlowState.FAILED_TO_DELETE_CHARGE_TAG,
              chargeTagErrorMessage: error.message,
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
  };
};

export default useUserChargeTags;
