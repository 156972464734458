import { makeStyles } from '@material-ui/core/styles';
import { ConnectorStandardDisplay, CpoConnector, CpoEvse, EmspCharger } from '../../../types/charger/Charger';
import React, { createRef, FC, useEffect } from 'react';
import { withTariffProfiles } from './hook/withTariffProfiles';
import useTariffProfiles, { TariffProfilesFlowState } from './hook/useTariffProfiles';
import { TariffProfilesEvent } from '../../../types/tariff/TariffProfilesEvents';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { Box, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SmartSelectField from '../../../commons/form/SmartSelectField';
import { roundNumberToCurrency } from '../../../utils/transaction/transactionUtils';
import { CpoConnectors } from '../ChargerDetails/ChargerInfo/ChargerInfo';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import CancelButton from '../../../commons/form/button/CancelButton';
import UpdateButton from '../../../commons/form/button/UpdateButton';

const useStyles = makeStyles((theme) => ({
  connectorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2)
  },
  connectorTitleHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  connectorsBox: {
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    minWidth: 300
  },
  connectorTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

type UpdateCpoChargerTariffsFormProps = {
  emspChargerFromParent: EmspCharger;
  evses: CpoEvse[];
  handleCancel: () => void;
  handleSubmit: (values: CpoConnectors) => void;
  hasFailed: boolean;
};

const UpdateCpoChargerTariffsForm: FC<UpdateCpoChargerTariffsFormProps> = ({
  emspChargerFromParent,
  evses,
  handleCancel,
  handleSubmit,
  hasFailed
}) => {
  const { state, addEvent } = useTariffProfiles();
  const { cpoTariffs } = state;

  const formikRef = createRef<FormikProps<CpoConnectors>>();
  useEffect(() => {
    if (hasFailed && formikRef.current) {
      formikRef.current.setSubmitting(false);
    }
  }, [hasFailed, formikRef]);

  const initialValues: CpoConnectors = {
    connectors: evses.flatMap((evse) => evse.connectors.sort((a, b) => a.ocppConnectorId - b.ocppConnectorId))
  };

  const validationSchema = Yup.object({
    connectors: Yup.array().of(
      Yup.object({
        tariffId: Yup.string().nullable()
      })
    )
  });

  useEffect(() => {
    if (state.flowState === TariffProfilesFlowState.INIT) {
      addEvent({
        type: TariffProfilesEvent.LOAD_TARIFF_PROFILES,
        payload: {
          cpoCountryCode: emspChargerFromParent.countryCode,
          cpoPartyId: emspChargerFromParent.partyId
        }
      });
    }
  }, [state.flowState, addEvent, emspChargerFromParent.countryCode, emspChargerFromParent.partyId]);

  const classes = useStyles();
  const connectors = emspChargerFromParent.evses?.flatMap((evse) => evse.connectors) ?? [];
  connectors.sort((a, b) => (a.cpoConnectorId as unknown as number) - (b.cpoConnectorId as unknown as number));

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(props) => {
        const connectors = props.values.connectors;
        return (
          <Form>
            <PaperWithTitle
              title="Edit Tariffs"
              bottomRightComponent={
                <>
                  <CancelButton handleCancel={handleCancel} />
                  <UpdateButton formikProps={props} />
                </>
              }
            >
              <LoadingContainerWithErrorPanel {...state.loadingState}>
                <Box className={classes.connectorsContainer}>
                  {connectors.map((connector: CpoConnector, index: number) => {
                    const selectedConnector = props.values.connectors[index];
                    const selectedTariff = selectedConnector.tariffId;
                    const cpoTariff = cpoTariffs.find((tariff) => tariff.cpoTariffId === selectedTariff);

                    return (
                      <Box key={index} className={classes.connectorsBox}>
                        <Box className={classes.connectorTitle}>
                          <Typography className={classes.connectorTitleHeading} variant="h4">
                            Connector
                          </Typography>
                          <Typography className={classes.connectorTitleHeading} variant="h5">
                            {ConnectorStandardDisplay.get(connector.standard)}
                          </Typography>
                        </Box>
                        <Field
                          component={SmartSelectField}
                          name={`connectors[${index}].tariffId`}
                          label="CPO Connector Tariff"
                          helperText="Profile applied"
                          options={[
                            { value: null, displayValue: 'None' },
                            ...cpoTariffs.map((tariff) => ({ value: tariff.cpoTariffId, displayValue: tariff.displayText ?? tariff.cpoTariffId }))
                          ]}
                        />
                        <TextField
                          label="Electricity price"
                          value={`$${roundNumberToCurrency(cpoTariff?.ratePerKwh ?? 0).toFixed(2)} / kWh`}
                          disabled={true}
                        />
                        <TextField
                          label="Duration"
                          value={`$${roundNumberToCurrency(cpoTariff?.ratePerMinute ?? 0).toFixed(2)} / min`}
                          disabled={true}
                        />
                        <TextField
                          label="Idle / Parking time"
                          value={`$${roundNumberToCurrency(cpoTariff?.ratePerParkingMinute ?? 0).toFixed(2)} / min`}
                          disabled={true}
                        />
                        <TextField
                          label="Unlock price"
                          value={`$${roundNumberToCurrency(cpoTariff?.unlockFee ?? 0).toFixed(2)} / transaction`}
                          disabled={true}
                        />
                      </Box>
                    );
                  })}
                </Box>
                <Typography variant="caption">
                  If a tariff that you wish to add does not exist or you wish to add a new tariff please inform the OpenLoop team on
                  support@openloop.co.nz
                </Typography>
              </LoadingContainerWithErrorPanel>
            </PaperWithTitle>
          </Form>
        );
      }}
    </Formik>
  );
};

export default withTariffProfiles(UpdateCpoChargerTariffsForm);
