import _ from 'lodash';
import moment from 'moment';
import React, { createContext, PropsWithChildren, useReducer } from 'react';
import { initialState as loadingContainerInitialState } from '../../../../hooks/useLoadingContainerWithErrorPanel';
import { ChargerLogsFlowState, ChargerLogsState, LogType } from '../../../../types/charger/ChargerLogs';
const reducer = (state: ChargerLogsState, newState: ChargerLogsState) => ({ ...state, ...newState });

// initial state
const initialState: ChargerLogsState = {
  logs: [],
  flowState: ChargerLogsFlowState.INIT,
  loadingState: loadingContainerInitialState,
  isPartialLoad: false,
  dateRange: {
    dateTimeFrom: moment().subtract(30, 'minutes'),
    dateTimeTo: moment()
  },
  logType: LogType.PRETTY
};

type ChargerLogsContextType = {
  state: ChargerLogsState;
  dispatch: React.Dispatch<ChargerLogsState>;
};

// context
export const ChargerLogsContext = createContext<ChargerLogsContextType>({
  state: initialState,
  dispatch: () => undefined
});

// provider
export const ChargerLogsProvider: ({ children }: React.PropsWithChildren<any>) => React.JSX.Element = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <ChargerLogsContext.Provider value={{ state, dispatch }}>{children}</ChargerLogsContext.Provider>
  );
};
