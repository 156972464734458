import { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { requiresPermission } from '../../hocs/requiresPermission';
import { FeatureFlag, requiresFeatureFlag } from '../../hooks/useFeatureFlags';
import paths from '../../routes/paths';
import { Permission } from '../../types/user/UserPermission';
import Dashboard from '../Dashboard/Dashboard';
import useUserGroups, { UserGroupEvent, UserGroupFlowState } from '../UserGroups/hook/useUserGroups';
import { withUserGroups } from '../UserGroups/hook/withUserGroups';
import UserGroupDetailsTabs from './UserGroupDetailsTabs';

const UserGroupDetails = () => {
  const match = useRouteMatch(paths.USER_FLEET_DETAILS);
  const userGroupId = match.params.userFleetId;
  const { state, addEvent } = useUserGroups();
  useEffect(() => {
    if (state.flowState === UserGroupFlowState.INIT) {
      addEvent({ type: UserGroupEvent.LOAD_USER_GROUP, payload: { userGroupId: userGroupId } });
    }
  }, [state.flowState, addEvent, userGroupId]);
  return (
    <Dashboard pageHeading={state.userGroup?.name}>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        {state.userGroup && <UserGroupDetailsTabs userGroup={state.userGroup} />}
      </LoadingContainerWithErrorPanel>
    </Dashboard>
  );
};

export default requiresFeatureFlag(FeatureFlag.LEGACY_CHARGE_POINT_MANAGEMENT, { redirectTo: paths.ACCOUNTS })(
  requiresPermission(Permission.MANAGE_USER_GROUP)(withUserGroups(UserGroupDetails))
);
