import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import {Box, Divider, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  menuHeading: {
    fontSize: 12,
    lineHeight: 1,
    fontWeight: 500,
    color: theme.palette.drawerItem.menuHeading,
    marginLeft: theme.spacing(1),
  },
  divider: {
    backgroundColor: theme.palette.drawerItem.divider,
    marginBottom: theme.spacing(1.5),
  },
}));

const LeftDrawerMenuHeading = ({label}) => {
  const classes = useStyles(mainTheme);

  return (
    <Box className={classes.container}>
      <Divider className={classes.divider} />
      <Typography variant="overline" noWrap component="span" className={classes.menuHeading}>
        {label}
      </Typography>
    </Box>
  );
};

LeftDrawerMenuHeading.propTypes = {
  label: PropTypes.string.isRequired,
};

export default LeftDrawerMenuHeading;
