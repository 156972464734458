import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, makeStyles } from '@material-ui/core';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    marginTop: theme.spacing(2),
    fontSize: '0.8rem'
  },
  radioContainer: {
    marginTop: theme.spacing(1)
  }
}));
const SmartRadioField = ({ field, label, helperText, fullWidth, options, form, displayEmpty, ...rest }) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth={fullWidth} disabled={form.isSubmitting}>
      <FormLabel className={classes.inputLabel}>{label}</FormLabel>
      <RadioGroup row {...field} name={field.name} className={classes.radioContainer}>
        {options.map((optionEntry, index) => (
          <FormControlLabel key={`${index}-${optionEntry.value}`} value={optionEntry.value} control={<Radio color="primary" />} label={optionEntry.displayValue} />
        ))}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

SmartRadioField.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  options: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired
};

export default SmartRadioField;
