import _ from 'lodash';
import { FC, useEffect } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import FormDialog from '../../../commons/dialogs/FormDialog';
import { WarningDialog } from '../../../commons/dialogs/dialogs';
import { ErrorBar, ProcessingBar, SuccessBar, WarningBar } from '../../../commons/snackbars/snackbars';
import { EmspAccount, EmspAccountAdmin } from '../../../types/account/Account';
import { AccountAdminsEvent } from '../../../types/account/AccountAdminEvents';
import AccountAdminsListTable from './AccountAdminsListTable';
import AddAccountAdminForm from './AddAccountAdminForm';
import useAccountAdmins, { AccountAdminsFlowState } from './hook/useAccountAdmins';
import { withAccountAdmins } from './hook/withAccountAdmins';

interface AccountAdminListPanelProps {
  account: EmspAccount;
}

const AccountAdminListPanel: FC<AccountAdminListPanelProps> = ({ account }) => {
  const accountId = account.id;
  const accountName = account.name;

  const { state, addEvent } = useAccountAdmins();

  useEffect(() => {
    if (state.flowState === AccountAdminsFlowState.INIT) {
      addEvent({ type: AccountAdminsEvent.LOAD_ACCOUNT_ADMINS, payload: { accountId } });
    }
  }, [state.flowState, addEvent, accountId]);

  const showAddAdminForm = _.includes(
    [
      AccountAdminsFlowState.SHOWING_ADD_ACCOUNT_ADMIN_FORM,
      AccountAdminsFlowState.FAILED_TO_ADD_ACCOUNT_ADMIN,
      AccountAdminsFlowState.ADDING_ACCOUNT_ADMIN
    ],
    state.flowState
  );

  const handleAddAdminButtonClick = () => {
    addEvent({ type: AccountAdminsEvent.SHOW_ADD_ACCOUNT_ADMIN_FORM });
  };

  const handleRemoveAdminButtonClick = (admin: EmspAccountAdmin) => {
    addEvent({ type: AccountAdminsEvent.SHOW_REMOVE_ACCOUNT_ADMIN_WARNING, payload: { admin } });
  };

  const handleAddAdminByEmail = (email: string) => {
    addEvent({ type: AccountAdminsEvent.ADD_ACCOUNT_ADMIN_BY_EMAIL, payload: { email, accountId } });
  };

  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        <AccountAdminsListTable
          adminUsers={state.accountAdmins}
          accountName={accountName}
          handleAddAdminButtonClick={handleAddAdminButtonClick}
          handleRemoveAdminButtonClick={handleRemoveAdminButtonClick}
        />
        {/* Add Admin Form */}
        <FormDialog
          fullPage={false}
          open={showAddAdminForm}
          onClose={() => {
            if (state.flowState !== AccountAdminsFlowState.ADDING_ACCOUNT_ADMIN) addEvent({ type: AccountAdminsEvent.CLOSE_ADD_ACCOUNT_ADMIN_FORM });
          }}
          title={`Add admin to ${account.name}`}
        >
          <AddAccountAdminForm
            handleAddAdminByEmail={handleAddAdminByEmail}
            hasFailed={state.flowState === AccountAdminsFlowState.FAILED_TO_ADD_ACCOUNT_ADMIN}
          />
        </FormDialog>
      </LoadingContainerWithErrorPanel>
      {/* Remove Admin Warning */}
      <WarningDialog
        fullPage={false}
        open={_.includes([AccountAdminsFlowState.SHOWING_REMOVE_ACCOUNT_ADMIN_WARNING], state.flowState)}
        buttonLabel="Remove admin"
        buttonCallback={() => {
          if (state.adminToRemove)
            addEvent({
              type: AccountAdminsEvent.REMOVE_ACCOUNT_ADMIN_BY_ID,
              payload: { userId: state.adminToRemove?.id, accountId }
            });
        }}
        onClose={() => addEvent({ type: AccountAdminsEvent.CLOSE_REMOVE_ACCOUNT_ADMIN_WARNING })}
      >
        Are you sure you want to remove {state.adminToRemove?.name} from {account.name} admins?
      </WarningDialog>
      {/* Notification Bars */}
      <SuccessBar open={state.adminAdded || state.adminRemoved} fullPage={false} onClose={() => addEvent({ type: AccountAdminsEvent.CLEAR_SUCCESS })}>
        Admin has been {state.adminAdded ? 'added' : 'removed'}
      </SuccessBar>
      <ProcessingBar
        open={state.flowState === AccountAdminsFlowState.ADDING_ACCOUNT_ADMIN || state.flowState === AccountAdminsFlowState.REMOVING_ACCOUNT_ADMIN}
        fullPage={false}
      />
      <WarningBar open={!_.isEmpty(state.warningMessage)} fullPage={false} onClose={() => addEvent({ type: AccountAdminsEvent.CLEAR_WARNING })}>
        {state.warningMessage}
      </WarningBar>
      <ErrorBar open={!_.isEmpty(state.errorMessage)} fullPage={false} onClose={() => addEvent({ type: AccountAdminsEvent.CLEAR_ERROR })}>
        {state.errorMessage}
      </ErrorBar>
    </>
  );
};

export default withAccountAdmins(AccountAdminListPanel);
