import { Moment } from 'moment';

export enum ChargerLogsFlowState {
  INIT = 'INIT',
  ERROR = 'ERROR',
  CHARGER_LOGS_LOADED = 'CHARGER_LOGS_LOADED',
  LOADING_LOGS_TABLE = 'LOADING_LOGS_TABLE'
}

// state
export type ChargerLogsState = {
  isPartialLoad: boolean;
  logs: ChargerLog[];
  flowState: ChargerLogsFlowState;
  loadingState: any;
  dateRange: DateRange;
  logType: LogType;
};
export type UseChargerLogsType = {
  state: ChargerLogsState;
  addEvent: (event: ChargerLogsEventType) => void;
};
export type ChargerLogsEventType = {
  type: ChargerLogsEvent;
  payload?: any;
};

// events
export enum ChargerLogsEvent {
  LOAD_CHARGER_LOGS = 'LOAD_CHARGER_LOGS',
  FILTER_CRITERIA_CHANGED = 'FILTER_CRITERIA_CHANGED',
  PANEL_ENTERED = 'PANEL_ENTERED',
  APPLY_FILTER_CRITERIA = 'APPLY_FILTER_CRITERIA'
}

export type ChargerLog = {
  message: string;
  messageId: string | null;
  event: string;
  serverTimestamp: string;
  chargePointTimestamp: string | null;
  raw: boolean;
};

export type ChargerLogs = {
  data: ChargerLog[];
  nextToken: string;
};

export type DateRange = {
  dateTimeFrom: Date | Moment;
  dateTimeTo: Date | Moment;
};

export enum LogType {
  RAW = 'RAW',
  PRETTY = 'PRETTY',
  ALL = 'ALL'
}

type LogOption = {
  displayValue: string;
  value: LogType;
};

export const chargerLogOptions: LogOption[] = [
  { displayValue: 'All Logs', value: LogType.ALL },
  { displayValue: 'Raw Charge Point Logs', value: LogType.RAW },
  { displayValue: 'Formatted Logs', value: LogType.PRETTY }
];
