import React from 'react';
import useChargePointGroupUserList, {ChargePointGroupUserListEvent, ChargePointGroupUserListFlowState} from './hook/useChargePointGroupUserList';
import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';
import dateTimeFormatter from '../../../utils/date/dateTimeFormatter';
import _ from 'lodash';
import FormDialog from '../../../commons/dialogs/FormDialog';
import {WarningDialog} from '../../../commons/dialogs/dialogs';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddChargePointGroupUsersForm from './AddChargePointGroupUsersForm/AddChargePointGroupUsersForm';

const ChargePointGroupUserListTable = () => {
  const {state, addEvent} = useChargePointGroupUserList();
  const chargePointGroupName = state.chargePointGroup.name;

  return (
    <>
      <MaterialTableWrapper
        columns={[
          {title: 'Id', field: 'id', width: 'auto', hidden: true, hiddenByColumnsButton: true},
          {title: 'Name', field: 'name', width: 'auto', defaultSort: 'asc'},
          {title: 'Email', field: 'email', width: 'auto'},
          {
            title: 'Created',
            field: 'created',
            width: 'auto',
            render: (rowData) => dateTimeFormatter.printReadableDateTime(rowData.created),
            customFilterAndSearch: (filter, rowData) => _.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.created), filter),
            export: false,
          },
          {
            title: 'Created',
            field: 'readableCreated',
            hidden: true,
            export: true,
          },
        ]}
        data={state.chargePointGroupUserList.map((chargePointGroupUser) => ({
          id: chargePointGroupUser.id,
          name: chargePointGroupUser.name,
          email: chargePointGroupUser.email,
          created: chargePointGroupUser.created,
          readableCreated: dateTimeFormatter.printReadableDateTime(chargePointGroupUser.created),
        }))}
        exportFileName={`Users in ${chargePointGroupName} charge point fleet`}
        globalActionButton={{
          name: 'Add Users',
          tooltip: 'Add users to this charge point fleet',
          onClick: () => addEvent({type: ChargePointGroupUserListEvent.SHOW_ADD_CHARGE_POINT_GROUP_USERS_FORM}),
        }}
        singleActionButton={{
          icon: RemoveCircleIcon,
          tooltip: 'Remove user',
          onClick: (rowData) => {
            addEvent({
              type: ChargePointGroupUserListEvent.SHOW_REMOVE_CHARGE_POINT_GROUP_USER_DIALOG,
              payload: {
                chargePointGroupUser: rowData,
              },
            });
          },
        }}
      />
      <FormDialog
        open={_.includes(
          [
            ChargePointGroupUserListFlowState.SHOWING_ADD_CHARGE_POINT_GROUP_USERS_FORM,
            ChargePointGroupUserListFlowState.ADDING_USERS_TO_CHARGE_POINT_GROUP,
            ChargePointGroupUserListFlowState.SHOWING_CONFIRM_CANCELLING_ADD_USERS_TO_CHARGE_POINT_GROUP,
            ChargePointGroupUserListFlowState.COMPLETED_ADDING_USERS_TO_CHARGE_POINT_GROUP,
          ],
          state.flowState
        )}
        onClose={() =>
          addEvent({
            type: ChargePointGroupUserListEvent.CLOSE_CHARGE_POINT_GROUP_USERS_FORM,
            payload: {
              flowState: state.flowState,
              newChargePointGroupUsers: state.newChargePointGroupUsers,
            },
          })
        }
        title={`Add users to "${chargePointGroupName}" charge point fleet`}
      >
        <AddChargePointGroupUsersForm />
      </FormDialog>
      <WarningDialog
        open={_.includes([ChargePointGroupUserListFlowState.SHOWING_CONFIRM_CANCELLING_ADD_USERS_TO_CHARGE_POINT_GROUP], state.flowState)}
        buttonLabel="Confirm"
        buttonCallback={() =>
          addEvent({
            type: ChargePointGroupUserListEvent.COMPLETE_ADDING_USERS_TO_CHARGE_POINT_GROUP,
            payload: {
              newChargePointGroupUsers: state.newChargePointGroupUsers,
            },
          })
        }
        onClose={() =>
          addEvent({
            type: ChargePointGroupUserListEvent.RESUME_ADDING_USERS_TO_CHARGE_POINT_GROUP,
            payload: {
              newChargePointGroupUsers: state.newChargePointGroupUsers,
              chargePointGroup: state.chargePointGroup,
            },
          })
        }
      >
        Closing the form will stop adding the remaining users to the charge point fleet. Would you like to do so?
      </WarningDialog>
      <WarningDialog
        open={_.includes([ChargePointGroupUserListFlowState.SHOWING_REMOVE_CHARGE_POINT_GROUP_USER_FORM], state.flowState)}
        buttonLabel="Remove user"
        buttonCallback={() =>
          addEvent({
            type: ChargePointGroupUserListEvent.SUBMIT_REMOVE_CHARGE_POINT_GROUP_USER_DIALOG,
            payload: {chargePointGroupId: state.chargePointGroup.id, email: state.chargePointGroupUser.email},
          })
        }
        onClose={() => addEvent({type: ChargePointGroupUserListEvent.CLOSE_REMOVE_CHARGE_POINT_GROUP_USER_DIALOG})}
      >
        Are you sure you want to remove {state.chargePointGroupUser?.name} ({state.chargePointGroupUser?.email}) from {state.chargePointGroup?.name}?
      </WarningDialog>
    </>
  );
};

export default ChargePointGroupUserListTable;
