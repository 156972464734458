import React from 'react';
import _ from 'lodash';
import useChargePoints, {ChargePointEvent, ChargePointFlowState} from '../../ChargePoints/hook/useChargePoints';
import FormDialog from '../../../commons/dialogs/FormDialog';
import UpdateChargePointFormContainer from './UpdateChargePointFormContainer';

const UpdateChargePointFormDialog = () => {
  const {state, addEvent} = useChargePoints();
  return (
    <FormDialog
      open={_.includes([ChargePointFlowState.SHOWING_UPDATE_CHARGE_POINT_FORM, ChargePointFlowState.FAILED_TO_UPDATE_CHARGE_POINT], state.flowState)}
      onClose={() => addEvent({type: ChargePointEvent.CLOSE_UPDATE_CHARGE_POINT_FORM})}
      title={`Edit ${state.updateChargePoint?.id}`}
    >
      <UpdateChargePointFormContainer />
    </FormDialog>
  );
};

export default UpdateChargePointFormDialog;
