import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { FaHeartBroken } from 'react-icons/fa';

import { FC } from 'react';
import Center from '../../commons/layout/Center';
import { colors } from '../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  children: {
    margin: theme.spacing(1)
  }
}));

export const GradientBrokenHeartIcon: FC = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor={colors.purple} />
        <stop offset={1} stopColor={colors.blue} />
      </linearGradient>
    </svg>
    <FaHeartBroken size="8em" style={{ fill: 'url(#linearColors)' }} />
  </>
);

interface NotFoundPageProps {
  errorMessage?: string;
}

const NotFoundPage: FC<NotFoundPageProps> = ({ errorMessage }) => {
  const classes = useStyles();
  const defaultErrorMessage = "Looks like this page is out of juice! Let's plug you back into the right spot.";
  return (
    <Center>
      <Box className={classes.root}>
        <GradientBrokenHeartIcon />
        <Typography className={classes.children} variant="h5" align="center">
          {errorMessage ? errorMessage : defaultErrorMessage}
        </Typography>
        {!errorMessage && (
          <Button className={classes.children} variant="outlined" color="primary" href="/">
            Dashboard
          </Button>
        )}
      </Box>
    </Center>
  );
};
export default NotFoundPage;
