import { useContext } from 'react';
import { EMPTY } from 'rxjs';
import { expand, takeWhile } from 'rxjs/operators';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import cpoAdminService from '../../../../services/cpoAdminService';
import { ChargerLog, ChargerLogsEvent, ChargerLogsEventType, ChargerLogsFlowState, UseChargerLogsType } from '../../../../types/charger/ChargerLogs';
import { ChargerLogsContext } from './ChargerLogsProvider';

const MAX_NUMBER_OF_AGGREGATED_LOGS = 200;
const GET_LOGS_REQUEST_LIMIT = 10;

// hook
const useChargerLogs = (): UseChargerLogsType & { ChargerLogsEventType: typeof ChargerLogsEvent } => {
  const { state, dispatch } = useContext(ChargerLogsContext);
  const addEvent = (event: ChargerLogsEventType) => {
    const payload = event.payload;
    switch (event.type) {
      case ChargerLogsEvent.LOAD_CHARGER_LOGS:
      case ChargerLogsEvent.APPLY_FILTER_CRITERIA:
        dispatch({
          ...state,
          loadingState: loadingContainerInitialState,
          flowState: ChargerLogsFlowState.LOADING_LOGS_TABLE
        });

        let previousToken: string;

        const getLogsForCriteria = (nextToken?: string) =>
          cpoAdminService.getLogs(payload.chargePointId, payload.dateRange, payload.logType, nextToken);

        let aggregatedLogs: ChargerLog[] = [];
        let totalNumberOfGetLogsRequests = 0;
        let keepLoading = false;
        getLogsForCriteria()
          .pipe(
            expand(({ nextToken, data }) => {
              if (previousToken === nextToken) {
                return EMPTY;
              }
              aggregatedLogs = aggregatedLogs.concat(...data);
              totalNumberOfGetLogsRequests += 1;
              previousToken = nextToken;
              return getLogsForCriteria(nextToken);
            }),
            // for some reason the stream does not terminate
            // use take while to stop streaming into the pipe
            takeWhile(({ nextToken, data }) => {
              keepLoading = aggregatedLogs.length < MAX_NUMBER_OF_AGGREGATED_LOGS && totalNumberOfGetLogsRequests < GET_LOGS_REQUEST_LIMIT;
              return nextToken !== null && previousToken !== nextToken && keepLoading;
            })
            // finalize(callback), this will get executed regardless if there is an error in the stream or not
          )
          // IMPORTANT: Reduce does not work with AjaxResponse or Promise as it requires concrete value.
          // The only way to obtain value from AjaxResponse is to subscribe to it
          .subscribe(
            // accumulate all logs here
            // concat does not alter the current array, it returns a new concatenated array
            () => {
              // do nothing here, we are accumulating logs in the expand operator;
            },
            // this occurs when there is an error in the stream
            (error) =>
              dispatch({
                ...state,
                dateRange: payload.dateRange,
                logType: payload.logType,
                loadingState: loadingContainerFailedState(error.message),
                flowState: ChargerLogsFlowState.ERROR
              }),
            // this ONLY occurs when we have processed all items in the stream without any errors.
            // if we wish to execute a callback regardless if there is an error, then use "finalize" operator
            () =>
              dispatch({
                dateRange: payload.dateRange,
                logType: payload.logType,
                loadingState: loadingContainerSucceededState,
                logs: aggregatedLogs,
                isPartialLoad: !keepLoading, // Loading stopped because we reached the limit of 200 logs or 10 requests
                flowState: ChargerLogsFlowState.CHARGER_LOGS_LOADED
              })
          );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
    ChargerLogsEventType: ChargerLogsEvent
  };
};
export default useChargerLogs;
