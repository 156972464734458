import { AddEventType, InitialStateType } from '../SharedStates';
import { CpoChargePoint, EmspCharger } from './Charger';

export enum ChargerDetailsFlowState {
  INIT = 'INIT',
  CHARGER_LOADED = 'CHARGER_LOADED',
  FAILED_TO_LOAD_CHARGER = 'FAILED_TO_LOAD_CHARGER'
}

export type ChargerDetailsState = {
  successMessage: string;
  flowState: ChargerDetailsFlowState;
  loadingState: InitialStateType;
  emspCharger?: EmspCharger;
  cpoChargePoint?: CpoChargePoint;
  errorMessage: string;
};

export type UseChargerDetailsType = {
  state: ChargerDetailsState;
  addEvent: AddEventType;
};
