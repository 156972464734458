import CryptoJS from 'crypto-js';

// FIXME make sure this writes to local storage

export const getValue = (key) => {
  const encryptedValue = localStorage.getItem(key);
  if (encryptedValue == null) {
    return null;
  }

  const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, `${process.env.REACT_APP_SECURE_STORAGE_SECRET_KEY}`).toString(CryptoJS.enc.Utf8);
  return decryptedValue;
};

export const putValue = (key, value) => {
  const encryptedValue = CryptoJS.AES.encrypt(value, `${process.env.REACT_APP_SECURE_STORAGE_SECRET_KEY}`).toString();
  return localStorage.setItem(key, encryptedValue);
};

export const getObject = (key) => {
  const encryptedValue = localStorage.getItem(key);
  if (encryptedValue == null) {
    return null;
  }

  const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, `${process.env.REACT_APP_SECURE_STORAGE_SECRET_KEY}`).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedValue);
};

export const putObject = (key, object) => {
  const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(object), `${process.env.REACT_APP_SECURE_STORAGE_SECRET_KEY}`).toString();
  return localStorage.setItem(key, encryptedValue);
};

export const remove = (key) => {
  localStorage.removeItem(key);
};

export const clear = () => {
  localStorage.clear();
};
