import { Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { MdChevronRight, MdLaunch } from 'react-icons/all';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import mainTheme from '../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    '&$listItemSelected, &$listItemSelected:hover': {
      backgroundColor: theme.palette.drawerItem.selectedBackgroundColor
    }
  },
  listItemIcon: {
    paddingBottom: 2,
    marginRight: theme.spacing(2),
    minWidth: 24,
    color: theme.palette.primary.contrastText
  },
  drawerText: {
    color: theme.palette.primary.contrastText
  },
  // DO NOT REMOVE THIS: This class is tied to the selected menu item and used inside root to increase specificity
  listItemSelected: {}
}));

const LeftDrawerMenuItem = ({ label, icon, path, location, includes, excludes }) => {
  const classes = useStyles(mainTheme);

  const isExternalLink = _.startsWith(path, 'http');

  const isSelected = () => {
    const matchesPath = location.pathname.includes(path);
    if (excludes) {
      return matchesPath && !location.pathname.includes(excludes);
    }
    if (includes) {
      return matchesPath || location.pathname.includes(includes);
    }
    return matchesPath;
  };

  return (
    <ListItem
      component={Link}
      classes={{
        root: classes.root,
        selected: classes.listItemSelected
      }}
      button
      key={label}
      to={path}
      selected={isSelected()}
    >
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography component="span" className={classes.drawerText}>
            {label}
          </Typography>
        }
      />
      {isExternalLink ? <MdLaunch size={16} /> : <MdChevronRight size={20} />}
    </ListItem>
  );
};

LeftDrawerMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired
};

export default withRouter(LeftDrawerMenuItem);
