import _, { delay } from 'lodash';
import { createContext, useContext, useReducer } from 'react';
import locationService from '../../../services/locationService';
import { cpos } from '../../../utils/cpos/cpos';

// TODO: unit test
// events
export const RemoteStartEvent = {
  PREVIEW_SUBMITTED: 'PREVIEW_SUBMITTED',
  START_TRANSACTION_SUBMITTED: 'START_TRANSACTION_SUBMITTED',
  CONNECTORS_OPENED: 'CONNECTORS_OPENED',
  CONNECTOR_SUBMITTED: 'CONNECTOR_SUBMITTED',
  REVIEW_REMOTE_START_CANCELLED: 'REVIEW_REMOTE_START_CANCELLED'
};

// flow states
export const RemoteStartFlowState = {
  INIT: 'INIT',
  CHECKING_ELIGIBILITY: 'CHECKING_ELIGIBILITY',
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
  ELIGIBLE: 'ELIGIBLE',
  REVIEW: 'REVIEW',
  FAILED_TO_START: 'FAILED_TO_START',
  TRANSACTION_STARTED: 'TRANSACTION_STARTED',
  TRANSACTION_STARTING: 'TRANSACTION_STARTING'
};

// initial state
const initialState = {
  flowState: RemoteStartFlowState.INIT,
  transaction: null,
  errorMessage: null,
  isEligible: false,
  preview: {
    name: null,
    hasTariff: null,
    cardDetails: null
  },
  email: null,
  evse: null,
  connectors: []
};

// reducer
const reducer = (state, newState) => ({ ...state, ...newState });

// context
const remoteStartContext = createContext();

// provider
export const RemoteStartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return <remoteStartContext.Provider value={{ state, dispatch }}>{children}</remoteStartContext.Provider>;
};

// hook
const useRemoteStartTransactions = () => {
  const { state, dispatch } = useContext(remoteStartContext);

  const addEvent = (event) => {
    switch (event.type) {
      case RemoteStartEvent.PREVIEW_SUBMITTED:
        dispatch({
          flowState: RemoteStartFlowState.CHECKING_ELIGIBILITY,
          errorMessage: null,
          isEligible: false
        });
        locationService.createStartTransactionPreview(event.payload.locationId, event.payload.email).subscribe(
          (response) =>
            delay(() => {
              return dispatch({
                flowState: RemoteStartFlowState.ELIGIBLE,
                preview: response,
                email: event.payload.email
              });
            }, 3000),
          (error) => {
            dispatch({
              flowState: RemoteStartFlowState.NOT_ELIGIBLE,
              errorMessage: error.message
            });
          }
        );
        break;
      case RemoteStartEvent.CONNECTORS_OPENED:
        locationService.getLocationById(event.payload.locationId).subscribe(
          (locationResponse) => {
            if (locationResponse.cpo === cpos.central_server) {
              locationService.getChargePointById(event.payload.locationId).subscribe(
                (chargePointResponse) => {
                  dispatch({
                    connectors: locationResponse.evses.map((evse) => {
                      const evses = {
                        ...evse,
                        connector: chargePointResponse.evses.find((e) => {
                          return (
                            e.connector.standard === evse.connector.standard &&
                            e.connector.format === evse.connector.format &&
                            e.connector.powerType === evse.connector.powerType &&
                            e.status === evse.status
                          );
                        }).connector
                      };
                      return evses;
                    })
                  });
                },
                (error) =>
                  dispatch({
                    errorMessage: error.message
                  })
              );
            } else {
              dispatch({ connectors: locationResponse.evses });
            }
          },
          (error) => dispatch({ errorMessage: error.message })
        );
        break;
      case RemoteStartEvent.CONNECTOR_SUBMITTED:
        dispatch({ flowState: RemoteStartFlowState.REVIEW, evse: event.payload.evse });
        break;
      case RemoteStartEvent.START_TRANSACTION_SUBMITTED:
        dispatch({ flowState: RemoteStartFlowState.TRANSACTION_STARTING, errorMessage: null });
        locationService.createStartTransactionRequestLegacy(event.payload.locationId, state.email, state.evse.id, state.evse.connector.id).subscribe(
          (response) =>
            delay(() => {
              dispatch({ flowState: RemoteStartFlowState.TRANSACTION_STARTED });
            }, 3000),
          (error) => dispatch({ flowState: RemoteStartFlowState.FAILED_TO_START, errorMessage: error.message })
        );
        break;
      case RemoteStartEvent.REVIEW_REMOTE_START_CANCELLED:
        dispatch({
          flowState: RemoteStartFlowState.ELIGIBLE
        });
        break;
      default:
        break;
    }
  };

  return { state, addEvent };
};

export default useRemoteStartTransactions;
