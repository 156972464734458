import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { buildQueryString } from '../utils/url/buildQueryString';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { errorMessageObservable } from '../utils/error/errorUtils';

const toTariff = (tariff) => ({
  id: tariff.id,
  ratePerKwh: tariff.rate_per_kwh,
  ratePerMinute: tariff.rate_per_minute,
  unlockFee: tariff.unlock_fee
});

const toTariffs = (tariffs) => tariffs.map(toTariff);

const legacyTariffService = {
  getTariffsByChargePointId: (chargePointId) => {
    const queryString = buildQueryString({
      location_id: chargePointId
    });
    return withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/tariffs?${queryString}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toTariffs(response.response.data)),
      catchError((error) => errorMessageObservable(error))
    );
  },

  createOrUpdateTariff(chargePointId, tariffId, values) {
    const body = {
      rate_per_kwh: values.ratePerKwh,
      rate_per_minute: values.ratePerMinute,
      unlock_fee: values.unlockFee
    };

    if (tariffId == null) {
      return withAccessToken((accessToken) =>
        ajax({
          url: `${appConfig.emspAdminApiDomain}/tariffs`,
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${accessToken}`
          },
          body: {
            location_id: chargePointId,
            ...body
          }
        })
      ).pipe(catchError((error) => errorMessageObservable(error)));
    }

    return withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/tariffs/${tariffId}`,
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        body: {
          ...body
        }
      })
    ).pipe(
      catchError((error) => {
        return errorMessageObservable(error);
      })
    );
  }
};

export default legacyTariffService;
