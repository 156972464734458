import { FC, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import paths from '../../../routes/paths';
import { AddEventType } from '../../../types/SharedStates';
import { LocationState, LocationsFlowState } from '../../../types/location/LocationState';
import ChargersTable from '../../Chargers/ListChargers/ChargersTable';
import { LocationEvent } from '../hook/useLocations';

interface LocationChargersPanelProps {
  state: LocationState;
  locationId: string;
  addEvent: AddEventType;
}
const LocationChargersPanel: FC<LocationChargersPanelProps> = ({ state, locationId, addEvent }) => {
  const chargersMatch = useRouteMatch(paths.LOCATION_CHARGERS);

  useEffect(() => {
    if (
      chargersMatch?.isExact &&
      state.flowState !== LocationsFlowState.CHARGERS_LOADED_FOR_LOCATION &&
      state.flowState !== LocationsFlowState.FAILED_TO_LOAD_CHARGERS_FOR_LOCATION
    ) {
      addEvent({ type: LocationEvent.LOAD_CHARGERS_FOR_LOCATION, payload: { locationId } });
    }
  });

  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      <ChargersTable chargers={state.chargers} locationId={locationId} showAddCharger={true} />
    </LoadingContainerWithErrorPanel>
  );
};

export default LocationChargersPanel;
