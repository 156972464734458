import PropTypes from 'prop-types';
import BoxWithLabel from '../../../commons/containers/ComponentWithLabel/BoxWithLabel';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import EditButton from '../../../commons/form/button/EditButton';
import { SuccessBar } from '../../../commons/snackbars/snackbars';
import ChargePointStatus from '../../ChargePointStatus/ChargePointStatus';
import useChargePoints from '../../ChargePoints/hook/useChargePoints';
import UpdateChargePointFormDialog from '../UpdateChargePointFormDialog/UpdateChargePointFormDialog';

const ChargePointGeneralInfo = ({ chargePoint, handleEdit }) => {
  const { state } = useChargePoints();

  return (
    <>
      <PaperWithTitle title="General" topRightComponent={<EditButton handleEdit={handleEdit} />}>
        <>
          <TextWithLabel label="Id" text={chargePoint.id} />
          {chargePoint.ocppChargePointId && <TextWithLabel label="Ocpp Charge Point Id" text={chargePoint.ocppChargePointId} />}

          <TextWithLabel label="Charge Point Fleet" text={chargePoint.groupName} />
          <TextWithLabel label="Address" text={chargePoint.address} />
          <TextWithLabel label="Mode" text={chargePoint.mode} />
          <TextWithLabel label="Electricity Cost" text={`$${chargePoint.operationalCost.electricityCost.ratePerKwh.toFixed(2)}/kWh`} />
          <BoxWithLabel label="Status">
            <ChargePointStatus status={chargePoint.status} />
          </BoxWithLabel>
        </>
      </PaperWithTitle>
      <UpdateChargePointFormDialog />
      <SuccessBar open={state.updatedChargePointId}>Charge point"{state.updatedChargePointId}" has been updated!</SuccessBar>
    </>
  );
};

ChargePointGeneralInfo.propTypes = {
  chargePoint: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default ChargePointGeneralInfo;
