import appConfig from '../utils/config/appConfig';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import NoConfigurationsFoundException from '../utils/auth/exceptions/NoConfigurationsFoundException';
import NoConfigurationItemFoundException from '../utils/auth/exceptions/NoConfigurationItemFoundException';
import ChargerNotAvailableException from '../utils/auth/exceptions/ChargerNotAvailableException';
import { ConfigurationTimeToWait, CpoChargePointConfiguration } from '../types/charger/ChargerConfiguration';
import { CpoChargePointConfigurationDto, UpdateConfigurationItemDto } from '../types/charger/ChargerConfigurationDto';
import { toConfigurationTimeToWait, toCpoChargePointConfigurations } from '../converter/charger/ToChargerConfiguration';

const mapConfigurationErrorResponse = (message?: string, action = 'retrieve'): Error => {
  if (!message) {
    return new Error(`Unable to ${action} charger configurations, please try again or contact support`);
  }
  if (message.indexOf('is not in Available status') !== -1 || message.indexOf('is unavailable') !== -1) {
    return new ChargerNotAvailableException(`Unable to ${action} charger configurations when the charger is offline`);
  } else if (message.indexOf('No charge point configurations found') !== -1) {
    return new NoConfigurationsFoundException(`No configurations found for this charger`);
  } else if (message.indexOf('does not exist') !== -1) {
    return new NoConfigurationItemFoundException(`No configuration item found with this key for the charger`);
  } else {
    console.error(message);
    return new Error(`Unable to ${action} charger configurations, please try again or contact support`);
  }
};

const cpoAdminChargePointConfigurationsService = {
  getConfigurations: (chargerPointId: string): Observable<CpoChargePointConfiguration> => {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/charge-points/${chargerPointId}/configurations`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return toCpoChargePointConfigurations(response.response.data as CpoChargePointConfigurationDto);
      }),
      catchError((error) => {
        switch (error.status) {
          case 422:
            return throwError(mapConfigurationErrorResponse(error.response.message));
          default:
            console.error(error.response.message);
            return throwError(new Error('Unable to retrieve charger configurations, please try again or contact support'));
        }
      })
    );
  },
  refreshConfigurations: (chargePointId: string): Observable<ConfigurationTimeToWait> => {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/charge-points/${chargePointId}/configurations/refresh-request`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'POST'
      })
    ).pipe(
      map((response) => toConfigurationTimeToWait(response.response)),
      catchError((error) => {
        switch (error.status) {
          case 422:
            return throwError(mapConfigurationErrorResponse(error.response.message));
          default:
            console.error(error.response.message);
            return throwError(new Error('Unable to retrieve charger configurations, please try again or contact support'));
        }
      })
    );
  },
  updateConfigurationItem: (chargePointId: string, configurationItem: UpdateConfigurationItemDto): Observable<ConfigurationTimeToWait> => {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/charge-points/${chargePointId}/configurations/${configurationItem.key}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'PATCH',
        body: {
          value: configurationItem.value
        }
      })
    ).pipe(
      map((response) => toConfigurationTimeToWait(response.response)),
      catchError((error) => {
        switch (error.status) {
          case 422:
            return throwError(mapConfigurationErrorResponse(error.response.message, 'update'));
          default:
            console.error(error.response.message);
            return throwError(new Error('Unable to update charger configurations, please try again or contact support'));
        }
      })
    );
  }
};

export default cpoAdminChargePointConfigurationsService;
