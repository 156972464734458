import React, {createContext, useContext, useReducer} from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState,
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import chargePointConfigurationsService from '../../../services/chargePointConfigurationsService';
import _, {delay} from 'lodash';

// events
export const ChargePointConfigurationsEvent = {
  LOAD_CHARGE_POINT_CONFIGS: 'LOAD_CHARGE_POINT_CONFIGS',
  REFRESH_CHARGE_POINT_CONFIGS: 'REFRESH_CHARGE_POINT_CONFIGS',
  LOAD_REFRESHED_CHARGE_POINT_CONFIGS: 'LOAD_REFRESHED_CHARGE_POINT_CONFIGS',
  LOAD_UPDATED_CHARGE_POINT_CONFIGS: 'LOAD_UPDATED_CHARGE_POINT_CONFIGS',
  UPDATE_CHARGE_POINT_CONFIG_REQUESTED: 'UPDATE_CHARGE_POINT_CONFIG_REQUESTED',
};

// flow states
export const ChargePointConfigurationsFlowState = {
  LOADING_CHARGE_POINT_CONFIGS: 'LOADING_CHARGE_POINT_CONFIGS',
  CHARGE_POINT_CONFIGS_LOADED: 'CHARGE_POINT_CONFIGS_LOADED',
  REFRESH_CHARGE_POINT_CONFIGS_REQUESTED: 'REFRESH_CHARGE_POINT_CONFIGS_REQUESTED',
  REFRESHING_CHARGE_POINT_CONFIGS: 'REFRESHING_CHARGE_POINT_CONFIGS',
  REFRESH_CHARGE_POINT_CONFIGS_COMPLETED: 'REFRESH_CHARGE_POINT_CONFIGS_COMPLETED',
  FAILED_TO_LOAD_CHARGE_POINT_CONFIGS: 'FAILED_TO_LOAD_CHARGE_POINT_CONFIGS',
  RESET_CHARGE_POINT_REQUIRED: 'RESET_CHARGE_POINT_REQUIRED',
  UPDATING_CHARGE_POINT_CONFIG: 'UPDATING_CHARGE_POINT_CONFIG',
  UPDATE_CHARGE_POINT_CONFIG_COMPLETED: 'UPDATE_CHARGE_POINT_CONFIG_COMPLETED',
  CHARGE_POINT_CONFIG_UPDATED: 'CHARGE_POINT_CONFIG_UPDATED'
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  locationId: '',
  configurations: [],
  refreshRequested: false,
  rebootRequired: false,
  updateRequested: false,
  flowState: ChargePointConfigurationsFlowState.LOADING_CHARGE_POINT_CONFIGS,
  getConfigurationsErrorMessage: null
};

// reducer
const reducer = (state, newState) => ({...state, ...newState});

// context
const chargePointConfigurationsContext = createContext();

// provider
export const ChargePointConfigurationsProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <chargePointConfigurationsContext.Provider value={{state, dispatch}}>{children}</chargePointConfigurationsContext.Provider>
  );
};


// hook
const useChargePointConfigurations = () => {
  const {state, dispatch} = useContext(chargePointConfigurationsContext);
  const getConfigurationsHandler = (event, flowState) => {
    chargePointConfigurationsService.getConfigurations(event.payload.id).subscribe(
      (result) =>
        dispatch({
          loadingState: loadingContainerSucceededState,
          refreshRequested: false,
          locationId: event.payload.id,
          updateRequested: false,
          configurations: result.configurations,
          rebootRequired: result.rebootRequired,
          getConfigurationsErrorMessage: null,
          flowState: (result.rebootRequired) ? ChargePointConfigurationsFlowState.RESET_CHARGE_POINT_REQUIRED : flowState
        }),
      (error) =>
        dispatch({
          ...state,
          loadingState: loadingContainerFailedState(error.message),
          flowState: ChargePointConfigurationsFlowState.FAILED_TO_LOAD_CHARGE_POINT_CONFIGS,
          getConfigurationsErrorMessage: `An error occurred: ${error.message}`,
        })
    );
  }
  
  const addEvent = (event) => {

    switch (event.type) {
      case ChargePointConfigurationsEvent.LOAD_CHARGE_POINT_CONFIGS:
        chargePointConfigurationsService.getConfigurations(event.payload.id).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              locationId: event.payload.id,
              configurations: result.configurations,
              refreshRequested: (!result.rebootRequired),
              rebootRequired: result.rebootRequired,
              updateRequested: false,
              getConfigurationsErrorMessage: null,
              flowState: (result.rebootRequired) ? ChargePointConfigurationsFlowState.RESET_CHARGE_POINT_REQUIRED : ChargePointConfigurationsFlowState.REFRESH_CHARGE_POINT_CONFIGS_REQUESTED
            }),
          (error) =>
            dispatch({
              ...state,
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointConfigurationsFlowState.REFRESH_CHARGE_POINT_CONFIGS_REQUESTED,
              getConfigurationsErrorMessage: `An error occurred: ${error.message}`
            })
        );
        break;
      case ChargePointConfigurationsEvent.REFRESH_CHARGE_POINT_CONFIGS:
        dispatch({
          ...state,
          loadingState: loadingContainerSucceededState,
          locationId: event.payload.id,
          refreshRequested: true,
          updateRequested: false,
          getConfigurationsErrorMessage: null,
          flowState: ChargePointConfigurationsFlowState.REFRESHING_CHARGE_POINT_CONFIGS
        });
        chargePointConfigurationsService.refreshConfigurations(event.payload.id).subscribe(
          (result) =>
            delay(() => {
              dispatch({
                loadingState: loadingContainerSucceededState,
                refreshRequested: false,
                locationId: event.payload.id,
                getConfigurationsErrorMessage: null,
                flowState: ChargePointConfigurationsFlowState.REFRESH_CHARGE_POINT_CONFIGS_COMPLETED
              });
            }, result.timeToWait),
        
          (error) =>
            dispatch({
              ...state,
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointConfigurationsFlowState.FAILED_TO_LOAD_CHARGE_POINT_CONFIGS,
              getConfigurationsErrorMessage: `An error occurred: ${error.message}`,
            })
        );
        break;
      case ChargePointConfigurationsEvent.LOAD_REFRESHED_CHARGE_POINT_CONFIGS:
        const refreshflowState = ChargePointConfigurationsFlowState.CHARGE_POINT_CONFIGS_LOADED
        getConfigurationsHandler(event, refreshflowState);
        break;
      case ChargePointConfigurationsEvent.LOAD_UPDATED_CHARGE_POINT_CONFIGS:
        const updatedflowState = ChargePointConfigurationsFlowState.CHARGE_POINT_CONFIG_UPDATED
        getConfigurationsHandler(event, updatedflowState);
        break;
      case ChargePointConfigurationsEvent.UPDATE_CHARGE_POINT_CONFIG_REQUESTED:
        dispatch({
          loadingState: loadingContainerSucceededState,
          updateRequested: true,
          locationId: event.payload.id,
          getConfigurationsErrorMessage: null,
          flowState: ChargePointConfigurationsFlowState.UPDATING_CHARGE_POINT_CONFIG
        });
        chargePointConfigurationsService.updateConfigurationItem(event.payload.id, event.payload.updatedConfigItem).subscribe(
         (result) =>
           delay(() => {
              // This callback function calls the promise resolve so the table can come out of editable mode
               event.payload.onConfigItemUpdate();
               dispatch({
                 loadingState: loadingContainerSucceededState,
                 updateRequested: true,
                 locationId: event.payload.id,
                 getConfigurationsErrorMessage: null,
                 flowState: ChargePointConfigurationsFlowState.UPDATE_CHARGE_POINT_CONFIG_COMPLETED
               });
            }, result.timeToWait),
         (error) =>
            dispatch({
              ...state,
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointConfigurationsFlowState.FAILED_TO_LOAD_CHARGE_POINT_CONFIGS,
              getConfigurationsErrorMessage: `An error occurred: ${error.message}`,
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };
  
  return {
    state,
    addEvent,
  };
};

export default useChargePointConfigurations;
