import _ from 'lodash';
import dateUtils from '../utils/date/dateUtils';

const buildTransactionDataSetBySum = (transactions, lastNumberOfDays, fieldToSum) => {
  const dateToSumByValueMap = _(transactions)
    // 'wed' -> [s1, s2,.. sn]
    .groupBy((transaction) => dateUtils.startOfDay(transaction.session.startDateTime).toISOString())
    // 'wed' -> sum(s1.kwh, s2.kwh, .. sn.kwh)
    .mapValues((transactions) => _.sumBy(transactions, fieldToSum))
    // {'mon': 20, 'tue': 25, ...}
    .value();

  return dateUtils.getPastDays(lastNumberOfDays).map((dateBetween) => ({
    key: dateUtils.startOfDay(dateBetween).toISOString(),
    value: dateToSumByValueMap[dateUtils.startOfDay(dateBetween).toISOString()] ?? 0
  }));
};

const transactionInsightService = {
  buildElectricityConsumptionData: (transactions, lastNumberOfDays) => {
    return buildTransactionDataSetBySum(transactions, lastNumberOfDays, 'session.kwh');
  },

  calculateTotalElectricityConsumption: (transactions) => {
    return _.round(_.sumBy(transactions, 'session.kwh'), 2);
  },

  buildTransactionCountData: (transactions, lastNumberOfDays) => {
    const totalTransactionsByDay = _(transactions)
      // 'wed' -> [s1, s2,.. sn]
      .groupBy((transaction) => dateUtils.startOfDay(transaction.session.startDateTime).toISOString())
      // 'wed' -> sum(s1.length, s2.length, .. sn.length) - number of sessions
      .mapValues((transactions) => transactions.length)
      // {'mon': 20, 'tue': 25, ...}
      .value();

    return dateUtils.getPastDays(lastNumberOfDays).map((dateBetween) => {
      return {
        key: dateUtils.startOfDay(dateBetween).toISOString(),
        value: totalTransactionsByDay[dateUtils.startOfDay(dateBetween).toISOString()] ?? 0
      };
    });
  },
  buildTransactionsByOrganisation: (transactions, lastNumberOfDays) => {
    if (transactions.length === 0) {
      return [];
    }

    const transactionsByOrganisation = _(transactions)
      .groupBy((transaction) => transaction.location.owner)
      .mapValues((transactions) => transactions.length)
      .value();

    // If only one org selected, show breakdown by Location
    if (Object.keys(transactionsByOrganisation).length === 1) {
      const transactionsByLocation = _(transactions)
        .groupBy((transaction) => transaction.location.name)
        .mapValues((transactions) => transactions.length)
        .value();

      return Object.keys(transactionsByLocation).map((key) => {
        return {
          key: key,
          value: transactionsByLocation[key]
        };
      });
    }

    return Object.keys(transactionsByOrganisation).map((key) => {
      return {
        key: key,
        value: transactionsByOrganisation[key]
      };
    });
  },

  calculateTotalNumberOfTransactions: (transactions) => transactions.length,

  buildCarbonOffsetData: (transactions, lastNumberOfDays) => {
    return buildTransactionDataSetBySum(transactions, lastNumberOfDays, 'carbonEmissions.carbonOffset');
  },

  calculateTotalCarbonOffset: (transactions) => {
    return _.round(_.sumBy(transactions, 'carbonEmissions.carbonOffset'), 2);
  },

  buildElectricityCostData: (transactions, lastNumberOfDays) => {
    return buildTransactionDataSetBySum(transactions, lastNumberOfDays, 'operationalExpenditure.electricityCostTotal');
  },

  calculateTotalElectricityCost: (transactions) => {
    return _.round(_.sumBy(transactions, 'operationalExpenditure.electricityCostTotal'), 2);
  }
};

export default transactionInsightService;
