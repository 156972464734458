import { useContext } from 'react';

import {
  failedState as loadingContainerFailedState,
  succeededState as loadingContainerSucceededState
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import organisationService from '../../../services/organisationService';
import { EventType } from '../../../types/SharedStates';
import { NewOrganisationEvent, NewOrganisationFlowState, OrganisationState } from '../../../types/organisation/OrganisationState';
import { NewOrganisationContext } from './OrganisationContextProvider';

interface UseNewOrganisationType {
  state: OrganisationState;
  addEvent: (event: EventType) => void;
}

const useNewOrganisation = (): UseNewOrganisationType => {
  const { state, dispatch } = useContext(NewOrganisationContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case NewOrganisationEvent.SUBMIT_NEW_ORGANISATION_FORM_REQUESTED:
        // Call service method to create an organisation
        dispatch({
          ...state,
          newOrganisation: { ...event.payload },
          flowState: NewOrganisationFlowState.SUBMITTING_ORGANISATION_FORM,
          newOrganisationErrorMessage: null
        });
        organisationService.createOrganisation(event.payload).subscribe(
          (values) =>
            dispatch({
              ...state,
              loadingState: loadingContainerSucceededState,
              newOrganisation: values,
              flowState: NewOrganisationFlowState.NEW_ORGANISATION_CREATED,
              newOrganisationErrorMessage: null
            }),
          (error) => {
            return dispatch({
              ...state,
              loadingState: loadingContainerFailedState(error.message),
              newOrganisation: event.payload,
              flowState: NewOrganisationFlowState.ENTERING_NEW_ORGANISATION_DETAILS,
              newOrganisationErrorMessage: `An error occurred: ${error.message}`
            });
          }
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useNewOrganisation;
