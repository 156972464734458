import {Field, Form, Formik} from 'formik';
import React, {createRef, useEffect} from 'react';
import * as Yup from 'yup';
import SmartTextField from '../../../commons/form/SmartTextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import useChargePointTariff, {ChargePointTariffEvent, ChargePointTariffFlowState} from './hook/useChargePointTariff';
import Button from '@material-ui/core/Button';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';

const MAX_UNLOCK_FEE = 10;
const MAX_RATE = 2;

const UpdateChargePointTariffForm = () => {
  const {state, addEvent} = useChargePointTariff();
  const formikRef = createRef();
  
  useEffect(() => {
    if (state.flowState === ChargePointTariffFlowState.FAILED_TO_LOAD_CHARGE_POINT_TARIFF) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.flowState, formikRef]);
  
  const handleCancelBtnClick = () => {
    addEvent({
      type: ChargePointTariffEvent.CANCEL_EDIT_TARIFF_REQUESTED,
    });
  };
  
  const initialValues = {
    ratePerKwh: state.chargePointTariff.ratePerKwh.toFixed(2),
    ratePerMinute: state.chargePointTariff.ratePerMinute.toFixed(2),
    unlockFee: state.chargePointTariff.unlockFee.toFixed(2),
  };
  
  const patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/;
  const rateValidation = Yup
    .number()
    .test(
      "is-decimal",
      "Please enter a number that is up to 2 decimal places. e.g. 0.30",
      (val) => {
        if (val !== undefined) {
          return patternTwoDigitsAfterComma.test(val);
        }
        return true;
      }
    )
    .min(0)
    .max(MAX_RATE, "maximum $2")
    .required("Required");
  
  const feeValidation = Yup
    .number()
    .test(
      "is-decimal",
      "Please enter a number that is up to 2 decimal places. e.g. 0.30",
      (val) => {
        if (val !== undefined) {
          return patternTwoDigitsAfterComma.test(val);
        }
        return true;
      }
    )
    .min(0)
    .max(MAX_UNLOCK_FEE, "maximum $10")
    .required("Required");
  
  
  const validationSchema = Yup.object({
    ratePerKwh: rateValidation,
    ratePerMinute: rateValidation,
    unlockFee: feeValidation
  });
  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={(values) => {
          addEvent({
            type: ChargePointTariffEvent.SUBMIT_UPDATE_TARIFF_FORM,
            payload: {
              chargePointId: state.chargePointId,
              tariffId: state.chargePointTariff.id,
              values,
            },
          });
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Form>
            <PaperWithTitle
              title='Tariff'
              children={
                <>
                  <Field
                    label='Electricity Fee'
                    name='ratePerKwh'
                    component={SmartTextField}
                    helperText='Cost per kWh. Up to 2 decimal places, e.g. 0.30'
                    fullWidth
                    startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                    endAdornment={<InputAdornment position='end'>/kWh</InputAdornment>}
                  />
                  <Field
                    label='Duration Fee'
                    name='ratePerMinute'
                    component={SmartTextField}
                    helperText='Cost per min. Up to 2 decimal places, e.g. 0.30'
                    fullWidth
                    startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                    endAdornment={<InputAdornment position='end'>/min</InputAdornment>}
                  />
                  <Field
                    label='Unlock Fee'
                    name='unlockFee'
                    component={SmartTextField}
                    helperText='Cost per transaction. Up to 2 decimal places, e.g. 0.30'
                    fullWidth
                    startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                    endAdornment={<InputAdornment position='end'>/transaction</InputAdornment>}
                  />
                </>
              }
              bottomRightComponent={
                <>
                  <Button type='button' variant='outlined' color='primary' onClick={() => handleCancelBtnClick()}>
                    Cancel
                  </Button>
                  <Button type='submit' variant='contained' color='primary' disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>
                    Confirm
                  </Button>
                </>
              }
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateChargePointTariffForm;
