import React from 'react';
import * as PropTypes from 'prop-types';
import SimpleMultiSelect from './SimpleMultiSelect';
import {Chip} from '@material-ui/core';
import _ from 'lodash';
import mainTheme from '../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const SimpleMultiChips = ({defaultValues, options, onChange}) => {
  const classes = useStyles(mainTheme);

  const displayValue = (value) => _.find(options, ['value', value]).displayValue;

  const onDelete = (value) => {
    // filter out the removed value
    const filteredValues = _.filter(defaultValues, (defaultValue) => defaultValue !== value);
    onChange(filteredValues);
  };

  return (
    <>
      {defaultValues.map((value) => (
        <Chip key={value} className={classes.root} label={displayValue(value)} color="primary" onDelete={() => onDelete(value)} />
      ))}
    </>
  );
};

SimpleMultiSelect.propTypes = {
  defaultValues: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SimpleMultiChips;
