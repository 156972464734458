import { EmspOrganisation, EmspOrganisationAdmin } from '../../types/organisation/Organisation';
import { EmspOrganisationAdminDto, EmspOrganisationDto } from '../../types/organisation/OrganisationDto';

export const toEmspOrganisation = (response: EmspOrganisationDto): EmspOrganisation => {
  return {
    id: response.id,
    name: response.name,
    website: response.website,
    logo: response.logo,
    adminIds: response.admin_ids,
    userIds: response.user_ids
  };
};

export const toEmspOrganisationAdmin = (response: EmspOrganisationAdminDto): EmspOrganisationAdmin => {
  return {
    id: response.id,
    name: response.name,
    email: response.email
  };
};
