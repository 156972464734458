import { LocationUser } from '../../../types/location/user/LocationUser';

import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { FC } from 'react';
import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';
import { requiresPermission } from '../../../hocs/requiresPermission';
import { Permission } from '../../../types/user/UserPermission';

interface IUsersForLocation {
  users: LocationUser[];
  handleAddUser: () => void;
  handleRemoveUser: (user: LocationUser) => void;
}

const UsersTableForLocation: FC<IUsersForLocation> = ({ users, handleAddUser, handleRemoveUser }) => {
  return (
    <MaterialTableWrapper
      columns={[
        {
          title: 'ID',
          field: 'id',
          width: 'auto',
          hidden: true,
          hiddenByColumnsButton: true,
          export: true
        },
        {
          title: 'Name',
          field: 'name',
          width: 'auto',
          defaultSort: 'asc'
        },
        {
          title: 'Email',
          field: 'email',
          width: 'auto'
        }
      ]}
      data={users}
      exportFileName="Users"
      globalActionButton={
        requiresPermission(Permission.MANAGE_ORGANISATION)
          ? {
              name: 'Add User',
              tooltip: 'Add a User to Location',
              onClick: handleAddUser
            }
          : {}
      }
      singleActionButton={{
        icon: RemoveCircleIcon,
        tooltip: 'Remove user from the location',
        onClick: handleRemoveUser
      }}
    />
  );
};

export default UsersTableForLocation;
