import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import {withChargePointTariff} from './hook/withChargePointTariff';
import useChargePointTariff, {ChargePointTariffEvent, ChargePointTariffFlowState} from './hook/useChargePointTariff';
import ChargePointTariff from './ChargePointTariff';
import {ErrorBar, SuccessBar} from '../../../commons/snackbars/snackbars';
import _ from 'lodash';
import UpdateChargePointTariffForm from './UpdateChargePointTariffForm';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import Typography from '@material-ui/core/Typography';

const ChargePointTariffPanel = ({chargePointId}) => {
  const {state, addEvent} = useChargePointTariff();
  
  useEffect(() => {
    if (state.flowState === ChargePointTariffFlowState.INIT) {
      addEvent({type: ChargePointTariffEvent.PAGE_ENTERED, payload: {id: chargePointId}});
    }
    if (state.flowState === ChargePointTariffFlowState.LOAD_TARIFF || state.flowState === ChargePointTariffFlowState.TARIFF_UPDATED) {
      addEvent({type: ChargePointTariffEvent.LOAD_TARIFF_REQUESTED, payload: {id: chargePointId}});
    }
  }, [state.flowState, addEvent, chargePointId]);
  
  const handleOnEdit = () =>
    addEvent({
      type: ChargePointTariffEvent.EDIT_TARIFF_FORM_REQUESTED,
      payload: {
        chargePointId: chargePointId,
        tariffId: state.chargePointTariff.id
      },
    });
  
  return state.flowState === ChargePointTariffFlowState.SHOW_EDIT_TARIFF_FORM ?
    <UpdateChargePointTariffForm/> :
    <LoadingContainerWithErrorPanel{...state.loadingState}>
      {state.flowState === ChargePointTariffFlowState.SHOW_TARIFF_NOT_ALLOWED &&
        <PaperWithTitle>
          <Typography align="center">Location {chargePointId} is in free vending mode</Typography>
        </PaperWithTitle>}
      {state.flowState === ChargePointTariffFlowState.CHARGE_POINT_TARIFF_LOADED &&
        <ChargePointTariff tariff={state.chargePointTariff} handleOnEdit={handleOnEdit}/>}
      <ErrorBar open={!_.isBlankString(state.tariffErrorMessage)}>{state.tariffErrorMessage}</ErrorBar>
      <SuccessBar open={state.tariffUpdated}> Tariff has been updated!</SuccessBar>
    </LoadingContainerWithErrorPanel>;
};

ChargePointTariffPanel.propTypes = {
  chargePointId: PropTypes.string.isRequired,
};
export default withChargePointTariff(ChargePointTariffPanel);
