import { TransactionSummary } from '../transaction/Transaction';

export enum ChargerTransactionsFlowState {
  INIT = 'INIT',
  ERROR = 'ERROR',
  CHARGER_TRANSACTIONS_LOADED = 'CHARGER_TRANSACTIONS_LOADED',
  LOADING_TRANSACTIONS_TABLE = 'LOADING_TRANSACTIONS_TABLE'
}

// state
export type ChargerTransactionsState = {
  transactions: TransactionSummary[];
  loadingState: any;
  flowState: ChargerTransactionsFlowState;
};

export type UseChargerTransactionsType = {
  state: ChargerTransactionsState;
  addEvent: (event: ChargerTransactionsEventType) => void;
};
export type ChargerTransactionsEventType = {
  type: ChargerTransactionsEvent;
  payload?: any;
};

// events
export enum ChargerTransactionsEvent {
  LOAD_CHARGER_TRANSACTIONS = 'LOAD_CHARGER_TRANSACTIONS'
}
