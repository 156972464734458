import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import Button from '@material-ui/core/Button';
import SmartMultiEmailField from '../SmartMultiEmailField';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

interface AddMultipleUsersFormProps {
  handleAddUserByEmails: (emails: string[]) => void;
  helperText: string;
}

const AddMultipleUsersForm: FC<AddMultipleUsersFormProps> = ({ handleAddUserByEmails, helperText }) => {
  const classes = useStyles(mainTheme);
  const [emails, setEmails] = useState<string[]>([]);

  const onSubmit = () => {
    handleAddUserByEmails(emails);
  };

  return (
    <>
      <SmartMultiEmailField
        label="Emails"
        autoFocus
        helperText={helperText}
        onChange={setEmails}
      />
      <Button
        onClick={onSubmit}
        className={classes.button}
        type="button"
        variant="contained"
        color="primary"
        fullWidth
        disabled={emails.length === 0}
      >
        Add
      </Button>
    </>
  );
};

export default AddMultipleUsersForm;
