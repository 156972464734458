import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

import AuthenticatorQrCode from '../AuthenticatorQrCode/AuthenticatorQrCode';
import mainTheme from '../../../../themes/mainTheme';
import OpenLoopPaper from '../../../../commons/layout/OpenLoopPaper';
import Center from '../../../../commons/layout/Center';

const useStyles = makeStyles((theme) => ({
  card: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  cardTitle: {
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: theme.spacing(2),
  },
  navigationContainer: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'flex-end',
    alignContent: 'center',
  },
  instructionList: {
    paddingLeft: theme.spacing(2),
  },
  cardMedia: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    margin: theme.spacing(2),
  },
}));

const RegisterMfaScanQrCode = ({appStoreUrl, storeName, handleReselectDevice, handleCodeAddedToAuthenticatorApp}) => {
  const classes = useStyles(mainTheme);

  const handleBack = () => {
    handleReselectDevice();
  };

  const handleNext = () => {
    handleCodeAddedToAuthenticatorApp();
  };

  return (
    <OpenLoopPaper>
      <Center>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" className={classes.cardTitle}>
              Activate 2-factor authentication
            </Typography>
            <ol className={classes.instructionList}>
              <li>
                <Typography variant="body1" display="inline">
                  Get the{' '}
                </Typography>
                <Link href={appStoreUrl} target="_blank" rel="noreferrer" variant="body1">
                  Twilio Authy App
                </Link>
                <Typography variant="body1" display="inline">
                  {' '}
                  from the {storeName}
                </Typography>
              </li>
              <li>
                <Typography variant="body1">In the App, tap the plus button to add a new account.</Typography>
              </li>
              <li>
                <Typography variant="body1">Choose 'Scan QR Code'.</Typography>
              </li>
              <li>
                <Typography variant="body1">Scan the following QR code to add OpenLoop to Authy:</Typography>
              </li>
            </ol>
            <CardMedia className={classes.cardMedia}>
              <AuthenticatorQrCode />
            </CardMedia>
            <Container className={classes.navigationContainer}>
              <Button onClick={() => handleBack()}>Back</Button>
              <Button onClick={() => handleNext()}>Next</Button>
            </Container>
          </CardContent>
        </Card>
      </Center>
    </OpenLoopPaper>
  );
};

RegisterMfaScanQrCode.propTypes = {
  appStoreUrl: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  handleReselectDevice: PropTypes.func.isRequired,
  handleCodeAddedToAuthenticatorApp: PropTypes.func.isRequired,
};

export default RegisterMfaScanQrCode;
