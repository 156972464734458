import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {Typography} from '@material-ui/core';
import {Field} from 'formik';
import SmartSelectField from '../../../commons/form/SmartSelectField';
import SmartTextField from '../../../commons/form/SmartTextField';
import React from 'react';
import {connectorPowerTypeMap, connectorTypeMap} from '../../../domain/connector';
import PropTypes from 'prop-types';
import mainTheme from '../../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  connectorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  connectorFieldsContainer: {
    border: 'dashed lightgray',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    minWidth: 300,
    justifyContent: 'flex-start',
  },
  connectorTitleHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }
}));

const NewConnectorsDetails = ({handleChange, index}) => {
  const classes = useStyles(mainTheme);
  
  const connectorStandardOptions = Array.from(connectorTypeMap, ([key, value]) => ({value: key, displayValue: value}));
  const connectionPowerTypeOptions = Array.from(connectorPowerTypeMap, ([key, value]) => ({value: key, displayValue: value}));
  
  return (
    <Box key={index} className={classes.connectorFieldsContainer}>
      <Typography className={classes.connectorTitleHeading} variant="h4">Connector</Typography>
      <Field label="Id" name={`connectors.${index}.id`}
             component={SmartTextField}
             helperText="Id of the connector"/>
      <Field label="Format"
             name={`connectors.${index}.format`}
             component={SmartSelectField}
             options={[{value: 'CABLE', displayValue: 'CABLE'}, {value: 'SOCKET', displayValue: 'SOCKET'}]}
             helperText="Format of the connector"/>
      <Field label="PowerType" name={`connectors.${index}.powerType`} component={SmartSelectField}
             options={connectionPowerTypeOptions}
             helperText="Power type of the connector"
      />
      <Field label="Standard" name={`connectors.${index}.standard`} component={SmartSelectField}
             options={connectorStandardOptions}
             helperText="Standard of the connector"
      />
      <Field label="Max Amperage (A)"
             name={`connectors.${index}.maxAmperage`}
             component={SmartTextField}
             helperText="Max amperage of the connector"
             onKeyUp={handleChange}
      />
      <Field label="Max Voltage (V)"
             name={`connectors.${index}.maxVoltage`}
             component={SmartTextField}
             helperText="Max voltage of the connector"
             onKeyUp={handleChange}/>
      <Field label="Max Power (Watts)"
             name={`connectors.${index}.maxPower`}
             component={SmartTextField}
             disabled={true}
            helperText="Max power of the connector"
      />
    </Box>
  )
}
NewConnectorsDetails.propTypes = {
  handleChange: PropTypes.func,
  index: PropTypes.number.isRequired
}
export default NewConnectorsDetails;

