import { makeStyles } from '@material-ui/core/styles';
import { ConnectorStandardDisplay, EmspCharger } from '../../../types/charger/Charger';
import React, { FC, useEffect } from 'react';
import { withTariffProfiles } from './hook/withTariffProfiles';
import useTariffProfiles, { TariffProfilesFlowState } from './hook/useTariffProfiles';
import { TariffProfilesEvent } from '../../../types/tariff/TariffProfilesEvents';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import EditButton from '../../../commons/form/button/EditButton';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { Box } from '@material-ui/core';
import ChargerConnectorIcon from '../ChargerConnectors/ChargerConnectorIcon';
import Typography from '@material-ui/core/Typography';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import { numberToPecentage, roundNumberToCurrency } from '../../../utils/transaction/transactionUtils';
import { AdminPermission, useAdminPermissions } from '../../../hooks/useAdminPermissions';

const useStyles = makeStyles((theme) => ({
  tariffRow: {
    borderBottom: `1px dashed ${theme.palette.divider}`,
    paddingBottom: 12,
    marginBottom: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    overflowX: 'auto',
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0,
      marginBottom: 0
    },
    gap: 8
  },
  tariffDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 150,
    paddingRight: 16
  },
  tariffColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 450
  },
  textRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  bold: {
    fontWeight: 'bold',
    minWidth: 200
  }
}));

type CpoChargerTariffsProps = {
  emspChargerFromParent: EmspCharger;
  handleEdit: () => void;
  isCentralServerCharger?: boolean;
};

const CpoChargerTariffs: FC<CpoChargerTariffsProps> = ({ emspChargerFromParent, isCentralServerCharger, handleEdit }) => {
  const { hasAdminPermission } = useAdminPermissions()
  const { state, addEvent } = useTariffProfiles();
  const { cpoTariffs, emspTariffs } = state;

  useEffect(() => {
    if (state.flowState === TariffProfilesFlowState.INIT) {
      addEvent({
        type: TariffProfilesEvent.LOAD_TARIFF_PROFILES,
        payload: {
          cpoCountryCode: emspChargerFromParent.countryCode,
          cpoPartyId: emspChargerFromParent.partyId
        }
      });
    }
  }, [state.flowState, addEvent, emspChargerFromParent.countryCode, emspChargerFromParent.partyId]);

  const styles = useStyles();
  const connectors = emspChargerFromParent.evses?.flatMap((evse) => evse.connectors) ?? [];
  connectors.sort((a, b) => (a.cpoConnectorId as unknown as number) - (b.cpoConnectorId as unknown as number));

  const shouldShowCpoTariffEdit = hasAdminPermission(AdminPermission.SUPER_ADMIN) && isCentralServerCharger

  return (
    <PaperWithTitle title="Tariffs" topRightComponent={shouldShowCpoTariffEdit ? <EditButton disabled={false} handleEdit={handleEdit} /> : null}>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        {connectors.map((connector, index) => {
          const cpoTariff = cpoTariffs.find((tariff) => tariff.cpoTariffId === connector.cpoTariffId);
          const emspTariff = emspTariffs.find((tariff) => tariff.id === connector.emspTariffOverrideId);

          const shouldShowCpoAppliedMargin = !(emspChargerFromParent.countryCode === 'NZ' &&  emspChargerFromParent.partyId === 'OPL') && cpoTariff && cpoTariff.appliedMargin > 0
          const shouldShowEmspAppliedMargin = !(emspChargerFromParent.countryCode === 'NZ' &&  emspChargerFromParent.partyId === 'OPL') && emspTariff && emspTariff.appliedMargin > 0

          return (
            <Box key={index} className={styles.tariffRow}>
              <Box className={styles.tariffDetails}>
                <ChargerConnectorIcon type={connector.standard} />
                <Typography variant="h5">{ConnectorStandardDisplay.get(connector.standard)}</Typography>
              </Box>
              <Box className={styles.tariffColumn}>
                <Typography variant="h5" className={styles.bold}>
                  {emspChargerFromParent.operator}
                </Typography>
                <Box className={styles.textRow}>
                  <Typography className={styles.bold}>Profile:</Typography><Typography className={styles.bold}>{cpoTariff?.displayText ?? cpoTariff?.cpoTariffId ?? 'None'}</Typography>
                </Box>
                <TextWithLabel label="Electricity Fee" text={`$${roundNumberToCurrency(cpoTariff?.ratePerKwh ?? 0).toFixed(2)}/kWh`} minWidth={200} />
                <TextWithLabel label="Duration Fee" text={`$${roundNumberToCurrency(cpoTariff?.ratePerMinute ?? 0).toFixed(2)}/min`} minWidth={200} />
                <TextWithLabel label="Parking Fee" text={`$${roundNumberToCurrency(cpoTariff?.ratePerParkingMinute ?? 0).toFixed(2)}/min`} minWidth={200} />
                <TextWithLabel label="Unlock Fee" text={`$${roundNumberToCurrency(cpoTariff?.unlockFee ?? 0).toFixed(2)}/transaction`} minWidth={200} />
                {shouldShowCpoAppliedMargin && <TextWithLabel label="Applied Roaming Margin" text={`${numberToPecentage(cpoTariff?.appliedMargin ?? 0)} %`} minWidth={200} />}
              </Box>
              {emspTariff !== undefined && (
                <Box className={styles.tariffColumn}>
                  <Typography variant="h5" className={styles.bold}>
                    Override
                  </Typography>
                  <Box className={styles.textRow}>
                    <Typography className={styles.bold}>Profile:</Typography><Typography className={styles.bold}>{emspTariff?.name ?? 'None'}</Typography>
                  </Box>
                  <TextWithLabel label="Electricity Fee" text={`$${roundNumberToCurrency(emspTariff?.ratePerKwh ?? 0).toFixed(2)}/kWh`} minWidth={200} />
                  <TextWithLabel label="Duration Fee" text={`$${roundNumberToCurrency(emspTariff?.ratePerMinute ?? 0).toFixed(2)}/min`} minWidth={200} />
                  <TextWithLabel label="Parking Fee" text={`$${roundNumberToCurrency(emspTariff?.ratePerParkingMinute ?? 0).toFixed(2)}/min`} minWidth={200} />
                  <TextWithLabel label="Unlock Fee" text={`$${roundNumberToCurrency(emspTariff?.unlockFee ?? 0).toFixed(2)}/transaction`} minWidth={200} />
                  {shouldShowEmspAppliedMargin && <TextWithLabel label="Applied Roaming Margin" text={`${numberToPecentage(emspTariff?.appliedMargin ?? 0)} %`} minWidth={200} />}
                </Box>
              )}
            </Box>
          );
        })}
      </LoadingContainerWithErrorPanel>
    </PaperWithTitle>
  );
};

export default withTariffProfiles(CpoChargerTariffs);
