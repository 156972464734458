import {TextField} from 'formik-material-ui';
import React, {useState} from 'react';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import {Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  passwordHintBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: theme.spacing(1),
  },
  passwordHintRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  passwordHint: {
    fontSize: '0.75rem',
    fontStyle: 'italic',
  },
  passwordHintNotSatisfied: {
    color: theme.palette.smartPasswordHint.notSatisfied,
  },
  passwordHintSatisfied: {
    color: theme.palette.smartPasswordHint.satisfied,
  },
  passwordHintDot: {
    margin: theme.spacing(0.5),
    fontSize: '0.5rem',
    color: theme.palette.smartPasswordHint.notSatisfied,
  },
  passwordHintCheck: {
    marginRight: '0.25rem',
    fontSize: '0.75rem',
    color: theme.palette.smartPasswordHint.satisfied,
  },
}));

const SmartPasswordField = ({inputClass, showHint, field, ...rest}) => {
  const classes = useStyles(mainTheme);

  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const password = field.value;
  const atLeastEightCharacters = password.length >= 8;
  const atLeastOneLowercaseLetter = RegExp(/^.*[a-z].*$/).test(password);
  const atLeastOneUppercaseLetter = RegExp(/^.*[A-Z].*$/).test(password);
  const atLeastOneNumber = RegExp(/^.*\d.*$/).test(password);

  return (
    <>
      <TextField
        field={field}
        {...rest}
        type={values.showPassword ? 'text' : 'password'}
        InputProps={{
          className: inputClass,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {showHint ? (
        <Box classes={{root: classes.passwordHintBox}}>
          <Box classes={{root: classes.passwordHintRow}}>
            {atLeastEightCharacters ? <CheckIcon classes={{root: classes.passwordHintCheck}} /> : <FiberManualRecordIcon classes={{root: classes.passwordHintDot}} />}
            <Typography
              classes={{
                root: clsx({
                  [classes.passwordHint]: true,
                  [classes.passwordHintSatisfied]: atLeastEightCharacters,
                  [classes.passwordHintNotSatisfied]: !atLeastEightCharacters,
                }),
              }}
            >
              At least eight characters
            </Typography>
          </Box>
          <Box classes={{root: classes.passwordHintRow}}>
            {atLeastOneLowercaseLetter ? <CheckIcon classes={{root: classes.passwordHintCheck}} /> : <FiberManualRecordIcon classes={{root: classes.passwordHintDot}} />}
            <Typography
              classes={{
                root: clsx({
                  [classes.passwordHint]: true,
                  [classes.passwordHintSatisfied]: atLeastOneLowercaseLetter,
                  [classes.passwordHintNotSatisfied]: !atLeastOneLowercaseLetter,
                }),
              }}
            >
              At least one lowercase letter (a-z)
            </Typography>
          </Box>
          <Box classes={{root: classes.passwordHintRow}}>
            {atLeastOneUppercaseLetter ? <CheckIcon classes={{root: classes.passwordHintCheck}} /> : <FiberManualRecordIcon classes={{root: classes.passwordHintDot}} />}
            <Typography
              classes={{
                root: clsx({
                  [classes.passwordHint]: true,
                  [classes.passwordHintSatisfied]: atLeastOneUppercaseLetter,
                  [classes.passwordHintNotSatisfied]: !atLeastOneUppercaseLetter,
                }),
              }}
            >
              At least one uppercase letter (A-Z)
            </Typography>
          </Box>
          <Box classes={{root: classes.passwordHintRow}}>
            {atLeastOneNumber ? <CheckIcon classes={{root: classes.passwordHintCheck}} /> : <FiberManualRecordIcon classes={{root: classes.passwordHintDot}} />}
            <Typography
              classes={{
                root: clsx({
                  [classes.passwordHint]: true,
                  [classes.passwordHintSatisfied]: atLeastOneNumber,
                  [classes.passwordHintNotSatisfied]: !atLeastOneNumber,
                }),
              }}
            >
              At least one number (0-9)
            </Typography>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

SmartPasswordField.defaultProps = {
  showHint: false,
};

export default SmartPasswordField;
