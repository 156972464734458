import React, {useContext, useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';

import QRCode from 'qrcode.react';
import CircularProgress from '@material-ui/core/CircularProgress';
import mainTheme from '../../../../themes/mainTheme';
import IdTokenContext from '../../IdTokenContext';
import authenticatorService from '../../../../services/authenticatorService';
import {ErrorBar} from '../../../../commons/snackbars/snackbars';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    margin: '48px',
  },
  qrCode: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const AuthenticatorQrCode = (props) => {
  const classes = useStyles(mainTheme);

  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [failedGenerateQrCode, setFailedGenerateQrCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const idToken = useContext(IdTokenContext);

  useEffect(
    () => {
      authenticatorService.generateAuthenticatorQrCode(idToken).subscribe(
        (result) => {
          setQrCodeUrl(result.data);
          setLoading(false);
        },
        (error) => {
          if (error instanceof Error) {
            setErrorMessage(error.message);
          }
          setFailedGenerateQrCode(true);
          setLoading(true);
        }
      );
    },
    // we use the following as an optimization so that we only make one call to generate the QR code
    // without this, we would send an ajax request on every render cycle
    // (each state variable change triggers a new render cycle)
    [idToken]
  );

  const component = loading ? <CircularProgress size={32} className={classes.circularProgress} /> : <QRCode value={qrCodeUrl} />;

  return (
    <>
      {component}
      <ErrorBar open={failedGenerateQrCode} fullPage>
        {errorMessage}
      </ErrorBar>
    </>
  );
};

export default AuthenticatorQrCode;
