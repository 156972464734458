import { Button, makeStyles, Typography } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { MdChevronRight } from 'react-icons/md';
import BoxWithLabel from '../../../../commons/containers/ComponentWithLabel/BoxWithLabel';
import TextWithLabel from '../../../../commons/containers/ComponentWithLabel/TextWithLabel';

import { connectorTypeMap } from '../../../../domain/connector';
import ChargePointConnectorIcon from '../../../ChargePointDetails/ChargePointConnector/ChargePointConnectorIcon';
import ChargePointConnectorStatus from '../../../ChargePointDetails/ChargePointConnector/ChargePointConnectorStatus';
import { RemoteStartFlowState } from '../../../RemoteStartTransaction/hook/useRemoteStartTransactions';
import useRemoteStartTransactions, { RemoteStartEvent } from '../hook/useRemoteStartTransactions';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  connectorBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #e0e0e0',
    borderRadius: 10
  },
  connectorButton: {
    margin: theme.spacing(1)
  }
}));

interface RemoteStartChooseConnectorFormProps {
  locationId: string;
  displayName?: string;
}

const RemoteStartChooseConnectorForm: FC<RemoteStartChooseConnectorFormProps> = ({ locationId, displayName }) => {
  const classes = useStyles();
  const { state, addEvent } = useRemoteStartTransactions();

  const handleChooseConnector = (evse: any, connector: any) => {
    addEvent({
      type: RemoteStartEvent.CONNECTOR_SUBMITTED,
      payload: {
        selectedEvse: evse,
        selectedConnector: connector
      }
    });
  };

  useEffect(() => {
    if (state.flowState === RemoteStartFlowState.ELIGIBLE) {
      addEvent({
        type: RemoteStartEvent.CONNECTORS_OPENED,
        payload: {
          locationId: locationId,
          displayName: displayName
        }
      });
    }
  }, [locationId, displayName, addEvent, state.flowState]);

  return (
    <>
      <Typography variant="h3">Choose connector</Typography>
      {state.evses.length > 0 &&
        state.evses.map((evse: any) => {
          return evse.connectors.map((connector: any) => {
            const connectorReference = connector.connectorReference !== null;
            return (
              <Button
                className={classes.connectorButton}
                key={`${evse.id}-${connector.id}`}
                onClick={() => handleChooseConnector(evse, connector)}
                fullWidth
              >
                <Grid className={classes.connectorBox}>
                  <Grid item xs={1}>
                    <ChargePointConnectorIcon type={connector.standard} />
                  </Grid>
                  <Grid item xs={3}>
                    <TextWithLabel label="Type" text={connectorTypeMap.get(connector.standard)} />
                  </Grid>
                  <Grid item xs={4}>
                    <BoxWithLabel label="Status">
                      <ChargePointConnectorStatus status={evse.status} />
                    </BoxWithLabel>
                  </Grid>

                  {connectorReference && (
                    <Grid item xs={3}>
                      <TextWithLabel label="Connector Reference" text={connector.connectorReference} />
                    </Grid>
                  )}
                  <Grid item xs={1}>
                    <MdChevronRight size={30} />
                  </Grid>
                </Grid>
              </Button>
            );
          });
        })}
    </>
  );
};

export default RemoteStartChooseConnectorForm;
