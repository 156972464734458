import { InitialStateType } from '../SharedStates';
import { EmspOrganisation } from './Organisation';

export enum NewOrganisationFlowState {
  ENTERING_NEW_ORGANISATION_DETAILS = 'ENTERING_NEW_ORGANISATION_DETAILS',
  SUBMITTING_ORGANISATION_FORM = 'SUBMITTING_ORGANISATION_FORM',
  NEW_ORGANISATION_CREATED = 'NEW_ORGANISATION_CREATED'
}

export enum NewOrganisationEvent {
  SUBMIT_NEW_ORGANISATION_FORM_REQUESTED = 'SUBMIT_NEW_ORGANISATION_FORM_REQUESTED'
}

export type OrganisationState = {
  loadingState: InitialStateType;
  organisations: EmspOrganisation[] | null;
  newOrganisation: EmspOrganisation;
  flowState: NewOrganisationFlowState;
  loading: boolean;
  organisation: EmspOrganisation | null;
  newOrganisationErrorMessage: string | null;
};
