import _ from 'lodash';
import React, { createContext, PropsWithChildren, useContext, useReducer } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import accountService from '../../../services/accountService';
import organisationService from '../../../services/organisationService';
import { EmspAccount } from '../../../types/account/Account';
import { NewAccountFlowState } from '../../../types/account/AccountState';
import { EmspOrganisation } from '../../../types/organisation/Organisation';
import { AddEventType, EventType, InitialStateType } from '../../../types/SharedStates';

// events
export const AccountDetailsEvent = {
  LOAD_ACCOUNT: 'LOAD_ACCOUNT',
  SHOW_UPDATE_ACCOUNT_FORM: 'SHOW_UPDATE_ACCOUNT_FORM',
  CANCEL_UPDATE_ACCOUNT_FORM: 'CANCEL_UPDATE_ACCOUNT_FORM',
  SUBMITTING_UPDATE_ACCOUNT_FORM: 'SUBMITTING_UPDATE_ACCOUNT_FORM',
  // Notifications
  CLEAR_ERROR: 'CLEAR_ERROR',
  CLEAR_SUCCESS: 'CLEAR_SUCCESS'
};

// flow states
export enum AccountDetailsFlowState {
  INIT = 'INIT',
  SHOWING_ACCOUNT = 'SHOWING_ACCOUNT',
  UPDATING_ACCOUNT = 'UPDATING_ACCOUNT',
  FAILED_TO_LOAD_ACCOUNT = 'FAILED_TO_LOAD_ACCOUNT',
  EDITING_ACCOUNT = 'EDITING_ACCOUNT',
  FAILED_TO_EDIT_ACCOUNT = 'FAILED_TO_EDIT_ACCOUNT'
}

export interface AccountDetailsState {
  flowState: AccountDetailsFlowState | NewAccountFlowState;
  loadingState: InitialStateType;
  account: EmspAccount | null;
  organisations: EmspOrganisation[];
  errorMessage: string;
  accountUpdated: boolean;
}

// initial state
const initialState: AccountDetailsState = {
  flowState: AccountDetailsFlowState.INIT,
  loadingState: loadingContainerInitialState,
  account: null,
  organisations: [],
  errorMessage: '',
  accountUpdated: false
};

// reducer
const reducer = (state: AccountDetailsState, newState: Partial<AccountDetailsState>) => ({ ...state, ...newState });

// context
const AccountDetailsContext = createContext<{
  state: AccountDetailsState;
  dispatch: React.Dispatch<Partial<AccountDetailsState>>;
}>({
  state: initialState,
  dispatch: () => initialState
});

// provider
export const AccountDetailsProvider: ({ children }: React.PropsWithChildren<any>) => React.JSX.Element = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <AccountDetailsContext.Provider value={{ state, dispatch }}>{children}</AccountDetailsContext.Provider>
  );
};

interface UseAccountDetailsType {
  state: AccountDetailsState;
  addEvent: AddEventType;
}

// hook
const useAccountDetails = (): UseAccountDetailsType => {
  const { state, dispatch } = useContext(AccountDetailsContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case AccountDetailsEvent.LOAD_ACCOUNT:
        accountService.getAccountById(event.payload.id).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              account: result,
              flowState: AccountDetailsFlowState.SHOWING_ACCOUNT
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message, error.status),
              flowState: AccountDetailsFlowState.FAILED_TO_LOAD_ACCOUNT
            })
        );
        break;

      case AccountDetailsEvent.SHOW_UPDATE_ACCOUNT_FORM:
        organisationService.getOrganisations().subscribe(
          (values) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: AccountDetailsFlowState.EDITING_ACCOUNT,
              organisations: values
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: AccountDetailsFlowState.INIT
            })
        );
        break;

      case AccountDetailsEvent.CANCEL_UPDATE_ACCOUNT_FORM:
        dispatch({ flowState: AccountDetailsFlowState.SHOWING_ACCOUNT });
        break;

      case AccountDetailsEvent.SUBMITTING_UPDATE_ACCOUNT_FORM:
        accountService.updateAccount(event.payload.id, event.payload.name, event.payload.orgId, event.payload.accountType).subscribe(
          () =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              accountUpdated: true,
              flowState: AccountDetailsFlowState.INIT
            }),
          (error) =>
            dispatch({
              ...state,
              accountUpdated: false,
              flowState: AccountDetailsFlowState.EDITING_ACCOUNT,
              errorMessage: `An error occurred: ${error.message}`,
              loadingState: loadingContainerSucceededState
            })
        );
        break;
      case AccountDetailsEvent.CLEAR_ERROR:
        dispatch({
          errorMessage: ''
        });
        break;
      case AccountDetailsEvent.CLEAR_SUCCESS:
        dispatch({
          accountUpdated: false
        });
        break;

      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useAccountDetails;
