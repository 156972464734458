import _ from 'lodash';
import React, { createContext, PropsWithChildren, useReducer } from 'react';
import { initialState as loadingContainerInitialState } from '../../../hooks/useLoadingContainerWithErrorPanel';
import { ChargerConfigurationsFlowState, ChargerConfigurationsState } from '../../../types/charger/ChargerConfiguration';

// initial state
const initialState: ChargerConfigurationsState = {
  flowState: ChargerConfigurationsFlowState.LOADING_CHARGE_POINT_CONFIGS,
  loadingState: loadingContainerInitialState,
  configurations: [],
  rebootRequired: false,
  refreshRequested: false,
  updateRequested: false,
  configurationsErrorMessage: null
};

const reducer = (state: ChargerConfigurationsState, newState: ChargerConfigurationsState) => ({ ...state, ...newState });

// context
export const ChargerConfigurationsContext = createContext<ChargerConfigurationsState | any>(initialState);

// provider
export const ChargerConfigurationsProvider: ({ children }: React.PropsWithChildren<any>) => React.JSX.Element = ({
  children
}: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <ChargerConfigurationsContext.Provider value={{ state, dispatch }}>{children}</ChargerConfigurationsContext.Provider>
  );
};
