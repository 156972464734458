
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { locationStatus } from '../../domain/locationStatus';
import mainTheme from '../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  chargePointStatus: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: 500,
  },
  available: {
    backgroundColor: theme.palette.chargePointStatus.available,
  },
  unavailable: {
    backgroundColor: theme.palette.chargePointStatus.unavailable,
  },
  charging: {
    backgroundColor: theme.palette.chargePointStatus.charging,
  },
  decommissioned: {
    backgroundColor: theme.palette.chargePointStatus.decommissioned,
  },
}));

const LocationStatusDisplay = ({ decommissioned }) => {
  const classes = useStyles(mainTheme);
  return (
    <Box
      width={150}
      className={clsx(classes.chargePointStatus, {
        [classes.available]: !decommissioned,
        [classes.decommissioned]: decommissioned,
      })}
    >
      {decommissioned ? locationStatus.DECOMMISSIONED : locationStatus.ACTIVE}
    </Box>
  );
};

export default LocationStatusDisplay;
