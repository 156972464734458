import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';

const PublicChargePointGroupUserList = ({chargePointGroup}) => (
  <PaperWithTitle>
    <Typography align="center">All users can use the charge points under "{chargePointGroup.name}" charge point fleet</Typography>
  </PaperWithTitle>
);

PublicChargePointGroupUserList.propTypes = {
  chargePointGroup: PropTypes.object.isRequired,
};

export default PublicChargePointGroupUserList;
