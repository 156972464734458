import { Box, Divider } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { createRef, useEffect } from 'react';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import { getModifiedValues } from '../../../commons/form/FormikFunctions';
import CancelButton from '../../../commons/form/button/CancelButton';
import UpdateButton from '../../../commons/form/button/UpdateButton';
import { LocationDetailsFlowState } from '../../../types/LocationDetails';
import useLocations, { LocationEvent } from '../hook/useLocations';
import LocationAddressForm from './EditLocationAddress';
import LocationDetailsForm from './EditLocationDetails';
import { validationSchema } from './LocationDetailsValidation';

const LocationEditForm = () => {
  const {
    state: { updateLocation, flowState, osps, organisations },
    addEvent
  } = useLocations();

  const formikRef = createRef();

  const handleCancel = () => {
    addEvent({
      type: LocationEvent.CANCEL_UPDATE_LOCATION_FORM
    });
  };

  useEffect(() => {
    if (flowState === LocationDetailsFlowState.FAILED_TO_UPDATE_LOCATION) {
      formikRef.current.setSubmitting(false);
    }
  }, [flowState, formikRef]);

  const initialValues = updateLocation
    ? {
        ...updateLocation,
        visibleToOsps: updateLocation.visibleToOsps.map((osp) => {
          return osp.id;
        })
      }
    : {
        electricityCost: updateLocation.electricityCost
      };

  const handleCoordinates = (modifiedValues, values) => {
    if (Object.keys(modifiedValues).includes('latitude') || Object.keys(modifiedValues).includes('longitude')) {
      return {
        latitude: modifiedValues.latitude || values.latitude,
        longitude: modifiedValues.longitude || values.longitude
      };
    }
    return null;
  };

  const setAddressFields = (address) => {
    if (address) {
      formikRef.current.setFieldValue('address', address.address1 + ' ' + address.address2);
      formikRef.current.setFieldValue('city', address.locality);
      formikRef.current.setFieldValue('state', address.state);
      formikRef.current.setFieldValue('postalCode', address.postcode);
      formikRef.current.setFieldValue('latitude', address.latitude);
      formikRef.current.setFieldValue('longitude', address.longitude);
    }
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={(values) => {
          const modifiedValues = getModifiedValues(values, initialValues);

          // We want include the osp object in the visibleToOsps array
          modifiedValues.visibleToOsps = modifiedValues.visibleToOsps?.map((ospId) => osps.find((osp) => osp.id === ospId));

          const coordinateParameters = handleCoordinates(modifiedValues, values);
          addEvent({
            type: LocationEvent.SUBMIT_UPDATE_LOCATION_FORM,
            payload: {
              id: values.id,
              values: { ...modifiedValues, ...coordinateParameters }
            }
          });
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          if (formikProps.values.public === false) {
            formikProps.values.visibleToOsps = [];
          }

          return (
            <Form>
              <PaperWithTitle
                title="General"
                children={
                  <Box>
                    <LocationDetailsForm formikProps={formikProps} organisations={organisations} osps={osps} />
                    <Divider variant="fullWidth" style={{ marginTop: '20px', marginBottom: '5px' }} />
                    <LocationAddressForm setAddressFields={setAddressFields} />
                  </Box>
                }
                bottomRightComponent={
                  <>
                    <CancelButton handleCancel={handleCancel} />
                    <UpdateButton formikProps={formikProps} />
                  </>
                }
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default LocationEditForm;
