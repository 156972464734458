import { FC, useEffect } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { OrganisationChargersEvent, OrganisationChargersFlowState } from '../../../types/organisation/OrganisationChargers';
import ChargersTable from '../../Chargers/ListChargers/ChargersTable';
import useOrganisationChargers from './hook/useOrganisationChargers';
import { withOrganisationChargers } from './hook/withOrganisationChargers';

export interface OrganisationChargersListPanelProps {
  organisationId: string;
}

const OrganisationChargersListPanel: FC<OrganisationChargersListPanelProps> = ({ organisationId }) => {
  const { state, addEvent } = useOrganisationChargers();
  useEffect(() => {
    if (state.flowState === OrganisationChargersFlowState.INIT) {
      addEvent({ type: OrganisationChargersEvent.LOAD_CHARGERS, payload: { organisationId } });
    }
  }, [state.flowState, addEvent, organisationId]);

  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        {<ChargersTable chargers={state.chargers ?? []} hideOrganisationColumn={true} />}
      </LoadingContainerWithErrorPanel>
    </>
  );
};

export default withOrganisationChargers(OrganisationChargersListPanel);
