import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { toEmspAccount } from '../converter/accounts/ToAccount';
import { toEmspOrganisation, toEmspOrganisationAdmin } from '../converter/organisation/ToOrganisation';
import { toEmspOrganisationDto } from '../converter/organisation/ToOrganisationDto';
import { EmspAccount } from '../types/account/Account';
import { EmspAccountDto } from '../types/account/AccountDto';
import { EmspOrganisation, EmspOrganisationAdmin } from '../types/organisation/Organisation';
import { EmspOrganisationAdminDto, EmspOrganisationDto } from '../types/organisation/OrganisationDto';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { handleError } from '../utils/error/handleApiError';
import { buildUrlWithQueryString } from '../utils/url/buildQueryString';

const organisationService = {
  getOrganisations(): Observable<EmspOrganisation[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomain}/organisations`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,

        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return response.response.data.map((organisation: EmspOrganisationDto) => toEmspOrganisation(organisation));
      }),
      catchError((error) => handleError(error))
    );
  },
  getOrganisationById: (organisationId: string): Observable<EmspOrganisation> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/organisations/${organisationId}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toEmspOrganisation(response.response)),
      catchError((error) => handleError(error, 'organisation'))
    ),

  createOrganisation: (organisation: EmspOrganisation): Observable<EmspOrganisation> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/organisations`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        body: toEmspOrganisationDto(organisation)
      })
    ).pipe(
      map((response) => toEmspOrganisation(response.response)),
      catchError((error) => handleError(error))
    ),

  getOrganisationAdmins: (organisationId: string): Observable<EmspOrganisationAdmin[]> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/organisations/${organisationId}/admins`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return response.response.data.map((admin: EmspOrganisationAdminDto) => toEmspOrganisationAdmin(admin));
      }),
      catchError((error) => handleError(error, 'organisation'))
    ),

  addOrganisationAdmin: (organisationId: string, email: string): Observable<void> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/organisations/${organisationId}/admins`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({ email })
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => handleError(error))
    ),
  removeOrganisationAdmin: (organisationId: string, userId: string): Observable<void> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/organisations/${organisationId}/admins/${userId}`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => handleError(error))
    ),

  getAccounts(organisationId: string): Observable<EmspAccount[]> {
    const url = `${appConfig.emspAdminApiDomain}/organisations/${organisationId}/accounts`;
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => response.response.data.map((account: EmspAccountDto) => toEmspAccount(account))),
      catchError((error) => handleError(error, 'organisation'))
    );
  }
};

export default organisationService;
