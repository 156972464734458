// reducer
import _ from 'lodash';
import { PropsWithChildren, createContext, useReducer } from 'react';
import { initialState as loadingContainerInitialState } from '../../../../hooks/useLoadingContainerWithErrorPanel';
import { ReducerContext } from '../../../../types/SharedStates';
import { LocationUsersFlowState, LocationUsersState } from '../../../../types/location/user/LocationUserState';

// initial state
const initialState = {
  flowState: LocationUsersFlowState.INIT,
  loadingState: loadingContainerInitialState,
  users: [],
  errorMessage: ''
};

const reducer = (state: LocationUsersState, newState: Partial<LocationUsersState>) => ({ ...state, ...newState });

// context
export const LocationUsersContext = createContext<ReducerContext<LocationUsersState>>({ state: initialState, dispatch: () => initialState });

// provider
const LocationUsersProvider: ({ children }: React.PropsWithChildren<any>) => React.JSX.Element = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));

  return <LocationUsersContext.Provider value={{ state, dispatch }}>{children}</LocationUsersContext.Provider>;
};

export default LocationUsersProvider;
