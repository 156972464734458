import React from 'react';
import PropTypes from 'prop-types';
import PaperWithTitle from '../../commons/containers/PaperWithTile/PaperWithTitle';
import TextWithLabel from '../../commons/containers/ComponentWithLabel/TextWithLabel';
import dateTimeFormatter from '../../utils/date/dateTimeFormatter';

const UserGroupInfoPanel = ({userGroup}) => (
  <PaperWithTitle title="General">
    <>
      <TextWithLabel label="Id" text={userGroup.id} />
      <TextWithLabel label="Name" text={userGroup.name} />
      <TextWithLabel label="Created" text={dateTimeFormatter.printReadableDateTime(userGroup.created)} />
    </>
  </PaperWithTitle>
);

UserGroupInfoPanel.propTypes = {
  userGroup: PropTypes.object.isRequired,
};

export default UserGroupInfoPanel;
