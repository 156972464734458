import * as React from 'react';
import { ChargerConfigurationsProvider } from '../ChargerConfigurationsProvider';
import { ChargerConfigurationsProps } from '../ChargerConfigurations';

interface WithChargerConfigurationsProps {
  (Component: React.FC<ChargerConfigurationsProps>): (props: ChargerConfigurationsProps) => JSX.Element;
}

export const withChargerConfigurations: WithChargerConfigurationsProps = (Component) => (props) =>
  (
    <ChargerConfigurationsProvider>
      <Component {...props} />
    </ChargerConfigurationsProvider>
  );
