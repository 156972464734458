import _ from 'lodash';
import React, { createContext, Dispatch, PropsWithChildren, useContext, useReducer } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import accountService from '../../../services/accountService';
import organisationService from '../../../services/organisationService';
import { NewAccountEvent, NewAccountFlowState, NewAccountState } from '../../../types/account/AccountState';
import { EventType } from '../../../types/SharedStates';

const initialState = {
  flowState: NewAccountFlowState.INIT,
  loadingState: loadingContainerInitialState
};

export const NewAccountContext = createContext<{ state: NewAccountState; dispatch: Dispatch<Partial<NewAccountState>> }>({
  state: initialState,
  dispatch: () => initialState
});

const reducer = (state: NewAccountState, newState: Partial<NewAccountState>) => ({ ...state, ...newState });

export const NewAccountProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return <NewAccountContext.Provider value={{ state, dispatch }}>{children}</NewAccountContext.Provider>;
};

interface UseNewAccountType {
  state: NewAccountState;
  addEvent: (event: EventType) => void;
}

const useNewAccount = (): UseNewAccountType => {
  const { state, dispatch } = useContext(NewAccountContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case NewAccountEvent.LOAD_ORGANISATION:
        organisationService.getOrganisations().subscribe(
          (values) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: NewAccountFlowState.ENTERING_NEW_ACCOUNT_DETAILS,
              organisations: values
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: NewAccountFlowState.ENTERING_NEW_ACCOUNT_DETAILS
            })
        );
        break;
      case NewAccountEvent.SUBMIT_NEW_ACCOUNT_FORM_REQUESTED:
        // Call service method to create an account
        dispatch({
          ...state,
          newAccount: { ...event.payload },
          flowState: NewAccountFlowState.SUBMITTING_ACCOUNT_FORM,
          newAccountErrorMessage: undefined
        });
        accountService.createAccount(event.payload.name, event.payload.orgId, event.payload.accountType).subscribe(
          (values) =>
            dispatch({
              ...state,
              newAccount: values,
              flowState: NewAccountFlowState.NEW_ACCOUNT_CREATED,
              newAccountErrorMessage: undefined
            }),
          (error) =>
            dispatch({
              ...state,
              newAccount: event.payload,
              flowState: NewAccountFlowState.ENTERING_NEW_ACCOUNT_DETAILS,
              newAccountErrorMessage: `An error occurred: ${error.message}`
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useNewAccount;
