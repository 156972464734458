import * as React from 'react';
import { AccountAdminsProvider } from './useAccountAdmins';

export const withAccountAdmins =
  <T extends Record<string, any>>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: T) =>
    (
      <AccountAdminsProvider>
        <Component {...props} />
      </AccountAdminsProvider>
    );
