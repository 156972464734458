import { Box, Button, Divider, Drawer, List, SwipeableDrawer } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import { Apps, Assessment, Business, BusinessCenter, DescriptionOutlined, EmojiPeople, EvStation, Group, Place } from '@material-ui/icons';
import TableChartIcon from '@material-ui/icons/TableChart';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { MdMenu } from 'react-icons/all';
import drawerBackgroundImage from '../../assets/images/openloop_background_drawer.png';
import logo from '../../assets/images/openloop_logo_white.png';
import HasPermission from '../../commons/permissions/HasPermission';
import { AdminPermission, useAdminPermissions } from '../../hooks/useAdminPermissions';
import { FeatureFlag, useFeatureFlag } from '../../hooks/useFeatureFlags';
import { usePermissions } from '../../hooks/usePermissions';
import paths from '../../routes/paths';
import mainTheme from '../../themes/mainTheme';
import { Permission } from '../../types/user/UserPermission';
import LeftDrawerMenuHeading from './LeftDrawerMenuHeading';
import LeftDrawerMenuItem from './LeftDrawerMenuItem';
import LogOutMenuItem from './LogOutMenuItem';
import ProfileMenuItem from './ProfileMenuItem';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    height: '100vh',
    width: theme.leftDrawer.width,
    backgroundColor: theme.palette.drawer,
    backgroundImage: `url(${drawerBackgroundImage})`,
    justifyContent: 'space-between'
  },
  drawerToggleButton: {
    position: 'absolute',
    top: 18,
    left: 0,
    zIndex: 2
  },
  logoContainer: {
    height: 69
  },
  logo: {
    height: 80,
    marginTop: -5
  }
}));

const LeftDrawer = () => {
  const classes = useStyles(mainTheme);
  const [open, setOpen] = useState(false);
  const { hasAdminPermission } = useAdminPermissions();
  const { hasPermission } = usePermissions();

  // Legacy Admin Portal Management
  const displayUserFleets = useFeatureFlag(FeatureFlag.LEGACY_CHARGE_POINT_MANAGEMENT) && hasPermission(Permission.MANAGE_USER_GROUP);
  const displayChargePoints = useFeatureFlag(FeatureFlag.LEGACY_CHARGE_POINT_MANAGEMENT);
  const displayChargePointFleets = useFeatureFlag(FeatureFlag.LEGACY_CHARGE_POINT_MANAGEMENT);

  const displayLocation = useFeatureFlag(FeatureFlag.LOCATION_DISPLAY) && hasAdminPermission(AdminPermission.LOCATION_ADMIN);
  const displayOrganisation = useFeatureFlag(FeatureFlag.ORGANISATION_DISPLAY) && hasAdminPermission(AdminPermission.ORG_ADMIN);
  const displayChargers = useFeatureFlag(FeatureFlag.CHARGERS_DISPLAY) && hasAdminPermission(AdminPermission.LOCATION_ADMIN);
  const displayUsers = hasPermission(Permission.VIEW_ALL_USERS);
  const displayAccount = useFeatureFlag(FeatureFlag.ORGANISATION_DISPLAY) && hasAdminPermission(AdminPermission.ACCOUNT_ADMIN);

  const displayDashboard = hasAdminPermission(AdminPermission.ORG_ADMIN) || hasAdminPermission(AdminPermission.LOCATION_ADMIN);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const topContainer = (
    <CardMedia className={classes.logoContainer}>
      <img src={logo} className={classes.logo} title="OpenLoop Logo" alt="OpenLoop Logo" />
    </CardMedia>
  );

  const CHARGERS_PATH = '/chargers/';
  const TRANSACTIONS_PATH = '/transactions/';

  const menuItems = (
    <List>
      <Divider className={classes.divider} />
      <ProfileMenuItem />

      <LeftDrawerMenuHeading label={'Charger Management'} />

      {displayDashboard && <LeftDrawerMenuItem label={'Dashboard'} icon={<Assessment />} path={paths.INSIGHTS} />}
      {displayChargePoints && <LeftDrawerMenuItem label={'Charge Points'} icon={<EvStation />} path={paths.CHARGE_POINTS_LEGACY} />}
      {displayChargers && <LeftDrawerMenuItem label={'Chargers'} icon={<EvStation />} path={paths.CHARGERS} includes={CHARGERS_PATH} />}
      {displayChargePointFleets && <LeftDrawerMenuItem label={'Charge Point Fleets'} icon={<Apps />} path={paths.CHARGE_POINT_FLEETS} />}
      {displayLocation && <LeftDrawerMenuItem label={'Locations'} icon={<Place />} path={paths.LOCATIONS} excludes={CHARGERS_PATH} />}
      <LeftDrawerMenuItem label={'Transactions'} icon={<DescriptionOutlined />} path={paths.TRANSACTIONS} includes={TRANSACTIONS_PATH} />
      {(displayUserFleets || displayAccount) && (
        <>
          <LeftDrawerMenuHeading label={'User Management'} />
          {displayUserFleets && <LeftDrawerMenuItem label={'User Fleets'} icon={<Group />} path={paths.USER_FLEETS} />}
          {displayAccount && <LeftDrawerMenuItem label={'Accounts'} icon={<BusinessCenter />} path={paths.ACCOUNTS} />}
        </>
      )}
      <HasPermission permission={Permission.VIEW_REPORTS}>
        <>
          <LeftDrawerMenuHeading label={'Finance'} />
          <LeftDrawerMenuItem label={'Reports'} icon={<TableChartIcon />} path={paths.REPORTS} />
        </>
      </HasPermission>
      {(displayUsers || displayOrganisation) && (
        <>
          <LeftDrawerMenuHeading label={'Advanced'} />
          {displayUsers && <LeftDrawerMenuItem label={'Users'} icon={<EmojiPeople />} path={paths.USERS} />}
          {displayOrganisation && <LeftDrawerMenuItem label={'Organisations'} icon={<Business />} path={paths.ORGANISATIONS} />}
        </>
      )}
      <Divider className={classes.divider} />
    </List>
  );

  const mobileView = (
    <Box>
      <Button className={classes.drawerToggleButton} onClick={toggleDrawer(true)}>
        <MdMenu size={24} color="white" />
      </Button>
      <SwipeableDrawer
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box>
          {topContainer}
          {menuItems}
        </Box>
        <LogOutMenuItem />
      </SwipeableDrawer>
    </Box>
  );

  const browserView = (
    <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }}>
      <Box>
        {topContainer}
        {menuItems}
      </Box>
      <LogOutMenuItem />
    </Drawer>
  );

  return isMobile ? mobileView : browserView;
};

export default LeftDrawer;
