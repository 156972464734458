import { AddEventType, InitialStateType } from '../SharedStates';

export enum ChargerConfigurationsFlowState {
  LOADING_CHARGE_POINT_CONFIGS = 'LOADING_CHARGE_POINT_CONFIGS',
  CHARGE_POINT_CONFIGS_LOADED = 'CHARGE_POINT_CONFIGS_LOADED',
  REFRESH_CHARGE_POINT_CONFIGS_REQUESTED = 'REFRESH_CHARGE_POINT_CONFIGS_REQUESTED',
  REFRESHING_CHARGE_POINT_CONFIGS = 'REFRESHING_CHARGE_POINT_CONFIGS',
  REFRESH_CHARGE_POINT_CONFIGS_COMPLETED = 'REFRESH_CHARGE_POINT_CONFIGS_COMPLETED',
  FAILED_TO_LOAD_CHARGE_POINT_CONFIGS = 'FAILED_TO_LOAD_CHARGE_POINT_CONFIGS',
  RESET_CHARGE_POINT_REQUIRED = 'RESET_CHARGE_POINT_REQUIRED',
  UPDATING_CHARGE_POINT_CONFIG = 'UPDATING_CHARGE_POINT_CONFIG',
  UPDATE_CHARGE_POINT_CONFIG_COMPLETED = 'UPDATE_CHARGE_POINT_CONFIG_COMPLETED',
  CHARGE_POINT_CONFIG_UPDATED = 'CHARGE_POINT_CONFIG_UPDATED'
}

// state
export type ChargerConfigurationsState = {
  flowState: ChargerConfigurationsFlowState;
  loadingState: InitialStateType;
  configurations: any[];
  rebootRequired: boolean;
  refreshRequested: boolean;
  updateRequested: boolean;
  configurationsErrorMessage: string | null;
};

export type UseChargerConfigurationsType = {
  state: ChargerConfigurationsState;
  addEvent: AddEventType;
};

export type CpoChargePointConfiguration = {
  configurations: CpoChargePointConfigurationDetail[];
  rebootRequired: boolean;
  lastUpdated: string;
};

export type CpoChargePointConfigurationDetail = {
  key: string;
  value: string;
  readonly: boolean;
};

export type ConfigurationTimeToWait = {
  timeToWait: number;
};
