import { requiresPermission } from '../../hocs/requiresPermission';
import { Permission } from '../../types/user/UserPermission';
import Dashboard from '../Dashboard/Dashboard';
import NewChargePointContainer from './NewChargePointContainer';

const NewChargePoint = () => {
  return (
    <Dashboard pageHeading="New Charge Point">
      <NewChargePointContainer />
    </Dashboard>
  );
};

export default requiresPermission(Permission.CREATE_CHARGE_POINT)(NewChargePoint);
