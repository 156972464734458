import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { toEmspChargerFromChargerSummaryDto } from '../converter/charger/ToCharger';
import { EmspCharger } from '../types/charger/Charger';
import { EmspChargerSummaryDto } from '../types/charger/ChargerDto';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { errorMessageObservable } from '../utils/error/errorUtils';
import { buildUrlWithQueryString } from '../utils/url/buildQueryString';
import { EmspLocationIdentifier } from '../types/location/Location';
import { toEmspLocationIdentifier } from '../converter/location/ToLocation';
import { handleError } from '../utils/error/handleApiError';

const emspChargerServicev4 = {
  getChargers(orgId?: string): Observable<EmspCharger[]> {
    const params = orgId ? { org_id: orgId } : {};
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV4}/chargers`, params);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((observable) => {
        return observable.response.data.map((charger: EmspChargerSummaryDto) => {
          return toEmspChargerFromChargerSummaryDto(charger);
        });
      }),
      catchError((error) => errorMessageObservable(error))
    );
  },

  getLocationIdentifier(chargerDisplayName: string): Observable<EmspLocationIdentifier> {
    const url = `${appConfig.emspAdminApiDomainV4}/chargers/${chargerDisplayName}/location-id`;
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((observable) => toEmspLocationIdentifier(observable.response)),
      catchError((error) => handleError(error))
    );
  }
};

export default emspChargerServicev4;
