import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import TextWithLabel from '../../../../commons/containers/ComponentWithLabel/TextWithLabel';
import { getChargerModeFromAnonymousChargingAllowed, NewCpoChargePoint } from '../../../../types/charger/Charger';

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  infoFieldsContainer: {
    display: 'flex',
    minWidth: '400px',
    justifyContent: 'flex-start',
    flexDirection: 'column'
  }
}));

interface NewChargerReviewDetailsProps {
  newChargePoint: NewCpoChargePoint;
}

const NewChargerReviewDetails: React.FC<NewChargerReviewDetailsProps> = ({ newChargePoint }) => {
  const classes = useStyles();

  return (
    <Box className={classes.infoContainer}>
      <Box className={classes.infoFieldsContainer}>
        <TextWithLabel label="Ocpp Charge Point Id" text={newChargePoint.ocppChargePointIdentifier} />
        <TextWithLabel label="Name" text={newChargePoint.physicalReference} />
        <TextWithLabel label="Location" text={newChargePoint.locationName} />
        <TextWithLabel label="Mode" text={getChargerModeFromAnonymousChargingAllowed(newChargePoint.anonymousChargingAllowed)} />
      </Box>
    </Box>
  );
};

export default NewChargerReviewDetails;
