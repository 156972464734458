import { Button } from '@material-ui/core';

const EditButton = ({ handleEdit, disabled }) => {
  return (
    <Button variant="contained" color="primary" onClick={handleEdit} disabled={disabled}>
      Edit
    </Button>
  );
};

export default EditButton;
