import _ from 'lodash';
import { FC, createContext, useContext, useReducer } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import accountService from '../../../../services/accountService';
import { AddEventType, InitialStateType, ReducerContext } from '../../../../types/SharedStates';
import { EmspAccountAdmin } from '../../../../types/account/Account';
import { AccountAdminEventTypes, AccountAdminsEvent } from '../../../../types/account/AccountAdminEvents';
import ConflictException from '../../../../utils/auth/exceptions/ConflictException';

export enum AccountAdminsFlowState {
  INIT = 'INIT',
  LOADING_ACCOUNT_ADMINS = 'LOADING_ACCOUNT_ADMINS',
  LOADED_ACCOUNT_ADMINS = 'LOADED_ACCOUNT_ADMINS',
  FAILED_LOADING_ACCOUNT_ADMINS = 'FAILED_LOADING_ACCOUNT_ADMINS',
  // Adding
  SHOWING_ADD_ACCOUNT_ADMIN_FORM = 'SHOWING_ADD_ACCOUNT_ADMIN_FORM',
  FAILED_TO_ADD_ACCOUNT_ADMIN = 'FAILED_TO_ADD_ACCOUNT_ADMIN',
  ADDING_ACCOUNT_ADMIN = 'ADDING_ACCOUNT_ADMIN',
  // Removing
  SHOWING_REMOVE_ACCOUNT_ADMIN_WARNING = 'SHOWING_REMOVE_ACCOUNT_ADMIN_WARNING',
  FAILED_TO_REMOVE_ACCOUNT_ADMIN = 'FAILED_TO_REMOVE_ACCOUNT_ADMIN',
  REMOVING_ACCOUNT_ADMIN = 'REMOVING_ACCOUNT_ADMIN'
}

export interface AccountAdminsState {
  flowState: AccountAdminsFlowState;
  accountAdmins?: EmspAccountAdmin[];
  loadingState: InitialStateType;
  adminAdded?: boolean;
  adminRemoved?: boolean;
  adminToRemove?: EmspAccountAdmin;
  warningMessage?: string;
  errorMessage?: string;
}

const initialState: AccountAdminsState = {
  flowState: AccountAdminsFlowState.INIT,
  loadingState: loadingContainerInitialState
};

const AccountAdminsContext = createContext<ReducerContext<AccountAdminsState>>({
  state: initialState,
  dispatch: () => initialState
});

const reducer = (state: AccountAdminsState, newState: Partial<AccountAdminsState>) => ({ ...state, ...newState });

export const AccountAdminsProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));

  return <AccountAdminsContext.Provider value={{ state, dispatch }}>{children}</AccountAdminsContext.Provider>;
};

interface UseAccountAdminsType {
  state: AccountAdminsState;
  addEvent: AddEventType<AccountAdminEventTypes>;
}

const useAccountAdmins = (): UseAccountAdminsType => {
  const { state, dispatch } = useContext(AccountAdminsContext);

  const addEvent = (event: AccountAdminEventTypes) => {
    switch (event.type) {
      // Initialization
      case AccountAdminsEvent.LOAD_ACCOUNT_ADMINS:
        dispatch({
          flowState: AccountAdminsFlowState.LOADING_ACCOUNT_ADMINS,
          loadingState: loadingContainerInitialState
        });
        accountService.getAccountAdmins(event.payload.accountId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              accountAdmins: result,
              flowState: AccountAdminsFlowState.LOADED_ACCOUNT_ADMINS
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: AccountAdminsFlowState.FAILED_LOADING_ACCOUNT_ADMINS
            })
        );
        break;
      // Add Account Admin
      case AccountAdminsEvent.SHOW_ADD_ACCOUNT_ADMIN_FORM:
        dispatch({
          flowState: AccountAdminsFlowState.SHOWING_ADD_ACCOUNT_ADMIN_FORM
        });
        break;
      case AccountAdminsEvent.CLOSE_ADD_ACCOUNT_ADMIN_FORM:
        dispatch({
          flowState: AccountAdminsFlowState.LOADED_ACCOUNT_ADMINS
        });
        break;
      case AccountAdminsEvent.ADD_ACCOUNT_ADMIN_BY_EMAIL:
        dispatch({
          flowState: AccountAdminsFlowState.ADDING_ACCOUNT_ADMIN
        });
        accountService.addAccountAdmin(event.payload.accountId, event.payload.email).subscribe(
          () =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: AccountAdminsFlowState.INIT,
              adminAdded: true
            }),
          (error) =>
            error instanceof ConflictException
              ? dispatch({
                  warningMessage: 'User is already an admin of this account',
                  flowState: AccountAdminsFlowState.FAILED_TO_ADD_ACCOUNT_ADMIN
                })
              : dispatch({
                  errorMessage: error.message,
                  flowState: AccountAdminsFlowState.FAILED_TO_ADD_ACCOUNT_ADMIN
                })
        );
        break;
      // Remove Account Admin
      case AccountAdminsEvent.SHOW_REMOVE_ACCOUNT_ADMIN_WARNING:
        dispatch({
          flowState: AccountAdminsFlowState.SHOWING_REMOVE_ACCOUNT_ADMIN_WARNING,
          adminToRemove: event.payload.admin
        });
        break;
      case AccountAdminsEvent.CLOSE_REMOVE_ACCOUNT_ADMIN_WARNING:
        dispatch({
          flowState: AccountAdminsFlowState.LOADED_ACCOUNT_ADMINS
        });
        break;
      case AccountAdminsEvent.REMOVE_ACCOUNT_ADMIN_BY_ID:
        dispatch({
          flowState: AccountAdminsFlowState.REMOVING_ACCOUNT_ADMIN
        });
        accountService.removeAccountAdmin(event.payload.accountId, event.payload.userId).subscribe(
          () =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: AccountAdminsFlowState.INIT,
              adminRemoved: true
            }),
          (error) =>
            dispatch({
              errorMessage: error.message,
              flowState: AccountAdminsFlowState.FAILED_TO_REMOVE_ACCOUNT_ADMIN
            })
        );
        break;
      // Notifications
      case AccountAdminsEvent.CLEAR_WARNING:
        dispatch({
          warningMessage: ''
        });
        break;
      case AccountAdminsEvent.CLEAR_ERROR:
        dispatch({
          errorMessage: ''
        });
        break;
      case AccountAdminsEvent.CLEAR_SUCCESS:
        dispatch({
          adminAdded: false,
          adminRemoved: false
        });
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return { state, addEvent };
};

export default useAccountAdmins;
