import { ConfigurationTimeToWait, CpoChargePointConfiguration, CpoChargePointConfigurationDetail } from '../../types/charger/ChargerConfiguration';
import {
  CpoChargePointConfigurationDetailDto,
  CpoChargePointConfigurationDto,
  CpoConfigurationTimeToWaitResponseDto
} from '../../types/charger/ChargerConfigurationDto';

type ToCpoChargePointConfigurationsConverter = (cpoChargePointConfigurationDto: CpoChargePointConfigurationDto) => CpoChargePointConfiguration;
export const toCpoChargePointConfigurations: ToCpoChargePointConfigurationsConverter = (
  cpoChargePointConfigurationDto: CpoChargePointConfigurationDto
) => {
  return {
    configurations: cpoChargePointConfigurationDto.configurations.map((configuration: CpoChargePointConfigurationDetailDto) =>
      toChargePointConfigurationDetail(configuration)
    ),
    rebootRequired: cpoChargePointConfigurationDto.reboot_required,
    lastUpdated: cpoChargePointConfigurationDto.last_updated
  };
};

type ToCpoChargePointConfigurationDetailConverter = (configuration: CpoChargePointConfigurationDetailDto) => CpoChargePointConfigurationDetail;

const toChargePointConfigurationDetail: ToCpoChargePointConfigurationDetailConverter = (configuration) => {
  return {
    key: configuration.key,
    value: configuration.value,
    readonly: configuration.readonly
  };
};

type ToConfigurationTimeToWaitConverter = (response: CpoConfigurationTimeToWaitResponseDto) => ConfigurationTimeToWait;
export const toConfigurationTimeToWait: ToConfigurationTimeToWaitConverter = (response: CpoConfigurationTimeToWaitResponseDto) => ({
  timeToWait: response.time_to_wait
});
