import React, {useState} from 'react';
import IdTokenContext from './IdTokenContext';
import VerifyMfaCodeContainer from './Mfa/VerifyMfaCode/VerifyMfaCodeContainer';
import RegisterForMfa from './Mfa/RegisterForMfa/RegisterForMfa';
import Login from './Login/Login';

const LoginFlow = () => {
  const VERIFY_USERNAME_PASSWORD = 'VERIFY_USERNAME_PASSWORD';
  const REGISTER_FOR_MFA = 'REGISTER_FOR_MFA';
  const VERIFY_MFA = 'VERIFY_MFA';

  const [loginStep, setLoginStep] = useState(VERIFY_USERNAME_PASSWORD);
  const [idToken, setIdToken] = useState(null);

  const handleUsernamePasswordAuthenticated = (authState) => {
    setIdToken(authState.idToken);

    if (!authState.mfaEnabled) {
      setLoginStep(REGISTER_FOR_MFA);
      return;
    }

    setLoginStep(VERIFY_MFA);
  };

  const handleRegisteredForMfa = () => {
    setLoginStep(VERIFY_MFA);
  };

  switch (loginStep) {
    case VERIFY_MFA:
      return (
        <IdTokenContext.Provider value={idToken}>
          <VerifyMfaCodeContainer />
        </IdTokenContext.Provider>
      );
    case REGISTER_FOR_MFA:
      return (
        <IdTokenContext.Provider value={idToken}>
          <RegisterForMfa handleRegisteredForMfa={handleRegisteredForMfa} />
        </IdTokenContext.Provider>
      );
    case VERIFY_USERNAME_PASSWORD:
    default:
      return <Login setUsernamePasswordAuthenticated={handleUsernamePasswordAuthenticated} />;
  }
};

export default LoginFlow;
