class ForbiddenException extends Error {
  status;
  constructor(message, status = 403) {
    super(message);
    this.name = 'ForbiddenException';
    this.status = status;
  }
}

export default ForbiddenException;
