import React, {useContext} from 'react';

import VerifyMfaCode from './VerifyMfaCode';
import IdTokenContext from '../../IdTokenContext';
import useAuthentication from '../../../../hooks/useAuthentication';
import authenticatorService from '../../../../services/authenticatorService';
import tokenStorage from '../../../../utils/storage/tokenStorage';
import jwt from '../../../../utils/jwt/jwt';

const VerifyMfaCodeEnhancement = () => {
  const idToken = useContext(IdTokenContext);
  const {authenticate} = useAuthentication();

  const saveTokenCallback = (idToken, authResult) => {
    tokenStorage.setKey(jwt.getEmail(idToken));
    tokenStorage.save(authResult);
  };

  return <VerifyMfaCode verifyAuthenticatorOneTimePassword={authenticatorService.verifyAuthenticatorOneTimePassword} initialise={authenticate} saveToken={saveTokenCallback} idToken={idToken} />;
};

export default VerifyMfaCodeEnhancement;
