import React from 'react';
import { OrganisationChargersProvider } from './useOrganisationChargers';

export const withOrganisationChargers =
  <T extends Record<string, any>>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: T) => {
    return (
      <OrganisationChargersProvider>
        <Component {...props} />
      </OrganisationChargersProvider>
    );
  };
