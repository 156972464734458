import React, {useCallback, useMemo, useState} from 'react';
import {useLoadingContainerWithErrorPanel} from '../../hooks/useLoadingContainerWithErrorPanel';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import reportService from '../../services/reportService';
import ChargePointsTable from './ChargePointsTable';

const ChargePointsReport = () => {
  const [locations, setLocations] = useState([]);

  const observable = useMemo(() => reportService.getLocations(), []);
  const onSuccessCallback = useCallback((response) => setLocations(response), []);
  const onErrorCallback = useCallback((error) => setLocations([]), []);

  const loadingContainerWithErrorPanelState = useLoadingContainerWithErrorPanel(observable, onSuccessCallback, onErrorCallback);

  return (
    <>
      <LoadingContainerWithErrorPanel {...loadingContainerWithErrorPanelState}>
        <ChargePointsTable locations={locations} />
      </LoadingContainerWithErrorPanel>
    </>
  );
};

export default ChargePointsReport;
