import { useContext } from 'react';
import { iif, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
  failedState as loadingContainerFailedState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import cpoAdminService from '../../../../services/cpoAdminService';
import emspLocationServicev4 from '../../../../services/emspLocationServicev4';
import { EventType } from '../../../../types/SharedStates';
import { ChargerDetailsFlowState, UseChargerDetailsType } from '../../../../types/charger/ChargeDetailsState';
import { isCentralServerCpo } from '../../../../utils/cpos/cpos';
import { ChargerDetailsContext } from './ChargerDetailsProvider';

export enum ChargerDetailsEvent {
  LOAD_CHARGER = 'LOAD_CHARGER',
  RELOAD_CHARGER = 'RELOAD_CHARGER'
}
const useChargerDetails = (): UseChargerDetailsType => {
  const { state, dispatch } = useContext(ChargerDetailsContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case ChargerDetailsEvent.LOAD_CHARGER:
      case ChargerDetailsEvent.RELOAD_CHARGER:
        emspLocationServicev4
          .getChargersByLocationIdAndDisplayName(event.payload.locationId, event.payload.displayName)
          .pipe(
            mergeMap((emspCharger) => {
              const isCentralServerCharger = isCentralServerCpo(emspCharger.countryCode, emspCharger.partyId);
              return iif(
                () => isCentralServerCharger,
                cpoAdminService.getChargerByDisplayName(emspCharger.cpoLocationId, emspCharger.displayName),
                of([])
              ).pipe(
                map((cpoChargePoints) => ({
                  emspCharger: emspCharger,
                  cpoChargePoint: cpoChargePoints[0] ?? null
                }))
              );
            })
          )
          .subscribe(
            ({ emspCharger, cpoChargePoint }) =>
              dispatch({
                loadingState: loadingContainerSucceededState,
                emspCharger: emspCharger,
                cpoChargePoint: cpoChargePoint,
                flowState: ChargerDetailsFlowState.CHARGER_LOADED
              }),
            (error) =>
              dispatch({
                loadingState: loadingContainerFailedState(error.message, error.status),
                flowState: ChargerDetailsFlowState.FAILED_TO_LOAD_CHARGER
              })
          );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };
  return {
    state,
    addEvent
  };
};

export default useChargerDetails;
