import _ from 'lodash';
import { createContext, PropsWithChildren, useContext, useReducer } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import organisationService from '../../../../services/organisationService';
import {
  OrganisationAccountsEvent,
  OrganisationAccountsFlowState,
  OrganisationAccountsState
} from '../../../../types/organisation/OrganisationAccountsState';
import { EventType, ReducerContext } from '../../../../types/SharedStates';

const initialState: OrganisationAccountsState = {
  loadingState: loadingContainerInitialState,
  accounts: [],
  flowState: OrganisationAccountsFlowState.INIT
};

export const OrganisationAccountsContext = createContext<ReducerContext<OrganisationAccountsState>>({
  state: initialState,
  dispatch: () => initialState
});

const reducer = (state: OrganisationAccountsState, newState: Partial<OrganisationAccountsState>) => ({ ...state, ...newState });

export const OrganisationAccountsProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return <OrganisationAccountsContext.Provider value={{ state, dispatch }}>{children}</OrganisationAccountsContext.Provider>;
};

interface UseOrganisationAccountsType {
  state: OrganisationAccountsState;
  addEvent: (event: EventType) => void;
}

const useOrganisationAccounts = (): UseOrganisationAccountsType => {
  const { state, dispatch } = useContext(OrganisationAccountsContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case OrganisationAccountsEvent.LOAD_ACCOUNTS:
        organisationService.getAccounts(event.payload.organisationId).subscribe(
          (values) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              accounts: values,
              flowState: OrganisationAccountsFlowState.ACCOUNTS_LOADED
            }),
          (error) =>
            dispatch({
              flowState: OrganisationAccountsFlowState.ERROR_LOADING_ACCOUNTS,
              loadingState: loadingContainerFailedState(error.message)
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useOrganisationAccounts;
