// User converter
import { TransactionSummary } from '../../types/transaction/Transaction';
import {
  TransactionCarbonEmissionsDto,
  TransactionEmissionFactorDto,
  TransactionLocationDto,
  TransactionOperationalExpenditureDto,
  TransactionPaymentBreakdownDto,
  TransactionPaymentDto,
  TransactionSessionDto,
  TransactionSummaryDto,
  TransactionTariffDto,
  TransactionUserDto
} from '../../types/transaction/TransactionSummaryDto';

const toUser = (source: TransactionUserDto) => ({
  id: source.id,
  name: source.name,
  email: source.email,
  mobileNumber: source.mobile_number,
  chargeTagId: source.charge_tag_id,
  accountName: source.account_name
});

// Location converter
const toLocation = (source: TransactionLocationDto) => ({
  id: source.id,
  name: source.name,
  evseUid: source.evse_uid,
  address: source.address,
  coordinates: {
    latitude: source.coordinates.latitude,
    longitude: source.coordinates.longitude
  },
  owner: source.owner
});

// Session converter
const toSession = (source: TransactionSessionDto) => ({
  id: source.id,
  startDateTime: source.start_date_time,
  durationInSeconds: source.duration_in_seconds,
  kwh: source.kwh,
  latestKw: source.latest_kw,
  startStateOfCharge: source.start_state_of_charge,
  latestStateOfCharge: source.latest_state_of_charge
});

// Tariff converter
const toTariff = (source: TransactionTariffDto) => ({
  ratePerKwh: source.rate_per_kwh,
  ratePerMinute: source.rate_per_minute,
  unlockFee: source.unlock_fee
});

// Payment converter
const toPayment = (source: TransactionPaymentDto) => ({
  unlockFeeTotal: source.unlock_fee_total,
  kwhFeeTotal: source.kwh_fee_total,
  minuteFeeTotal: source.minute_fee_total,
  total: source.total
});

// EmissionFactor converter
const toEmissionFactor = (source: TransactionEmissionFactorDto) => ({
  value: source.value,
  unit: source.unit
});

// CarbonEmissions converter
const toCarbonEmissions = (source: TransactionCarbonEmissionsDto) => ({
  petrolEmissions: {
    emissionFactor: toEmissionFactor(source.petrol_emissions.emission_factor),
    amount: source.petrol_emissions.amount
  },
  kwhToKmConversionRate: source.kwh_to_km_conversion_rate,
  electricityEmissions: {
    emissionFactor: toEmissionFactor(source.electricity_emissions.emission_factor),
    amount: source.electricity_emissions.amount
  },
  carbonOffset: source.carbon_offset
});

// OperationalExpenditure converter
const toOperationalExpenditure = (source?: TransactionOperationalExpenditureDto) => ({
  electricityCostTotal: source?.electricity_cost_total ?? null
});

// PaymentBreakdown converter
const toPaymentBreakdown = (source?: TransactionPaymentBreakdownDto) => ({
  prepaidAmount: source?.prepaid_amount ?? 0,
  creditCardPayment: source?.credit_card_payment ?? 0,
  postpayAmount: source?.postpay_amount ?? 0
});

// Main converter function
export const toTransactionSummary = (source: TransactionSummaryDto): TransactionSummary => ({
  id: source.id,
  groupId: source.group_id,
  status: source.status,
  user: toUser(source.user),
  location: toLocation(source.location),
  session: toSession(source.session),
  tariff: toTariff(source.tariff),
  payment: toPayment(source.payment),
  carbonEmissions: toCarbonEmissions(source.carbon_emissions),
  operationalExpenditure: toOperationalExpenditure(source.operational_expenditure),
  paymentBreakdown: toPaymentBreakdown(source.payment_breakdown)
});
