import React, { FC } from 'react';
import Dashboard from '../Dashboard/Dashboard';
import AccountsList from './AccountsList';
import { AdminPermission, requiresAdminPermission } from '../../hooks/useAdminPermissions';

const Accounts: FC = () => (
  <Dashboard pageHeading="Accounts">
    <AccountsList />
  </Dashboard>
);

export default requiresAdminPermission(AdminPermission.ACCOUNT_ADMIN)(Accounts);
