import React, {createContext, useContext, useReducer} from 'react';
import _ from 'lodash';

// events
export const ApplicationEvent = {
  CHARGE_POINT_GROUP_REMOVED: 'CHARGE_POINT_GROUP_REMOVED',
  CLEAR_REMOVED_CHARGE_POINT_GROUP: 'CLEAR_REMOVED_CHARGE_POINT_GROUP',
};

// flow states
export const ApplicationFlowState = {
  INIT: 'INIT',
};

// initial state
const initialState = {
  flowState: ApplicationFlowState.INIT,
  removedChargePointGroupName: null,
};

// reducer
const reducer = (state, newState) => ({...state, ...newState});

// context
const context = createContext(null);

// provider
export const ApplicationProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <context.Provider value={{state, dispatch}}>{children}</context.Provider>
  );
};

// hook
const useApplication = () => {
  const {state, dispatch} = useContext(context);

  const addEvent = (event) => {
    switch (event.type) {
      case ApplicationEvent.CHARGE_POINT_GROUP_REMOVED:
        dispatch({
          removedChargePointGroupName: event.payload.removedChargePointGroupName,
        });
        break;
      case ApplicationEvent.CLEAR_REMOVED_CHARGE_POINT_GROUP:
        dispatch({
          removedChargePointGroupName: null,
        });
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    applicationState: state,
    addApplicationEvent: addEvent,
  };
};

export default useApplication;
