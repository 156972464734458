import * as React from 'react';
import { NewChargerProvider } from './NewChargerProvider';

interface WithNewChargerProps {
  (Component: React.FC<any>): (props: any) => JSX.Element;
}

export const withNewCharger: WithNewChargerProps = (Component) => (props) =>
  (
    <NewChargerProvider>
      <Component {...props} />
    </NewChargerProvider>
  );
