import { LocationUser } from '../../../types/location/user/LocationUser';
import { LocationUserDto } from '../../../types/location/user/LocationUserDto';

type toLocationUserConverter = (user: LocationUserDto) => LocationUser;

export const toLocationUser: toLocationUserConverter = (user) => {
  return {
    id: user.id,
    name: user.name,
    email: user.email
  };
};
