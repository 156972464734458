import {FC, useEffect} from 'react';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import OrganisationsTable from './OrganisationsTable';
import useOrganisations, { OrganisationEvent, OrganisationFlowState } from './hook/useOrganisations';
import { withOrganisations } from './hook/withOrganisations';

const OrganisationsList: FC = () => {
    const { state, addEvent } = useOrganisations();

    useEffect(() => {
        if (state.flowState === OrganisationFlowState.INIT) {
            addEvent({ type: OrganisationEvent.LOAD_ORGANISATIONS });
        }
    }, [state.flowState, addEvent]);

    return (
        <>
            <LoadingContainerWithErrorPanel {...state.loadingState}>
                <OrganisationsTable />
            </LoadingContainerWithErrorPanel>
        </>
    );
};

export default withOrganisations(OrganisationsList);
