import { useContext } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import {
  ChargerTariffsEvent,
  ChargerTariffsEventType,
  ChargerTariffsFlowState,
  UseChargerTariffsType
} from '../../../../types/charger/ChargerTariffs';
import { ChargerTariffsContext } from './ChargerTariffsProvider';
import legacyTariffService from '../../../../services/legacyTariffService';

// hook
const useChargerTariffs = (): UseChargerTariffsType & { ChargerTariffsEventType: typeof ChargerTariffsEvent } => {
  const { state, dispatch } = useContext(ChargerTariffsContext);
  const addEvent = (event: ChargerTariffsEventType) => {
    const payload = event.payload;
    switch (event.type) {
      case ChargerTariffsEvent.LOAD_CHARGER_TARIFFS:
        dispatch({
          ...state,
          loadingState: loadingContainerInitialState,
          flowState: ChargerTariffsFlowState.LOADING_TARIFFS
        });

        legacyTariffService.getTariffsByChargePointId(payload.chargePointId).subscribe(
          (tariffs) => {
            dispatch({
              ...state,
              // only set tariff if there is one, we also currently only support one tariff per charge point
              ...(tariffs[0] && { tariff: tariffs[0] }),
              flowState: ChargerTariffsFlowState.CHARGER_TARIFFS_LOADED,
              loadingState: loadingContainerSucceededState
            });
          },
          (error) => {
            dispatch({
              ...state,
              tariffErrorMessage: error.message,
              flowState: ChargerTariffsFlowState.LOAD_CHARGER_TARIFF_FAILED,
              loadingState: loadingContainerFailedState
            });
          }
        );
        break;
      case ChargerTariffsEvent.EDIT_TARIFF_FORM_REQUESTED:
        dispatch({
          ...state,
          flowState: ChargerTariffsFlowState.SHOW_EDIT_TARIFF_FORM
        });
        break;
      case ChargerTariffsEvent.SUBMIT_TARIFF_UPDATE:
        dispatch({
          ...state,
          loadingState: loadingContainerInitialState,
          flowState: ChargerTariffsFlowState.UPDATING_TARIFF
        });
        legacyTariffService.createOrUpdateTariff(payload.chargePointId, payload.tariff.id, payload.tariff).subscribe(
          () => {
            dispatch({
              ...state,
              flowState: ChargerTariffsFlowState.TARIFF_UPDATED,
              tariffUpdated: true,
              loadingState: loadingContainerSucceededState
            });
          },
          (error) => {
            dispatch({
              ...state,
              tariffErrorMessage: error.message,
              flowState: ChargerTariffsFlowState.UPDATE_CHARGER_TARIFF_FAILED
            });
          }
        );
        break;
      case ChargerTariffsEvent.CANCEL_TARIFF_UPDATE:
        dispatch({
          ...state,
          flowState: ChargerTariffsFlowState.CHARGER_TARIFFS_LOADED
        });
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
    ChargerTariffsEventType: ChargerTariffsEvent
  };
};
export default useChargerTariffs;
