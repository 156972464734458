import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import paths from '../../../routes/paths';
import { OrganisationAccountsEvent, OrganisationAccountsFlowState } from '../../../types/organisation/OrganisationAccountsState';
import AccountsTable from '../../Accounts/AccountsTable';
import useOrganisationAccounts from './hook/useOrganisationAccounts';
import { withOrganisationAccounts } from './hook/withOrganisationAccounts';

export interface OrganisationAccountListPanelProps {
  organisationId: string;
}

const OrganisationAccountListPanel: FC<OrganisationAccountListPanelProps> = ({ organisationId }) => {
  const { state, addEvent } = useOrganisationAccounts();
  const history = useHistory();
  useEffect(() => {
    if (state.flowState === OrganisationAccountsFlowState.INIT) {
      addEvent({ type: OrganisationAccountsEvent.LOAD_ACCOUNTS, payload: { organisationId } });
    }
  }, [state.flowState, addEvent, organisationId]);

  const handleAddAccount = () => {
    history.push(paths.NEW_ACCOUNT, { orgId: organisationId });
  };

  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        <AccountsTable accounts={state.accounts ?? []} handleAddAccount={handleAddAccount} hideOrganisationColumn={true} />
      </LoadingContainerWithErrorPanel>
    </>
  );
};

export default withOrganisationAccounts(OrganisationAccountListPanel);
