import _ from 'lodash';
import { FC, useEffect } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import FormDialog from '../../../commons/dialogs/FormDialog';
import { WarningDialog } from '../../../commons/dialogs/dialogs';
import { ErrorBar, ProcessingBar, SuccessBar, WarningBar } from '../../../commons/snackbars/snackbars';
import { EmspAccount, EmspAccountUser } from '../../../types/account/Account';
import { AccountUserEvent } from '../../../types/account/AccountUserEvent';
import AccountUsersListTable from './AccountUsersListTable';
import AddMultipleUsersForm from '../../../commons/form/AddMultipleUsersForm/AddMultipleUsersForm';
import useAccountUsers, { AccountUsersFlowState } from './hook/useAccountUsers';
import { withAccountUsers } from './hook/withAccountUsers';
import AddingMultipleUsersProgress from '../../../commons/form/AddMultipleUsersForm/AddingMultipleUsersProgress';

interface AccountUsersListPanelProps {
  account: EmspAccount;
}

const AccountUsersListPanel: FC<AccountUsersListPanelProps> = ({ account }) => {
  const accountId = account.id;
  const accountName = account.name;

  const { state, addEvent } = useAccountUsers();

  useEffect(() => {
    if (state.flowState === AccountUsersFlowState.INIT) {
      addEvent({ type: AccountUserEvent.LOAD_ACCOUNT_USERS, payload: { accountId } });
    }
  }, [state.flowState, addEvent, accountId]);

  const showAddUserForm = _.includes(
    [
      AccountUsersFlowState.SHOWING_ADD_ACCOUNT_USER_FORM,
      AccountUsersFlowState.PAUSED_ADDING_ACCOUNT_USERS,
      AccountUsersFlowState.COMPLETED_ADDING_ACCOUNT_USERS,
      AccountUsersFlowState.ADDING_ACCOUNT_USERS
    ],
    state.flowState
  );

  const handleAddUserButtonClick = () => {
    addEvent({ type: AccountUserEvent.SHOW_ADD_ACCOUNT_USER_FORM });
  };

  const handleRemoveUserButtonClick = (user: EmspAccountUser) => {
    addEvent({ type: AccountUserEvent.SHOW_REMOVE_ACCOUNT_USER_WARNING, payload: { user } });
  };

  const handleAddUserByEmails = (emails: string[]) => {
    addEvent({ type: AccountUserEvent.ADD_ACCOUNT_USERS_BY_EMAIL, payload: { emails, accountId } });
  };

  const handleCloseAddingUsersForm = () => {
    if (state.flowState === AccountUsersFlowState.SHOWING_ADD_ACCOUNT_USER_FORM) addEvent({ type: AccountUserEvent.CLOSE_ADD_ACCOUNT_USER_FORM });
    else addEvent({ type: AccountUserEvent.LOAD_ACCOUNT_USERS, payload: { accountId } });
  };

  const handleCancelAddUsers = () => {
    addEvent({ type: AccountUserEvent.CANCEL_ADDING_ACCOUNT_USERS });
  };

  const handleResumeAddUsers = () => {
    addEvent({ type: AccountUserEvent.RESUME_ADDING_ACCOUNT_USERS });
  };

  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        <AccountUsersListTable
          accountUsers={state.accountUsers}
          accountName={accountName}
          handleAddUserButtonClick={handleAddUserButtonClick}
          handleRemoveUserButtonClick={handleRemoveUserButtonClick}
        />
        {/* Add User Form */}
        <FormDialog fullPage={false} open={showAddUserForm} onClose={handleCloseAddingUsersForm} title={`Add users to ${account.name}`}>
          {state.flowState === AccountUsersFlowState.SHOWING_ADD_ACCOUNT_USER_FORM || state.newAccountUsers === undefined ? (
            <AddMultipleUsersForm
              handleAddUserByEmails={handleAddUserByEmails}
              helperText="Please enter the email addresses (space delimited) of the users you would like to add to this account e.g. user1@organisation.com user2@organisation.com"
            />
          ) : (
            <AddingMultipleUsersProgress
              newUsers={state.newAccountUsers}
              handleClose={handleCloseAddingUsersForm}
              handleCancel={handleCancelAddUsers}
              handleResume={handleResumeAddUsers}
              isAdding={state.flowState === AccountUsersFlowState.ADDING_ACCOUNT_USERS}
              isPaused={state.flowState === AccountUsersFlowState.PAUSED_ADDING_ACCOUNT_USERS}
            />
          )}
        </FormDialog>
      </LoadingContainerWithErrorPanel>
      {/* Remove User Warning */}
      <WarningDialog
        fullPage={false}
        open={_.includes([AccountUsersFlowState.SHOWING_REMOVE_ACCOUNT_USER_WARNING], state.flowState)}
        buttonLabel="Remove user"
        buttonCallback={() => {
          if (state.userToRemove)
            addEvent({
              type: AccountUserEvent.REMOVE_ACCOUNT_USER_BY_ID,
              payload: { userId: state.userToRemove?.id, accountId }
            });
        }}
        onClose={() => addEvent({ type: AccountUserEvent.CLOSE_REMOVE_ACCOUNT_USER_WARNING })}
      >
        Are you sure you want to remove {state.userToRemove?.name} from {account.name} users?
      </WarningDialog>
      {/* Notification Bars */}
      <SuccessBar open={state.userRemoved} fullPage={false} onClose={() => addEvent({ type: AccountUserEvent.CLEAR_SUCCESS })}>
        User has been removed
      </SuccessBar>
      <ProcessingBar
        open={state.flowState === AccountUsersFlowState.ADDING_ACCOUNT_USERS || state.flowState === AccountUsersFlowState.REMOVING_ACCOUNT_USER}
        fullPage={false}
      />
      <WarningBar open={!_.isEmpty(state.warningMessage)} fullPage={false} onClose={() => addEvent({ type: AccountUserEvent.CLEAR_WARNING })}>
        {state.warningMessage}
      </WarningBar>
      <ErrorBar open={!_.isEmpty(state.errorMessage)} fullPage={false} onClose={() => addEvent({ type: AccountUserEvent.CLEAR_ERROR })}>
        {state.errorMessage}
      </ErrorBar>
    </>
  );
};

export default withAccountUsers(AccountUsersListPanel);
