import { EmspAccountUser } from './Account';

export enum AccountUserEvent {
  LOAD_ACCOUNT_USERS = 'LOAD_ACCOUNT_USERS',
  // Add Account User
  SHOW_ADD_ACCOUNT_USER_FORM = 'SHOW_ADD_ACCOUNT_USER_FORM',
  CLOSE_ADD_ACCOUNT_USER_FORM = 'CLOSE_ADD_ACCOUNT_USER_FORM',
  ADD_ACCOUNT_USERS_BY_EMAIL = 'ADD_ACCOUNT_USERS_BY_EMAIL',
  CANCEL_ADDING_ACCOUNT_USERS = 'CANCEL_ADDING_ACCOUNT_USERS',
  RESUME_ADDING_ACCOUNT_USERS = 'RESUME_ADDING_ACCOUNT_USERS',
  // Remove Account User
  SHOW_REMOVE_ACCOUNT_USER_WARNING = 'SHOW_REMOVE_ACCOUNT_USER_WARNING',
  CLOSE_REMOVE_ACCOUNT_USER_WARNING = 'CLOSE_REMOVE_ACCOUNT_USER_WARNING',
  REMOVE_ACCOUNT_USER_BY_ID = 'REMOVE_ACCOUNT_USER_BY_ID',
  // Notifications
  CLEAR_WARNING = 'CLEAR_WARNING',
  CLEAR_ERROR = 'CLEAR_ERROR',
  CLEAR_SUCCESS = 'CLEAR_SUCCESS'
}

interface AccountUserEventWithoutPayload {
  type:
    | AccountUserEvent.SHOW_ADD_ACCOUNT_USER_FORM
    | AccountUserEvent.CLOSE_ADD_ACCOUNT_USER_FORM
    | AccountUserEvent.CLOSE_REMOVE_ACCOUNT_USER_WARNING
    | AccountUserEvent.CLEAR_WARNING
    | AccountUserEvent.CLEAR_ERROR
    | AccountUserEvent.CLEAR_SUCCESS
    | AccountUserEvent.CANCEL_ADDING_ACCOUNT_USERS
    | AccountUserEvent.RESUME_ADDING_ACCOUNT_USERS;
}

interface LoadAccountUsersEvent {
  type: AccountUserEvent.LOAD_ACCOUNT_USERS;
  payload: { accountId: string };
}

interface AddAccountUserByEmailEvent {
  type: AccountUserEvent.ADD_ACCOUNT_USERS_BY_EMAIL;
  payload: { emails: string[]; accountId: string };
}

interface ShowRemoveAccountUserWarningEvent {
  type: AccountUserEvent.SHOW_REMOVE_ACCOUNT_USER_WARNING;
  payload: { user: EmspAccountUser };
}

interface RemoveAccountUserByIdEvent {
  type: AccountUserEvent.REMOVE_ACCOUNT_USER_BY_ID;
  payload: { userId: string; accountId: string };
}

export type AccountUserEventTypes =
  | AccountUserEventWithoutPayload
  | LoadAccountUsersEvent
  | AddAccountUserByEmailEvent
  | ShowRemoveAccountUserWarningEvent
  | RemoveAccountUserByIdEvent;
