import React from 'react';
import PropTypes from 'prop-types';
import chademo from '../../../assets/images/connectors/chademo.svg';
import ccs from '../../../assets/images/connectors/ccs.svg';
import type1 from '../../../assets/images/connectors/type1.svg';
import type2 from '../../../assets/images/connectors/type2.svg';
import CardMedia from '@material-ui/core/CardMedia';

const ChargePointConnectorIcon = ({type}) => {
  const getConnector = (type) => {
    switch (type) {
      case 'CHADEMO':
        return chademo;
      case 'IEC_62196_T1':
        return type1;
      case 'IEC_62196_T2':
        return type2;
      case 'IEC_62196_T2_COMBO':
        return ccs;
      default:
        throw new Error('Unknown connector');
    }
  };
  return (
    <CardMedia>
      <img src={getConnector(type)} alt="Connector" height={72} width={72} />
    </CardMedia>
  );
};

ChargePointConnectorIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ChargePointConnectorIcon;
