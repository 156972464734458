import React, {createContext, useContext, useReducer} from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState,
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import _ from 'lodash';
import userService from '../../../../services/userService';

// events
export const UserChargePointFleetsEvent = {
  PAGE_ENTERED: 'PAGE_ENTERED',
};

// flow states
export const UserChargePointFleetsFlowState = {
  INIT: 'INIT',
  SHOWING_CHARGE_POINT_FLEETS: 'SHOWING_CHARGE_POINT_FLEETS',
  ERROR: 'ERROR',
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  flowState: UserChargePointFleetsFlowState.INIT,
  chargePointFleets: null,
};

// reducer
const reducer = (state, newState) => ({...state, ...newState});

// context
const userChargePointFleetsContext = createContext(null);

// provider
export const UserChargePointFleetsProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <userChargePointFleetsContext.Provider value={{state, dispatch}}>{children}</userChargePointFleetsContext.Provider>
  );
};

// hook
const useUserChargePointFleets = () => {
  const {state, dispatch} = useContext(userChargePointFleetsContext);

  const addEvent = (event) => {
    switch (event.type) {
      case UserChargePointFleetsEvent.PAGE_ENTERED:
        userService.getChargePointFleetsByUserId(event.userId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: UserChargePointFleetsFlowState.SHOWING_CHARGE_POINT_FLEETS,
              chargePointFleets: result,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: UserChargePointFleetsFlowState.ERROR,
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
  };
};

export default useUserChargePointFleets;
