class PageNotFoundException extends Error {
  status;
  constructor(message, status = 404) {
    super(message);
    this.name = 'PageNotFoundException';
    this.status = status;
  }
}

export default PageNotFoundException;
