import useChargePointConfigurations, {ChargePointConfigurationsEvent, ChargePointConfigurationsFlowState} from './hook/useChargePointConfigurations';
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import ChargePointConfigurationsPanel from './ChargePointConfigurationsPanel';
import {withChargePointConfigurations} from './hook/withChargePointConfigurations';

const ChargePointConfigurations = ({locationId}) => {
  const {state, addEvent} = useChargePointConfigurations();
  
  useEffect(() => {
      async function handleFlowState() {
        switch( state.flowState){
          case ChargePointConfigurationsFlowState.LOADING_CHARGE_POINT_CONFIGS:
            addEvent({type: ChargePointConfigurationsEvent.LOAD_CHARGE_POINT_CONFIGS, payload: {id: locationId}});
            break;
          case ChargePointConfigurationsFlowState.REFRESH_CHARGE_POINT_CONFIGS_REQUESTED:
            addEvent({type: ChargePointConfigurationsEvent.REFRESH_CHARGE_POINT_CONFIGS, payload: {id: locationId}});
            break;
          case ChargePointConfigurationsFlowState.UPDATE_CHARGE_POINT_CONFIG_COMPLETED:
            addEvent({type: ChargePointConfigurationsEvent.LOAD_UPDATED_CHARGE_POINT_CONFIGS, payload: {id: locationId}});
            break;
          case ChargePointConfigurationsFlowState.REFRESH_CHARGE_POINT_CONFIGS_COMPLETED:
            addEvent({type: ChargePointConfigurationsEvent.LOAD_REFRESHED_CHARGE_POINT_CONFIGS, payload: {id: locationId}});
            break;
          default:
            break;
        }
      }
    handleFlowState();
  
  }, [state.flowState, addEvent, locationId]);
  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      {state.configurations && <ChargePointConfigurationsPanel
        configurations={state.configurations}
        rebootRequired={state.rebootRequired}
        refreshRequested={state.refreshRequested}
        updateRequested={state.updateRequested}
      />}
    </LoadingContainerWithErrorPanel>
  );
}
ChargePointConfigurations.propTypes = {
  locationId: PropTypes.string.isRequired,
};

export default withChargePointConfigurations(ChargePointConfigurations);