import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes, { string } from 'prop-types';
import { isMobile } from 'react-device-detect';
import mainTheme from '../../themes/mainTheme';
import LeftDrawer from './LeftDrawer';
import TopAppBar from './TopAppBar';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    height: '100vh'
  },
  left: {},
  right: {
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'scroll'
  },
  content: {
    padding: theme.spacing(2)
  },
  pageHeading: {
    padding: theme.spacing(2),
    paddingBottom: 0
  }
}));

const Dashboard = ({ pageHeading, children }) => {
  const classes = useStyles(mainTheme);

  return (
    <Box className={classes.container}>
      <Box className={classes.left}>
        <LeftDrawer />
      </Box>
      <Box className={classes.right}>
        {isMobile && <TopAppBar />}
        <Typography className={classes.pageHeading} variant="h1">
          {pageHeading}
        </Typography>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Box>
  );
};

Dashboard.defaultProps = {
  pageHeading: ''
};

Dashboard.propTypes = {
  pageHeading: string,
  children: PropTypes.element.isRequired
};

export default Dashboard;
