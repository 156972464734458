import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { FC } from 'react';
import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';

import { usePermissions } from '../../../hooks/usePermissions';
import { EmspAccountAdmin } from '../../../types/account/Account';
import { Permission } from '../../../types/user/UserPermission';

interface AccountAdminListTableProps {
  adminUsers?: EmspAccountAdmin[];
  accountName: string;
  handleAddAdminButtonClick?: () => void;
  handleRemoveAdminButtonClick?: (admin: EmspAccountAdmin) => void;
}

const AccountAdminListTable: FC<AccountAdminListTableProps> = ({
  adminUsers,
  accountName,
  handleAddAdminButtonClick,
  handleRemoveAdminButtonClick
}) => {
  const { hasPermission } = usePermissions();

  return (
    <MaterialTableWrapper
      columns={[
        { title: 'Id', field: 'id', width: 'auto', hidden: true, hiddenByColumnsButton: true, export: true },
        {
          title: 'Name',
          field: 'name',
          width: 'auto',
          defaultSort: 'asc'
        },
        { title: 'Email', field: 'email', width: 'auto' }
      ]}
      data={adminUsers}
      globalActionButton={
        hasPermission(Permission.MANAGE_ACCOUNT) && {
          name: 'Add Admin',
          tooltip: 'Add a new admin to the account',
          onClick: handleAddAdminButtonClick
        }
      }
      singleActionButton={
        hasPermission(Permission.MANAGE_ACCOUNT) && {
          icon: RemoveCircleIcon,
          tooltip: 'Remove admin from the account',
          onClick: handleRemoveAdminButtonClick
        }
      }
      exportFileName={`account_admins_${accountName}`}
    />
  );
};

export default AccountAdminListTable;
