import React from 'react';
import Link from '@material-ui/core/Link';
import * as PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';

const NavigationLink = ({className, path, text}) => (
  <Link className={className} component={RouterLink} to={path}>
    {text}
  </Link>
);

NavigationLink.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default NavigationLink;
