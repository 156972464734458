import { FC, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { AdminPermission, requiresAdminPermission } from '../../hooks/useAdminPermissions';
import paths from '../../routes/paths';
import { LocationDetailsFlowState } from '../../types/LocationDetails';
import { LocationsFlowState } from '../../types/location/LocationState';
import Dashboard from '../Dashboard/Dashboard';
import LocationTabs from './LocationTabs';
import useLocations, { LocationEvent } from './hook/useLocations';
import { withLocations } from './hook/withLocations';

const routeDirectlyAccessed = (detailsMatch: boolean | undefined, flowState: LocationDetailsFlowState | LocationsFlowState | null) =>
  detailsMatch && flowState === LocationDetailsFlowState.INIT;

const pageLoadingFromRedirection = (flowState: LocationDetailsFlowState | LocationsFlowState | null) =>
  flowState === LocationDetailsFlowState.INIT ||
  flowState === LocationDetailsFlowState.LOCATION_UPDATED ||
  flowState === LocationDetailsFlowState.BASIC_CONFIG_UPDATED;

export interface LocationDetailsMatchParams {
  cpoCountryCode: string;
  cpoPartyId: string;
  locationId: string;
}

const LocationsDetails: FC = () => {
  const { state, addEvent } = useLocations();

  const detailsMatch = useRouteMatch<LocationDetailsMatchParams>(paths.LOCATION_DETAILS);
  const chargersMatch = useRouteMatch<LocationDetailsMatchParams>(paths.LOCATION_CHARGERS);
  const usersMatch = useRouteMatch<LocationDetailsMatchParams>(paths.LOCATION_USERS);
  const adminsMatch = useRouteMatch<LocationDetailsMatchParams>(paths.LOCATION_ADMINS);

  const match = detailsMatch || chargersMatch || usersMatch || adminsMatch;
  const locationId = match?.params.locationId;
  const isInternal = match?.params.cpoCountryCode === 'NZ' && match.params?.cpoPartyId === 'OPL';

  useEffect(() => {
    if (routeDirectlyAccessed(detailsMatch?.isExact, state.flowState) || pageLoadingFromRedirection(state.flowState)) {
      addEvent({ type: isInternal ? LocationEvent.LOAD_LOCATION : LocationEvent.LOAD_EXTERNAL_LOCATION, payload: { id: locationId } });
    }
  }, [isInternal, state.flowState, addEvent, locationId, detailsMatch?.isExact]);

  return (
    <Dashboard pageHeading={state.emspLocation?.name}>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        {state.emspLocation && <LocationTabs emspLocation={state.emspLocation} />}
      </LoadingContainerWithErrorPanel>
    </Dashboard>
  );
};

export default requiresAdminPermission(AdminPermission.LOCATION_ADMIN)(withLocations(LocationsDetails));
