import * as Yup from 'yup';

const nameValidation = Yup.string();
const descriptionValidation = Yup.string();
const addressValidation = Yup.string();
const cityValidation = Yup.string();
const countryValidation = Yup.string();
const postCodeValidation = Yup.number().max(10000);
const stateValidation = Yup.string();

const latitudeValidation = Yup.string().matches(/^-?([0-9]{1,2})\.([0-9]{5,7})$/, 'Should be in valid latitude format e.g. -36.848461');
const longitudeValidation = Yup.string().matches(/^-?([0-9]{1,3})\.([0-9]{5,7})$/, 'Should be in valid longitude format e.g 174.763336');
const publicValidation = Yup.boolean();

export const validationSchema = Yup.object({
  name: nameValidation,
  description: descriptionValidation,
  address: addressValidation,
  city: cityValidation,
  country: countryValidation,
  postCode: postCodeValidation,
  state: stateValidation,
  latitude: latitudeValidation,
  longitude: longitudeValidation,
  public: publicValidation
});
