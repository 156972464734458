import { Divider, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import PaperWithTitle from '../../commons/containers/PaperWithTile/PaperWithTitle';
import HasPermission from '../../commons/permissions/HasPermission';
import { tableIcons } from '../../commons/table/tableIcons';
import mainTheme from '../../themes/mainTheme';
import { Permission } from '../../types/user/UserPermission';
import dateTimeFormatter from '../../utils/date/dateTimeFormatter';
import { roundNumberToCurrencyAndFormatToFixed } from '../../utils/transaction/transactionUtils';

const TransactionInfo = ({ transaction, onStop, onRetryPayment }) => {
  const useStyles = makeStyles((theme) => ({
    bold: {
      fontWeight: 600
    },
    row: {
      gap: theme.spacing(1)
    },
    divider: {
      margin: `${theme.spacing(2)}px 0`
    },
    alert: {
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3)
    },
    activeTransactionAlert: {
      backgroundColor: theme.palette.sessionStatus.active,
      color: theme.palette.primary.contrastText
    },
    activeIcon: {
      marginTop: 12,
      color: theme.palette.primary.contrastText,
      '& div': {
        width: theme.spacing(0.25),
        height: theme.spacing(0.25)
      },
      '& div:first-child': {
        display: 'none'
      }
    }
  }));

  const classes = useStyles(mainTheme);

  const buildCostBreakdown = () => {
    const fee = [];
    if (transaction.tariff.ratePerKwh) {
      fee.push({
        rate: `$${roundNumberToCurrencyAndFormatToFixed(transaction.tariff.ratePerKwh, 2)}/kWh`,
        usage: transaction.session.kwh,
        cost: `${roundNumberToCurrencyAndFormatToFixed(transaction.payment.kwhFeeTotal, 2)}`
      });
    }

    if (transaction.tariff.ratePerMinute) {
      fee.push({
        rate: `$${roundNumberToCurrencyAndFormatToFixed(transaction.tariff.ratePerMinute, 2)}/min`,
        usage: dateTimeFormatter.printReadableTimeFromSeconds(transaction.session.durationInSeconds),
        cost: `${roundNumberToCurrencyAndFormatToFixed(transaction.payment.minuteFeeTotal, 2)}`
      });
    }

    if (transaction.tariff.unlockFee) {
      fee.push({
        rate: `$${roundNumberToCurrencyAndFormatToFixed(transaction.tariff.unlockFee, 2)} unlock`,
        usage: '',
        cost: `${roundNumberToCurrencyAndFormatToFixed(transaction.payment.unlockFeeTotal, 2)}`
      });
    }
    return fee;
  };

  const isActive = transaction.status === 'ACTIVE';
  const isCompleted = transaction.status === 'COMPLETED';
  const isPaymentFailed = transaction.status === 'PAYMENT_FAILED';

  // TODO: After V4 transaction endpoint is integrated, we can use totalPaid instead of calculating the total cost using the payment components
  const totalCost = isActive
    ? transaction.payment.kwhFeeTotal + transaction.payment.minuteFeeTotal + transaction.payment.unlockFeeTotal
    : transaction.payment.total;

  // We need to determine whether the transaction is free or not to display the cost breakdown so we check if there are tariff components
  const isFree = () => !transaction.tariff.ratePerKwh && !transaction.tariff.ratePerMinute && !transaction.tariff.unlockFee;

  // We display FREE if the total cost is 0, otherwise we display the total cost
  const buildTotalCost = () => (totalCost === 0 ? 'FREE' : `$${roundNumberToCurrencyAndFormatToFixed(totalCost, 2)}`);

  const shouldShowPaymentBreakdown =
    isCompleted &&
    (transaction.paymentBreakdown.prepaidAmount !== 0 ||
      transaction.paymentBreakdown.creditCardPayment !== 0 ||
      transaction.paymentBreakdown.postpayAmount !== 0);
  
  const stopButton =
    isActive ? (
      <HasPermission permission={Permission.REMOTE_STOP_TRANSACTION}>
        <Button variant="contained" color="secondary" onClick={onStop}>
          Stop
        </Button>
      </HasPermission>
    ) : (
      <></>
    );
  
  const retryPaymentButton =
    isPaymentFailed ? (
      <HasPermission permission={Permission.RETRY_FAILED_PAYMENT}>
        <Button variant="contained" color="secondary" onClick={onRetryPayment}>
          Retry Payment
        </Button>
      </HasPermission>
    ) : <></>

  return (
    <PaperWithTitle title={`Id: ${transaction.id}`} topRightComponent={<>{stopButton}{retryPaymentButton}</>}>
      <>
        {isActive && (
          <>
            <Alert
              className={[classes.alert, classes.activeTransactionAlert]}
              severity="info"
              icon={<Spinner className={classes.activeIcon} name="ball-clip-rotate-multiple" fadeIn="none" />}
            >
              Active transaction! Usage and costs are not final.
            </Alert>
          </>
        )}
        
        {isPaymentFailed && (
          <Alert severity="error" className={classes.alert}>
            Payment for this transaction failed when attempting to charge the users card
          </Alert>
        )}

        <Typography className={classes.bold}>{transaction.location.address}</Typography>
        <Typography className={classes.bold}>{transaction.location.name}</Typography>
        <Typography className={classes.bold}>{transaction.location.owner}</Typography>
        <Typography>Station Id: {transaction.location.id}</Typography>
        <Divider className={classes.divider} />

        <Typography className={classes.bold}>Started charging at:</Typography>
        <Typography>{dateTimeFormatter.printReadableDateTime(transaction.session.startDateTime)}</Typography>
        <Divider className={classes.divider} />

        <Typography>
          <b>Starting state of charge:</b> {transaction.session.startStateOfCharge}%
        </Typography>
        <Typography>
          <b>Ending state of charge:</b> {transaction.session.latestStateOfCharge}%
        </Typography>
        <Divider className={classes.divider} />

        {!isFree() && (
          <>
            <MaterialTable
              icons={tableIcons}
              components={{
                Container: (props) => props.children,
                Toolbar: () => <></>
              }}
              style={{
                padding: '0 16px'
              }}
              columns={[
                { title: 'Rate', field: 'rate', align: 'left' },
                { title: 'Usage', field: 'usage', align: 'left' },
                { title: 'Cost', field: 'cost', type: 'currency', align: 'left' }
              ]}
              data={buildCostBreakdown()}
              options={{
                headerStyle: {
                  color: 'black',
                  fontWeight: 600,
                  padding: `0`,
                  borderBottom: '0'
                },
                cellStyle: {
                  padding: `0`,
                  borderBottom: '0'
                },
                showTitle: false,
                search: false,
                paging: false,
                sorting: false
              }}
            />
            <Divider className={classes.divider} />
          </>
        )}

        <Typography className={classes.bold} align="left">
          Total Cost: {buildTotalCost()}
        </Typography>

        {shouldShowPaymentBreakdown && (
          <>
            <Divider className={classes.divider} />
            {transaction.user.accountName && <Typography align="left">Account Name: {transaction.user.accountName}</Typography>}
            {transaction.paymentBreakdown.prepaidAmount > 0 && (
              <Typography className={classes.bold} align="left">
                Prepaid Amount Charged: ${transaction.paymentBreakdown.prepaidAmount.toFixed(2)}
              </Typography>
            )}
            {transaction.paymentBreakdown.creditCardPayment > 0 && (
              <Typography className={classes.bold} align="left">
                Amount Paid By Credit Card: ${transaction.paymentBreakdown.creditCardPayment.toFixed(2)}
              </Typography>
            )}
            {transaction.paymentBreakdown.postpayAmount > 0 && (
              <Typography className={classes.bold} align="left">
                Postpay Amount Charged: ${transaction.paymentBreakdown.postpayAmount.toFixed(2)}
              </Typography>
            )}
          </>
        )}
      </>
    </PaperWithTitle>
  );
};
TransactionInfo.propTypes = {
  transaction: PropTypes.object.isRequired
};

export default TransactionInfo;
