import { FC } from 'react';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import EditButton from '../../../commons/form/button/EditButton';
import { EmspAccount } from '../../../types/account/Account';

type AccountInfoPanelProps = {
  account: EmspAccount;
  handleEdit: () => void;
  canEdit: boolean;
};
const AccountViewPanel: FC<AccountInfoPanelProps> = ({ account, handleEdit, canEdit }) => (
  <PaperWithTitle title="General" topRightComponent={canEdit ? <EditButton handleEdit={handleEdit} disabled={!canEdit} /> : null}>
    <>
      <TextWithLabel label="Name" text={account.name} />
      <TextWithLabel label="Organanisation Name" text={account.organisationName} />
      <TextWithLabel label="Account Type" text={account.accountType} />
    </>
  </PaperWithTitle>
);

export default AccountViewPanel;
