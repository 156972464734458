import React from 'react';
import Dashboard from '../Dashboard/Dashboard';
import ChargePointList from './ChargePointList';
import { FeatureFlag, requiresFeatureFlag } from '../../hooks/useFeatureFlags';
import paths from '../../routes/paths';

const ChargePoints = () => (
  <Dashboard pageHeading="Charge Points">
    <ChargePointList />
  </Dashboard>
);

export default requiresFeatureFlag(FeatureFlag.LEGACY_CHARGE_POINT_MANAGEMENT, { redirectTo: paths.CHARGERS })(ChargePoints);
