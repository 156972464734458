import React from 'react';
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@material-ui/core';
import * as PropTypes from 'prop-types';

const SmartCheckboxField = ({field, label, helperText, defaultValue, isSubmitting, form, disabled, ...rest}) => (
  <FormControl fullWidth disabled={form.isSubmitting }>
    <FormControlLabel control={<Checkbox checked={field.value} color="primary" {...field} {...rest} disabled={form.isSubmitting || disabled} />} label={label} />
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
);

SmartCheckboxField.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  defaultValue: PropTypes.bool,
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default SmartCheckboxField;
