import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { FC } from 'react';
import NavigationLink from '../../../commons/link/NavigationLink';
import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';
import { AdminPermission, useAdminPermissions } from '../../../hooks/useAdminPermissions';

import { usePermissions } from '../../../hooks/usePermissions';
import { DASHBOARD_PATH } from '../../../routes/paths';
import { EmspAccountUser } from '../../../types/account/Account';
import { Permission } from '../../../types/user/UserPermission';
import ChargeTagStatus from '../../ChargeTag/ChargeTagStatus';

interface AccountUserListTableProps {
  accountUsers?: EmspAccountUser[];
  accountName: string;
  handleAddUserButtonClick?: () => void;
  handleRemoveUserButtonClick?: (user: EmspAccountUser) => void;
}

const AccountUsersListTable: FC<AccountUserListTableProps> = ({
  accountUsers,
  accountName,
  handleAddUserButtonClick,
  handleRemoveUserButtonClick
}) => {
  const { hasPermission } = usePermissions();
  const { hasAdminPermission } = useAdminPermissions();

  return (
    <MaterialTableWrapper
      columns={[
        { title: 'Id', field: 'id', width: 'auto', hidden: true, hiddenByColumnsButton: true, export: true },
        {
          title: 'Name',
          field: 'name',
          width: 'auto',
          defaultSort: 'asc',
          render: (rowData: EmspAccountUser) =>
            hasAdminPermission(AdminPermission.ACCOUNT_ADMIN) ? (
              <NavigationLink path={`${DASHBOARD_PATH}/users/${rowData.id}`} text={rowData.name} />
            ) : (
              rowData.name
            )
        },
        { title: 'Email', field: 'email', width: 'auto' },
        {
          title: 'Charge Tag Id',
          export: false,
          render: (emspAccountUser: EmspAccountUser) => {
            const chargeTag = emspAccountUser.chargeTags.length !== 0 ? emspAccountUser.chargeTags[0] : undefined;
            return chargeTag && <ChargeTagStatus active={chargeTag.isActive} chargeTagId={chargeTag.id} />;
          }
        }
      ]}
      data={accountUsers}
      globalActionButton={
        hasPermission(Permission.MANAGE_ACCOUNT) && {
          name: 'Add Users',
          tooltip: 'Add a new user to the account',
          onClick: handleAddUserButtonClick
        }
      }
      singleActionButton={
        hasPermission(Permission.MANAGE_ACCOUNT) && {
          icon: RemoveCircleIcon,
          tooltip: 'Remove user from the account',
          onClick: handleRemoveUserButtonClick
        }
      }
      exportFileName={`account_users_${accountName}`}
    />
  );
};

export default AccountUsersListTable;
