import { CpoLocation, LocationType } from '../../types/location/Location';
import { CpoLocationRequestDto, EmspUpdateLocationDto } from '../../types/location/LocationDto';

type ToCpoLocationRequestDtoConverter = (location: Partial<CpoLocation>) => Partial<CpoLocationRequestDto>;
export const toCpoLocationDto: ToCpoLocationRequestDtoConverter = (location) => {
  return {
    name: location.name,
    address: location.address,
    postal_code: location.postalCode,
    city: location.city,
    country: location.country,
    ...(location.latitude &&
      location.longitude && {
        coordinates: {
          latitude: location.latitude,
          longitude: location.longitude
        }
      }),
    time_zone: location.timeZone,
    state: location.state,
    public: location.public,
    owner_name: location.ownerName,
    visible_to_osps: location.visibleToOsps?.map((osp) => osp.id),
    msp_country_code: location.mspCountryCode,
    msp_party_id: location.mspPartyId,
    cpo_country_code: location.cpoCountryCode,
    cpo_party_id: location.cpoPartyId,
    publish: location.publish,
    id: location.id
  };
};
export const toEmspUpdateLocationDto = (electricityCost: number, locationType: LocationType): EmspUpdateLocationDto => {
  return {
    electricity_cost: electricityCost,
    location_type: locationType
  };
};
