import { Osp, OspDto } from '../../types/Osp';

type ToOspConverter = (ospDto: OspDto) => Osp;

export const toOsp: ToOspConverter = (ospDto) => {
  return {
    id: ospDto.id,
    name: ospDto.name,
    countryCode: ospDto.country_code,
    partyId: ospDto.party_id
  };
};
