import _ from 'lodash';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import { LocationDetailsFlowState } from '../../../types/LocationDetails';
import AdvancedConfigEditView from '../AdvancedConfig/BasicConfigEditForm';
import BasicConfigView from '../AdvancedConfig/BasicConfigView';

const LocationInfo = ({ location }) => {
  return (
    <>
      <TextWithLabel label="Name" text={location.name} />
      <TextWithLabel label="Owner" text={location.owner} />
      <TextWithLabel label="Latitude" text={location.latitude} />
      <TextWithLabel label="Longitude" text={location.longitude} />
      <TextWithLabel label="Address" text={location.address} />
      <TextWithLabel label="City" text={location.city} />
      <TextWithLabel label="State" text={location.state} />
      <TextWithLabel label="Postcode" text={location.postCode} />
      <TextWithLabel label="Access Type" text={_.capitalize(location.locationType)} />
    </>
  );
};

const ExternalPartyLocationViewForm = ({ state, addEvent }) => {
  return (
    <>
      <PaperWithTitle title="General">
        <LoadingContainerWithErrorPanel {...state.loadingState}>
          {state.emspLocation && <LocationInfo location={state.emspLocation} />}
        </LoadingContainerWithErrorPanel>
      </PaperWithTitle>
      {state.flowState === LocationDetailsFlowState.SHOWING_UPDATE_BASIC_CONFIG_FORM ? (
        <AdvancedConfigEditView />
      ) : (
        <LoadingContainerWithErrorPanel {...state.loadingState}>
          <BasicConfigView emspLocation={state.emspLocation} state={state} addEvent={addEvent} />
        </LoadingContainerWithErrorPanel>
      )}
    </>
  );
};

export default ExternalPartyLocationViewForm;
