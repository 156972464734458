import { Field } from 'formik';
import SmartRadioField from '../../../commons/form/SmartRadioField';
import SmartSelectField from '../../../commons/form/SmartSelectField';
import SmartTextField from '../../../commons/form/SmartTextField';
import PageWithColumns from '../../../commons/layout/PageWithColumns';
import { EmspLocation } from '../../../types/location/Location';
import { ChargerMode } from '../../../types/charger/Charger';

interface NewChargerFormProps {
  locationId?: string;
  locations?: EmspLocation[];
}

const ChargerFormInfo: React.FC<NewChargerFormProps> = ({ locationId, locations }) => {
  return (
    <PageWithColumns
      columnOne={
        <>
          <Field label="Ocpp Charge Point Id" name="ocppChargePointIdentifier" autoFocus component={SmartTextField} helperText="Identifier this charge point connects with" />
          <Field label="Name" name="physicalReference" component={SmartTextField} helperText="Charger Id e.g OPL-1007" />
          <Field
            label="Mode"
            name="anonymousChargingAllowed"
            component={SmartRadioField}
            options={[
              { value: 'true', displayValue: ChargerMode.FREE },
              { value: 'false', displayValue: ChargerMode.AUTH }
            ]}
            helperText="Switching modes alters the software settings; when set to FREE, the charger will not identify or authenticate the user that is charging."
          />
        </>
      }
      columnTwo={
        <>
          <Field
            label="Location"
            name="locationId"
            component={SmartSelectField}
            defaultValue={locationId || ''}
            helperText="Select a Location"
            options={locations?.map((location) => ({ value: location.cpoLocationId, displayValue: location.name }))}
          />
        </>
      }
    />
  );
};

export default ChargerFormInfo;
