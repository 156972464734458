import { Field } from 'formik';
import { FC } from 'react';
import SmartMultiSelectField from '../../../commons/form/SmartMultiSelectField';
import SmartSelectField from '../../../commons/form/SmartSelectField';
import SmartTextField from '../../../commons/form/SmartTextField';
import PageWithColumns from '../../../commons/layout/PageWithColumns';
import { Osp } from '../../../types/Osp';
import { EmspOrganisation } from '../../../types/organisation/Organisation';

interface LocationDetailsFormProps {
  formikProps: any;
  organisations: EmspOrganisation[];
  osps: Osp[];
}

const LocationDetailsForm: FC<LocationDetailsFormProps> = ({ formikProps, osps, organisations }) => {
  return (
    <PageWithColumns
      columnOne={
        <>
          <Field label="Name" name="name" autoFocus component={SmartTextField} fullWidth helperText="Enter name of this location" />
          <Field
            label="Access Type"
            name="public"
            component={SmartSelectField}
            options={[
              { value: true, displayValue: 'Public' },
              { value: false, displayValue: 'Private' }
            ]}
            helperText="Select access type for this location"
            fullWidth={true}
          />
        </>
      }
      columnTwo={
        <>
          {organisations && organisations.length > 0 ? (
            <Field
              label="Organisation"
              name="ownerName"
              component={SmartSelectField}
              options={organisations.map((organisation) => {
                return {
                  value: organisation.name,
                  displayValue: organisation.name
                };
              })}
              helperText="Select organisation for this location"
              fullWidth={true}
            />
          ) : null}
          {formikProps.values.public && osps && osps.length > 0 ? (
            <Field
              label="Roaming Partners"
              name="visibleToOsps"
              component={SmartMultiSelectField}
              options={osps.map((msp) => {
                return {
                  id: msp.id,
                  value: msp.name
                };
              })}
              multiple={true}
              allowNull={true}
              helperText="Select all roaming partners this location should be visible to."
              fullWidth={true}
            />
          ) : null}
        </>
      }
    />
  );
};

export default LocationDetailsForm;
