import moment from 'moment/moment';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { ProcessingBar } from '../../../commons/snackbars/snackbars';
import { logType } from '../../../domain/logType';
import ChargePointLogsTable from '../../ChargePointLogs/ChargePointLogsTable/ChargePointLogsTable';
import useChargePointLogs, { ChargePointLogsEvent, ChargePointLogsFlowState } from '../../ChargePointLogs/hook/useChargePointLogs';
import { withChargePointLogs } from '../../ChargePointLogs/hook/withChargePointLogs';

const ChargePointLogsPanel = ({ chargePointId }) => {
  const { state, addEvent } = useChargePointLogs();
  useEffect(() => {
    if (state.flowState === ChargePointLogsFlowState.INIT) {
      addEvent({
        type: ChargePointLogsEvent.PANEL_ENTERED,
        payload: {
          chargePointId: chargePointId,
          dateRange: {
            dateTimeFrom: moment().subtract(30, 'minutes'),
            dateTimeTo: moment()
          },
          logType: logType.PRETTY
        }
      });
    }
  }, [state.flowState, chargePointId, addEvent]);

  const logOptions = [
    { displayValue: 'All Logs', value: logType.ALL },
    { displayValue: 'Raw Charge Point Logs', value: logType.RAW },
    { displayValue: 'Formatted Logs', value: logType.PRETTY }
  ];

  const onFilterApply = (values) => {
    addEvent({
      type: ChargePointLogsEvent.APPLY_FILTER_CRITERIA,
      payload: {
        chargePointId: chargePointId,
        dateRange: {
          dateTimeFrom: moment(values.dateTimeFrom),
          dateTimeTo: moment(values.dateTimeTo)
        },
        logType: values.option
      }
    });
  };

  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        <ChargePointLogsTable
          logs={state.logs}
          dateTimeRangeFilter={{
            dateRange: state.dateRange,
            maxRangeInDays: 7,
            onFilterApply: onFilterApply,
            options: logOptions,
            defaultOption: state.logType
          }}
          isPartialLoad={state.partialLoad}
        />
      </LoadingContainerWithErrorPanel>
      <ProcessingBar open={state.flowState === ChargePointLogsFlowState.LOADING_LOGS_TABLE} text={'Loading charge point events... Please wait...'} />
    </>
  );
};

ChargePointLogsPanel.propTypes = {
  chargePointId: PropTypes.string.isRequired
};

export default withChargePointLogs(ChargePointLogsPanel);
