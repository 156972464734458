import _ from 'lodash';
import { FC, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import FormDialog from '../../../commons/dialogs/FormDialog';
import { WarningDialog } from '../../../commons/dialogs/dialogs';
import { ErrorBar, ProcessingBar, SuccessBar, WarningBar } from '../../../commons/snackbars/snackbars';
import paths from '../../../routes/paths';
import { LocationUser } from '../../../types/location/user/LocationUser';
import { LocationUsersEvent, LocationUsersFlowState } from '../../../types/location/user/LocationUserState';
import AddUserToLocationForm from './NewUser/AddUserToLocationForm';
import UsersTableForLocation from './UsersTableForLocation';
import useLocationUsers from './hook/useLocationUsers';
import { withLocationUsers } from './hook/withLocationUsers';

interface LocationUsersPanelProps {
  locationId: string;
}

const LocationUsersPanel: FC<LocationUsersPanelProps> = ({ locationId }) => {
  const { state, addEvent } = useLocationUsers();
  const usersMatch = useRouteMatch<LocationUsersPanelProps>(paths.LOCATION_USERS);

  useEffect(() => {
    if (
      (usersMatch?.isExact && state.flowState === LocationUsersFlowState.INIT) ||
      state.flowState === LocationUsersFlowState.USER_ADDED_TO_LOCATION
    ) {
      addEvent({ type: LocationUsersEvent.LOAD_USERS_FOR_LOCATION, payload: { locationId } });
    }
  });

  const showAddUserToLocationDialog = _.includes(
    [LocationUsersFlowState.SHOWING_ADD_USER_DIALOG, LocationUsersFlowState.FAILED_TO_ADD_USER, LocationUsersFlowState.ADDING_USER_TO_LOCATION],
    state.flowState
  );

  const handleAddUserToLocation = (email: string) => {
    addEvent({ type: LocationUsersEvent.SUBMIT_ADD_USER_DIALOG_TO_LOCATION_REQUESTED, payload: { locationId, email } });
  };

  const handleAddUser = () => {
    addEvent({ type: LocationUsersEvent.SHOW_ADD_USER_DIALOG });
  };

  const handleRemoveUser = (user: LocationUser) => {
    addEvent({ type: LocationUsersEvent.SHOW_REMOVE_USER_FROM_LOCATION_WARNING, payload: { user } });
  };

  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        <UsersTableForLocation users={state.users} handleAddUser={handleAddUser} handleRemoveUser={handleRemoveUser} />
        <FormDialog
          open={showAddUserToLocationDialog}
          onClose={() => addEvent({ type: LocationUsersEvent.CLOSE_ADD_USER_DIALOG })}
          title="Add User"
          fullPage={false}
        >
          <AddUserToLocationForm
            handleAddUserToLocation={handleAddUserToLocation}
            hasFailed={state.flowState === LocationUsersFlowState.FAILED_TO_ADD_USER}
          />
        </FormDialog>
      </LoadingContainerWithErrorPanel>
      {/* Remove User Warning */}
      <WarningDialog
        fullPage={false}
        open={_.includes([LocationUsersFlowState.SHOWING_REMOVE_USER_FROM_LOCATION_WARNING], state.flowState)}
        buttonLabel="Remove user"
        buttonCallback={() => {
          if (state.userToRemove)
            addEvent({
              type: LocationUsersEvent.REMOVE_USER_FROM_LOCATION,
              payload: { userId: state.userToRemove?.id, locationId: usersMatch?.params.locationId }
            });
        }}
        onClose={() => addEvent({ type: LocationUsersEvent.CLOSE_REMOVE_USER_FROM_LOCATION_WARNING })}
      >
        Are you sure you want to remove {state.userToRemove?.name} from this location?
      </WarningDialog>
      <ErrorBar fullPage={false} open={!_.isEmpty(state.errorMessage)} onClose={() => addEvent({ type: LocationUsersEvent.CLEAR_ERROR })}>
        {state.errorMessage}
      </ErrorBar>
      <ProcessingBar open={state.flowState === LocationUsersFlowState.ADDING_USER_TO_LOCATION} text="Adding User" fullPage={false} />

      <SuccessBar open={state.userAdded || state.userRemoved} fullPage={false} onClose={() => addEvent({ type: LocationUsersEvent.CLEAR_SUCCESS })}>
        User has been {state.userAdded ? 'added' : 'removed'}
      </SuccessBar>
      <ProcessingBar
        open={
          state.flowState === LocationUsersFlowState.ADDING_USER_TO_LOCATION || state.flowState === LocationUsersFlowState.REMOVING_USER_FROM_LOCATION
        }
        fullPage={false}
      />
      <WarningBar open={!_.isEmpty(state.warningMessage)} fullPage={false} onClose={() => addEvent({ type: LocationUsersEvent.CLEAR_WARNING })}>
        {state.warningMessage}
      </WarningBar>
      <ErrorBar open={!_.isEmpty(state.errorMessage)} fullPage={false} onClose={() => addEvent({ type: LocationUsersEvent.CLEAR_ERROR })}>
        {state.errorMessage}
      </ErrorBar>
    </>
  );
};

export default withLocationUsers(LocationUsersPanel);
