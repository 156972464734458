import PropTypes from 'prop-types';
import NotFoundPage from '../../../components/GenericNotFound/GenericNotFound';
import { ErrorBar } from '../../snackbars/snackbars';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import PanelLoadingIndicator from '../PanelLoadingIndicator';

const LoadingContainerWithErrorPanel = ({ loading, hasError, errorMessage, children, status }) => {
  const genericErrorMessage = errorMessage ? errorMessage : "Sorry, we can't find that page";

  //placed before the loading icon to avoid flickering
  if (status === 404 || status === 403) {
    return <NotFoundPage errorMessage={genericErrorMessage} />;
  }

  if (loading) {
    return <PanelLoadingIndicator />;
  }

  if (hasError) {
    return (
      <>
        <ErrorContainer message={errorMessage} />
        <ErrorBar open={hasError}>{errorMessage}</ErrorBar>
      </>
    );
  }

  return <>{children}</>;
};

LoadingContainerWithErrorPanel.propTypes = {
  loading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  status: PropTypes.number || null
};

export default LoadingContainerWithErrorPanel;
