import { useEffect } from 'react';
import { useLocation } from 'react-router';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { SuccessBar } from '../../../commons/snackbars/snackbars';
import { ChargersFlowState } from '../../../types/charger/ChargerState';
import Dashboard from '../../Dashboard/Dashboard';
import useChargers, { ChargerEvent } from '../useChargers';
import withChargers from '../withChargers';
import ChargersTable from './ChargersTable';
import { AdminPermission, requiresAdminPermission } from '../../../hooks/useAdminPermissions';

interface LocationState {
  showChargerCreatedSuccessBar: boolean;
  showChargerDecommissionedSuccessBar: boolean;
}

const ChargersList = () => {
  const { state, addEvent } = useChargers();
  const location = useLocation<LocationState>();

  useEffect(() => {
    if (state.flowState === ChargersFlowState.INIT) {
      addEvent({ type: ChargerEvent.LOAD_CHARGERS });
    }
  }, [state.flowState, addEvent]);

  return (
    <Dashboard pageHeading="Chargers">
      <>
        <LoadingContainerWithErrorPanel {...state.loadingState}>
          <ChargersTable chargers={state.chargers} showAddCharger={true} />
        </LoadingContainerWithErrorPanel>
        <SuccessBar
          open={location?.state?.showChargerCreatedSuccessBar}
          fullPage={false}
          onClose={() => window.history.replaceState({ showChargerCreatedSuccessBar: false }, '')}
        >
          New Charger has been created!
        </SuccessBar>
        <SuccessBar
          open={location?.state?.showChargerDecommissionedSuccessBar}
          fullPage={false}
          onClose={() => window.history.replaceState({ showChargerDecommissionedSuccessBar: false }, '')}
        >
          Charger has been decommissioned!
        </SuccessBar>
      </>
    </Dashboard>
  );
};

export default requiresAdminPermission(AdminPermission.LOCATION_ADMIN)(withChargers(ChargersList));
