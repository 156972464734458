import { Box } from '@material-ui/core';
import _ from 'lodash';
import MaterialTable, { MTableAction, MTableToolbar } from 'material-table';
import * as PropTypes from 'prop-types';
import mainTheme from '../../themes/mainTheme';
import ActionMenu from './ActionMenu';
import CreateActionButton from './CreateActionButton';
import DateRangePicker from './DateRangePicker';
import DateTimeRangePicker from './DateTimeRangePicker';
import { PatchedPagination } from './PatchedPagination';
import { tableIcons } from './tableIcons';

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

// IMPORTANT: DO NOT REMOVE ANY FIELDS HERE
// The action has to conform to this shape {icon, tooltip, onClick}
const rowActions = {
  icon: '',
  tooltip: '',
  onClick: () => {}
};

const globalActions = {
  icon: '',
  tooltip: '',
  onClick: () => {},
  isFreeAction: true
};

// FIXME: Update the default values for the props as more components are converted to typescript.
const MaterialTableWrapper = ({
  filtering = undefined,
  paginationType = undefined,
  searchText = undefined,
  debounceInterval = undefined,
  pageSize = ROWS_PER_PAGE_OPTIONS[0],
  exportFileName,
  dateRangeFilter = undefined,
  dateTimeRangeFilter = undefined,
  globalActionButton = {},
  moreActionsButton = undefined,
  singleActionButton = {},
  components = undefined,
  pageSizeOptions = ROWS_PER_PAGE_OPTIONS,
  emptyDataMessage = 'No records to display',
  ...rest
}) => {
  const getActions = (singleActionButton, moreActionsButton, globalActionButton) => {
    if (!_.isEmpty(singleActionButton) && moreActionsButton) {
      throw new Error('singleActionButton can not be used together with moreActionsButton');
    }
    return _.concat(singleActionButton || moreActionsButton ? [rowActions] : [], globalActionButton ? [globalActions] : []);
  };

  const defaultComponents = {
    Toolbar: (props) => (
      <>
        <MTableToolbar {...props} />
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
          {dateRangeFilter && <DateRangePicker dateRangeFilter={dateRangeFilter} />}
          {dateTimeRangeFilter && <DateTimeRangePicker dateTimeRangeFilter={dateTimeRangeFilter} />}
        </Box>
      </>
    ),
    Pagination: (props) => <PatchedPagination {...props} />,
    ...components
  };

  const actionComponent = {
    Action: (props) => {
      const isGlobalAction = props.action.isFreeAction;

      const globalActions = () => <>{globalActionButton && !_.isEmpty(globalActionButton) && <CreateActionButton {...globalActionButton} />}</>;

      const rowActions = () => (
        <>
          {moreActionsButton && (
            <ActionMenu
              // If the action has a "getVisible" property, we use that to determine whether to show the action or not
              actions={moreActionsButton?.actions
                ?.filter((action) => (action.getVisible ? action.getVisible(props.data) : true))
                .map((action) => ({
                  name: action.name,
                  onClick: () => action.onClick(props.data)
                }))}
            />
          )}
          {!_.isEmpty(singleActionButton) && (
            <MTableAction action={{ ...singleActionButton, onClick: () => singleActionButton.onClick(props.data) }} />
          )}
        </>
      );

      return isGlobalAction ? globalActions() : rowActions();
    }
  };

  const getComponents = () => {
    return rest.editable ? defaultComponents : Object.assign({}, defaultComponents, actionComponent);
  };

  return (
    <MaterialTable
      style={{
        paddingTop: mainTheme.spacing(1)
      }}
      icons={tableIcons}
      options={{
        headerStyle: {
          backgroundColor: mainTheme.palette.table.header,
          fontWeight: 500,
          zIndex: 0
        },
        searchAutoFocus: true,
        searchFieldAlignment: 'left',
        searchFieldStyle: {
          color: mainTheme.palette.primary.dark,
          marginTop: mainTheme.spacing(2),
          marginBottom: mainTheme.spacing(2)
        },
        showTitle: false,
        pageSize: pageSize,
        pageSizeOptions: pageSizeOptions,
        exportButton: true,
        exportAllData: true,
        exportFileName: exportFileName,
        columnsButton: true,
        filtering: filtering,
        debounceInterval: debounceInterval,
        searchText: searchText,
        paginationType: paginationType,
        emptyRowsWhenPaging: false,
        thirdSortClick: false,
        actionsColumnIndex: -1
      }}
      localization={{
        body: {
          emptyDataSourceMessage: emptyDataMessage
        },
        header: {
          actions: ''
        }
      }}
      {...rest}
      // in order to show action menu, actions needs to be set to rowActions
      actions={getActions(singleActionButton, moreActionsButton, globalActionButton)}
      components={getComponents()}
    />
  );
};
MaterialTableWrapper.defaultProps = {
  filtering: true,
  paginationType: 'normal',
  pageSize: ROWS_PER_PAGE_OPTIONS[0],
  pageSizeOptions: ROWS_PER_PAGE_OPTIONS
};

MaterialTableWrapper.propTypes = {
  filtering: PropTypes.bool,
  paginationType: PropTypes.string,
  pageSize: PropTypes.number,
  exportFileName: PropTypes.string.isRequired,
  debounceInterval: PropTypes.number,
  searchText: PropTypes.string,
  dateRangeFilter: PropTypes.object,
  dateTimeRangeFilter: PropTypes.object,
  globalActionButton: PropTypes.object,
  moreActionsButton: PropTypes.object,
  singleActionButton: PropTypes.object,
  search: PropTypes.bool,
  pageSizeOptions: PropTypes.array
};

export default MaterialTableWrapper;
