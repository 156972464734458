import * as React from 'react';
import { ChargerTransactionsProvider } from './ChargerTransactionsProvider';

interface WithChargerTransactionsProps {
  (Component: React.FC<any>): (props: any) => JSX.Element;
}

export const withChargerTransactions: WithChargerTransactionsProps = (Component) => (props) =>
  (
    <ChargerTransactionsProvider>
      <Component {...props} />
    </ChargerTransactionsProvider>
  );
