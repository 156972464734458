import React from 'react';
import { Box, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface ConnectorActionButtonProps {
  handleAddConnector: () => void;
  isEvse: boolean;
}

export const ConnectorActionButtonsContainer: React.FC<ConnectorActionButtonProps> = ({ handleAddConnector, isEvse }) => {
  return (
    <Box>
      <Button type="button" variant="outlined" color="primary" onClick={handleAddConnector} startIcon={<AddCircleIcon />}>
        Add {isEvse ? 'Evse' : 'Connector'}
      </Button>
    </Box>
  );
};
