import { FC } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import ValueWithUnit from '../../../commons/texts/ValueWithUnit';
import transactionInsightService from '../../../services/transactionInsightService';
import mainTheme from '../../../themes/mainTheme';
import ChargePointInsightBarGraph from './ChargePointInsightBarGraphs';

interface ChargePointElectricityCostProps {
  loadingTransactionsState: any;
  transactions: any;
  lastNumberOfDays: number;
}

const ChargePointElectricityCost: FC<ChargePointElectricityCostProps> = ({ loadingTransactionsState, transactions, lastNumberOfDays }) => (
  <PaperWithTitle
    title="Electricity Cost"
    topRightComponent={
      <ValueWithUnit value={transactionInsightService.calculateTotalElectricityCost(transactions).toString()} unit="NZD" textAlign="right" />
    }
  >
    <LoadingContainerWithErrorPanel {...loadingTransactionsState}>
      <ChargePointInsightBarGraph
        dataset={transactionInsightService.buildElectricityCostData(transactions, lastNumberOfDays)}
        color={mainTheme.palette.chart.electricityCost}
      />
    </LoadingContainerWithErrorPanel>
  </PaperWithTitle>
);

export default ChargePointElectricityCost;
