import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import HasPermission from '../../../commons/permissions/HasPermission';
import { ErrorBar, ProcessingBar, SuccessBar } from '../../../commons/snackbars/snackbars';
import mainTheme from '../../../themes/mainTheme';
import { Permission } from '../../../types/user/UserPermission';
import ConnectorPanels from './ConnectorPanels';
import EditCentralServerConnectorsForm from './EditCentralServerConnectorsForm';
import ResetChargePointForm from './ResetChargePointForm';
import useCentralServerChargePoints, { CentralServerChargePointsFlowState } from './hook/useCentralServerChargePoints';

const useStyles = makeStyles((theme) => ({
  remoteStartTransaction: {
    backgroundColor: theme.palette.remoteStartButton.active,
    '&:hover': {
      backgroundColor: theme.palette.remoteStartButton.hover
    }
  }
}));

const CentralServerConnectors = ({ locationId, evses, onChargePointReset, onChargePointEdit, handleRemoteStart }) => {
  const classes = useStyles(mainTheme);
  const { state } = useCentralServerChargePoints();
  const hasAtLeastOneAvailableConnector = state.chargePoint.evses.some((evse) => evse.status === 'AVAILABLE');

  const ConnectorActions = () => (
    <>
      <Button variant="contained" color="secondary" onClick={onChargePointReset}>
        Reset
      </Button>
      <HasPermission permission={Permission.REMOTE_START_TRANSACTION}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRemoteStart}
          className={classes.remoteStartTransaction}
          disabled={!hasAtLeastOneAvailableConnector}
        >
          Remote Start
        </Button>
      </HasPermission>
      <HasPermission permission={Permission.UPDATE_CHARGE_POINT_DETAILS}>
        <Button variant="contained" color="primary" onClick={onChargePointEdit}>
          Edit
        </Button>
      </HasPermission>
    </>
  );

  return state.flowState === CentralServerChargePointsFlowState.SHOWING_EDIT_CHARGE_POINT_CONNECTORS ? (
    <EditCentralServerConnectorsForm locationId={locationId} />
  ) : (
    <>
      <PaperWithTitle title="Connectors" topRightComponent={<ConnectorActions />}>
        <>
          <ConnectorPanels evses={evses} />
          <ResetChargePointForm />
        </>
      </PaperWithTitle>
      <ProcessingBar
        open={state.flowState === CentralServerChargePointsFlowState.SUBMITTING_UPDATE_CONNECTORS_FORM}
        text={'Connectors are being updated..'}
      />
      <ErrorBar open={!_.isNull(state.updateConnectorsErrorMessage)}>{state.updateConnectorsErrorMessage}</ErrorBar>
      <SuccessBar open={state.connectorsUpdated}>Connectors updated!</SuccessBar>
    </>
  );
};

CentralServerConnectors.propTypes = {
  locationId: PropTypes.string.isRequired,
  evses: PropTypes.array.isRequired,
  onChargePointReset: PropTypes.func.isRequired,
  onChargePointEdit: PropTypes.func.isRequired,
  handleRemoteStart: PropTypes.func.isRequired
};
export default CentralServerConnectors;
