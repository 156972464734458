import moment from 'moment';

const dateTimeFormatter = {
  printReadableDateTime: (date) => {
    if (date == null) return;
    return moment(date).local().format('DD MMM YY, hh:mm A');
  },
  printReadableTimeFromSeconds: (seconds) => (seconds >= 3600 ? moment.utc(seconds * 1000).format('HH:mm:ss') : moment.utc(seconds * 1000).format('mm:ss')),
};

export default dateTimeFormatter;
