import { AccountType, EmspAccount, EmspAccountAdmin, EmspAccountUser, EmspAccountUserChargeTag } from '../../types/account/Account';
import { EmspAccountAdminDto, EmspAccountDto, EmspAccountUserChargeTagDto, EmspAccountUserDto } from '../../types/account/AccountDto';

export const toEmspAccount = (response: EmspAccountDto): EmspAccount => {
  return {
    id: response.id,
    name: response.name,
    organisationName: response.organisation_name,
    accountType: response.account_type as AccountType,
    numberOfAdmins: response.number_of_admins,
    numberOfUsers: response.number_of_users
  };
};

export const toEmspAccountAdmin = (response: EmspAccountAdminDto): EmspAccountAdmin => {
  return {
    id: response.id,
    name: response.name,
    email: response.email
  };
};

export const toEmspAccountUserChargeTag = (chargeTag: EmspAccountUserChargeTagDto): EmspAccountUserChargeTag => {
  return {
    id: chargeTag.id,
    isActive: chargeTag.is_active
  };
};

export const toEmspAccountUser = (response: EmspAccountUserDto): EmspAccountUser => {
  return {
    id: response.id,
    name: response.name,
    email: response.email,
    chargeTags: response.charge_tags.map((chargeTag) => toEmspAccountUserChargeTag(chargeTag))
  };
};
