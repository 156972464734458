import { createContext, useContext, useReducer } from 'react';

import _ from 'lodash';
import locationService from '../../../services/locationService';

// events
export const NewChargePointEvent = {
  REVIEW_NEW_CHARGE_POINT_FORM_REQUESTED: 'REVIEW_NEW_CHARGE_POINT_FORM_REQUESTED',
  SUBMIT_NEW_CHARGE_POINT_FORM_REQUESTED: 'SUBMIT_NEW_CHARGE_POINT_FORM_REQUESTED',
  REVIEW_NEW_CHARGE_POINT_FORM_CANCELLED: 'REVIEW_NEW_CHARGE_POINT_FORM_CANCELLED',
};

// flow states
export const NewChargePointFlowState = {
  ENTERING_NEW_CHARGE_POINT_DETAILS: 'ENTERING_NEW_CHARGE_POINT_DETAILS',
  REVIEWING_NEW_CHARGE_POINT_FORM: 'REVIEWING_NEW_CHARGE_POINT_FORM',
  SUBMITTING_CHARGE_POINT_FORM: 'SUBMITTING_CHARGE_POINT_FORM',
  NEW_CHARGE_POINT_CREATED: 'NEW_CHARGE_POINT_CREATED'
};

// initial state
const initialState = {
  flowState: NewChargePointFlowState.ENTERING_NEW_CHARGE_POINT_DETAILS,
  newChargePoint: null,
  createChargePointErrorMessage: null
};

// reducer
const reducer = (state, newState) => ({ ...state, ...newState });

// context
const NewChargePointContext = createContext();

// provider
export const NewChargePointProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <NewChargePointContext.Provider value={{ state, dispatch }}>{children}</NewChargePointContext.Provider>
  );
};

// hook
const useNewChargePoint = () => {
  const { state, dispatch } = useContext(NewChargePointContext);

  const addEvent = (event) => {
    switch (event.type) {
      case NewChargePointEvent.REVIEW_NEW_CHARGE_POINT_FORM_REQUESTED:
        dispatch({
          ...state,
          flowState: NewChargePointFlowState.REVIEWING_NEW_CHARGE_POINT_FORM,
          newChargePoint: { ...event.payload },
          createChargePointErrorMessage: null
        });
        break;

      case NewChargePointEvent.SUBMIT_NEW_CHARGE_POINT_FORM_REQUESTED:
        // Call service method to create location/charge point
        dispatch({
          ...state,
          flowState: NewChargePointFlowState.SUBMITTING_CHARGE_POINT_FORM,
          createChargePointErrorMessage: null
        });

        locationService.createLocation(state.newChargePoint).subscribe(
          () =>
            dispatch({
              ...state,
              flowState: NewChargePointFlowState.NEW_CHARGE_POINT_CREATED,
              createChargePointErrorMessage: null
            }),
          (error) =>
            dispatch({
              ...state,
              flowState: NewChargePointFlowState.REVIEWING_NEW_CHARGE_POINT_FORM,
              createChargePointErrorMessage: `An error occurred: ${error.message}`,
            })
        );
        break;
      case NewChargePointEvent.REVIEW_NEW_CHARGE_POINT_FORM_CANCELLED:
        dispatch({
          ...state,
          flowState: NewChargePointFlowState.ENTERING_NEW_CHARGE_POINT_DETAILS,
          createChargePointErrorMessage: null
        });
        break;
      default:
        console.log(event);
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
  };
};

export default useNewChargePoint;
