import _ from 'lodash';
import { FC, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import FormDialog from '../../../commons/dialogs/FormDialog';
import { WarningDialog } from '../../../commons/dialogs/dialogs';
import { ErrorBar, ProcessingBar, SuccessBar, WarningBar } from '../../../commons/snackbars/snackbars';
import { AdminPermission, useAdminPermissions } from '../../../hooks/useAdminPermissions';
import paths from '../../../routes/paths';
import { LocationAdminsEvent, LocationAdminsFlowState } from '../../../types/location/admin/LocationAdminState';
import { LocationUser } from '../../../types/location/user/LocationUser';
import AdminsTableForLocation from './AdminsTableForLocation';
import AddAdminToLocationForm from './NewAdmin/AddAdminToLocationForm';
import useLocationAdmins from './hook/useLocationAdmins';
import { withLocationAdmins } from './hook/withLocationAdmins';

type LocationAdminsPanelProps = {
  locationId: string;
};

const LocationAdminsPanel: FC<LocationAdminsPanelProps> = ({ locationId }) => {
  const { state, addEvent } = useLocationAdmins();
  const adminsMatch = useRouteMatch<LocationAdminsPanelProps>(paths.LOCATION_ADMINS);
  const { hasAdminPermission } = useAdminPermissions();

  useEffect(() => {
    if (
      (adminsMatch?.isExact && state.flowState === LocationAdminsFlowState.INIT) ||
      state.flowState === LocationAdminsFlowState.ADMIN_ADDED_TO_LOCATION
    ) {
      addEvent({ type: LocationAdminsEvent.LOAD_ADMINS_FOR_LOCATION, payload: { locationId } });
    }
  });

  const showAddUserToLocationDialog = _.includes(
    [LocationAdminsFlowState.SHOWING_ADD_ADMIN_DIALOG, LocationAdminsFlowState.FAILED_TO_ADD_ADMIN, LocationAdminsFlowState.ADDING_ADMIN_TO_LOCATION],
    state.flowState
  );

  const handleAddAdminToLocation = (email: string) => {
    addEvent({ type: LocationAdminsEvent.SUBMIT_ADD_ADMIN_DIALOG_TO_LOCATION_REQUESTED, payload: { locationId, email } });
  };

  const handleAddAdmin = () => {
    addEvent({ type: LocationAdminsEvent.SHOW_ADD_ADMIN_DIALOG });
  };

  const handleRemoveAdmin = (adminUser: LocationUser) => {
    addEvent({ type: LocationAdminsEvent.SHOW_REMOVE_ADMIN_FROM_LOCATION_WARNING, payload: { adminUser } });
  };

  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        <AdminsTableForLocation
          admins={state.admins}
          handleAddAdmin={handleAddAdmin}
          handleRemoveAdmin={handleRemoveAdmin}
          hasAdminPermission={hasAdminPermission(AdminPermission.ORG_ADMIN)}
        />
        <FormDialog
          open={showAddUserToLocationDialog}
          onClose={() => addEvent({ type: LocationAdminsEvent.CLOSE_ADD_ADMIN_DIALOG })}
          title="Add Admin"
          fullPage={false}
        >
          <AddAdminToLocationForm
            handleAddAdminToLocation={handleAddAdminToLocation}
            hasFailed={state.flowState === LocationAdminsFlowState.FAILED_TO_ADD_ADMIN}
          />
        </FormDialog>
      </LoadingContainerWithErrorPanel>
      {/* Remove Remove Warning */}
      <WarningDialog
        fullPage={false}
        open={_.includes([LocationAdminsFlowState.SHOWING_REMOVE_ADMIN_FROM_LOCATION_WARNING], state.flowState)}
        buttonLabel="Remove admin"
        buttonCallback={() => {
          if (state.adminToRemove)
            addEvent({
              type: LocationAdminsEvent.REMOVE_ADMIN_FROM_LOCATION,
              payload: { adminUserId: state.adminToRemove?.id, locationId: adminsMatch?.params.locationId }
            });
        }}
        onClose={() => addEvent({ type: LocationAdminsEvent.CLOSE_REMOVE_ADMIN_FROM_LOCATION_WARNING })}
      >
        Are you sure you want to remove {state.adminToRemove?.name} from this location?
      </WarningDialog>
      <ErrorBar fullPage={false} open={!_.isEmpty(state.errorMessage)} onClose={() => addEvent({ type: LocationAdminsEvent.CLEAR_ERROR })}>
        {state.errorMessage}
      </ErrorBar>
      <ProcessingBar open={state.flowState === LocationAdminsFlowState.ADDING_ADMIN_TO_LOCATION} text="Adding Admin" fullPage={false} />

      <SuccessBar
        open={state.adminAdded || state.adminRemoved}
        fullPage={false}
        onClose={() => addEvent({ type: LocationAdminsEvent.CLEAR_SUCCESS })}
      >
        Admin User has been {state.adminAdded ? 'added' : 'removed'}
      </SuccessBar>
      <ProcessingBar
        open={
          state.flowState === LocationAdminsFlowState.ADDING_ADMIN_TO_LOCATION ||
          state.flowState === LocationAdminsFlowState.REMOVING_ADMIN_FROM_LOCATION
        }
        fullPage={false}
      />
      <WarningBar open={!_.isEmpty(state.warningMessage)} fullPage={false} onClose={() => addEvent({ type: LocationAdminsEvent.CLEAR_WARNING })}>
        {state.warningMessage}
      </WarningBar>
      <ErrorBar open={!_.isEmpty(state.errorMessage)} fullPage={false} onClose={() => addEvent({ type: LocationAdminsEvent.CLEAR_ERROR })}>
        {state.errorMessage}
      </ErrorBar>
    </>
  );
};

export default withLocationAdmins(LocationAdminsPanel);
