import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import paths from '../../routes/paths';
import AccountsTable from './AccountsTable';
import useAccounts, { AccountEvent, AccountFlowState } from './hook/useAccounts';
import { withAccounts } from './hook/withAccounts';

const AccountsList: FC = () => {
  const { state, addEvent } = useAccounts();
  const history = useHistory();

  useEffect(() => {
    if (state.flowState === AccountFlowState.INIT) {
      addEvent({ type: AccountEvent.LOAD_ACCOUNTS });
    }
  }, [state.flowState, addEvent]);

  const handleAddAccount = () => {
    history.push(paths.NEW_ACCOUNT);
  };

  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      <AccountsTable accounts={state.accounts ?? []} handleAddAccount={handleAddAccount} />
    </LoadingContainerWithErrorPanel>
  );
};

export default withAccounts(AccountsList);
