import * as React from 'react';
import { DecommissionChargerProvider } from './useDecommissionCharger';

export const withDecommissionCharger =
  <T extends Record<string, any>>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: T) =>
    (
      <DecommissionChargerProvider>
        <Component {...props} />
      </DecommissionChargerProvider>
    );
