import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Field, FieldArray, FormikProps } from 'formik';
import React, { RefObject } from 'react';
import SmartSelectField from '../../../commons/form/SmartSelectField';
import SmartTextField from '../../../commons/form/SmartTextField';
import { ConnectorPowerTypeDisplay, ConnectorStandardDisplay, CpoConnector, NewCpoChargePoint } from '../../../types/charger/Charger';
import { ConnectorActionButtonsContainer } from '../ChargerConnectors/ConnectorActionButtonsContainer';
import { CpoEvses } from '../ChargerDetails/ChargerInfo/ChargerInfo';
import BoxWithTitle from '../../../commons/containers/PaperWithTile/BoxWithTitle';
import { isNumeric } from 'rxjs/internal-compatibility';

interface ConnectorsFormProps {
  formikRef: RefObject<FormikProps<NewCpoChargePoint>> | RefObject<FormikProps<CpoEvses>>;
  evseReference: string;
}

const useStyles = makeStyles((theme) => ({
  connectorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  connectorTitleHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  connectorsBox: {
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    minWidth: 300
  },
  connectorTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const NewConnectors: React.FC<ConnectorsFormProps> = ({ formikRef, evseReference }) => {
  const classes = useStyles();
  const evseReferenceNumber = parseInt(evseReference.split('.')[1]);
  if (!formikRef.current) return null;

  const formConnectors = formikRef.current.values.evses[evseReferenceNumber]?.connectors;
  const countFormConnectors = formikRef.current.values.evses.flatMap((evse) => evse.connectors).length;

  const handleMaxPowerUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const connectorFieldIndex = parseInt(e.target.name.split('.')[3]);
    const currentConnector = formConnectors?.[connectorFieldIndex];
    if (!currentConnector || !isNumeric(currentConnector.maxAmperage) || !isNumeric(currentConnector.maxVoltage)) return;
    formikRef.current?.setFieldValue(
      `evses.${evseReferenceNumber}.connectors.${connectorFieldIndex}.maxElectricPower`,
      currentConnector.maxAmperage * currentConnector.maxVoltage
    );
  };

  const newConnectorValues = {
    format: '',
    maxAmperage: 0,
    maxVoltage: 0,
    maxElectricPower: 0,
    powerType: 'AC_1_PHASE',
    standard: '',
    ocppConnectorId: countFormConnectors ? countFormConnectors + 1 : 1
  };

  const connectorStandardOptions = Array.from(ConnectorStandardDisplay, ([value, displayValue]) => ({ value, displayValue }));
  const connectionPowerTypeOptions = Array.from(ConnectorPowerTypeDisplay, ([value, displayValue]) => ({ value, displayValue }));

  return (
    <FieldArray name={`evses.${evseReferenceNumber}.connectors`}>
      {({ push, remove, form }) => {
        const connectors = form.values.evses[evseReferenceNumber].connectors;
        const handleAddConnector = () => push(newConnectorValues);
        const handleRemoveConnector = (connectorIndex: number) => {
          if (connectors.length > 1) {
            remove(connectorIndex);
          }
        };

        return (
          <BoxWithTitle
            title="Connectors"
            topRightComponent={<ConnectorActionButtonsContainer handleAddConnector={handleAddConnector} isEvse={false} />}
          >
            <Box className={classes.connectorsContainer}>
              {connectors.map((_: CpoConnector, index: number) => {
                const connectorPath = `evses.${evseReferenceNumber}.connectors.${index}`;
                return (
                  <Box key={index} className={classes.connectorsBox}>
                    <Box className={classes.connectorTitle}>
                      <Typography className={classes.connectorTitleHeading} variant="h4">
                        Connector
                      </Typography>
                      <IconButton onClick={() => handleRemoveConnector(index)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Field label="Id" name={`${connectorPath}.ocppConnectorId`} component={SmartTextField} helperText="Id of the connector" />
                    <Field
                      label="Format"
                      name={`${connectorPath}.format`}
                      component={SmartSelectField}
                      options={[
                        { value: 'CABLE', displayValue: 'CABLE' },
                        { value: 'SOCKET', displayValue: 'SOCKET' }
                      ]}
                      helperText="Format of the connector"
                    />
                    <Field
                      label="PowerType"
                      name={`${connectorPath}.powerType`}
                      component={SmartSelectField}
                      options={connectionPowerTypeOptions}
                      helperText="Power type of the connector"
                    />
                    <Field
                      label="Type"
                      name={`${connectorPath}.standard`}
                      component={SmartSelectField}
                      options={connectorStandardOptions}
                      helperText="Type of the connector"
                    />
                    <Field
                      label="Max Amperage (A)"
                      name={`${connectorPath}.maxAmperage`}
                      component={SmartTextField}
                      helperText="Max amperage of the connector"
                      onKeyUp={handleMaxPowerUpdate}
                    />
                    <Field
                      label="Max Voltage (V)"
                      name={`${connectorPath}.maxVoltage`}
                      component={SmartTextField}
                      helperText="Max voltage of the connector"
                      onKeyUp={handleMaxPowerUpdate}
                    />
                    <Field
                      label="Max Power (Watts)"
                      name={`${connectorPath}.maxElectricPower`}
                      component={SmartTextField}
                      disabled
                      helperText="Max power of the connector"
                    />
                  </Box>
                );
              })}
            </Box>
          </BoxWithTitle>
        );
      }}
    </FieldArray>
  );
};

export default NewConnectors;
