import React, { ComponentType, createContext, FC, PropsWithChildren, useContext, useEffect, useReducer } from 'react';
import NotFoundPage from '../components/GenericNotFound/GenericNotFound';
import userService from '../services/userService';
import { UserAdminPermissions } from '../types/user/UserPermission';
import useAuthentication from './useAuthentication';

type AdminPermissionsState = UserAdminPermissions;

// Default values for any feature flags before fetching
const initialState: AdminPermissionsState = {
  isLocationAdmin: false,
  isAccountAdmin: false,
  isOrgAdmin: false,
  isHelpdesk: false,
  isSuperAdmin: false
};

const reducer = (state: AdminPermissionsState, newState: Partial<AdminPermissionsState>) => {
  return {
    ...state,
    ...newState
  };
};

const AdminPermissionsContext = createContext<AdminPermissionsState>(initialState);

export const AdminPermissionsProvider: FC = ({ children }: PropsWithChildren<any>) => {
  const { user } = useAuthentication();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!user) return;

    userService.getCurrentUserAdminPermissions().subscribe(dispatch);
  }, [user]);

  return <AdminPermissionsContext.Provider value={state}>{children}</AdminPermissionsContext.Provider>;
};

export enum AdminPermission {
  LOCATION_ADMIN = 'isLocationAdmin',
  ACCOUNT_ADMIN = 'isAccountAdmin',
  ORG_ADMIN = 'isOrgAdmin',
  HELPDESK = 'isHelpdesk',
  SUPER_ADMIN = 'isSuperAdmin'
}

interface UserAdminPermissionsType {
  hasAdminPermission: (permission: AdminPermission) => boolean;
  permissions: AdminPermissionsState;
}

export const useAdminPermissions = (): UserAdminPermissionsType => {
  const permissions = useContext(AdminPermissionsContext);
  const hasAdminPermission = (permission: AdminPermission) => permissions[permission];

  return { hasAdminPermission, permissions };
};

export const requiresAdminPermission =
  (permission: AdminPermission) =>
  <T extends Record<string, unknown>>(Component: ComponentType<T>): React.FC<T> =>
  (props: T) => {
    const { hasAdminPermission } = useAdminPermissions();
    return hasAdminPermission(permission) ? <Component {...props} /> : <NotFoundPage />;
  };
