export enum LocationDetailsFlowState {
  INIT = 'INIT',
  LOCATION_LOADED = 'LOCATION_LOADED',
  FAILED_TO_LOAD_LOCATION = 'FAILED_TO_LOAD_LOCATION',
  SHOWING_UPDATE_LOCATION_FORM = 'SHOWING_UPDATE_LOCATION_FORM',
  LOCATION_UPDATED = 'LOCATION_UPDATED',
  UPDATING_LOCATION = 'UPDATING_LOCATION',
  FAILED_TO_UPDATE_LOCATION = 'FAILED_TO_UPDATE_LOCATION',
  UPDATE_LOCATION_FORM_CLOSED = 'UPDATE_LOCATION_FORM_CLOSED',
  SHOWING_UPDATE_BASIC_CONFIG_FORM = 'SHOWING_UPDATE_BASIC_CONFIG_FORM',
  UPDATE_BASIC_CONFIG_FORM_CLOSED = 'UPDATE_BASIC_CONFIG_FORM_CLOSED',
  BASIC_CONFIG_UPDATED = 'BASIC_CONFIG_UPDATED',
  FAILED_TO_UPDATE_BASIC_CONFIG = 'FAILED_TO_UPDATE_BASIC_CONFIG',
  EXTERNAL_LOCATION_LOADED = 'EXTERNAL_LOCATION_LOADED',
  FAILED_TO_LOAD_EXTERNAL_LOCATION = 'FAILED_TO_LOAD_EXTERNAL_LOCATION',
  FAILED_TO_LOAD_DROPDOWN_DATA = 'FAILED_TO_LOAD_DROPDOWN_DATA',
  SHOWING_NEW_LOCATION_FORM = 'SHOWING_NEW_LOCATION_FORM',
  NEW_LOCATION_CREATED = 'NEW_LOCATION_CREATED',
  FAILED_TO_CREATE_LOCATION = 'FAILED_TO_CREATE_LOCATION',
  NEW_LOCATION_FORM_CLOSED = 'NEW_LOCATION_FORM_CLOSED',
  LOAD_CHARGERS_FOR_LOCATION = 'LOAD_CHARGERS_FOR_LOCATION'
}
