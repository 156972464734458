import { FC } from 'react';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import { EmspOrganisation } from '../../../types/organisation/Organisation';

interface OrganisationInfoPanelProps {
  organisation: EmspOrganisation;
}
const OrganisationInfoPanel: FC<OrganisationInfoPanelProps> = ({ organisation }) => {
  return (
    <PaperWithTitle title="General">
      <>
        <TextWithLabel label="Name" text={organisation.name} />
        <TextWithLabel label="Website" text={organisation.website} />
      </>
    </PaperWithTitle>
  );
};

export default OrganisationInfoPanel;
