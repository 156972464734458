import { Button } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import React, { RefObject } from 'react';
import Yup from '../../../utils/validation/yupExtensions';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import { ErrorBar } from '../../../commons/snackbars/snackbars';
import { ConnectorFormat, NewCpoChargePoint } from '../../../types/charger/Charger';
import { EmspLocation } from '../../../types/location/Location';
import ChargerFormInfo from './ChargerFormInfo';
import { findLocation } from './NewChargerContainer';
import NewEvses from './NewEvses';

interface NewChargerFormProps {
  emspLocationId?: string;
  locations: EmspLocation[];
  onSubmit: (values: NewCpoChargePoint) => void;
  newChargePoint: NewCpoChargePoint;
  createChargePointErrorMessage: string;
  formikRef: RefObject<FormikProps<NewCpoChargePoint>>;
}

const connectorInitialValues = {
  ocppConnectorId: '1',
  format: ConnectorFormat.CABLE,
  maxAmperage: '',
  maxVoltage: '',
  maxElectricPower: '',
  powerType: '',
  standard: ''
};

const validationSchema = Yup.object({
  ocppChargePointIdentifier: Yup.string()
    .required('Required')
    .min(5)
    .max(50)
    .matches(/^[A-Z0-9]+([A-Z0-9_-])*$/, 'Should be in uppercase and alphanumeric'),
  physicalReference: Yup.string()
    .required('Required')
    .min(5, 'Should be at least 5 characters')
    .max(50, 'Should be at most 50 characters')
    .matches(/^OPL-[A-Z0-9][A-Z0-9-]*$/, 'Please enter a valid id. e.g. OPL-{number}'),
  locationId: Yup.string().required('Required'),
  evses: Yup.array()
    .of(
      Yup.object({
        connectors: Yup.array()
          .of(
            Yup.object({
              ocppConnectorId: Yup.number().typeError('Should be a number').required('Required'),
              format: Yup.string().required('Required'),
              maxAmperage: Yup.number().typeError('Should be a number').required('Required'),
              maxVoltage: Yup.number().typeError('Should be a number').required('Required'),
              powerType: Yup.string().required('Required'),
              standard: Yup.string().required('Required')
            })
          )
          .distinctOnProperty('ocppConnectorId', 'Connector Id should be unique'),
        evseReference: Yup.number().typeError('Should be a number').required('Required')
      })
    )
    .distinctOnProperty('evseReference', 'Evse Reference should be unique')
    .distinctOnNestedProperty('connectors', 'ocppConnectorId', 'Connector Id should be unique')
});

const NewChargerForm: React.FC<NewChargerFormProps> = ({
  createChargePointErrorMessage,
  newChargePoint,
  emspLocationId,
  formikRef,
  locations,
  onSubmit
}) => {
  const cpoLocationId = emspLocationId ? findLocation(locations, emspLocationId, false)?.cpoLocationId : undefined;

  const initialChargerValue = {
    ocppChargePointIdentifier: '',
    evses: [
      {
        id: '1',
        status: 'AVAILABLE',
        evseId: '1',
        connectors: [connectorInitialValues],
        evseReference: '1'
      }
    ],
    anonymousChargingAllowed: 'false',
    locationId: cpoLocationId,
    locationName: '',
    physicalReference: 'OPL-'
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={newChargePoint || initialChargerValue}
        enableReinitialize
        validateOnMount
        onSubmit={(values: NewCpoChargePoint) => {
          onSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <PaperWithTitle title="General">
              <ChargerFormInfo locationId={cpoLocationId} locations={locations} />
            </PaperWithTitle>
            <NewEvses
              formikRef={formikRef}
              bottomActionButtons={
                <Button type="submit" variant="contained" color="primary" disabled={!props.isValid}>
                  Next
                </Button>
              }
              isNewEvse={true}
            />
          </Form>
        )}
      </Formik>
      <ErrorBar open={Boolean(createChargePointErrorMessage)} fullPage={undefined} onClose={undefined}>
        {createChargePointErrorMessage}
      </ErrorBar>
    </>
  );
};

export default NewChargerForm;
