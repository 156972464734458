import * as React from 'react';
import { TariffProfilesProvider } from './useTariffProfiles';

export const withTariffProfiles =
  <T extends Record<string, any>>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: T) =>
    (
      <TariffProfilesProvider>
        <Component {...props} />
      </TariffProfilesProvider>
    );
