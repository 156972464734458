import { Form, Formik, FormikProps } from 'formik';
import { createRef, FC, useEffect } from 'react';
import * as Yup from 'yup';
import CancelButton from '../../../../commons/form/button/CancelButton';
import UpdateButton from '../../../../commons/form/button/UpdateButton';
import { CpoEvse } from '../../../../types/charger/Charger';
import NewEvses from '../../NewCharger/NewEvses';
import { CpoEvses } from './ChargerInfo';

interface EditChargerConnectorsFormProps {
  evses: CpoEvse[];
  handleCancel: () => void;
  handleSubmit: (values: CpoEvses) => void;
  hasFailed: boolean;
}

export const EditChargerEvsesForm: FC<EditChargerConnectorsFormProps> = ({ evses, handleCancel, handleSubmit, hasFailed }) => {
  const formikRef = createRef<FormikProps<CpoEvses>>();
  useEffect(() => {
    if (hasFailed && formikRef.current) {
      formikRef.current.setSubmitting(false);
    }
  }, [hasFailed, formikRef]);

  const initialValues: CpoEvses = {
    evses: evses.map((evse) => ({
      ...evse,
      connectors: evse.connectors.map((connector) => ({
        ...connector
      }))
    }))
  };

  const validationSchema = Yup.object({
    evses: Yup.array()
      .of(
        Yup.object({
          connectors: Yup.array()
            .of(
              Yup.object({
                ocppConnectorId: Yup.number().typeError('Should be a number').required('Required'),
                format: Yup.string().required('Required'),
                maxAmperage: Yup.number().typeError('Should be a number').required('Required'),
                maxVoltage: Yup.number().typeError('Should be a number').required('Required'),
                powerType: Yup.string().required('Required'),
                standard: Yup.string().required('Required')
              })
            )
            .distinctOnProperty('ocppConnectorId', 'Connector Id should be unique'),
          evseReference: Yup.number().typeError('Should be a number').required('Required')
        })
      )
      .distinctOnProperty('evseReference', 'Evse Reference should be unique')
      .distinctOnNestedProperty('connectors', 'ocppConnectorId', 'Connector Id should be unique')
  });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Form>
          <NewEvses
            formikRef={formikRef}
            bottomActionButtons={
              <>
                <CancelButton handleCancel={handleCancel} />
                <UpdateButton formikProps={props} />
              </>
            }
            isNewEvse={false}
          />
        </Form>
      )}
    </Formik>
  );
};
