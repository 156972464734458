import { FC, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import paths from '../../routes/paths';
import emspChargerServicev4 from '../../services/emspChargerServicev4';
import { EmspLocationIdentifier } from '../../types/location/Location';
import NotFoundPage from '../GenericNotFound/GenericNotFound';

export type ChargPointMatchParams = {
  chargerDisplayName: string;
};
const RedirectToChargerDetails: FC = () => {
  const matchChargePointDetails = useRouteMatch<ChargPointMatchParams>(paths.CHARGE_POINT_DETAILS);
  const matchCharger = useRouteMatch<ChargPointMatchParams>(paths.CHARGER);
  const chargerDisplayName = matchChargePointDetails?.params.chargerDisplayName || matchCharger?.params.chargerDisplayName || '';
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const subscription = emspChargerServicev4.getLocationIdentifier(chargerDisplayName).subscribe(
      (result: EmspLocationIdentifier) => {
        const { cpoCountryCode, cpoPartyId, locationId } = result;
        history.push(
          paths.CHARGER_DETAILS.replace(':cpoCountryCode', cpoCountryCode)
            .replace(':cpoPartyId', cpoPartyId)
            .replace(':locationId', locationId)
            .replace(':chargerDisplayName', chargerDisplayName)
        );
      },
      (error) => {
        setErrorMessage(error.message);
      }
    );
    // Clean up subscription on unmount
    return () => {
      subscription.unsubscribe();
    };
  }, [chargerDisplayName, history]);

  return errorMessage ? <NotFoundPage errorMessage={errorMessage} /> : null;
};
export default RedirectToChargerDetails;
