import _ from 'lodash';
import { createContext, useContext, useReducer } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import transactionService from '../../../../services/transactionService';
export const TransactionDetailsEvent = {
  LOAD_TRANSACTION_REQUESTED: 'LOAD_TRANSACTION_REQUESTED',
  STOP_TRANSACTION_REQUESTED: 'STOP_TRANSACTION_REQUESTED',
  STOP_TRANSACTION_CONFIRMED: 'STOP_TRANSACTION_CONFIRMED',
  CONFIRM_STOP_TRANSACTION_DIALOG_CLOSED: 'CONFIRM_STOP_TRANSACTION_DIALOG_CLOSED',
  RETRY_PAYMENT_REQUESTED: 'RETRY_PAYMENT_REQUESTED',
  RETRY_PAYMENT_CONFIRMED: 'RETRY_PAYMENT_CONFIRMED',
  CONFIRM_RETRY_PAYMENT_DIALOG_CLOSED: 'CONFIRM_RETRY_PAYMENT_DIALOG_CLOSED',
};

export const TransactionDetailsFlowState = {
  INIT: 'INIT',
  TRANSACTION_DETAILS_LOADED: 'TRANSACTION_DETAILS_LOADED',
  FAILED_TO_LOAD_TRANSACTION_DETAILS: 'FAILED_TO_LOAD_TRANSACTION_DETAILS',
  STOP_TRANSACTION_SUBMITTED: 'STOP_TRANSACTION_SUBMITTED',
  FAILED_TO_SUBMIT_STOP_TRANSACTION: 'FAILED_TO_SUBMIT_STOP_TRANSACTION',
  SUBMITTING_STOP_TRANSACTION: 'SUBMITTING_STOP_TRANSACTION',
  SHOWING_CONFIRM_STOP_TRANSACTION_DIALOG: 'SHOWING_CONFIRM_STOP_TRANSACTION_DIALOG',
  RETRY_PAYMENT_COMPLETED: 'RETRY_PAYMENT_COMPLETED',
  SUBMITTING_RETRY_PAYMENT: 'SUBMITTING_RETRY_PAYMENT',
  SHOWING_CONFIRM_RETRY_PAYMENT_DIALOG: 'SHOWING_CONFIRM_RETRY_PAYMENT_DIALOG',
  FAILED_TO_SUBMIT_RETRY_PAYMENT: 'FAILED_TO_SUBMIT_RETRY_PAYMENT',
};

const initialState = {
  flowState: TransactionDetailsFlowState.INIT,
  loadingState: loadingContainerInitialState,
  transactionErrorMessage: null,
  showStopTransactionRequestedSuccess: false,
  showRetryPaymentSuccess: false,
  transaction: null
};

const reducer = (state, newState) => {
  return { ...state, ...newState };
};

const transactionDetailsContext = createContext();

export const TransactionDetailsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return <transactionDetailsContext.Provider value={{ state, dispatch }}>{children}</transactionDetailsContext.Provider>;
};

const useTransactionDetails = () => {
  const { state, dispatch } = useContext(transactionDetailsContext);

  const addEvent = (event) => {
    switch (event.type) {
      case TransactionDetailsEvent.LOAD_TRANSACTION_REQUESTED:
        transactionService.getTransactionsById(event.payload.id).subscribe(
          (result) => {
            dispatch({
              ...state,
              loadingState: loadingContainerSucceededState,
              transaction: result,
              flowState: TransactionDetailsFlowState.TRANSACTION_DETAILS_LOADED,
              transactionErrorMessage: null
            });
          },
          (error) =>
            dispatch({
              ...state,
              loadingState: loadingContainerFailedState(error.message, error.status),
              flowState: TransactionDetailsFlowState.FAILED_TO_LOAD_TRANSACTION_DETAILS
            })
        );
        break;
      case TransactionDetailsEvent.STOP_TRANSACTION_REQUESTED:
        dispatch({
          ...state,
          flowState: TransactionDetailsFlowState.SHOWING_CONFIRM_STOP_TRANSACTION_DIALOG,
          transactionErrorMessage: null
        });
        break;
      case TransactionDetailsEvent.CONFIRM_STOP_TRANSACTION_DIALOG_CLOSED:
        dispatch({
          ...state,
          flowState: TransactionDetailsFlowState.TRANSACTION_DETAILS_LOADED
        });
        break;
      case TransactionDetailsEvent.STOP_TRANSACTION_CONFIRMED:
        dispatch({
          ...state,
          flowState: TransactionDetailsFlowState.SUBMITTING_STOP_TRANSACTION
        });
        transactionService.stopTransaction(event.payload.id).subscribe(
          () => {
            dispatch({
              ...state,
              loadingState: loadingContainerSucceededState,
              flowState: TransactionDetailsFlowState.STOP_TRANSACTION_SUBMITTED,
              transactionErrorMessage: null,
              showStopTransactionRequestedSuccess: true
            });
          },
          (error) =>
            dispatch({
              ...state,
              flowState: TransactionDetailsFlowState.FAILED_TO_SUBMIT_STOP_TRANSACTION,
              transactionErrorMessage: error.message
            })
        );
        break;
      case TransactionDetailsEvent.RETRY_PAYMENT_REQUESTED:
        dispatch({
          ...state,
          flowState: TransactionDetailsFlowState.SHOWING_CONFIRM_RETRY_PAYMENT_DIALOG,
          transactionErrorMessage: null
        });
        break;
      case TransactionDetailsEvent.CONFIRM_RETRY_PAYMENT_DIALOG_CLOSED:
        dispatch({
          ...state,
          flowState: TransactionDetailsFlowState.TRANSACTION_DETAILS_LOADED
        });
        break;
      case TransactionDetailsEvent.RETRY_PAYMENT_CONFIRMED:
        dispatch({
          ...state,
          flowState: TransactionDetailsFlowState.SUBMITTING_RETRY_PAYMENT
        });
        transactionService.retryFailedPayment(event.payload.id).subscribe(
          () => {
            dispatch({
              ...state,
              loadingState: loadingContainerSucceededState,
              flowState: TransactionDetailsFlowState.RETRY_PAYMENT_COMPLETED,
              transactionErrorMessage: null,
              showRetryPaymentSuccess: true
            });
          },
          (error) =>
            dispatch({
              ...state,
              flowState: TransactionDetailsFlowState.FAILED_TO_SUBMIT_RETRY_PAYMENT,
              transactionErrorMessage: 'Failed to capture payment for transaction'
            })
        );
        break;
      default:
        console.log(event);
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useTransactionDetails;
