import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import mainTheme from '../../themes/mainTheme';
import Container from '@material-ui/core/Container';
import SpacedBox from './SpacedBox/SpacedBox';

const useStyles = makeStyles((theme) => ({
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
}));

const PanelLoadingIndicator = () => {
  const classes = useStyles(mainTheme);
  return (
    <Container classes={{root: classes.centeredContainer}}>
      <SpacedBox>
        <CircularProgress color="primary" />
      </SpacedBox>
    </Container>
  );
};

export default PanelLoadingIndicator;
