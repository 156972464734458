import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import mainTheme from '../../../themes/mainTheme';
import Box from '@material-ui/core/Box';
import {formatStatusText} from '../../../utils/transaction/transactionUtils';

const useStyles = makeStyles((theme) => ({
  sessionStatus: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5),
    fontWeight: 500,
  },
  active: {
    backgroundColor: theme.palette.sessionStatus.active,
  },
  completed: {
    backgroundColor: theme.palette.sessionStatus.completed,
  },
  other: {
    backgroundColor: theme.palette.sessionStatus.other,
  },
}));

const TransactionStatus = ({status}) => {
  const classes = useStyles(mainTheme);
  return (
    <>
      <Box
        width={150}
        className={clsx(classes.sessionStatus, {
          [classes.active]: status === 'ACTIVE',
          [classes.completed]: status === 'COMPLETED',
          [classes.other]: status !== 'ACTIVE' && status !== 'COMPLETED',
        })}
      >
        {formatStatusText(status)}
      </Box>
    </>
  );
};

TransactionStatus.defaultProps = {
  status: 'LOADING...',
};

TransactionStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default TransactionStatus;
