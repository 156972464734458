import { createContext, PropsWithChildren, useContext, useReducer } from 'react';
import _ from 'lodash';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import { EventType, ReducerContext } from '../../../../types/SharedStates';
import {
  OrganisationChargersEvent,
  OrganisationChargersFlowState,
  OrganisationChargersState
} from '../../../../types/organisation/OrganisationChargers';
import emspChargerServicev4 from '../../../../services/emspChargerServicev4';

const initialState: OrganisationChargersState = {
  loadingState: loadingContainerInitialState,
  chargers: [],
  flowState: OrganisationChargersFlowState.INIT
};

export const OrganisationChargersContext = createContext<ReducerContext<OrganisationChargersState>>({
  state: initialState,
  dispatch: () => initialState
});

const reducer = (state: OrganisationChargersState, newState: Partial<OrganisationChargersState>) => ({ ...state, ...newState });

export const OrganisationChargersProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return <OrganisationChargersContext.Provider value={{ state, dispatch }}>{children}</OrganisationChargersContext.Provider>;
};

interface UseOrganisationChargersType {
  state: OrganisationChargersState;
  addEvent: (event: EventType) => void;
}

const useOrganisationChargers = (): UseOrganisationChargersType => {
  const { state, dispatch } = useContext(OrganisationChargersContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case OrganisationChargersEvent.LOAD_CHARGERS:
        emspChargerServicev4.getChargers(event.payload.organisationId).subscribe(
          (result: any) => {
            return dispatch({
              loadingState: loadingContainerSucceededState,
              chargers: result,
              flowState: OrganisationChargersFlowState.CHARGERS_LOADED
            });
          },
          (error: any) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: OrganisationChargersFlowState.FAILED_TO_LOAD_CHARGERS
            })
        );
        break;
      default:
        console.log(event);
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useOrganisationChargers;
