import { NewAccountProvider } from './useNewAccount';
import { ComponentType } from 'react';

export const withNewAccount =
  <T extends Record<string, unknown>>(Component: ComponentType<T>): React.FC<T> =>
  (props: T) => {
    return (
      <NewAccountProvider>
        <Component {...props} />
      </NewAccountProvider>
    );
  };
