import * as React from 'react';
import { OrganisationProvider } from './useOrganisations';

interface WithOrganisationsProps {
  (Component: React.FC): (props: Record<string, unknown>) => JSX.Element

}

export const withOrganisations: WithOrganisationsProps = (Component) => (props) => (
  <OrganisationProvider>
    <Component {...props} />
  </OrganisationProvider>
);