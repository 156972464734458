import React from 'react';
import useChargePointGroupAdminList, {ChargePointGroupAdminListEvent, ChargePointGroupAdminListFlowState} from './hook/useChargePointGroupAdminList';
import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';
import dateTimeFormatter from '../../../utils/date/dateTimeFormatter';
import _ from 'lodash';
import FormDialog from '../../../commons/dialogs/FormDialog';
import AddChargePointGroupAdminForm from './AddChargePointGroupAdminForm';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {WarningDialog} from '../../../commons/dialogs/dialogs';

const ChargePointGroupAdminListTable = () => {
  const {state, addEvent} = useChargePointGroupAdminList();
  const chargePointGroupName = state.chargePointGroup.name;

  return (
    <>
      <MaterialTableWrapper
        columns={[
          {title: 'Id', field: 'id', width: 'auto', hidden: true, hiddenByColumnsButton: true},
          {title: 'Name', field: 'name', width: 'auto', defaultSort: 'asc'},
          {title: 'Email', field: 'email', width: 'auto'},
          {
            title: 'Created',
            field: 'created',
            width: 'auto',
            render: (rowData) => dateTimeFormatter.printReadableDateTime(rowData.created),
            customFilterAndSearch: (filter, rowData) => _.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.created), filter),
            export: false,
          },
          {
            title: 'Created',
            field: 'readableCreated',
            hidden: true,
            export: true,
          },
        ]}
        globalActionButton={{
          name: 'Add Admin',
          tooltip: 'Add an admin to this charge point fleet',
          onClick: () => addEvent({type: ChargePointGroupAdminListEvent.NEW_CHARGE_POINT_GROUP_ADMIN_FORM_REQUESTED}),
        }}
        singleActionButton={{
          icon: RemoveCircleIcon,
          tooltip: 'Remove admin',
          onClick: (rowData) => {
            addEvent({
              type: ChargePointGroupAdminListEvent.REMOVE_CHARGE_POINT_GROUP_ADMIN_DIALOG_REQUESTED,
              payload: {
                chargePointGroupAdmin: rowData,
              },
            });
          },
        }}
        data={state.chargePointGroupAdminList.map((chargePointGroupAdmin) => ({
          id: chargePointGroupAdmin.id,
          name: chargePointGroupAdmin.name,
          email: chargePointGroupAdmin.email,
          created: chargePointGroupAdmin.created,
          readableCreated: dateTimeFormatter.printReadableDateTime(chargePointGroupAdmin.created),
        }))}
        exportFileName={`Admins in ${chargePointGroupName} charge point fleet`}
      />
      <FormDialog
        open={_.includes(
          [
            ChargePointGroupAdminListFlowState.SHOWING_NEW_CHARGE_POINT_GROUP_ADMIN_FORM,
            ChargePointGroupAdminListFlowState.FAILED_TO_ADD_NEW_CHARGE_POINT_GROUP_ADMIN,
            ChargePointGroupAdminListFlowState.ADDING_CHARGE_POINT_GROUP_ADMIN,
          ],
          state.flowState
        )}
        onClose={() => addEvent({type: ChargePointGroupAdminListEvent.NEW_CHARGE_POINT_GROUP_ADMIN_FORM_CLOSED})}
        title={`Add admin to ${chargePointGroupName} charge point fleet`}
      >
        <AddChargePointGroupAdminForm />
      </FormDialog>
      <WarningDialog
        open={_.includes([ChargePointGroupAdminListFlowState.SHOWING_REMOVE_CHARGE_POINT_GROUP_ADMIN_FORM], state.flowState)}
        buttonLabel='Remove admin'
        buttonCallback={() =>
          addEvent({
            type: ChargePointGroupAdminListEvent.REMOVE_CHARGE_POINT_GROUP_ADMIN_DIALOG_SUBMITTED,
            payload: {chargePointGroupId: state.chargePointGroup.id, email: state.chargePointGroupAdmin.email},
          })
        }
        onClose={() => addEvent({type: ChargePointGroupAdminListEvent.REMOVE_CHARGE_POINT_GROUP_ADMIN_DIALOG_CLOSED})}
      >
        Are you sure you want to remove {state.chargePointGroupAdmin?.name} ({state.chargePointGroupAdmin?.email}) from {state.chargePointGroup?.name} admin list?
      </WarningDialog>
    </>
  );
};

export default ChargePointGroupAdminListTable;
