import React, { useEffect } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PropTypes from 'prop-types';
import useChargePoints, { ChargePointEvent, ChargePointFlowState } from '../../ChargePoints/hook/useChargePoints';
import { withChargePoints } from '../../ChargePoints/hook/withChargePoints';
import ChargePointInfo from './ChargePointInfo';
import { useHistory } from 'react-router';
import paths from '../../../routes/paths';
import _ from 'lodash';

const ChargePointInfoPanel = ({ chargePointId }) => {
  const { state, addEvent } = useChargePoints();
  const history = useHistory();

  useEffect(() => {
    // if id is updated then we need to replace route
    if (!_.isNil(state.updatedChargePointId) && state.updatedChargePointId !== chargePointId) {
      history.replace(`${paths.CHARGE_POINTS_LEGACY}/${state.updatedChargePointId}`);
      return;
    }

    if (state.flowState === ChargePointFlowState.INIT || state.flowState === ChargePointFlowState.CHARGE_POINT_UPDATED) {
      addEvent({ type: ChargePointEvent.LOAD_CHARGE_POINT, payload: { id: chargePointId } });
    }
  }, [state.flowState, addEvent, chargePointId, history, state.updatedChargePointId]);

  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      {state.chargePoint && <ChargePointInfo chargePoint={state.chargePoint} />}
    </LoadingContainerWithErrorPanel>
  );
};

ChargePointInfoPanel.propTypes = {
  chargePointId: PropTypes.string.isRequired
};

export default withChargePoints(ChargePointInfoPanel);
