import { LocationUser } from '../../../types/location/user/LocationUser';

import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { FC } from 'react';
import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';

interface AdminsTableForLocationProps {
  admins: LocationUser[];
  handleAddAdmin: () => void;
  handleRemoveAdmin: (user: LocationUser) => void;
  hasAdminPermission: boolean;
}

const AdminsTableForLocation: FC<AdminsTableForLocationProps> = ({ admins, handleAddAdmin, handleRemoveAdmin, hasAdminPermission }) => {
  return (
    <MaterialTableWrapper
      columns={[
        {
          title: 'ID',
          field: 'id',
          width: 'auto',
          hidden: true,
          hiddenByColumnsButton: true,
          export: true
        },
        {
          title: 'Name',
          field: 'name',
          width: 'auto',
          defaultSort: 'asc'
        },
        {
          title: 'Email',
          field: 'email',
          width: 'auto'
        }
      ]}
      data={admins}
      exportFileName="Admins"
      globalActionButton={
        hasAdminPermission
          ? {
              name: 'Add Admin',
              tooltip: 'Add an Admin to Location',
              onClick: handleAddAdmin
            }
          : {}
      }
      singleActionButton={{
        icon: RemoveCircleIcon,
        tooltip: 'Remove admin from the location',
        onClick: handleRemoveAdmin
      }}
    />
  );
};

export default AdminsTableForLocation;
