import _ from 'lodash';
import { createContext, useContext, useReducer } from 'react';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';
import userService from '../../../../services/userService';

// events
export const UserPersonalDetailsEvent = {
  PAGE_ENTERED: 'PAGE_ENTERED'
};

// flow states
export const UserPersonalDetailsFlowState = {
  INIT: 'INIT',
  SHOWING_PERSONAL_DETAILS: 'SHOWING_PERSONAL_DETAILS',
  ERROR: 'ERROR'
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  flowState: UserPersonalDetailsFlowState.INIT,
  user: null
};

// reducer
const reducer = (state, newState) => ({ ...state, ...newState });

// context
const userPersonalDetailsContext = createContext();

// provider
export const UserPersonalDetailsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <userPersonalDetailsContext.Provider value={{ state, dispatch }}>{children}</userPersonalDetailsContext.Provider>
  );
};

// hook
const useUserPersonalDetails = () => {
  const { state, dispatch } = useContext(userPersonalDetailsContext);

  const addEvent = (event) => {
    switch (event.type) {
      case UserPersonalDetailsEvent.PAGE_ENTERED:
        userService.getUserByUuid(event.userId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              flowState: UserPersonalDetailsFlowState.SHOWING_PERSONAL_DETAILS,
              user: result
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message, error.status),
              flowState: UserPersonalDetailsFlowState.ERROR
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useUserPersonalDetails;
