import React, { createRef, FC, useEffect } from 'react';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import SmartTextField from '../../../../../commons/form/SmartTextField';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import useDecommissionCharger, { DecommissionChargerFlowState } from './hook/useDecommissionCharger';
import paths from '../../../../../routes/paths';
import { useHistory } from 'react-router-dom';
import { DecommissionChargerEventType } from '../../../../../types/charger/DecommissionChargerEventType';
import { ErrorBar, ProcessingBar } from '../../../../../commons/snackbars/snackbars';
import Typography from '@material-ui/core/Typography';
import { withDecommissionCharger } from './hook/withDecommissionCharger';
import ConfirmButton from '../../../../../commons/form/button/ConfirmButton';

export interface DecommissionChargerProps {
  displayName: string;
  cpoChargePointId: string;
}

type DisplayNameConfirmation = {
  chargerNameConfirmation: string;
};

const useStyles = makeStyles((theme) => ({
  confirmButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1)
  },
  notes: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));

const DecommissionChargerForm: FC<DecommissionChargerProps> = ({ displayName, cpoChargePointId }) => {
  const classes = useStyles();
  const formikRef = createRef<FormikProps<DisplayNameConfirmation>>();
  const { state, addEvent } = useDecommissionCharger();
  const hasFailed = state.flowState === DecommissionChargerFlowState.FAILED_TO_DECOMMISSION_CHARGER;
  const history = useHistory();

  const initialValues = {
    chargerNameConfirmation: ''
  };

  const onSubmit = () => {
    addEvent({ type: DecommissionChargerEventType.SUBMIT_DECOMMISSION_CHARGER, payload: { cpoChargePointId } });
  };

  const onClose = () => {
    addEvent({ type: DecommissionChargerEventType.CLEAR_FAILED_STATE });
  };

  useEffect(() => {
    if (hasFailed && formikRef.current) {
      formikRef.current.setSubmitting(false);
    }
    if (state.flowState === DecommissionChargerFlowState.CHARGER_DECOMMISSIONED) {
      history.push(paths.CHARGERS, { showChargerDecommissionedSuccessBar: true });
    }
  }, [hasFailed, state.flowState, formikRef, history]);

  const validationSchema = Yup.object({
    chargerNameConfirmation: Yup.string()
      .required('Required')
      .test('is-displayName', 'OPL ID must match', (value) => value === displayName)
  });

  const isProcessing = state.flowState === DecommissionChargerFlowState.SUBMITTING;
  return (
    <>
      <Typography variant="body1">Enter the charger OPL ID to confirm decommissioning.</Typography>
      <Box className={classes.notes}>
        <Typography color="secondary" variant="body2">
          Note: Decommissioning a charger will remove it from OpenLoop. This charger will be unavailable for any OpenLoop connected charging, or any
          remote configuration via OpenLoop admin portal.
        </Typography>
      </Box>
      <Formik innerRef={formikRef} onSubmit={onSubmit} validationSchema={validationSchema} validateOnMount={true} initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <Field
              component={SmartTextField}
              name="chargerNameConfirmation"
              label="OPL ID"
              fullWidth
              placeholder={displayName}
              helperText="OPL ID of the charger"
              autoFocus
            />
            <Box className={classes.confirmButtonContainer}>
              <ConfirmButton formikProps={formikProps} />
            </Box>
          </Form>
        )}
      </Formik>
      <ProcessingBar open={isProcessing} fullPage={false} />
      <ErrorBar open={hasFailed} fullPage={false} onClose={onClose}>
        Failed to decommission charger
      </ErrorBar>
    </>
  );
};

export default withDecommissionCharger(DecommissionChargerForm);
