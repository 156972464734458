import { Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { createRef, FC, useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import mainTheme from '../../../../themes/mainTheme';
import Box from '@material-ui/core/Box';
import { ResetTypes } from '../../../../utils/reset_types/resetTypes';

const useStyles = makeStyles((theme) => ({
  resetDialogButtonPadding: {
    paddingBottom: theme.spacing(1.25)
  },
  marginTopBottom10: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25)
  },
  toggleButton: {
    width: '100%'
  }
}));

interface ResetChargerFormProps {
  hasFailed: boolean;
  handleReset: (resetType: string) => void;
}

const ResetChargePointForm: FC<ResetChargerFormProps> = ({ hasFailed, handleReset }) => {
  const classes = useStyles(mainTheme);
  const [resetType, setResetType] = useState(ResetTypes.HARD);
  const formikRef = createRef<FormikProps<FormikValues>>();

  useEffect(() => {
    if (hasFailed && formikRef.current) {
      formikRef.current.setSubmitting(false);
    }
  }, [hasFailed, formikRef]);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newResetType: string | null) => {
    event.preventDefault(); // Prevents the default action of the event from happening
    if (newResetType !== null) {
      setResetType(newResetType);
    }
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={{ resetType: resetType }}
      onSubmit={() => {
        handleReset(resetType);
      }}
    >
      <Form>
        <Box className={classes.resetDialogButtonPadding}>
          <ToggleButtonGroup classes={{ root: classes.toggleButton }} value={resetType} exclusive onChange={handleChange}>
            <ToggleButton classes={{ root: classes.toggleButton }} value={ResetTypes.SOFT}>
              Soft Reset
            </ToggleButton>
            <ToggleButton classes={{ root: classes.toggleButton }} value={ResetTypes.HARD}>
              Hard Reset
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Button
          classes={{ root: classes.marginTopBottom10 }}
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          disabled={resetType !== ResetTypes.HARD && resetType !== ResetTypes.SOFT}
        >
          Confirm
        </Button>
      </Form>
    </Formik>
  );
};

export default ResetChargePointForm;
