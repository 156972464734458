import { Box, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import mainTheme from '../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  barContainer: {
    display: 'flex',
    height: 16
  },
  bar: {
    transition: 'ease-out width 1s'
  },
  legendContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap'
  },
  legend: {
    display: 'flex',
    alignItems: 'flex-start',
    marginRight: theme.spacing(4)
  },
  legendColorIcon: {
    marginRight: theme.spacing(0.5),
    marginTop: 1
  },
  legendCount: {
    fontSize: 14,
    fontWeight: 500
  }
}));

interface HorizontalStackedBarChartProps {
  data: any[];
}

interface TooltipTitleProps {
  part: {
    label: string;
    value: number;
    color: string;
  };
  classes: {
    legend: any;
    legendColorIcon: any;
  };
}
const ToolTipTitle: React.FC<TooltipTitleProps> = ({ part, classes }) => (
  <Box className={classes.legend}>
    <FiberManualRecordRoundedIcon className={classes.legendColorIcon} htmlColor={part.color} fontSize="small" />
    <Box>
      <Typography>
        {part.label}: {part.value}
      </Typography>
    </Box>
  </Box>
);

const HorizontalStackedBarChart: React.FC<HorizontalStackedBarChartProps> = ({ data }) => {
  const classes = useStyles(mainTheme);

  const totalValue = _.sumBy(data, 'value');
  const parts = data.map((singleData) => ({ ...singleData, percentage: totalValue ? `${(singleData.value / totalValue) * 100}%` : 0 }));

  const [widths, setWidths] = useState(data.map((part) => 0));

  const animate = useCallback(() => {
    // Animation code goes here
    setWidths(parts.map((part) => part.percentage));
  }, [parts]);

  // trigger the animation by calling requestAnimationFrame
  // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
  // In order to prevent re-rendering (ie a dataleak as we were seeing, place the animation in a callback)
  // https://www.kirupa.com/html5/animating_with_requestAnimationFrame.htm
  useEffect(() => {
    const requestId = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [animate]);

  return (
    <>
      <Box className={classes.barContainer}>
        {parts.map((part, index) => (
          <Tooltip key={index} title={<ToolTipTitle part={part} classes={classes} />} placement="top" arrow>
            <Box style={{ width: widths[index], backgroundColor: part.color }} className={classes.bar} />
          </Tooltip>
        ))}
      </Box>
      <Box className={classes.legendContainer}>
        {parts.map((part, index) => (
          <Box key={index} className={classes.legend}>
            <FiberManualRecordRoundedIcon className={classes.legendColorIcon} htmlColor={part.color} fontSize="small" />
            <Box>
              <Typography variant="h5">{part.label}</Typography>
              <Typography className={classes.legendCount}>
                {part.value}/{totalValue}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default HorizontalStackedBarChart;
