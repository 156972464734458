import { Button, makeStyles } from '@material-ui/core';
import { Field, Form, Formik, FormikValues } from 'formik';
import { FC, createRef, useEffect } from 'react';
import * as Yup from 'yup';
import SmartTextField from '../../../../commons/form/SmartTextField';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

type AddAdminToLocationFormProps = {
  handleAddAdminToLocation: (email: string) => void;
  hasFailed: boolean;
};
const AddAdminToLocationForm: FC<AddAdminToLocationFormProps> = ({ handleAddAdminToLocation, hasFailed }) => {
  const formikRef = createRef<any>();

  const classes = useStyles();

  useEffect(() => {
    if (hasFailed && formikRef.current) {
      formikRef.current.setSubmitting(false);
    }
  }, [hasFailed, formikRef]);

  const initialValues = {
    email: ''
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Required')
  });

  const onSubmit = (values: FormikValues) => {
    const { email } = values;
    handleAddAdminToLocation(email);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        return (
          <Form>
            <Field name="email" label="Email" component={SmartTextField} autoFocus fullWidth />
            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}
            >
              Add
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddAdminToLocationForm;
