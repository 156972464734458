import { Step, StepLabel, Stepper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { FormikProps } from 'formik';
import _ from 'lodash';
import { createRef, FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ErrorBar, ProcessingBar } from '../../../commons/snackbars/snackbars';
import { requiresPermission } from '../../../hocs/requiresPermission';
import paths from '../../../routes/paths';
import mainTheme from '../../../themes/mainTheme';
import { AddEventType } from '../../../types/SharedStates';
import { NewCpoChargePoint } from '../../../types/charger/Charger';
import { NewChargerFlowState } from '../../../types/charger/ChargerInfoState';
import { EmspLocation } from '../../../types/location/Location';
import { Permission } from '../../../types/user/UserPermission';
import Dashboard from '../../Dashboard/Dashboard';
import NewChargerForm from './NewChargerForm';
import NewChargerReviewForm from './NewChargerReview/NewChargerReview';
import useNewCharger, { NewChargerEvent } from './hook/useNewCharger';
import { withNewCharger } from './hook/withNewCharger';

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    backgroundColor: 'transparent'
  }
}));

const onChargerReview = (addEvent: AddEventType, locations: EmspLocation[]) => (values: NewCpoChargePoint) => {
  values.locationName = findLocation(locations, values.locationId, true)?.name;
  addEvent({
    type: NewChargerEvent.REVIEW_NEW_CHARGE_POINT_FORM_REQUESTED,
    payload: values
  });
};

const onChargerSubmit = (addEvent: AddEventType) => (values: NewCpoChargePoint) => {
  addEvent({
    type: NewChargerEvent.SUBMIT_NEW_CHARGE_POINT_FORM_REQUESTED,
    payload: values
  });
};

const handleSubmitCancel = (addEvent: AddEventType) => () => {
  addEvent({
    type: NewChargerEvent.REVIEW_NEW_CHARGE_POINT_FORM_CANCELLED
  });
};

export const findLocation = (locations: EmspLocation[], locationId: string, cpoId: boolean): EmspLocation | undefined => {
  const idToSearch = cpoId ? 'cpoLocationId' : 'id';
  return locations.find((location) => location[idToSearch] === locationId);
};

const NewChargerContainer: FC = () => {
  const { state, addEvent } = useNewCharger();
  const history = useHistory();
  const formikRef = createRef<FormikProps<NewCpoChargePoint>>();

  const locationParams = useLocation<any>();

  const getComponentAndActiveStep = () => {
    switch (state.flowState) {
      case NewChargerFlowState.ENTERING_NEW_CHARGE_POINT_DETAILS:
        return {
          activeStep: 0,
          createComponent: (
            <NewChargerForm
              formikRef={formikRef}
              emspLocationId={locationParams.state?.locationId}
              locations={state.locations}
              onSubmit={onChargerReview(addEvent, state.locations)}
              newChargePoint={state.newChargePoint}
              createChargePointErrorMessage={state.createChargePointErrorMessage}
            />
          )
        };

      case NewChargerFlowState.REVIEWING_NEW_CHARGE_POINT_FORM:
      case NewChargerFlowState.SUBMITTING_CHARGE_POINT_FORM:
        return {
          activeStep: 1,
          createComponent: (
            <NewChargerReviewForm
              formikRef={formikRef}
              newChargePoint={state.newChargePoint}
              onSubmit={onChargerSubmit(addEvent)}
              onCancel={handleSubmitCancel(addEvent)}
            />
          )
        };

      default:
        return { activeStep: -1, createComponent: null };
    }
  };

  const steps = ['Enter Details', 'Review'];
  const classes = useStyles(mainTheme);

  useEffect(() => {
    if (state.flowState === NewChargerFlowState.INIT) {
      addEvent({
        type: NewChargerEvent.ENTERING_NEW_CHARGE_POINT_DETAILS
      });
    } else {
      if (state.flowState === NewChargerFlowState.NEW_CHARGE_POINT_CREATED) {
        history.push({ pathname: paths.CHARGERS }, { showChargerCreatedSuccessBar: true });
      }
      if (state.flowState !== NewChargerFlowState.SUBMITTING_CHARGE_POINT_FORM && state.flowState !== NewChargerFlowState.NEW_CHARGE_POINT_CREATED) {
        formikRef.current?.setSubmitting(false);
      }
    }
  }, [state.flowState, addEvent, history, formikRef, locationParams]);

  const { activeStep, createComponent } = getComponentAndActiveStep();

  return (
    <Dashboard pageHeading="New Charger">
      <Box>
        <Stepper activeStep={activeStep} className={classes.stepperContainer}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {createComponent}
        <ErrorBar open={!_.isEmpty(state.createChargePointErrorMessage)} fullPage={false} onClose={undefined}>
          {state.createChargePointErrorMessage}
        </ErrorBar>
        <ProcessingBar open={state.flowState === NewChargerFlowState.SUBMITTING_CHARGE_POINT_FORM} fullPage={false} />
      </Box>
    </Dashboard>
  );
};

export default requiresPermission(Permission.CREATE_CHARGE_POINT)(withNewCharger(NewChargerContainer));
