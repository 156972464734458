import React from 'react';
import PropTypes from 'prop-types';
import ChargePointGeneralInfo from './ChargePointGeneralInfo';
import ManagedConnectors from './ManagedConnectors';
import ChargePointDecommissionedPanel from '../../ChargePointDecommissionedPanel/ChargePointDecommissionedPanel';
import { chargePointStatus } from '../../../domain/chargePoint';
import useChargePoints, { ChargePointEvent } from '../../ChargePoints/hook/useChargePoints';
import { cpos } from '../../../utils/cpos/cpos';
import { SuccessBar } from '../../../commons/snackbars/snackbars';
import CentralServerChargePoint from './CentralServerChargePoint';
import paths from '../../../routes/paths';
import { useHistory } from 'react-router-dom';

const ChargePointInfo = ({ chargePoint }) => {
  const { state, addEvent } = useChargePoints();
  const isCentralServerChargePoint = chargePoint.cpo === cpos.central_server;
  const history = useHistory();

  const handleReset = () => {
    addEvent({
      type: ChargePointEvent.SHOW_RESET_CHARGE_POINT_FORM,
      payload: {
        id: chargePoint.id
      }
    });
  };

  const handleEdit = () =>
    addEvent({
      type: ChargePointEvent.SHOW_UPDATE_CHARGE_POINT_FORM,
      payload: {
        id: chargePoint.id,
        groupName: chargePoint.groupName,
        mode: chargePoint.mode,
        electricityCost: chargePoint.operationalCost.electricityCost.ratePerKwh.toFixed(2)
      }
    });

  const handleRemoteStart = (id) => {
    return history.push(`${paths.CHARGE_POINTS_LEGACY}/${id}/remote-start`);
  };

  if (chargePoint.status === chargePointStatus.DECOMMISSIONED) {
    return <ChargePointDecommissionedPanel chargePoint={chargePoint} />;
  }
  return (
    <>
      {isCentralServerChargePoint && chargePoint ? (
        <CentralServerChargePoint
          chargePoint={chargePoint}
          handleReset={handleReset}
          handleEdit={handleEdit}
          handleRemoteStart={() => {
            handleRemoteStart(chargePoint.id);
          }}
        />
      ) : (
        <>
          <ChargePointGeneralInfo chargePoint={chargePoint} handleEdit={handleEdit} />
          <ManagedConnectors evses={chargePoint.evses} cpo={state.chargePoint.cpo} handleRemoteStart={() => handleRemoteStart(chargePoint.id)} />
        </>
      )}
      <SuccessBar open={state.resetSuccessMessage != null}>{state.resetSuccessMessage}</SuccessBar>
    </>
  );
};
ChargePointInfo.propTypes = {
  chargePoint: PropTypes.object.isRequired
};

export default ChargePointInfo;
