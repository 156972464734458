import _ from 'lodash';
import { throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import ConflictException from '../utils/auth/exceptions/ConflictException';
import UserNotFoundException from '../utils/auth/exceptions/UserNotFoundException';
import appConfig from '../utils/config/appConfig';
import { errorMessageObservable } from '../utils/error/errorUtils';
import { handleError } from '../utils/error/handleApiError';

// charge point group access type
export const accessType = {
  PUBLIC: 'Public',
  PRIVATE: 'Private'
};

export const toChargePointGroup = (chargePointGroup) => ({
  id: chargePointGroup.id,
  name: chargePointGroup.name,
  cpo: chargePointGroup.cpo,
  accessType: chargePointGroup.is_public ? accessType.PUBLIC : accessType.PRIVATE
});

export const toChargePointGroups = (chargePointGroups) => chargePointGroups.map(toChargePointGroup);

const toChargePointGroupUser = (chargePointGroupUser) => ({
  id: chargePointGroupUser.id,
  name: chargePointGroupUser.name,
  email: chargePointGroupUser.email,
  created: chargePointGroupUser.created
});

const toChargePointGroupUsers = (chargePointGroupUsers) => chargePointGroupUsers.map(toChargePointGroupUser);

const chargePointGroupService = {
  getAllGroupsForAdmin: () =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-point-groups`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toChargePointGroups(response.response.data)),
      catchError((error) => errorMessageObservable(error))
    ),

  getGroupForAdmin: (id) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-point-groups/${id}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toChargePointGroup(response.response)),
      catchError((error) => handleError(error))
    ),

  createGroup: (id, name, isPublic) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-point-groups`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        body: {
          id: _.toUpper(id),
          name: _.startCase(name),
          isPublic: isPublic
        }
      })
    ).pipe(catchError((error) => throwError(new Error(error.response.message)))),

  getUsers: (id) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-point-groups/${id}/users`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toChargePointGroupUsers(response.response.data)),
      catchError((error) => errorMessageObservable(error))
    ),
  getAdmins: (id) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-point-groups/${id}/admins`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toChargePointGroupUsers(response.response.data)),
      catchError((error) => handleError(error))
    ),
  addUser: (chargePointGroupId, email) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-point-groups/${chargePointGroupId}/users`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        body: {
          email: email
        }
      })
    ).pipe(
      map((response) => toChargePointGroupUser(response.response)),
      catchError((error) => {
        switch (error.status) {
          case 404:
            return throwError(new UserNotFoundException(error.response.message));
          case 409:
            return throwError(new ConflictException(error.response.message));
          default:
            return throwError(new Error(error.response.message));
        }
      })
    ),

  addAdmin: (chargePointGroupId, email) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-point-groups/${chargePointGroupId}/admins`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        body: {
          email: email
        }
      })
    ).pipe(
      map((response) => toChargePointGroupUser(response.response)),
      catchError((error) => {
        return error.status === 409 ? throwError(new ConflictException(error.response.message)) : throwError(new Error(error.response.message));
      })
    ),
  removeUser: (chargePointGroupId, email) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-point-groups/${chargePointGroupId}/users`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        body: {
          email: email
        }
      })
    ).pipe(
      map((response) => toChargePointGroupUser(response.response)),
      catchError((error) => throwError(new Error(error.response.message)))
    ),
  removeAdmin: (chargePointGroupId, email) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-point-groups/${chargePointGroupId}/admins`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        body: {
          email: email
        }
      })
    ).pipe(
      map((response) => toChargePointGroupUser(response.response)),
      catchError((error) => throwError(new Error(error.response.message)))
    ),
  removeGroup: (chargePointGroupId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-point-groups/${chargePointGroupId}`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(catchError((error) => throwError(new Error(error.response.message))))
};

export default chargePointGroupService;
