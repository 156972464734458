import _ from 'lodash';
import { useLocation } from 'react-router';
import FormDialog from '../../commons/dialogs/FormDialog';
import NavigationLink from '../../commons/link/NavigationLink';
import MaterialTableWrapper from '../../commons/table/MaterialTableWrapper';
import { usePermissions } from '../../hooks/usePermissions';
import { Permission } from '../../types/user/UserPermission';
import CreateChargePointGroupFormContainer from './CreateChargePointGroupFormContainer';
import useChargePointGroups, { ChargePointGroupEvent, ChargePointGroupFlowState } from './hook/useChargePointGroups';

const ChargePointGroupsTable = () => {
  const { hasPermission } = usePermissions();
  const { state, addEvent } = useChargePointGroups();
  const location = useLocation();

  return (
    <>
      <MaterialTableWrapper
        columns={[
          {
            title: 'Id',
            field: 'id',
            width: 'auto',
            render: (rowData) => <NavigationLink path={`${location.pathname}/${rowData.id}`} text={rowData.id} />
          },
          { title: 'Name', field: 'name', width: 'auto' },
          { title: 'Access Type', field: 'accessType', width: 'auto' }
        ]}
        data={state.chargePointGroups.map((chargePointGroup) => ({
          id: chargePointGroup.id,
          name: chargePointGroup.name,
          accessType: chargePointGroup.accessType
        }))}
        exportFileName="Charge Point Fleets"
        globalActionButton={
          hasPermission(Permission.CREATE_CHARGE_POINT_GROUPS)
            ? {
                name: 'New',
                tooltip: 'Create a new charge point fleet',
                onClick: () => addEvent({ type: ChargePointGroupEvent.SHOW_NEW_CHARGE_POINT_GROUP_FORM })
              }
            : null
        }
      />
      <FormDialog
        open={_.includes(
          [ChargePointGroupFlowState.SHOWING_NEW_CHARGE_POINT_GROUP_FORM, ChargePointGroupFlowState.FAILED_TO_CREATE_NEW_CHARGE_POINT_GROUP],
          state.flowState
        )}
        onClose={() => addEvent({ type: ChargePointGroupEvent.CLOSE_NEW_CHARGE_POINT_GROUP_FORM })}
        title={'New Charge Point Fleet'}
      >
        <CreateChargePointGroupFormContainer />
      </FormDialog>
    </>
  );
};

export default ChargePointGroupsTable;
