import { FC } from 'react';
import { EmspAccount } from '../../../types/account/Account';
import { EmspOrganisation } from '../../../types/organisation/Organisation';
import AccountForm from '../../NewAccount/AccountForm';

interface AccountDetailsEditPanelProps {
  account: EmspAccount;
  organisations: EmspOrganisation[];
  onSubmit: (values: Partial<EmspAccount>) => void;
  onCancel: () => void;
  loadingState: any;
  editAccountErrorMessage?: string;
  onCloseError: () => void;
  disableSubmit: boolean;
}

const AccountEditPanel: FC<AccountDetailsEditPanelProps> = ({
  account,
  organisations,
  onSubmit,
  onCancel,
  editAccountErrorMessage,
  onCloseError,
  disableSubmit
}) => {
  return (
    <AccountForm
      accountType={account.accountType}
      name={account.name}
      onSubmit={onSubmit}
      onCancel={onCancel}
      organisations={organisations}
      orgId={organisations.find((o) => o.name === account.organisationName)?.id}
      disableSubmit={disableSubmit}
      errorMessage={editAccountErrorMessage}
      onCloseError={onCloseError}
    />
  );
};

export default AccountEditPanel;
