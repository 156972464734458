import {withAccessToken} from '../utils/auth/authenticationUtils';
import {ajax} from 'rxjs/ajax';
import {catchError, map} from 'rxjs/operators';
import {buildUrlWithQueryString} from '../utils/url/buildQueryString';
import appConfig from '../utils/config/appConfig';
import {errorMessageObservable} from '../utils/error/errorUtils';

const toChargePointLog = (log) => ({
  serverTimestamp: log.server_timestamp,
  chargePointTimestamp: log.charge_point_timestamp,
  event: log.event,
  messageId: log.message_id,
  raw: log.raw,
  message: log.message,
});

const toChargePointLogsResponse = (result) => ({
  data: result.data.map((log) => toChargePointLog(log)),
  nextToken: result.next_token,
});

const chargePointLogsService = {
  getLogs: (locationId, dateRange, nextToken, limit, logType) => {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV2}/locations/${locationId}/logs`, {
      date_from: dateRange.dateTimeFrom.toISOString(),
      date_to: dateRange.dateTimeTo.toISOString(),
      next_token: nextToken,
      limit: limit,
      log_type: logType,
    });
    return withAccessToken((accessToken) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      }).pipe(
        map((response) => toChargePointLogsResponse(response.response)),
        catchError((error) => errorMessageObservable(error))
      )
    );
  },
};

export default chargePointLogsService;
