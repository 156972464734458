import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { toEmspAccount, toEmspAccountAdmin, toEmspAccountUser } from '../converter/accounts/ToAccount';
import { toTransactionSummary } from '../converter/transaction/toTransaction';
import { AccountType, EmspAccount, EmspAccountAdmin, EmspAccountUser } from '../types/account/Account';
import { CreateOrUpdateEmspAccountDto, EmspAccountAdminDto, EmspAccountDto, EmspAccountUserDto } from '../types/account/AccountDto';
import { TransactionSummary } from '../types/transaction/Transaction';
import { TransactionSummaryDto } from '../types/transaction/TransactionSummaryDto';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { handleError } from '../utils/error/handleApiError';
import { buildUrlWithQueryString } from '../utils/url/buildQueryString';

const accountService = {
  getAccounts(): Observable<EmspAccount[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomain}/accounts`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return response.response.data.map((account: EmspAccountDto) => toEmspAccount(account));
      }),
      catchError((error) => handleError(error))
    );
  },
  getAccountById(accountId: string): Observable<EmspAccount> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomain}/accounts/${accountId}`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return toEmspAccount(response.response);
      }),
      catchError((error) => handleError(error, 'account'))
    );
  },
  createAccount: (accountName: string, orgId: string, accountType: AccountType): Observable<EmspAccount> => {
    const body: CreateOrUpdateEmspAccountDto = { name: accountName, organisation_id: orgId, account_type: accountType };
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/accounts`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        body: body
      })
    ).pipe(
      map((response) => toEmspAccount(response.response)),
      catchError((error) => handleError(error))
    );
  },
  // Account Admins
  getAccountAdmins: (accountId: string): Observable<EmspAccountAdmin[]> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/accounts/${accountId}/admins`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return response.response.data.map((accountAdmin: EmspAccountAdminDto) => toEmspAccountAdmin(accountAdmin));
      }),
      catchError((error) => handleError(error))
    ),
  addAccountAdmin: (accountId: string, email: string): Observable<void> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/accounts/${accountId}/admins`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        body: { email }
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => handleError(error))
    ),
  removeAccountAdmin: (accountId: string, userId: string): Observable<void> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/accounts/${accountId}/admins/${userId}`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => handleError(error))
    ),
  // Account Users
  getAccountUsers: (accountId: string): Observable<EmspAccountUser[]> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/accounts/${accountId}/users`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return response.response.data.map((accountUser: EmspAccountUserDto) => toEmspAccountUser(accountUser));
      }),
      catchError((error) => handleError(error, 'account'))
    ),
  addAccountUser: (accountId: string, email: string): Observable<void> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/accounts/${accountId}/users`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        body: { email }
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => handleError(error))
    ),
  removeAccountUser: (accountId: string, userId: string): Observable<void> =>
    withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/accounts/${accountId}/users/${userId}`,
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => handleError(error))
    ),
  updateAccount(accountId: string, accountName?: string, orgId?: string, accountType?: AccountType): Observable<void> {
    const body: Partial<CreateOrUpdateEmspAccountDto> = { name: accountName, organisation_id: orgId, account_type: accountType };
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomain}/accounts/${accountId}`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'PATCH',
        body: body
      })
    ).pipe(
      map((response) => response.response),
      catchError((error) => handleError(error))
    );
  },
  getAccountTransactions(accountId: string): Observable<TransactionSummary[]> {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomain}/accounts/${accountId}/transactions`);
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => response.response.data.map((transaction: TransactionSummaryDto) => toTransactionSummary(transaction))),
      catchError((error) => handleError(error, 'account'))
    );
  }
};

export default accountService;
