import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { SuccessBar } from '../../commons/snackbars/snackbars';
import { AdminPermission, requiresAdminPermission } from '../../hooks/useAdminPermissions';
import paths from '../../routes/paths';
import { NewAccountFlowState } from '../../types/account/AccountState';
import Dashboard from '../Dashboard/Dashboard';
import AccountDetailsTabs from './AccountDetailsTabs';
import useAccountDetails, { AccountDetailsEvent, AccountDetailsFlowState } from './hook/useAccountDetails';
import { withAccountDetails } from './hook/withAccountDetails';

export interface AccountDetailsMatchParams {
  accountId: string;
}

type NewAccountDetailsState = {
  showAccountCreatedSuccessBar: boolean;
};

const AccountDetails = () => {
  const { state, addEvent } = useAccountDetails();
  const match = useRouteMatch<AccountDetailsMatchParams>([
    paths.ACCOUNT_DETAILS,
    paths.ACCOUNT_ADMINS,
    paths.ACCOUNT_USERS,
    paths.ACCOUNT_TRANSACTION
  ]);
  const accountId = match?.params.accountId;
  const location = useLocation<NewAccountDetailsState>();

  useEffect(() => {
    if (state.flowState === AccountDetailsFlowState.INIT || state.flowState === NewAccountFlowState.NEW_ACCOUNT_CREATED) {
      addEvent({ type: AccountDetailsEvent.LOAD_ACCOUNT, payload: { id: accountId } });
    }
  }, [state.flowState, addEvent, accountId]);

  return (
    <>
      <Dashboard pageHeading={state.account?.name}>
        <LoadingContainerWithErrorPanel {...state.loadingState}>
          {state.account && <AccountDetailsTabs account={state.account} />}
        </LoadingContainerWithErrorPanel>
      </Dashboard>
      <SuccessBar
        fullPage={false}
        open={location.state?.showAccountCreatedSuccessBar}
        onClose={() => window.history.replaceState({ showAccountCreatedSuccessBar: false }, '')}
      >
        New Account {state.account?.name ?? ''} has been created.
      </SuccessBar>
    </>
  );
};

export default requiresAdminPermission(AdminPermission.ACCOUNT_ADMIN)(withAccountDetails(AccountDetails));
