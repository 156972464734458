export enum TariffProfilesEvent {
  LOAD_TARIFF_PROFILES = 'LOAD_TARIFF_PROFILES',
}

interface LoadTariffProfiles {
  type: TariffProfilesEvent.LOAD_TARIFF_PROFILES;
  payload: { cpoCountryCode: string, cpoPartyId: string };
}

export type TariffProfilesEventTypes =
  | LoadTariffProfiles
