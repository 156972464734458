import _, { delay } from 'lodash';
import { createContext, FC, useContext, useReducer } from 'react';
import { AddEventType, ReducerContext } from '../../../../../../types/SharedStates';
import { DecommissionChargerEvent, DecommissionChargerEventType } from '../../../../../../types/charger/DecommissionChargerEventType';
import cpoAdminService from '../../../../../../services/cpoAdminService';

export enum DecommissionChargerFlowState {
  INIT = 'INIT',
  SUBMITTING = 'SUBMITTING',
  CHARGER_DECOMMISSIONED = 'CHARGER_DECOMMISSIONED',
  FAILED_TO_DECOMMISSION_CHARGER = 'FAILED_TO_DECOMMISSION_CHARGER'
}

export interface DecommissionChargerState {
  flowState: DecommissionChargerFlowState;
}

const initialState: DecommissionChargerState = {
  flowState: DecommissionChargerFlowState.INIT
};

const DecommissionChargerContext = createContext<ReducerContext<DecommissionChargerState>>({
  state: initialState,
  dispatch: () => initialState
});

const reducer = (state: DecommissionChargerState, newState: Partial<DecommissionChargerState>) => ({ ...state, ...newState });

export const DecommissionChargerProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));

  return <DecommissionChargerContext.Provider value={{ state, dispatch }}>{children}</DecommissionChargerContext.Provider>;
};

interface UseDecommissionChargerType {
  state: DecommissionChargerState;
  addEvent: AddEventType<DecommissionChargerEvent>;
}

const useDecommissionCharger = (): UseDecommissionChargerType => {
  const { state, dispatch } = useContext(DecommissionChargerContext);

  const addEvent = (event: DecommissionChargerEvent) => {
    switch (event.type) {
      case DecommissionChargerEventType.SUBMIT_DECOMMISSION_CHARGER:
        dispatch({
          flowState: DecommissionChargerFlowState.SUBMITTING
        });

        cpoAdminService.decommissionChargePoint(event.payload.cpoChargePointId).subscribe(
          () =>
            delay(() => {
              dispatch({
                flowState: DecommissionChargerFlowState.CHARGER_DECOMMISSIONED
              });
            }, 1000),
          (error) =>
            dispatch({
              flowState: DecommissionChargerFlowState.FAILED_TO_DECOMMISSION_CHARGER
            })
        );
        break;

      case DecommissionChargerEventType.CLEAR_FAILED_STATE:
        dispatch({
          flowState: DecommissionChargerFlowState.INIT
        });
        break;

      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return { state, addEvent };
};

export default useDecommissionCharger;
