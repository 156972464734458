import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import mainTheme from '../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';
import _ from 'lodash';
import {Checkbox, TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline',
    marginRight: theme.spacing(2),
    '& $inputRoot $input': {
      width: 'auto',
    },
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  // this class is used to increase the specificity
  inputRoot: {},
  input: {
    '&::placeholder': {
      opacity: 1,
    },
  },
}));

const SimpleMultiAutoComplete = ({placeholderWithNoValues, placeholderWithSelectedValues, defaultValues, options, onChange, onClose, size}) => {
  const classes = useStyles(mainTheme);

  const placeholder = _.isEmpty(defaultValues) ? placeholderWithNoValues : placeholderWithSelectedValues;
  const defaultSelectedOptions = defaultValues.map((defaultValue) => _.find(options, ['value', defaultValue]));
  const [selectedOptions, setSelectedOptions] = useState(defaultSelectedOptions);
  const emptyInputValue = '';
  const [inputValue, setInputValue] = useState(emptyInputValue);

  useEffect(() => {
    const defaultSelectedOptions = defaultValues.map((defaultValue) => _.find(options, ['value', defaultValue]));
    setSelectedOptions(defaultSelectedOptions);
  }, [options, defaultValues]);

  // we record the newly selected options here but don't call onChange that may trigger a redraw
  const handleChange = (event, newlySelectedOptions) => {
    setSelectedOptions(newlySelectedOptions);

    // call on change if exists
    if (onChange) {
      const selectedValues = _.map(newlySelectedOptions, 'value');
      onChange(selectedValues);
    }
  };

  const handleInputChange = (event, inputValue, reason) => {
    // A 'reset' event is fired here with an empty input value when:
    // * user selects/deselects an item
    // * or when user click away from the input field
    // this causes the drop down to redraw -> this is undesired behaviour
    // what we want is when user selects/deselects an item, the drop down list sticks around until the user clicks away

    // what we are doing here is ignore reset event and use onClose={handleClose} to clear the input value when the user clicks away
    if (reason !== 'reset') {
      setInputValue(inputValue);
    }
  };

  const handleClose = () => {
    // reset the input value
    setInputValue(emptyInputValue);

    // call on close if exists
    if (onClose) {
      const selectedValues = _.map(selectedOptions, 'value');
      onClose(selectedValues);
    }
  };

  return (
    <Autocomplete
      classes={{root: classes.root, inputRoot: classes.inputRoot, input: classes.input}}
      multiple
      disableClearable
      autoHighlight
      openOnFocus
      disableCloseOnSelect
      inputValue={inputValue}
      value={selectedOptions}
      options={options}
      getOptionSelected={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.displayValue}
      renderInput={(params) => <TextField className={classes.textField} {...params} variant="outlined" placeholder={placeholder} fullWidth={false} />}
      renderOption={(option, {selected}) => (
        <>
          <Checkbox checked={selected} color="primary" />
          <Typography>{option.displayValue}</Typography>
        </>
      )}
      renderTags={(value, getTagProps) => <></>}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onClose={handleClose}
      size={size}
    />
  );
};

SimpleMultiAutoComplete.defaultProps = {
  size: 'small', // "small" || "medium"
};

SimpleMultiAutoComplete.propTypes = {
  placeholderWithNoValues: PropTypes.string.isRequired,
  placeholderWithSelectedValues: PropTypes.string.isRequired,
  defaultValues: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default SimpleMultiAutoComplete;
