import { InputAdornment } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import { createRef, useEffect } from 'react';
import { FaDollarSign } from 'react-icons/fa';
import * as Yup from 'yup';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import { getModifiedValues } from '../../../commons/form/FormikFunctions';
import SmartSelectField from '../../../commons/form/SmartSelectField';
import SmartTextField from '../../../commons/form/SmartTextField';
import CancelButton from '../../../commons/form/button/CancelButton';
import UpdateButton from '../../../commons/form/button/UpdateButton';
import PageWithColumns from '../../../commons/layout/PageWithColumns';
import { LocationDetailsFlowState } from '../../../types/LocationDetails';
import { LocationType } from '../../../types/location/Location';
import useLocations, { LocationEvent } from '../hook/useLocations';
import { electrictyCostValidation } from './AdvancedConfigValidation';

const BasicConfigEditForm = () => {
  const {
    state: { flowState, loadingState, emspLocation },
    addEvent
  } = useLocations();
  const formikRef = createRef();

  const handleCancel = () => {
    addEvent({
      type: LocationEvent.CANCEL_UPDATE_BASIC_CONFIG_FORM
    });
  };

  useEffect(() => {
    if (flowState === LocationDetailsFlowState.FAILED_TO_UPDATE_BASIC_CONFIG) {
      formikRef.current.setSubmitting(false);
    }
  }, [flowState, formikRef]);

  const initialValues = emspLocation
    ? {
        electricityCost: emspLocation.electricityCost,
        status: emspLocation.status,
        locationType: emspLocation.locationType
      }
    : {
        electricityCost: 0
      };

  const validationSchema = Yup.object({
    electricityCost: electrictyCostValidation
  });

  // Only show location type that is not public
  const locationTypeOptions = Object.keys(LocationType)
    .filter((key) => key !== LocationType.PUBLIC)
    .map((key) => {
      return { value: LocationType[key], displayValue: _.capitalize(LocationType[key]) };
    });

  const handleSubmit = (values) => {
    const modifiedValues = getModifiedValues(values, initialValues);
    addEvent({
      type: LocationEvent.SUBMIT_UPDATE_BASIC_CONFIG_FORM,
      payload: {
        id: emspLocation.id,
        ...modifiedValues
      }
    });
  };

  return (
    <LoadingContainerWithErrorPanel {...loadingState}>
      <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {(formikProps) => {
          return (
            <Form>
              <PaperWithTitle
                title="Basic Configuration"
                children={
                  <PageWithColumns
                    columnOne={
                      <>
                        <Field
                          label="Electricity Cost"
                          name="electricityCost"
                          autoFocus
                          helperText="Cost of electricity per kWh in NZD"
                          component={SmartTextField}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" style={{ outline: 'none' }}>
                                <FaDollarSign fontSize="default"></FaDollarSign>
                              </InputAdornment>
                            )
                          }}
                        />
                        {emspLocation.locationType !== LocationType.PUBLIC && (
                          <Field
                            label="Location Type"
                            name="locationType"
                            autoFocus
                            helperText="Location Type of the location"
                            component={SmartSelectField}
                            options={locationTypeOptions}
                            fullWidth
                          />
                        )}
                      </>
                    }
                    columnTwo={false}
                  />
                }
                bottomRightComponent={
                  <>
                    <CancelButton handleCancel={handleCancel} />
                    <UpdateButton formikProps={formikProps} />
                  </>
                }
              />
            </Form>
          );
        }}
      </Formik>
    </LoadingContainerWithErrorPanel>
  );
};

export default BasicConfigEditForm;
