import { FC, useCallback, useMemo, useState } from 'react';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { useLoadingContainerWithErrorPanel } from '../../hooks/useLoadingContainerWithErrorPanel';
import reportService from '../../services/reportService';
import ChargersTable from './ChargersReportTable';

const ChargersReport: FC = () => {
  const [chargers, setChargers] = useState([]);

  const observable = useMemo(() => reportService.getChargers(), []);
  const onSuccessCallback = useCallback((response) => setChargers(response), []);
  const onErrorCallback = useCallback((error) => setChargers([]), []);

  const loadingContainerWithErrorPanelState = useLoadingContainerWithErrorPanel(observable, onSuccessCallback, onErrorCallback);

  return (
    <LoadingContainerWithErrorPanel {...loadingContainerWithErrorPanelState}>
      <ChargersTable chargers={chargers} />
    </LoadingContainerWithErrorPanel>
  );
};

export default ChargersReport;
