import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {withRouter} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import {Typography} from '@material-ui/core';
import paths from '../../routes/paths';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {BiLogOutCircle} from 'react-icons/all';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
  },
  drawerText: {
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.primary.contrastText,
  },
  listItemIcon: {
    marginRight: theme.spacing(0.5),
    minWidth: 24,
    color: theme.palette.primary.contrastText,
  },
}));

const LogOutMenuItem = ({history}) => {
  const classes = useStyles(mainTheme);

  const onClickHandler = (event) => {
    event.preventDefault();
    history.push(paths.LOGOUT);
  };

  return (
    <ListItem
      classes={{
        root: classes.root,
      }}
      button
      key={'Log Out'}
      onClick={onClickHandler}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <BiLogOutCircle fontSize="large" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography component="span" className={classes.drawerText}>
            Log Out
          </Typography>
        }
      />
    </ListItem>
  );
};

export default withRouter(LogOutMenuItem);
