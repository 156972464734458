import React from 'react';
import UpdateChargePointForm from './UpdateChargePointForm';
import useChargePoints from '../../ChargePoints/hook/useChargePoints';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';

const UpdateChargePointFormContainer = () => {
  const {state} = useChargePoints();

  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      <UpdateChargePointForm />
    </LoadingContainerWithErrorPanel>
  );
};

export default UpdateChargePointFormContainer;
