import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import { createContext, Dispatch, FC, PropsWithChildren, useContext, useReducer } from 'react';
import { AccountState, UseAccountsType } from '../../../types/account/AccountState';
import _ from 'lodash';
import { EventType } from '../../../types/SharedStates';
import accountService from '../../../services/accountService';

export enum AccountEvent {
  LOAD_ACCOUNTS = 'LOAD_ACCOUNTS'
}

export enum AccountFlowState {
  INIT = 'INIT',
  ACCOUNTS_LOADED = 'ACCOUNTS_LOADED',
  FAILED_TO_LOAD_ACCOUNTS = 'FAILED_TO_LOAD_ACCOUNTS'
}

const initialState = {
  loadingState: loadingContainerInitialState,
  accounts: [],
  flowState: AccountFlowState.INIT
};

// Reducer
const reducer = (state: AccountState, newState: Partial<AccountState>) => ({ ...state, ...newState });

// Context
const AccountContext = createContext<{ state: AccountState; dispatch: Dispatch<Partial<AccountState>> }>({
  state: initialState,
  dispatch: () => initialState
});

// Provider
export const AccountProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return <AccountContext.Provider value={{ state, dispatch }}>{children}</AccountContext.Provider>;
};

// Hook
const useAccounts = (): UseAccountsType => {
  const { state, dispatch } = useContext(AccountContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case AccountEvent.LOAD_ACCOUNTS:
        accountService.getAccounts().subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              accounts: result,
              flowState: AccountFlowState.ACCOUNTS_LOADED
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: AccountFlowState.FAILED_TO_LOAD_ACCOUNTS
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};

export default useAccounts;
