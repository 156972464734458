import {Box, makeStyles} from '@material-ui/core';
import React from 'react';
import BoxWithLabel from '../../../commons/containers/ComponentWithLabel/BoxWithLabel';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import mainTheme from '../../../themes/mainTheme';
import ChargePointConnectorIcon from "../ChargePointConnector/ChargePointConnectorIcon";
import ChargePointConnectorStatus from "../ChargePointConnector/ChargePointConnectorStatus";
import {connectorTypeMap} from "../../../domain/connector"

const useStyles = makeStyles((theme) => ({
  evse: {
    borderBottom: `1px dashed ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },
}));

const ConnectorPanels = ({evses}) => {
  const classes = useStyles(mainTheme);
  
  return (
    <>
      {evses?.map((evse) => (
        <Box classes={{root: classes.evse}} key={evse.id}>
          <Box>
            <ChargePointConnectorIcon type={evse.connector.standard} />
            <TextWithLabel label='Type' text={connectorTypeMap.get(evse.connector.standard)} />
            <BoxWithLabel label='Status'>
              <ChargePointConnectorStatus status={evse.status} />
            </BoxWithLabel>
            {evse.connector.id && <TextWithLabel label='OCPP ID' text={evse.connector.id} />}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default ConnectorPanels;
