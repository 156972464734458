import React, {useState} from 'react';
import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import {Tab} from '@material-ui/core';
import UserGroupInfoPanel from './UserGroupInfoPanel';
import UserGroupUserList from './UserGroupUserListPanel/UserGroupUserList';

const UserGroupDetailsTabs = ({userGroup}) => {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange} aria-label="User Fleet Tabs" indicatorColor="primary" textColor="primary">
        <Tab label="Details" value="1" />
        <Tab label="Users" value="2" />
      </TabList>
      <TabPanel value="1">
        <UserGroupInfoPanel userGroup={userGroup} />
      </TabPanel>
      <TabPanel value="2">
        <UserGroupUserList userGroup={userGroup} />
      </TabPanel>
    </TabContext>
  );
};

export default UserGroupDetailsTabs;
