import React from 'react';
import PropTypes from 'prop-types';
import {withChargePointGroupUserList} from './hook/withChargePointGroupUserList';
import {accessType} from '../../../services/chargePointGroupService';
import PrivateChargePointGroupUserList from './PrivateChargePointGroupUserList';
import PublicChargePointGroupUserList from './PublicChargePointGroupUserList';

const ChargePointGroupUserListPanel = ({chargePointGroup}) =>
  accessType.PUBLIC === chargePointGroup.accessType ? <PublicChargePointGroupUserList chargePointGroup={chargePointGroup} /> : <PrivateChargePointGroupUserList chargePointGroup={chargePointGroup} />;

ChargePointGroupUserListPanel.propTypes = {
  chargePointGroup: PropTypes.object.isRequired,
};

export default withChargePointGroupUserList(ChargePointGroupUserListPanel);
