import Box from '@material-ui/core/Box';
import React from 'react';

const Center = ({children}) => (
  <Box display="flex" minHeight="100vh" alignItems="center" justify="center">
    <Box m="auto">{children}</Box>
  </Box>
);

export default Center;
