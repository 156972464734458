import useUserGroupUserList, {UserGroupUserListEvent, UserGroupUserListFlowState} from './hook/useUserGroupUserList';
import React, {useEffect} from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import UserGroupUserListTable from './UserGroupUserListTable';
import PropTypes from 'prop-types';
import {withUserGroupUserList} from './hook/withUserGroupUserList';

const UserGroupUserList = ({userGroup}) => {
  const {state, addEvent} = useUserGroupUserList();
  useEffect(() => {
    if (state.flowState === UserGroupUserListFlowState.INIT) {
      addEvent({type: UserGroupUserListEvent.LOAD_USER_GROUP_USER_LIST, payload: {userGroup}});
    }
  }, [state.flowState, addEvent, userGroup]);
  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>
        <UserGroupUserListTable />
      </LoadingContainerWithErrorPanel>
    </>
  );
};
UserGroupUserList.propTypes = {
  userGroup: PropTypes.object.isRequired,
};

export default withUserGroupUserList(UserGroupUserList);
