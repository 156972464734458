import React, {useEffect, useState} from 'react';
import {Checkbox, FormControl, ListItemText, MenuItem, Select} from '@material-ui/core';
import {v4 as uuidV4} from 'uuid';
import * as PropTypes from 'prop-types';
import mainTheme from '../../themes/mainTheme';
import {makeStyles} from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  select: {
    padding: '10px 15px 11px 15px',
  },
  selectMenu: {
    // Enable this if you want to control the max height of the menu
    //maxHeight: theme.spacing(27),
  },
}));

const SimpleMultiSelect = ({placeholder, selectedLabel, defaultValues, options, onChange}) => {
  const classes = useStyles(mainTheme);
  const selectId = `select-${uuidV4()}`;

  const [values, setValues] = useState(defaultValues);
  useEffect(() => {
    setValues(defaultValues);
  }, [defaultValues]);

  const handleChange = (event) => {
    setValues(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl classes={{root: classes.root}}>
      <Select
        classes={{root: classes.select}}
        multiple
        value={values}
        onChange={handleChange}
        variant="outlined"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          // TODO: find out why this is needed
          getContentAnchorEl: null,
          PopoverClasses: {paper: classes.selectMenu},
        }}
        // must have it here to have "renderValue" called when nothing is selected
        displayEmpty
        renderValue={(selectedValues) => (_.isEmpty(selectedValues) ? placeholder : selectedLabel)}
      >
        {options.map((option) => (
          <MenuItem key={`${selectId}-${option.value}`} value={option.value}>
            <Checkbox checked={_.includes(values, option.value)} color="primary" />
            <ListItemText primary={option.displayValue} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SimpleMultiSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  selectedLabel: PropTypes.string.isRequired,
  defaultValues: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SimpleMultiSelect;
