import { FC, useEffect } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';

import { AccountTransactionEvent } from '../../../types/account/AccountTransactionEvent';
import TransactionsTable from '../../Transaction/TransactionsTable/TransactionsTable';
import useAccountTransactions, { AccountTransactionsFlowState } from './hook/useAccountTransactions';
import { withAccountTransactions } from './hook/withAccountTransactions';

interface AccountInfoPanelProps {
  accountId: string;
}

const AccountTransactionListPanel: FC<AccountInfoPanelProps> = ({ accountId }) => {
  const { state, addEvent } = useAccountTransactions();

  useEffect(() => {
    if (state.flowState === AccountTransactionsFlowState.INIT) {
      addEvent({
        type: AccountTransactionEvent.LOAD_ACCOUNT_TRANSACTIONS,
        payload: {
          accountId: accountId
        }
      });
    }
  }, [state.flowState, accountId, addEvent]);

  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      <TransactionsTable transactions={state.transactions} />
    </LoadingContainerWithErrorPanel>
  );
};

export default withAccountTransactions(AccountTransactionListPanel);
