import {Box, Step, StepLabel, Stepper, makeStyles} from '@material-ui/core';
import React from 'react';
import useRemoteStartTransactions from './hook/useRemoteStartTransactions';
import mainTheme from '../../themes/mainTheme';
import {RemoteStartFlowState} from './hook/useRemoteStartTransactions';
import {withRemoteStartTransactions} from './hook/withRemoteStartTransactions';
import RemoteStartTransactionForm from './RemoteStartTransactionForms/RemoteStartTransactionForm';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    backgroundColor: 'transparent',
  },
}));

const RemoteStartTransactionContainer = ({chargePointId}) => {
  const {state} = useRemoteStartTransactions();
  const steps = ['Check Eligibility', 'Choose Connector', 'Review'];
  const classes = useStyles(mainTheme);

  const stateToStepMap = {
    [RemoteStartFlowState.INIT]: 0,
    [RemoteStartFlowState.NOT_ELIGIBLE]: 0,
    [RemoteStartFlowState.ELIGIBLE]: 1,
    [RemoteStartFlowState.REVIEW]: 2,
    [RemoteStartFlowState.TRANSACTION_STARTED]: 3,
    [RemoteStartFlowState.FAILED_TO_START]: 3,
  };
  
  const activeStep = stateToStepMap[state.flowState] || 0; 

  return (
    <Box>
      <Stepper activeStep={activeStep} className={classes.stepperContainer}>
        {steps.map((label) => {
          const stepProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <RemoteStartTransactionForm chargePointId={chargePointId}/>
    </Box>
  );
};

RemoteStartTransactionContainer.propTypes = {
  chargePointId: PropTypes.string.isRequired,
}

export default withRemoteStartTransactions(RemoteStartTransactionContainer);
