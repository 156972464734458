import _ from 'lodash';
import React, { createContext, PropsWithChildren, useReducer } from 'react';
import { ChargerRemoteStartFlowState, ChargerRemoteStartState } from '../../../../types/charger/ChargerRemoteStartState';

// initial state
const initialState: ChargerRemoteStartState = {
  flowState: ChargerRemoteStartFlowState.INIT,
  transaction: null,
  errorMessage: null,
  isEligible: false,
  preview: {
    name: null,
    creditCardPaymentRequired: false,
    cardDetails: null,
    postpay: false,
    postpayAccountName: null,
    userName: null
  },
  email: null,
  selectedEvse: null,
  selectedConnector: null,
  evses: []
};

const reducer = (state: any, newState: any) => ({ ...state, ...newState });

// context
export const ChargerRemoteStartContext = createContext<ChargerRemoteStartState | any>(initialState);

// provider
export const ChargerRemoteStartProvider: ({ children }: React.PropsWithChildren<any>) => React.JSX.Element = ({
  children
}: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return <ChargerRemoteStartContext.Provider value={{ state, dispatch }}>{children}</ChargerRemoteStartContext.Provider>;
};
