import {CpoTariffDto} from "../../types/tariff/CpoTariffDto";
import {CpoTariff} from "../../types/tariff/CpoTariff";

export const toCpoTariff = (dto: CpoTariffDto): CpoTariff => ({
    id: dto.id,
    cpoTariffId: dto.cpo_tariff_id,
    displayText: dto.display_text,
    ratePerMinute: dto.rate_per_minute,
    ratePerKwh: dto.rate_per_kwh,
    ratePerParkingMinute: dto.rate_per_parking_minute,
    unlockFee: dto.unlock_fee,
    appliedMargin: dto.applied_margin
})