import _ from 'lodash';
import { useEffect, useReducer } from 'react';

// initial state
export const initialState = {
  loading: true,
  hasError: false,
  errorMessage: null,
  status: null
};

export const succeededState = {
  loading: false,
  hasError: false,
  errorMessage: null,
  status: null
};

export const failedState = (errorMessage, status = null) => ({
  loading: false,
  hasError: true,
  errorMessage: errorMessage,
  status: status
});

// action types
const actionTypes = {
  success: 'success',
  error: 'error'
};

// reducer for hook state and actions
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.success:
      return {
        ...state,
        ...succeededState
      };
    case actionTypes.error:
      return {
        ...state,
        ...failedState(action.errorMessage)
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

/**
 * A Hook that takes an observable, and calls the given success handler or error handler when the observable emits values.
 * The hook returns a state object that can be used with <LoadingContainerWithErrorPanel> component.
 *
 * Example:
 * <pre>
 *   <code>
 *     const memoizedObservable = useMemo(() => someService.getObservable(), []);
 *     const onSuccessCallback = useCallback((response) => setSomeState(response), []);
 *     const onErrorCallback = useCallback(() => setSomeState({}), []);
 *     const loadingContainerWithErrorPanelState = useLoadingContainerWithErrorPanel(memoizedObservable, onSuccessCallback, onErrorCallback);
 *   </code>
 * </pre>
 *
 * @param observable a memoized observable (the observable must be wrapped in the useMemo react hook)
 * @param onSuccess a memoized callback to call when the observable has a value (the callback must be wrapped in the useCallback react hook)
 * @param onError a memoized callback to call when the observable has an error (the callback must be wrapped in the useCallback react hook)
 * @returns A state object that reflects the state of the observable
 */
export const useLoadingContainerWithErrorPanel = (observable, onSuccess, onError) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));

  useEffect(() => {
    const subscription = observable.subscribe(
      (response) => {
        onSuccess(response);
        dispatch({ type: actionTypes.success });
      },
      (error) => {
        onError(error);
        dispatch({ type: actionTypes.error, errorMessage: error.message });
      }
    );

    return () => subscription.unsubscribe();
  }, [observable, onSuccess, onError]);
  return state;
};
