import { AccountType } from '../../types/account/Account';

export const formatAccountType = (type: AccountType): string => {
  switch (type) {
    case AccountType.POSTPAY:
      return 'Postpay';
    case AccountType.PAYG:
      return 'Pay as you go';
    default:
      return type;
  }
};
