import React, { FC, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import { CpoChargePoint, CpoConnector, EmspCharger } from '../../../types/charger/Charger';
import LegacyChargerTariffs from './LegacyChargerTariffs';
import CpoChargerTariffs from './CpoChargerTariffs';
import UpdateCpoChargerTariffsForm from './UpdateCpoChargerTariffsForm';
import useChargerInfo from '../ChargerDetails/ChargerInfo/hook/useChargerInfo';
import { CpoConnectors } from '../ChargerDetails/ChargerInfo/ChargerInfo';
import { ErrorBar, ProcessingBar, SuccessBar } from '../../../commons/snackbars/snackbars';
import _ from 'lodash';
import { ChargerInfoFlowState } from '../../../types/charger/ChargerInfoState';
import { withChargerInfo } from '../ChargerDetails/ChargerInfo/hook/withChargerInfo';
import { FeatureFlag, useFeatureFlag } from '../../../hooks/useFeatureFlags';

type ChargerTariffsProps = {
  emspChargerFromParent: EmspCharger;
  cpoChargePointFromParent?: CpoChargePoint;
  handleReload: () => void;
};

const ChargerTariffs: FC<ChargerTariffsProps> = ({ emspChargerFromParent, cpoChargePointFromParent, handleReload }) => {
  const { state, addEvent, ChargerInfoEvent } = useChargerInfo();
  const emspChargerDisplayName = emspChargerFromParent.displayName;
  const isAnonymousChargingAllowed = cpoChargePointFromParent?.anonymousChargingAllowed ?? false;
  const cpoChargePoint = state.cpoChargePoint;
  const isCentralServerCharger = !_.isNil(cpoChargePoint);

  const showCpoTariffAdmin = useFeatureFlag(FeatureFlag.CPO_TARIFF_ADMINISTRATION);

  useEffect(() => {
    if (state.flowState === ChargerInfoFlowState.INIT || cpoChargePointFromParent !== cpoChargePoint) {
      addEvent({ type: ChargerInfoEvent.VIEW_CHARGER, payload: { emspCharger: emspChargerFromParent, cpoChargePoint: cpoChargePointFromParent } });
    } else if (state.flowState === ChargerInfoFlowState.CHARGER_UPDATED || state.flowState === ChargerInfoFlowState.EVSES_UPDATED) {
      addEvent({ type: ChargerInfoEvent.RELOAD_CHARGER, payload: { emspCharger: emspChargerFromParent, cpoChargePoint: null } });
      handleReload();
    }
  }, [
    state.flowState,
    addEvent,
    handleReload,
    emspChargerFromParent,
    cpoChargePointFromParent,
    cpoChargePoint,
    ChargerInfoEvent.VIEW_CHARGER,
    ChargerInfoEvent.RELOAD_CHARGER
  ]);

  const handleConnectorsEditClicked = () => {
    addEvent({ type: ChargerInfoEvent.EDIT_CONNECTORS_TARIFF_REQUESTED });
  };

  const handleCancelAction = () => {
    addEvent({ type: ChargerInfoEvent.CHARGER_ACTION_CANCELLED });
  };

  const identifyUpdatedConnectors = (originalConnectors: CpoConnector[], connectorsInTheForm: CpoConnector[]) => {
    return connectorsInTheForm.filter((newConnector) => {
      const originalConnector = originalConnectors.find((conn) => conn.ocppConnectorId === newConnector.ocppConnectorId);
      return originalConnector && originalConnector.tariffId !== newConnector.tariffId;
    });
  };

  const handleSubmitEditConnectors = (values: CpoConnectors) => {
    const originalConnectors = state.cpoChargePoint?.evses.flatMap((evse) => evse.connectors) || [];
    const connectorsInTheForm = values.connectors;
    const chargePointId = state.cpoChargePoint?.id;

    const updatedConnectors = identifyUpdatedConnectors(originalConnectors, connectorsInTheForm);

    const payload = {
      chargePointId,
      evses: state.cpoChargePoint?.evses.map((evse) => ({
        evseId: evse.id,
        connectors: updatedConnectors.filter((updatedConnector) =>
          evse.connectors.some((originalConnector) => originalConnector.id === updatedConnector.id)
        )
      }))
    };

    addEvent({
      type: ChargerInfoEvent.SUBMIT_EDIT_CONNECTORS_TARIFF,
      payload: payload
    });
  };

  const showProcessing = state.flowState === ChargerInfoFlowState.UPDATING_CHARGER || state.flowState === ChargerInfoFlowState.UPDATING_EVSES;
  const showEditConnectorsForm =
    state.flowState === ChargerInfoFlowState.SHOW_EDIT_EVSES ||
    state.flowState === ChargerInfoFlowState.FAILED_TO_UPDATE_EVSES ||
    state.flowState === ChargerInfoFlowState.UPDATING_EVSES;

  return isAnonymousChargingAllowed ? (
    <PaperWithTitle>
      <Typography align="center">Charger {emspChargerDisplayName} is in free vending mode</Typography>
    </PaperWithTitle>
  ) : (
    <>
      <LegacyChargerTariffs emspChargerDisplayName={emspChargerDisplayName} isAnonymousChargingAllowed={isAnonymousChargingAllowed} />
      {showCpoTariffAdmin && (
        <>
          {showEditConnectorsForm && cpoChargePointFromParent !== undefined ? (
            <UpdateCpoChargerTariffsForm
              emspChargerFromParent={emspChargerFromParent}
              evses={cpoChargePointFromParent.evses}
              handleCancel={handleCancelAction}
              hasFailed={state.flowState === ChargerInfoFlowState.FAILED_TO_UPDATE_EVSES}
              handleSubmit={handleSubmitEditConnectors}
            />
          ) : (
            <CpoChargerTariffs
              emspChargerFromParent={emspChargerFromParent}
              isCentralServerCharger={isCentralServerCharger}
              handleEdit={handleConnectorsEditClicked}
            />
          )}
          <SuccessBar
            open={!_.isEmpty(state.successMessage)}
            fullPage={false}
            onClose={() => {
              addEvent({ type: ChargerInfoEvent.CLOSE_SUCCESS_DIALOG });
            }}
          >
            {state.successMessage}
          </SuccessBar>
          <ProcessingBar open={showProcessing} fullPage={false} />
          <ErrorBar
            open={!_.isEmpty(state.errorMessage)}
            fullPage={false}
            onClose={() => {
              addEvent({ type: ChargerInfoEvent.CLOSE_ERROR_DIALOG });
            }}
          >
            {state.errorMessage}
          </ErrorBar>
        </>
      )}
    </>
  );
};
export default withChargerInfo(ChargerTariffs);
