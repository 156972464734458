export const DASHBOARD_PATH = '/dashboard';

const paths = {
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  PAGE_NOT_FOUND: `${DASHBOARD_PATH}/not-found`,
  INSIGHTS: `${DASHBOARD_PATH}/insights`,
  USERS: `${DASHBOARD_PATH}/users`,
  NEW_USER: `${DASHBOARD_PATH}/users/new`,
  USER_DETAILS: `${DASHBOARD_PATH}/users/:userId`,
  CHARGE_POINTS_LEGACY: `${DASHBOARD_PATH}/charge-points-legacy`,
  CHARGERS: `${DASHBOARD_PATH}/chargers`,
  CHARGER: `${DASHBOARD_PATH}/chargers/:chargerDisplayName`,
  CHARGER_DETAILS: `${DASHBOARD_PATH}/locations/:cpoCountryCode/:cpoPartyId/:locationId/chargers/:chargerDisplayName`,
  CHARGER_LOGS: `${DASHBOARD_PATH}/locations/:cpoCountryCode/:cpoPartyId/:locationId/chargers/:chargerDisplayName/logs`,
  CHARGER_INFO_DETAILS: `${DASHBOARD_PATH}/locations/:cpoCountryCode/:cpoPartyId/:locationId/chargers/:chargerDisplayName/details`,
  CHARGER_CONFIGURATIONS: `${DASHBOARD_PATH}/locations/:cpoCountryCode/:cpoPartyId/:locationId/chargers/:chargerDisplayName/configurations`,
  CHARGER_TARIFFS: `${DASHBOARD_PATH}/locations/:cpoCountryCode/:cpoPartyId/:locationId/chargers/:chargerDisplayName/tariffs`,
  CHARGER_TRANSACTIONS: `${DASHBOARD_PATH}/locations/:cpoCountryCode/:cpoPartyId/:locationId/chargers/:chargerDisplayName/transactions`,
  REMOTE_START_CHARGER: `${DASHBOARD_PATH}/locations/:cpoCountryCode/:cpoPartyId/:locationId/chargers/:chargerDisplayName/remote-start`,
  NEW_CHARGER: `${DASHBOARD_PATH}/chargers/new`,
  NEW_CHARGER_FOR_LOCATION: `${DASHBOARD_PATH}/locations/:cpoCountryCode/:cpoPartyId/:locationId/chargers/new`,
  TRANSACTIONS: `${DASHBOARD_PATH}/transactions`,
  TRANSACTION_DETAILS: `${DASHBOARD_PATH}/transactions/:transactionId`,
  CHARGE_POINT_DETAILS: `${DASHBOARD_PATH}/charge-points/:chargerDisplayName`,
  CHARGE_POINT_DETAILS_LEGACY: `${DASHBOARD_PATH}/charge-points-legacy/:chargePointId`,
  REPORTS: `${DASHBOARD_PATH}/reports`,
  REPORTS_CHARGE_POINTS: `${DASHBOARD_PATH}/reports/charge-points`,
  REPORTS_POSTPAY_TRANSACTIONS: `${DASHBOARD_PATH}/reports/post-pay-transactions`,
  REPORTS_CHARGERS: `${DASHBOARD_PATH}/reports/chargers`,
  CHARGE_POINT_FLEETS: `${DASHBOARD_PATH}/charge-point-fleets`,
  CHARGE_POINT_FLEET_DETAILS: `${DASHBOARD_PATH}/charge-point-fleets/:chargePointFleetId`,
  CHARGE_POINT_FLEET_TRANSACTIONS: `${DASHBOARD_PATH}/charge-point-fleet-transactions`,
  USER_FLEETS: `${DASHBOARD_PATH}/user-fleets`,
  USER_FLEET_DETAILS: `${DASHBOARD_PATH}/user-fleets/:userFleetId`,
  USER_FLEET_USER_DETAILS: `${DASHBOARD_PATH}/user-fleets/:userFleetId/users/:userId`,
  NEW_CHARGE_POINT: `${DASHBOARD_PATH}/new-charge-point`,
  REMOTE_START_LEGACY: `${DASHBOARD_PATH}/charge-points-legacy/:chargePointId/remote-start`,
  LOCATIONS: `${DASHBOARD_PATH}/locations`,
  NEW_LOCATION: `${DASHBOARD_PATH}/locations/new`,
  LOCATION_DETAILS: `${DASHBOARD_PATH}/locations/:cpoCountryCode/:cpoPartyId/:locationId/details`,
  LOCATION_CHARGERS: `${DASHBOARD_PATH}/locations/:cpoCountryCode/:cpoPartyId/:locationId/chargers`,
  LOCATION_USERS: `${DASHBOARD_PATH}/locations/:cpoCountryCode/:cpoPartyId/:locationId/users`,
  LOCATION_ADMINS: `${DASHBOARD_PATH}/locations/:cpoCountryCode/:cpoPartyId/:locationId/admins`,
  ORGANISATIONS: `${DASHBOARD_PATH}/organisations`,
  NEW_ORGANISATION: `${DASHBOARD_PATH}/organisations/new`,
  ORGANISATION_DETAILS: `${DASHBOARD_PATH}/organisations/:organisationId/details`,
  ORGANISATION_ADMINS: `${DASHBOARD_PATH}/organisations/:organisationId/admins`,
  ORGANISATION_ACCOUNTS: `${DASHBOARD_PATH}/organisations/:organisationId/accounts`,
  ORGANISATION_CHARGERS: `${DASHBOARD_PATH}/organisations/:organisationId/chargers`,
  ACCOUNTS: `${DASHBOARD_PATH}/accounts`,
  NEW_ACCOUNT: `${DASHBOARD_PATH}/accounts/new`,
  ACCOUNT_DETAILS: `${DASHBOARD_PATH}/accounts/:accountId/details`,
  ACCOUNT_ADMINS: `${DASHBOARD_PATH}/accounts/:accountId/admins`,
  ACCOUNT_USERS: `${DASHBOARD_PATH}/accounts/:accountId/users`,
  ACCOUNT_TRANSACTION: `${DASHBOARD_PATH}/accounts/:accountId/transactions`
};

export default paths;
