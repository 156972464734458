import { CpoChargerLogDto, CpoChargerLogsDto } from '../../types/charger/ChargerLogsDto';
import { ChargerLog, ChargerLogs } from '../../types/charger/ChargerLogs';

const toChargerLog = (dto: CpoChargerLogDto): ChargerLog => {
  return {
    message: dto.message,
    messageId: dto.message_id,
    event: dto.event,
    serverTimestamp: dto.server_timestamp,
    chargePointTimestamp: dto.charge_point_timestamp,
    raw: dto.raw
  };
};

export const toChargerLogs = (dto: CpoChargerLogsDto): ChargerLogs => {
  const result = {
    data: dto.data.map(toChargerLog),
    nextToken: dto.next_token
  };
  return result;
};
