import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FeatureFlag, useFeatureFlag } from '../../hooks/useFeatureFlags';
import UserAccountBalance from './UserAccountBalance/UserAccountBalance';
import UserChargePointFleets from './UserChargePointFleets/UserChargePointFleets';
import UserChargeTags from './UserChargeTags/UserChargeTags';
import UserPersonalDetails from './UserPersonalDetails/UserPersonalDetails';
import UserTransactions from './UserTransactions/UserTransactions';

const UserDetailsTabs = ({ userId, state }) => {
  const showLegacyManagement = useFeatureFlag(FeatureFlag.LEGACY_CHARGE_POINT_MANAGEMENT);
  const [value, setValue] = useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [<Tab label="Details" value="1" />, <Tab label="Charge Tags" value="2" />, <Tab label="Transactions" value="3" />];

  if (showLegacyManagement) tabs.push(<Tab label="Charge Point Fleets" value="4" />);

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange} aria-label="User Details Tabs" indicatorColor="primary" textColor="primary">
        {tabs}
      </TabList>
      <TabPanel value="1">
        <>
          <UserPersonalDetails userId={userId} state={state} />
          <UserAccountBalance userId={userId} />
        </>
      </TabPanel>
      <TabPanel value="2">
        <UserChargeTags userId={userId} />
      </TabPanel>
      <TabPanel value="3">
        <UserTransactions userId={userId} />
      </TabPanel>
      <TabPanel value="4">
        <UserChargePointFleets userId={userId} />
      </TabPanel>
    </TabContext>
  );
};

UserDetailsTabs.propTypes = {
  userId: PropTypes.string.isRequired
};

export default UserDetailsTabs;
