import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Link, useLocation } from 'react-router-dom';
import { DASHBOARD_PATH } from '../../routes/paths';
import ExternalPartyLocationViewForm from './Details/ExternalPartyLocationViewForm';
import LocationChargersPanel from './Details/LocationChargers';

import { FC } from 'react';
import { EmspLocation, LocationType } from '../../types/location/Location';
import LocationAdminPanel from './Admins/LocationAdminPanel';
import LocationView from './Details/LocationView';
import LocationUsersPanel from './Users/LocationUsersPanel';
import useLocations from './hook/useLocations';

interface ILocationTabsProps {
  emspLocation: EmspLocation;
}
const LocationTabs: FC<ILocationTabsProps> = ({ emspLocation }) => {
  const { pathname } = useLocation();
  const { state, addEvent } = useLocations();
  const locationId = emspLocation?.id;

  const route = `${DASHBOARD_PATH}/locations/${emspLocation.cpoCountryCode}/${emspLocation.cpoPartyId}/${emspLocation.id}`;
  const detailsPath = `${route}/details`;
  const chargersPath = `${route}/chargers`;
  const usersPath = `${route}/users`;
  const adminsPath = `${route}/admins`;
  const isOpenloopLocation = emspLocation.cpoCountryCode === 'NZ' && emspLocation.cpoPartyId === 'OPL';
  const usersTabLabel = emspLocation.locationType === LocationType.WORK ? 'Additional Users' : 'Users';
  const shouldShowUsers = emspLocation.locationType !== LocationType.PUBLIC;

  return (
    <TabContext value={pathname}>
      <TabList aria-label="Location" indicatorColor="primary" textColor="primary">
        <Tab label="Details" value={detailsPath} to={detailsPath} component={Link} />
        <Tab label="Chargers" value={chargersPath} to={chargersPath} component={Link} />
        <Tab label={usersTabLabel} value={usersPath} to={usersPath} component={Link} style={{ display: !shouldShowUsers ? 'none' : undefined }} />
        <Tab label="Admins" value={adminsPath} to={adminsPath} component={Link} style={{ display: !isOpenloopLocation ? 'none' : undefined }} />
      </TabList>
      <TabPanel value={detailsPath}>
        {isOpenloopLocation && <LocationView state={state} addEvent={addEvent} />}
        {!isOpenloopLocation && <ExternalPartyLocationViewForm state={state} addEvent={addEvent} />}
      </TabPanel>
      <TabPanel value={chargersPath}>
        <LocationChargersPanel state={state} locationId={locationId} addEvent={addEvent} />
      </TabPanel>
      {shouldShowUsers && (
        <TabPanel value={usersPath}>
          <LocationUsersPanel locationId={locationId} />
        </TabPanel>
      )}
      <TabPanel value={adminsPath}>
        <LocationAdminPanel locationId={locationId} />
      </TabPanel>
    </TabContext>
  );
};

export default LocationTabs;
