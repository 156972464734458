export const connectorTypeMap = new Map();
connectorTypeMap.set('CHADEMO', 'CHAdeMO');
connectorTypeMap.set('IEC_62196_T1', 'Type 1');
connectorTypeMap.set('IEC_62196_T2', 'Type 2');
connectorTypeMap.set('IEC_62196_T2_COMBO', 'CCS');


export const connectorPowerTypeMap = new Map()
connectorPowerTypeMap.set('AC_1_PHASE', 'AC, single phase');
connectorPowerTypeMap.set('AC_3_PHASE', 'AC, 3-phase');
connectorPowerTypeMap.set('DC', 'DC');
