import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import HasPermission from '../../../commons/permissions/HasPermission';
import mainTheme from '../../../themes/mainTheme';
import { Permission } from '../../../types/user/UserPermission';
import ConnectorPanels from './ConnectorPanels';

const useStyles = makeStyles((theme) => ({
  remoteStartTransaction: {
    backgroundColor: theme.palette.remoteStartButton.active,
    '&:hover': {
      backgroundColor: theme.palette.remoteStartButton.hover
    }
  }
}));
const ManagedConnectors = ({ evses, cpo, handleRemoteStart }) => {
  const classes = useStyles(mainTheme);
  const hasAtLeastOneAvailableConnector = evses.some((evse) => evse.status === 'AVAILABLE');

  return (
    <PaperWithTitle
      title="Connectors"
      topRightComponent={
        <>
          <HasPermission permission={Permission.REMOTE_START_TRANSACTION}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRemoteStart}
              className={classes.remoteStartTransaction}
              disabled={!hasAtLeastOneAvailableConnector}
            >
              Remote Start
            </Button>
          </HasPermission>
          <Typography>Managed by {cpo}</Typography>
        </>
      }
    >
      <ConnectorPanels evses={evses} />
    </PaperWithTitle>
  );
};

ManagedConnectors.propTypes = {
  cpo: PropTypes.string.isRequired,
  evses: PropTypes.array.isRequired
};

export default ManagedConnectors;
