import appConfig from '../utils/config/appConfig';
import {withAccessToken} from '../utils/auth/authenticationUtils';
import {ajax} from 'rxjs/ajax';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import LocationNotAvailableException from '../utils/auth/exceptions/LocationNotAvailableException';
import NoConfigurationsFoundException from '../utils/auth/exceptions/NoConfigurationsFoundException';
import NoConfigurationItemFoundException from '../utils/auth/exceptions/NoConfigurationItemFoundException';

const toConfigurationTimeToWait = (waitTime) => ({
  timeToWait: waitTime.time_to_wait,
});

const toConfiguration = (configuration) => ({
  key: configuration.key,
  value: configuration.value,
  readonly: configuration.readonly
});

const toConfigurationsResponse = (result) => ({
  configurations: result.configurations.map((configuration) => toConfiguration(configuration)),
  rebootRequired: result.reboot_required
});

const chargePointConfigurationsService = {
  getConfigurations: (locationId) => {
    
    return withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV2}/locations/${locationId}/configurations`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
      })
    ).pipe(
      map((response) => toConfigurationsResponse(response.response)),
      catchError((error) => {
        switch (error.status) {
          case 422:
            if (error.response.message.indexOf('is not in Available status') !== -1) {
              return throwError(new LocationNotAvailableException(`Unable to retrieve configurations. Location ${locationId} is not Available.`));
            } else if (error.response.message.indexOf('No configurations found for this charge point') !== -1){
              return throwError(new NoConfigurationsFoundException(`No configurations found for the charge point on Location ${locationId}.`));
            } else {
              return throwError(new Error(error.response.message));
            }
          default:
              return throwError(new Error(error.response.message));
        }}
      ));
  },
  refreshConfigurations: (locationId) => {
    return withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV2}/locations/${locationId}/refresh-configurations`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        method: 'POST'
      })
    ).pipe(
      map((response) => toConfigurationTimeToWait(response.response)),
      catchError((error) => {
        switch (error.status) {
          case 422:
            if (error.response.message.indexOf('is not in Available status') !== -1) {
              return throwError(new LocationNotAvailableException(`Unable to refresh configurations. Location ${locationId} is not Available.`));
            } else if (error.response.message.indexOf('No configurations found for this charge point') !== -1){
              return throwError(new NoConfigurationsFoundException(`No configurations found for the charge point on Location ${locationId}.`));
           } else {
              return throwError(new Error(error.response.message));
            }
          default:
            return throwError(new Error(error.response.message));
        }}
      ));
  },
  updateConfigurationItem: (locationId, configurationItem) => {
    return withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV2}/locations/${locationId}/configurations/${configurationItem.key}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        method: 'POST',
        body: {
          value: configurationItem.value
        }
      })
    ).pipe(
      map((response) => toConfigurationTimeToWait(response.response)),
      catchError((error) => {
        switch (error.status) {
          case 422:
            if (error.response.message.indexOf('is not in Available status') !== -1) {
              return throwError(new LocationNotAvailableException(`Unable to update configuration item. Location ${locationId} is not Available.`));
            } else if (error.response.message.indexOf('does not exist') !== -1){
              return throwError(new NoConfigurationItemFoundException(`No configuration item found with this key for the charge point on Location ${locationId}.`));
            } else {
              return throwError(new Error(error.response.message));
            }
          default:
            return throwError(new Error(error.response.message));
        }}
      ));
  },
}

export default chargePointConfigurationsService;