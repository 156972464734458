import useChargePoints, {ChargePointEvent, ChargePointFlowState} from '../../ChargePoints/hook/useChargePoints';
import FormDialog from '../../../commons/dialogs/FormDialog';
import {Form, Formik} from 'formik';
import _ from 'lodash';
import React, {createRef, useEffect} from 'react';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../../themes/mainTheme';
import Box from '@material-ui/core/Box';
import {ErrorBar} from '../../../commons/snackbars/snackbars';
import {ResetTypes} from '../../../utils/reset_types/resetTypes';

const useStyles = makeStyles((theme) => ({
  resetDialogButtonPadding: {
    paddingBottom: theme.spacing(1.25),
  },
  marginTopBottom10: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25),
  },
  toggleButton: {
    width: '100%',
  }
  
}));

const ResetChargePointForm = () => {
  const classes = useStyles(mainTheme);
  const [resetType, setResetType] = React.useState(ResetTypes.HARD);
  const {state, addEvent} = useChargePoints();
  const formikRef = createRef();
  
  useEffect(() => {
    if (state.flowState === ChargePointFlowState.FAILED_TO_RESET_CHARGE_POINT) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.flowState, formikRef]);
  
  const handleChange = (event, newResetType) => {
    if(newResetType === null || newResetType === "") {
      resetType === ResetTypes.HARD ? setResetType(ResetTypes.SOFT) : setResetType(ResetTypes.HARD);
    } else {
      setResetType(newResetType);
    }
  }
  
  return (
    
    <FormDialog
      open={_.includes([ChargePointFlowState.SHOWING_RESET_CHARGE_POINT_FORM, ChargePointFlowState.FAILED_TO_RESET_CHARGE_POINT], state.flowState)}
      onClose={() => {
          addEvent(
          {
            type: ChargePointEvent.CLOSE_RESET_CHARGE_POINT_FORM,
          });
        }
      }
      title={`Reset Charge Point`}
      maxWidth={400}
    >
      <Formik
        innerRef={formikRef}
        initialValues={resetType}
        onSubmit={() => {
          addEvent({
            type: ChargePointEvent.SUBMIT_RESET_CHARGE_POINT_FORM,
            payload: {
              id: state.chargePoint.id,
              resetType: resetType,
              
            },
          });
        }}
        
      >
        {(formikProps) => (
          <Form>
            <Box className={classes.resetDialogButtonPadding}>
              <ToggleButtonGroup
                classes={{root: classes.toggleButton}}
                value={resetType}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton classes={{root: classes.toggleButton}} value={ResetTypes.SOFT}>
                  Soft Reset
                </ToggleButton>
                <ToggleButton classes={{root: classes.toggleButton}} value={ResetTypes.HARD}>
                  Hard Reset
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Button classes={{root: classes.marginTopBottom10}} variant="contained" color="primary" type="submit" fullWidth disabled={resetType !== ResetTypes.HARD && resetType !== ResetTypes.SOFT}>
              Confirm
            </Button>
            <ErrorBar open={!_.isNull(state.resetChargePointErrorMessage)}>{state.resetChargePointErrorMessage}</ErrorBar>
          </Form>
        )}
      </Formik>
    </FormDialog>
  
  
  );
}

export default ResetChargePointForm

