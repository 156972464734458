import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import SmartCheckboxField from '../../../commons/form/SmartCheckboxField';
import SmartSelectField from '../../../commons/form/SmartSelectField';
import SmartTextField from '../../../commons/form/SmartTextField';
import PageWithColumns from '../../../commons/layout/PageWithColumns';
import mainTheme from '../../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    marginTop: theme.spacing(2)
  }
}));

const NewChargePointFormInfoDetails = () => {
  const classes = useStyles(mainTheme);

  return (
    <PageWithColumns
      columnOne={<>
        <Field label='Ocpp Charge Point Id' name='ocppChargePointIdentifier' autoFocus component={SmartTextField} helperText='Identifier this charge point connects with' />
        <Field label='Name' name='name' component={SmartTextField} helperText='Charge point name e.g Counties Energy - Walls Road - OPL-1007' />
        <Field label='Latitude' name='latitude' component={SmartTextField} helperText='Latitude of the location e.g. -36.846040' />
        <Field label='Longitude' name='longitude' component={SmartTextField} helperText='Longitude of the location e.g. 174.763336' />
        <Box className={classes.checkboxContainer}><Field label='Anonymous Charging Only' name='anonymousChargingMode' component={SmartCheckboxField}
          helperText="When this is enabled the charge point will not identify the user that is charging. This is also known as 'freevending' mode or 'NON-AUTH' mode" /></Box>
      </>}
      columnTwo={<>
        <Field label='Address' name='address' component={SmartTextField} helperText='Street address e.g 12 Fort Street' />
        <Field label='City' name='city' component={SmartTextField} helperText='Name of the city' />
        <Field label='State' name='state' component={SmartTextField} helperText='State or territory e.g Auckland' />
        <Field
          as='select'
          label='Country'
          name='country'
          component={SmartSelectField}
          options={[
            { value: 'NZL', displayValue: 'NZL' }
          ]}
          helperText='Country'
        />
        <Field label='Post Code' name='postCode' component={SmartTextField} helperText='Post code' />
      </>}
    />
  )
}

export default NewChargePointFormInfoDetails;