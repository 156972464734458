export const toTariffBreakdown = (tariff) => `$${tariff.ratePerKwh.toFixed(2)} per kWh + $${tariff.ratePerMinute.toFixed(2)} per minute + $${tariff.unlockFee.toFixed(2)} unlock`;

export const toPaymentBreakdown = (payment) => `kWh fee: $${payment.kwhFeeTotal.toFixed(2)}, minute fee: $${payment.minuteFeeTotal.toFixed(2)}, unlock fee: $${payment.unlockFeeTotal.toFixed(2)}`;

export const toOperationalCostBreakdown = (operationalCost) => `Electricity cost: $${operationalCost.electricityCost.ratePerKwh.toFixed(2)} per kWh`;

// Utilising correct round as specified by MDN here: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
export const roundNumberToCurrency = (num) => +(Math.round(num + "e+2")  + "e-2");

export const formatStatusText = (status) => status.replace('_', ' ');

export const numberToPecentage = (num) => (num * 100).toFixed(2);

export const roundNumberToCurrencyAndFormatToFixed = (num, digits) => `${roundNumberToCurrency(num).toFixed(digits)}`;
