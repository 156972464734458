import { useContext } from 'react';
import { ChargerConfigurationsContext } from '../ChargerConfigurationsProvider';
import {
  failedState as loadingContainerFailedState,
  succeededState as loadingContainerSucceededState
} from '../../../../hooks/useLoadingContainerWithErrorPanel';

import { delay } from 'lodash';
import { ChargerConfigurationsFlowState, UseChargerConfigurationsType } from '../../../../types/charger/ChargerConfiguration';
import { EventType } from '../../../../types/SharedStates';
import cpoAdminChargePointConfigurationsService from '../../../../services/cpoAdminChargePointConfigurationsService';

// events
export enum ChargerConfigurationsEvent {
  LOAD_CHARGE_POINT_CONFIGS = 'LOAD_CHARGE_POINT_CONFIGS',
  REFRESH_CHARGE_POINT_CONFIGS = 'REFRESH_CHARGE_POINT_CONFIGS',
  LOAD_REFRESHED_CHARGE_POINT_CONFIGS = 'LOAD_REFRESHED_CHARGE_POINT_CONFIGS',
  LOAD_UPDATED_CHARGE_POINT_CONFIGS = 'LOAD_UPDATED_CHARGE_POINT_CONFIGS',
  UPDATE_CHARGE_POINT_CONFIG_REQUESTED = 'UPDATE_CHARGE_POINT_CONFIG_REQUESTED'
}

// hook
const useChargerConfigurations = (): UseChargerConfigurationsType & { ChargerConfigurationsEvent: typeof ChargerConfigurationsEvent } => {
  const { state, dispatch } = useContext(ChargerConfigurationsContext);

  const getConfigurationsHandler = (event: EventType, flowState: string) => {
    cpoAdminChargePointConfigurationsService.getConfigurations(event.payload.id).subscribe(
      (result) =>
        dispatch({
          loadingState: loadingContainerSucceededState,
          refreshRequested: false,
          updateRequested: false,
          configurations: result.configurations,
          rebootRequired: result.rebootRequired,
          configurationsErrorMessage: null,
          flowState: result.rebootRequired ? ChargerConfigurationsFlowState.RESET_CHARGE_POINT_REQUIRED : flowState
        }),
      (error) =>
        dispatch({
          ...state,
          loadingState: loadingContainerFailedState(error.message),
          flowState: ChargerConfigurationsFlowState.FAILED_TO_LOAD_CHARGE_POINT_CONFIGS,
          configurationsErrorMessage: error.message
        })
    );
  };
  const addEvent = (event: EventType) => {
    switch (event.type) {
      case ChargerConfigurationsEvent.LOAD_CHARGE_POINT_CONFIGS:
        cpoAdminChargePointConfigurationsService.getConfigurations(event.payload.id).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              configurations: result.configurations,
              refreshRequested: !result.rebootRequired,
              rebootRequired: result.rebootRequired,
              updateRequested: false,
              configurationsErrorMessage: null,
              flowState: result.rebootRequired
                ? ChargerConfigurationsFlowState.RESET_CHARGE_POINT_REQUIRED
                : ChargerConfigurationsFlowState.REFRESH_CHARGE_POINT_CONFIGS_REQUESTED
            }),
          (error) =>
            dispatch({
              ...state,
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargerConfigurationsFlowState.REFRESH_CHARGE_POINT_CONFIGS_REQUESTED,
              configurationsErrorMessage: error.message
            })
        );
        break;
      case ChargerConfigurationsEvent.REFRESH_CHARGE_POINT_CONFIGS:
        dispatch({
          ...state,
          loadingState: loadingContainerSucceededState,
          refreshRequested: true,
          updateRequested: false,
          configurationsErrorMessage: null,
          flowState: ChargerConfigurationsFlowState.REFRESHING_CHARGE_POINT_CONFIGS
        });
        cpoAdminChargePointConfigurationsService.refreshConfigurations(event.payload.id).subscribe(
          (result) =>
            delay(() => {
              dispatch({
                loadingState: loadingContainerSucceededState,
                refreshRequested: false,
                configurationsErrorMessage: null,
                flowState: ChargerConfigurationsFlowState.REFRESH_CHARGE_POINT_CONFIGS_COMPLETED
              });
            }, result.timeToWait),

          (error) =>
            dispatch({
              ...state,
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargerConfigurationsFlowState.FAILED_TO_LOAD_CHARGE_POINT_CONFIGS,
              configurationsErrorMessage: error.message
            })
        );
        break;
      case ChargerConfigurationsEvent.LOAD_REFRESHED_CHARGE_POINT_CONFIGS:
        const refreshflowState = ChargerConfigurationsFlowState.CHARGE_POINT_CONFIGS_LOADED;
        getConfigurationsHandler(event, refreshflowState);
        break;
      case ChargerConfigurationsEvent.LOAD_UPDATED_CHARGE_POINT_CONFIGS:
        const updatedflowState = ChargerConfigurationsFlowState.CHARGE_POINT_CONFIG_UPDATED;
        getConfigurationsHandler(event, updatedflowState);
        break;
      case ChargerConfigurationsEvent.UPDATE_CHARGE_POINT_CONFIG_REQUESTED:
        dispatch({
          loadingState: loadingContainerSucceededState,
          updateRequested: true,
          configurationsErrorMessage: null,
          flowState: ChargerConfigurationsFlowState.UPDATING_CHARGE_POINT_CONFIG
        });
        cpoAdminChargePointConfigurationsService.updateConfigurationItem(event.payload.id, event.payload.updatedConfigItem).subscribe(
          (result) =>
            delay(() => {
              // This callback function calls the promise resolve so the table can come out of editable mode
              event.payload.onConfigItemUpdate();
              dispatch({
                loadingState: loadingContainerSucceededState,
                updateRequested: true,
                configurationsErrorMessage: null,
                flowState: ChargerConfigurationsFlowState.UPDATE_CHARGE_POINT_CONFIG_COMPLETED
              });
            }, result.timeToWait),
          (error) =>
            dispatch({
              ...state,
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargerConfigurationsFlowState.FAILED_TO_LOAD_CHARGE_POINT_CONFIGS,
              configurationsErrorMessage: error.message
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
    ChargerConfigurationsEvent: ChargerConfigurationsEvent
  };
};
export default useChargerConfigurations;
