import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import mainTheme from '../../../themes/mainTheme';
import { Box, Container } from '@material-ui/core';
import _ from 'lodash';
import clsx from 'clsx';

const useStyles = (theme, backgroundColor) =>
  makeStyles((theme) => ({
    container: {
      margin: 0,
      marginBottom: theme.spacing(2)
    },
    paper: {
      width: '100%',
      borderRadius: 4,
      padding: theme.spacing(2),
      backgroundColor: backgroundColor
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: theme.spacing(5),
      marginBottom: theme.spacing(1)
    },
    title: {
      marginRight: theme.spacing(1)
    },
    topRightComponent: {
      '& .MuiButton-root': {
        marginLeft: theme.spacing(2)
      }
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2)
    },
    bottomRightComponent: {
      '& .MuiButton-root': {
        marginLeft: theme.spacing(2)
      }
    }
  }));

const PaperWithTitle = ({ className, title, topRightComponent, bottomRightComponent, children, maxWidth, backgroundColor }) => {
  const classes = useStyles(mainTheme, backgroundColor)();

  return (
    <Container className={clsx(classes.container, className)} disableGutters maxWidth={_.isNil(maxWidth) ? false : maxWidth}>
      <Paper classes={{ root: classes.paper }}>
        <>
          <Box classes={{ root: title && topRightComponent && classes.header }}>
            {title && (
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
            )}
            {topRightComponent && <Box classes={{ root: topRightComponent && classes.topRightComponent }}>{topRightComponent}</Box>}
          </Box>
          {children}
          {bottomRightComponent && (
            <Box classes={{ root: bottomRightComponent && classes.footer }}>
              <Box classes={{ root: bottomRightComponent && classes.bottomRightComponent }}>{bottomRightComponent}</Box>
            </Box>
          )}
        </>
      </Paper>
    </Container>
  );
};

PaperWithTitle.defaultProps = {
  topRightComponent: <></>
};

PaperWithTitle.propTypes = {
  title: PropTypes.string,
  topRightComponent: PropTypes.element,
  bottomRightComponent: PropTypes.element,
  children: PropTypes.element.isRequired,
  maxWidth: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string
};

export default PaperWithTitle;
