import { Field, Form, Formik, FormikProps } from 'formik';
import React, { createRef, FC } from 'react';
import * as Yup from 'yup';
import SmartTextField from '../../../commons/form/SmartTextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import { ChargerTariffSummary } from '../../../types/charger/ChargerTariffs';

interface UpdateChargerTariffFormProps {
  initialValues: ChargerTariffSummary;
  onSubmit: (values: ChargerTariffSummary) => void;
  onCancel: () => void;
}

interface FormattedChargerTariffSummary {
  id: string | null;
  ratePerKwh: string;
  ratePerMinute: string;
  unlockFee: string;
}

const UpdateChargerTariffForm: FC<UpdateChargerTariffFormProps> = ({ initialValues, onSubmit, onCancel }) => {
  const formikRef = createRef<FormikProps<FormattedChargerTariffSummary>>();

  // We need to format the initial values to string to be able to display 2 decimal places
  const formattedInitialValues: FormattedChargerTariffSummary = {
    id: initialValues.id,
    ratePerKwh: initialValues.ratePerKwh.toFixed(2),
    ratePerMinute: initialValues.ratePerMinute.toFixed(2),
    unlockFee: initialValues.unlockFee.toFixed(2)
  };

  const handleOnSubmit = (values: FormattedChargerTariffSummary) => {
    onSubmit({
      id: values.id,
      ratePerKwh: parseFloat(values.ratePerKwh),
      ratePerMinute: parseFloat(values.ratePerMinute),
      unlockFee: parseFloat(values.unlockFee)
    });
  };

  const patternUpToTwoDigitsAfterDecimal = /^\d+(\.\d{0,2})?$/;
  const rateValidation = Yup.number()
    .test('is-decimal', 'Please enter a number that is up to 2 decimal places. e.g. 0.30', (value) => {
      if (value !== undefined) {
        return patternUpToTwoDigitsAfterDecimal.test(value.toString());
      }
      return true;
    })
    .min(0)
    .max(2, 'maximum $2')
    .required('Required');

  const feeValidation = Yup.number()
    .test('is-decimal', 'Please enter a number that is up to 2 decimal places. e.g. 0.30', (value) => {
      if (value !== undefined) {
        return patternUpToTwoDigitsAfterDecimal.test(value.toString());
      }
      return true;
    })
    .min(0)
    .max(10, 'maximum $10')
    .required('Required');

  const validationSchema = Yup.object({
    ratePerKwh: rateValidation,
    ratePerMinute: rateValidation,
    unlockFee: feeValidation
  });

  return (
    <Formik innerRef={formikRef} initialValues={formattedInitialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
      {(formikProps) => (
        <Form>
          <PaperWithTitle
            title="Tariff"
            children={
              <>
                <Field
                  label="Electricity Fee"
                  name="ratePerKwh"
                  component={SmartTextField}
                  helperText="Cost per kWh. Up to 2 decimal places, e.g. 0.30"
                  fullWidth
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  endAdornment={<InputAdornment position="end">/kWh</InputAdornment>}
                />
                <Field
                  label="Duration Fee"
                  name="ratePerMinute"
                  component={SmartTextField}
                  helperText="Cost per min. Up to 2 decimal places, e.g. 0.30"
                  fullWidth
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  endAdornment={<InputAdornment position="end">/min</InputAdornment>}
                />
                <Field
                  label="Unlock Fee"
                  name="unlockFee"
                  component={SmartTextField}
                  helperText="Cost per transaction. Up to 2 decimal places, e.g. 0.30"
                  fullWidth
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  endAdornment={<InputAdornment position="end">/transaction</InputAdornment>}
                />
              </>
            }
            bottomRightComponent={
              <>
                <Button type="button" variant="outlined" color="primary" onClick={() => onCancel()}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}
                >
                  Confirm
                </Button>
              </>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default UpdateChargerTariffForm;
