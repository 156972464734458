import LocationContextProvider from './LocationContextProvider';

export const withLocations =
  <T extends Record<string, any>>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: T) =>
    (
      <LocationContextProvider>
        <Component {...props} />
      </LocationContextProvider>
    );
