import { Box, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useState } from 'react';
import { useRouteMatch } from 'react-router';
import HasPermission from '../../commons/permissions/HasPermission';
import { FeatureFlag, requiresFeatureFlag } from '../../hooks/useFeatureFlags';
import { usePermissions } from '../../hooks/usePermissions';
import paths from '../../routes/paths';
import { Permission } from '../../types/user/UserPermission';
import ChargePointConfigurations from '../ChargePointConfigurations/ChargePointConfigurations';
import Dashboard from '../Dashboard/Dashboard';
import ChargePointInfoPanel from './ChargePointInfoPanel/ChargePointInfoPanel';
import ChargePointLogsPanel from './ChargePointLogsPanel/ChargePointLogsPanel';
import ChargePointTariffPanel from './ChargePointTariffPanel/ChargePointTariffPanel';
import ChargePointTransactionsPanel from './ChargePointTransactionsPanel/ChargePointTransactionsPanel';

const ChargePointDetails = () => {
  const [value, setValue] = useState('1');
  const { hasPermission } = usePermissions();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const match = useRouteMatch(paths.CHARGE_POINT_DETAILS_LEGACY);
  const chargePointId = match.params.chargePointId;

  return (
    <Dashboard pageHeading={chargePointId}>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="Charge Point Tabs" indicatorColor="primary" textColor="primary">
          <Tab label="Details" value="1" key="1" />
          <Tab label="Tariffs" value="2" key="2" />
          <Tab label="Transactions" value="3" key="3" />
          {hasPermission(Permission.UPDATE_CHARGE_POINT_DETAILS) ? <Tab label="Configurations" value="4" key="4" /> : <Box></Box>}
          <Tab label="Logs" value="5" key="5" />
        </TabList>
        <TabPanel value="1">
          <ChargePointInfoPanel chargePointId={chargePointId} />
        </TabPanel>
        <TabPanel value="2">
          <ChargePointTariffPanel chargePointId={chargePointId} />
        </TabPanel>
        <TabPanel value="3">
          <ChargePointTransactionsPanel chargePointId={chargePointId} />
        </TabPanel>
        <HasPermission permission={Permission.UPDATE_CHARGE_POINT_DETAILS}>
          <TabPanel value="4">
            <ChargePointConfigurations locationId={chargePointId} />
          </TabPanel>
        </HasPermission>
        <TabPanel value="5">
          <ChargePointLogsPanel chargePointId={chargePointId} />
        </TabPanel>
      </TabContext>
    </Dashboard>
  );
};

export default requiresFeatureFlag(FeatureFlag.LEGACY_CHARGE_POINT_MANAGEMENT, { redirectTo: paths.CHARGERS })(ChargePointDetails);
