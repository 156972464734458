import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { ErrorBar } from '../../../commons/snackbars/snackbars';
import paths from '../../../routes/paths';
import { LocationDetailsFlowState } from '../../../types/LocationDetails';
import { LocationsFlowState } from '../../../types/location/LocationState';
import Dashboard from '../../Dashboard/Dashboard';
import useLocations, { LocationEvent } from '../hook/useLocations';
import { withLocations } from '../hook/withLocations';
import NewLocationForm from './NewLocationForm';

const NewLocation = () => {
  const { state, addEvent } = useLocations();
  const history = useHistory();
  const [errorSubmitting, setErrorSubmitting] = useState(false);

  useEffect(() => {
    if (state.flowState === LocationsFlowState.INIT) {
      addEvent({ type: LocationEvent.SHOW_CREATE_LOCATION_FORM });
    }
    if (state.flowState === LocationDetailsFlowState.NEW_LOCATION_FORM_CLOSED) {
      addEvent({ type: LocationEvent.CANCEL_CREATE_LOCATION_FORM });
      history.push(paths.LOCATIONS);
    }
    if (state.flowState === LocationDetailsFlowState.FAILED_TO_CREATE_LOCATION) {
      setErrorSubmitting(true);
      addEvent({ type: LocationEvent.SHOW_CREATE_LOCATION_FORM });
    }
    if (state.flowState === LocationDetailsFlowState.NEW_LOCATION_CREATED) {
      history.push({ pathname: paths.LOCATIONS }, { showLocationCreatedSuccessBar: true });
    }
  }, [state.flowState, addEvent, history, state.newLocationId]);

  return (
    <Dashboard pageHeading="New Location">
      <>
        <LoadingContainerWithErrorPanel {...state.loadingState}>
          <NewLocationForm
            osps={state.osps ? state.osps : []}
            organisations={state.organisations}
            addEvent={addEvent}
            newLocationId={''}
            flowState={state.flowState}
          />
        </LoadingContainerWithErrorPanel>
        <ErrorBar fullPage={false} open={errorSubmitting} onClose={() => setErrorSubmitting(false)}>
          {state.newLocationErrorMessage}
        </ErrorBar>
      </>
    </Dashboard>
  );
};
export default withLocations(NewLocation);
