import _ from 'lodash';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { chargeTagStatus } from '../components/ChargeTag/ChargeTagStatus';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { errorMessageObservable } from '../utils/error/errorUtils';
import { handleError } from '../utils/error/handleApiError';

const toUserGroup = (userGroup) => ({
  id: userGroup.id,
  name: userGroup.name,
  created: userGroup.created
});

const determineChargeTagStatus = (userGroupUser) => {
  if (_.isNil(userGroupUser.charge_tag_id)) {
    return chargeTagStatus.UNASSIGNED;
  }
  return userGroupUser.is_charge_tag_active ? chargeTagStatus.ACTIVE : chargeTagStatus.DEACTIVATED;
};

const toUserGroupUser = (userGroupUser) => {
  return {
    id: userGroupUser.id,
    name: userGroupUser.name,
    email: userGroupUser.email,
    chargeTagId: userGroupUser.charge_tag_id,
    isChargeTagActive: userGroupUser.is_charge_tag_active,
    chargeTagStatus: determineChargeTagStatus(userGroupUser)
  };
};

const toUserGroups = (userGroups) => userGroups.map(toUserGroup);
const toUserGroupUsers = (userGroupUsers) => userGroupUsers.map(toUserGroupUser);

const userGroupService = {
  getAllUserGroups: () =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/user-groups`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toUserGroups(response.response.data)),
      catchError((error) => errorMessageObservable(error))
    ),
  getUserGroup: (id) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/user-groups/${id}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toUserGroup(response.response)),
      catchError((error) => handleError(error, 'user group'))
    ),
  getUserGroupUsers: (id) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/user-groups/${id}/users`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toUserGroupUsers(response.response.data)),
      catchError((error) => handleError(error, 'user group'))
    )
};

export default userGroupService;
