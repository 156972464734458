import * as React from 'react';
import { ChargerInfoProvider } from './ChargerInfoProvider';

export const withChargerInfo =
  <T extends Record<string, any>>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: T) =>
    (
      <ChargerInfoProvider>
        <Component {...props} />
      </ChargerInfoProvider>
    );
