import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React from 'react';
import * as PropTypes from 'prop-types';

const ConnectorActionButtonsContainer = ({handleAddConnector, handleRemoveConnector, connectors}) => {
  
  const shouldDisplayRemoveButton = connectors.length > 1;
  
  return (
    <Box>
      {shouldDisplayRemoveButton &&
        <IconButton size='small' aria-label='Remove connector' variant='contained' color='primary' onClick={() => handleRemoveConnector()}>
          <RemoveCircleIcon fontSize='large' />
        </IconButton>
      }
      <IconButton size='small' aria-label='Add connector' variant='contained' color='primary' onClick={() => handleAddConnector()}>
        <AddCircleIcon fontSize='large' />
      </IconButton>
    </Box>
  )
}

ConnectorActionButtonsContainer.propTypes = {
  handleAddConnector: PropTypes.func,
  handleRemoveConnector: PropTypes.func,
  connectors: PropTypes.array.isRequired
}

export default ConnectorActionButtonsContainer