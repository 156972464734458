import { Button } from "@material-ui/core";
import React from "react";
const CancelButton = ({ handleCancel }) => {
    return (
        <Button type='button' variant='outlined' color='primary' onClick={handleCancel}>
            Cancel
        </Button>
    );
}

export default CancelButton;