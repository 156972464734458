import { useContext } from 'react';
import { map, mergeMap } from 'rxjs/operators';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import cpoAdminService from '../../../services/cpoAdminService';
import emspLocationServicev4 from '../../../services/emspLocationServicev4';
import organisationService from '../../../services/organisationService';
import { LocationDetailsFlowState } from '../../../types/LocationDetails';
import { EventType } from '../../../types/SharedStates';
import { LocationsFlowState, UseLocationsType } from '../../../types/location/LocationState';
import { LocationContext } from './LocationContextProvider';

export const LocationEvent = {
  LOAD_LOCATION: 'LOAD_LOCATION',
  LOAD_EXTERNAL_LOCATION: 'LOAD_EXTERNAL_LOCATION',
  SHOW_UPDATE_LOCATION_FORM: 'SHOW_UPDATE_LOCATION_FORM',
  CLOSE_UPDATE_LOCATION_FORM: 'CLOSE_UPDATE_LOCATION_FORM',
  SUBMIT_UPDATE_LOCATION_FORM: 'SUBMIT_UPDATE_LOCATION_FORM',
  CANCEL_UPDATE_LOCATION_FORM: 'CANCEL_UPDATE_LOCATION_FORM',
  SHOW_UPDATE_BASIC_CONFIG_FORM: 'SHOW_UPDATE_BASIC_CONFIG_FORM',
  CLOSE_UPDATE_BASIC_CONFIG_FORM: 'CLOSE_UPDATE_BASIC_CONFIG_FORM',
  SUBMIT_UPDATE_BASIC_CONFIG_FORM: 'SUBMIT_UPDATE_BASIC_CONFIG_FORM',
  CANCEL_UPDATE_BASIC_CONFIG_FORM: 'CANCEL_UPDATE_BASIC_CONFIG_FORM',
  SHOW_CREATE_LOCATION_FORM: 'SHOW_CREATE_LOCATION_FORM',
  CANCEL_CREATE_LOCATION_FORM: 'CANCEL_CREATE_LOCATION_FORM',
  SUBMIT_CREATE_LOCATION_FORM: 'SUBMIT_CREATE_LOCATION_FORM',
  LOAD_LOCATIONS: 'LOAD_LOCATIONS',
  LOAD_CHARGERS_FOR_LOCATION: 'LOAD_CHARGERS_FOR_LOCATION'
};

const useLocations = (): UseLocationsType => {
  const { state, dispatch } = useContext(LocationContext);

  const addEvent = (event: EventType) => {
    switch (event.type) {
      case LocationEvent.LOAD_EXTERNAL_LOCATION:
        emspLocationServicev4.getLocationById(event.payload.id).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              emspLocation: result,
              flowState: LocationDetailsFlowState.EXTERNAL_LOCATION_LOADED
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: LocationDetailsFlowState.FAILED_TO_LOAD_EXTERNAL_LOCATION
            })
        );
        break;
      case LocationEvent.LOAD_LOCATION:
        emspLocationServicev4
          .getLocationById(event.payload.id)
          .pipe(
            mergeMap((emspLocation) =>
              cpoAdminService.getLocationById(emspLocation.cpoLocationId).pipe(map((cpoLocation) => ({ emspLocation, cpoLocation })))
            )
          )
          .subscribe(
            ({ emspLocation, cpoLocation }) =>
              dispatch({
                loadingState: loadingContainerSucceededState,
                emspLocation: emspLocation,
                cpoLocation: cpoLocation,
                flowState: LocationDetailsFlowState.LOCATION_LOADED
              }),
            (error) =>
              dispatch({
                loadingState: loadingContainerFailedState(error.message, error.status),
                flowState: LocationDetailsFlowState.FAILED_TO_LOAD_LOCATION
              })
          );
        break;
      case LocationEvent.SHOW_CREATE_LOCATION_FORM:
        cpoAdminService
          .getOcpiServiceProviders()
          .pipe(mergeMap((osps) => organisationService.getOrganisations().pipe(map((organisations) => ({ osps: osps, organisations })))))
          .subscribe(
            ({ osps, organisations }) =>
              dispatch({
                loadingState: loadingContainerSucceededState,
                osps: osps,
                organisations: organisations,
                flowState: LocationDetailsFlowState.SHOWING_NEW_LOCATION_FORM
              }),
            (error) =>
              dispatch({
                loadingState: loadingContainerFailedState(error.message),
                flowState: LocationDetailsFlowState.FAILED_TO_LOAD_DROPDOWN_DATA
              })
          );
        break;

      case LocationEvent.SHOW_UPDATE_LOCATION_FORM:
        cpoAdminService
          .getOcpiServiceProviders()
          .pipe(mergeMap((osps) => organisationService.getOrganisations().pipe(map((organisations) => ({ osps: osps, organisations })))))
          .subscribe(
            ({ osps, organisations }) =>
              dispatch({
                loadingState: loadingContainerSucceededState,
                updateLocation: event.payload,
                osps: osps,
                organisations: organisations,
                flowState: LocationDetailsFlowState.SHOWING_UPDATE_LOCATION_FORM
              }),
            (error) =>
              dispatch({
                loadingState: loadingContainerFailedState(error.message),
                flowState: LocationDetailsFlowState.FAILED_TO_LOAD_DROPDOWN_DATA
              })
          );
        break;
      case LocationEvent.CANCEL_CREATE_LOCATION_FORM:
        dispatch({
          flowState: LocationDetailsFlowState.NEW_LOCATION_FORM_CLOSED
        });
        break;
      case LocationEvent.SUBMIT_UPDATE_LOCATION_FORM:
        dispatch({
          flowState: LocationDetailsFlowState.UPDATING_LOCATION
        });
        const values = event.payload.values;
        // remove id from event payload value
        delete values.id;

        cpoAdminService.updateLocation(event.payload.id, values).subscribe(
          (_) => {
            dispatch({
              flowState: LocationDetailsFlowState.LOCATION_UPDATED
            });
          },
          (error) => {
            dispatch({
              flowState: LocationDetailsFlowState.FAILED_TO_UPDATE_LOCATION,
              updateLocationErrorMessage: error.message
            });
          }
        );
        break;
      case LocationEvent.CANCEL_UPDATE_LOCATION_FORM:
        dispatch({
          flowState: LocationDetailsFlowState.UPDATE_LOCATION_FORM_CLOSED
        });
        break;

      case LocationEvent.SHOW_UPDATE_BASIC_CONFIG_FORM:
        dispatch({
          flowState: LocationDetailsFlowState.SHOWING_UPDATE_BASIC_CONFIG_FORM
        });
        break;
      case LocationEvent.SUBMIT_UPDATE_BASIC_CONFIG_FORM:
        dispatch({
          updateLocationErrorMessage: null
        });
        emspLocationServicev4.updateLocation(event.payload.id, event.payload.electricityCost, event.payload.locationType).subscribe(
          (_) => {
            dispatch({
              flowState: LocationDetailsFlowState.BASIC_CONFIG_UPDATED
            });
          },
          (error) => {
            dispatch({
              flowState: LocationDetailsFlowState.FAILED_TO_UPDATE_BASIC_CONFIG,
              updateLocationErrorMessage: error.message
            });
          }
        );
        break;

      case LocationEvent.CANCEL_UPDATE_BASIC_CONFIG_FORM:
        dispatch({
          flowState: LocationDetailsFlowState.UPDATE_BASIC_CONFIG_FORM_CLOSED
        });
        break;
      case LocationEvent.SUBMIT_CREATE_LOCATION_FORM:
        const newLocation = event.payload.values;
        cpoAdminService.createLocation(newLocation).subscribe(
          (response) => {
            dispatch({
              flowState: LocationDetailsFlowState.NEW_LOCATION_CREATED,
              newLocationId: response.id,
              newLocationErrorMessage: null
            });
          },
          (error) => {
            dispatch({
              flowState: LocationDetailsFlowState.FAILED_TO_CREATE_LOCATION,
              newLocationErrorMessage: error.message
            });
          }
        );
        break;
      case LocationEvent.LOAD_LOCATIONS:
        emspLocationServicev4.getLocations().subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              locations: result,
              flowState: LocationsFlowState.LOCATIONS_LOADED
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: LocationsFlowState.FAILED_TO_LOAD_LOCATIONS
            })
        );
        break;
      case LocationEvent.LOAD_CHARGERS_FOR_LOCATION:
        dispatch({
          loadingState: loadingContainerInitialState
        });
        emspLocationServicev4.getChargersForLocationId(event.payload?.locationId).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              chargers: result,
              flowState: LocationsFlowState.CHARGERS_LOADED_FOR_LOCATION
            }),
          (error) => {
            dispatch({
              loadingState: loadingContainerFailedState(error.message, error.status),
              flowState: LocationsFlowState.FAILED_TO_LOAD_CHARGERS_FOR_LOCATION
            });
          }
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent
  };
};
export default useLocations;
