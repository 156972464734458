import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import * as Yup from 'yup';
import mainTheme from '../../themes/mainTheme';
import SmartSelectField from '../form/SmartSelectField';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  datePicker: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(4)
  },
  input: {
    width: 238
  },
  calendarIcon: {
    paddingBottom: theme.spacing(0.5),
    width: 18,
    height: 18
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    gap: 20
  }
}));

const DateTimeRangePicker = ({ dateTimeRangeFilter }) => {
  const classes = useStyles(mainTheme);

  const SmartDateTextField = ({ inputClass, startAdornment, endAdornment, ...rest }) => (
    <TextField
      type="datetime-local"
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        className: inputClass,
        startAdornment: startAdornment,
        endAdornment: endAdornment
      }}
      {...rest}
    />
  );

  const maxPastDays = dateTimeRangeFilter.maxRangeInDays;
  const validationSchema = Yup.object({
    fromDate: Yup.date()
      .default(() => new Date()) // this is required because when an invalid date is entered in the input field, the value is set to null and will not pass the validation
      .min(moment().subtract(maxPastDays, 'days').toDate(), `Start date cannot be more than ${maxPastDays} days in the past`)
      .required(),
    toDate: Yup.date()
      .when('fromDate', (fromDate, yup) => fromDate && yup.min(fromDate, 'End date cannot be before start date'))
      .required(),
    option: Yup.string().required('Required')
  });
  return (
    <Box className={classes.root}>
      <Formik
        enableReinitialize={false}
        initialValues={{
          fromDate: moment(dateTimeRangeFilter.dateRange.dateTimeFrom).format('YYYY-MM-DDTHH:mm'),
          toDate: moment(dateTimeRangeFilter.dateRange.dateTimeTo).format('YYYY-MM-DDTHH:mm'),
          option: dateTimeRangeFilter.defaultOption
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dateTimeRangeFilter.onFilterApply({
            dateTimeFrom: values.fromDate,
            dateTimeTo: values.toDate,
            option: values.option
          });
        }}
      >
        {(props) => (
          <Form>
            <Box className={classes.box}>
              <Field label="From" name="fromDate" component={SmartDateTextField} />
              <Field label="To" name="toDate" component={SmartDateTextField} />
              <Field label="" name="option" component={SmartSelectField} options={dateTimeRangeFilter.options} />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.actionButton}
                disabled={props.isSubmitting || !props.isValid}
              >
                Apply
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

DateTimeRangePicker.propTypes = {
  dateTimeRangeFilter: PropTypes.object.isRequired,
  maxRangeInDays: PropTypes.number
};

export default DateTimeRangePicker;
