import React, {useState} from 'react';
import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import {Tab} from '@material-ui/core';
import ChargePointGroupInfoPanel from './ChargePointGroupInfoPanel/ChargePointGroupInfoPanel';
import ChargePointGroupUserListPanel from './ChargePointGroupUserListPanel/ChargePointGroupUserListPanel';
import PropTypes from 'prop-types';
import ChargePointGroupAdminList from './ChargePointGroupAdminListPanel/ChargePointGroupAdminList';
import ChargePointList from '../ChargePoints/ChargePointList';

const ChargePointGroupDetailsTabs = ({chargePointGroup}) => {
  const [value, setValue] = useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange} aria-label="Charge Point Group Tabs" indicatorColor="primary" textColor="primary">
        <Tab label="Details" value="1" />
        <Tab label="Charge Points" value="2" />
        <Tab label="Users" value="3" />
        <Tab label="Admins" value="4" />
      </TabList>
      <TabPanel value="1">
        <ChargePointGroupInfoPanel chargePointGroup={chargePointGroup} />
      </TabPanel>
      <TabPanel value="2">
        <ChargePointList chargePointGroupId={chargePointGroup.id} />
      </TabPanel>
      <TabPanel value="3">
        <ChargePointGroupUserListPanel chargePointGroup={chargePointGroup} />
      </TabPanel>
      <TabPanel value="4">
        <ChargePointGroupAdminList chargePointGroup={chargePointGroup} />
      </TabPanel>
    </TabContext>
  );
};

ChargePointGroupDetailsTabs.propTypes = {
  chargePointGroup: PropTypes.object.isRequired,
};

export default ChargePointGroupDetailsTabs;
