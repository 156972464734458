import { CpoLocation, EmspLocation, EmspLocationIdentifier, LocationType } from '../../types/location/Location';
import { CpoLocationResponseDto, EmspLocationDto, EmspLocationIdentifierResponseDto } from '../../types/location/LocationDto';
import { toCpoChargePoint } from '../charger/ToCharger';
import { toOsp } from './ToOsp';

type toEmspLocationConverter = (location: EmspLocationDto) => EmspLocation;

export const toEmspLocation: toEmspLocationConverter = (location) => {
  return {
    id: location.id,
    name: location.name,
    address: location.address,
    city: location.city,
    latitude: location.latitude,
    longitude: location.longitude,
    currency: location.currency,
    operator: location.operator,
    decommissioned: location.decommissioned,
    owner: location.owner,
    postCode: location.post_code,
    electricityCost: location.electricity_cost,
    cpoLocationId: location.cpo_location_id,
    cpoCountryCode: location.cpo_country_code,
    cpoPartyId: location.cpo_party_id,
    status: location.status,
    locationType: LocationType[location.location_type]
  };
};

type ToEmspLocationDtoConverter = (location: Partial<EmspLocation>) => Partial<EmspLocationDto>;

export const toEmspLocationDto: ToEmspLocationDtoConverter = (location) => {
  return {
    id: location.id,
    name: location.name,
    address: location.address,
    city: location.city,
    latitude: location.latitude,
    longitude: location.longitude,
    currency: location.currency,
    operator: location.operator,
    decommissioned: location.decommissioned,
    owner: location.owner,
    post_code: location.postCode,
    electricity_cost: location.electricityCost,
    cpo_location_id: location.cpoLocationId,
    cpo_country_code: location.cpoCountryCode,
    cpo_party_id: location.cpoPartyId
  };
};

type ToEmspLocationSummaryConverter = (location: Partial<EmspLocationDto>) => Partial<EmspLocation>;
export const toEmspLocationSummary: ToEmspLocationSummaryConverter = (location) => {
  return {
    cpoLocationId: location.cpo_location_id,
    name: location.name,
    id: location.id
  };
};

type ToCpoLocationConverter = (location: CpoLocationResponseDto) => CpoLocation;
export const toCpoLocation: ToCpoLocationConverter = (location) => {
  return {
    id: location.id,
    name: location.name,
    address: location.address,
    postalCode: location.postal_code,
    city: location.city,
    country: location.country,
    latitude: location.coordinates.latitude,
    longitude: location.coordinates.longitude,
    state: location.state,
    timeZone: location.time_zone,
    public: location.public,
    ownerName: location.owner_name,
    visibleToOsps: location.visible_to_osps.map(toOsp),
    chargePoints: location.charge_points.map(toCpoChargePoint),
    cpoCountryCode: location.cpo_country_code,
    cpoPartyId: location.cpo_party_id,
    mspCountryCode: location.msp_country_code,
    mspPartyId: location.msp_party_id,
    publish: location.publish
  };
};

type ToEmspLocationIdentifier = (locationIdentifiers: EmspLocationIdentifierResponseDto) => EmspLocationIdentifier;
export const toEmspLocationIdentifier: ToEmspLocationIdentifier = (locationIdentifiers) => {
  return {
    cpoCountryCode: locationIdentifiers.cpo_country_code,
    cpoPartyId: locationIdentifiers.cpo_party_id,
    locationId: locationIdentifiers.location_id
  };
};
