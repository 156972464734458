import React, {createContext, useContext, useReducer} from 'react';
import chargePointLogsService from '../../../services/chargePointLogsService';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import _ from 'lodash';
import {expand, takeWhile} from 'rxjs/operators';
import {EMPTY} from 'rxjs';

// events
export const ChargePointLogsEvent = {
  FILTER_CRITERIA_CHANGED: 'FILTER_CRITERIA_CHANGED',
  PANEL_ENTERED: 'PANEL_ENTERED',
  APPLY_FILTER_CRITERIA: 'APPLY_FILTER_CRITERIA'
};

// flow states
export const ChargePointLogsFlowState = {
  INIT: 'INIT',
  LOADING_LOGS_TABLE: 'LOADING_LOGS_TABLE',
  SHOWING_LOGS_TABLE: 'SHOWING_LOGS_TABLE',
  ERROR: 'ERROR',
};

// initial state
const initialState = {
  loadingState: loadingContainerInitialState,
  logs: [],
  flowState: ChargePointLogsFlowState.INIT,
  dateRange: {
    dateTimeFrom: null,
    dateTimeTo: null,
  },
  logType: null,
  partialLoad: false
};

// reducer
const reducer = (state, newState) => ({...state, ...newState});

// context
const chargePointLogsContext = createContext();

// provider
export const ChargePointLogsProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <chargePointLogsContext.Provider value={{state, dispatch}}>{children}</chargePointLogsContext.Provider>
  );
};

export const MAX_NUMBER_OF_AGGREGATED_LOGS = 200;
export const GET_LOGS_REQUEST_LIMIT = 10;

// hook
const useChargePointLogs = () => {
  const {state, dispatch} = useContext(chargePointLogsContext);
  
  const addEvent = (event) => {
    const payload = event.payload;
    switch (event.type) {
      case ChargePointLogsEvent.PANEL_ENTERED:
      case ChargePointLogsEvent.APPLY_FILTER_CRITERIA:
        dispatch({
          loadingState: loadingContainerInitialState,
          flowState: ChargePointLogsFlowState.LOADING_LOGS_TABLE,
        });
        
        let previousToken = null;
        const getLogsForCriteria = (nextToken) => chargePointLogsService.getLogs(payload.chargePointId, payload.dateRange, nextToken, null, payload.logType);
        
        let aggregatedLogs = [];
        let totalNumberOfGetLogsRequests = 0;
        let keepLoading = false;
        getLogsForCriteria()
          .pipe(
            expand(({nextToken, data}) => {
              if (previousToken === nextToken) {
                return EMPTY;
              }
              aggregatedLogs = aggregatedLogs.concat(...data)
              totalNumberOfGetLogsRequests += 1
              previousToken = nextToken;
              return getLogsForCriteria(nextToken);
            }),
            // for some reason the stream does not terminate
            // use take while to stop streaming into the pipe
            takeWhile(({nextToken, data}) => {
              keepLoading = aggregatedLogs.length < MAX_NUMBER_OF_AGGREGATED_LOGS && totalNumberOfGetLogsRequests < GET_LOGS_REQUEST_LIMIT
              return (nextToken !== null && previousToken !== nextToken && keepLoading)
            }),
            // finalize(callback), this will get executed regardless if there is an error in the stream or not
          )
          // IMPORTANT: Reduce does not work with AjaxResponse or Promise as it requires concrete value.
          // The only way to obtain value from AjaxResponse is to subscribe to it
          .subscribe(
            // accumulate all logs here
            // concat does not alter the current array, it returns a new concatenated array
            () => {
            },
            // this occurs when there is an error in the stream
            (error) =>
              dispatch({
                loadingState: loadingContainerFailedState(error.message),
                flowState: ChargePointLogsFlowState.ERROR,
              }),
            // this ONLY occurs when we have processed all items in the stream without any errors.
            // if we wish to execute a callback regardless if there is an error, then use "finalize" operator
            () =>
              dispatch({
                dateRange: payload.dateRange,
                logType: payload.logType,
                loadingState: loadingContainerSucceededState,
                logs: aggregatedLogs,
                partialLoad: !keepLoading, // Loading stopped because we reached the limit of 200 logs or 10 requests
                flowState: ChargePointLogsFlowState.SHOWING_LOGS_TABLE,
              })
          );
        break;
      case ChargePointLogsEvent.FILTER_CRITERIA_CHANGED:
        dispatch({
          ...state,
          dateRange: payload.dateRange,
          logType: payload.logType,
        });
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };
  
  return {
    state,
    addEvent,
  };
};

export default useChargePointLogs;
