export enum DecommissionChargerEventType {
  SUBMIT_DECOMMISSION_CHARGER = 'SUBMIT_DECOMMISSION_CHARGER',
  CLEAR_FAILED_STATE = 'CLEAR_FAILED_STATE'
}

interface SubmitDecommissionChargersEvent {
  type: DecommissionChargerEventType.SUBMIT_DECOMMISSION_CHARGER;
  payload: { cpoChargePointId: string };
}

export type DecommissionChargerEvent = SubmitDecommissionChargersEvent | { type: DecommissionChargerEventType.CLEAR_FAILED_STATE };
