import moment from 'moment';
import { FC, useEffect } from 'react';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import { chargerLogOptions, ChargerLogsEvent, ChargerLogsFlowState, LogType } from '../../../types/charger/ChargerLogs';
import ChargePointLogsTable from '../../ChargePointLogs/ChargePointLogsTable/ChargePointLogsTable';
import useChargerLogs from './hook/useChargerLogs';
import { withChargerLogs } from './hook/withChargerLogs';

type ChargerLogsProps = {
  cpoChargePointId: string;
};

const ChargerLogsPanel: FC<ChargerLogsProps> = ({ cpoChargePointId }) => {
  const { state, addEvent, ChargerLogsEventType } = useChargerLogs();

  useEffect(() => {
    if (state.flowState === ChargerLogsFlowState.INIT) {
      addEvent({
        type: ChargerLogsEventType.LOAD_CHARGER_LOGS,
        payload: {
          chargePointId: cpoChargePointId,
          dateRange: {
            dateTimeFrom: moment().subtract(30, 'minutes'),
            dateTimeTo: moment()
          },
          logType: LogType.PRETTY
        }
      });
    }
  }, [state.flowState, addEvent, cpoChargePointId, ChargerLogsEventType.LOAD_CHARGER_LOGS]);

  const onFilterApply = (values: any) => {
    addEvent({
      type: ChargerLogsEvent.APPLY_FILTER_CRITERIA,
      payload: {
        chargePointId: cpoChargePointId,
        dateRange: {
          dateTimeFrom: moment(values.dateTimeFrom),
          dateTimeTo: moment(values.dateTimeTo)
        },
        logType: values.option
      }
    });
  };

  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      <ChargePointLogsTable
        logs={state.logs}
        dateTimeRangeFilter={{
          dateRange: state.dateRange,
          maxRangeInDays: 7,
          onFilterApply: onFilterApply,
          options: chargerLogOptions,
          defaultOption: state.logType
        }}
        isPartialLoad={state.isPartialLoad}
      />
    </LoadingContainerWithErrorPanel>
  );
};

export default withChargerLogs(ChargerLogsPanel);
