import _ from 'lodash';
import { PropsWithChildren, createContext, useReducer } from 'react';
import { initialState as loadingContainerInitialState } from '../../../../hooks/useLoadingContainerWithErrorPanel';
import { ChargePointInsightsChartFlowState, ChargePointInsightsState, lastNumberOfDaysChoices } from '../../../../types/insights/InsightsState';
const reducer = (state: any, newState: any) => ({ ...state, ...newState });

export const initialState: Partial<ChargePointInsightsState> = {
  loadingFilterState: loadingContainerInitialState,
  flowState: ChargePointInsightsChartFlowState.INIT,
  filter: {
    lastNumberOfDaysChoices: [],
    chargePointOrganisationsChoices: [],
    chargePointLocationsChoices: []
  },
  filteredBy: {
    lastNumberOfDays: lastNumberOfDaysChoices[0],
    chargePointOrganisations: [],
    chargePointLocations: []
  },
  loadingTransactionsState: loadingContainerInitialState,
  transactions: []
};

export const InsightsContext = createContext<ChargePointInsightsState | any>(initialState);

const InsightsContextProvider: React.FC = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));

  return <InsightsContext.Provider value={{ state, dispatch }}>{children}</InsightsContext.Provider>;
};
export default InsightsContextProvider;
