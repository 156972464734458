import { InitialStateType } from '../../SharedStates';
import { LocationUser } from '../user/LocationUser';

// ***************************************************
// Location Admins List
export enum LocationAdminsFlowState {
  INIT = 'INIT',
  ADMINS_LOADED_FOR_LOCATION = 'ADMINS_LOADED_FOR_LOCATION',
  FAILED_TO_LOAD_ADMINS_FOR_LOCATION = 'FAILED_TO_LOAD_ADMINS_FOR_LOCATION',
  SHOWING_ADD_ADMIN_DIALOG = 'SHOWING_ADD_ADMIN_FORM',
  ADDING_ADMIN_TO_LOCATION = 'ADDING_ADMIN_TO_LOCATION',
  ADD_ADMIN_DIALOG_CLOSED = 'ADD_ADMIN_FORM_CLOSED',
  ADMIN_ADDED_TO_LOCATION = 'NEW_ADMIN_ADDED',
  FAILED_TO_ADD_ADMIN = 'FAILED_TO_ADD_ADMIN',
  FAILED_TO_REMOVE_ADMIN_FROM_LOCATION = 'FAILED_TO_REMOVE_ADMIN_FROM_LOCATION',
  SHOWING_REMOVE_ADMIN_FROM_LOCATION_WARNING = 'SHOWING_REMOVE_ADMIN_FROM_LOCATION_WARNING',
  REMOVING_ADMIN_FROM_LOCATION = 'REMOVING_ADMIN_FROM_LOCATION'
}
// events
export enum LocationAdminsEvent {
  INIT = 'INIT',
  LOAD_ADMINS_FOR_LOCATION = 'LOAD_ADMINS_FOR_LOCATION',
  SHOW_ADD_ADMIN_DIALOG = 'SHOW_ADD_ADMIN_DIALOG',
  SUBMIT_ADD_ADMIN_DIALOG_TO_LOCATION_REQUESTED = 'SUBMIT_ADD_ADMIN__DIALOG_TO_LOCATION_REQUESTED',
  CLOSE_ADD_ADMIN_DIALOG = 'CLOSE_ADD_ADMIN_DIALOG',
  CLEAR_ERROR = 'CLEAR_ERROR',
  CLEAR_WARNING = 'CLEAR_WARNING',
  CLEAR_SUCCESS = 'CLEAR_SUCCESS',
  SHOW_REMOVE_ADMIN_FROM_LOCATION_WARNING = 'SHOW_REMOVE_ADMIN_FROM_LOCATION_WARNING',
  CLOSE_REMOVE_ADMIN_FROM_LOCATION_WARNING = 'CLOSE_REMOVE_ADMIN_FROM_LOCATION_WARNING',
  REMOVE_ADMIN_FROM_LOCATION = 'REMOVE_ADMIN_FROM_LOCATION'
}
export type LocationAdminsState = {
  flowState: LocationAdminsFlowState;
  loadingState: InitialStateType;
  admins: LocationUser[];
  errorMessage?: string;
  warningMessage?: string;
  adminAdded?: boolean;
  adminRemoved?: boolean;
  adminToRemove?: LocationUser;
};
