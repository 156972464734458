import { ReactNode } from 'react';
import { usePermissions } from '../../hooks/usePermissions';
import { Permission } from '../../types/user/UserPermission';

interface HasPermissionProps {
  permission: Permission;
  children: ReactNode;
}

const HasPermission: React.FC<HasPermissionProps> = ({ permission, children }) => {
  const { hasPermission } = usePermissions();

  return <>{hasPermission(permission) && children}</>;
};

export default HasPermission;
