import * as React from 'react';
import {OrganisationDetailsProvider} from './useOrganisationDetails';

interface WithOrganisationDetailsProps {
  (Component: React.FC): (props: Record<string, unknown>) => JSX.Element
}

export const withOrganisationDetails: WithOrganisationDetailsProps = (Component) => (props) => (
  <OrganisationDetailsProvider>
    <Component {...props} />
  </OrganisationDetailsProvider>
);
