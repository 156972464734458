import * as React from 'react';
import { AccountTransactionsProvider } from './useAccountTransactions';

export const withAccountTransactions =
  <T extends Record<string, any>>(Component: React.ComponentType<T>): React.FC<T> =>
  (props: T) =>
    (
      <AccountTransactionsProvider>
        <Component {...props} />
      </AccountTransactionsProvider>
    );
