import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Devices from '../Devices';
import mainTheme from '../../../../themes/mainTheme';
import OpenLoopPaper from '../../../../commons/layout/OpenLoopPaper';
import Center from '../../../../commons/layout/Center';

const useStyles = makeStyles((theme) => ({
  card: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  cardTitle: {
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: theme.spacing(2),
  },
  navigationContainer: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'flex-end',
    alignContent: 'center',
  },
  contentInset: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const RegisterForMfaSelectDevice = ({handleDeviceSelected}) => {
  const classes = useStyles(mainTheme);

  const [selectedDevice, setSelectedDevice] = useState('');
  const [helperText, setHelperText] = useState('');

  const handleDeviceChange = (event) => {
    const selectedDevice = event.target.value;
    setSelectedDevice(selectedDevice);
  };

  const handleNext = () => {
    if (selectedDevice === Devices.ANDROID) {
      handleDeviceSelected(Devices.ANDROID);
      return;
    }
    if (selectedDevice === Devices.IPHONE) {
      handleDeviceSelected(Devices.IPHONE);
      return;
    }
    setHelperText('Please select a device.');
  };

  return (
    <OpenLoopPaper>
      <Center>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" className={classes.cardTitle}>
              Activate 2-factor authentication
            </Typography>
            <Box className={classes.contentInset}>
              <FormControl component="fieldset">
                <Typography variant="body1">What kind of phone do you have?</Typography>
                <FormHelperText>{helperText}</FormHelperText>
                <RadioGroup aria-label="device" name="device" value={selectedDevice} onChange={handleDeviceChange}>
                  <FormControlLabel value={Devices.ANDROID} control={<Radio color="primary" />} label="Android" />
                  <FormControlLabel value={Devices.IPHONE} control={<Radio color="primary" />} label="iPhone" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Container className={classes.navigationContainer}>
              <Button onClick={handleNext}>Next</Button>
            </Container>
          </CardContent>
        </Card>
      </Center>
    </OpenLoopPaper>
  );
};

RegisterForMfaSelectDevice.propTypes = {
  handleDeviceSelected: PropTypes.func.isRequired,
};

export default RegisterForMfaSelectDevice;
