import _ from 'lodash';
import { PropsWithChildren, createContext, useReducer } from 'react';
import { initialState as loadingContainerInitialState } from '../../hooks/useLoadingContainerWithErrorPanel';
import { ChargerState, ChargersFlowState } from '../../types/charger/ChargerState';

const reducer = (state: any, newState: any) => ({ ...state, ...newState });

export const initialState: ChargerState = {
  loadingState: loadingContainerInitialState,
  flowState: ChargersFlowState.INIT,
  chargers: []
};
export const ChargerStateContext = createContext<ChargerState | any>(initialState);

const ChargerProvider: React.FC = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));

  return <ChargerStateContext.Provider value={{ state, dispatch }}>{children}</ChargerStateContext.Provider>;
};
export default ChargerProvider;
