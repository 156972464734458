import moment from 'moment';
import { FC } from 'react';
import BarChart from '../../../commons/charts/BarChart';

interface ChargePointInsightBarGraphProps {
  dataset: any[];
  color: string;
  trendLine?: boolean;
}

const ChargePointInsightBarGraph: FC<ChargePointInsightBarGraphProps> = ({ dataset, color, trendLine = false }) => {
  const convertToReadableDate = (date: any, numberOfDays: number) => moment(date).format(numberOfDays > 7 ? 'D MMM' : 'ddd');

  const labels = dataset.map((value) => convertToReadableDate(value.key, dataset.length));
  const values = dataset.map((value) => value.value.toFixed(2));

  return <BarChart labels={labels} values={values} colors={[color]} height={200} />;
};

export default ChargePointInsightBarGraph;
