import React, {createContext, useContext, useReducer} from 'react';
import chargePointGroupService from '../../../services/chargePointGroupService';
import {
  failedState as loadingContainerFailedState,
  initialState as loadingContainerInitialState,
  succeededState as loadingContainerSucceededState,
} from '../../../hooks/useLoadingContainerWithErrorPanel';
import _ from 'lodash';

// events
export const ChargePointGroupDetailEvent = {
  LOAD_CHARGE_POINT_GROUP: 'LOAD_CHARGE_POINT_GROUP',
};

// flow states
export const ChargePointGroupDetailFlowState = {
  INIT: 'INIT',
  SHOWING_CHARGE_POINT_GROUP: 'SHOWING_CHARGE_POINT_GROUP',
  FAILED_TO_LOAD_CHARGE_POINT_GROUP: 'FAILED_TO_LOAD_CHARGE_POINT_GROUP',
};

// initial state
const initialState = {
  flowState: ChargePointGroupDetailFlowState.INIT,
  loadingState: loadingContainerInitialState,
  chargePointGroup: null,
};

// reducer
const reducer = (state, newState) => ({...state, ...newState});

// context
const chargePointGroupDetailContext = createContext();

// provider
export const ChargePointGroupDetailProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, _.cloneDeep(initialState));
  return (
    // provide {state, dispatch} object to all children
    <chargePointGroupDetailContext.Provider value={{state, dispatch}}>{children}</chargePointGroupDetailContext.Provider>
  );
};

// hook
const useChargePointGroupDetail = () => {
  const {state, dispatch} = useContext(chargePointGroupDetailContext);

  const addEvent = (event) => {
    switch (event.type) {
      case ChargePointGroupDetailEvent.LOAD_CHARGE_POINT_GROUP:
        chargePointGroupService.getGroupForAdmin(event.payload.id).subscribe(
          (result) =>
            dispatch({
              loadingState: loadingContainerSucceededState,
              chargePointGroup: result,
              flowState: ChargePointGroupDetailFlowState.SHOWING_CHARGE_POINT_GROUP,
            }),
          (error) =>
            dispatch({
              loadingState: loadingContainerFailedState(error.message),
              flowState: ChargePointGroupDetailFlowState.FAILED_TO_LOAD_CHARGE_POINT_GROUP,
            })
        );
        break;
      default:
        throw new Error(`Unhandled event: ${event}`);
    }
  };

  return {
    state,
    addEvent,
  };
};

export default useChargePointGroupDetail;
