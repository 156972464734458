import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import mainTheme from '../../themes/mainTheme';
import drawerBackgroundImage from '../../assets/images/openloop_background_drawer.png';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.drawer,
    backgroundImage: `url(${drawerBackgroundImage})`,
    zIndex: 1,
  },
  toolbar: {
    height: 69,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const TopAppBar = () => {
  const classes = useStyles(mainTheme);

  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar className={classes.toolbar}></Toolbar>
    </AppBar>
  );
};

export default TopAppBar;
