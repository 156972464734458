import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { AccountType } from '../types/account/Account';

import { CSChargerReport, ChargerReport } from '../types/charger/Charger';
import { TransactionDetails } from '../types/transaction/Transaction';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { errorMessageObservable } from '../utils/error/errorUtils';
import { buildQueryString } from '../utils/url/buildQueryString';

const toLocation = (locationReportItem: any) => ({
  owner: locationReportItem.owner,
  id: locationReportItem.id,
  address: locationReportItem.address,
  powerType: locationReportItem.power_type,
  commissioned: locationReportItem.commissioned,
  decommissioned: locationReportItem.decommissioned
});

const toLocations = (locationReportItems: any) => locationReportItems.map(toLocation);

const toCharger = (charger: CSChargerReport): ChargerReport => ({
  id: charger.id,
  address: charger.address,
  locationName: charger.location_name,
  owner: charger.owner,
  commissioned: charger.commissioned,
  decommissioned: charger.decommissioned,
  powerType: charger.power_type
});

const toChargers = (chargers: CSChargerReport[]) => chargers.map(toCharger);

const toTransactionReportDetails = (source: TransactionDetails) => ({
  id: source.id,
  created: source.created,
  status: source.status,
  user: {
    id: source.user.id,
    customerId: source.user.customer_id,
    accountName: source.user.account_name,
    accountId: source.user.account_id,
    accountType: source.user.account_type,
    name: source.user.name,
    chargeTagId: source.user.charge_tag_id,
    email: source.user.email,
    mobileNumber: source.user.mobile_number
  },
  location: {
    id: source.location.id,
    owner: source.location.owner,
    name: source.location.name
  },
  session: {
    durationInSeconds: source.session.duration_in_seconds,
    kwh: source.session.kwh
  },
  tariff: {
    ratePerKwh: source.tariff.rate_per_kwh,
    ratePerMinute: source.tariff.rate_per_minute,
    unlockFee: source.tariff.unlock_fee
  },
  payment: {
    unlockFeeTotal: source.payment.unlock_fee_total,
    kwhFeeTotal: source.payment.kwh_fee_total,
    minuteFeeTotal: source.payment.minute_fee_total,
    total: source.payment.total
  },
  carbonEmissions: {
    petrolEmissions: {
      emissionFactor: {
        value: source.carbon_emissions.petrol_emissions.emission_factor.value,
        unit: source.carbon_emissions.petrol_emissions.emission_factor.unit
      },
      amount: source.carbon_emissions.petrol_emissions.amount
    },
    kwhToKmConversionRate: source.carbon_emissions.kwh_to_km_conversion_rate,
    electricityEmissions: {
      emissionFactor: {
        value: source.carbon_emissions.electricity_emissions.emission_factor.value,
        unit: source.carbon_emissions.electricity_emissions.emission_factor.unit
      },
      amount: source.carbon_emissions.electricity_emissions.amount
    },
    carbonOffset: source.carbon_emissions.carbon_offset
  },
  operationalCost: {
    electricityCost: {
      ratePerKwh: source.operational_cost.electricity_cost.rate_per_kwh
    }
  },
  operationalExpenditure: {
    electricityCostTotal: source.operational_expenditure.electricity_cost_total
  },
  paymentBreakdown: {
    // Payment breakdown will be null for active transactions
    prepaidAmount: source.payment_breakdown?.prepaid_amount ?? 0,
    creditCardPayment: source.payment_breakdown?.credit_card_payment ?? 0,
    postpayAmount: source.payment_breakdown?.postpay_amount ?? 0
  }
});

const toTransactionReportDetailsArray = (transactionReportItems: any) => transactionReportItems.map(toTransactionReportDetails);

interface DateRange {
  dateFrom: Moment;
  dateTo: Moment;
}

const reportService = {
  getTransactions(dateRange: DateRange, accountType: AccountType): Observable<TransactionDetails[]> {
    const queryString = buildQueryString({
      date_from: dateRange.dateFrom.toISOString(),
      // moment.min(dateRange.dateTo, moment()) is to avoid posting dateTo in the future
      date_to: moment.min(dateRange.dateTo, moment()).toISOString(),
      account_type: accountType
    });
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV3}/reports/transactions?${queryString}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toTransactionReportDetailsArray(response.response.data)),
      catchError((error) => errorMessageObservable(error))
    );
  },
  getLocations(): Observable<Location[]> {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/reports/locations`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toLocations(response.response.data)),
      catchError((error) => errorMessageObservable(error))
    );
  },
  getChargers(): Observable<ChargerReport[]> {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/reports/charge-points`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toChargers(response.response.data)),
      catchError((error) => errorMessageObservable(error))
    );
  }
};

export default reportService;
