import PropTypes from 'prop-types';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import NavigationLink from '../../../commons/link/NavigationLink';
import { DASHBOARD_PATH } from '../../../routes/paths';
import { formatAccountType } from '../../../utils/account/accountUtils';

const UserPersonalDetails = ({ state }) => {
  const user = state?.user;
  const account = user?.account;

  return (
    <>
      {user && (
        <>
          <PaperWithTitle title="Personal Details">
            <>
              <TextWithLabel label="Name" text={user.name} />
              <TextWithLabel label="Email" text={user.email} />
              <TextWithLabel label="Phone" text={user.mobileNumber} />
            </>
          </PaperWithTitle>
          {account && (
            <PaperWithTitle title="Account Details">
              <>
                <TextWithLabel label="Name" text={<NavigationLink text={account.name} path={`${DASHBOARD_PATH}/accounts/${account.id}/details`} />} />
                <TextWithLabel label="Type" text={formatAccountType(account.type)} />
              </>
            </PaperWithTitle>
          )}
        </>
      )}
    </>
  );
};
UserPersonalDetails.propTypes = {
  state: PropTypes.object.isRequired
};

export default UserPersonalDetails;
