import { useRouteMatch } from 'react-router';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import LoadingContainerWithErrorPanel from '../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import EditButton from '../../../commons/form/button/EditButton';
import paths from '../../../routes/paths';
import { LocationDetailsFlowState } from '../../../types/LocationDetails';
import { LocationEvent } from '../hook/useLocations';
import { AdminPermission, useAdminPermissions } from '../../../hooks/useAdminPermissions';

const LocationInfo = ({ location }) => {
  return (
    <>
      <TextWithLabel label="Name" text={location.name} />
      <TextWithLabel label="Organisation" text={location.ownerName} />
      <TextWithLabel label="Latitude" text={location.latitude} />
      <TextWithLabel label="Longitude" text={location.longitude} />
      <TextWithLabel label="Address" text={location.address} />
      <TextWithLabel label="City" text={location.city} />
      <TextWithLabel label="State" text={location.state} />
      <TextWithLabel label="Postcode" text={location.postalCode} />
      <TextWithLabel label="Access Type" text={location.public ? 'Public' : 'Private'} />
      {location.public && location.visibleToOsps.length > 0 && (
        <TextWithLabel label="Roaming Partners" text={location.visibleToOsps.map((osp) => osp.name).join(', ')} />
      )}
    </>
  );
};

const LocationViewForm = ({ location, state, addEvent }) => {
  const handleEdit = () => {
    addEvent({
      type: LocationEvent.SHOW_UPDATE_LOCATION_FORM,
      payload: location
    });
  };

  const match = useRouteMatch(paths.LOCATION_DETAILS);
  const isEditable = match.params.cpoCountryCode === 'NZ' && match.params.cpoPartyId === 'OPL';

  const { hasAdminPermission } = useAdminPermissions();
  const userCanEdit = hasAdminPermission(AdminPermission.HELPDESK);

  return (
    <PaperWithTitle
      title="General"
      topRightComponent={
        userCanEdit &&
        location && (
          <EditButton
            disabled={!isEditable || state.flowState === LocationDetailsFlowState.SHOWING_UPDATE_BASIC_CONFIG_FORM}
            handleEdit={handleEdit}
          />
        )
      }
      bottomRightComponent={<></>}
    >
      <LoadingContainerWithErrorPanel {...state.loadingState}>{location && <LocationInfo location={location} />}</LoadingContainerWithErrorPanel>
    </PaperWithTitle>
  );
};

export default LocationViewForm;
