import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import { FC } from 'react';
import BoxWithLabel from '../../../../commons/containers/ComponentWithLabel/BoxWithLabel';
import TextWithLabel from '../../../../commons/containers/ComponentWithLabel/TextWithLabel';
import PaperWithTitle from '../../../../commons/containers/PaperWithTile/PaperWithTitle';
import EditButton from '../../../../commons/form/button/EditButton';
import PageWithColumns from '../../../../commons/layout/PageWithColumns';
import NavigationLink from '../../../../commons/link/NavigationLink';
import paths from '../../../../routes/paths';
import { CpoChargePoint, EmspCharger, getChargerModeFromAnonymousChargingAllowed } from '../../../../types/charger/Charger';
import ChargePointStatus from '../../../ChargePointStatus/ChargePointStatus';

export interface ChargerDetailsPanelProps {
  cpoChargePoint?: CpoChargePoint;
  emspCharger: EmspCharger;
  handleEdit: () => void;
  canEdit: boolean;
  canReset: boolean;
  handleReset: () => void;
  canRemoteStart: boolean;
  handleRemoteStart: () => void;
  hasAtLeastOneAvailableEvse: boolean;
}

const ChargerInfoPanel: FC<ChargerDetailsPanelProps> = ({
  emspCharger,
  cpoChargePoint,
  canEdit,
  handleEdit,
  canReset,
  handleReset,
  canRemoteStart,
  handleRemoteStart,
  hasAtLeastOneAvailableEvse
}) => {
  const renderLocationRoute = (locationId: string, countryCode: string, partyId: string) => {
    return `${paths.LOCATIONS}/${countryCode}/${partyId}/${locationId}`;
  };

  const chargerActions = (
    <>
      {canEdit ? <EditButton disabled={false} handleEdit={handleEdit} /> : null}
      {canReset ? (
        <Button variant="contained" color="secondary" onClick={handleReset}>
          Reset
        </Button>
      ) : null}
      {canRemoteStart && emspCharger ? (
        <Button variant="contained" color="primary" onClick={handleRemoteStart} disabled={!hasAtLeastOneAvailableEvse}>
          Remote Start
        </Button>
      ) : null}
    </>
  );

  return (
    <PaperWithTitle title="General" topRightComponent={chargerActions}>
      <>
        <PageWithColumns
          columnOne={
            <Box>
              <TextWithLabel label="Name" text={emspCharger.displayName} />
              {cpoChargePoint && <TextWithLabel label="Ocpp Identifier" text={cpoChargePoint?.ocppChargePointIdentifier} />}
              <BoxWithLabel label="Location">
                <NavigationLink
                  path={`${renderLocationRoute(emspCharger.locationId, emspCharger.countryCode, emspCharger.partyId)}/details`}
                  text={emspCharger.locationName}
                />
              </BoxWithLabel>
              <TextWithLabel label="Location Type" text={_.capitalize(emspCharger.locationType)} />
              {cpoChargePoint && <TextWithLabel label="Organisation" text={emspCharger.orgName} />}
              {cpoChargePoint && (
                <TextWithLabel label="Mode" text={getChargerModeFromAnonymousChargingAllowed(cpoChargePoint.anonymousChargingAllowed)} />
              )}
              <BoxWithLabel label="Status">
                <ChargePointStatus status={emspCharger.status} />
              </BoxWithLabel>
            </Box>
          }
          columnTwo={
            <Box>
              {cpoChargePoint && cpoChargePoint.chargePointModel && <TextWithLabel label="Charger Model" text={cpoChargePoint.chargePointModel} />}
              {cpoChargePoint && cpoChargePoint.chargePointVendor && (
                <TextWithLabel label="Charger Manufacturer" text={cpoChargePoint.chargePointVendor} />
              )}
              {cpoChargePoint && cpoChargePoint.firmwareVersion && <TextWithLabel label="Firmware Version" text={cpoChargePoint.firmwareVersion} />}
              {cpoChargePoint && cpoChargePoint.chargeBoxSerialNumber && (
                <TextWithLabel label="Serial Number" text={cpoChargePoint?.chargeBoxSerialNumber} />
              )}
            </Box>
          }
        ></PageWithColumns>
      </>
    </PaperWithTitle>
  );
};

export default ChargerInfoPanel;
