import React from 'react';
import useUserGroupUserList from './hook/useUserGroupUserList';
import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';
import ChargeTagStatus, {chargeTagStatus} from '../../ChargeTag/ChargeTagStatus';
import {useLocation} from 'react-router';
import NavigationLink from '../../../commons/link/NavigationLink';

const UserGroupUserListTable = () => {
  const {state} = useUserGroupUserList();
  const userGroupName = state.userGroup.name;
  const location = useLocation();

  return (
    <MaterialTableWrapper
      columns={[
        {title: 'Id', field: 'id', width: 'auto', hidden: true, hiddenByColumnsButton: true},
        {
          title: 'Name',
          field: 'name',
          width: 'auto',
          render: (rowData) => <NavigationLink path={`${location.pathname}/users/${rowData.id}`} text={rowData.name} />,
          defaultSort: 'asc',
        },
        {title: 'Email', field: 'email', width: 'auto'},
        {title: 'Charge Tag Id', field: 'chargeTagId', hidden: true, export: true},
        {
          title: 'Charge Tag Id',
          field: 'chargeTagId',
          export: false,
          render: (rowData) => <>{rowData.chargeTagStatus !== chargeTagStatus.UNASSIGNED && <ChargeTagStatus active={rowData.isChargeTagActive} chargeTagId={rowData.chargeTagId} />}</>,
        },
        {title: 'Charge Tag Status', field: 'chargeTagStatus', width: 'auto', hidden: true, hiddenByColumnsButton: true, export: true},
      ]}
      data={state.userGroupUserList.map((user) => ({
        id: user.id,
        name: user.name,
        email: user.email,
        chargeTagId: user.chargeTagId,
        isChargeTagActive: user.isChargeTagActive,
        chargeTagStatus: user.chargeTagStatus,
      }))}
      exportFileName={`Users in ${userGroupName} user fleet`}
    />
  );
};

export default UserGroupUserListTable;
